import type { TAllTripBinTypes } from "@app/analysis/addOns/state/addOns.constants";
import type { TTravelMode } from "@common/constants/analysis.constants";
import type { TMeasurementUnits } from "@common/constants/measurementUnits.constants";

export const getTripBinDefaults = (
    tripBin: TAllTripBinTypes,
    travelModeCode: TTravelMode["code"],
    measurementUnit: TMeasurementUnits["id"],
) => {
    const travelModeDefaults = tripBin.defaultValueForTravelMode?.[travelModeCode];

    if (travelModeDefaults) {
        return (
            travelModeDefaults[measurementUnit as keyof typeof travelModeDefaults] ||
            travelModeDefaults
        );
    } else {
        return tripBin.defaultValue;
    }
};
