import { MAP_STYLES } from "@app/viz3/odgVisualization/map/mapStyles.constants";
import { ODG_LAYERS } from "@app/viz3/odgVisualization/state/odgViz.constants";

export const ODG_VIZ_INITIAL_STATE = {
    map: {
        clickedZoneId: null,
        layerConfigurations: {
            [ODG_LAYERS.ORIGIN.code]: {
                colors: {
                    high: MAP_STYLES.FILTER_ZONES.COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES.FILTER_ZONES.COLOR.LOW,
                    hover: MAP_STYLES.FILTER_ZONES.COLOR.HOVER,
                },
                opacity: MAP_STYLES.FILTER_ZONES.OPACITY,
            },
            [ODG_LAYERS.DESTINATION.code]: {
                colors: {
                    high: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.LOW,
                    hover: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HOVER,
                },
                opacity: MAP_STYLES.DISTRIBUTION_ZONES.OPACITY,
            },
        },
    },
    distributionLayers: null,
    preSelectedZones: [],
    cachedZones: {
        trafficBehaviors: [],
        zoneMap: {},
        selectedZones: [],
    },
};
