import { Switch } from "@material-ui/core";
import { SwitchProps } from "@material-ui/core/Switch/Switch";
import "./switch.less";

export const StlSwitch = ({ checked, onChange, ...restProps }: SwitchProps): JSX.Element => (
    <Switch
        checked={checked}
        className="stl-switch"
        disableRipple
        onChange={onChange}
        {...restProps}
    />
);
