import { useEffect, useState } from "react";
import { useAppSelector } from "@app/store/hooks";
import { useForm } from "@common/hooks/useForm";
import { getIsOrgHasFeature } from "@app/store/currentUser/currentUser.selector";
import { StlLightbox } from "@common/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { LightboxService } from "@common/components/lightbox/lightbox.service";
import { FormattedMessage } from "react-intl";
import { StudiesApiService } from "@common/services/server/studiesApi.service";
import { RequestQuote } from "@common/features/studyModal/requestQuote/requestQuote";
import {
    getStudyHash,
    studyDataToState,
    studyStateToData,
} from "@common/features/studyModal/common/study.helpers";
import { READ_ONLY_STATUSES } from "@common/features/studyModal/common/study.constants";
import { IStudy, IStudyData } from "@common/features/studyModal/common/study.types";
import "./requestQuoteModal.less";

const INITIAL_STATE = {
    study: {} as IStudy,
    isViewMode: true,
    studyHash: null,
};

type TProps = {
    show: boolean;
    onHide: () => void;
    study: IStudyData | null;
    isReadOnly?: boolean;
};

export const RequestQuoteModal = ({
    show,
    onHide,
    study: originalStudy,
    isReadOnly = false,
}: TProps) => {
    const { formValue, setFormValue, patchFormValue } = useForm(INITIAL_STATE.study);
    const [isViewMode, setIsViewMode] = useState(INITIAL_STATE.isViewMode);
    const [studyHash, setStudyHash] = useState<Buffer | string | null>(INITIAL_STATE.studyHash);

    const isEnterpriseStudy = useAppSelector(state =>
        getIsOrgHasFeature(state, "enterprise_study_workflow"),
    );

    useEffect(() => {
        if (!show || !originalStudy) return;

        StudiesApiService.getStudy(originalStudy.study_id).then(({ study }) => {
            const parsedStudy = studyDataToState(study) as IStudy;

            setIsViewMode(
                isReadOnly ||
                    READ_ONLY_STATUSES.includes(
                        parsedStudy.status as typeof READ_ONLY_STATUSES[number],
                    ),
            );
            setStudyHash(getStudyHash(parsedStudy));
            setFormValue(parsedStudy);
        });
    }, [originalStudy, setFormValue, show, isReadOnly]);

    const requestQuote = () => {
        LightboxService.openConfirmation({
            // @ts-ignore
            title: (
                <FormattedMessage
                    id="app.confirmRequestQuoteTitle"
                    defaultMessage="Confirm {requestTitle}"
                    values={{
                        requestTitle: isEnterpriseStudy
                            ? "Convert To Paid License"
                            : "Request Quote",
                    }}
                />
            ),
            content: (
                <FormattedMessage
                    id="app.confirmRequestQuote"
                    defaultMessage="You will receive an email confirming {request}. Your request will be forwarded to your StreetLight Data Representative who will get in touch with you within 1-2 business days."
                    values={{
                        request: isEnterpriseStudy
                            ? "the conversion to a paid license"
                            : "your request for a quote",
                    }}
                />
            ),
            onOK: () => {
                const serializedStudy = studyStateToData(formValue, isEnterpriseStudy);
                return StudiesApiService.requestPurchase(serializedStudy).then(() => {
                    onHide();
                });
            },
        });
    };

    const closeModal = () => {
        const hasChanges = !isViewMode && getStudyHash(formValue) !== studyHash;

        if (hasChanges) {
            LightboxService.openConfirmation({
                title: "Confirm Close",
                content: "Closing this window will discard changes. Are you sure?",
                onOK: onHide,
            });
        } else {
            onHide();
        }
    };

    const requestStatus = (
        <div className="content">
            <FontAwesomeIcon icon={faCheck} />
            Request Submitted
        </div>
    );

    return (
        <StlLightbox
            show={show}
            className="stl-request-quote-modal"
            title={isEnterpriseStudy ? "Convert To Paid License" : "Request Quote"}
            titleContent={isViewMode && requestStatus}
            body={
                <RequestQuote
                    study={formValue}
                    updateStudy={patchFormValue}
                    requestQuote={requestQuote}
                    closeModal={closeModal}
                    isReadOnly={isViewMode}
                    isEnterpriseStudy={isEnterpriseStudy}
                />
            }
            onHide={onHide}
            centered
        />
    );
};
