import { combineReducers } from "@reduxjs/toolkit";
import { basicsReducer } from "@app/analysis/basics/state/basics.reducer";
import { addOnsReducer } from "@app/analysis/addOns/state/addOns.reducer";
import { timePeriodsReducer } from "@app/analysis/timePeriods/state/timePeriods.reducer";
import { reviewReducer } from "@app/analysis/review/state/review.reducer";
import { chooseZonesReducer } from "@app/analysis/zones/chooseZones/state/chooseZones.reducer";
import { tmcChooseZonesReducer } from "@app/analysis/zones/chooseZones/configurations/tmc/state/tmcChooseZones.reducer";
import { draftAnalysisReducer } from "@app/analysis/draftAnalysis/state/draftAnalysis.reducer";
import { generalReducer } from "./general/general.reducer";

export const analysisConfiguration = combineReducers({
    general: generalReducer,
    basics: basicsReducer,
    timePeriods: timePeriodsReducer,
    addOns: addOnsReducer,
    chooseZones: chooseZonesReducer,
    tmcChooseZones: tmcChooseZonesReducer,
    draft: draftAnalysisReducer,
    review: reviewReducer,
});
