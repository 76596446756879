import { ReactNode } from "react";

type TProps = {
    title: string;
    children: ReactNode;
};

export const StlAccountListItem = ({ title, children }: TProps) => (
    <li>
        <div className="card-item-label">{title}</div>
        {children}
    </li>
);
