import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { ISelectedProjectFolder } from "./analysisLightning.types";
import type { TLightningAnalysisTypeIds } from "./analysisLightning.constants";
import { IUiStates, IConfigurations, BASE_INITIAL_STATE } from "./analysisLightning.state";

export const { actions, reducer } = createSlice({
    name: "analysisLightning",
    initialState: BASE_INITIAL_STATE,
    reducers: {
        setUiState: (state, action: PayloadAction<Partial<IUiStates>>) => {
            state.uiStates = {
                ...state.uiStates,
                ...action.payload,
            };
        },
        setConfigurations: (state, action: PayloadAction<Partial<IConfigurations>>) => {
            state.configurations = {
                ...state.configurations,
                ...action.payload,
            };
        },
        resetAnalysisLightningState: () => {
            return BASE_INITIAL_STATE;
        },
        setProjectFolder: (state, action: PayloadAction<ISelectedProjectFolder | null>) => {
            state.configurations.projectFolder = action.payload;
        },
        setAnalysisType: (state, action: PayloadAction<TLightningAnalysisTypeIds | null>) => {
            state.configurations.analysisType = action.payload;
        },
    },
});

export const baseReducer = reducer;
