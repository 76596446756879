import { BASE_MAP_STYLES } from "@app/viz3/base/map/baseMapStyles.constants";

export const MAP_STYLES = {
    ...BASE_MAP_STYLES,
    ZONE_COLOR: {
        MIN: "#626262",
        MAX: "#FFDA6A",
        HOVER: "#FFFFFF",
    },
    ZONE_OPACITY: 0.7,
    ZONE_Z_INDEX: 10,
};
