import {
    ZONE_KINDS,
    ZONE_ROLES as BASE_ZONE_ROLES,
} from "@common/constants/zoneLibrary.constants";
import {
    CREATE_ANALYSIS_TYPES,
    OSM_NETWORK_TYPES,
} from "@app/analysis/state/analysisConfiguration.constants";
import {
    ROAD_TYPES,
    ZONE_TYPES,
    ZONE_TYPES_LIST,
} from "@common/features/zonesManager/state/zonesManager.constants";
import { ZoneRoles } from "@common/features/zones/zones.types";

export const SUPPORTED_ROAD_TYPES_BY_TRAVEL_MODES = {
    [OSM_NETWORK_TYPES.VEHICLE.id]: [
        ...ROAD_TYPES.MOTORWAY.roadIds,
        ...ROAD_TYPES.RAMP.roadIds,
        ...ROAD_TYPES.TRUNK.roadIds,
        ...ROAD_TYPES.PRIMARY.roadIds,
        ...ROAD_TYPES.SECONDARY.roadIds,
        ...ROAD_TYPES.TERTIARY.roadIds,
        ...ROAD_TYPES.RESIDENTIAL.roadIds,
        ...ROAD_TYPES.SERVICE.roadIds,
        ...ROAD_TYPES.UNCLASSIFIED.roadIds,
    ],
    [OSM_NETWORK_TYPES.BICYCLE.id]: [
        ...ROAD_TYPES.PRIMARY.roadIds,
        ...ROAD_TYPES.SECONDARY.roadIds,
        ...ROAD_TYPES.TERTIARY.roadIds,
        ...ROAD_TYPES.RESIDENTIAL.roadIds,
        ...ROAD_TYPES.SERVICE.roadIds,
        ...ROAD_TYPES.PEDESTRIAN.roadIds,
        ...ROAD_TYPES.CYCLEWAY.roadIds,
        ...ROAD_TYPES.PATH.roadIds,
        ...ROAD_TYPES.UNCLASSIFIED.roadIds,
    ],
    [OSM_NETWORK_TYPES.PEDESTRIAN.id]: [
        ...ROAD_TYPES.PRIMARY.roadIds,
        ...ROAD_TYPES.SECONDARY.roadIds,
        ...ROAD_TYPES.TERTIARY.roadIds,
        ...ROAD_TYPES.RESIDENTIAL.roadIds,
        ...ROAD_TYPES.SERVICE.roadIds,
        ...ROAD_TYPES.PEDESTRIAN.roadIds,
        ...ROAD_TYPES.PATH.roadIds,
        ...ROAD_TYPES.UNCLASSIFIED.roadIds,
    ],
};

export const DEFAULT_STANDARD_AREA = ZONE_KINDS.BLOCK_GROUP_US_2020;

export const ZONES_SECTION_ACTIONS = {
    IMPORT_FROM_ANALYSIS: "importFromAnalysis",
    REUSE_AS_ORIGINS: "reuseAsOrigins",
    REUSE_AS_DESTINATIONS: "reuseAsDestinations",
    MOVE_TO_MIDDLE_FILTERS: "moveToMiddleFilters",
    MOVE_TO_ORIGINS: "moveToOrigins",
    MOVE_TO_DESTINATIONS: "moveToDestinations",
} as const;
export type TZonesSectionAction = typeof ZONES_SECTION_ACTIONS[keyof typeof ZONES_SECTION_ACTIONS];

export const ZONE_ACTIONS = {
    COPY: "copy",
    REUSE_AS_ORIGIN: "reuseAsOrigin",
    REUSE_AS_DESTINATION: "reuseAsDestination",
    ADD_AS_CALIBRATION: "addAsCalibration",
    ZOOM_TO_ZONE: "zoomToZone",
} as const;
export type TZoneAction = typeof ZONE_ACTIONS[keyof typeof ZONE_ACTIONS];

export const ZONE_ROLES = {
    DEFAULT: {
        ...BASE_ZONE_ROLES.DEFAULT,
        sectionActions: [],
    },
    ORIGINS: {
        ...BASE_ZONE_ROLES.ORIGINS,
        sectionActions: [ZONES_SECTION_ACTIONS.REUSE_AS_DESTINATIONS],
        zoneActions: [ZONE_ACTIONS.REUSE_AS_DESTINATION],
    },
    DESTINATIONS: {
        ...BASE_ZONE_ROLES.DESTINATIONS,
        sectionActions: [ZONES_SECTION_ACTIONS.REUSE_AS_ORIGINS],
        zoneActions: [ZONE_ACTIONS.REUSE_AS_ORIGIN],
    },
    MIDDLE_FILTERS: {
        ...BASE_ZONE_ROLES.MIDDLE_FILTERS,
        accessor: "mfz",
        sectionActions: [ZONES_SECTION_ACTIONS.MOVE_TO_MIDDLE_FILTERS],
    },
    SEGMENTS: {
        ...BASE_ZONE_ROLES.SEGMENTS,
        sectionActions: [],
    },
} as const;

export const ZONE_ROLES_LIST = Object.values(ZONE_ROLES);
export type TZoneRole = typeof ZONE_ROLES[keyof typeof ZONE_ROLES];

export const SELECTED_ZONES_ROLES = [
    ZoneRoles.oz,
    ZoneRoles.dz,
    ZoneRoles.mfz,
    ZoneRoles.tfz,
    ZoneRoles.cz,
    ZoneRoles.az,
];

export const TRIP_BEHAVIOR_ZONE_ROLES = {
    [CREATE_ANALYSIS_TYPES.AADT.code]: [ZONE_ROLES.DEFAULT],
    [CREATE_ANALYSIS_TYPES.OD.code]: [ZONE_ROLES.ORIGINS, ZONE_ROLES.DESTINATIONS],
    [CREATE_ANALYSIS_TYPES.ODMF.code]: [
        ZONE_ROLES.ORIGINS,
        ZONE_ROLES.MIDDLE_FILTERS,
        ZONE_ROLES.DESTINATIONS,
    ],
    [CREATE_ANALYSIS_TYPES.ZA.code]: [ZONE_ROLES.DEFAULT],
    [CREATE_ANALYSIS_TYPES.ODG.code]: [ZONE_ROLES.DEFAULT],
    [CREATE_ANALYSIS_TYPES.TOP_ROUTES_ZA.code]: [ZONE_ROLES.DEFAULT],
    [CREATE_ANALYSIS_TYPES.TOP_ROUTES_OD.code]: [ZONE_ROLES.ORIGINS, ZONE_ROLES.DESTINATIONS],
    [CREATE_ANALYSIS_TYPES.SEGMENT.code]: [ZONE_ROLES.DEFAULT],
    [CREATE_ANALYSIS_TYPES.CONGESTION.code]: [ZONE_ROLES.DEFAULT],
    [CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.code]: [ZONE_ROLES.DEFAULT],
    [CREATE_ANALYSIS_TYPES.TMC.code]: [ZONE_ROLES.DEFAULT],
    [CREATE_ANALYSIS_TYPES.TT_TMC.code]: [ZONE_ROLES.DEFAULT],
};

export const ANALYSIS_TYPES_AVAILABLE_ZONE_TYPES = {
    [CREATE_ANALYSIS_TYPES.AADT.code]: [ZONE_TYPES.OSM_LINE_SEGMENTS],
    [CREATE_ANALYSIS_TYPES.OD.code]: ZONE_TYPES_LIST,
    [CREATE_ANALYSIS_TYPES.ODMF.code]: ZONE_TYPES_LIST,
    [CREATE_ANALYSIS_TYPES.ZA.code]: ZONE_TYPES_LIST,
    [CREATE_ANALYSIS_TYPES.ODG.code]: ZONE_TYPES_LIST,
    [CREATE_ANALYSIS_TYPES.TOP_ROUTES_ZA.code]: ZONE_TYPES_LIST,
    [CREATE_ANALYSIS_TYPES.TOP_ROUTES_OD.code]: ZONE_TYPES_LIST,
    [CREATE_ANALYSIS_TYPES.SEGMENT.code]: [ZONE_TYPES.OSM_LINE_SEGMENTS],
    [CREATE_ANALYSIS_TYPES.CONGESTION.code]: [ZONE_TYPES.OSM_LINE_SEGMENTS],
    [CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.code]: ZONE_TYPES_LIST,
    [CREATE_ANALYSIS_TYPES.TMC.code]: [ZONE_TYPES.OSM_LINE_SEGMENTS],
    [CREATE_ANALYSIS_TYPES.TT_TMC.code]: [ZONE_TYPES.OSM_LINE_SEGMENTS],
};

export const CHOOSE_ZONE_MODAL_TITLE = "What kind of zone do you want to choose?";

export const SWITCH_MODES = {
    ANALYSIS_ZONES: {
        id: "analysisZones",
        name: "Analysis Zones",
    },
    CALIBRATION_ZONES: {
        id: "calibrationZones",
        name: "Calibration Zones",
    },
} as const;
export const SWITCH_MODES_LIST = Object.values(SWITCH_MODES);
export type TSwitchMode = typeof SWITCH_MODES[keyof typeof SWITCH_MODES];
export const ZONE_TYPE_MODE = {
    ZONES: { id: "Zones" },
    SPOT_COUNTERS: { id: "SpotCounters" },
};

export type TZoneTypeMode = typeof ZONE_TYPE_MODE[keyof typeof ZONE_TYPE_MODE];
export type TZoneTypeModeKey = keyof typeof ZONE_TYPE_MODE;
