export const ZONE_COUNT_LIMIT = 500;
export const FIRST_SECONDARY_BAR_CLASSNAME = "first-secondary-bar";

export const SPEED_METRIC_TYPES = {
    AVG_SPEED: {
        code: "avg_speed",
        label: "Average Speed",
        units: { MPH: "Mph", KPH: "Kph" },
    },
    SPEED_PERCENTILE: {
        code: "speed_percentile",
        label: "Speed Percentile",
        unit: "",
    },
};
export const SPEED_METRIC_TYPES_LIST = Object.values(SPEED_METRIC_TYPES);
