import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { IMetricsPackage, IModuleType } from "@common/services/server/projectFolderApi.types";
import { TOrg } from "@app/bigBang/admin/orgs/orgs.types";
import { ORGS_INITIAL_STATE } from "./orgs.state";

export const { actions, reducer } = createSlice({
    name: "orgsReducer",
    initialState: ORGS_INITIAL_STATE,
    reducers: {
        setSelectedOrg: (state, action: PayloadAction<TOrg>) => {
            state.selectedOrg = action.payload;
        },
        updateSelectedOrg: (state, action: PayloadAction<Partial<TOrg>>) => {
            state.selectedOrg = {
                ...state.selectedOrg,
                ...action.payload,
            };
        },
        setMetricsPackages: (state, action: PayloadAction<Array<IMetricsPackage>>) => {
            state.metricsPackages = action.payload;
        },
        setModuleTypes: (state, action: PayloadAction<Array<IModuleType>>) => {
            state.moduleTypes = action.payload;
        },
    },
});

export const orgsReducer = reducer;
