export const SHAPEFILE_UPLOAD_POLLING_INTERVAL_MS = 5000;

export const SHAPEFILE_UPLOAD_POLLING_STATUSES = {
    NEW: "New",
    PROCESSING: "Processing",
    AVAILABLE: "Available",
    ERROR: "Error",
};

export const UPLOAD_DEFAULT_OPTION = "[-- Choose an option --]";

export const UPLOAD_BLANK_OPTION = "[Leave as Blank]";

export const UPLOAD_VALIDATION_MSG =
    "Please confirm the selected fields for the zones, or change as appropriate.";

export const CALIBRATION_FIELDS = {
    calibrationType: {
        name: "calibrationTypeSelect",
        displayOptionName: "calibration_type_options_display",
        editOptionName: "calibration_type_options",
    },
    calibrationValue: {
        name: "calibrationValueSelect",
        displayOptionName: "calibration_value_options_display",
        editOptionName: "calibration_value_options",
    },
    persRatio: {
        name: "persRatioSelect",
        displayOptionName: "pers_ratio_options_display",
        editOptionName: "pers_ratio_options",
    },
    mdRatio: {
        name: "mdRatioSelect",
        displayOptionName: "md_ratio_options_display",
        editOptionName: "md_ratio_options",
    },
    hdRatio: {
        name: "hdRatioSelect",
        displayOptionName: "hd_ratio_options_display",
        editOptionName: "hd_ratio_options",
    },
    collectionMethod: {
        name: "collectionMethodSelect",
        displayOptionName: "collection_method_options_display",
        editOptionName: "collection_method_options",
    },
    description: {
        name: "descriptionSelect",
        displayOptionName: "description_options_display",
        editOptionName: "description_options",
    },
    bikeCalValue: {
        name: "bikeCalValueSelect",
        displayOptionName: "bike_cal_value_options_display",
        editOptionName: "bike_cal_value_options",
    },
    bikeDesc: {
        name: "bikeDescSelect",
        displayOptionName: "bike_desc_options_display",
        editOptionName: "bike_desc_options",
    },
    pedCalValue: {
        name: "pedCalValueSelect",
        displayOptionName: "ped_cal_value_options_display",
        editOptionName: "ped_cal_value_options",
    },
    pedDesc: {
        name: "pedDescSelect",
        displayOptionName: "ped_desc_options_display",
        editOptionName: "ped_desc_options",
    },
};

export const CALIBRATION_FIELDS_LIST = Object.values(CALIBRATION_FIELDS);

export const SUPPORTED_FILE_EXTENSIONS = {
    XLSX: "xlsx",
    ZIP: "zip",
};

export const SUPPORTED_FILE_EXTENSIONS_LIST = Object.values(SUPPORTED_FILE_EXTENSIONS);

export const TEMPLATE_URLS = {
    EXCEL: "/templates/StreetLight_Zone_List_Template.xlsx",
    LINE_UNCALIBRATED: "/templates/uncalibrated/StreetLight_LineZoneSet_Shapefile_Template.zip",
    LINE_CALIBRATED: "/templates/calibrated/StreetLight_LineZoneSet_Shapefile_Template.zip",
    LINE_CALIBRATED_VEH_BIKE_PED:
        "/templates/calibrated/StreetLight_LineZoneSet_Shapefile_Veh_Bike_Ped_Template.zip",
    LINE_CALIBRATED_VEH_BIKE:
        "/templates/calibrated/StreetLight_LineZoneSet_Shapefile_Veh_Bike_Template.zip",
    LINE_CALIBRATED_VEH_PED:
        "/templates/calibrated/StreetLight_LineZoneSet_Shapefile_Veh_Ped_Template.zip",
    POLYGON_UNCALIBRATED: "/templates/uncalibrated/StreetLight_ZoneSet_Shapefile_Template.zip",
    POLYGON_CALIBRATED: "/templates/calibrated/StreetLight_ZoneSet_Shapefile_Template.zip",
    POLYGON_CALIBRATED_VEH_BIKE_PED:
        "/templates/calibrated/StreetLight_ZoneSet_Shapefile_Veh_Bike_Ped_Template.zip",
    POLYGON_CALIBRATED_VEH_BIKE:
        "/templates/calibrated/StreetLight_ZoneSet_Shapefile_Veh_Bike_Template.zip",
    POLYGON_CALIBRATED_VEH_PED:
        "/templates/calibrated/StreetLight_ZoneSet_Shapefile_Veh_Ped_Template.zip",
};

export const UPLOAD_ZONE_FIELDS = {
    ZONE_SET_NAME: "Zone Set Name",
    ZONE_KIND: "Zone Kind",
    ZONE_COUNT: "Zone count",
    NUMBER_OF_ZONE_SETS: "Number of Zone Sets",
    ZONE_NAME: "Zone Name",
    ZONE_ID: "Zone ID",
    ZONE_IS_PASS_THROUGH: "Zone is Pass-Through",
    DIRECTION: "Direction",
    BI_DIRECTIONALITY_FOR_PASS_THROUGH_ZONES:
        "Bi-Directionality for Pass-Through Zones with Direction",
    ROAD_TYPE: "Road type",
    GATE_LATITUDE: "Gate Latitude",
    GATE_LONGITUDE: "Gate Longitude",
    GATE_WIDTH: "Gate width",
};

export const REQUIRED_FIELDS = [
    "nameSelect",
    "isPassSelect",
    "directionSelect",
    "isBidiSelect",
    "gateLatSelect",
    "gateLngSelect",
    "gateSizeSelect",
] as const;

export const ZONE_SET_MODAL_TRAVEL_MODES = {
    VEHICLE: { id: "Vehicles", name: "Vehicles" },
    BICYCLE: { id: "Bicycles", name: "Bicycles" },
    PEDESTRIAN: { id: "Pedestrians", name: "Pedestrians" },
};
