import { useEffect, useMemo } from "react";
import { StlButton, StlMenu } from "@common/components";
import { LightboxService } from "@common/components/lightbox/lightbox.service";
import { TMenuControl, TMenuOption } from "@common/components/menu/menu";
import { ZONE_KINDS } from "@common/constants/zoneLibrary.constants";
import { ZONE_GEOM_TYPES } from "@common/constants/zoneSet.constants";
import { TZonesManagerZone } from "@common/features/zonesManager/zonesManager.types";
import { EDirectionTypes } from "@common/services/server/zonesApi.types";
import {
    faAngleDown,
    faArrowsAltH,
    faExchangeAlt,
    faLongArrowAltRight,
    faMinus,
    faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./zonesBulkActions.less";

type TBulkUpdateWarning = {
    title: string;
    content: string;
};

const confirmBulkUpdateZones = (warnings: TBulkUpdateWarning[], onConfirm: () => void) => {
    if (!warnings.length) {
        onConfirm();
        return;
    }

    const [warning, ...otherWarnings] = warnings;

    LightboxService.openConfirmation({
        title: warning.title,
        content: warning.content,
        okText: otherWarnings.length ? "Next" : "Continue",
        cancelText: "Cancel",
        onOK: () => confirmBulkUpdateZones(otherWarnings, onConfirm),
    });
};

const isZoneWithDirection = (zone: TZonesManagerZone) => {
    // @ts-ignore
    return zone.direction !== undefined && zone.direction !== null;
};

const ADD_AS_CALIBRATION_ACTION = {
    id: "addAsCalibration",
    label: (
        <>
            <FontAwesomeIcon icon={faPlus} />
            Add As Calibration Zone
        </>
    ),
};

export const ZONES_BULK_ACTIONS = {
    BI_DIRECTIONAL: {
        id: "biDirectional",
        label: (
            <>
                <FontAwesomeIcon icon={faArrowsAltH} />
                Set zones to bi-directional
            </>
        ),
        code: EDirectionTypes.bidi,
        actionName: "Bi-Direction",
        additionalName: "Bi-directional",
        getShouldSkipAction: (zone: TZonesManagerZone) =>
            zone.is_pass && !!zone.zone_id && zone.is_bidi,
        // Setting a zone to bi-di is allowed when zone is not yet bi-di and has direction set
        getIsActionAllowed: (zone: TZonesManagerZone) =>
            zone.is_pass && !!zone.zone_id && !zone.is_bidi && isZoneWithDirection(zone),
    },
    UNI_DIRECTIONAL: {
        id: "uniDirectional",
        label: (
            <>
                <FontAwesomeIcon icon={faLongArrowAltRight} />
                Set zones to uni-directional
            </>
        ),
        code: EDirectionTypes.unidi,
        actionName: "Uni-Direction",
        additionalName: "Uni-directional",
        getShouldSkipAction: (zone: TZonesManagerZone) =>
            zone.is_pass && !!zone.zone_id && !zone.is_bidi && isZoneWithDirection(zone),
        // Setting a zone to uni-di is allowed when zone is not yet uni-di and has direction set
        getIsActionAllowed: (zone: TZonesManagerZone) =>
            zone.is_pass && !!zone.zone_id && zone.is_bidi && isZoneWithDirection(zone),
    },
    NO_DIRECTION: {
        id: "nonDirectional",
        label: (
            <>
                <FontAwesomeIcon icon={faMinus} />
                Set zones to No direction
            </>
        ),
        code: EDirectionTypes.nodirection,
        actionName: "No Direction",
        additionalName: "No-direction",
        confirmTitle: "Warning",
        confirmMessage:
            "By applying a no direction setting, this cannot be undone through the zones grid. Are you sure you want to continue?",
        getShouldSkipAction: (zone: TZonesManagerZone) =>
            zone.is_pass &&
            !!zone.zone_id &&
            zone.geom_type === ZONE_GEOM_TYPES.POLYGON.id &&
            !isZoneWithDirection(zone),
        // Setting a zone to no direction is allowed when zone is polygon and has direction set
        getIsActionAllowed: (zone: TZonesManagerZone) =>
            zone.is_pass &&
            !!zone.zone_id &&
            zone.geom_type === ZONE_GEOM_TYPES.POLYGON.id &&
            //@ts-ignore TODO: Remove ignore after updating TCustomZone type
            zone.zone_kind_id !== ZONE_KINDS.GATE.id[0] &&
            isZoneWithDirection(zone),
    },
    REVERSE_DIRECTION: {
        id: "reverseDirection",
        label: (
            <>
                <FontAwesomeIcon icon={faExchangeAlt} />
                Reverse zone direction angle
            </>
        ),
        code: EDirectionTypes.reverse,
        actionName: "Reverse Direction",
        additionalName: "Reverse direction",
        getShouldSkipAction: (zone: TZonesManagerZone) => false,
        // Reverse zone direction is allowed when zone is uni-di and has direction set
        getIsActionAllowed: (zone: TZonesManagerZone) =>
            zone.is_pass && !!zone.zone_id && !zone.is_bidi && isZoneWithDirection(zone),
    },
};

const ModifyButton = (props: TMenuControl) => (
    <StlButton
        className="modify-button"
        variant="primary"
        size="sm"
        endIcon={<FontAwesomeIcon icon={faAngleDown} />}
        {...props}
    >
        Modify
    </StlButton>
);

type TProps = {
    selectedZones: Array<TZonesManagerZone>;
    removeZones: () => void;
    setIsExpandedToolbar?: (value: boolean) => void;
    bulkUpdateZones?: (zones: Array<TZonesManagerZone>, directionType: EDirectionTypes) => void;
    addAsCalibration?: (zones: Array<TZonesManagerZone>) => void;
    shouldAllowAddCalibration?: boolean;
    shouldHideZoneActionsMenu?: boolean;
};

export const ZonesBulkActions = ({
    selectedZones,
    bulkUpdateZones = () => {},
    addAsCalibration = () => {},
    setIsExpandedToolbar,
    removeZones,
    shouldAllowAddCalibration,
    shouldHideZoneActionsMenu = false,
}: TProps) => {
    const options = useMemo(() => {
        const availableActions = Object.values(ZONES_BULK_ACTIONS).reduce((res, option) => {
            const isActionAvailable = selectedZones.some(option.getIsActionAllowed);

            if (isActionAvailable) {
                res.push({ id: option.id, label: option.label });
            }
            return res;
        }, [] as TMenuOption[]);

        if (shouldAllowAddCalibration) {
            availableActions.push(ADD_AS_CALIBRATION_ACTION);
        }

        return availableActions;
    }, [selectedZones, shouldAllowAddCalibration]);

    const handleSelect = (optionId: string) => {
        if (optionId === ADD_AS_CALIBRATION_ACTION.id) {
            addAsCalibration(selectedZones);
            return;
        }

        const option = Object.values(ZONES_BULK_ACTIONS).find(action => action.id === optionId)!;
        const { allowedZonesForAction, skippedZonesCount } = selectedZones.reduce(
            (res, zone) => {
                if (option.getIsActionAllowed(zone)) {
                    res.allowedZonesForAction.push(zone);
                }
                if (option.getShouldSkipAction(zone)) {
                    res.skippedZonesCount++;
                }
                return res;
            },
            { allowedZonesForAction: [] as TZonesManagerZone[], skippedZonesCount: 0 },
        );
        const notAllowedZonesCount = selectedZones.length - allowedZonesForAction.length;

        const warnings = [] as TBulkUpdateWarning[];
        if (notAllowedZonesCount && notAllowedZonesCount !== skippedZonesCount) {
            warnings.push({
                title: `${option.actionName} unavailable for (${
                    notAllowedZonesCount - skippedZonesCount
                }) zones`,
                content: `This selection contains zones where ${option.additionalName.toLowerCase()} setting is unavailable. This bulk modification will not apply to these zones.`,
            });
        }
        if (skippedZonesCount) {
            warnings.push({
                title: `${option.actionName} setting will not apply to (${skippedZonesCount}) zones`,
                content: `This selection contains zones where ${option.additionalName.toLowerCase()} setting is already set. This bulk modification will not apply to these zones.`,
            });
        }
        if (optionId === ZONES_BULK_ACTIONS.NO_DIRECTION.id) {
            warnings.push({
                title: ZONES_BULK_ACTIONS.NO_DIRECTION.confirmTitle,
                content: ZONES_BULK_ACTIONS.NO_DIRECTION.confirmMessage,
            });
        }

        if (warnings.length) {
            confirmBulkUpdateZones(warnings, () =>
                bulkUpdateZones(allowedZonesForAction, option.code),
            );
        } else {
            bulkUpdateZones(allowedZonesForAction, option.code);
        }
    };

    useEffect(() => {
        if (!setIsExpandedToolbar) return;

        setIsExpandedToolbar(!!options.length);
    }, [options.length, setIsExpandedToolbar]);

    return (
        <div className="stl-zones-bulk-actions">
            {!!options.length && !shouldHideZoneActionsMenu && (
                <StlMenu
                    id="zones-bulk-actions-menu"
                    className="stl-zones-bulk-actions-menu"
                    Control={ModifyButton}
                    options={options}
                    transformOrigin={{ vertical: -30, horizontal: 125 }}
                    onSelect={handleSelect}
                />
            )}
            <StlButton className="remove-button" variant="primary" size="sm" onClick={removeZones}>
                Remove
            </StlButton>
        </div>
    );
};
