import React, { useState, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import { StlButton } from "@common/components/button/button";
import type { TButtonSize, TButtonVariant } from "@common/components/button/button";
import "./floatButton.less";

export type TStlFloatButtonHandlers = {
    close: () => void;
};

type TAction = {
    id: string;
    icon?: React.ReactNode | string;
    label: string;
    testid?: string;
    disabled?: boolean;
};

type TProps = {
    children: React.ReactNode;
    actions: TAction[];
    className?: string;
    size?: TButtonSize;
    variant?: TButtonVariant;
    handleSelect: (id: string) => void;
    onClose?: () => void;
    showTooltip?: boolean;
};

const INITIAL_STATE = {
    isActive: false,
};

export const StlFloatButton = forwardRef(
    (
        {
            children,
            actions,
            className,
            size = "sm",
            variant = "primary",
            handleSelect,
            onClose = () => {},
            showTooltip = false,
        }: TProps,
        ref,
    ) => {
        const [isActive, setIsActive] = useState(INITIAL_STATE.isActive);
        const isSingleAction = actions.length === 1;

        const onPrimaryBtnClick = () => {
            setIsActive(!isActive);

            if (isSingleAction) handleSelect(actions[0].id);
        };

        const close = () => {
            setIsActive(false);
            onClose();
        };

        // allows to inactive button from the parent component
        useImperativeHandle(ref, () => ({
            close: () => close(),
        }));

        return (
            <div className={classNames("stl-float-btn", className)}>
                <StlButton
                    onClick={onPrimaryBtnClick}
                    className={classNames("primary-action-btn", isActive && "active")}
                    variant={variant}
                    size={size}
                    ariaLabel={isSingleAction ? actions[0].label : "Choose action"}
                >
                    {children}
                </StlButton>
                {!isSingleAction && (
                    <div className="secondary-action-list">
                        {actions.map(action => (
                            <div
                                key={action.id}
                                className={classNames(
                                    "secondary-action-item",
                                    isActive && "active",
                                )}
                            >
                                <StlButton
                                    variant={variant}
                                    size={size}
                                    onClick={() => handleSelect(action.id)}
                                    className="secondary-action-btn"
                                    id={action.id}
                                    ariaLabel={action.label}
                                    tabIndex={isActive ? 0 : -1}
                                    testid={action.testid}
                                    disabled={!!action.disabled}
                                >
                                    {action.icon || action.label}
                                </StlButton>
                                {showTooltip && <span className="tooltip">{action.label}</span>}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    },
);
