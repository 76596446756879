import { MAP_STYLES } from "@app/viz3/csVisualization/components/content/components/csMap/mapStyles.constants";
import { ICSVizState, ECorridorSubGroupType } from "@app/viz3/csVisualization/state/csViz.types";

export const CS_VIZ_INITIAL_STATE: ICSVizState = {
    segmentLayers: null,
    statsAndScores: null,
    map: {
        layerConfigurations: {
            colors: {
                high: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HIGH,
                mid: "",
                low: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.LOW,
                hover: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HOVER,
            },
            directionColors: MAP_STYLES.ROAD_DIRECTION_COLORS,
        },
    },
    filterGroups: {
        corridorGroups: {},
        corridorGroupIds: [],
        planningGroupIdsToOperationalGroupsIds: {},
    },
    corridorGroupCreation: {
        map: {
            mapError: null,
            hoveredZone: null,
            selectedToolId: null,
        },
        corridorGroups: {},
        selectedSegmentIds: [],
        groupSelectionMode: ECorridorSubGroupType.None,
        mainCorridorGroupId: null,
        planningGroupsCounter: 1,
        operationalGroupsCounter: 1,
        selectedCorridorGroupIds: [],
        selectedProjectRouteGroupId: null,
        corridorGroupBeingUpdatedId: null,
        isCorridorGroupCreationOpened: false,
    },
};
