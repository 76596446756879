const M_TO_FEET_RATIO = 3.28084;
const M_TO_MI_RATIO = 1 / 1609;
const M_TO_KM_RATIO = 1 / 1000;

export const DISTANCE_MEASUREMENT_OPTIONS = {
    feet: {
        id: "feet",
        label: "ft",
        name: "Feet",
        decimalPrecision: 0,
        handler: (value: number) => value * M_TO_FEET_RATIO,
    },
    mi: {
        id: "mi",
        label: "mi",
        name: "Miles",
        decimalPrecision: 2,
        handler: (value: number) => value * M_TO_MI_RATIO,
    },
    m: {
        id: "m",
        label: "m",
        name: "Meters",
        decimalPrecision: 0,
        handler: (value: number) => value,
    },
    km: {
        id: "km",
        label: "km",
        name: "Kilometers",
        decimalPrecision: 2,
        handler: (value: number) => value * M_TO_KM_RATIO,
    },
} as const;

export const DISTANCE_MEASUREMENT_OPTIONS_LIST = Object.values(DISTANCE_MEASUREMENT_OPTIONS);

const SQ_M_TO_SQ_FEET_RATIO = 10.7639;
const SQ_M_TO_SQ_MI_RATIO = 1 / 2589988.1;
const SQ_M_TO_SQ_KM_RATIO = 1 / 1000000;

export const AREA_MEASUREMENT_OPTIONS = {
    feet: {
        id: "feet",
        label: "sq ft",
        name: "Square Feet",
        handler: (value: number) => value * SQ_M_TO_SQ_FEET_RATIO,
        decimalPrecision: 0,
    },
    mi: {
        id: "mi",
        label: "sq mi",
        name: "Square Miles",
        handler: (value: number) => value * SQ_M_TO_SQ_MI_RATIO,
        decimalPrecision: 2,
    },
    m: {
        id: "m",
        label: "sq m",
        name: "Square Meters",
        handler: (value: number) => value,
        decimalPrecision: 0,
    },
    km: {
        id: "km",
        label: "sq km",
        name: "Square Kilometers",
        handler: (value: number) => value * SQ_M_TO_SQ_KM_RATIO,
        decimalPrecision: 2,
    },
} as const;

export const AREA_MEASUREMENT_OPTIONS_LIST = Object.values(AREA_MEASUREMENT_OPTIONS);

export const MEASUREMENT_MODES = {
    area: {
        id: "area",
        name: "Area",
        drawMode: "draw_polygon",
        options: AREA_MEASUREMENT_OPTIONS,
    },
    distance: {
        id: "distance",
        name: "Distance",
        drawMode: "draw_line_string",
        options: DISTANCE_MEASUREMENT_OPTIONS,
    },
} as const;

export const MEASUREMENT_MODES_LIST = Object.values(MEASUREMENT_MODES);
