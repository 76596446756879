import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { IFilterOptions } from "@app/bigBang/dashboard/state/dashboard.types";
import {
    DASHBOARD_INITIAL_STATE,
    IAnalysisTab,
    IDashboardState,
    IPendingExportRequests,
    ISummaryTab,
} from "./dashboard.state";

export const { actions, reducer } = createSlice({
    name: "dashboard",
    initialState: DASHBOARD_INITIAL_STATE,
    reducers: {
        setFiltersOptions: (state, action: PayloadAction<IFilterOptions>) => {
            state.filtersOptions = action.payload;
        },
        setSummaryTabFilters: (state, action: PayloadAction<Partial<ISummaryTab>>) => {
            state.summaryTab.filters = {
                ...state.summaryTab.filters,
                ...action.payload,
            };
        },
        setSummaryTabExcludeStlUsers: (state, action: PayloadAction<boolean>) => {
            state.summaryTab.shouldExcludeStlUsers = action.payload;
        },
        setUsersTabExcludeStlUsers: (state, action: PayloadAction<boolean>) => {
            state.usersTab.shouldExcludeStlUsers = action.payload;
        },
        setAnalysisTabExcludeStlUsers: (
            state,
            action: PayloadAction<IAnalysisTab["shouldExcludeStlUsers"]>,
        ) => {
            state.analysisTab.shouldExcludeStlUsers = action.payload;
        },
        setAnalysisTabActiveView: (state, action: PayloadAction<IAnalysisTab["activeView"]>) => {
            state.analysisTab.activeView = action.payload;
        },
        setOrgDetailsAnalysisTabExcludeStlUsers: (state, action: PayloadAction<boolean>) => {
            state.orgDetails.analysisTab.shouldExcludeStlUsers = action.payload;
        },
        setOrgDetailsUserTabExcludeStlUsers: (state, action: PayloadAction<boolean>) => {
            state.orgDetails.usersTab.shouldExcludeStlUsers = action.payload;
        },
        setAnalysisMapState: (state, action: PayloadAction<Partial<IAnalysisTab["mapState"]>>) => {
            state.analysisTab.mapState = {
                ...state.analysisTab.mapState,
                ...action.payload,
            };
        },
        setAnalysisMapLoaded: (state, action: PayloadAction<boolean>) => {
            state.analysisTab.mapState.loaded = action.payload;
        },
        setAnalysisMapDataLoaded: (state, action: PayloadAction<boolean>) => {
            state.analysisTab.mapState.dataLoaded = action.payload;
        },
        setPendingExportRequests: (
            state,
            action: PayloadAction<Partial<IPendingExportRequests>>,
        ) => {
            state.pendingExportRequests = {
                ...state.pendingExportRequests,
                ...action.payload,
            };
        },
        setDashboardState: (state, action: PayloadAction<IDashboardState>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        resetDashboardState: () => {
            return DASHBOARD_INITIAL_STATE;
        },
    },
});

export const dashboardReducer = reducer;
