export class EventManager {
    list = new Map();

    subscribe(event: string, callback: any) {
        if (!this.list.has(event)) {
            this.list.set(event, [callback]);
        } else {
            this.list.get(event).push(callback);
        }

        return () => this._removeListener(event, callback);
    }

    unsubscribe(event: string, callback?: any) {
        if (callback) {
            this._removeListener(event, callback);
        } else {
            this.list.delete(event);
        }
    }

    emit(event: string, args?: any) {
        if (this.list.has(event)) this.list.get(event).forEach((callback: any) => callback(args));
    }

    _removeListener(event: string, callback: any) {
        const listeners = this.list.get(event).filter((listener: any) => listener !== callback);
        this.list.set(event, listeners);
    }
}
