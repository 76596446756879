import DrawLineStringMode from "@mapbox/mapbox-gl-draw/src/modes/draw_line_string";
import * as Constants from "@mapbox/mapbox-gl-draw/src/constants";

// Copy of https://github.com/mapbox/mapbox-gl-draw/blob/main/src/modes/draw_line_string.js
// The difference with DrawLineString is that we stop drawing once user entered two points
// One more difference is that we tell when we start drawing the gate, so that we can
// change feature state to style it
export const DrawTwoPointLineMode = {
    ...DrawLineStringMode,
    clickAnywhere(state, e) {
        this.updateUIClasses({ mouse: Constants.cursors.ADD });
        state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);

        if (state.direction === "forward") {
            state.currentVertexPosition++;
            state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
        } else {
            state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
        }

        // Mapbox Draw adds next point with the same coordinates, so the line with two points will
        // consist of three points, where the last two points have the same coordinates.
        if (state.line.coordinates.length === 3) {
            this.changeMode(Constants.modes.SIMPLE_SELECT, {
                featureIds: [state.line.id],
            });
        } else {
            // we just started drawing, let's tell the world about it
            this.map.fire("draw.start", {
                feature: state.line.toGeoJSON(),
            });
        }
    },
};
