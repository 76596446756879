import { ANALYSIS_TYPES } from "@common/constants/analysis.constants";
import { AADT_VIZ_DEFAULTS } from "@app/viz3/aadtVisualization/state/aadtViz.constants";
import { OD_VIZ_DEFAULTS } from "@app/viz3/odVisualization/state/odViz.constants";
import { ODG_VIZ_DEFAULTS } from "@app/viz3/odgVisualization/state/odgViz.constants";
import { ODMF_VIZ_DEFAULTS } from "@app/viz3/odmfVisualization/state/odmfViz.constants";
import { NOD_VIZ_DEFAULTS } from "@app/viz3/nodVisualization/state/nodViz.constants";
import { NP_SPOT_VIZ_DEFAULTS } from "@app/viz3/npSpotVisualization/state/npSpotViz.constants";
import { SEGMENT_VIZ_DEFAULTS } from "@app/viz3/segmentVisualization/state/segmentViz.constants";
import { TMC_VIZ_DEFAULTS } from "@app/viz3/tmcVisualization/state/tmcViz.constants";
import { TROD_VIZ_DEFAULTS } from "@app/viz3/trodVisualization/state/trodViz.constants";
import { TRZA_VIZ_DEFAULTS } from "@app/viz3/trzaVisualization/state/trzaViz.constants";
import { AT_VIZ_DEFAULTS } from "@app/viz3/atVisualization/state/atViz.constants";
import { CT_VIZ_DEFAULTS } from "@app/viz3/ctVisualization/state/ctViz.constants";
import { CS_VIZ_DEFAULTS } from "@root/src/app/viz3/csVisualization/state/csViz.constants";
import { RV_VIZ_DEFAULTS } from "@app/viz3/rvVisualization/state/rvViz.constants";
import { ZA_VIZ_DEFAULTS } from "@app/viz3/zaVisualization/state/zaViz.constants";
import { AADT_VIZ_ACTIONS } from "@app/viz3/aadtVisualization/state/aadtViz.actionTypes";
import { OD_VIZ_ACTIONS } from "@app/viz3/odVisualization/state/odViz.actionTypes";
import { ZA_VIZ_ACTIONS } from "@app/viz3/zaVisualization/state/zaViz.actionTypes";
import { ODG_VIZ_ACTIONS } from "@app/viz3/odgVisualization/state/odgViz.actionTypes";
import { ODMF_VIZ_ACTIONS } from "@app/viz3/odmfVisualization/state/odmfViz.actionTypes";
import { NOD_VIZ_ACTIONS } from "@app/viz3/nodVisualization/state/nodViz.actionTypes";
import { NP_SPOT_VIZ_ACTIONS } from "@app/viz3/npSpotVisualization/state/npSpotViz.actionTypes";
import { SEGMENT_VIZ_ACTIONS } from "@app/viz3/segmentVisualization/state/segmentViz.actionTypes";
import { TMC_VIZ_ACTIONS } from "@app/viz3/tmcVisualization/state/tmcViz.actionTypes";
import { TROD_VIZ_ACTIONS } from "@app/viz3/trodVisualization/state/trodViz.actionTypes";
import { TRZA_VIZ_ACTIONS } from "@app/viz3/trzaVisualization/state/trzaViz.actionTypes";

export const VIZ_CODES = {
    AADT: "aadt",
    AT: "at",
    CT: "ct",
    CS: "cs",
    OD: "od",
    ODG: "odg",
    ODMF: "odmf",
    NOD: "nod",
    NP_SPOT: "npSpot",
    RV: "rv",
    SEGMENT: "segment",
    TMC: "tmc",
    TROD: "trod",
    TRZA: "trza",
    ZA: "za",
};

const AADT_CONFIG = {
    code: VIZ_CODES.AADT,
    path: "aadtVisualization/aadtVisualization",
    defaults: AADT_VIZ_DEFAULTS,
    actions: AADT_VIZ_ACTIONS,
};

export const VISUALIZATION_BY_ANALYSIS_TYPE = {
    [ANALYSIS_TYPES.OD.id]: {
        code: VIZ_CODES.OD,
        path: "odVisualization/odVisualization",
        defaults: OD_VIZ_DEFAULTS,
        actions: OD_VIZ_ACTIONS,
    },
    [ANALYSIS_TYPES.ZA.id]: {
        code: VIZ_CODES.ZA,
        path: "zaVisualization/zaVisualization",
        defaults: ZA_VIZ_DEFAULTS,
        actions: ZA_VIZ_ACTIONS,
    },
    [ANALYSIS_TYPES.ODG.id]: {
        code: VIZ_CODES.ODG,
        path: "odgVisualization/odgVisualization",
        defaults: ODG_VIZ_DEFAULTS,
        actions: ODG_VIZ_ACTIONS,
    },
    [ANALYSIS_TYPES.ODMF.id]: {
        code: VIZ_CODES.ODMF,
        path: "odmfVisualization/odmfVisualization",
        defaults: ODMF_VIZ_DEFAULTS,
        actions: ODMF_VIZ_ACTIONS,
    },
    [ANALYSIS_TYPES.AADT2016.id]: AADT_CONFIG,
    [ANALYSIS_TYPES.AADT2017.id]: AADT_CONFIG,
    [ANALYSIS_TYPES.AADT2018.id]: AADT_CONFIG,
    [ANALYSIS_TYPES.AADT2019.id]: AADT_CONFIG,
    [ANALYSIS_TYPES.AADT2020.id]: AADT_CONFIG,
    [ANALYSIS_TYPES.AADT2021.id]: AADT_CONFIG,
    [ANALYSIS_TYPES.AADT2022.id]: AADT_CONFIG,
    [ANALYSIS_TYPES.AADT2023.id]: AADT_CONFIG,
    [ANALYSIS_TYPES.SEGMENT.id]: {
        code: VIZ_CODES.SEGMENT,
        path: "segmentVisualization/segmentVisualization",
        defaults: SEGMENT_VIZ_DEFAULTS,
        actions: SEGMENT_VIZ_ACTIONS,
    },
    [ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.id]: {
        code: VIZ_CODES.SEGMENT,
        path: "segmentVisualization/segmentVisualization",
        defaults: SEGMENT_VIZ_DEFAULTS,
        actions: SEGMENT_VIZ_ACTIONS,
    },
    [ANALYSIS_TYPES.NETWORK_OD.id]: {
        code: VIZ_CODES.NOD,
        path: "nodVisualization/nodVisualization",
        defaults: NOD_VIZ_DEFAULTS,
        actions: NOD_VIZ_ACTIONS,
    },
    [ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.id]: {
        code: VIZ_CODES.NP_SPOT,
        path: "npSpotVisualization/npSpotVisualization",
        defaults: NP_SPOT_VIZ_DEFAULTS,
        actions: NP_SPOT_VIZ_ACTIONS,
    },
    [ANALYSIS_TYPES.TMC.id]: {
        code: VIZ_CODES.TMC,
        path: "tmcVisualization/tmcVisualization",
        defaults: TMC_VIZ_DEFAULTS,
        actions: TMC_VIZ_ACTIONS,
    },
    [ANALYSIS_TYPES.TROD.id]: {
        code: VIZ_CODES.TROD,
        path: "trodVisualization/trodVisualization",
        defaults: TROD_VIZ_DEFAULTS,
        actions: TROD_VIZ_ACTIONS,
    },
    [ANALYSIS_TYPES.TRZA.id]: {
        code: VIZ_CODES.TRZA,
        path: "trzaVisualization/trzaVisualization",
        defaults: TRZA_VIZ_DEFAULTS,
        actions: TRZA_VIZ_ACTIONS,
    },
    [ANALYSIS_TYPES.ATM.id]: {
        code: VIZ_CODES.AT,
        path: "atVisualization/atVisualization",
        defaults: AT_VIZ_DEFAULTS,
        actions: {},
    },
    [ANALYSIS_TYPES.CT.id]: {
        code: VIZ_CODES.CT,
        path: "ctVisualization/ctVisualization",
        defaults: CT_VIZ_DEFAULTS,
        actions: {},
    },
    [ANALYSIS_TYPES.CS.id]: {
        code: VIZ_CODES.CS,
        path: "csVisualization/csVisualization",
        defaults: CS_VIZ_DEFAULTS,
        actions: {},
    },
    [ANALYSIS_TYPES.RV.id]: {
        code: VIZ_CODES.RV,
        path: "rvVisualization/rvVisualization",
        defaults: RV_VIZ_DEFAULTS,
        actions: {},
    },
};

export const VISUALIZATION_BY_ANALYSIS_TYPE_LIST = Object.values(VISUALIZATION_BY_ANALYSIS_TYPE);

// Need for passing tests, will be removed after migrating viz state on TS.
export const ANALYSIS_CODES_MIGRATED_ON_TS = [
    VIZ_CODES.AT,
    VIZ_CODES.CT,
    VIZ_CODES.RV,
    VIZ_CODES.CS,
];
