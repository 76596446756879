import { ReactNode } from "react";
import classnames from "classnames";
import { StlButton, StlLightbox } from "@common/components";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import "./notificationDialog.less";

type TProps = Omit<DialogProps, "open"> & {
    onHide: () => void;
    onCancel?: () => void;
    testid?: string;
    ariaLabel?: string;
    description?: string;
    show: boolean;
    centered?: boolean;
    hideHeader?: boolean;
    hideCloseButton?: boolean;
    footer?: ReactNode;
    content?: ReactNode;
    closeText?: ReactNode;
    titleContent?: ReactNode;
};

export const NotificationDialog = ({
    title,
    content,
    show,
    onHide,
    closeText = "Close",
    className,
    ...props
}: TProps) => {
    return (
        <StlLightbox
            className={classnames("stl-notification-dialog-modal", className)}
            show={show}
            onHide={onHide}
            title={title}
            body={content}
            footer={
                <StlButton type="button" variant="primary" size="sm" onClick={onHide}>
                    {closeText}
                </StlButton>
            }
            {...props}
        />
    );
};
