import { OSM_LAYERS } from "@common/components/baseMap/mapLayers/mapLayers.constants";
import {
    BUS_ZONE_KINDS,
    RAIL_ZONE_KINDS,
    ZONE_KINDS,
} from "@common/constants/zoneLibrary.constants";
import { getNestedRoadTypes } from "@common/features/zonesManager/components/spotCounter/spotCounter.helpers";

import {
    DEFAULT_BUS_ZONES_BUFFER_RADIUS,
    DEFAULT_STANDARD_AREA,
    MANAGER_MODES,
    OSM_NETWORK_TYPES,
    OSM_SEGMENT_SPLIT_TYPES,
    ROAD_TYPES,
    ZONE_ROLES,
    ZONE_TYPES,
    ZONES_MANAGER_CONTROLS,
} from "./zonesManager.constants";

export const ZONES_MANAGER_INITIAL_STATE = {
    zoneLibraryType: {
        id: ZONE_TYPES.OSM_LINE_SEGMENTS.id,
        filters: {
            segmentSplitType: OSM_SEGMENT_SPLIT_TYPES.TERTIARY.id,
        },
    },
    zoneLibraryFilters: {
        [ZONE_TYPES.OSM_LINE_SEGMENTS.id]: {
            networkType: OSM_NETWORK_TYPES.VEHICLE,
            segmentSplitType: OSM_SEGMENT_SPLIT_TYPES.TERTIARY.id,
            roads: [],
        },
        [ZONE_TYPES.STANDARD_AREAS.id]: {
            selectedStandardArea: DEFAULT_STANDARD_AREA.id,
        },
        [ZONE_TYPES.TRANSIT.id]: {
            selectedTransitZoneKind: ZONE_KINDS.BUS.code,
            [ZONE_KINDS.RAIL.code]: {
                selectedAgencies: [],
                selectedLines: [],
                selectedKinds: {
                    [RAIL_ZONE_KINDS.SEGMENTS.id]: true,
                    [RAIL_ZONE_KINDS.STATIONS.id]: true,
                },
            },
            [ZONE_KINDS.BUS.code]: {
                bufferRadius: DEFAULT_BUS_ZONES_BUFFER_RADIUS,
                selectedAgencies: [],
                selectedLines: [],
                selectedKinds: {
                    [BUS_ZONE_KINDS.ROUTES.id]: true,
                },
            },
        },
    },
    zoneVintage: {},
    zoneRole: ZONE_ROLES.ZONE_SET.accessor,
    selectedZone: null,
    clickedBusZones: [],
    reuseZonesConfig: {
        excludedZoneSetId: null,
        excludedZones: [],
        allowedZonesConditions: [],
    },
    reuseZonesModal: {
        isLoading: false,
        hoveredZone: null,
        selectedObjectId: null,
        selectedAnalysis: null,
        selectedZoneSet: null,
        selectedZones: [],
        hiddenZoneRoles: [],
    },
    uiStates: {
        mapMode: MANAGER_MODES.VIEW_MAP,
        editableFeature: null,
        isEditDirection: false,
        isEditCalibration: false,
        showZLZones: true,
        activeControl: ZONES_MANAGER_CONTROLS.ZONE_TYPES_PICKER,
        zoneToZoom: null,
    },
    uploadZonesModal: {
        zonesUploadData: null,
        isLoading: false,
    },
    spotCounterMapLayers: {
        osmLayersCategories: {
            [OSM_LAYERS.OSM_VEHICLE.code]: getNestedRoadTypes(OSM_LAYERS.OSM_VEHICLE.code).reduce(
                (res, nestedRoadType) => {
                    if (nestedRoadType.id !== ROAD_TYPES.SERVICE.id) {
                        res.push(nestedRoadType.id);
                    }

                    return res;
                },
                [],
            ),
        },
    },
};
