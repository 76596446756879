import {
    VIZ_ZONE_TYPES,
    VIZ_MAP_LAYERS,
    BASE_VIZ_DEFAULTS,
} from "@app/viz3/base/state/baseViz.constants";

export const TROD_ZONE_TYPES = {
    ORIGIN: VIZ_ZONE_TYPES.ORIGIN,
    DESTINATION: VIZ_ZONE_TYPES.DESTINATION,
    ROUTE_SEGMENT: VIZ_ZONE_TYPES.SEGMENTS,
} as const;
export const TROD_ZONE_TYPES_LIST = Object.values(TROD_ZONE_TYPES);
export type TTRODZoneTypeKey = keyof typeof TROD_ZONE_TYPES;
export type TTRODZoneType = typeof TROD_ZONE_TYPES[keyof typeof TROD_ZONE_TYPES];

export const TROD_LAYERS = {
    ORIGIN: {
        code: "origin",
        label: "Origins",
        hasConfiguration: true,
    },
    DESTINATION: {
        code: "destination",
        label: "Destinations",
        hasConfiguration: true,
    },
    ROUTE_SEGMENT: {
        code: "route_segment",
        label: "Route Segments",
        hasConfiguration: true,
    },
    ...VIZ_MAP_LAYERS,
} as const;
export type TTRODLayerType = keyof typeof TROD_LAYERS;
export type TTRODLayer = typeof TROD_LAYERS[keyof typeof TROD_LAYERS];

export const DEFAULT_MAP_LAYERS_VISIBILITY = {
    [TROD_LAYERS.ROUTE_SEGMENT.code]: true,
    [TROD_LAYERS.ORIGIN.code]: true,
    [TROD_LAYERS.DESTINATION.code]: true,
    [TROD_LAYERS.BASE.code]: true,
    [TROD_LAYERS.UNSELECTED.code]: false,
    [TROD_LAYERS.LABEL.code]: true,
    [TROD_LAYERS.LEGEND.code]: true,
} as const;

export const TROD_DEFAULT_TRIM_RANGE = [1, 100];

export const TROD_VIZ_DEFAULTS = {
    filters: {
        ...BASE_VIZ_DEFAULTS.filters,
        [TROD_ZONE_TYPES.ROUTE_SEGMENT.filterName]: [],
    },
    visualization: {
        ...BASE_VIZ_DEFAULTS.visualization,
        dataTrimRange: TROD_DEFAULT_TRIM_RANGE,
        mapLayersVisibility: DEFAULT_MAP_LAYERS_VISIBILITY,
    },
} as const;
export type TTRODVizDefaultKey = keyof typeof TROD_VIZ_DEFAULTS;
export type TTRODVizDefault = typeof TROD_VIZ_DEFAULTS[keyof typeof TROD_VIZ_DEFAULTS];

export const TRAFFIC_BEHAVIORS = {
    zoneType: VIZ_ZONE_TYPES.SEGMENTS,
} as const;
export type TTrafficBehaviorKey = keyof typeof TRAFFIC_BEHAVIORS;
export type TTrafficBehavior = typeof TRAFFIC_BEHAVIORS[keyof typeof TRAFFIC_BEHAVIORS];
