import { GENERAL_INITIAL_STATE } from "./general.state";
import { GENERAL_ACTIONS } from "./general.actionTypes";

const resetGeneralReducerHandler = () => {
    return GENERAL_INITIAL_STATE;
};

const updateGeneralStateHandler = (state, { data }) => {
    return {
        ...state,
        ...data,
    };
};

const setInitialGeneralReducerHandler = (state, { data }) => {
    return {
        ...state,
        ...data,
        uiStates: {
            ...state.uiStates,
            ...data.uiStates,
        },
    };
};

const setAnalysisTypeCodeHandler = (state, { data }) => {
    return {
        ...state,
        analysisTypeCode: data.analysisTypeCode,
    };
};

const updateSaVersionIdHandler = (state, { data }) => {
    return {
        ...state,
        analysisVersionId: data.analysisVersionId,
    };
};

const showPreviewAnalysisHandler = (state, { data }) => {
    return {
        ...state,
        uiStates: {
            ...state.uiStates,
            showPreviewAnalysis: data.isOpen,
        },
    };
};

const setIs15MinuteBinsActive = (state, { data }) => {
    return {
        ...state,
        uiStates: {
            ...state.uiStates,
            is15MinuteBinsModeActive: data.is15MinuteBinsModeActive,
        },
    };
};

const setZonesMode = (state, { data }) => {
    return {
        ...state,
        uiStates: {
            ...state.uiStates,
            zonesMode: data.zonesMode,
        },
    };
};

const setScreenMode = (state, { data }) => {
    return {
        ...state,
        uiStates: {
            ...state.uiStates,
            screenMode: data.screenMode,
        },
    };
};

const setActiveTab = (state, { data }) => {
    return {
        ...state,
        uiStates: {
            ...state.uiStates,
            activeTab: data.activeTab,
        },
    };
};

const setIsAnalysisTypeChangedHandler = (state, { data }) => {
    return {
        ...state,
        uiStates: {
            ...state.uiStates,
            isAnalysisTypeChanged: data.value,
        },
    };
};

const setIsShowAllAnalysesAllowedHandler = (state, { data }) => {
    return {
        ...state,
        uiStates: {
            ...state.uiStates,
            isShowAllAnalysesAllowed: data.value,
        },
    };
};

const showAnalysisSizeReviewModal = (state, { data }) => {
    return {
        ...state,
        runAnalysis: {
            ...state.runAnalysis,
            sizeReviewData: data.sizeReviewData,
        },
    };
};

const resetAnalysisSizeReviewData = state => {
    return {
        ...state,
        runAnalysis: {
            ...state.runAnalysis,
            sizeReviewData: null,
        },
    };
};

const showZoneQuotaModal = (state, { data }) => {
    return {
        ...state,
        runAnalysis: {
            ...state.runAnalysis,
            uniqueZoneData: data.uniqueZoneData,
        },
    };
};

const resetUniqueZoneData = state => {
    return {
        ...state,
        runAnalysis: {
            ...state.runAnalysis,
            uniqueZoneData: null,
        },
    };
};

const saveAnalysisSuccessHandler = (state, { data }) => {
    return {
        ...state,
        runAnalysis: {
            submitted: true,
            status: "ok",
            isDraft: data.isDraft,
        },
    };
};

const saveAnalysisResetHandler = state => {
    // status and error are not reset so that UI is not accidentally updated.
    // This strategy avoids the complexity of having separate actions to
    // reset submit status and to clear status.
    return {
        ...state,
        runAnalysis: {
            ...state.runAnalysis,
            submitted: false,
        },
    };
};

const setAnalysisActionErrorHandler = (state, { data }) => {
    return {
        ...state,
        runAnalysis: {
            submitted: true,
            status: "error",
            error: data.error,
        },
    };
};

const setAnalysisValidationHandler = (state, { data }) => {
    return {
        ...state,
        validation: {
            ...state.validation,
            isInvalid: data.isInvalid,
        },
    };
};

const setUiStateHandler = (state, { data }) => {
    return {
        ...state,
        uiStates: {
            ...state.uiStates,
            ...data,
        },
    };
};

const setProjectFolderHandler = (state, { data }) => {
    return {
        ...state,
        projectFolder: data.projectFolder,
    };
};

const ACTION_HANDLERS = {
    [GENERAL_ACTIONS.SET_ANALYSIS_TYPE_CODE]: setAnalysisTypeCodeHandler,
    [GENERAL_ACTIONS.SET_ANALYSIS_VALIDATION]: setAnalysisValidationHandler,
    [GENERAL_ACTIONS.SHOW_PREVIEW_ANALYSIS]: showPreviewAnalysisHandler,
    [GENERAL_ACTIONS.SAVE_ANALYSIS_SUCCESS]: saveAnalysisSuccessHandler,
    [GENERAL_ACTIONS.SAVE_ANALYSIS_RESET]: saveAnalysisResetHandler,
    [GENERAL_ACTIONS.SET_IS_15_MINUTE_BINS_MODE_ACTIVE]: setIs15MinuteBinsActive,
    [GENERAL_ACTIONS.SET_ZONES_MODE]: setZonesMode,
    [GENERAL_ACTIONS.SET_SCREEN_MODE]: setScreenMode,
    [GENERAL_ACTIONS.SET_ACTIVE_TAB]: setActiveTab,
    [GENERAL_ACTIONS.SHOW_ZONE_QUOTA_MODAL]: showZoneQuotaModal,
    [GENERAL_ACTIONS.RESET_UNIQUE_ZONE_DATA]: resetUniqueZoneData,
    [GENERAL_ACTIONS.SHOW_ANALYSIS_SIZE_REVIEW_MODAL]: showAnalysisSizeReviewModal,
    [GENERAL_ACTIONS.RESET_ANALYSIS_SIZE_REVIEW_DATA]: resetAnalysisSizeReviewData,
    [GENERAL_ACTIONS.RESET_REDUCER]: resetGeneralReducerHandler,
    [GENERAL_ACTIONS.UPDATE_STATE]: updateGeneralStateHandler,
    [GENERAL_ACTIONS.SET_INITIAL_DATA]: setInitialGeneralReducerHandler,
    [GENERAL_ACTIONS.SET_ANALYSIS_ACTION_ERROR]: setAnalysisActionErrorHandler,
    [GENERAL_ACTIONS.SET_ANALYSIS_TYPE_CHANGED]: setIsAnalysisTypeChangedHandler,
    [GENERAL_ACTIONS.SET_SHOW_ALL_ANALYSES_ALLOWED]: setIsShowAllAnalysesAllowedHandler,
    [GENERAL_ACTIONS.SET_GENERAL_UI_STATE]: setUiStateHandler,
    [GENERAL_ACTIONS.UPDATE_ANALYSIS_VERSION_ID]: updateSaVersionIdHandler,
    [GENERAL_ACTIONS.SET_PROJECT_FOLDER]: setProjectFolderHandler,
};

export const generalReducer = (state = GENERAL_INITIAL_STATE, action) => {
    if (ACTION_HANDLERS[action.type]) {
        return ACTION_HANDLERS[action.type](state, action);
    }

    return state;
};
