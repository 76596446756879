/*eslint jsx-a11y/click-events-have-key-events:off,react/button-has-type:off, jsx-a11y/no-static-element-interactions:off */
import { ReactNode, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faCaretRight,
    faExclamationTriangle,
    faAngleDown,
    faAngleRight,
} from "@fortawesome/pro-solid-svg-icons";
import "./expandableSection.less";

type TProps = {
    title: ReactNode;
    testid?: string;
    isOpen?: boolean;
    warning?: string;
    className?: string;
    children?: ReactNode;
    summary?: string | null;
    variant?: "box" | "flat";
    headerContent?: ReactNode;
    onHeaderMouseEnter?: () => void;
    onHeaderMouseLeave?: () => void;
    toggle?: (value?: boolean) => void;
};

export const StlExpandableSection = ({ variant = "box", ...props }: TProps) => {
    const [isOpen, setIsOpen] = useState(props.isOpen);

    const toggleSectionState = () => {
        setIsOpen(!isOpen);

        if (props.toggle) props.toggle(!isOpen);
    };

    const getToggleIcon = () => {
        if (isOpen) {
            return variant === "box" ? faCaretDown : faAngleDown;
        } else {
            return variant === "box" ? faCaretRight : faAngleRight;
        }
    };

    const shouldShowWarningIcon = !isOpen && !!props.warning;

    const warningIcon = (
        <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="warning-icon"
            title={props.warning}
        />
    );

    return (
        <div
            className={classNames(
                "stl-expandable-section",
                variant === "flat" && "flat",
                props.className,
            )}
            data-testid={props.testid}
        >
            <button
                className={classNames("panel-header", {
                    "invalid-warning": shouldShowWarningIcon,
                    expanded: isOpen,
                })}
                onClick={toggleSectionState}
                onMouseEnter={props.onHeaderMouseEnter}
                onMouseLeave={props.onHeaderMouseLeave}
                data-testid="expandableSection"
                aria-expanded={isOpen}
                tabIndex={0}
            >
                <h2>
                    <FontAwesomeIcon icon={getToggleIcon()} className={`toggle-icon-${variant}`} />
                    {props.title}
                    {shouldShowWarningIcon && warningIcon}
                </h2>
                {!isOpen && (
                    <div className="summary-text" title={props.summary ?? undefined}>
                        {props.summary}
                    </div>
                )}
            </button>
            {isOpen && <div className="panel-body">{props.children}</div>}
        </div>
    );
};

StlExpandableSection.defaultProps = {
    onHeaderMouseEnter: () => {},
    onHeaderMouseLeave: () => {},
};
StlExpandableSection.displayName = "StlExpandableSection";
