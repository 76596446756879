import { StlIconPopover } from "@common/components";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const STUDY_TYPE_HELP_ICON = (
    <StlIconPopover
        title="Please assign a Type to your Study"
        content={
            <>
                <p>
                    <b>Request for Proposal:</b> Select if your exploratory work is to prepare for
                    a proposal such as RFP, RFI, RFQ, RFEI, etc. that you intend to convert to paid
                    account if you win the RFP.
                </p>
                <p>
                    <b>Business Development:</b> Select if you are researching for new ways of
                    solving a transportation challenge.
                </p>
                <p>
                    <b>Marketing Materials:</b> Select if you are using the study to demonstrate
                    your firm’s thought leadership and expertise with Big Data in marketing
                    initiatives.
                </p>
            </>
        }
    />
);

export const STUDY_NAME_HELP_ICON = (
    <StlIconPopover content="Provide a meaningful name for the exploratory work." />
);

export const STUDY_END_DATE_HELP_ICON = (
    <FontAwesomeIcon id="study-end-date-info-icon" className="info-icon" icon={faInfoCircle} />
);

export const STUDY_ID_HELP_ICON = (
    <StlIconPopover content="Provide an identification number for the study. For example, “RFPMN4532”." />
);

export const END_CLIENT_HELP_ICON = (
    <StlIconPopover content="Provide the name of the end client you are scoping for. It helps track all your studies." />
);

export const SUBSCRIPTION_TIER_HELP_ICON = (
    <StlIconPopover content="Contact your StreetLight Data Representative for information on subscription bundles." />
);

export const ENTERPRISE_STUDY_TYPE_HELP_ICON = (
    <StlIconPopover
        title="Please assign a Type to your Study"
        content={
            <p>
                <b>Pursuit/End Game (Paid subscription context):</b> is the study type that should
                be used when you are working on a project proposal that is billable.
            </p>
        }
    />
);

export const CHARGE_CODE_HELP_ICON = (
    <FontAwesomeIcon id="charge-code-info-icon" className="info-icon" icon={faInfoCircle} />
);
