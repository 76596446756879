import type { IUserEmailAPI } from "@common/services/server/userApi.types";

type TProps = {
    users: Array<IUserEmailAPI>;
};

export const UsersList = ({ users }: TProps) => {
    if (!users.length) return null;

    return (
        <div className="users-list">
            <div className="title">Users</div>
            <div className="list">
                {users.map(user => (
                    <div className="user-item" key={user.email_addr}>
                        <p>{user.email_addr}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};
