import { useState } from "react";
import moment, { Moment } from "moment/moment";
import type { TDate } from "@common/components/dateRangeFilter/dateRangeFilter";
import {
    DateCalendar,
    DatePicker,
    DatePickerProps,
    DateValidationError,
    LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { PickerSelectionState } from "@mui/x-date-pickers/internals";
import "./datePicker.less";

export const FORMAT_DATE = "MM/DD/YYYY" as const;

const FORMAT_PLACEHOLDER = FORMAT_DATE.toLowerCase();

// Insight platform doesn't have dates earlier than 2010
const MIN_DATE = moment("2010-01-01");

const defaultDayOfWeekFormatter = (date: Moment) => moment(date).format("ddd");

const getParsedValue = (value: any, partialValue: Moment | null) => {
    if (partialValue) {
        return partialValue;
    }
    if (typeof value === "string") {
        return moment(value, FORMAT_DATE);
    }
    return value ? moment(value) : null;
};

type TState = {
    partialValue: Moment | null;
    errorText: string;
};

const INITIAL_STATE: TState = {
    partialValue: null,
    errorText: "",
};

type TPickerDate = Moment | TDate;

type TProps = Omit<DatePickerProps<Moment>, "onChange" | "value" | "minDate" | "maxDate"> & {
    id?: string;
    value: any;
    minDate?: TPickerDate;
    maxDate?: TPickerDate;
    openToDate?: TPickerDate;
    testid?: string;
    ariaLabel?: string;
    placeholder?: string;
    required?: boolean;
    inline?: boolean;
    shouldAllowClearDate?: boolean;
    filterDate?: (date: Moment | null) => boolean;
    onChange?: (date: TDate) => void;
    onFocus?: () => void;
    onOpenCalendar?: () => void;
    onPartialDateChange?: (date: Moment | null) => void;
};

export const StlDatePicker = (props: TProps) => {
    const [partialValue, setPartialValue] = useState(INITIAL_STATE.partialValue);
    const [errorText, setErrorText] = useState(INITIAL_STATE.errorText);

    const {
        id,
        testid,
        className = "",
        value,
        minDate,
        maxDate,
        openToDate,
        autoFocus = false,
        inline,
        required,
        disabled,
        shouldAllowClearDate,
        ariaLabel,
        placeholder = FORMAT_PLACEHOLDER,
        filterDate,
        onChange = () => {},
        onFocus,
        onOpenCalendar,
        onPartialDateChange = () => {},
        ...restProps
    } = props;

    const handlePickerDateChange = (date: Moment | null): void => {
        const dateWithoutTimeZone = date && date.isValid() ? date.valueOf() : null;

        onChange(dateWithoutTimeZone);
    };

    const handleCalendarDateChange = (
        date: Moment | null,
        selectionState: PickerSelectionState | undefined,
    ): void => {
        if (selectionState === "partial") {
            setPartialValue(date);
            onPartialDateChange(date);
            return;
        }
        const dateWithoutTimeZone = date && date.isValid() ? date.valueOf() : null;

        onChange(dateWithoutTimeZone);
        setPartialValue(INITIAL_STATE.partialValue);
    };

    const handleError = (reason: DateValidationError, _value: Moment | null) => {
        let text;
        switch (reason) {
            case "minDate":
                text = "Date should not be before minimal date";
                break;
            case "maxDate":
                text = "Date should not be after maximal date";
                break;
            default:
                text = "";
                break;
        }
        setErrorText(text);
    };

    const ariaLabelAttribute = ariaLabel ? { "aria-label": ariaLabel } : {};

    const commonProps = {
        id,
        testid,
        value: getParsedValue(value, partialValue),
        minDate: minDate ? moment(minDate) : MIN_DATE,
        maxDate: maxDate ? moment(maxDate) : undefined,
        referenceDate: openToDate ? moment(openToDate) : undefined,
        dayOfWeekFormatter: defaultDayOfWeekFormatter,
        format: FORMAT_DATE,
        autoFocus,
        readOnly: disabled,
        disabled,
        shouldDisableDate: filterDate && ((date: Moment): boolean => !filterDate(date)), // call the function with negation because the library has only a parameter to disable dates
        showDaysOutsideCurrentMonth: true,
        onError: handleError,
        ...ariaLabelAttribute,
        ...restProps,
    };

    const slotProps = {
        field: {
            id,
            "data-testid": testid,
            autoFocus,
            clearable: shouldAllowClearDate,
            "aria-required": required,
            required,
            placeholder,
            onFocus,
            ...ariaLabelAttribute,
        },
        popper: {
            className: "stl-datepicker-popover stl-calendar-root",
            "data-testid": "stl-dropdown-calendar",
        },
        openPickerButton: {
            "data-testid": "stl-open-button",
            ...(onOpenCalendar ? { onClick: onOpenCalendar } : {}),
        },
        textField: {
            helperText: errorText || "",
            error: !!errorText,
        },
    };

    return (
        <div className={`stl-datepicker ${className}`}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                {inline ? (
                    <DateCalendar
                        className="datecalendar-root stl-calendar-root"
                        onChange={handleCalendarDateChange}
                        {...commonProps}
                    />
                ) : (
                    <DatePicker
                        className="datepicker-root"
                        onChange={handlePickerDateChange}
                        // @ts-ignore
                        slotProps={slotProps}
                        closeOnSelect
                        {...commonProps}
                    />
                )}
            </LocalizationProvider>
        </div>
    );
};
