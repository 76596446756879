const ACTION_PREFIX = "general";

export const GENERAL_ACTIONS = {
    RESET_REDUCER: `${ACTION_PREFIX}/RESET_REDUCER`,
    SET_INITIAL_DATA: `${ACTION_PREFIX}/SET_INITIAL_DATA`,
    SET_ANALYSIS_TYPE_CODE: `${ACTION_PREFIX}/SET_ANALYSIS_TYPE_CODE`,
    SET_DATA_SOURCE_CODE: `${ACTION_PREFIX}/SET_DATA_SOURCE_CODE`,
    SET_ZONES_MODE: `${ACTION_PREFIX}/SET_ZONES_MODE`,
    SET_SCREEN_MODE: `${ACTION_PREFIX}/SET_SCREEN_MODE`,
    SET_ACTIVE_TAB: `${ACTION_PREFIX}/SET_ACTIVE_TAB`,
    SHOW_PREVIEW_ANALYSIS: `${ACTION_PREFIX}/SHOW_PREVIEW_ANALYSIS`,
    SET_IS_15_MINUTE_BINS_MODE_ACTIVE: `${ACTION_PREFIX}/SET_IS_15_MINUTE_BINS_MODE_ACTIVE`,
    SET_ANALYSIS_TYPE_CHANGED: `${ACTION_PREFIX}/SET_ANALYSIS_TYPE_CHANGED`,
    SET_SHOW_ALL_ANALYSES_ALLOWED: `${ACTION_PREFIX}/SET_SHOW_ALL_ANALYSES_ALLOWED`,
    SAVE_ANALYSIS_SUCCESS: `${ACTION_PREFIX}/SAVE_ANALYSIS_SUCCESS`,
    SAVE_ANALYSIS_RESET: `${ACTION_PREFIX}/SAVE_ANALYSIS_RESET`,
    SET_ANALYSIS_ACTION_ERROR: `${ACTION_PREFIX}/SET_ANALYSIS_ACTION_ERROR`,
    SHOW_ANALYSIS_SIZE_REVIEW_MODAL: `${ACTION_PREFIX}/SHOW_ANALYSIS_SIZE_REVIEW_MODAL`,
    RESET_ANALYSIS_SIZE_REVIEW_DATA: `${ACTION_PREFIX}/RESET_ANALYSIS_SIZE_REVIEW_DATA`,
    SHOW_ZONE_QUOTA_MODAL: `${ACTION_PREFIX}/SHOW_ZONE_QUOTA_MODAL`,
    RESET_UNIQUE_ZONE_DATA: `${ACTION_PREFIX}/RESET_UNIQUE_ZONE_DATA`,
    SET_ANALYSIS_VALIDATION: `${ACTION_PREFIX}/SET_ANALYSIS_VALIDATION`,
    UPDATE_ANALYSIS_VERSION_ID: `${ACTION_PREFIX}/UPDATE_ANALYSIS_VERSION_ID`,
    SET_GENERAL_UI_STATE: `${ACTION_PREFIX}/SET_GENERAL_UI_STATE`,
    SET_PROJECT_FOLDER: `${ACTION_PREFIX}/SET_SELECTED_PROJECT_FOLDER`,
    UPDATE_STATE: `${ACTION_PREFIX}/UPDATE_STATE`,
};
