import { useState, useCallback, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import { StlCheckbox } from "@common/components/checkbox/checkbox";
import { StlButton, StlIconPopover } from "@common/components";
import "./nestedCheckbox.less";

export const Checkbox = memo(
    ({
        checked,
        label,
        onChange,
        disabled,
        infoIconContent,
    }: {
        checked: boolean;
        label: string;
        onChange: (checked: boolean) => void;
        disabled: boolean;
        infoIconContent: string | null;
    }) => {
        return (
            <div className="checkbox-item">
                <StlCheckbox checked={checked} onChange={onChange} disabled={disabled}>
                    {label}
                </StlCheckbox>
                {infoIconContent && <StlIconPopover content={infoIconContent} />}
            </div>
        );
    },
);

export const NestedCheckbox = ({
    value,
    checked,
    expanded = false,
    showCheckbox = true,
    getLabel,
    onChange,
    nestedCheckboxesData,
}: {
    value: string;
    checked: boolean;
    expanded?: boolean;
    showCheckbox?: boolean;
    getLabel: () => JSX.Element;
    onChange: ({ code, isChecked }: { code: string; isChecked: boolean }) => void;
    nestedCheckboxesData?: {
        value: string;
        label: string;
        checked: boolean;
        disabled: boolean;
        infoIconContent: string | null;
    }[];
}) => {
    const [childrenExpanded, setChildrenExpanded] = useState(expanded);

    const handleChange = useCallback(
        (isChecked: boolean) => {
            onChange({ code: value, isChecked });
        },
        [onChange, value],
    );

    return (
        <div className="stl-nested-checkbox">
            <div className="checkbox-label">
                {showCheckbox ? (
                    <StlCheckbox checked={checked} onChange={handleChange}>
                        {getLabel()}
                    </StlCheckbox>
                ) : (
                    <span>{getLabel()}</span>
                )}

                <StlButton
                    variant="naked"
                    onClick={() => setChildrenExpanded(prevValue => !prevValue)}
                    className="expand-button"
                    size="sm"
                >
                    <FontAwesomeIcon icon={childrenExpanded ? faAngleDown : faAngleRight} />
                </StlButton>
            </div>
            {childrenExpanded && !!nestedCheckboxesData?.length && (
                <div className="checkbox-list">
                    {nestedCheckboxesData.map(childCheckbox => {
                        return (
                            <Checkbox
                                key={childCheckbox.value}
                                label={childCheckbox.label}
                                checked={childCheckbox.checked}
                                onChange={isChecked =>
                                    onChange({ code: childCheckbox.value, isChecked })
                                }
                                disabled={childCheckbox.disabled}
                                infoIconContent={childCheckbox.infoIconContent}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};
