import { BASE_VIZ_INITIAL_STATE } from "@app/viz3/base/state/baseViz.state";
import { AADT_VIZ_INITIAL_STATE } from "@app/viz3/aadtVisualization/state/aadtViz.state";
import { AT_VIZ_INITIAL_STATE } from "@app/viz3/atVisualization/state/atViz.state";
import { CS_VIZ_INITIAL_STATE } from "@root/src/app/viz3/csVisualization/state/csViz.state";
import { CT_VIZ_INITIAL_STATE } from "@app/viz3/ctVisualization/state/ctViz.state";
import { OD_VIZ_INITIAL_STATE } from "@app/viz3/odVisualization/state/odViz.state";
import { ODMF_VIZ_INITIAL_STATE } from "@app/viz3/odmfVisualization/state/odmfViz.state";
import { ODG_VIZ_INITIAL_STATE } from "@app/viz3/odgVisualization/state/odgViz.state";
import { NOD_VIZ_INITIAL_STATE } from "@app/viz3/nodVisualization/state/nodViz.state";
import { RV_VIZ_INITIAL_STATE } from "@app/viz3/rvVisualization/state/rvViz.state";
import { SEGMENT_VIZ_INITIAL_STATE } from "@app/viz3/segmentVisualization/state/segmentViz.state";
import { TMC_VIZ_INITIAL_STATE } from "@app/viz3/tmcVisualization/state/tmcViz.state";
import { TROD_VIZ_INITIAL_STATE } from "@app/viz3/trodVisualization/state/trodViz.state";
import { TRZA_VIZ_INITIAL_STATE } from "@app/viz3/trzaVisualization/state/trzaViz.state";
import { ZA_VIZ_INITIAL_STATE } from "@app/viz3/zaVisualization/state/zaViz.state";

export const VIZ3_INITIAL_STATE = {
    base: BASE_VIZ_INITIAL_STATE,
    aadt: AADT_VIZ_INITIAL_STATE,
    at: AT_VIZ_INITIAL_STATE,
    cs: CS_VIZ_INITIAL_STATE,
    ct: CT_VIZ_INITIAL_STATE,
    od: OD_VIZ_INITIAL_STATE,
    odmf: ODMF_VIZ_INITIAL_STATE,
    odg: ODG_VIZ_INITIAL_STATE,
    nod: NOD_VIZ_INITIAL_STATE,
    rv: RV_VIZ_INITIAL_STATE,
    segment: SEGMENT_VIZ_INITIAL_STATE,
    tmc: TMC_VIZ_INITIAL_STATE,
    trod: TROD_VIZ_INITIAL_STATE,
    trza: TRZA_VIZ_INITIAL_STATE,
    za: ZA_VIZ_INITIAL_STATE,
};
