import { InputHTMLAttributes, useMemo } from "react";
import "./radio.less";

type TProps = InputHTMLAttributes<HTMLInputElement> & {
    testid?: string;
    ariaLabel?: string;
};

export const StlRadio = (props: TProps) => {
    const id = useMemo<string>(() => Math.random().toString(), []);

    return (
        <div className="stl-radio">
            <input
                id={id}
                data-testid={props.testid}
                type="radio"
                className="stl-radio-input"
                name={props.name}
                checked={props.checked}
                disabled={props.disabled}
                readOnly={props.readOnly}
                onChange={props.onChange}
                tabIndex={props.tabIndex || 0}
                aria-label={props.ariaLabel}
            />
            <label className="stl-radio-label" htmlFor={id}>
                {props.children}
            </label>
        </div>
    );
};

StlRadio.displayName = "StlRadio";
