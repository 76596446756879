import { VISUALIZATION_PROPERTIES } from "@app/viz3/csVisualization/state/csViz.constants";

export const STATIC_LEGEND_BREAKPOINTS = {
    [VISUALIZATION_PROPERTIES.TTI.code]: {
        bins: [1, 1.1, 1.25, 1.5, 2],
        postfixLabels: ["No Congestion", "", "Congested", "", "More Congested"],
    },
};

export const VISUALIZATION_PROPERTY_CODE_TO_MAP_METRIC_NAME = {
    [VISUALIZATION_PROPERTIES.VOLUME.code]: "metric_volume",
    [VISUALIZATION_PROPERTIES.AVG_SPEED.code]: "metric_avg_speed",
    [VISUALIZATION_PROPERTIES.SEP.code]: "metric_speed_eightyfive_percentile",
    [VISUALIZATION_PROPERTIES.VHD.code]: "metric_vhd",
    [VISUALIZATION_PROPERTIES.TTI.code]: "metric_tti",
    [VISUALIZATION_PROPERTIES.COG.code]: "metric_is_congested",
};

export const VISUALIZATION_PROPERTY_CODE_TO_MAP_SAMPLE_SIZE_NAME = {
    [VISUALIZATION_PROPERTIES.AVG_SPEED.code]: "sample_size_avg_speed",
    [VISUALIZATION_PROPERTIES.SEP.code]: "sample_size_speed_eightyfive_percentile",
    [VISUALIZATION_PROPERTIES.VHD.code]: "sample_size_vhd",
    [VISUALIZATION_PROPERTIES.TTI.code]: "sample_size_tti",
    [VISUALIZATION_PROPERTIES.COG.code]: "sample_size_is_congested",
} as const;
