import { useEffect, useRef, useState } from "react";
import { Map } from "mapbox-gl";
import { MAP_DATA_LOADING_ERROR_MESSAGE } from "@common/components/baseMap/baseMap.constants";
import { getFallbackBaseMapStyleId } from "@common/components/baseMap/baseMap.helpers";
import { EMapStyleIds } from "@common/components/baseMap/baseMap.types";
import { IMapError } from "@common/components/baseMap/mapErrorAlert/mapErrorAlert";
import { MapStyleLoadingAlert } from "./mapStyleLoadingAlert";

type TMapStyleOptions = {
    style: EMapStyleIds;
    setError?: (error: IMapError | null) => void;
    onStyleChange: (newStyle: EMapStyleIds) => void;
};

export const useMapStyleLoading = (
    map: Map | null,
    { style, onStyleChange, setError }: TMapStyleOptions,
) => {
    const [styleIsLoading, setStyleIsLoading] = useState(false);
    const styleRef = useRef<EMapStyleIds>(style);
    const styleIsLoadingRef = useRef<boolean>(styleIsLoading);

    styleIsLoadingRef.current = styleIsLoading;

    useEffect(() => {
        if (!map || style === styleRef.current) return;
        styleRef.current = style;

        setStyleIsLoading(true);
    }, [map, style]);

    useEffect(() => {
        if (!map) return;

        map.once("style.load", () => {
            setStyleIsLoading(false);
        });

        map.once("error", (err: ErrorEvent) => {
            const { error } = err;
            const isStyleLoadingError =
                styleIsLoadingRef.current && error.message === MAP_DATA_LOADING_ERROR_MESSAGE;

            if (isStyleLoadingError) {
                setStyleIsLoading(false);
                setError?.({
                    errorTexts: [
                        <MapStyleLoadingAlert
                            key="StyleLoadingError"
                            onHide={() => {
                                onStyleChange(getFallbackBaseMapStyleId(styleRef.current));
                                setError(null);
                            }}
                        />,
                    ],
                });
            }
        });
    }, [map, setError, onStyleChange]);
};
