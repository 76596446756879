import { TRAVELER_PARAMS_US_CENSUS_2020 } from "@app/analysis/addOns/state/addOns.constants";

export const TRAVELER_BIN_CATEGORIES_US_CENSUS_2020 = {
    EQUITY_DEMOGRAPHICS: {
        ...TRAVELER_PARAMS_US_CENSUS_2020.EQUITY_DEMOGRAPHICS,
        code: "equity",
        name: "Equity Related Demographics",
    },
    EDUCATION_INCOME: {
        ...TRAVELER_PARAMS_US_CENSUS_2020.EDUCATION_INCOME,
        code: "income_education",
    },
    HOUSEHOLD_CHARACTERISTICS: {
        ...TRAVELER_PARAMS_US_CENSUS_2020.HOUSEHOLD_CHARACTERISTICS,
        code: "household",
    },
    EMPLOYMENT_CHARACTERISTICS: {
        ...TRAVELER_PARAMS_US_CENSUS_2020.EMPLOYMENT_CHARACTERISTICS,
        code: "employment",
    },
    TRIP_PURPOSES: {
        ...TRAVELER_PARAMS_US_CENSUS_2020.TRIP_PURPOSES,
        code: "trip_purpose",
    },
} as const;

export const TRAVELER_BIN_CATEGORIES_US_CENSUS_2020_LIST = Object.values(
    TRAVELER_BIN_CATEGORIES_US_CENSUS_2020,
);

export type TTravelerBinCategory =
    typeof TRAVELER_BIN_CATEGORIES_US_CENSUS_2020[keyof typeof TRAVELER_BIN_CATEGORIES_US_CENSUS_2020];
