export const KILOBYTE = 1024 as const;
export const MEGABYTE = 1024 * 1024;
export const DEFAULT_FILE_MAX_SIZE = 20 as const;
export const IMAGE_FILE_TYPES = [".gif", ".jpg", ".jpeg", ".png"] as const;
export const DOC_FILE_TYPES = [
    ".xls",
    ".xlsx",
    ".docx",
    ".doc",
    ".pdf",
    ".rtf",
    ".txt",
    ".ppt",
    ".pptx",
] as const;
export const DEFAULT_FILE_TYPES = [...IMAGE_FILE_TYPES, ...DOC_FILE_TYPES];

export const UPLOAD_ERRORS = {
    FILE_TYPE: "Invalid file type for upload",
    FILE_SIZE:
        "The size of this upload exceeds the size limit of 20mb. Large files can be uploaded as multiple zone sets in the",
} as const;
