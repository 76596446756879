import { ReactNode } from "react";
import "./authLayout.less";

type TProps = {
    children: ReactNode;
};

export const AuthLayout = ({ children }: TProps) => {
    return (
        <>
            <header className="stl-entry-header" role="banner">
                <img src="/img/main-app-logo-insight.png" alt="StreetLight InSight logo" />
            </header>
            <main className="stl-entry-main" role="main">
                {children}
            </main>
        </>
    );
};
