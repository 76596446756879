import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-solid-svg-icons";
import { StlButton } from "@common/components/button/button";
import { StlPopover } from "@common/components/popover/popover";
import type { StlPopoverHandlers } from "@common/components/popover/popover";
import type { TColumnVisibility, TColumnDef } from "@common/components/table/table.types";
import { ColumnChooser } from "./columnChooser";
import "./columnSettings.less";

type TProps = {
    columnDefs: Array<TColumnDef>;
    mandatoryColumns?: Array<string>;
    columnVisibility: TColumnVisibility;
    onSetColumns?: (columnVisibility: TColumnVisibility) => void;
};

export const StlColumnSettings = ({
    columnVisibility,
    onSetColumns,
    columnDefs,
    mandatoryColumns,
}: TProps) => {
    const [_columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(columnVisibility);
    const [prevState, setPrevState] = useState<TColumnVisibility>(columnVisibility);

    const popover = useRef<StlPopoverHandlers>(null);

    const handleApply = () => {
        popover.current?.close();
        setPrevState(_columnVisibility);
        onSetColumns?.(_columnVisibility);
    };

    const handleCancel = () => {
        popover.current?.close();
        setColumnVisibility(prevState);
    };

    return (
        <div className="stl-column-setting" data-testid="stl-column-setting">
            <StlPopover
                id="column-choose-popover"
                className="stl-column-chooser-popover"
                ref={popover}
                sourceControl={
                    <StlButton
                        size="sm"
                        testid="chooseColumnsBtn"
                        className="column-choose-button"
                        ariaLabel="Configure Columns Visibility"
                    >
                        <FontAwesomeIcon icon={faCog} className="choose-columns" />
                    </StlButton>
                }
                anchorOrigin={{ vertical: "center", horizontal: "left" }}
                transformOrigin={{ vertical: "center", horizontal: "right" }}
                disableAutoFocus={false}
                disableEnforceFocus={false}
                disableRestoreFocus={false}
                contentClickable
            >
                <ColumnChooser
                    columnVisibility={_columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    columnDefs={columnDefs}
                    mandatoryColumns={mandatoryColumns}
                    onApply={handleApply}
                    onCancel={handleCancel}
                />
            </StlPopover>
        </div>
    );
};
