import { sortBy } from "lodash-es";
import moment from "moment";
import { formatValue } from "@common/formatters/formatValue";
import { getNumber } from "@app/viz3/baseLightningViz/state/baseLightningViz.helpers";
import { Expression } from "mapbox-gl";
import { LineString, MultiLineString, FeatureCollection } from "geojson";
import { CorridorSegmentData } from "@common/services/server/layerGroupApi.types";
import { IAnalysis } from "@common/services/server/analysesApi.types";
import type { IAnalysisDateRange } from "@app/viz3/viz3.types";
import type { IDayPartValue, ISwatch } from "@app/viz3/base/state/baseViz.types";
import type {
    TSelectedSegmentFilters,
    IProjectZoneGroup,
    TRoadType,
} from "@app/viz3/baseLightningViz/state/baseLightningViz.types";
import type {
    TDayTypeOption,
    TGroupModeOptionId,
    IVisualizationProperties,
    Coordinate,
    ICSCorridorGroup,
    ICSSegment,
    IProjectRoute,
    TCSCorridorSubGroupType,
    TCSVizStateFilterGroups,
    TCSSegmentId,
    TVisualizationPropertiesCodes,
} from "@app/viz3/csVisualization/state/csViz.types";
import type { IGetProjectCorridorGroupsResponse } from "@app/viz3/csVisualization/components/filters/services/corridorGroupFiltersService.types";
import {
    ECorridorSubGroupType,
    EFilterOptionVariant,
} from "@app/viz3/csVisualization/state/csViz.types";
import {
    DAY_PARTS_OPTIONS,
    GROUP_MODE_OPTIONS,
    UNRELIABLE_SEGMENTS_OPTIONS,
    VISUALIZATION_PROPERTIES,
} from "@app/viz3/csVisualization/state/csViz.constants";
import { SEGMENT_FILTERS } from "@app/viz3/baseLightningViz/state/baseLightningViz.constants";
import { VISUALIZATION_PROPERTY_CODE_TO_MAP_METRIC_NAME } from "@app/viz3/csVisualization/components/content/components/csMap/csMap.constants";

//"Early AM (12am-6am)" -> "Early AM"
export const getDayPartOptionLabel = (label: string) => label.split("(")[0].trim();

export const getDayPartsOptions = (analysis: Pick<IAnalysis, "day_parts">) => {
    const { day_parts = [] } = analysis;

    return day_parts.reduce((result, value) => {
        //Check if name starts with number
        if (/^\d/.test(value.day_part_desc))
            return [
                ...result,
                {
                    label: value.day_part_desc,
                    value: value.day_part8,
                },
            ];

        return result;
    }, [] as Array<IDayPartValue>);
};

const getIsInOrder = (currentDayPart: IDayPartValue, nextDayPart: IDayPartValue) => {
    const getValue = (dayPart: IDayPartValue) => +dayPart.value.toString().substring(0, 3);

    const currentDayPartValue = getValue(currentDayPart);
    const nextDayPartValue = getValue(nextDayPart);

    return nextDayPartValue - currentDayPartValue === 1;
};

export const getHourRanges = (dayParts: Array<IDayPartValue>) => {
    let groupedDayParts: IDayPartValue[][] = [];
    let period: IDayPartValue[] = [];

    sortBy(dayParts, ["value"]).forEach((dayPart, index, initialArray) => {
        if (!index) {
            period = [dayPart];

            if (initialArray.length === 1) {
                groupedDayParts = [[dayPart]];
            }

            return;
        }

        const isLastDayPart = index === initialArray.length - 1;
        const isInOrder = getIsInOrder(period[period.length - 1], dayPart);

        if (!isLastDayPart) {
            if (isInOrder) {
                period = [...period, dayPart];
                return;
            }

            groupedDayParts = [...groupedDayParts, period];
            period = [dayPart];
            return;
        }

        if (isInOrder) {
            period = [...period, dayPart];
            groupedDayParts = [...groupedDayParts, period];
            return;
        }

        groupedDayParts = [...groupedDayParts, period, [dayPart]];
    });

    const getOptionLabel = (startLabel: string, endLabel: string) => {
        const startHour = startLabel.split(" ")[0];
        const endHourLabel = endLabel.split(" ")[0];
        const endHour = moment(endHourLabel, "ha").add(1, "hours").format("ha");

        return `${startHour} - ${endHour}`;
    };

    return groupedDayParts
        .reduce((result, groupedDayPart) => {
            if (!groupedDayPart.length) return result;

            return `${result}, ${getOptionLabel(
                groupedDayPart[0].label,
                groupedDayPart[groupedDayPart.length - 1].label,
            )}`;
        }, "")
        .replace(/^.{2}/, "")
        .trim();
};

export const convertDayPartsToString = (
    selectedGroupMode: TGroupModeOptionId,
    selectedDayParts: Array<IDayPartValue>,
) => {
    if (selectedGroupMode === GROUP_MODE_OPTIONS.DAY_PARTS.id) {
        if (selectedDayParts.length === DAY_PARTS_OPTIONS.length) {
            return "All Day Parts";
        }

        return selectedDayParts.reduce((result, { label }, index) => {
            if (!index) return `${getDayPartOptionLabel(label)}`;

            return `${result}, ${getDayPartOptionLabel(label)}`;
        }, "");
    }

    if (selectedDayParts.length === 24) {
        return "All Hours";
    }

    return getHourRanges(selectedDayParts);
};
function filterElementsStartingWith(element: String, compared: string) {
    return element.startsWith(compared);
}
interface ICorridorGroups {
    [key: string]: {
        id: string;
        name: string;
        type: string;
        parentId: string;
        childIds: Array<string>;
    }; // Assuming both keys and values are strings
}
export const convertSelectedSegmentFiltersToLabels = (
    {
        selectedSegmentFilters,
        selectedCorridorGroupIds,
        segmentGroups,
    }: {
        selectedSegmentFilters: TSelectedSegmentFilters;
        selectedCorridorGroupIds: Array<string>;
        segmentGroups: Array<IProjectZoneGroup>;
        selectedRoads: Array<TRoadType["id"]>;
        selectedRoad?: {
            county_name: string;
            city_name: string;
            highway: string;
        };
    },
    csFilters?: { corridorGroups: ICorridorGroups },
) => {
    if (!selectedSegmentFilters) {
        return { primaryLabel: "" };
    }

    if (selectedSegmentFilters.code === SEGMENT_FILTERS.ALL.code) {
        return { primaryLabel: SEGMENT_FILTERS.ALL.display };
    }

    if (selectedSegmentFilters.code === SEGMENT_FILTERS.CORRIDOR_GROUPS.code) {
        const corridorGroups: ICorridorGroups | undefined = csFilters?.corridorGroups;
        const filteredCorridorList = selectedCorridorGroupIds.filter(corridor =>
            filterElementsStartingWith(corridor, "planningGroupId"),
        );
        let totalPlanningGroups = 0;
        filteredCorridorList.forEach(element => {
            const corridorChild = corridorGroups?.[element];
            if (corridorChild) {
                const filteredOperationGroupList = corridorChild.childIds.filter(operationGroup =>
                    filterElementsStartingWith(operationGroup, "operationalGroupId"),
                );
                totalPlanningGroups += filteredOperationGroupList.length;
            }
        });
        if (filteredCorridorList.length >= 3) {
            return {
                primaryLabel: `${filteredCorridorList.length} Planning Groups, ${totalPlanningGroups} Operational Groups.`,
            };
        } else if (
            (filteredCorridorList.length < 3 || totalPlanningGroups > 5) &&
            corridorGroups
        ) {
            const label = filteredCorridorList.reduce((res, planningGroup) => {
                const { childIds, name } = corridorGroups[planningGroup] || {};

                if (name && Array.isArray(childIds)) {
                    const filteredOperationGroupList = childIds.filter(operationGroup =>
                        filterElementsStartingWith(operationGroup, "operationalGroupId"),
                    );

                    res.push(
                        `${name}: ${filteredOperationGroupList.length} Operational Group${
                            filteredOperationGroupList.length > 1 ? "s" : ""
                        }`,
                    );
                }
                return res;
            }, [] as Array<string>);

            return {
                primaryLabel: label.join(", "),
            };
        }
    }

    return {
        // @ts-ignore
        primaryLabel: selectedSegmentFilters.values.reduce((result, value, index) => {
            const groupName = segmentGroups.find(
                group => group.project_zone_group_id === value,
            )?.project_zone_group_name;

            if (!groupName) return result;

            if (!index) {
                return `${groupName}`;
            }

            return `${result}, ${groupName}`;
        }, ""),
    };
};

export const getDateRangesDisplayByYear = (dateRanges: Array<IAnalysisDateRange>) => {
    const rangesByYear = dateRanges.reduce((res, dateRange) => {
        const year = moment(dateRange.start_date, "MM/DD/YYYY").year();
        if (!res[year]) {
            res[year] = [];
        }
        res[year].push(dateRange);
        return res;
    }, {} as { [key: string]: Array<IAnalysisDateRange> });

    return Object.keys(rangesByYear).map(year => {
        const months = rangesByYear[year].reduce((res, dateRange) => {
            const startMonth = moment(dateRange.start_date).month();
            const endMonth = moment(dateRange.end_date).month();

            for (let i = startMonth; i <= endMonth; i++) {
                res.push(moment({ month: i }).format("MMMM"));
            }
            return res;
        }, [] as Array<string>);
        const monthsDisplay =
            months.length === 12
                ? `${months[0]} - ${months[months.length - 1]}`
                : months.join(", ");

        return { year, label: `${year} (${monthsDisplay})` };
    });
};

export const convertDayTypesToString = (dayTypes: Array<TDayTypeOption>) => {
    return dayTypes.reduce((result, dayType, index) => {
        const dayTypeName = dayType.label.split(" ")[0];

        if (!index) {
            return dayTypeName;
        }

        return `${result}, ${dayTypeName}`;
    }, "");
};

export const getZoneMetricColor = ({
    swatches,
    trafficMetric,
    visualizationPropertyCode,
}: {
    swatches: ISwatch[];
    trafficMetric?: number | boolean | string;
    visualizationPropertyCode?: IVisualizationProperties["code"];
}) => {
    if (
        (!trafficMetric && trafficMetric !== 0 && trafficMetric !== false) ||
        !swatches.length ||
        !visualizationPropertyCode
    ) {
        return "";
    }

    if (visualizationPropertyCode === VISUALIZATION_PROPERTIES.COG.code) {
        const _trafficMetric = trafficMetric
            ? UNRELIABLE_SEGMENTS_OPTIONS.YES
            : UNRELIABLE_SEGMENTS_OPTIONS.NO;

        const swatchData = swatches.find(({ label }) => {
            return label === _trafficMetric;
        });

        return swatchData?.color || "";
    }

    const shouldShowAsFractional = Number(swatches[0].label.split("-")[0]) <= 1;
    const isPercentage = VISUALIZATION_PROPERTIES.TTI.code === visualizationPropertyCode;

    const swatchData = swatches.find(({ label }) => {
        const [startRangeValue, endRangeValue] = label.replace("%", "").split("-");

        const config = { shouldShowAsFractional };
        const _trafficMetric = getNumber(formatValue(trafficMetric, config));

        if (!endRangeValue) {
            return _trafficMetric === getNumber(startRangeValue);
        }

        return (
            _trafficMetric >= getNumber(startRangeValue) &&
            _trafficMetric <= getNumber(endRangeValue)
        );
    });

    if (!swatchData) {
        return (
            swatches.find(({ label }) => {
                const [startRangeValue] = label.replace("%", "").split("-");

                const config = isPercentage
                    ? { shouldShowAsPercentage: true, fractionDigitsCount: 0 }
                    : { shouldShowAsFractional };

                const _trafficMetric = getNumber(formatValue(trafficMetric, config));

                return _trafficMetric >= getNumber(startRangeValue);
            })?.color || ""
        );
    }

    return swatchData.color;
};

export const getAllChildrenCorridorGroups = (
    corridorGroupsMap: TCSVizStateFilterGroups["corridorGroups"],
    corridorGroupId: ICSCorridorGroup["id"],
) => {
    const corridorGroup = corridorGroupsMap[corridorGroupId];

    const childrenAreGroups = corridorGroup.childIds.every(
        childId => !!corridorGroupsMap[childId],
    );

    if (!corridorGroup || !childrenAreGroups) return [];

    const result: Array<ICSCorridorGroup["id"]> = [];

    result.push(...corridorGroup.childIds);

    for (const childId of corridorGroup.childIds) {
        result.push(...getAllChildrenCorridorGroups(corridorGroupsMap, childId));
    }

    return result || [];
};

export const calculateLineDirection = (start: Coordinate, end: Coordinate): number => {
    const yDiff = end[0] - start[0];
    const xDiff = end[1] - start[1];

    // Calculate the direction in radians.
    const directionInRadians = Math.atan2(yDiff, xDiff);
    // Convert the direction to degrees.
    let directionInDegrees = directionInRadians * (180 / Math.PI);
    // Normalize the direction to [0, 360) range.
    if (directionInDegrees < 0) {
        directionInDegrees += 360;
    }

    return directionInDegrees;
};

export const getAllSegmentIdsFromCorridorGrouping = (
    node: ICSCorridorGroup | ICSSegment,
    getNode: (nodeId: string) => ICSCorridorGroup | ICSSegment,
): Array<string> => {
    if (!(node as ICSCorridorGroup).type) return [(node as ICSSegment).projectRouteZoneId];

    const _node = node as ICSCorridorGroup;

    return _node.childIds
        .map(id => {
            return !getNode(id) ? [] : getAllSegmentIdsFromCorridorGrouping(getNode(id), getNode);
        })
        .flat(3)
        .map(String);
};

export const getChildrenGroupOfCertainTypeCount = ({
    childIds,
    getNodeById,
    corridorGroupType,
}: {
    childIds: Array<string>;
    getNodeById: (id: string) => ICSCorridorGroup | ICSSegment;
    corridorGroupType: TCSCorridorSubGroupType;
}) => {
    return childIds.reduce((res: number, id) => {
        const corridorGroup = getNodeById(id) as ICSCorridorGroup;

        return corridorGroup.type === corridorGroupType
            ? res + getAllSegmentIdsFromCorridorGrouping(corridorGroup, getNodeById).length
            : res;
    }, 0);
};

export const sortByOrderIndex = <T extends ICSCorridorGroup["id"] | TCSSegmentId>(
    a: T,
    b: T,
    getOrderIndexById: (id: T) => number,
) => {
    return getOrderIndexById(a) - getOrderIndexById(b);
};

export const getPrevGroupIdByOrderIndex = ({
    nodeId,
    parentId = null,
    orderIndex,
    getGroupById,
    getSegmentById,
    requiredGroupType,
}: {
    nodeId: ICSCorridorGroup["id"];
    parentId?: string | null;
    orderIndex: number;
    getGroupById: (id: ICSCorridorGroup["id"]) => ICSCorridorGroup;
    getSegmentById: (id: TCSSegmentId) => ICSSegment;
    requiredGroupType?: TCSCorridorSubGroupType;
}): string | null => {
    const group = getGroupById(nodeId);

    if (!group || (requiredGroupType === group.type && orderIndex - 1 >= group.orderIndex)) {
        return parentId;
    }

    const nextChildId = [...group.childIds].reverse().find(childId => {
        const curChildGroup = getGroupById(childId) ?? getSegmentById(childId);

        return orderIndex - 1 >= curChildGroup.orderIndex;
    });

    return nextChildId
        ? getPrevGroupIdByOrderIndex({
              nodeId: nextChildId,
              parentId: group.id,
              orderIndex,
              getGroupById,
              getSegmentById,
              requiredGroupType,
          })
        : null;
};

export const getNextGroupIdByOrderIndex = ({
    nodeId,
    parentId = null,
    orderIndex,
    getGroupById,
    getSegmentById,
}: {
    nodeId: ICSCorridorGroup["id"];
    parentId?: string | null;
    orderIndex: number;
    getGroupById: (id: ICSCorridorGroup["id"]) => ICSCorridorGroup;
    getSegmentById: (id: TCSSegmentId) => ICSSegment;
}): string | null => {
    const group = getGroupById(nodeId);

    if (!group) {
        return parentId;
    }

    const nextChildId =
        group.childIds.length > 1
            ? [...group.childIds].reverse().find(childId => {
                  const curChildGroup = getGroupById(childId) ?? getSegmentById(childId);

                  return orderIndex + 1 >= curChildGroup.orderIndex;
              })
            : group.childIds[0];

    return nextChildId
        ? getNextGroupIdByOrderIndex({
              nodeId: nextChildId,
              parentId: group.id,
              orderIndex,
              getGroupById,
              getSegmentById,
          })
        : null;
};

export const reevaluateAllOrderIndexes = ({
    id,
    getNodeById,
}: {
    id: string;
    getNodeById: (id: string) => ICSCorridorGroup | ICSSegment;
}) => {
    const node = getNodeById(id) as ICSCorridorGroup;

    // Node is segment and has constant orderIndex
    if (!node?.type) return;

    node.childIds.forEach(childId => reevaluateAllOrderIndexes({ id: childId, getNodeById }));

    const firstChild = getNodeById(node.childIds[0]);

    if (!firstChild) return;

    node.orderIndex = firstChild.orderIndex;
};

export const removeEmptyGroupIdsFromChildIds = ({
    id,
    getNodeById,
}: {
    id: string;
    getNodeById: (id: string) => ICSCorridorGroup;
}) => {
    const group = getNodeById(id);

    const groupChildIdsToSet: Array<string> = [];

    group.childIds.forEach(childId => {
        if (getNodeById(childId)) {
            groupChildIdsToSet.push(childId);
        }
    });

    group.childIds = groupChildIdsToSet;
};

export const retrieveCorridorGroupsFromApiData = (
    responseData: IGetProjectCorridorGroupsResponse["data"],
) => {
    const {
        corridor_groups,
        planning_groups,
        project_route_zone,
        project_route_zones,
        operational_groups,
    } = responseData;

    const groupsMap = new Map<ICSCorridorGroup["id"], ICSCorridorGroup>();
    const planningGroupIdsToOperationalGroupsIds = new Map<string, Array<string>>();

    for (const projectRouteZone of Object.values(project_route_zone ?? project_route_zones)) {
        const {
            corridor_group_id,
            planning_group_id,
            operational_group_id,
            project_route_zone_id,
        } = projectRouteZone;
        const corridorGroup = corridor_groups[corridor_group_id as keyof typeof corridor_groups];
        const planningGroup = planning_groups[planning_group_id as keyof typeof planning_groups];
        const operationalGroup =
            operational_groups[operational_group_id as keyof typeof operational_groups];

        const corridorGroupId = corridor_group_id.toString();
        const planningGroupId = planning_group_id.toString();
        const operationalGroupId = operational_group_id.toString();
        const projectRouteZoneId = project_route_zone_id.toString();

        [
            {
                id: corridorGroupId,
                name: corridorGroup.name,
                type: ECorridorSubGroupType.Corridor,
                shouldAdd: true,
                project_route_id: corridorGroup.project_route_id,
            },
            {
                id: `planningGroupId_${planningGroupId}`,
                name: planningGroup.name,
                type: ECorridorSubGroupType.Planning,
                shouldAdd: true,
            },
            {
                id: `operationalGroupId_${operationalGroupId}`,
                name: operationalGroup.name,
                type: ECorridorSubGroupType.Operational,
                shouldAdd: !operationalGroup.display_as_segments,
            },
        ].forEach(({ id, name, type, shouldAdd, project_route_id }, index, groupsInfoArray) => {
            const [parentId, childId] = [
                groupsInfoArray[index - 1]?.id ?? null,
                groupsInfoArray[index + 1]?.shouldAdd
                    ? groupsInfoArray[index + 1].id
                    : projectRouteZoneId,
            ];

            const foundCorridorGroup = groupsMap.get(id);

            if (type === ECorridorSubGroupType.Planning) {
                const planningGroupChildIds = planningGroupIdsToOperationalGroupsIds.get(id);

                if (planningGroupChildIds) {
                    planningGroupChildIds.push(groupsInfoArray[index + 1].id);
                } else {
                    planningGroupIdsToOperationalGroupsIds.set(id, [
                        groupsInfoArray[index + 1].id,
                    ]);
                }
            }

            if (foundCorridorGroup) {
                foundCorridorGroup.childIds.push(childId);

                return;
            }

            const projectRouteId = project_route_id
                ? { projectRouteId: project_route_id.toString() }
                : {};

            if (shouldAdd) {
                groupsMap.set(id, {
                    id,
                    name,
                    type,
                    parentId: parentId,
                    childIds: [childId],
                    orderIndex: project_route_zone_id,
                    ...projectRouteId,
                });
            }
        });
    }

    groupsMap.forEach(group => {
        group.childIds = Array.from(new Set(group.childIds));
    });

    const _planningGroupIdsToOperationalGroupsIds = Object.fromEntries(
        planningGroupIdsToOperationalGroupsIds,
    );

    Object.entries(_planningGroupIdsToOperationalGroupsIds).forEach(([id, ids]) => {
        _planningGroupIdsToOperationalGroupsIds[id] = Array.from(new Set(ids));
    });

    return {
        corridorGroups: Object.fromEntries(groupsMap),
        planningGroupIdsToOperationalGroupsIds: _planningGroupIdsToOperationalGroupsIds,
    };
};

export const getProjectRouteFiltersConfig = (
    projectRoute: IProjectRoute,
    corridorGroupId: string | undefined,
) => {
    const projectRouteId = projectRoute.project_route_id.toString();
    const allFiltersData = {
        variant: EFilterOptionVariant.allSegments,
        projectRouteId,
    };

    const filtersConfig = {
        direction: projectRoute.direction,
        filters: [allFiltersData] as Array<{
            variant: EFilterOptionVariant;
            projectRouteId: string;
            corridorGroupId?: string;
        }>,
    };

    if (corridorGroupId) {
        filtersConfig.filters.push({
            variant: EFilterOptionVariant.corridorGroup,
            projectRouteId,
            corridorGroupId,
        });
    }

    return filtersConfig;
};

export const convertZonesToFeatureCollection = (
    zones: CorridorSegmentData<LineString | MultiLineString>[] | undefined | null,
): FeatureCollection | null => {
    if (!zones) return null;

    return {
        type: "FeatureCollection",
        features: zones.map(zone => {
            const { value, ...restProperties } = zone.properties;

            return { ...zone, properties: { ...restProperties, ...value } };
        }),
    };
};

export const getMetricExpressionFromVizProp = (
    propertyCode: TVisualizationPropertiesCodes,
): Expression => [
    "to-number",
    ["get", VISUALIZATION_PROPERTY_CODE_TO_MAP_METRIC_NAME[propertyCode]],
    0,
];

export const getPrevAvailableSegments = (
    newSelectedSegmentIds: string[],
    avaialbleSegmentIds: Array<string>,
) => {
    const newSelectedSegmentIdPosition = avaialbleSegmentIds.indexOf(newSelectedSegmentIds[0]);

    return newSelectedSegmentIdPosition > 0
        ? avaialbleSegmentIds.slice(0, newSelectedSegmentIdPosition)
        : [];
};
