import type { Feature, LineString, MultiPolygon, Point, Position } from "geojson";
import {
    ROAD_TYPES_LIST,
    SERVICE_LAYER_ID,
} from "@common/features/zonesManager/state/zonesManager.constants";
import { arrayIncludes } from "@common/utils/arrayIncludes";
import bearing from "@turf/bearing";
import destination from "@turf/destination";
import distance from "@turf/distance";
import explode from "@turf/explode";
import { lineString, multiPolygon as createMultiPolygon } from "@turf/helpers";
import midpoint from "@turf/midpoint";
import type { GateProperties } from "./spotCounter.types";

type TSideWithDistance = {
    from: Feature<Point>;
    to: Feature<Point>;
    distance: number;
};

const getText = (osmName: string, isNetworkPerformanceAnalysis: boolean): string =>
    isNetworkPerformanceAnalysis
        ? `${osmName} Roads are currently not available for this analysis type.`
        : `${osmName} Road zones may not be compatible with all analysis types.`;

export const getNestedRoadTypes = (osmType: string) => {
    return ROAD_TYPES_LIST.filter(roadType => arrayIncludes(roadType.osmNetwork, osmType));
};

export const getNestedOsmCheckboxesData = (
    osmType: string,
    isNetworkPerformanceAnalysis: boolean,
) => {
    return getNestedRoadTypes(osmType).map(roadType => {
        return {
            label: roadType.name,
            value: `${osmType}_${roadType.id}`,
            infoIconContent:
                `${osmType}_${roadType.id}` === SERVICE_LAYER_ID
                    ? getText(roadType.name, isNetworkPerformanceAnalysis)
                    : null,
        };
    });
};

export const convertLineToRectangleMultiPolygon = (
    line: Feature<LineString>,
    offsetDistance: number = 1,
): Feature<MultiPolygon> => {
    const perpendicularOffsets = line.geometry.coordinates.map((coord, index, array) => {
        const nextCoord = array[(index + 1) % array.length];
        const _bearing = bearing(coord, nextCoord);
        const offsetCoord1 = destination(coord, offsetDistance, _bearing + 90, {
            units: "meters",
        });
        const offsetCoord2 = destination(coord, offsetDistance, _bearing - 90, {
            units: "meters",
        });
        return [offsetCoord1.geometry.coordinates, offsetCoord2.geometry.coordinates];
    });

    // Combine midline points and perpendicular offsets to create points for the polygon
    const polygonPoints = line.geometry.coordinates.reduce(
        (coordinates: Position[], position, index) => {
            const offsetCoords = perpendicularOffsets[index];
            return [...coordinates, offsetCoords[0], offsetCoords[1]];
        },
        [],
    );

    polygonPoints.push(polygonPoints[0]);

    // Create a polygon feature from the calculated points
    return createMultiPolygon([[polygonPoints]]);
};

export const convertMultiPolygonRectangleToLine = (
    polygon: Feature<MultiPolygon>,
): Feature<LineString> => {
    const points = explode(polygon).features;

    const sidesWithDistance = points.reduce(
        (sides: Array<TSideWithDistance>, point: Feature<Point>, index: number) => {
            const prevPoint = index === 0 ? points[points.length - 1] : points[index - 1];
            const distanceBetweenPoints = distance(prevPoint, point, { units: "meters" });

            if (distanceBetweenPoints === 0) return sides;

            return [
                ...sides,
                { from: prevPoint, to: point, distance: distanceBetweenPoints },
            ] as Array<TSideWithDistance>;
        },
        [] as Array<TSideWithDistance>,
    );

    const sortedSides = sidesWithDistance.sort((a, b) => a.distance - b.distance);
    const [firstSide, secondSide] = sortedSides;

    const linePointA = midpoint(firstSide.from, firstSide.to);
    const linePointB = midpoint(secondSide.from, secondSide.to);

    return lineString([linePointA.geometry.coordinates, linePointB.geometry.coordinates]);
};

export const calculateSpotCounterGate = (
    spotCounterFeature: Feature<Point, GateProperties>,
): Feature<LineString> => {
    const offsetDistance = 10;
    const options = {
        units: "meters",
    } as const;

    const spotCounterCoords = spotCounterFeature.geometry.coordinates;
    const direction = spotCounterFeature.properties.direction;

    const perpendicularOffsets = [direction, (direction + 180) % 360].map(
        _direction =>
            destination(spotCounterCoords, offsetDistance, _direction, options).geometry
                .coordinates,
    );

    return {
        type: "Feature",
        geometry: {
            type: "LineString",
            coordinates: perpendicularOffsets,
        },
        properties: {},
    };
};
