export const COLORS = {
    RED: "#ff8989",
    YELLOW: "#f0cf7f",
    MID_YELLOW: "#eac772",
    BLUE: "#7AB0F5",
    GREEN: "#8CBF56",
    PURPLE: "#CF82E4",
    WHITE: "#FFFFFF",
    LIGHT_GREY: "#a4a4a4",
    MID_GREY: "#707070",
    DARK_GRAY: "#404040",
    TRANSPARENT: "transparent",
    BLACK: "#000000",
    BLACK82: "#444444",
    BLACK96: "#292929",
    BLACK98: "#242424",
};

export enum EDefaultColors {
    NO_COLOR = "",
    RED = "#C84C4C",
    BLUE = "#526DD1",
    GREEN = "#3D813F",
    PURPLE = "#95489C",
}

export const ORG_COLORS = {
    RED: "#F69C78",
    BLUE: "#88B8F6",
    GREEN: "#97C667",
    YELLOW: "#F5CE78",
    PURPLE: "#DBB2F0",
    TURQUOISE: "#6AC5CB",
    LIGHT_GREEN: "#9FDDA9",
    OLIVE: "#CCD981",
    LAVENDER: "#9FBAF1",
};

export const ORG_COLORS_LIST = Object.values(ORG_COLORS);
