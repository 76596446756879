// eslint-disable-next-line import/no-extraneous-dependencies
import type { CSSObject } from "@emotion/serialize";
import type { Styles } from "react-select/src/styles";
import type { OptionTypeBase } from "react-select";
import type { CommonProps, ValueType } from "react-select/src/types";

// Removes selected item(s) from the source.
export const getTypeaheadOptions = <TOption, TIsMulti extends boolean = false>({
    options,
    value,
    isMulti,
    isOptionsLimited,
    // @ts-ignore Property 'value' does not exist on type 'TValue'
    getOptionValue = option => option.value,
}: {
    value: ValueType<TOption, TIsMulti>;
    options: Array<TOption>;
    isMulti?: boolean;
    isOptionsLimited?: boolean;
    getOptionValue: (option: TOption) => any;
}) => {
    if (!isOptionsLimited) return options;

    if (isMulti) {
        const typeaheadOptions = [...options];

        Array.isArray(value) &&
            value?.forEach((valueOption: TOption) => {
                const foundIndex = typeaheadOptions.findIndex(
                    option => getOptionValue(option) === getOptionValue(valueOption),
                );

                if (foundIndex !== -1) {
                    typeaheadOptions.splice(foundIndex, 1);
                }
            });

        return typeaheadOptions;
    }
    // @ts-ignore Property 'value' does not exist on type 'TValue'
    return options.filter(option => getOptionValue(option) !== value?.value);
};

type TStyleState = CommonProps<OptionTypeBase, boolean> & {
    [key: string]: unknown;
};

type TStyleKey = keyof Styles<OptionTypeBase, boolean>;

export const mergeStyles = (...styles: Array<Styles<OptionTypeBase, boolean>>) =>
    styles.reduce((acc, style) => ({
        ...acc,
        ...Object.keys(style).reduce(
            (styleAcc, key) => ({
                ...styleAcc,
                [key]: acc[key as TStyleKey]
                    ? (providedStyles: CSSObject, state: TStyleState) =>
                          style[key as TStyleKey]?.(
                              // @ts-ignore TStyleState is missing the following properties from type
                              acc[key as TStyleKey]?.(providedStyles, state),
                              state,
                          )
                    : style[key as TStyleKey],
            }),
            {} as Styles<OptionTypeBase, boolean>,
        ),
    })) || ({} as Styles<OptionTypeBase, boolean>);
