import { getApiAnalysesIds } from "@common/helpers/analysis";
import type { IBulkActionAnalysesData } from "@common/services/server/adminApi.types";
import {
    IAnalysesResponse,
    ICancelAnalysesResponse,
    IDeleteAnalysisConfig,
    IDeleteAnalysisData,
    IDeleteDraftConfig,
    IDeleteDraftData,
    IExportAnalysisDetailsResponse,
    IExportSavedAnalysisDetailsResponse,
    IGetAnalysesConfig,
    IGetAnalysesResponse,
    IGetAnalysesTypeKeywordsResponse,
    IGetAnalysisConfig,
    IGetAnalysisResponse,
    IGetAnalysisVizRequestStatusResponse,
    IGetAnalysisVizStatusResponse,
    IGetAnalysisZonesLimitsResponse,
    IGetAvailableDataPeriodsResponse,
    IGetDraftResponse,
    IGetQueueWaitTimeResponse,
    IGetSATCAvailableDataPeriodsResponse,
    IGetUniqueZoneDetailsConfig,
    IGetUniqueZoneDetailsData,
    IGetUniqueZoneDetailsResponse,
    IReviewAnalysesConfig,
    IReviewAnalysesData,
    IReviewAnalysesResponse,
    ISaveAnalysisData,
    ISaveAnalysisExportDetailsData,
    ISaveAnalysisExportDetailsResponse,
    ISaveAnalysisResponse,
    ISaveDraftResponse,
    IUpdateAnalysisConfig,
    IUpdateAnalysisData,
    IUpdateAnalysisResponse,
    IValidateAnalysisData,
    IValidateAnalysisResponse,
    IValidateIntersectionsData,
    IValidateIntersectionsResponse,
} from "@common/services/server/analysesApi.types";
import { HttpService, IRequestConfig } from "@common/services/server/http.service";

export const AnalysesApiService = {
    getAnalysis(analysisId: number, config: IRequestConfig<IGetAnalysisConfig> = {}) {
        return HttpService.get<IGetAnalysisResponse>(`/projects/${analysisId}`, config);
    },
    getAnalyses(config: IRequestConfig<IGetAnalysesConfig> = {}) {
        return HttpService.get<IGetAnalysesResponse>("/projects", config).catch(
            HttpService.showIfError("Unknown error occurred while fetching analyses."),
        );
    },
    saveAnalysis(data: ISaveAnalysisData) {
        return HttpService.post<ISaveAnalysisResponse>("/projects", data);
    },
    saveAnalysisExportDetails(analysisId: number, data: ISaveAnalysisExportDetailsData) {
        return HttpService.post<ISaveAnalysisExportDetailsResponse>(
            `/projects/copy/${analysisId}`,
            data,
        ).catch(HttpService.showIfError("Unknown error occurred while copying covered zone."));
    },
    updateAnalysis(
        analysisId: number,
        data: IUpdateAnalysisData,
        config: IRequestConfig<IUpdateAnalysisConfig>,
    ) {
        return HttpService.put<IUpdateAnalysisResponse>(`/projects/${analysisId}`, data, config)
            .then(HttpService.showIfSuccess("Analysis has been successfully updated."))
            .catch(HttpService.showIfError("Unknown error occurred while updating analysis."));
    },
    cancelAnalyses(analyses: Array<IBulkActionAnalysesData>) {
        const analysesIds = getApiAnalysesIds(analyses);

        return HttpService.post<ICancelAnalysesResponse>("/project/cancelling", {
            project_details: analysesIds,
        });
    },
    deleteAnalysis(
        analysisId: number,
        data: IDeleteAnalysisData,
        config: IRequestConfig<IDeleteAnalysisConfig>,
    ) {
        return HttpService.delete<IAnalysesResponse>(`/projects/${analysisId}`, config)
            .then(HttpService.showIfSuccess(`Analysis ${data.project_name} deleted successfully.`))
            .catch(
                HttpService.showIfError(
                    `Unknown error occurred while deleting analysis ${data.project_name}.`,
                ),
            );
    },
    validateAnalysis(data: IValidateAnalysisData) {
        return HttpService.post<IValidateAnalysisResponse>("/projects/validate", data);
    },
    validateIntersections(data: IValidateIntersectionsData) {
        return HttpService.post<IValidateIntersectionsResponse>("/intersections/validate", data);
    },
    getUniqueZoneDetails(
        data: IGetUniqueZoneDetailsData,
        config: IRequestConfig<IGetUniqueZoneDetailsConfig> = {},
    ) {
        return HttpService.post<IGetUniqueZoneDetailsResponse>(
            "/projects/unique_zone_details",
            data,
            config,
        );
    },
    getAvailableDataPeriods() {
        return HttpService.get<IGetAvailableDataPeriodsResponse>(
            "/project/available_data_periods",
        ).catch(
            HttpService.showIfError(
                "Unknown error occurred while fetching available data periods.",
            ),
        );
    },
    getSATCAvailableDataPeriods() {
        return HttpService.get<IGetSATCAvailableDataPeriodsResponse>(
            "/project/satc_available_data_months",
        ).catch(
            HttpService.showIfError(
                "Unknown error occurred while fetching SATC available data periods.",
            ),
        );
    },
    saveDraft(data: ISaveAnalysisData) {
        return HttpService.post<ISaveDraftResponse>("/drafts", data);
    },
    getDraft(analysisId: number) {
        return HttpService.get<IGetDraftResponse>(`/drafts/${analysisId}`);
    },
    updateDraft(analysisId: number | string, data: ISaveAnalysisData) {
        return HttpService.put<IAnalysesResponse>(`/drafts/${analysisId}`, data);
    },
    deleteDraft(
        analysisId: number,
        data: IDeleteDraftData,
        config: IRequestConfig<IDeleteDraftConfig>,
    ) {
        return HttpService.delete<IAnalysesResponse>(`/drafts/${analysisId}`, config)
            .then(
                data &&
                    HttpService.showIfSuccess(
                        `Draft ${data.project_name} was successfully deleted.`,
                    ),
            )
            .catch(HttpService.showIfError("Unknown error occurred while deleting draft."));
    },
    exportAnalysisDetails(analysisId: number, withCounts: boolean = false) {
        return HttpService.get<IExportAnalysisDetailsResponse>(
            `/export/project/detail/${analysisId}/${withCounts}`,
        ).catch(
            HttpService.showIfError("Unknown error occurred while exporting analysis details."),
        );
    },
    exportSavedAnalysisDetails(uuid: string) {
        return HttpService.get<IExportSavedAnalysisDetailsResponse>(
            `/export/project/saved/${uuid}`,
            { cached: true },
        ).catch(
            HttpService.showIfError(
                "Unknown error occurred while exporting saved analysis details.",
            ),
        );
    },
    getExportAnalysisURL(analysisId: number, sha: string) {
        return `/server/export/project/${analysisId}/${sha}`;
    },
    getQueueWaitTime() {
        return HttpService.get<IGetQueueWaitTimeResponse>("/project_queue_wait_time");
    },
    getAnalysisZonesLimits() {
        return HttpService.get<IGetAnalysisZonesLimitsResponse>("/projects/zone_limits").catch(
            HttpService.showIfError("Unknown error occurred while fetching analysis zones limit."),
        );
    },
    getAnalysisVizStatus(analysisId: string) {
        return HttpService.get<IGetAnalysisVizStatusResponse>(
            `/projects/${analysisId}/viz_status`,
        ).catch(
            HttpService.showIfError(
                "Unknown error occurred while fetching analysis visualization status.",
            ),
        );
    },
    getAnalysisVizRequestStatus(projectVizloadRequestId: unknown) {
        return HttpService.get<IGetAnalysisVizRequestStatusResponse>(
            `/projects/viz_status/${projectVizloadRequestId}`,
        ).catch(
            HttpService.showIfError(
                "Unknown error occurred while fetching analysis visualization status.",
            ),
        );
    },
    reviewAnalyses(analyses: IReviewAnalysesData, config: IReviewAnalysesConfig) {
        const analysesIds = getApiAnalysesIds(analyses);
        const params = {
            project_details: analysesIds,
            status: config.status,
            rerun: config.rerun,
            bypass_sets_coverage: config.bypassSetsCoverage,
            is_size_review: config.isSizeReview,
            is_confirm_with_caveats: config.withCaveats,
            ignore_runtime_check: config.ignoreRuntimeCheck,
            ignore_privacy_check: config.ignorePrivacyCheck,
            review_note: config.reviewNote,
        };

        return HttpService.post<IReviewAnalysesResponse>("/admin/bulk_review_project", params);
    },
    getAnalysesSearchFilters() {
        return HttpService.get<IGetAnalysesTypeKeywordsResponse>(
            "/project/project_search_filters",
        );
    },
    subscribeToAnalysis(analysisId: number, userId: number) {
        return HttpService.post<IAnalysesResponse>(
            `/project/${analysisId}/subscribe_user/${userId}`,
        ).catch(HttpService.showIfError("Unknown error occurred while subscribing to analysis."));
    },
    unsubscribeFromAnalysis(analysisId: number, userId: number) {
        return HttpService.post<IAnalysesResponse>(
            `/project/${analysisId}/unsubscribe_user/${userId}`,
        ).catch(
            HttpService.showIfError("Unknown error occurred while unsubscribing from analysis."),
        );
    },
};
