import { SEGMENT_FILTERS } from "@app/viz3/baseLightningViz/state/baseLightningViz.constants";
import { COLORS } from "@common/constants/color.constants";
import { PointLike } from "mapbox-gl";

export const ZONE_SELECTION_TOOLS = {
    CURSOR: {
        id: "cursor",
        name: "Add/Remove to Selection",
        img: {
            selected: "/img/zone-selection-tools/cursor_selected.svg",
            unselected: "/img/zone-selection-tools/cursor.svg",
        },
    },
    DEFAULT: {
        id: "default",
        name: "Pan tool",
        img: {
            selected: "/img/zone-selection-tools/hand_selected.svg",
            unselected: "/img/zone-selection-tools/hand.svg",
        },
    },
    LASSO_ADD: {
        id: "lasso_add",
        name: "Lasso select",
        img: {
            selected: "/img/zone-selection-tools/lasso_plus_selected.svg",
            unselected: "/img/zone-selection-tools/lasso_plus.svg",
        },
    },
    LASSO_REMOVE: {
        id: "lasso_remove",
        name: "Lasso deselect",
        img: {
            selected: "/img/zone-selection-tools/lasso_minus_selected.svg",
            unselected: "/img/zone-selection-tools/lasso_minus.svg",
        },
    },
} as const;

export type TZoneSelectionSelectedTool =
    typeof ZONE_SELECTION_TOOLS[keyof typeof ZONE_SELECTION_TOOLS];

export type TZoneSelectionSelectedToolId = TZoneSelectionSelectedTool["id"];

export const ZONE_SELECTION_TOOLS_LIST = Object.values(
    ZONE_SELECTION_TOOLS,
) as Array<TZoneSelectionSelectedTool>;

export const ZONE_SELECTION_HISTORY_ACTIONS = {
    HISTORY_UNDO: {
        id: "history_undo",
        name: "Undo selection action",
        img: {
            selected: "/img/zone-selection-tools/undo_selected.svg",
            unselected: "/img/zone-selection-tools/undo.svg",
        },
    },
    HISTORY_REDO: {
        id: "history_redo",
        name: "Redo selection action",
        img: {
            selected: "/img/zone-selection-tools/redo_selected.svg",
            unselected: "/img/zone-selection-tools/redo.svg",
        },
    },
};
export type TZoneSelectionHistoryAction =
    typeof ZONE_SELECTION_HISTORY_ACTIONS[keyof typeof ZONE_SELECTION_HISTORY_ACTIONS];

export const ZONE_SELECTION_HISTORY_ACTIONS_LIST = Object.values(
    ZONE_SELECTION_HISTORY_ACTIONS,
) as Array<TZoneSelectionHistoryAction>;

export const CREATE_SEGMENTS_GROUP_TYPES = {
    [SEGMENT_FILTERS.CORRIDORS.code]: {
        id: SEGMENT_FILTERS.CORRIDORS.code,
        name: "Corridor Group",
        type: "Corridor",
    },
    [SEGMENT_FILTERS.AREAS.code]: {
        id: SEGMENT_FILTERS.AREAS.code,
        name: "Area of Interest",
        type: "Area",
    },
} as const;

export type TCreateSegmentsGroupType =
    typeof CREATE_SEGMENTS_GROUP_TYPES[keyof typeof CREATE_SEGMENTS_GROUP_TYPES];

export const MAP_ZOOM = {
    MAX: 25,
};

export const MAP_STYLES = {
    LINE_WIDTH: 6,
    COLORS: {
        UNSELECTED: COLORS.LIGHT_GREY,
        SELECTED: COLORS.BLUE,
        HOVER: COLORS.WHITE,
    },
};

//Need to draw region over segments
export const DEFAULT_DRAW_POLYGON_LAYER_ID = "gl-draw-polygon-fill-active.cold";

export const POPUP_OFFSET = {
    top: [0, 30],
    bottom: [0, -10],
    left: [20, 0],
    right: [-10, 0],
} as {
    [key: string]: PointLike;
};
