import { ReactNode, ElementType } from "react";
import { History, Location } from "history";
import { RouteMatch } from "react-router/lib/router";
import { Route, Redirect, useLocation, matchPath } from "react-router-dom";
import { useStore } from "react-redux";
import { WithTitle } from "@common/helpers/helmet.helper";
import { ROUTES } from "@common/constants/routes.constants";
import {
    getIsUserAuthenticated,
    isAuthRequired,
} from "@app/store/currentUser/currentUser.helpers";
import {
    getCurrentUser,
    getHasAccessToInSight,
    getHasAccessToInSightGo,
    getHasAccessToInSightSafety,
    getHasAccessToViz3,
} from "@app/store/currentUser/currentUser.selector";
import { REDIRECT_KEY } from "@app/store/currentUser/currentUser.constants";

const renderRouteContent = ({
    children,
    component: Component,
    render,
    match,
    location,
    history,
    title,
}: {
    children?: ReactNode;
    component?: ElementType;
    render?: (value?: TRouteProps) => ReactNode;
    match: RouteMatch;
    location: Partial<Location>;
    history: History;
    title?: ReactNode;
}) => {
    const props = { match, location, history };

    if (match) {
        if (children) {
            return typeof children === "function" ? children(props) : children;
        } else if (Component) {
            return (
                <WithTitle title={title}>
                    <Component {...props} />
                </WithTitle>
            );
        } else if (render) {
            return render(props);
        }

        return null;
    } else {
        return typeof children === "function" ? children(props) : null;
    }
};

export type TRouteProps = {
    history: History;
    location: Partial<Location>;
    match: RouteMatch;
};

type TProps = {
    path: string;
    children?: ReactNode;
    component?: ElementType;
    render?: (value?: TRouteProps) => ReactNode;
    title?: ReactNode;
};

export const ProtectedRoute = ({ path, children, component, render, title }: TProps) => {
    const store = useStore();

    const isUserAuthenticated = getIsUserAuthenticated();
    const currentUser = getCurrentUser(store.getState());

    const location = useLocation();

    const routeRender = (routeProps: TRouteProps) => {
        const { pathname } = routeProps.location;

        const _render = () =>
            renderRouteContent({ ...routeProps, children, component, render, title });

        // Big Bang view doesn't have 'path' and all of the routes from it should require authentication
        if (path && !isAuthRequired(path)) {
            return _render();
        }

        if (!isUserAuthenticated) {
            let redirectPath = "";

            if (location.search) {
                redirectPath = `/${location.search}`;
            } else if (location.pathname.length > 1) {
                redirectPath = REDIRECT_KEY + location.pathname;
            }

            const loginPath = `/login${redirectPath}`;

            return <Redirect to={loginPath} />;
        }

        if (!currentUser.user.org) {
            if (pathname?.includes("/chooseOrg")) {
                return _render();
            }

            return <Redirect to="/chooseOrg" />;
        }

        if (!currentUser.isSuper && !currentUser.isUAFSigned) {
            if (pathname === "/verifySignature") {
                return _render();
            }

            return <Redirect to="/logout" />;
        }

        if (currentUser.user.org?.is_study_enabled && !currentUser.user.study) {
            if (pathname === "/chooseStudy") {
                return _render();
            }

            if (pathname?.includes("/chooseOrg") && currentUser.canChangeOrg) {
                return _render();
            }

            return <Redirect to="/chooseStudy" />;
        }

        if (matchPath(pathname as string, ROUTES.SAFETY)) {
            const accessToInSightSafety = getHasAccessToInSightSafety(store.getState());

            if (!accessToInSightSafety) return <Redirect to="/status403" />;
        }

        if (matchPath(pathname as string, ROUTES.PIRATE_SHIP)) {
            const accessToInSightGo = getHasAccessToInSightGo(store.getState());

            if (!accessToInSightGo) return <Redirect to="/status403" />;
        } else if (!matchPath(pathname as string, ROUTES.DEFAULT)) {
            const accessToInSight = getHasAccessToInSight(store.getState());

            if (!accessToInSight) return <Redirect to="/status403" />;
        }

        if (
            matchPath(pathname as string, ROUTES.VIZ3) ||
            matchPath(pathname as string, ROUTES.VIZ3_SHARED_LINK)
        ) {
            const accessToViz3 = getHasAccessToViz3(store.getState());

            if (!accessToViz3) return <Redirect to="/status403" />;
        }

        return _render();
    };
    // @ts-ignore Property 'render' does not exist on type '(PathRouteProps | LayoutRouteProps)'
    return <Route path={path} render={routeRender} />;
};
