export const SORT_BY_OPTIONS = {
    DEFAULT: { label: "Bin", value: "default" },
    METRIC_VALUE_ASC: { label: "Metric Value (Ascending)", value: "metricValueAscending" },
    METRIC_VALUE_DESC: { label: "Metric Value (Descending)", value: "metricValueDescending" },
};
export const SORT_BY_OPTIONS_LIST = Object.values(SORT_BY_OPTIONS);

export type TSortByOption = typeof SORT_BY_OPTIONS_LIST[number];

export const CHART_TYPE_OPTIONS = {
    HISTOGRAM: { label: "Histogram", value: "histogram" },
    DEFAULT: { label: "Pie Chart", value: "default" },
};
export const CHART_TYPE_OPTIONS_LIST = Object.values(CHART_TYPE_OPTIONS);

export type TChartTypeOption = typeof CHART_TYPE_OPTIONS_LIST[number];
