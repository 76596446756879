interface ISearchFilterOptions {
    replaceSpecialSymbols: boolean;
    [key: string]: unknown;
}

const escapeRegExp = (s: string) => s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
const replacer = (str: string) =>
    str.replace(/[_|!|$|%|#|&|*|{|}|?|-]/g, " ").replace(/\s+/g, " ");

export const searchFilter = (
    input: number | string,
    query: string,
    options: ISearchFilterOptions = { replaceSpecialSymbols: true },
) => {
    if (!input) {
        return false;
    }
    const replaceSpecialSymbols = options?.replaceSpecialSymbols;
    let inputStr = String(input).toLowerCase();
    let queryStr = query.toLowerCase();

    if (replaceSpecialSymbols) {
        inputStr = replacer(inputStr);
        queryStr = replacer(queryStr);
    }
    const words = queryStr
        .split(/\s+/g)
        .map((s: string) => s.trim())
        .filter((s: string) => !!s);
    const searchRegex = new RegExp(
        `${words.map((word: string) => `(?=.*${escapeRegExp(word)})`).join("")}.+`,
        "gi",
    );
    return searchRegex.test(inputStr);
};
