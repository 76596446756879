export const ROUTES = {
    LOGIN: "/login",
    ZONES: "/zones",
    ANALYSES: "/analyses",
    LIGHTNING_ANALYSIS: "/lightning-analysis",
    VIZ3: "/viz3",
    VIZ3_SHARED_LINK: "/viz3/shared",
    STUDIES: "/studies",
    DASHBOARD: "/dashboard",
    DASHBOARD_USERS: "/dashboard/users",
    DASHBOARD_STUDIES: "/dashboard/studies",
    DASHBOARD_ORGS: "/dashboard/orgs",
    DASHBOARD_SUMMARY: "/dashboard/summary",
    DASHBOARD_SUBSCRIPTIONS: "/dashboard/subscriptions",
    DASHBOARD_ANALYSES: "/dashboard/analyses",
    ADMIN: "/admin",
    ADMIN_USERS: "/admin/users",
    ADMIN_USER: "/admin/users/:userId",
    ADMIN_STUDIES: "/admin/studies",
    ADMIN_ORGS: "/admin/orgs",
    ADMIN_ORG: "/admin/orgs/:orgId",
    ADMIN_SSO_DOMAINS: "/admin/sso-domains",
    ADMIN_PENDING_ANALYSES: "/admin/pending-analyses",
    ADMIN_REVIEW_ANALYSES: "/admin/flagged-analyses",
    ADMIN_AUTO_PASSED_ZONES: "/admin/auto-passed-zones",
    ADMIN_MA_API_REQUESTS: "/admin/ma-api-requests",
    ADMIN_ABOUT: "/admin/about",
    PIRATE_SHIP: "/go",
    PIRATE_SHIP_ANALYZE: "/go/analyze",
    PIRATE_SHIP_RESULTS: "/go/results",
    PIRATE_SHIP_SHARED_LINK: "/go/results/shared",
    PROJECTS: "/projects",
    PROJECT_FOLDER: "/projects/:projectId",
    SAFETY: "/safety-prioritize",
    DEFAULT: "/",
};
