import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserAPI } from "@common/services/server/adminApi.types";
import { TSelectedUser } from "@app/bigBang/admin/users/users.types";
import { USERS_INITIAL_STATE } from "./users.state";

export const { actions, reducer } = createSlice({
    name: "usersReducer",
    initialState: USERS_INITIAL_STATE,
    reducers: {
        setUsers: (state, action: PayloadAction<Array<IUserAPI>>) => {
            state.users = action.payload;
        },
        setSelectedUser: (state, action: PayloadAction<TSelectedUser>) => {
            state.selectedUser = action.payload;
        },
        updateSelectedUser: (state, action: PayloadAction<Partial<TSelectedUser>>) => {
            state.selectedUser = {
                ...state.selectedUser,
                ...action.payload,
            };
        },
    },
});

export const usersReducer = reducer;
