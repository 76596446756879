import { lazy } from "react";
import { ROUTES } from "@common/constants/routes.constants";

const PirateShipLayout = lazy(() => import("@app/pirateShip/layout/pirateShipLayout"));
const PirateShipScreenshot = lazy(
    () => import("@app/pirateShip/screenshots/pirateShipScreenshots.page"),
);

export const routes = [
    {
        path: `${ROUTES.PIRATE_SHIP}/screenshots/:pirateShipStateId`,
        component: PirateShipScreenshot,
        id: "pirateShip.Screenshots",
    },
    {
        path: `${ROUTES.PIRATE_SHIP}`,
        component: PirateShipLayout,
        id: "pirateShipLayout",
    },
];
