import { KILOBYTE, MEGABYTE, UPLOAD_ERRORS } from "./fileUpload.constants";

export interface IFileValidation {
    checkOnlyFileType?: boolean;
    checkOnlyFileSize?: boolean;
}

export const roundSize = (size: number) => {
    return Math.round(size * 10) / 10;
};

export const isFileSizeAllowed = (fileSize: number, maxSize: number) => {
    return fileSize <= maxSize * MEGABYTE;
};

export const isFileTypeAllowed = (fileName: string, allowedTypes: Array<string>) => {
    const loweredFileName = fileName.toLowerCase();
    const fileTypeRegExp = (type: string) => new RegExp(`\\${type}$`);
    return allowedTypes.some(type => fileTypeRegExp(type).test(loweredFileName));
};

export const getValidFiles = (
    files: Array<File>,
    maxSize: number,
    allowedTypes: Array<string>,
    fileValidation: IFileValidation,
) => {
    if (fileValidation?.checkOnlyFileType) {
        return files.filter(file => isFileTypeAllowed(file.name, allowedTypes));
    }
    if (fileValidation?.checkOnlyFileSize) {
        return files.filter(file => isFileTypeAllowed(file.name, allowedTypes));
    }

    return files.filter(
        file =>
            isFileSizeAllowed(file.size, maxSize) && isFileTypeAllowed(file.name, allowedTypes),
    );
};

export const filesAreValid = (
    files: Array<File>,
    maxSize: number,
    allowedTypes: Array<string>,
    fileValidation: IFileValidation = {},
) => files.length === getValidFiles(files, maxSize, allowedTypes, fileValidation).length;

export const filesExistAndValid = (
    files: Array<File>,
    maxSize: number,
    allowedTypes: Array<string>,
) => files.length && filesAreValid(files, maxSize, allowedTypes);

export const getFileSizeString = (size: number) => {
    let sizeString;

    if (size > MEGABYTE) {
        sizeString = `${roundSize(size / MEGABYTE)} mb`;
    } else if (size < KILOBYTE) {
        sizeString = `${roundSize(size)} bytes`;
    } else {
        sizeString = `${roundSize(size / KILOBYTE)} kb`;
    }

    return sizeString;
};

export const getFileErrorString = (
    file: File,
    maxSize: number,
    allowedTypes: Array<string>,
    fileValidation: IFileValidation,
) => {
    if (fileValidation?.checkOnlyFileType) {
        return !isFileTypeAllowed(file.name, allowedTypes) ? UPLOAD_ERRORS.FILE_TYPE : "";
    }

    if (fileValidation?.checkOnlyFileSize) {
        return !isFileSizeAllowed(file.size, maxSize) ? UPLOAD_ERRORS.FILE_SIZE : "";
    }

    if (!isFileSizeAllowed(file.size, maxSize)) {
        return UPLOAD_ERRORS.FILE_SIZE;
    }

    if (!isFileTypeAllowed(file.name, allowedTypes)) {
        return UPLOAD_ERRORS.FILE_TYPE;
    }

    return "";
};
