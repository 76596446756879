import React, { useRef, ReactNode } from "react";
import { StlButton } from "@common/components/button/button";

type TProps = {
    children: ReactNode;
    disabled?: boolean;
    isMultiple?: boolean;
    onFileSelect: (file: File | Array<File>) => void;
    variant?: "primary" | "secondary" | "link" | "naked";
    startIcon?: JSX.Element;
};

export const StlFileUploadButton = ({
    variant = "primary",
    onFileSelect,
    children,
    isMultiple = false,
    ...props
}: TProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const openFileSelectDialog = () => {
        inputRef.current?.click();
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        const newFiles = [].slice.call(event.target.files);

        onFileSelect(isMultiple ? newFiles : newFiles[0]);
        event.target.value = "";
    };

    return (
        <>
            <input
                ref={inputRef}
                type="file"
                className="hidden"
                hidden
                data-testid="file-upload-input"
                aria-hidden
                onChange={handleFileSelect}
                multiple={isMultiple}
            />
            <StlButton variant={variant} onClick={openFileSelectDialog} {...props}>
                {children}
            </StlButton>
        </>
    );
};
