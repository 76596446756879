import { TIME_PERIODS_ACTION_TYPES } from "@app/analysis/timePeriods/state/timePeriods.actionTypes";
import { DAY_PARTS, DAY_TYPES } from "@app/analysis/timePeriods/state/timePeriods.constants";
import { TIME_PERIODS_INITIAL_STATE } from "@app/analysis/timePeriods/state/timePeriods.state";

const isFieldsInvalid = validationFields =>
    Object.values(validationFields).some(field => field && field.isInvalid);

export const timePeriodsReducer = (state = TIME_PERIODS_INITIAL_STATE, { type, data }) => {
    switch (type) {
        case TIME_PERIODS_ACTION_TYPES.SET_USE_CUSTOM_RANGES: {
            return {
                ...state,
                shouldUseCustomRanges: data.shouldUseCustomRanges,
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_DATA_PERIOD_SETTINGS: {
            return {
                ...state,
                dataPeriodSettings: {
                    ...state.dataPeriodSettings,
                    [data.propertyName]: data.propertyValue,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.TOGGLE_EXCLUDE_DATE_RANGES: {
            return {
                ...state,
                dataPeriodSettings: {
                    ...state.dataPeriodSettings,
                    excludeDateRangesEnabled: !state.dataPeriodSettings.excludeDateRangesEnabled,
                    excludedDataPeriods: [],
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_DATA_PERIODS: {
            return {
                ...state,
                dataPeriodSettings: {
                    ...state.dataPeriodSettings,
                    dataPeriods: data.dataPeriods,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.CLEAR_DATA_PERIODS: {
            return {
                ...state,
                dataPeriodSettings: {
                    ...state.dataPeriodSettings,
                    dataPeriods: [],
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_DATA_MONTHS: {
            //Sort months in chronological order
            const sortedMonths = [...data.dataMonths].sort((a, b) => {
                const aIndex = a.year * 12 + a.month;
                const bIndex = b.year * 12 + b.month;

                return aIndex - bIndex;
            });

            return {
                ...state,
                dataMonthSettings: {
                    ...state.dataMonthSettings,
                    dataMonths: sortedMonths,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_VALIDATION: {
            const newFields = {
                ...state.validation.fields,
                ...data.validation,
            };

            return {
                ...state,
                validation: {
                    fields: newFields,
                    isInvalid: isFieldsInvalid(newFields),
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_DAY_TYPE_KIND: {
            return {
                ...state,
                dayTypeSettings: {
                    ...state.dayTypeSettings,
                    dayTypeKind: data.dayTypeKind,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_WEEKDAY_TYPE: {
            return {
                ...state,
                dayTypeSettings: {
                    ...state.dayTypeSettings,
                    weekdayType: data.weekdayType,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_DAY_TYPES: {
            return {
                ...state,
                dayTypeSettings: {
                    ...state.dayTypeSettings,
                    dayTypes: data.dayTypes,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_DRAFT_CUSTOM_DAY_TYPES: {
            return {
                ...state,
                dayTypeSettings: {
                    ...state.dayTypeSettings,
                    draftCustomDayTypes: data.draftCustomDayTypes,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_DAILY_DAY_TYPES: {
            return {
                ...state,
                dayTypeSettings: {
                    ...state.dayTypeSettings,
                    dayTypes: DAY_TYPES.getDefault(),
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.ADD_DAY_TYPE: {
            return {
                ...state,
                dayTypeSettings: {
                    ...state.dayTypeSettings,
                    dayTypes: [...state.dayTypeSettings.dayTypes, data.dayType],
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.REMOVE_DAY_TYPE: {
            const newDayTypes = state.dayTypeSettings.dayTypes.filter(
                (dayType, i) => i !== data.index,
            );

            return {
                ...state,
                dayTypeSettings: {
                    ...state.dayTypeSettings,
                    dayTypes: newDayTypes,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.CANCEL_ALL_EDIT_DAY_TYPE: {
            return {
                ...state,
                dayTypeSettings: {
                    ...state.dayTypeSettings,
                    dayTypes: data.dayTypes,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_INITIAL_DAY_TYPES: {
            return {
                ...state,
                dayTypeSettings: {
                    ...state.dayTypeSettings,
                    dayTypes: TIME_PERIODS_INITIAL_STATE.dayTypeSettings.dayTypes,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.CLEAR_DAY_TYPES: {
            return {
                ...state,
                dayTypeSettings: {
                    ...state.dayTypeSettings,
                    dayTypes: [DAY_TYPES.ALL_DAYS],
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_DAY_PART_KIND: {
            return {
                ...state,
                dayPartSettings: {
                    ...state.dayPartSettings,
                    dayPartKind: data.dayPartKind,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.CANCEL_ALL_EDIT_DAY_PART: {
            return {
                ...state,
                dayPartSettings: {
                    ...state.dayPartSettings,
                    dayParts: data.dayParts,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_DAY_PARTS: {
            return {
                ...state,
                dayPartSettings: {
                    ...state.dayPartSettings,
                    dayParts: data.dayParts,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_DRAFT_CUSTOM_DAY_PARTS: {
            return {
                ...state,
                dayPartSettings: {
                    ...state.dayPartSettings,
                    draftCustomDayParts: data.draftCustomDayParts,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_INITIAL_DAY_PARTS: {
            return {
                ...state,
                dayPartSettings: {
                    ...state.dayPartSettings,
                    dayParts: TIME_PERIODS_INITIAL_STATE.dayPartSettings.dayParts,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_INITIAL_15_MINUTE_BINS_DAY_PARTS: {
            return {
                ...state,
                dayPartSettings: {
                    ...state.dayPartSettings,
                    dayParts: DAY_PARTS.get15MinuteBinsDefault(),
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.CLEAR_DAY_PARTS: {
            return {
                ...state,
                dayPartSettings: {
                    ...state.dayPartSettings,
                    dayParts: data.is15MinuteBinsModeActive ? [] : [DAY_PARTS.ALL_DAY],
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_DATA_PERIOD: {
            return {
                ...state,
                dataPeriodSettings: {
                    ...state.dataPeriodSettings,
                    [data.dataPeriodType]: data.dataPeriod,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.ADD_DAY_PART: {
            return {
                ...state,
                dayPartSettings: {
                    ...state.dayPartSettings,
                    dayParts: [...state.dayPartSettings.dayParts, data.dayPart],
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.REMOVE_DAY_PART: {
            const newDayParts = state.dayPartSettings.dayParts.filter(
                (dayPart, i) => i !== data.index,
            );

            return {
                ...state,
                dayPartSettings: {
                    ...state.dayPartSettings,
                    dayParts: newDayParts,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.RESET_REDUCER: {
            return TIME_PERIODS_INITIAL_STATE;
        }

        case TIME_PERIODS_ACTION_TYPES.SET_INITIAL_DATA: {
            return {
                ...state,
                ...data.timePeriodsData,
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_AADT_YEAR: {
            return {
                ...state,
                aadtSettings: {
                    ...state.aadtSettings,
                    aadtYear: data.aadtYear,
                },
            };
        }

        case TIME_PERIODS_ACTION_TYPES.SET_DEFAULT_AADT_YEAR: {
            return {
                ...state,
                aadtSettings: {
                    ...state.aadtSettings,
                    defaultAADTYear: data.aadtYear,
                },
            };
        }

        default:
            return state;
    }
};
