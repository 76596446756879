import { ANALYSIS_CONSTANTS } from "@app/analysis/state/analysisConfiguration.constants";
import { getBasics } from "@app/analysis/basics/state/basics.selectors";
import * as addOnsActions from "@app/analysis/addOns/state/addOns.actions";
import { getTripAttrSettings } from "@app/analysis/addOns/state/addOns.selectors";
import { getIncrementedRange } from "@app/analysis/addOns/state/addOns.helpers";
import {
    TRIP_BIN_TYPES,
    TRIP_BIN_TYPES_LIST,
    EMPTY_TRIP_BIN_ADD_INPUT,
    TAllTripBinTypes,
} from "@app/analysis/addOns/state/addOns.constants";
import type { TAppDispatch } from "@app/store";
import type { TGetState } from "@app/store/root.reducer";
import type { TTravelMode } from "@common/constants/analysis.constants";
import type { TMeasurementUnits } from "@common/constants/measurementUnits.constants";
import type { ITripUiState, TValue, IBin } from "@app/analysis/addOns/state/addOns.types";
import { getTripBinDefaults } from "./tripParams.helpers";

export const validateTripBins = (bins: Array<IBin> | null, binType: TAllTripBinTypes) => {
    if (!bins) return "";

    const max = ANALYSIS_CONSTANTS.MAX_PREM_A_BINS;

    if (!bins.length) {
        return `${binType.name} bins cannot be empty. Please select at least one bin`;
    } else if (bins.length > max) {
        return `Number of bins has exceeded the maximum: ${max}`;
    } else {
        // Check for uniqueness of start and end values.
        const binRanges = new Set();

        for (const bin of bins) {
            const range = `${bin.start}-${bin.end}`;
            if (binRanges.has(range)) {
                return binType.code === TRIP_BIN_TYPES.PERCENTILE.code ||
                    binType.code === TRIP_BIN_TYPES.TRAVEL_TIME_PERCENTILE.code
                    ? "Bin must have unique value"
                    : "Bins must have unique start and end values";
            } else {
                if (
                    typeof binType.minValue === "number" &&
                    (bin.start < binType.minValue ||
                        (typeof bin.end === "number" && bin.end < binType.minValue))
                ) {
                    return `Bins value must be more than ${binType.minValue - 1}`;
                }
                if (
                    typeof binType.maxValue === "number" &&
                    (bin.start > binType.maxValue ||
                        (typeof bin.end === "number" && bin.end > binType.maxValue))
                ) {
                    return `Bins value must be less than ${binType.maxValue + 1}`;
                }
                binRanges.add(range);
            }
        }
    }

    return "";
};

export const validateTripBinInput = (start: TValue, end: TValue, increment: TValue) => {
    const isBinValueDefined = (value?: number | string | null): value is number =>
        value !== null && value !== "" && value !== undefined;
    const isBinValuePositiveInteger = (value?: number | string | null): value is number =>
        !isNaN(value as number) && parseInt(value as string, 10) === value && value >= 0;

    const isBinValueValid = (value?: number | string | null): value is number =>
        isBinValueDefined(value) && isBinValuePositiveInteger(value);

    if (!isBinValueValid(start)) {
        return "Please enter a positive number for the start value";
    }

    // Validate the optional bin values.
    if (isBinValueDefined(end)) {
        if (!isBinValuePositiveInteger(end)) {
            // End value should be positive integer if not omitted.
            return "Please enter a positive number for the end value";
        } else if ((start as number) >= end) {
            return "Start value cannot be greater than or equal to the end value";
        }
    }

    if (isBinValueDefined(increment)) {
        // Increment requires a valid end value.
        if (!isBinValueValid(end)) {
            return "Please enter a positive number for the end value";
        } else if (!isBinValuePositiveInteger(increment)) {
            return "Please enter a positive number for the increment";
            // Check that the range of bins can be created.
        } else if ((((((end as number) - start) as number) % increment) as number) !== 0) {
            return "Bins cannot be created with this start, end, and increment values";
        }
    }

    return "";
};

export const setTripBins =
    (binTypeCode: TAllTripBinTypes["code"], tripBins: Array<IBin> | null) =>
    (dispatch: TAppDispatch) => {
        dispatch(addOnsActions.setTripBins({ binTypeCode, tripBins }));
    };

export const updateTripBins =
    (travelModeCode: TTravelMode["code"], measurementUnit: TMeasurementUnits["id"]) =>
    (dispatch: TAppDispatch) => {
        TRIP_BIN_TYPES_LIST.forEach(tripBin => {
            const defaultValue = getTripBinDefaults(
                tripBin,
                travelModeCode,
                measurementUnit,
            )! as Array<IBin>;

            if (defaultValue) {
                dispatch(setTripBins(tripBin.code, defaultValue));
            }
        });
    };

export const removeTripBin =
    ({ index, binType }: { index: number; binType: TAllTripBinTypes }) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const availableTripAttrOptions = getTripAttrSettings(state);
        const newTripBins = (availableTripAttrOptions[binType.code]! as Array<IBin>).filter(
            (bin, binIndex) => binIndex !== index,
        );

        dispatch(setTripBins(binType.code, newTripBins));
    };

export const editTripBin =
    ({ index, binType }: { index: number; binType: TAllTripBinTypes }) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const availableTripAttrOptions = getTripAttrSettings(state);

        const newTripBins = [...(availableTripAttrOptions[binType.code] as Array<IBin>)];
        newTripBins[index] = { ...newTripBins[index], editMode: true } as IBin;

        dispatch(setTripBins(binType.code, newTripBins));
        dispatch(addOnsActions.editTripBin({ binUiStateName: binType.uiStateName }));
    };

export const saveEditTripBin =
    ({
        binType,
        start,
        end,
        index,
        onlyStartValue,
    }: {
        binType: TAllTripBinTypes;
        start: number;
        end: number;
        index: number;
        onlyStartValue: boolean;
    }) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const availableTripAttrOptions = getTripAttrSettings(state);

        const currentBins = availableTripAttrOptions[binType.code] as Array<IBin>;

        const newTripBins = currentBins.map((bin: IBin, binIndex: number) => {
            if (binIndex !== index) return bin;

            let tripBinsError = validateTripBinInput(start, end, null);

            if (tripBinsError) {
                return {
                    ...bin,
                    reason: tripBinsError,
                };
            }

            //We need to update start and end values so that validation for unique items works
            const tripBinsForValidation = currentBins.map(
                (currBin: IBin, currBinIndex: number) => {
                    return index === currBinIndex
                        ? {
                              ...currBin,
                              start: start,
                              end: end,
                          }
                        : currBin;
                },
            );

            tripBinsError = validateTripBins(tripBinsForValidation, binType);

            if (tripBinsError) {
                return {
                    ...bin,
                    reason: tripBinsError,
                };
            }

            return onlyStartValue ? { start, end, onlyStartValue } : { start, end };
        });

        dispatch(setTripBins(binType.code, newTripBins));
        dispatch(
            addOnsActions.saveEditTripBin({ binUiStateName: binType.uiStateName, newTripBins }),
        );
    };

export const saveNewTripBin =
    ({
        binType,
        start,
        end,
        increment,
    }: {
        binType: TAllTripBinTypes;
        start: TValue;
        end: TValue;
        increment: TValue;
    }) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const availableTripAttrOptions = getTripAttrSettings(state);

        const newBinUiState = {} as ITripUiState;

        let tripBinsError = validateTripBinInput(start, end, increment);

        if (tripBinsError.length > 0) {
            newBinUiState.reason = tripBinsError;
        } else {
            const generatedBins = binType.rangeFunction
                ? binType.rangeFunction(start as number, end as number, increment as number)
                : getIncrementedRange({
                      endPoint: end as number,
                      startPoint: start as number,
                      increment: increment as number,
                  });

            const newTripBins = [
                ...(availableTripAttrOptions[binType.code] as Array<IBin>),
                ...generatedBins,
            ] as Array<IBin>;

            tripBinsError = validateTripBins(newTripBins, binType);

            newBinUiState.reason = tripBinsError;

            if (!tripBinsError.length) {
                newBinUiState.addInput = EMPTY_TRIP_BIN_ADD_INPUT;
                newBinUiState.mode = "";

                dispatch(setTripBins(binType.code, newTripBins));
            }
        }
        dispatch(
            addOnsActions.saveNewTripBin({
                binUiStateName: binType.uiStateName,
                binUiState: newBinUiState,
            }),
        );
    };

export const setInitialTripBins =
    (tripBin: TAllTripBinTypes) => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const { travelModeCode, measurementUnit } = getBasics(state);
        const defaultValue = getTripBinDefaults(
            tripBin,
            travelModeCode,
            measurementUnit,
        ) as Array<IBin>;

        dispatch(setTripBins(tripBin.code, defaultValue));
        dispatch(addOnsActions.setInitialTripBin(tripBin.uiStateName));
    };

export const clearTripBin = (tripBin: TAllTripBinTypes) => (dispatch: TAppDispatch) => {
    dispatch(setTripBins(tripBin.code, []));
    dispatch(addOnsActions.setInitialTripBin(tripBin.uiStateName));
};

export const cancelAllTripBin =
    (tripBin: TAllTripBinTypes) => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const availableTripAttrOptions = getTripAttrSettings(state);

        const newTripBins =
            (availableTripAttrOptions[tripBin.code]?.map(bin => ({
                ...bin,
                editMode: false,
                reason: "",
            })) as Array<IBin>) || null;

        dispatch(setTripBins(tripBin.code, newTripBins));
        dispatch(addOnsActions.setInitialTripBin(tripBin.uiStateName));
    };

export const cancelAllTripAttr = () => (dispatch: TAppDispatch) => {
    TRIP_BIN_TYPES_LIST.forEach(tripBinType => dispatch(cancelAllTripBin(tripBinType)));
};
