import { combineReducers } from "@reduxjs/toolkit";
import { baseVizReducer } from "@app/viz3/base/state/baseViz.reducer";
import { aadtVizReducer } from "@app/viz3/aadtVisualization/state/aadtViz.reducer";
import { csVizReducer } from "@app/viz3/csVisualization/state/csViz.reducer";
import { atVizReducer } from "@app/viz3/atVisualization/state/atViz.reducer";
import { ctVizReducer } from "@app/viz3/ctVisualization/state/ctViz.reducer";
import { odVizReducer } from "@app/viz3/odVisualization/state/odViz.reducer";
import { odmfVizReducer } from "@app/viz3/odmfVisualization/state/odmfViz.reducer";
import { odgVizReducer } from "@app/viz3/odgVisualization/state/odgViz.reducer";
import { nodVizReducer } from "@app/viz3/nodVisualization/state/nodViz.reducer";
import { npSpotVizReducer } from "@app/viz3/npSpotVisualization/state/npSpotViz.reducer";
import { rvVizReducer } from "@app/viz3/rvVisualization/state/rvViz.reducer";
import { segmentVizReducer } from "@app/viz3/segmentVisualization/state/segmentViz.reducer";
import { tmcVizReducer } from "@app/viz3/tmcVisualization/state/tmcViz.reducer";
import { trodVizReducer } from "@app/viz3/trodVisualization/state/trodViz.reducer";
import { trzaVizReducer } from "@app/viz3/trzaVisualization/state/trzaViz.reducer";
import { zaVizReducer } from "@app/viz3/zaVisualization/state/zaViz.reducer";

export const viz3Reducer = combineReducers({
    base: baseVizReducer,
    aadt: aadtVizReducer,
    cs: csVizReducer,
    at: atVizReducer,
    ct: ctVizReducer,
    od: odVizReducer,
    odmf: odmfVizReducer,
    odg: odgVizReducer,
    rv: rvVizReducer,
    nod: nodVizReducer,
    npSpot: npSpotVizReducer,
    segment: segmentVizReducer,
    tmc: tmcVizReducer,
    trod: trodVizReducer,
    trza: trzaVizReducer,
    za: zaVizReducer,
});
