import { ZONE_GEOM_TYPES } from "@common/constants/zoneSet.constants";
import { getZoneDirectionType } from "@common/features/zonesManager/state/zonesManager.helpers";
import {
    PASS_THROUGH_OPTIONS,
    ROAD_TYPE_OPTIONS,
    ZONE_GATES_OPTIONS,
} from "@common/features/zonesManager/state/zonesManager.constants";

// TODO: Review usages and replaces with more specific functions/interfaces
export const convertApiObjectToZone = zoneApiObject => {
    const zoneFeatures = {};
    const isLineZone = zoneApiObject.geom_type === ZONE_GEOM_TYPES.LINE.id;
    if (isLineZone) {
        zoneFeatures.featureStart = {
            geometry: JSON.parse(zoneApiObject.start_geometry),
        };
        zoneFeatures.featureMiddle = {
            geometry: JSON.parse(zoneApiObject.geometry),
        };
        zoneFeatures.featureEnd = {
            geometry: JSON.parse(zoneApiObject.end_geometry),
        };
        zoneFeatures.feature = {
            geometry: JSON.parse(zoneApiObject.line_geometry),
        };
    } else {
        zoneFeatures.feature = {
            geometry: JSON.parse(zoneApiObject.geometry),
        };
    }

    return {
        ...zoneApiObject,
        zone_id: zoneApiObject.zone_id,
        customer_zone_id:
            zoneApiObject.customer_zone_id !== null ? zoneApiObject.customer_zone_id : "",
        area: zoneApiObject.area,
        direction: zoneApiObject.direction ?? null,
        direction_type: getZoneDirectionType(zoneApiObject),
        is_bidi: zoneApiObject.is_bidi,
        is_pass: zoneApiObject.is_pass
            ? PASS_THROUGH_OPTIONS.PassThrough.id
            : PASS_THROUGH_OPTIONS.NonPassThrough.id,
        gate: zoneApiObject.gate || ZONE_GATES_OPTIONS.MIDDLE_GATE.id,
        road_type: zoneApiObject.road_type || ROAD_TYPE_OPTIONS.BLANK.id,
        ...zoneFeatures,
    };
};
