const ACTION_PREFIX = "ZONES_MANAGER";

export const ZONES_MANAGER_ACTIONS = {
    RESET_ZONE_MANAGER_STATE: `${ACTION_PREFIX}/RESET_ZONE_MANAGER_STATE`,
    RESET_REUSE_ZONES_MODAL_STATE: `${ACTION_PREFIX}/RESET_REUSE_ZONES_MODAL_STATE`,
    SET_REUSE_ZONES_MODAL_STATE: `${ACTION_PREFIX}/SET_REUSE_ZONES_MODAL_STATE`,
    SET_REUSE_ZONES_CONFIG: `${ACTION_PREFIX}/SET_REUSE_ZONES_CONFIG`,
    SET_ZONE_LIBRARY_TYPE: `${ACTION_PREFIX}/SET_ZONE_LIBRARY_TYPE`,
    SET_ZONE_LIBRARY_FILTERS: `${ACTION_PREFIX}/SET_ZONE_LIBRARY_FILTERS`,
    SET_NETWORK_TYPE: `${ACTION_PREFIX}/SET_NETWORK_TYPE`,
    SET_SEGMENT_SPLIT_TYPE: `${ACTION_PREFIX}/SET_SEGMENT_SPLIT_TYPE`,
    SET_SELECTED_ZONE: `${ACTION_PREFIX}/SET_SELECTED_ZONE`,
    UPDATE_SELECTED_ZONE: `${ACTION_PREFIX}/UPDATE_SELECTED_ZONE`,
    SET_CLICKED_BUS_ZONES: `${ACTION_PREFIX}/SET_CLICKED_BUS_ZONES`,
    UPDATE_CLICKED_BUS_ZONES: `${ACTION_PREFIX}/UPDATE_CLICKED_BUS_ZONES`,
    SET_ROADS: `${ACTION_PREFIX}/SET_ROADS`,
    SET_MAP_MODE: `${ACTION_PREFIX}/SET_MAP_MODE`,
    SET_EDITABLE_FEATURE: `${ACTION_PREFIX}/SET_EDITABLE_FEATURE`,
    SET_IS_EDIT_DIRECTION: `${ACTION_PREFIX}/SET_IS_EDIT_DIRECTION`,
    SET_UPLOAD_ZONES_MODAL_STATE: `${ACTION_PREFIX}/SET_UPLOAD_ZONES_MODAL_STATE`,
    UPDATE_ZONES_UPLOAD_DATA: `${ACTION_PREFIX}/UPDATE_ZONES_UPLOAD_DATA`,
    SET_UI_STATE: `${ACTION_PREFIX}/SET_UI_STATE`,
    SET_SPOT_COUNTER_OSM_LAYERS_CATEGORIES: `${ACTION_PREFIX}/SET_SPOT_COUNTER_OSM_LAYERS_CATEGORIES`,
};
