import { useEffect, useRef, MutableRefObject } from "react";
import { Map, Layer, Sources, AnySourceData } from "mapbox-gl";
import { EMapStyleIds } from "@common/components/baseMap/baseMap.types";
import { getBaseMapStyleUrl } from "@common/components/baseMap/baseMap.helpers";

type TSource = [sourceId: string, configuration: AnySourceData];

type TMapStyle = {
    sources: Array<TSource>;
    layers: Array<Layer>;
};

type TMapStyleOptions = {
    style: EMapStyleIds;
    isMounted: MutableRefObject<boolean>;
    onMapLoad: (mapInstance: Map) => void;
};

export type TWaitParams = {
    onFail?: () => void;
    failTimeout?: number;
    checkTimeout?: number;
    successAttemptsTarget?: number;
};

export const waitTillMapLoad = (
    map: Map,
    onMapLoad: (mapInstance: Map) => void,
    { checkTimeout = 200, successAttemptsTarget = 1, failTimeout = -1, onFail }: TWaitParams = {},
) => {
    // When 'styledata' event is fired, map styles is not loaded yet, so we need to check this with some delay.
    // Seems to be an issue in mapbox-gl: https://stackoverflow.com/questions/44394573/mapbox-gl-js-style-is-not-done-loading

    let checkTimeoutId: number | NodeJS.Timeout = 0;
    let endedTimeout = false;

    const failTimeoutId =
        failTimeout >= 0
            ? setTimeout(() => {
                  endedTimeout = true;
                  onFail?.();
              }, failTimeout)
            : 0;

    const onStyleData = () => {
        let successAttemptsCount = 0;
        const waiting = () => {
            if (checkTimeoutId) clearTimeout(checkTimeoutId as number);

            if (endedTimeout) {
                return;
            }

            if (!map.isStyleLoaded()) {
                successAttemptsCount = 0;
            } else {
                ++successAttemptsCount;
            }

            if (successAttemptsCount === successAttemptsTarget) {
                clearTimeout(failTimeoutId as number);
                onMapLoad(map);
            } else if (!endedTimeout) {
                checkTimeoutId = setTimeout(waiting, checkTimeout);
            }
        };
        waiting();
    };

    // 'load' event is fired before map styles are loaded, so change it to 'styledata' event
    map.once("styledata", onStyleData);
};

const loadMapStyle = (map: Map, mapStyle: TMapStyle) => {
    const { sources, layers } = mapStyle;

    // Actually we should not have cases, when there is a source or layer with such id, but we
    // would like to know about such if that is not true.
    // For example, previously Viz3 has its logic to re-trigger sources and layers creation.
    sources.forEach(([sourceId, configuration]: TSource) => {
        if (map.getSource(sourceId)) {
            console.error(`There is already a source with id ${sourceId}.`);
            return;
        }

        map.addSource(sourceId, configuration);
    });
    layers.forEach((layer: Layer) => {
        if (map.getLayer(layer.id)) {
            console.error(
                `There is already a layer with id ${layer.id} and source ${layer.source}.`,
            );
            return;
        }

        map.addLayer(layer);
    });
};

const INITIAL_STATE = {
    stlMapStyle: { sources: [], layers: [] },
};

export const useMapStyle = (
    map: Map | null,
    { style, isMounted, onMapLoad }: TMapStyleOptions,
) => {
    const stlMapStyle = useRef<TMapStyle>(INITIAL_STATE.stlMapStyle);
    const styleRef = useRef<EMapStyleIds>(style);

    useEffect(() => {
        if (!map) return;

        // We need to delay style loading check, since `map.setStyle` seems to be asynchronous
        setTimeout(() => {
            waitTillMapLoad(map, (mapInstance: Map) => {
                if (isMounted.current) {
                    onMapLoad(mapInstance);

                    loadMapStyle(map, stlMapStyle.current);
                    stlMapStyle.current = INITIAL_STATE.stlMapStyle;
                    // @ts-ignore Property 'eventManager' does not exist on type 'Map'.
                    map.eventManager.emit("map style changed");
                }
            });
        }, 0);
    }, [style, onMapLoad, map, isMounted]);

    useEffect(() => {
        if (!map || style === styleRef.current) return;

        const { sources, layers } = map.getStyle();

        styleRef.current = style;
        stlMapStyle.current = {
            sources: Object.entries(sources as Sources).filter(([sourceId]) =>
                sourceId.startsWith("stl:"),
            ),
            layers: (layers as Array<Layer>)?.filter(layer => layer.id.startsWith("stl:")),
        };

        map.setStyle(getBaseMapStyleUrl(style));
    }, [style, map]);
};
