export const BASE_MAP_STYLES = {
    NON_SELECTED_COLOR: {
        DEFAULT: "#a4a4a4",
        HOVER: "#FFFFFF",
    },
    NON_SELECTED_OPACITY: 0.8,
    LINE_WIDTH: {
        MIN: 5,
        MAX: 30,
    },
    LINE_OUTLINE: {
        WIDTH: 2,
        COLOR: {
            DEFAULT: "#000000",
            HOVER: "#FFFFFF",
        },
    },
    DISTRIBUTION_ZONES: {
        COLOR: {
            HIGH: "#FFDA6A",
            LOW: "#a4a4a4",
            HOVER: "#FFFFFF",
        },
        OPACITY: 0.7,
        Z_INDEX: 10,
        CHOROPLETH_OPACITY: {
            AREA: {
                MIN: 0.1,
                MAX: 0.7,
            },
            OSM: {
                MIN: 0.4,
                MAX: 1,
            },
        },
    },
    FILTER_ZONES: {
        COLOR: {
            HIGH: "#7AB0F5",
            LOW: "#a4a4a4",
            HOVER: "#FFFFFF",
        },
        OPACITY: 0.7,
        Z_INDEX: 9,
    },
    NON_SELECTED_LINE_COLOR: {
        DEFAULT: "#000000",
        HOVER: "#000000",
    },
    CIRCLE_RADIUS: {
        MIN: 5,
        MAX: 10,
    },
    EXTRUSION_HEIGHT: {
        MIN: 0,
        MAX: 3000,
    },
    PITCH_THRESHOLD: 10,
    DEFAULT_Z_INDEX: 1,
};

const MIN_ZOOM = 1;
const MAX_LINE_WIDTH = 4;
const MAX_ZOOM = 12;
const MIN_LINE_WIDTH = 2;

// Used to set line width for static unselected and outline layers
export const LINE_ZONE_WIDTH = [
    "interpolate",
    ["linear"],
    ["zoom"],
    MIN_ZOOM,
    MAX_LINE_WIDTH,
    MAX_ZOOM,
    MIN_LINE_WIDTH,
];

const MAX_FILTER_LINE_WIDTH = 8;
const MIN_FILTER_LINE_WIDTH = 6;

// Used to set line width for static filter layers
export const FILTER_LINE_ZONE_WIDTH = [
    "interpolate",
    ["linear"],
    ["zoom"],
    MIN_ZOOM,
    MAX_FILTER_LINE_WIDTH,
    MAX_ZOOM,
    MIN_FILTER_LINE_WIDTH,
];
