import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "@app/store/hooks";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDrawPolygon, faUpload, faTrash } from "@fortawesome/pro-solid-svg-icons";
import {
    StlFormError,
    StlButton,
    StlFileUploadButton,
    StlInput,
    StlIconPopover,
    StlSelect,
} from "@common/components";
import { OrgApiService } from "@common/services/server/orgApi.service";
import { useDraw, BaseMap, useJumpToLocation } from "@common/components/baseMap";
import { calculateBounds } from "@common/components/baseMap/baseMap.helpers";
import { STUDY_MODES } from "@common/features/studyModal/common/study.constants";
import {
    MEASUREMENT_UNITS,
    MEASUREMENT_UNITS_LIST,
} from "@common/constants/measurementUnits.constants";
import {
    getDefaultMapCenter,
    getMaxBufferSize,
} from "@common/features/studyModal/common/study.helpers";
import { getIsSuperUser, getUserOrg } from "@app/store/currentUser/currentUser.selector";
import { FlyToOptions, Map } from "mapbox-gl";
import { FeatureCollection } from "geojson";
import { ILocation } from "@common/components/geocoder/geocoder";
import { IErrors } from "@common/components/formError/formError";
import { EMeasurementUnits } from "@common/features/orgs/orgs.types";
import { EStudyMode, IStudy, TGeometry } from "@common/features/studyModal/common/study.types";
import "./studyRegionTab.less";

const SET_STUDY_HELP_ICON = (
    <StlIconPopover
        content="Draw or upload an area you intend to study.
                    This will form a boundary within which you will be creating Analyses in this study.
                    The region must be an area less than 100,000 sq miles (250,000 sq kilometers) in size."
    />
);

const DRAW_HELP_ICON = (
    <StlIconPopover
        title="Add Study Region"
        content={
            <div>
                <h4>To Draw a New Region:</h4>
                <ol>
                    <li>Click the Draw Region button.</li>
                    <li>Left-click for the first point.</li>
                    <li>Left-click for each additional point.</li>
                    <li>Double left-click to close the region.</li>
                </ol>
                <h4>To Upload a Shapefile:</h4>
                <ol>
                    <li>Click the Upload Shapefile Button.</li>
                    <li>
                        Select a zipped shapefile consisting of *.dbf, *.prj, *.shp, *.shx files.
                    </li>
                    <li>Click Open.</li>
                </ol>
            </div>
        }
    />
);

const getBufferPlaceholder = (measurementUnit?: EMeasurementUnits | "") => {
    if (!measurementUnit) return "";
    const maxSize = getMaxBufferSize(measurementUnit);
    return `${Number(0).toFixed(3)} to ${maxSize.toFixed(3)}`;
};

const BUFFER_REGEX = /^\d+(\.\d{0,3})?$/;

const INITIAL_STATE = {
    map: null,
    location: null,
    shapefileGeojson: null,
    isSaving: false,
};

type TProps = {
    study: IStudy;
    mode: EStudyMode;
    closeModal: () => void;
    showNextTab: () => void;
    showPreviousTab: () => void;
    saveStudy: () => Promise<unknown>;
    updateStudy: (value: Partial<IStudy>) => void;
    isValid: boolean;
    errors: IErrors;
};

const MAP_CONTROLS_CONFIG = {
    controls: { measurementTool: false },
};

export const StudyRegionTab = ({
    study,
    updateStudy,
    saveStudy,
    showNextTab,
    showPreviousTab,
    closeModal,
    errors,
    isValid,
    mode,
}: TProps) => {
    const [map, setMap] = useState<Map | null>(INITIAL_STATE.map);
    const [location, setLocation] = useState<ILocation | null>(INITIAL_STATE.location);
    const [isSaving, setIsSaving] = useState(INITIAL_STATE.isSaving);

    const isSuperUser = useAppSelector(getIsSuperUser);
    const org = useAppSelector(getUserOrg);
    const isUS = org.country_us;
    const isCA = org.country_ca;

    const isViewMode = mode === STUDY_MODES.VIEW;

    useJumpToLocation(map, location);

    const onRegionChange = useCallback(
        (geojson: FeatureCollection) => {
            if (!map) return;

            const { geometry, id } = geojson.features[0];
            const studyData = { geometry: { ...geometry, id, isDrawn: true } } as Partial<IStudy>;

            if (!study.buffer) studyData.buffer = "0";
            if (!study.measurementUnit)
                studyData.measurementUnit = MEASUREMENT_UNITS.KILOMETERS.id as EMeasurementUnits;

            updateStudy(studyData);
            map.getCanvas().style.cursor = "grab";
        },
        [map, study, updateStudy],
    );

    const onBufferChange = (value: string) => {
        // buffer value should be non-negative with max of 3 decimal places
        const matches = value.match(new RegExp(BUFFER_REGEX));
        if (matches || value === "") {
            const _value = matches ? matches[0] : value;
            updateStudy({ buffer: _value !== "" ? _value : "" });
        }
    };

    const onMeasurementUnitChange = (
        value: typeof MEASUREMENT_UNITS[keyof typeof MEASUREMENT_UNITS] | null,
    ) => {
        if (!value) return;
        updateStudy({ measurementUnit: value.id as EMeasurementUnits });
    };

    const draw = useDraw(map, onRegionChange);

    const isDrawAvailable = !!map && !!draw && (mode !== STUDY_MODES.VIEW || isSuperUser);

    const onDraw = () => {
        if (!map || !draw || draw.getMode() === "draw_polygon") return;

        draw.deleteAll();
        draw.changeMode("draw_polygon");
        map.getCanvas().style.cursor = "pointer";
    };

    const onClean = () => {
        if (!map || !draw) return;
        draw.deleteAll();
        updateStudy({ geometry: null, stagedRegionId: null, buffer: "", measurementUnit: "" });
        map.getCanvas().style.cursor = "grab";
    };

    const onFileSelect = (file: File) => {
        const formData = new FormData();

        formData.append("shapefile", file, file.name);
        formData.append("buffer", study.buffer || "");

        OrgApiService.uploadStagedRegion(formData).then(response => {
            const geometry = JSON.parse(response.staged_region.geometry);
            const stagedRegionId = response.staged_region.id;

            updateStudy({ geometry, stagedRegionId });
        });
    };

    useEffect(() => {
        if (!map || mode !== STUDY_MODES.CREATE) return;

        const zoomParams = getDefaultMapCenter(isUS, isCA);
        map.flyTo(zoomParams as FlyToOptions);
    }, [map, mode, isUS, isCA]);

    useEffect(() => {
        if (!map || !study.geometry || !draw) return;

        const { type, coordinates, id } = study.geometry;

        if (id && draw.get(String(id))) return;

        draw.deleteAll();
        draw.add({ type, coordinates } as TGeometry);

        const bounds = calculateBounds(type, coordinates);

        map.fitBounds(bounds, { padding: 20 });
    }, [study.geometry, draw, map]);

    const handleSaveButtonClick = () => {
        setIsSaving(true);

        saveStudy().finally(() => setIsSaving(false));
    };

    return (
        <div className="stl-study-region-tab">
            <div className="tab-content">
                {!isViewMode && (
                    <div className="header">
                        <div className="study-header-group">
                            <label className="stl-label">Set Custom Study Area</label>
                            <span className="stl-span">&nbsp;(Optional)</span>
                            {SET_STUDY_HELP_ICON}
                        </div>
                        <div className="region-control-group">
                            <StlButton
                                variant="primary"
                                onClick={onDraw}
                                disabled={!isDrawAvailable}
                                startIcon={<FontAwesomeIcon icon={faDrawPolygon} />}
                            >
                                Draw Region
                            </StlButton>
                            <StlFileUploadButton
                                // @ts-ignore
                                onFileSelect={onFileSelect}
                                disabled={!isDrawAvailable}
                                startIcon={<FontAwesomeIcon icon={faUpload} />}
                            >
                                Upload Shapefile
                            </StlFileUploadButton>
                            <StlButton
                                className="btn-trash"
                                aria-label="Delete Region"
                                variant="primary"
                                onClick={onClean}
                                disabled={!isDrawAvailable}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </StlButton>
                            {isDrawAvailable && DRAW_HELP_ICON}
                        </div>
                    </div>
                )}
                <BaseMap
                    onLoad={setMap}
                    className="region-map"
                    onLocationChange={setLocation}
                    location={location}
                    controlsConfig={MAP_CONTROLS_CONFIG}
                />
                <StlFormError name="geometry" errors={errors} />
                <div className="buffer-control-wrapper">
                    <div className="buffer-control-group">
                        <label htmlFor="region-buffer" className="stl-label">
                            Study Area Buffer
                        </label>
                        <StlInput
                            id="region-buffer"
                            placeholder={getBufferPlaceholder(study.measurementUnit)}
                            value={study.buffer}
                            onChange={onBufferChange}
                            type="number"
                            disabled={!study.geometry || isViewMode}
                            invalid={!!errors.buffer}
                        />
                        <StlFormError name="buffer" errors={errors} />
                    </div>
                    <div className="buffer-control-group">
                        <label htmlFor="measurement-unit-select">Unit of Measurement</label>
                        <StlSelect
                            id="measurement-unit-select"
                            options={MEASUREMENT_UNITS_LIST}
                            value={study.measurementUnit || ""}
                            placeholder="Choose Measurement Unit"
                            onChange={onMeasurementUnitChange}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            disabled={!study.geometry || isViewMode}
                        />
                        <StlFormError name="measurementUnit" errors={errors} />
                    </div>
                </div>
            </div>
            <div className="tab-actions">
                <StlButton id="studyCloseBtn" variant="secondary" onClick={closeModal}>
                    {isViewMode ? (
                        <FormattedMessage id="app.close" defaultMessage="Close" />
                    ) : (
                        <FormattedMessage id="app.cancel" defaultMessage="Cancel" />
                    )}
                </StlButton>
                <StlButton id="studyBackBtn" variant="secondary" onClick={showPreviousTab}>
                    <FormattedMessage id="app.back" defaultMessage="Back" />
                </StlButton>
                {!isViewMode && (
                    <StlButton
                        id="studySaveBtn"
                        variant="primary"
                        onClick={handleSaveButtonClick}
                        loading={isSaving}
                        disabled={isSaving || !isValid}
                    >
                        <FormattedMessage id="app.save" defaultMessage="Save" />
                    </StlButton>
                )}
            </div>
        </div>
    );
};
