import type { IRVAvailableCounty } from "@app/viz3/rvVisualization/state/rvViz.types";
import type { IDistributionLayerGroupAPI } from "@common/services/server/layerGroupApi.types";
import type { ILayerConfiguration } from "@app/viz3/viz3.types";
import type { IRVRoadwayAPI, IRVSegmentAPI } from "@common/services/server/widgetApi.types";
import { MAP_STYLES } from "@app/viz3/rvVisualization/components/content/rvMap/mapStyles.constants";

export interface IRVVizState {
    availableCounties: Array<IRVAvailableCounty>;
    distributionLayers: IDistributionLayerGroupAPI | null;
    map: {
        roadways: Array<IRVRoadwayAPI>;
        segments: Array<IRVSegmentAPI>;
        zoneIdsToZoom: Array<number | string>;
        layerConfiguration: Omit<ILayerConfiguration, "color" | "opacity">;
    };
}

export const RV_VIZ_INITIAL_STATE = {
    availableCounties: [],
    distributionLayers: null,
    map: {
        roadways: [],
        segments: [],
        zoneIdsToZoom: [],
        layerConfiguration: {
            colors: {
                high: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HIGH,
                mid: "",
                low: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.LOW,
                hover: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HOVER,
            },
            choroplethOpacity: {
                min: MAP_STYLES.DISTRIBUTION_ZONES.CHOROPLETH_OPACITY.MIN,
                max: MAP_STYLES.DISTRIBUTION_ZONES.CHOROPLETH_OPACITY.MAX,
            },
        },
    },
} as IRVVizState;
