import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type {
    IProjectFolder,
    ISelectedProjectFolder,
} from "@common/services/server/projectFolderApi.types";
import { PROJECTS_INITIAL_STATE } from "@app/bigBang/projects/state/projects.state";
import { IProjectFolderFilters } from "@common/services/server/projectFolderApi.types";
import { IMapError } from "@common/components/baseMap/mapErrorAlert/mapErrorAlert";
import { TZonesManagerZone } from "@common/features/zonesManager/zonesManager.types";

export const { actions, reducer } = createSlice({
    name: "projects",
    initialState: PROJECTS_INITIAL_STATE,
    reducers: {
        setProjectFolders: (state, action: PayloadAction<Array<IProjectFolder> | null>) => {
            state.projectFolders = action.payload;
        },
        setSelectedProjectFolder: (
            state,
            action: PayloadAction<ISelectedProjectFolder | null>,
        ) => {
            state.selectedProjectFolder = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.uiStates.isLoading = action.payload;
        },
        setFilters: (state, action: PayloadAction<IProjectFolderFilters>) => {
            state.selectedProjectFolderFilters = action.payload;
        },
        setMapError: (state, action: PayloadAction<null | IMapError>) => {
            state.uiStates.mapError = action.payload;
        },
        setHoveredZone: (state, action: PayloadAction<null | TZonesManagerZone>) => {
            state.uiStates.hoveredZone = action.payload;
        },
        setShouldFilterByPackages: (state, action: PayloadAction<boolean>) => {
            state.uiStates.shouldFilterByPackages = action.payload;
        },
    },
});

export const projectsReducer = reducer;
