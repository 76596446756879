import { ICurrentUser } from "@common/services/services.types";

class WalkmeService {
    isLocal = window.location.hostname.includes("localhost");

    get walkmeVariables() {
        // @ts-ignore Property 'WALKME_VARIABLES' does not exist on type 'Window & typeof globalThis'.
        return window.WALKME_VARIABLES;
    }

    set walkmeVariables(value) {
        // @ts-ignore Property 'WALKME_VARIABLES' does not exist on type 'Window & typeof globalThis'.
        window.WALKME_VARIABLES = value;
    }

    resetWalkmeVariables() {
        if (!this.isLocal) {
            this.walkmeVariables = {};
        }
    }

    addWalkmeVariables(currentUser: ICurrentUser) {
        if (!this.isLocal) {
            this.walkmeVariables = {
                email: currentUser.realUser.email_addr,
                org_name: currentUser.user.org.org_name,
                org_type: currentUser.user.org.org_type,
                user_id: currentUser.realUser.user_id,
                user_name: currentUser.realUser.user_name,
                org_id: currentUser.user.org.org_id,
                org_nickname: currentUser.user.org.org_nickname,
                actual_user_id: currentUser.realUser.user_id,
                effective_user_id: currentUser.user.user_id,
                actual_user_email: currentUser.realUser.email_addr,
                effective_email_address: currentUser.user.email_addr,
                is_emulating: String(currentUser.user.user_id !== currentUser.realUser.user_id),
                url_subdomain: window.location.hostname.split(".")[0],
            };
        }
    }
}

export const walkmeService = new WalkmeService();
