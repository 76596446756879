import { useCallback, useEffect, useMemo, useRef } from "react";
import { isEqual } from "lodash-es";
import type { EventData, Map } from "mapbox-gl";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { getEnhancedLayersCategories } from "@app/store/userPreferences/userPreferences.selector";
import { useClick, useHover } from "@common/components/baseMap";
import { _createHighlightZone } from "@common/components/baseMap/baseMap.helpers";
import { useDACZoneLayers } from "@common/features/mapEnhancedLayers/hooks/useDACZoneLayers";
import { useFEMAZoneLayers } from "@common/features/mapEnhancedLayers/hooks/useFEMAZoneLayers";
import {
    setHoveredEnhancedLayerZone,
    setSelectedEnhancedLayerZone,
} from "@common/features/mapEnhancedLayers/state/mapEnhancedLayers.actions";
import { ENHANCED_LAYERS } from "@common/features/mapEnhancedLayers/state/mapEnhancedLayers.constants";
import { getMapEnhancedLayersState } from "@common/features/mapEnhancedLayers/state/mapEnhancedLayers.selectors";
import { TMapLayersLocalState } from "../mapLayers.constants";

export const EnhancedLayers = ({
    map,
    mapLayersLocalState,
}: {
    map: Map | null;
    mapLayersLocalState?: Pick<TMapLayersLocalState, "enhancedLayersCategories"> | null;
}) => {
    const persistedEnhancedDataLayer = useAppSelector(getEnhancedLayersCategories);
    const { selectedEnhancedLayerZone, hoveredLegendSwatch } = useAppSelector(
        getMapEnhancedLayersState,
    );

    const dispatch = useAppDispatch();

    const selectedEnhancedDataLayer = useMemo(() => {
        return mapLayersLocalState
            ? mapLayersLocalState.enhancedLayersCategories
            : persistedEnhancedDataLayer;
    }, [mapLayersLocalState, persistedEnhancedDataLayer]);

    const prevEnhancedLayerState = useRef(selectedEnhancedDataLayer);

    const dacLayers = useDACZoneLayers({
        map: map,
        show:
            selectedEnhancedDataLayer.enabled &&
            selectedEnhancedDataLayer.category === ENHANCED_LAYERS.DAC_ZONES.code,
        selectedZone: selectedEnhancedLayerZone,
    });
    const femaLayers = useFEMAZoneLayers({
        map: map,
        activeLayer: selectedEnhancedDataLayer,
        selectedZone: selectedEnhancedLayerZone,
        hoveredLegendSwatch,
    });

    const handleHoverDACZone = useCallback(
        (e: EventData) => {
            const feature = e.features?.[0]?.properties || null;
            dispatch(
                setHoveredEnhancedLayerZone(feature, ENHANCED_LAYERS.DAC_ZONES.featureIdProperty),
            );
        },
        [dispatch],
    );

    const handleHoverFEMAZone = useCallback(
        (e: EventData) => {
            const feature = e.features?.[0]?.properties || null;
            dispatch(
                setHoveredEnhancedLayerZone(feature, ENHANCED_LAYERS.NRI_ZONES.featureIdProperty),
            );
        },
        [dispatch],
    );

    const handleSelectDACZone = useCallback(
        (e: EventData) => {
            const feature = e.features?.[0]?.properties || null;
            const featureId = ENHANCED_LAYERS.DAC_ZONES.featureIdProperty;
            const selectedZone =
                feature?.[featureId] !== selectedEnhancedLayerZone?.[featureId] ? feature : null;
            dispatch(setSelectedEnhancedLayerZone(selectedZone, featureId));
        },
        [dispatch, selectedEnhancedLayerZone],
    );

    const handleSelectFEMAZone = useCallback(
        (e: EventData) => {
            const feature = e.features?.[0]?.properties || null;
            const featureId = ENHANCED_LAYERS.NRI_ZONES.featureIdProperty;
            const selectedZone =
                feature?.[featureId] !== selectedEnhancedLayerZone?.[featureId] ? feature : null;
            dispatch(setSelectedEnhancedLayerZone(selectedZone, featureId));
        },
        [dispatch, selectedEnhancedLayerZone],
    );

    const createHighlightZone = useCallback(() => _createHighlightZone(), []);

    useHover(map, {
        layers: dacLayers,
        onHover: handleHoverDACZone,
        featureIdProperty: ENHANCED_LAYERS.DAC_ZONES.featureIdProperty,
        disableAnimatedHover: true,
        disableCursorChanging: true,
        createHighlightZone,
    });

    useHover(map, {
        layers: femaLayers,
        onHover: handleHoverFEMAZone,
        featureIdProperty: ENHANCED_LAYERS.NRI_ZONES.featureIdProperty,
        disableAnimatedHover: true,
        disableCursorChanging: true,
        createHighlightZone,
    });

    useClick(map, {
        layers: dacLayers,
        onClick: handleSelectDACZone,
    });

    useClick(map, {
        layers: femaLayers,
        onClick: handleSelectFEMAZone,
    });

    // Reset selectedEnhancedLayerZone when enhanced map layer is changed
    useEffect(() => {
        if (!isEqual(prevEnhancedLayerState.current, selectedEnhancedDataLayer)) {
            dispatch(setSelectedEnhancedLayerZone!(null, ""));
            prevEnhancedLayerState.current = selectedEnhancedDataLayer;
        }
    }, [dispatch, selectedEnhancedDataLayer]);

    return null;
};
