import { ANALYSIS_STATUSES } from "@common/constants/analysis.constants";
import BicycleAndPedestrianActivityHeatmapIcon from "@root/src/assets/img/pirate-ship-imgs/metrics/bicycleAndPedestrianActivityHeatmap.svg";
import CorridorCongestionAnalysisIcon from "@root/src/assets/img/pirate-ship-imgs/metrics/corridorCongestionAnalysis.svg";
import LocationVisitationPatternsIcon from "@root/src/assets/img/pirate-ship-imgs/metrics/locationVisitationPatterns.svg";
import RoadwayVehicleVolumeIcon from "@root/src/assets/img/pirate-ship-imgs/metrics/roadwayVehicleVolume.svg";
import type { PointLike } from "mapbox-gl";

type TPopupOffset = {
    [key: string]: PointLike;
};

export const STEPS = {
    SELECT_LOCATION: "SELECT_LOCATION",
    SELECT_ZONES: "SELECT_ZONES",
    SELECT_PERIODS: "SELECT_PERIODS",
    PREVIEW_DETAILS: "PREVIEW_DETAILS",
} as const;

export const STEPS_LIST = Object.values(STEPS);
export type TStep = keyof typeof STEPS;

export const ALL_VEHICLES_MODE_TRAVEL = {
    id: "All_Vehicles",
    name: "All Vehicles",
} as const;

export const ZONE_COUNT_LIMIT = 10;

export const DEFAULT_PERIOD = 2019;

export const POPUP_OFFSET = {
    top: [0, 30],
    bottom: [0, -10],
    left: [20, 0],
    right: [-10, 0],
} as TPopupOffset;

export const ROAD_COLORS = {
    light: {
        FIRST_ROAD: "#FF0000",
        SECOND_ROAD: "#0047FF",
        THIRD_ROAD: "#419100",
        FOURTH_ROAD: "#E000E0",
        FIFTH_ROAD: "#D35E00",
        SIXTH_ROAD: "#008BAA",
        SEVENTH_ROAD: "#A47A00",
        EIGHTH_ROAD: "#AD00FF",
        NINTH_ROAD: "#BB007B",
        TENTH_ROAD: "#007FF5",
    },
    dark: {
        FIRST_ROAD: "#FF5353",
        SECOND_ROAD: "#8BC7FF",
        THIRD_ROAD: "#87C55F",
        FOURTH_ROAD: "#DDA0DD",
        FIFTH_ROAD: "#FFB68D",
        SIXTH_ROAD: "#6FDDDD",
        SEVENTH_ROAD: "#FFF599",
        EIGHTH_ROAD: "#BFB0D8",
        NINTH_ROAD: "#D3B484",
        TENTH_ROAD: "#FF9595",
    },
};

export const ROAD_COLORS_LIST = {
    light: Object.values(ROAD_COLORS.light),
    dark: Object.values(ROAD_COLORS.dark),
};
export type TRoadColorsType = keyof typeof ROAD_COLORS_LIST;
export type TRoadColors = typeof ROAD_COLORS_LIST[keyof typeof ROAD_COLORS_LIST];

export const RESULTS_VIEW_STATES = {
    CHECK_IF_DATA_READY: "CHECK_IF_DATA_READY",
    VISUALIZE: "VISUALIZE",
};
export type TViewState = keyof typeof RESULTS_VIEW_STATES;

export const ERRORS = {
    COMMON: {
        statuses: [
            ANALYSIS_STATUSES.ERROR.id,
            ANALYSIS_STATUSES.CANCELLING.id,
            ANALYSIS_STATUSES.FLAGGED_COVERAGE.id,
            ANALYSIS_STATUSES.FLAGGED_SIZE.id,
        ],
        errorMessage:
            "There was an unexpected issue with your project, please run your project again.",
    },
    CANCELLED: {
        statuses: [ANALYSIS_STATUSES.CANCELLED.id],
        errorMessage: "Not enough sample size of trips, please select a larger area or roadway.",
    },
};

export const ERROR_STATUSES = [...ERRORS.COMMON.statuses, ...ERRORS.CANCELLED.statuses];
export type TErrorStatus = typeof ERROR_STATUSES[number];

export const IN_PROGRESS_STATUSES = [
    ANALYSIS_STATUSES.NEW.id,
    ANALYSIS_STATUSES.PROCESSING.id,
    ANALYSIS_STATUSES.QUEUED.id,
    ANALYSIS_STATUSES.RUNNING.id,
    ANALYSIS_STATUSES.RERUNNING.id,
    ANALYSIS_STATUSES.SAVED.id,
    ANALYSIS_STATUSES.PAUSED_REQUEUED.id,
    ANALYSIS_STATUSES.RESUMED.id,
    ANALYSIS_STATUSES.EXPORTING.id,
];

export const MONTHS = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
];

export type TMonth = typeof MONTHS[number];

export const FULL_MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const DAY_OPTIONS = {
    "All Days": { name: "All Days", code: 17, shortName: "All" },
    Monday: { name: "Monday", code: 11, shortName: "M" },
    Tuesday: { name: "Tuesday", code: 22, shortName: "Tu" },
    Wednesday: { name: "Wednesday", code: 33, shortName: "W" },
    Thursday: { name: "Thursday", code: 44, shortName: "Th" },
    Friday: { name: "Friday", code: 55, shortName: "F" },
    Saturday: { name: "Saturday", code: 66, shortName: "Sa" },
    Sunday: { name: "Sunday", code: 77, shortName: "Su" },
};

export const DAY_OPTIONS_LIST = Object.values(DAY_OPTIONS);

export const DAILY_TRAFFIC_METRIC_TYPE = {
    day_part: {
        id: "day_part",
        name: "Hourly",
    },
    day_type: {
        id: "day_type",
        name: "Daily",
    },
} as const;
export type TDailyTrafficMetricType = keyof typeof DAILY_TRAFFIC_METRIC_TYPE;
export type TDailyTrafficMetric =
    typeof DAILY_TRAFFIC_METRIC_TYPE[keyof typeof DAILY_TRAFFIC_METRIC_TYPE];

export const PS_DONT_SHOW_LONG_RUNNING_ANALYSIS_WARNING_COOKIES_KEY =
    "ps-dont-show-long-running-analysis-warning";

export const MAP_THEMES = {
    LIGHT: "navigation-day-v1",
    DARK: "navigation-night-v1",
};
export type TMapType = keyof typeof MAP_THEMES;
export type TMapTheme = typeof MAP_THEMES[keyof typeof MAP_THEMES];

export const DEFAULT_ANALYSIS_NAME = "Untitled Project";

export const HUBSPOT_FORM_DATA = {
    PORTAL_ID: "6767808",
    QUOTE_REQUEST_FORM_ID: "1375ed7d-5dc3-4ae7-90ff-0207dfb65a44",
};

export const REQUEST_QUOTE_FIELD_DATA = {
    EMAIL: {
        id: "email-address",
        label: "Business Email *",
        placeholder: "Enter your email address",
        hubspotFieldName: "email",
    },
    LOCATION_COUNT: {
        id: "location-count",
        label: "Number of Locations Needed",
        placeholder: "Choose quantity",
        hubspotFieldName: "plans_pricing_how_much",
    },
    NEEDS: {
        id: "needs-desc",
        label: "What are your immediate needs/specific projects which StreetLight Insight® can help with? *",
        placeholder: "Describe",
        hubspotFieldName: "message",
    },
    METRICS: {
        id: "metrics-desc",
        label: "What metrics are you interested in?",
        placeholder: "Describe",
        hubspotFieldName: "notes",
    },
    METRIC_TYPE: {
        hubspotFieldName: "metric_type",
    },
};

export const LOCATION_COUNT_OPTIONS = {
    LESS_THAN_100: { id: "lessThanHundred", name: "Less than 100" },
    FROM_100_TO_1000: { id: "fromHundredToThousand", name: "100 - 1,000" },
    MORE_THAN_1000: { id: "moreThanThousand", name: "More than 1,000" },
};

export const LOCATION_COUNT_OPTIONS_LIST = Object.values(LOCATION_COUNT_OPTIONS).map(option => ({
    label: option.name,
    id: option.id,
}));

export const LOW_COUNT_TYPES = {
    low: {
        id: "low",
        restrictLevel: 2,
    },
    medium: {
        id: "medium",
        restrictLevel: 1,
    },
    normal: {
        id: "normal",
        restrictLevel: 0,
    },
} as const;

export type TLowCountTypes = typeof LOW_COUNT_TYPES[keyof typeof LOW_COUNT_TYPES];

export const PROCESSING_STEP_DATA = {
    FETCHING: {
        title: "Fetching data...",
        className: "fetch",
        content:
            "365 days a year, we process billions of location records to shed light on travel patterns on North America’s vast network of over 4.5 million miles of roads, bike lanes and sidewalks...",
    },
    GEOGRAPHY: {
        title: "For any geography...",
        className: "geography",
        content:
            "We make these patterns available for any route or road segment right on your desktop, letting you analyze, visualize and compare how your region, city or neighborhood moves over time...",
    },
    CALCULATION: {
        title: "Calculating your metrics...",
        className: "calculation",
        content:
            "You get access to the metrics you’re used to, without deploying sensors or surveys.  Validated counts, origin-destination, speed, and more, are all available in a few clicks, calibrated using 1,000s of permanent traffic counters and embedded sensors nationwide.",
    },
    FINISHING: {
        title: "But let’s start with the basic metrics...",
        className: "finish",
        content: "Your sample StreetLight Metrics will be ready shortly",
    },
};

export const PROCESSING_STEP_LIST = Object.values(PROCESSING_STEP_DATA);

export const METRICS_TYPES = {
    TRIAL_METRICS: {
        id: "trial_metrics",
        title: "Trial Metrics:",
        options: [
            {
                id: "roadwayVehicleVolume",
                label: "Roadway Vehicle Volume",
                description:
                    "Average daily traffic Metrics for the roadway segments of your choice, broken out by month, day of the week, time of the day, for the U.S. and Canada.",
                Icon: RoadwayVehicleVolumeIcon,
            },
        ],
    },
    UPGRADE_METRICS: {
        id: "upgrade_metics",
        title: "Upgrade To:",
        options: [
            {
                id: "bicycleAndPedestrianActivityHeatmap",
                label: "Bicycle and Pedestrian Activity Heatmap",
                description:
                    "Use bicycle and pedestrian traffic maps to identify activity hotspots in your area and prioritize where to invest in active transportation infrastructure.",
                Icon: BicycleAndPedestrianActivityHeatmapIcon,
            },
            {
                id: "corridorCongestionAnalysis",
                label: "Corridor Congestion Analysis",
                description:
                    "Understand the cause of congestion and traffic for local roadways and which routes are contributing to traffic.  Is congestion caused by local or regional trips?",
                Icon: CorridorCongestionAnalysisIcon,
            },
            {
                id: "locationVisitationPatterns",
                label: "Location Visitation Patterns",
                description:
                    "Analyze aggregate visitor demographics to understand where they’re coming from; analyze local vs visitors",
                Icon: LocationVisitationPatternsIcon,
            },
        ],
    },
} as const;

export const METRICS_TYPES_LIST = Object.values(METRICS_TYPES);

export type TMetricsType = typeof METRICS_TYPES[keyof typeof METRICS_TYPES];
export type TMetricOptions = TMetricsType["options"];
