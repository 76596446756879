import { TFilterOption } from "@common/components/table/table.types";
import { ANALYSIS_TYPES, ORG_COUNTRIES } from "@common/constants/analysis.constants";
import { COLORS } from "@common/constants/color.constants";
import DestinationsIcon from "@root/src/assets/img/zone-roles/destination.svg";
import MiddleFiltersIcon from "@root/src/assets/img/zone-roles/middle-filter.svg";
import OriginsIcon from "@root/src/assets/img/zone-roles/origin.svg";

export const RAIL_ZONE_KINDS = {
    STATIONS: {
        code: "STATIONS",
        name: "Rail Stations",
        zoneKindUiDisplay: "Rail Station",
        id: 9,
        analysisTypes: [
            ANALYSIS_TYPES.ZA.id,
            ANALYSIS_TYPES.OD.id,
            ANALYSIS_TYPES.ODG.id,
            ANALYSIS_TYPES.ODMF.id,
            ANALYSIS_TYPES.TROD.id,
            ANALYSIS_TYPES.TRZA.id,
        ],
    },
    SEGMENTS: {
        code: "SEGMENTS",
        name: "Rail Line Segments",
        zoneKindUiDisplay: "Rail Line Segment",
        id: 10,
        analysisTypes: [ANALYSIS_TYPES.ZA.id, ANALYSIS_TYPES.OD.id],
    },
} as const;

export const RAIL_ZONE_KINDS_LIST = Object.values(RAIL_ZONE_KINDS);

export const BUS_ZONE_KINDS = {
    ROUTES: {
        code: "ROUTES",
        name: "Bus Routes",
        id: 23,
        analysisTypes: [
            ANALYSIS_TYPES.ZA.id,
            ANALYSIS_TYPES.OD.id,
            ANALYSIS_TYPES.ODG.id,
            ANALYSIS_TYPES.ODMF.id,
            ANALYSIS_TYPES.TROD.id,
            ANALYSIS_TYPES.TRZA.id,
        ],
    },
} as const;

export const OSM_ZONE_KINDS = {
    OSM: {
        code: "OSM",
        name: "OSM",
        zoneKindUiDisplay: "OSM",
        id: 7,
    },
    OSM_TERTIARY: {
        code: "OSM_TERTIARY",
        name: "OSM",
        zoneKindUiDisplay: "OSM Tertiary Segments",
        id: 24,
    },
    OSM_SATC: {
        code: "OSM_SATC",
        name: "OSM",
        zoneKindUiDisplay: "OSM SATC Segments",
        id: 25,
    },
} as const;
export const EDITABLE_OSM_ZONE_KIND_IDS = [OSM_ZONE_KINDS.OSM.id, OSM_ZONE_KINDS.OSM_TERTIARY.id];

// zoneKindUiDisplay exactly matches the ui_display column in the webapp.zone_kind table
export const ZONE_KINDS = {
    USER: {
        code: "USER",
        name: "Custom Zone",
        year: "",
        id: [1],
        zoneKindUiDisplay: "Custom Zone",
        sortOrder: 1,
    },
    ZIP: {
        code: "ZIP",
        name: "US Zip Codes",
        country: ORG_COUNTRIES.US.code,
        year: "2010",
        id: [2],
        zoneKindUiDisplay: "US Zip Codes",
        sortOrder: 7,
    },
    BLOCK_GROUP: {
        code: "BLOCK_GROUP",
        name: "US Census Block Groups",
        country: ORG_COUNTRIES.US.code,
        year: "2010",
        id: [3],
        zoneKindUiDisplay: "US Census Block Groups",
        sortOrder: 9,
    },
    TAZ: {
        code: "TAZ",
        name: "US Traffic Analysis Zones",
        country: ORG_COUNTRIES.US.code,
        year: "2010",
        id: [4],
        zoneKindUiDisplay: "US Traffic Analysis Zones",
        sortOrder: 11,
    },
    GRID: {
        code: "GRID",
        name: "Grids",
        year: "",
        id: [5],
        zoneKindUiDisplay: "Grids",
        sortOrder: 22,
    },
    DA: {
        code: "DA",
        name: "Canada Dissemination Areas",
        country: ORG_COUNTRIES.CA.code,
        year: "2016",
        id: [6],
        zoneKindUiDisplay: "Canada Dissemination Areas",
        sortOrder: 18,
    },
    OSM: {
        code: "OSM",
        name: "OSM Segment",
        year: "",
        id: [OSM_ZONE_KINDS.OSM.id, OSM_ZONE_KINDS.OSM_TERTIARY.id],
        zoneKindUiDisplay: "OSM Segment",
        sortOrder: 3,
    },
    TRACT_US: {
        code: "TRACT_US",
        name: "US Census Tracts",
        country: ORG_COUNTRIES.US.code,
        year: "2010",
        id: [8],
        zoneKindUiDisplay: "US Census Tracts",
        sortOrder: 12,
    },
    BUS: {
        code: "BUS",
        name: "Bus Lines",
        year: "",
        id: [BUS_ZONE_KINDS.ROUTES.id],
        zoneKindUiDisplay: "Bus Lines",
        sortOrder: 4,
    },
    RAIL: {
        code: "RAIL",
        name: "Rail",
        year: "",
        id: [RAIL_ZONE_KINDS.STATIONS.id, RAIL_ZONE_KINDS.SEGMENTS.id],
        zoneKindUiDisplay: "Rail",
        sortOrder: 5,
    },
    INTERSECTION: {
        code: "INTERSECTION",
        name: "Intersections",
        year: "",
        id: [11],
        zoneKindUiDisplay: "Intersections",
        sortOrder: 6,
    },
    TRACT_CA: {
        code: "TRACT_CA",
        name: "Canada Census Tracts",
        country: ORG_COUNTRIES.CA.code,
        year: "2016",
        id: [12],
        zoneKindUiDisplay: "Canada Census Tracts",
        sortOrder: 17,
    },
    SUBDIVISION: {
        code: "SUBDIVISION",
        name: "Canada Census Subdivisions",
        country: ORG_COUNTRIES.CA.code,
        year: "2016",
        id: [13],
        zoneKindUiDisplay: "Canada Census Subdivisions",
        sortOrder: 19,
    },
    STATE_US: {
        code: "STATE_US",
        name: "US States",
        country: ORG_COUNTRIES.US.code,
        year: "",
        id: [15],
        zoneKindUiDisplay: "US States",
        sortOrder: 16,
    },
    ZIP_US_2020: {
        code: "ZIP_US_2020",
        name: "US Zip Codes",
        country: ORG_COUNTRIES.US.code,
        year: "2020",
        id: [16],
        zoneKindUiDisplay: "US Zip Codes",
        sortOrder: 8,
    },
    BLOCK_GROUP_US_2020: {
        code: "BLOCK_GROUP_US_2020",
        name: "US Census Block Groups",
        country: ORG_COUNTRIES.US.code,
        year: "2020",
        id: [17],
        zoneKindUiDisplay: "US Census Block Groups",
        sortOrder: 10,
    },
    TRACT_US_2020: {
        code: "TRACT_US_2020",
        name: "US Census Tracts",
        country: ORG_COUNTRIES.US.code,
        year: "2020",
        id: [18],
        zoneKindUiDisplay: "US Census Tracts",
        sortOrder: 13,
    },
    COUNTY_US_2020: {
        code: "COUNTY_US_2020",
        name: "US Counties",
        country: ORG_COUNTRIES.US.code,
        year: "2020",
        id: [19],
        zoneKindUiDisplay: "US Counties",
        sortOrder: 15,
    },
    CITY_US_2020: {
        code: "CITY_US_2020",
        name: "US Cities",
        country: ORG_COUNTRIES.US.code,
        year: "2020",
        id: [20],
        zoneKindUiDisplay: "US Cities",
        sortOrder: 14,
    },
    GATE: {
        code: "GATE",
        name: "Custom Gate",
        country: ORG_COUNTRIES.US.code,
        year: "",
        id: [26],
        zoneKindUiDisplay: "Custom Gate",
        sortOrder: 2,
    },
} as const;

export const ZONE_KINDS_LIST = Object.values(ZONE_KINDS);

export type TZoneKinds = typeof ZONE_KINDS;
export type TZoneKind = typeof ZONE_KINDS[keyof typeof ZONE_KINDS];
export type TZoneKindId = TZoneKind["id"][number];
export type TZoneKindIdsByCode<T extends keyof typeof ZONE_KINDS> =
    typeof ZONE_KINDS[T]["id"][number];

export type TSelectedZoneKind = {
    id: Readonly<Array<number>>;
    filters: any;
    selectedKindsList?: Array<number>;
};

export const ZONE_KIND_IDS_BY_NAMES = ZONE_KINDS_LIST.reduce((res, option) => {
    // Remove GRID zone kind from available options as it is not supported
    if (option.code === ZONE_KINDS.GRID.code) {
        return res;
    }
    if (option.code === ZONE_KINDS.RAIL.code) {
        [RAIL_ZONE_KINDS.SEGMENTS, RAIL_ZONE_KINDS.STATIONS].forEach(railOption => {
            res[railOption.zoneKindUiDisplay] = {
                id: [railOption.id],
                sortOrder: option.sortOrder,
            };
        });
    } else {
        const existingIds = res[option.zoneKindUiDisplay]?.id || [];
        res[option.zoneKindUiDisplay] = {
            id: [...existingIds, ...option.id],
            sortOrder: option.sortOrder,
        };
    }

    return res;
}, {} as { [key: string]: { id: Array<number>; sortOrder: number } });

export const ZONE_KIND_FILTER_OPTIONS = Object.keys(ZONE_KIND_IDS_BY_NAMES)
    .sort((a, b) => ZONE_KIND_IDS_BY_NAMES[a].sortOrder - ZONE_KIND_IDS_BY_NAMES[b].sortOrder)
    .map(zoneKindName => ({
        label: zoneKindName,
        value: ZONE_KIND_IDS_BY_NAMES[zoneKindName].id,
    })) as Array<TFilterOption>;

// Here we check for zone.is_current flag for every zone as zone kind could have zones with multiple vintages
export const ZONE_KINDS_WITH_OUTDATED_ZONES = [...ZONE_KINDS.OSM.id, ...ZONE_KINDS.RAIL.id];

// Here we check if zone has zone kind id from the list of outdated zone kinds
export const ZONE_KINDS_WITH_OUTDATED_VINTAGES = [
    ...ZONE_KINDS.BLOCK_GROUP.id,
    ...ZONE_KINDS.ZIP.id,
    ...ZONE_KINDS.TRACT_US.id,
];

export const CALIBRATION_ZONE_ROLES = {
    CALIBRATIONS: {
        id: "calibrationZones",
        name: "Calibration Zones",
        shortName: "Zones",
        layerColor: COLORS.PURPLE,
        accessor: "cz",
    },
    AADT_CALIBRATIONS: {
        id: "aadtCalibrationZones",
        name: "AADT Calibration Zones",
        shortName: "Zones",
        layerColor: COLORS.PURPLE,
        accessor: "az",
    },
} as const;
export const CALIBRATION_ZONE_ROLES_LIST = Object.values(CALIBRATION_ZONE_ROLES);
export type TCalibrationZoneRole =
    typeof CALIBRATION_ZONE_ROLES[keyof typeof CALIBRATION_ZONE_ROLES];

export const ZONE_ROLES = {
    DEFAULT: {
        id: "zones",
        name: "Zones",
        shortName: "Zones",
        layerColor: COLORS.BLUE,
        accessor: "oz",
    },
    ORIGINS: {
        id: "originZones",
        name: "Origin Zones",
        shortName: "Origins",
        Icon: OriginsIcon,
        layerColor: COLORS.BLUE,
        accessor: "oz",
    },
    DESTINATIONS: {
        id: "destinationZones",
        name: "Destination Zones",
        shortName: "Destinations",
        Icon: DestinationsIcon,
        layerColor: COLORS.YELLOW,
        accessor: "dz",
    },
    MIDDLE_FILTERS: {
        id: "middleFilters",
        name: "Middle Filters",
        shortName: "Middle Filters",
        Icon: MiddleFiltersIcon,
        layerColor: COLORS.GREEN,
        accessor: "mfz",
    },
    SEGMENTS: {
        id: "segments",
        name: "Segments",
        shortName: "Segments",
        layerColor: COLORS.BLUE,
        accessor: "oz",
    },
} as const;

export const ZONE_ROLES_LIST = Object.values(ZONE_ROLES);

export const ZONE_ROLES_BY_ANALYSIS_TYPE = {
    [ANALYSIS_TYPES.AADT2016.id]: [ZONE_ROLES.DEFAULT],
    [ANALYSIS_TYPES.AADT2017.id]: [ZONE_ROLES.DEFAULT],
    [ANALYSIS_TYPES.AADT2018.id]: [ZONE_ROLES.DEFAULT],
    [ANALYSIS_TYPES.AADT2019.id]: [ZONE_ROLES.DEFAULT],
    [ANALYSIS_TYPES.AADT2020.id]: [ZONE_ROLES.DEFAULT],
    [ANALYSIS_TYPES.AADT2021.id]: [ZONE_ROLES.DEFAULT],
    [ANALYSIS_TYPES.OD.id]: [ZONE_ROLES.ORIGINS, ZONE_ROLES.DESTINATIONS],
    [ANALYSIS_TYPES.ODMF.id]: [
        ZONE_ROLES.ORIGINS,
        ZONE_ROLES.MIDDLE_FILTERS,
        ZONE_ROLES.DESTINATIONS,
    ],
    [ANALYSIS_TYPES.ZA.id]: [ZONE_ROLES.DEFAULT],
    [ANALYSIS_TYPES.ODG.id]: [ZONE_ROLES.DEFAULT],
    [ANALYSIS_TYPES.TRZA.id]: [ZONE_ROLES.DEFAULT],
    [ANALYSIS_TYPES.TROD.id]: [ZONE_ROLES.ORIGINS, ZONE_ROLES.DESTINATIONS],
    [ANALYSIS_TYPES.SEGMENT.id]: [ZONE_ROLES.DEFAULT],
    [ANALYSIS_TYPES.TD.id]: [ZONE_ROLES.DEFAULT],
    [ANALYSIS_TYPES.TMC.id]: [ZONE_ROLES.DEFAULT],
};

export const COLOR_BY_ROLE = {
    [ZONE_ROLES.ORIGINS.accessor]: COLORS.BLUE,
    [ZONE_ROLES.DESTINATIONS.accessor]: COLORS.YELLOW,
    [ZONE_ROLES.MIDDLE_FILTERS.accessor]: COLORS.GREEN,
    tfz: COLORS.GREEN,
    [CALIBRATION_ZONE_ROLES.CALIBRATIONS.accessor]: COLORS.PURPLE,
    [CALIBRATION_ZONE_ROLES.AADT_CALIBRATIONS.accessor]: COLORS.PURPLE,
};
