import type { TMapRegion } from "@common/components/baseMap/baseMap.types";
import type { IReviewDetail } from "@app/analysis/review/state/review.types";

export type TReviewState = {
    reviewNote: string;
    zendeskUrl?: string;
    streetlightSupportUrl?: string;
    validationDetails: string;
    currentReviewNote: string;
    shouldRenderZones: boolean;
    reviewNoteVersionId: number;
    byPassRuntimeReview: boolean;
    byPassCoverageReview: boolean;
    reviewRegion: TMapRegion | null;
    reviewDetail: IReviewDetail | null;
};

export const REVIEW_INITIAL_STATE = {
    reviewNote: "", //Review note from the server
    currentReviewNote: "", //Review note in the current state of the application
    reviewNoteVersionId: 0,
    byPassCoverageReview: false,
    byPassRuntimeReview: true,
    reviewRegion: null,
    shouldRenderZones: true,
} as TReviewState;
