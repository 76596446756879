import {
    CALIBRATIONS,
    CALIBRATIONS_LIST,
    DEFAULT_AADT_YEAR,
} from "@app/analysis/state/analysisConfiguration.constants";
import { MODES_OF_TRAVEL } from "@common/constants/analysis.constants";

export const BASICS_INITIAL_STATE = {
    name: "",
    createdBy: "",
    createdDate: "",
    description: "",
    country: "",
    tagIds: [],
    travelModeCode: MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code,
    calibrationSettings: {
        calibrationCode: CALIBRATIONS.VOLUME.code,
        availableCalibrationCodes: CALIBRATIONS_LIST.map(calibration => calibration.code),
        aadtCalibrationYear: DEFAULT_AADT_YEAR,
    },
    validation: {
        fields: {
            name: {},
            travelModeCode: {},
        },
        isInvalid: false,
        showValidationAlert: false,
    },
    measurementUnit: "",
    censusType: null,
    zoneCensusType: null,
};
