import { createSelector } from "reselect";
import { getAnalysisTypeCode } from "@app/analysis/state/general/general.selectors";
import { getBoundsFromIntersections } from "@app/analysis/zones/chooseZones/configurations/tmc/state/tmcChooseZones.helpers";
import type { TRootState } from "@app/store";
import { getDirectAnalysesZonesLimits } from "@app/store/staticData/state/staticData.selectors";
import { ANALYSIS_TYPES } from "@common/constants/analysis.constants";
import type {
    IIntersectionZone,
    IIntersectionZoneGate,
} from "@common/features/intersections/intersection.types";
import { capitalizeTheFirstLetter } from "@common/formatters/formatString";

/*
    Getter of the main object, just for the sake of convenience.
*/
export const getBaseTMCChooseZones = (state: TRootState) =>
    state.analysisConfiguration.tmcChooseZones;

export const getAvailableIntersections = (state: TRootState) =>
    getBaseTMCChooseZones(state).availableIntersections;

export const getIntersectionZones = (state: TRootState) =>
    getBaseTMCChooseZones(state).intersectionZones;

export const getSelectedIntersectionZoneId = (state: TRootState) =>
    getBaseTMCChooseZones(state).selectedIntersectionZoneId;

export const getHoveredIntersectionZoneId = (state: TRootState) =>
    getBaseTMCChooseZones(state).hoveredIntersectionZoneId;

export const getSelectedGateId = (state: TRootState) =>
    getBaseTMCChooseZones(state).selectedGateId;

export const getEditableFeature = (state: TRootState) =>
    getBaseTMCChooseZones(state).editableFeature;

export const getHoveredGate = (state: TRootState) => getBaseTMCChooseZones(state).hoveredGate;

export const getIntersectionZoneIdsList = (state: TRootState) =>
    getBaseTMCChooseZones(state).intersectionZoneIdsList;

export const getIntersectionZonesList = createSelector(
    getIntersectionZones,
    getIntersectionZoneIdsList,
    (intersectionZones, intersectionZoneIdsList) =>
        intersectionZoneIdsList.map(id => intersectionZones[id]),
);

// Creates an array of selected intersections in specified by BE format.
export const getApiIntersectionZones = createSelector(
    getIntersectionZonesList,
    intersectionZones => {
        if (!intersectionZones) return [];

        return intersectionZones.reduce((result, zone) => {
            if (zone.isImported) {
                return [
                    ...result,
                    { zone_id: zone.zone_id, name: zone.zone_name, gates: zone.gates },
                ];
            }

            const gates = zone.gates.map(gate => ({
                line_geom: gate.line_geom,
                role: gate.role,
                direction: gate.direction ? capitalizeTheFirstLetter(gate.direction) : undefined,
                road: gate.road,
            })) as Array<IIntersectionZoneGate>;

            let zoneData = {
                name: zone.zone_name,
                gates,
                zone_id: zone?.zone_id,
                is_edited_intersection: zone?.is_edited_intersection,
            };

            if (zone?.zone_id) {
                //if zone_id is present, is_edited_intersection should be passed as true or false based on logic,else don't pass it.
                zoneData = {
                    ...zoneData,
                    is_edited_intersection: zone?.is_edited_intersection ?? false,
                };
            }

            return [...result, zoneData];
        }, [] as Array<Partial<IIntersectionZone>>);
    },
);

export const getValidatedIntersectionZones = createSelector(
    getIntersectionZonesList,
    intersectionZones => {
        if (!intersectionZones) return [];

        return intersectionZones.reduce((result, zone) => {
            const gates = zone.gates.map(gate => ({
                line_geom: gate.line_geom,
                role: gate.role,
                direction: gate.direction ? capitalizeTheFirstLetter(gate.direction) : undefined,
                road: gate.road,
            })) as Array<IIntersectionZoneGate>;

            return [...result, { name: zone.zone_name, gates }];
        }, [] as Array<Partial<IIntersectionZone>>);
    },
);

export const getSelectedIntersection = createSelector(
    getIntersectionZones,
    getSelectedIntersectionZoneId,
    (intersectionZones, selectedIntersectionZoneId) => {
        if (!selectedIntersectionZoneId || !Object.keys(intersectionZones).length) return null;

        return intersectionZones[selectedIntersectionZoneId];
    },
);

export const getHoveredIntersection = createSelector(
    getIntersectionZones,
    getHoveredIntersectionZoneId,
    (intersectionZones, hoveredIntersectionZoneId) => {
        if (!hoveredIntersectionZoneId || !Object.keys(intersectionZones).length) return null;

        return intersectionZones[hoveredIntersectionZoneId];
    },
);

export const getSelectedGate = createSelector(
    getSelectedIntersection,
    getSelectedGateId,
    (selectedIntersectionZone, selectedGateId) => {
        if (!selectedIntersectionZone) return null;

        return selectedIntersectionZone.gates.find(gate => gate.id === selectedGateId);
    },
);

export const getAllSelectedIntersectionZoneIds = createSelector(
    getIntersectionZonesList,
    (intersectionZones = []) =>
        intersectionZones.reduce((zoneIds, { zone_id }) => {
            if (zone_id) zoneIds.push(String(zone_id));

            return zoneIds;
        }, [] as Array<string>),
);

export const getIntersectionZonesMaxCount = createSelector(
    getDirectAnalysesZonesLimits,
    getAnalysisTypeCode,
    (zoneLimits, analysisTypeCode) => {
        return Number(zoneLimits?.tmcLimits[analysisTypeCode]);
    },
);

export const getShouldOmitSomeIntersections = createSelector(
    getIntersectionZonesList,
    getIntersectionZonesMaxCount,
    getAnalysisTypeCode,
    (intersectionZones, maxIntersectionCount, analysisTypeCode) => {
        return (
            analysisTypeCode === ANALYSIS_TYPES.TMC.id &&
            intersectionZones.length > maxIntersectionCount
        );
    },
);

export const getCombinedAllIntersectionsBounds = createSelector(
    getIntersectionZonesList,
    getBoundsFromIntersections,
);
