import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from "@fortawesome/pro-solid-svg-icons";
import "./errorScreens.less";

export const ChunkErrorScreen = () => (
    <div className="stl-app-error">
        <h1>Page Reloading Needed</h1>
        <div>
            The web page you are viewing is not the latest version. You must reload the page.
        </div>
    </div>
);

export const CommonErrorScreen = () => (
    <div className="stl-app-error">
        <FontAwesomeIcon icon={faTools} />
        <div>
            An unexpected error occurred and our engineering team is looking into it, our
            apologies!
        </div>
    </div>
);
