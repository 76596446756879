import { FormattedMessage } from "react-intl";
import { LightboxService } from "@common/components/lightbox/lightbox.service";

export const confirmGateChange = (confirmCallback: () => void): void => {
    LightboxService.openConfirmation({
        title: "Warning",
        content: (
            <FormattedMessage
                id="app.warningGateChange"
                defaultMessage="You have unsaved changes for the gate. Are you sure you want to continue?"
                description="Warning message when user switches to another gate edit screen without saving data"
            />
        ),
        okText: "Yes",
        cancelText: "Cancel",
        onOK: () => confirmCallback(),
    });
};

export const confirmZoneSetDialogClose = (closeCallback: () => void): void => {
    LightboxService.openConfirmation({
        title: "Warning",
        content: (
            <FormattedMessage
                id="app.warningZoneSetDialogClose"
                defaultMessage="By exiting, you will lose your work. Are you sure you want to continue?"
                description="Warning message when Zone Set dialog is closed"
            />
        ),
        okText: "Yes",
        cancelText: "Cancel",
        onOK: () => closeCallback(),
    });
};

export const confirmZoneSetNameChangeCancel = (closeCallback: () => void): void => {
    LightboxService.openConfirmation({
        title: "Warning",
        content: (
            <FormattedMessage
                id="app.warningZoneSetNameChangeCancel"
                defaultMessage="By exiting, your zone set name will not be saved. Are you sure you want to continue?"
                description="Warning message when user leaves zone set edit screen without saving name"
            />
        ),
        okText: "Yes",
        cancelText: "Cancel",
        onOK: () => closeCallback(),
    });
};
