import type { Map } from "mapbox-gl";
import { TMapLayersConfig } from "@common/components/baseMap/baseMap.types";
import { EnhancedLayers } from "@common/components/baseMap/mapLayers/enhanced/enhancedLayers";
import { TMapLayersLocalState } from "@common/components/baseMap/mapLayers/mapLayers.constants";
import { OsmLayers } from "@common/components/baseMap/mapLayers/osm/osmLayers";

type TProps = {
    map: Map | null;
    config: TMapLayersConfig;
    mapLayersLocalState?: TMapLayersLocalState | null;
};

export const MapLayers = ({ map, config, mapLayersLocalState }: TProps) => (
    <>
        {config.showOsmLayers && <OsmLayers map={map} mapLayersLocalState={mapLayersLocalState} />}
        {config.showEnhancedLayers && (
            <EnhancedLayers map={map} mapLayersLocalState={mapLayersLocalState} />
        )}
    </>
);
