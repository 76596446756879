import { useCallback } from "react";
import { StlGeocoder } from "@common/components/geocoder/geocoder";
import type { Map } from "mapbox-gl";
import type { Result } from "@mapbox/mapbox-gl-geocoder";
import type { ILocation } from "@common/components/geocoder/geocoder";
import "./zoomToLocation.less";

const ZOOM_TO_LOCATION_PLACEHOLDER = "Find a Location" as const;

type TProps = {
    map: Map;
    mapId?: string;
    location?: ILocation | null;
    setLocation?: (location: ILocation) => void;
};

export const ZoomToLocation = ({
    map,
    mapId = "base-map",
    location,
    setLocation = () => {},
}: TProps) => {
    const handleSelect = useCallback(
        ({ result }: { result: Result }) => {
            const { id, bbox, center, place_name: placeName } = result;

            setLocation({ id, bbox, center, placeName });
        },
        [setLocation],
    );

    return (
        <div className="zoom-to-location">
            <StlGeocoder
                id={`${mapId}-geocoder`}
                map={map}
                selectedLocation={location}
                placeholder={ZOOM_TO_LOCATION_PLACEHOLDER}
                onSelect={handleSelect}
                clearable
            />
        </div>
    );
};
