import { BASE_MAP_STYLES } from "@app/viz3/base/map/baseMapStyles.constants";
import { DIRECTION_OPTIONS } from "@common/features/directions/directions.constants";
import { BOTH_DIRECTION_OPTION } from "@app/viz3/csVisualization/state/csViz.constants";

export const MAP_STYLES = {
    ...BASE_MAP_STYLES,
    ZONE_COLOR: {
        MIN: "#626262",
        MAX: "#FFDA6A",
        HOVER: "#FFFFFF",
    },
    ZONE_OPACITY: 0.7,
    ZONE_Z_INDEX: 10,
    ROAD_DIRECTION_COLORS: {
        [BOTH_DIRECTION_OPTION.code]: "#4B9D76",
        [DIRECTION_OPTIONS.EAST.code]: "#F2E995",
        [DIRECTION_OPTIONS.WEST.code]: "#71A2DC",
        [DIRECTION_OPTIONS.NORTH.code]: "#FF9797",
        [DIRECTION_OPTIONS.SOUTH.code]: "#97C667",
    },
};
