import React, { ReactNode } from "react";
import classNames from "classnames";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import type { DialogProps } from "@material-ui/core/Dialog/Dialog";
import "./lightbox.less";

type TProps = Omit<DialogProps, "open" | "title"> & {
    show: boolean;
    onHide?: () => void;
    testid?: string;
    ariaLabel?: string;
    description?: string;
    centered?: boolean;
    hideHeader?: boolean;
    hideCloseButton?: boolean;
    onCancel?: () => void;
    body?: ReactNode;
    footer?: ReactNode;
    title?: ReactNode;
    titleContent?: ReactNode;
};

export const StlLightbox = ({
    className = "",
    title,
    titleContent = "",
    description = "",
    ariaLabel = "",
    body,
    footer,
    show,
    hideHeader = false,
    hideCloseButton = false,
    disableEnforceFocus = false,
    centered = false,
    onHide = () => {},
    onCancel = () => {},
    testid = "",
    onExited = () => {},
    ...props
}: TProps) => {
    const descriptionIdAttribute = description ? { "aria-describedby": "modal-description" } : {};
    const ariaLabelledByAttribute = !hideHeader ? { "aria-labelledby": "modal-title" } : {};

    const decline = () => {
        onCancel();
        onHide();
    };

    const closeButton = (
        <button type="button" aria-label="Close modal" className="close-button" onClick={decline}>
            <FontAwesomeIcon icon={faTimes} />
        </button>
    );

    const onClose = (event: React.SyntheticEvent, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason === "backdropClick") {
            return;
        }
        onHide();
    };

    return (
        <Dialog
            open={show}
            onClose={onClose}
            classes={{
                root: classNames("stl-lightbox", className),
                paper: "modal-dialog",
                scrollPaper: classNames(!centered && "modal-dialog-top-align"),
            }}
            maxWidth={false}
            aria-label={ariaLabel}
            data-testid={testid}
            disableEnforceFocus={disableEnforceFocus}
            onExited={onExited}
            {...descriptionIdAttribute}
            {...ariaLabelledByAttribute}
            {...props}
        >
            {!hideHeader && (
                <DialogTitle className="modal-header" disableTypography>
                    <div className="title-content-wrapper">
                        <DialogTitle
                            id="modal-title"
                            disableTypography
                            classes={{ root: "modal-title" }}
                            data-testid="alertDialogHeader"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            {title}
                        </DialogTitle>
                        {!!description && (
                            <p id="modal-description" className="sr-only">
                                {description}
                            </p>
                        )}
                        {!!titleContent && <div className="title-content">{titleContent}</div>}
                    </div>
                    {!hideCloseButton && closeButton}
                </DialogTitle>
            )}
            <DialogContent classes={{ root: "modal-body" }} data-testid="alertBody">
                {body}
            </DialogContent>
            {footer && <DialogActions classes={{ root: "modal-footer" }}>{footer}</DialogActions>}
        </Dialog>
    );
};
