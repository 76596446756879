type TField = {
    touched: boolean;
    isInvalid: boolean;
    reasons: Array<string>;
};

type TFields = {
    [key: string]: TField;
};

export type TValidation = {
    fields: TFields;
    isInvalid: boolean;
    showValidationAlert: boolean;
};

// Returns true if a field in an validation object is invalid.
export function isFieldInvalid(validation: TValidation | null, fieldName: string) {
    const fields = validation ? validation.fields : undefined;
    return !!(
        validation &&
        fields &&
        fields[fieldName] &&
        fields[fieldName].isInvalid &&
        fields[fieldName].touched
    );
}

// Returns the array of reasons for a validation field.
export function validationFieldReasons(validation: TValidation, fieldName: string) {
    return isFieldInvalid(validation, fieldName) ? validation.fields[fieldName].reasons : [];
}

export const createValidateField = (reason: string) => {
    const isInvalid = reason.length > 0;

    return {
        isInvalid,
        touched: true,
        reasons: isInvalid ? [reason] : [],
    };
};
