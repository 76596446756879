const ACTIONS_PREFIX = "basics";

export const BASICS_ACTION_TYPES = {
    SET_NAME: `${ACTIONS_PREFIX}/SET_NAME`,
    SET_TAGS: `${ACTIONS_PREFIX}/SET_TAGS`,
    SET_MEASUREMENT_UNIT: `${ACTIONS_PREFIX}/SET_MEASUREMENT_UNIT`,
    SET_DESCRIPTION: `${ACTIONS_PREFIX}/SET_DESCRIPTION`,
    SET_TRAVEL_MODE_CODE: `${ACTIONS_PREFIX}/SET_TRAVEL_MODE_CODE`,
    SET_CALIBRATION_CODE: `${ACTIONS_PREFIX}/SET_CALIBRATION_CODE`,
    SET_AVAILABLE_CALIBRATION_CODES: `${ACTIONS_PREFIX}/SET_AVAILABLE_CALIBRATION_CODES`,
    SET_AADT_CALIBRATION_YEAR: `${ACTIONS_PREFIX}/SET_AADT_CALIBRATION_YEAR`,
    SET_VALIDATION: `${ACTIONS_PREFIX}/SET_VALIDATION`,
    HIDE_VALIDATION_ALERT: `${ACTIONS_PREFIX}/HIDE_VALIDATION_ALERT`,
    RESET_REDUCER: `${ACTIONS_PREFIX}/RESET_REDUCER`,
    SET_INITIAL_DATA: `${ACTIONS_PREFIX}/SET_INITIAL_DATA`,
    SET_COUNTRY: `${ACTIONS_PREFIX}/SET_COUNTRY`,
    UPDATE_ANALYSIS_DATA: `${ACTIONS_PREFIX}/UPDATE_ANALYSIS_DATA`,
};
