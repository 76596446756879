import { ICurrentUser } from "@common/services/services.types";

class HeapAnalyticsService {
    get heap() {
        // @ts-ignore Property 'heap' does not exist on type 'Window & typeof globalThis'.
        return window.heap;
    }

    get isInitialized() {
        return !!this.heap?.appid;
    }

    setUserIdentify(userId: number) {
        if (!this.isInitialized) return;

        this.heap.identify(userId);
    }

    resetUserIdentify() {
        if (!this.isInitialized) return;

        this.heap.resetIdentity();
        this.heap.clearEventProperties();
    }

    addUserProperties(currentUser: ICurrentUser) {
        if (!this.isInitialized) return;

        this.heap.clearEventProperties();

        this.heap.addUserProperties({
            email: currentUser.realUser.email_addr,
            user_id: currentUser.realUser.user_id,
            org_name: currentUser.user.org.org_name,
            org_type: currentUser.user.org.org_type,
            org_id: currentUser.user.org_id,
            org_nickname: currentUser.user.org.org_nickname,
        });
        this.heap.addEventProperties({
            effective_user_id: currentUser.user.user_id,
            effective_email_address: currentUser.user.email_addr,
            actual_user_id: currentUser.realUser.user_id,
            actual_user_email: currentUser.realUser.email_addr,
            is_emulating: currentUser.user.user_id !== currentUser.realUser.user_id,
        });

        if (currentUser.user.study) {
            this.heap.addEventProperties({
                study_id: currentUser.user.study.study_id,
                study_name: currentUser.user.study.study_name,
            });
        }
    }
}

export const heapAnalyticsService = new HeapAnalyticsService();
