import { ZONE_TYPES } from "@common/features/zonesManager/state/zonesManager.constants";

import { ZONES_MANAGER_ACTIONS } from "./zonesManager.actionTypes";
import { ZONES_MANAGER_INITIAL_STATE } from "./zonesManager.state";

export const zonesManagerReducer = (state = ZONES_MANAGER_INITIAL_STATE, { type, data }) => {
    switch (type) {
        case ZONES_MANAGER_ACTIONS.SET_ZONE_LIBRARY_TYPE:
            return {
                ...state,
                zoneLibraryType: data.zoneType,
            };

        case ZONES_MANAGER_ACTIONS.SET_ZONE_LIBRARY_FILTERS:
            return {
                ...state,
                zoneLibraryFilters: {
                    ...state.zoneLibraryFilters,
                    [data.zoneType.id]: {
                        ...state.zoneLibraryFilters[data.zoneType.id],
                        ...data.zoneType.filters,
                    },
                },
            };

        case ZONES_MANAGER_ACTIONS.SET_NETWORK_TYPE:
            return {
                ...state,
                zoneLibraryFilters: {
                    ...state.zoneLibraryFilters,
                    [ZONE_TYPES.OSM_LINE_SEGMENTS.id]: {
                        ...state.zoneLibraryFilters[ZONE_TYPES.OSM_LINE_SEGMENTS.id],
                        networkType: data.networkType,
                    },
                },
            };

        case ZONES_MANAGER_ACTIONS.SET_SEGMENT_SPLIT_TYPE:
            return {
                ...state,
                zoneLibraryFilters: {
                    ...state.zoneLibraryFilters,
                    [ZONE_TYPES.OSM_LINE_SEGMENTS.id]: {
                        ...state.zoneLibraryFilters[ZONE_TYPES.OSM_LINE_SEGMENTS.id],
                        segmentSplitType: data.segmentSplitType,
                    },
                },
            };

        case ZONES_MANAGER_ACTIONS.SET_ROADS:
            return {
                ...state,
                zoneLibraryFilters: {
                    ...state.zoneLibraryFilters,
                    [ZONE_TYPES.OSM_LINE_SEGMENTS.id]: {
                        ...state.zoneLibraryFilters[ZONE_TYPES.OSM_LINE_SEGMENTS.id],
                        roads: data.roads,
                    },
                },
            };

        case ZONES_MANAGER_ACTIONS.SET_SELECTED_ZONE:
            return {
                ...state,
                selectedZone: data.zone,
            };

        case ZONES_MANAGER_ACTIONS.SET_UI_STATE:
            return {
                ...state,
                uiStates: {
                    ...state.uiStates,
                    ...data,
                },
            };

        case ZONES_MANAGER_ACTIONS.SET_SPOT_COUNTER_OSM_LAYERS_CATEGORIES:
            return {
                ...state,
                spotCounterMapLayers: {
                    ...state.spotCounterMapLayers,
                    ...data,
                },
            };

        case ZONES_MANAGER_ACTIONS.UPDATE_SELECTED_ZONE:
            return {
                ...state,
                selectedZone: {
                    ...state.selectedZone,
                    ...data.zone,
                },
            };

        case ZONES_MANAGER_ACTIONS.SET_CLICKED_BUS_ZONES:
            return {
                ...state,
                clickedBusZones: data.clickedBusZones,
            };

        case ZONES_MANAGER_ACTIONS.UPDATE_CLICKED_BUS_ZONES:
            return {
                ...state,
                clickedBusZones: [...state.clickedBusZones, ...data.clickedBusZones],
            };

        case ZONES_MANAGER_ACTIONS.SET_REUSE_ZONES_MODAL_STATE:
            return {
                ...state,
                reuseZonesModal: {
                    ...state.reuseZonesModal,
                    ...data,
                },
            };

        case ZONES_MANAGER_ACTIONS.SET_REUSE_ZONES_CONFIG:
            return {
                ...state,
                reuseZonesConfig: {
                    ...state.reuseZonesConfig,
                    ...data.reuseZonesConfig,
                },
            };

        case ZONES_MANAGER_ACTIONS.SET_MAP_MODE:
            return {
                ...state,
                uiStates: {
                    ...state.uiStates,
                    mapMode: data.mapMode,
                },
            };

        case ZONES_MANAGER_ACTIONS.SET_EDITABLE_FEATURE:
            return {
                ...state,
                uiStates: {
                    ...state.uiStates,
                    editableFeature: data.editableFeature,
                },
            };

        case ZONES_MANAGER_ACTIONS.SET_IS_EDIT_DIRECTION:
            return {
                ...state,
                uiStates: {
                    ...state.uiStates,
                    isEditDirection: data.isEditDirection,
                },
            };

        case ZONES_MANAGER_ACTIONS.RESET_REUSE_ZONES_MODAL_STATE:
            return {
                ...state,
                reuseZonesModal: ZONES_MANAGER_INITIAL_STATE.reuseZonesModal,
            };

        case ZONES_MANAGER_ACTIONS.SET_UPLOAD_ZONES_MODAL_STATE:
            return {
                ...state,
                uploadZonesModal: {
                    ...state.uploadZonesModal,
                    ...data,
                },
            };

        case ZONES_MANAGER_ACTIONS.UPDATE_ZONES_UPLOAD_DATA:
            return {
                ...state,
                uploadZonesModal: {
                    ...state.uploadZonesModal,
                    zonesUploadData: {
                        ...state.uploadZonesModal.zonesUploadData,
                        ...data.zonesUploadData,
                    },
                },
            };

        case ZONES_MANAGER_ACTIONS.RESET_ZONE_MANAGER_STATE:
            return ZONES_MANAGER_INITIAL_STATE;

        default:
            return state;
    }
};
