import { setCalibrationCode } from "@app/analysis/basics/state/basics.actions";
import { validCalibrationCodeSelector } from "@app/analysis/basics/state/basics.selectors";
import type {
    TRouteOptions,
    TTravelMode,
} from "@app/analysis/state/analysisConfiguration.constants";
import {
    getIsAnalysisTypeSupportOption,
    getIsTravelModeSupportOption,
} from "@app/analysis/state/analysisConfiguration.helpers";
import type { TAppDispatch } from "@app/store";
import type { TGetState } from "@app/store/root.reducer";
import type { TAnalysisType } from "@common/constants/analysis.constants";
import {
    DEFAULT_HWL_ZONE_INTERSECTION_TYPE,
    HWL_FILTER_OPTIONS,
    TRAVELER_PARAMS_US_CENSUS_2020,
} from "./addOns.constants";
import { actions } from "./addOns.reducer";
import { getAnalysisAddOns, getHWLSettings, getTravelerAttrSettings } from "./addOns.selectors";
import { ADD_ONS_INITIAL_STATE } from "./addOns.state";
import { TTravelerAttrSettings } from "./addOns.types";

export const {
    addTripBin,
    editTripBin,
    editNewTripBin,
    resetAddOnsReducer,
    saveEditTripBin,
    saveNewTripBin,
    setAddOnsInitialData,
    setAddOnsValidation,
    setBypassPrivacyCheckSetting,
    setBypassSizeValidationCheckSetting,
    setCommercialVehicleType,
    setInitialTripBin,
    setGeogType: setSelectedGeographyType,
    setHwlFilterOptions,
    setHwlZoneIntersectionType: setZoneIntersectionType,
    setRouteOptions,
    setSizeReviewDetails,
    setTravelerAttrEnabled,
    setTravelerBinTypeEnabled,
    setTripAttrEnabled,
    setTripBinTypeEnabled,
    setTripBins,
    toggleBypassPrivacyCheckSetting,
    toggleBypassSizeValidationCheckSetting,
    toggleEnableEmailNotification,
    toggleEnableUpsampling,
    toggleEnableViz,
    toggleIsMassiveQueue,
    toggleHwlFilterOptions,
    toggleHwlSettings,
    toggleTripAttrSettings,
} = actions;

export const toggleTravelerAttrSetting =
    (isSomeTravelerAttrsLimited = false) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();
        const { enabled } = getTravelerAttrSettings(state);

        dispatch(setTravelerAttrEnabled(!enabled));
        if (isSomeTravelerAttrsLimited && !enabled) {
            dispatch(
                setTravelerBinTypeEnabled({
                    binUiStateName: TRAVELER_PARAMS_US_CENSUS_2020.TRIP_PURPOSES
                        .uiStateName as keyof TTravelerAttrSettings,
                    enabled: false,
                }),
            );
        }
    };

export const toggleHwlSetting = () => (dispatch: TAppDispatch, getState: TGetState) => {
    const state = getState();
    const { enabled } = getHWLSettings(state);
    if (enabled) {
        const newHwlFilterOptions = {
            hwl_enable_visitor: HWL_FILTER_OPTIONS.VISITOR.defaultValue,
            hwl_enable_resident: HWL_FILTER_OPTIONS.RESIDENT.defaultValue,
            hwl_enable_worker: HWL_FILTER_OPTIONS.WORKER.defaultValue,
        };

        dispatch(setHwlFilterOptions(newHwlFilterOptions));
        dispatch(setZoneIntersectionType(DEFAULT_HWL_ZONE_INTERSECTION_TYPE.code));
    }

    dispatch(toggleHwlSettings());

    const newState = getState();
    const newCalibrationCode = validCalibrationCodeSelector(newState);
    dispatch(setCalibrationCode(newCalibrationCode));
};

export const updateHwlParams =
    (
        travelModeCode: TTravelMode["code"],
        analysisTypeCode: TAnalysisType["id"],
        hwlEnabled?: boolean,
    ) =>
    (dispatch: TAppDispatch) => {
        if (!hwlEnabled) return;

        const isHwlSupported =
            getIsAnalysisTypeSupportOption("homeWorkLocations", analysisTypeCode) &&
            getIsTravelModeSupportOption(travelModeCode, "homeWorkLocations");

        if (!isHwlSupported) {
            dispatch(toggleHwlSetting());
        }
    };

export const setInitialRouteOptions = () => (dispatch: TAppDispatch) => {
    return dispatch(setRouteOptions(ADD_ONS_INITIAL_STATE.routeOptions));
};

export const clearRouteOptions = () => (dispatch: TAppDispatch, getState: TGetState) => {
    const state = getState();

    const { routeOptions } = getAnalysisAddOns(state);

    const clearedRouteOptions = {} as TRouteOptions;

    Object.keys(routeOptions).forEach(option => {
        clearedRouteOptions[option as keyof TRouteOptions] = false;
    });

    dispatch(setRouteOptions(clearedRouteOptions));
};
