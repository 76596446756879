import {
    CHART_TYPE_OPTIONS,
    SORT_BY_OPTIONS,
} from "@app/viz3/base/components/attributesWidget/attibutesWidget.constants";
import { TIME_FILTERS, VIZ_ZONE_TYPES } from "@app/viz3/base/state/baseViz.constants";
import {
    GROUPING_OPTIONS,
    METRIC_TYPE,
    TIME_DISTRIBUTION_WIDGET_KEY,
} from "@app/viz3/base/widgets/timeDistribution/timeDistribution.constants";
import { TRAVELER_BIN_CATEGORIES_US_CENSUS_2020 } from "@app/viz3/base/widgets/travelerAttributes/travelerAttributesWidget.constants";
import { SPEED_METRIC_TYPES } from "@app/viz3/base/widgets/zoneDistribution/zoneDistribution.constants";
import { METRIC_TYPES } from "@app/viz3/segmentVisualization/widgets/segmentDistribution/segmentDistribution.constants";
import {
    CHART_TYPE_OPTIONS as TIME_DISTRIBUTION_CHART_TYPE_OPTIONS,
    SEGMENT_TIME_DISTRIBUTION_WIDGET_KEY,
    TIME_DISTRIBUTION_METRIC_TYPES,
} from "@app/viz3/segmentVisualization/widgets/timeDistribution/segmentTimeDistribution.constants";
import { TRAFFIC_FLOW_CHART_TYPE_OPTIONS } from "@app/viz3/tmcVisualization/state/tmcViz.constants";
import { MAP_MODES } from "@common/components/baseMap/baseMap.constants";
import { ZONE_SELECTION_TOOLS } from "@common/components/baseMap/customControls/zoneSelectionTools/zoneSelectionTools.constants";

export const DEFAULT_SEGMENT_GROUP_STATE = {
    selectedZoneGroup: null,
    groupType: null,
    selectedSegments: [],
    groupName: "",
    map: {
        selectedToolId: ZONE_SELECTION_TOOLS.CURSOR.id,
        roads: [],
        mapError: null,
        hoveredSegment: null,
    },
};

export const BASE_VIZ_INITIAL_STATE = {
    selectedAnalysis: null,
    filters: {
        [VIZ_ZONE_TYPES.ORIGIN.filterName]: [],
        [VIZ_ZONE_TYPES.DESTINATION.filterName]: [],
        [VIZ_ZONE_TYPES.MIDDLE_FILTER.filterName]: [],
        [TIME_FILTERS.DAY_TYPES.filterName]: [],
        [TIME_FILTERS.DAY_PARTS.filterName]: [],
    },
    changedFilters: {},
    visualization: {
        mapLayersVisibility: {},
        measurement: null,
        visualizationProperty: null,
    },
    widgets: {
        [TIME_DISTRIBUTION_WIDGET_KEY]: {
            groupBy: GROUPING_OPTIONS.dayPart.id,
            metricType: METRIC_TYPE.volume.id,
        },
        tripAttributes: {
            sortBy: SORT_BY_OPTIONS.DEFAULT.value,
            chartType: CHART_TYPE_OPTIONS.HISTOGRAM.value,
        },
        travelerAttributes: {
            sortBy: SORT_BY_OPTIONS.DEFAULT.value,
            chartType: CHART_TYPE_OPTIONS.HISTOGRAM.value,
            category: TRAVELER_BIN_CATEGORIES_US_CENSUS_2020.EQUITY_DEMOGRAPHICS.code,
        },
        segmentDistribution: {
            metricType: METRIC_TYPES.traffic.id,
            speedMetricType: SPEED_METRIC_TYPES.AVG_SPEED.code,
        },
        segmentSpeed: {
            sortBy: SORT_BY_OPTIONS.DEFAULT.value,
        },
        segmentDuration: {
            sortBy: SORT_BY_OPTIONS.DEFAULT.value,
        },
        [SEGMENT_TIME_DISTRIBUTION_WIDGET_KEY]: {
            groupBy: GROUPING_OPTIONS.dayPart.id,
            metricType: TIME_DISTRIBUTION_METRIC_TYPES.vmt.id,
            chartType: TIME_DISTRIBUTION_CHART_TYPE_OPTIONS.CHART.id,
        },
        zoneDistribution: {
            sortBy: SORT_BY_OPTIONS.DEFAULT.value,
            selectedBehavior: null,
        },
        trafficFlow: {
            chartType: TRAFFIC_FLOW_CHART_TYPE_OPTIONS.DIAGRAM.value,
        },
        tmcTimeDistribution: {
            chunkIndex: 0,
        },
    },
    map: {
        style: null,
        hoveredZone: null,
        staticGeometries: null,
        loaded: false,
        mode: MAP_MODES.DEFAULT,
        location: null,
        error: {
            errorTexts: [],
            isServerError: false,
        },
        isLoading: false,
    },
    multipleSelection: {
        zoneType: null,
        selectedZoneIds: {
            [VIZ_ZONE_TYPES.ORIGIN.filterName]: [],
            [VIZ_ZONE_TYPES.DESTINATION.filterName]: [],
        },
    },
    pendingExportRequests: {
        arePending: false,
        requestCount: 0,
    },
    segmentsGroupConfigurations: DEFAULT_SEGMENT_GROUP_STATE,
    filterHash: null,
};
