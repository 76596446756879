import { batch } from "react-redux";
import { cloneDeep } from "lodash-es";
import { getAvailableDataPeriodsForMode } from "@app/analysis/state/analysisConfiguration.selectors";
import { getCensusMixingPermitted } from "@app/analysis/state/general/general.selectors";
import { TIME_PERIODS_ACTION_TYPES } from "@app/analysis/timePeriods/state/timePeriods.actionTypes";
import { getDataPeriodSettings } from "@app/analysis/timePeriods/state/timePeriods.selectors";
import { setTimePeriodsValidation } from "@app/analysis/timePeriods/state/timePeriods.actions";
import { validateDataPeriodsField } from "@app/analysis/timePeriods/components/dataPeriodParams/dataPeriodParams.actions";
import {
    checkAreDatesWithinLimits,
    getAvailableDatesText,
} from "@app/analysis/timePeriods/components/dataPeriodParams/dataPeriodParams.helpers";
import { getIsUSTruckVolumeAnalysis } from "@app/analysis/basics/state/basics.selectors";
import type { TAppDispatch } from "@app/store";
import type { TGetState } from "@app/store/root.reducer";
import type { IDateRange, ISortedDateRange } from "@app/analysis/timePeriods/timePeriods.types";

/*
 * Modifies dataPeriods to include validation status.
 * Returns the number of invalid data periods.
 */
export const validateDataPeriods = (
    dataPeriods: Array<ISortedDateRange>,
    whiteListDateRanges: Array<IDateRange>,
) => {
    const sortedDataPeriods = [] as Array<ISortedDateRange>;
    let invalidCount = 0;

    // first check that range is defined and that startDate < endDate
    dataPeriods.forEach(dataPeriod => {
        if (!checkAreDatesWithinLimits(whiteListDateRanges, dataPeriod)) {
            dataPeriod.isInvalid = true;
            dataPeriod.reasons = [getAvailableDatesText(whiteListDateRanges)];
            invalidCount += 1;
        } else if (!dataPeriod.startDate || !dataPeriod.endDate) {
            dataPeriod.isInvalid = true;
            dataPeriod.reasons = ["Please provide first/last dates"];
            invalidCount += 1;
        } else if (dataPeriod.startDate > dataPeriod.endDate) {
            dataPeriod.isInvalid = true;
            dataPeriod.reasons = ["First date cannot be after last date"];
            invalidCount += 1;
        } else {
            delete dataPeriod.isInvalid;
            delete dataPeriod.reasons;
            sortedDataPeriods.push(dataPeriod);
        }
    });

    if (invalidCount > 0 || dataPeriods.length <= 1) {
        // Overlap is not checked if there are invalid periods
        // or only a single period
        return invalidCount;
    }

    // now check overlap
    sortedDataPeriods.sort((period1, period2) => {
        if (period1.startDate < period2.startDate) {
            return -1;
        }
        if (period1.startDate > period2.startDate) {
            return 1;
        }
        return 0;
    });

    let prev = sortedDataPeriods[0];
    for (let idx = 1; idx < sortedDataPeriods.length; idx++) {
        const dataPeriod = sortedDataPeriods[idx];
        if (dataPeriod.startDate <= prev.endDate) {
            invalidCount += 1;
            dataPeriod.isInvalid = true;
            dataPeriod.reasons = ["Overlaps"];
            prev.isInvalid = true;
            prev.reasons = ["Overlaps"];
        }
        prev = dataPeriod;
    }

    return invalidCount;
};

export const setDataPeriod = (dataPeriodType: string, newDataPeriod: Array<IDateRange>) => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_DATA_PERIOD,
    data: { dataPeriodType, dataPeriod: newDataPeriod },
});

export const cancelEditDataPeriod =
    (index: number, dataPeriodType: string) => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const dataPeriodSettings = getDataPeriodSettings(state);
        const whiteListDateRanges = getAvailableDataPeriodsForMode(state) as Array<IDateRange>;
        const censusMixingPermitted = getCensusMixingPermitted(state);
        const isTruckVolumeAnalysis = getIsUSTruckVolumeAnalysis(state) as boolean;

        const dataPeriods = cloneDeep(dataPeriodSettings[dataPeriodType]);

        if (dataPeriods[index].status === "adding") {
            // Just throw away if was adding
            dataPeriods.splice(index, 1);
        } else {
            // Roll back
            dataPeriods[index] = {
                startDate: dataPeriodSettings[dataPeriodType][index].startDate,
                endDate: dataPeriodSettings[dataPeriodType][index].endDate,
            };
        }

        // re-validate to clear cross-data-period validation issues
        validateDataPeriods(dataPeriods, whiteListDateRanges);

        const newValidation = {
            [dataPeriodType]: validateDataPeriodsField({
                dataPeriods,
                dataPeriodType,
                censusMixingPermitted,
                isTruckVolumeAnalysis,
            }),
        };

        batch(() => {
            dispatch(setDataPeriod(dataPeriodType, dataPeriods));
            dispatch(setTimePeriodsValidation(newValidation));
        });
    };

export const editDataPeriod =
    (index: number, dataPeriodType: string) => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const dataPeriodSettings = getDataPeriodSettings(state);

        const dataPeriod = dataPeriodSettings[dataPeriodType][index];
        const editingDataPeriod = {
            startDate: dataPeriod.startDate,
            endDate: dataPeriod.endDate,
            editStartDate: dataPeriod.startDate,
            editEndDate: dataPeriod.endDate,
            status: "editing",
        };

        const newDataPeriods = [...dataPeriodSettings[dataPeriodType]];
        newDataPeriods[index] = editingDataPeriod;

        dispatch(setDataPeriod(dataPeriodType, newDataPeriods));
    };

export const saveDataPeriod =
    (index: number, dataPeriodType: string) => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const dataPeriodSettings = getDataPeriodSettings(state);
        const whiteListDateRanges = getAvailableDataPeriodsForMode(state) as Array<IDateRange>;
        const censusMixingPermitted = getCensusMixingPermitted(state);
        const isTruckVolumeAnalysis = getIsUSTruckVolumeAnalysis(state) as boolean;

        const dataPeriods = cloneDeep(dataPeriodSettings[dataPeriodType]);

        // If data is invalid want to preserve editing status and rollback data,
        // so update the data period but preserve status and previous data.
        const dataPeriodToUpdate = dataPeriods[index];

        dataPeriods[index] = {
            ...dataPeriods[index],
            startDate: dataPeriodToUpdate.editStartDate,
            endDate: dataPeriodToUpdate.editEndDate,
        };

        // validateDataPeriods will mark invalid items in updatedDataPeriods
        validateDataPeriods(dataPeriods, whiteListDateRanges);

        if (!dataPeriods[index].isInvalid) {
            // Valid, so done editing.  Create fresh data so that status is no longer editing/adding.
            // TODO - do we need some abstractions for how to create and interact with data period data?
            dataPeriods[index] = {
                startDate: dataPeriodToUpdate.editStartDate,
                endDate: dataPeriodToUpdate.editEndDate,
            };
        } else {
            dataPeriods[index].startDate = dataPeriodToUpdate.startDate;
            dataPeriods[index].endDate = dataPeriodToUpdate.endDate;
        }

        const newValidation = {
            [dataPeriodType]: validateDataPeriodsField({
                dataPeriods,
                dataPeriodType,
                censusMixingPermitted,
                isTruckVolumeAnalysis,
            }),
        };

        batch(() => {
            dispatch(setDataPeriod(dataPeriodType, dataPeriods));
            dispatch(setTimePeriodsValidation(newValidation));
        });
    };

export const removeDataPeriod =
    (index: number, dataPeriodType: string) => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const dataPeriodSettings = getDataPeriodSettings(state);
        const censusMixingPermitted = getCensusMixingPermitted(state);
        const isTruckVolumeAnalysis = getIsUSTruckVolumeAnalysis(state) as boolean;
        const dataPeriods = (dataPeriodSettings[dataPeriodType] as Array<IDateRange>).filter(
            (periods, i) => i !== index,
        );

        const newValidation = {
            [dataPeriodType]: validateDataPeriodsField({
                dataPeriods,
                dataPeriodType,
                censusMixingPermitted,
                isTruckVolumeAnalysis,
            }),
        };

        batch(() => {
            dispatch(setDataPeriod(dataPeriodType, dataPeriods));
            dispatch(setTimePeriodsValidation(newValidation));
        });
    };

export const updateEditStartDate =
    (index: number, editStartDate: number, dataPeriodType: string) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const dataPeriodSettings = getDataPeriodSettings(state);

        const newDataPeriod = [...dataPeriodSettings[dataPeriodType]];

        newDataPeriod[index] = {
            ...newDataPeriod[index],
            editStartDate,
        };

        dispatch(setDataPeriod(dataPeriodType, newDataPeriod));
    };

export const updateEditEndDate =
    (index: number, editEndDate: number, dataPeriodType: string) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const dataPeriodSettings = getDataPeriodSettings(state);

        const newDataPeriod = [...dataPeriodSettings[dataPeriodType]];

        newDataPeriod[index] = {
            ...newDataPeriod[index],
            editEndDate,
        };

        dispatch(setDataPeriod(dataPeriodType, newDataPeriod));
    };
