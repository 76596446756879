import { AuthLayout } from "@app/auth/layout/authLayout";
import { StlStatus404 } from "./status404";

export const StlStatus404Page = () => {
    return (
        <AuthLayout>
            <StlStatus404 />
        </AuthLayout>
    );
};
