export const SUPPORT_CONTACT_EMAIL = "support@streetlightdata.com";

export const DEFAULT_AUTO_REFRESH_INTERVAL_MS = 15000;

export const THEME_KEY = "THEME";

export const APP_THEME = {
    DARK: "dark",
    LIGHT: "light",
} as const;
export type TAppTheme = typeof APP_THEME[keyof typeof APP_THEME];

export const DEFAULT_THEME = APP_THEME.DARK;

export const EMPTY_FN = () => {};
