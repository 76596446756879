import React, { useMemo } from "react";
import { StlCheckbox } from "@common/components/checkbox/checkbox";
import "./checkboxGroup.less";

type TValue = Record<string, boolean>;
type TOption = {
    [key: string]: any;
    disabled?: boolean;
};
type TProps = {
    value: TValue;
    options: TOption[];
    onChange: (value: TValue) => void;
    disabled?: boolean;
    testid?: string;
    getOptionLabel?: (option: TOption) => string;
    getOptionValue?: (option: TOption) => string;
};

export function StlCheckboxGroup({
    value,
    options = [],
    disabled,
    testid,
    onChange,
    getOptionLabel = option => option.label,
    getOptionValue = option => option.value,
}: TProps): JSX.Element {
    const innerOptions = useMemo(() => {
        return options.map((option: any) => {
            return {
                label: getOptionLabel(option),
                value: getOptionValue(option),
                disabled: !!option.disabled,
                shouldAddSeparator: option.shouldAddSeparator,
            } as TOption;
        });
    }, [options, getOptionLabel, getOptionValue]);

    return (
        <div className="stl-checkbox-group">
            {innerOptions.map(
                ({
                    value: optionValue,
                    label: optionLabel,
                    disabled: _disabled,
                    shouldAddSeparator,
                }: TOption) => (
                    <React.Fragment key={optionValue}>
                        {shouldAddSeparator && <div className="separator" />}
                        <StlCheckbox
                            data-testid={testid}
                            checked={value[optionValue] || false}
                            disabled={!!(disabled || _disabled)}
                            onChange={() =>
                                onChange({
                                    ...value,
                                    [optionValue]: !value[optionValue],
                                })
                            }
                        >
                            {optionLabel}
                        </StlCheckbox>
                    </React.Fragment>
                ),
            )}
        </div>
    );
}
