import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { LocationControl } from "@common/components/baseMap/customControls";
import { ZoomToLocation } from "@common/components/baseMap/zoomToLocation/zoomToLocation";
import type { Map } from "mapbox-gl";
import type { ILocation } from "@common/components/geocoder/geocoder";
import type { TControlConfig } from "@common/components/baseMap/baseMap.types";

export type TProps = {
    map: Map | null;
    config: boolean | { open: boolean };
    position: TControlConfig["position"];
    mapId?: string;
    location?: ILocation | null;
    onLocationChange?: (location: ILocation) => void;
};

export const useLocationControl = ({
    map,
    config,
    position,
    mapId,
    location,
    onLocationChange,
}: TProps) => {
    const [isControlAdded, setIsControlAdded] = useState(false);

    const { enabled, open } = useMemo(() => {
        return {
            enabled: !!config,
            open: typeof config === "boolean" ? false : config.open,
        };
    }, [config]);

    const locationControl = useMemo(() => {
        return enabled ? new LocationControl({ onAdd: () => setIsControlAdded(true) }) : null;
    }, [enabled]);

    useEffect(() => {
        if (!map || !locationControl) return;

        map.addControl(locationControl, position);
    }, [map, locationControl, position]);

    useEffect(() => {
        if (!map || !locationControl || !isControlAdded) return;

        locationControl.switchOpening(open);
    }, [locationControl, map, isControlAdded, open]);

    return useCallback(() => {
        if (!map || !locationControl || !isControlAdded) return null;

        const zoomToLocationInput = (
            <ZoomToLocation
                map={map}
                mapId={mapId}
                location={location}
                setLocation={onLocationChange}
            />
        );

        return ReactDOM.createPortal(zoomToLocationInput, locationControl.locationContainer);
    }, [map, locationControl, isControlAdded, mapId, location, onLocationChange]);
};
