import { useContext, useMemo } from "react";
import type { Map } from "mapbox-gl";
import {
    getAvailableOSMLayers,
    getSelectedOsmLayersCategories,
} from "@app/store/currentUser/currentUser.selector";
import { useAppSelector } from "@app/store/hooks";
import { MapContext } from "@common/components/baseMap";
import { OSM_LAYERS_LIST, TMapLayersLocalState, TOSMLayer } from "../mapLayers.constants";
import { useDrawLineLayers, useOSMLayersVisibility } from "./useDrawLineLayers";
import { useWindshaftLayers } from "./useWindshaftLayers";

type TProps = {
    map?: Map | null;
    mapLayersLocalState?: Pick<TMapLayersLocalState, "osmLayersCategories"> | null;
};

export const OsmLayers = ({ map, mapLayersLocalState }: TProps) => {
    const { map: mapFromContext } = useContext(MapContext);
    const _map = map || mapFromContext;

    const availableOSMLayers = useAppSelector(getAvailableOSMLayers);
    const persistedOsmLayersWithCategories = useAppSelector(getSelectedOsmLayersCategories);

    const selectedOsmLayersCategories = useMemo(() => {
        if (mapLayersLocalState) {
            return availableOSMLayers.reduce((res, layer) => {
                const osmLayersCategoryChildren =
                    mapLayersLocalState.osmLayersCategories[layer.code];

                if (osmLayersCategoryChildren?.length) {
                    res.push({
                        ...layer,
                        // @ts-ignore
                        children: osmLayersCategoryChildren,
                    });
                }

                return res;
            }, []);
        }
        return persistedOsmLayersWithCategories;
    }, [availableOSMLayers, mapLayersLocalState, persistedOsmLayersWithCategories]);

    const availableOSMLayerCodes = useMemo(
        () => availableOSMLayers.map(layer => layer.layerCode),
        [availableOSMLayers],
    ) as Array<TOSMLayer["layerCode"]>;

    const selectedOSMLayerCodes = useMemo(() => {
        return selectedOsmLayersCategories.map((layer: TOSMLayer) => {
            const osmLayer = OSM_LAYERS_LIST.find(_osmLayer => _osmLayer.code === layer.code)!;

            return osmLayer.layerCode;
        }) as Array<TOSMLayer["layerCode"]>;
    }, [selectedOsmLayersCategories]);

    const { source, windshaftLayers } = useWindshaftLayers(_map, {
        osmLayerCodes: availableOSMLayerCodes,
    });

    useDrawLineLayers(_map, {
        source,
        windshaftLayers,
        osmLayerCategories: selectedOsmLayersCategories,
    });

    useOSMLayersVisibility(_map, { windshaftLayers, osmLayerCodes: selectedOSMLayerCodes });

    return null;
};
