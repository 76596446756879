import { MutableRefObject, useEffect } from "react";
import type { Map } from "mapbox-gl";
import { debounce } from "lodash-es";
import { MAP_RESIZE_ANIMATION_TIME } from "@common/components/baseMap/baseMap.constants";

export const useMapResize = (
    map: Map | null,
    mapContainerRef: MutableRefObject<HTMLDivElement | null>,
) => {
    useEffect(() => {
        const node = mapContainerRef.current;

        if (!node) return undefined;

        const debouncedResize = debounce(() => {
            if (!map) return;

            if (map.isMoving() || map.isEasing() || map.isZooming()) {
                // repeat later because the map is not ready
                debouncedResize();
                return;
            }

            const center = map.getCenter();
            map.resize();
            // restore map center to avoid map's offset
            map.setCenter(center);
        }, MAP_RESIZE_ANIMATION_TIME);

        const resizeObserver = new ResizeObserver(debouncedResize);
        resizeObserver.observe(node);

        return () => resizeObserver.unobserve(node);
    }, [map, mapContainerRef]);
};
