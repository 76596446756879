import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { REVIEW_INITIAL_STATE, TReviewState } from "@app/analysis/review/state/review.state";

export const { actions, reducer } = createSlice({
    name: "reviewReducer",
    initialState: REVIEW_INITIAL_STATE,
    reducers: {
        updateCurrentReviewNote: (state, action: PayloadAction<string>) => {
            state.currentReviewNote = action.payload;
        },
        updateByPassCoverageReview: (state, action: PayloadAction<boolean>) => {
            state.byPassCoverageReview = action.payload;
        },
        updateByPassRuntimeReview: (state, action: PayloadAction<boolean>) => {
            state.byPassRuntimeReview = action.payload;
        },
        setReviewInitialData: (state, action: PayloadAction<Partial<TReviewState>>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        resetReviewReducer: () => {
            return REVIEW_INITIAL_STATE;
        },
    },
});

export const reviewReducer = reducer;
