import type {
    TATAvailableStatesMap,
    TATAvailableCountiesMap,
    IATAvailableCounty,
    TATSelectedCensusTractsMap,
    IATAvailableState,
} from "@app/analysisLightning/activeTransportation/state/activeTransportation.types";

export interface IActiveTransportationState {
    availableTimePeriods: Array<number>;
    availableStatesMap: TATAvailableStatesMap;
    availableCountiesMap: TATAvailableCountiesMap;
    selectedCensusTracts: TATSelectedCensusTractsMap;
    selectedStateId: IATAvailableState["zone_id"] | null;
    selectedCountyId: IATAvailableCounty["zone_id"] | null;
}

export const ACTIVE_TRANSPORTATION_INITIAL_STATE = {
    availableTimePeriods: [],
    availableStatesMap: {},
    availableCountiesMap: {},
    selectedCensusTracts: {},
    selectedStateId: null,
    selectedCountyId: null,
} as IActiveTransportationState;
