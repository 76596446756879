import {
    MEASUREMENT_UNITS,
    MEASUREMENT_UNITS_LIST,
    SPEED_UNITS,
} from "@common/constants/measurementUnits.constants";
import { ORG_COUNTRIES } from "@common/constants/analysis.constants";
import { TRIP_BIN_TYPES } from "@app/analysis/addOns/state/addOns.constants";
import { VISUALIZATION_PROPERTIES } from "@app/viz3/segmentVisualization/state/segmentViz.constants";
import { EMeasurementUnits } from "@common/features/orgs/orgs.types";

interface IUnits {
    [key: string]: string;
}

export interface IAttribute {
    code: string;
    unit?: string;
    units?: IUnits | null;
    [key: string]: any;
}

export const getSpeedUnitDisplayNameById = (measurementUnitId: EMeasurementUnits) => {
    if (measurementUnitId === MEASUREMENT_UNITS.MILES.id) {
        return SPEED_UNITS.MPH.name;
    }
    return SPEED_UNITS.KPH.name;
};

export const getMeasurementUnitById = (measurementUnitId: EMeasurementUnits) =>
    MEASUREMENT_UNITS_LIST.find(measurementUnit => measurementUnit.id === measurementUnitId);

export const getMeasurementUnitDisplayNameById = (measurementUnitId: EMeasurementUnits) =>
    getMeasurementUnitById(measurementUnitId)?.name;

export const getDefaultMeasurementUnitByCountries = (
    countries: Array<"country_ca" | "country_us">,
) => {
    if (!countries.length) return MEASUREMENT_UNITS.MILES.id;

    if (countries.length === 1 && countries[0] === ORG_COUNTRIES.CA.id) {
        return MEASUREMENT_UNITS.KILOMETERS.id;
    }

    return MEASUREMENT_UNITS.MILES.id;
};

export const getAttributeMeasurementUnit = ({
    attribute,
    measurementUnit,
}: {
    attribute: IAttribute;
    measurementUnit: EMeasurementUnits;
}) => {
    if (attribute.units) {
        if (attribute.code === TRIP_BIN_TYPES.LENGTH.code) {
            if (measurementUnit === MEASUREMENT_UNITS.MILES.id) return attribute.units.MILES;
            if (measurementUnit === MEASUREMENT_UNITS.KILOMETERS.id) {
                return attribute.units.KILOMETERS;
            }
        }

        if (
            attribute.code === TRIP_BIN_TYPES.SPEED.code ||
            attribute.code === VISUALIZATION_PROPERTIES.AVG_SPEED.code
        ) {
            if (measurementUnit === MEASUREMENT_UNITS.MILES.id) return attribute.units.MPH;
            if (measurementUnit === MEASUREMENT_UNITS.KILOMETERS.id) return attribute.units.KPH;
        }
    }

    if (attribute.unit) return attribute.unit;

    return null;
};
