// Service to sign into Esri ArcGIS Online using OAuth2 via Esri's ArcGIS API for JavaScript.
// This exports a singleton instance.

// See the following Esri docs:
// https://developers.arcgis.com/documentation/mapping-apis-and-services/security/oauth-2.0/
// https://developers.arcgis.com/javascript/latest/sample-code/identity-oauth-basic/
// https://developers.arcgis.com/javascript/latest/api-reference/esri-identity-IdentityManager.html
// https://developers.arcgis.com/javascript/latest/api-reference/esri-identity-OAuthInfo.html

import esriId from "@arcgis/core/identity/IdentityManager";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import { store } from "@app/store";
import { getConfigParams } from "@app/store/currentUser/currentUser.selector";

export interface IEsriId {
    userId: string | null;
    token: string | null;
}

class EsriService {
    isInitialized = false;
    esriUrl = "";

    initializeIfNeeded() {
        if (this.isInitialized) return;

        const state = store.getState();
        const { esri_app_id } = getConfigParams(state);
        const info = new OAuthInfo({
            appId: esri_app_id,
            flowType: "auto",
            popup: false,
        });

        esriId.registerOAuthInfos([info]);
        this.esriUrl = `${info.portalUrl}/sharing`;
        this.isInitialized = true;
    }

    checkEsriId() {
        // Check currrent Esri Sign-in ID.
        // Returns a Promise that resolves to { userid, token }.
        // If not signed in, userid and token are null.

        this.initializeIfNeeded();
        return new Promise(resolve => {
            esriId
                .checkSignInStatus(this.esriUrl) // Throws if not signed in.
                .then(() => {
                    return esriId.getCredential(this.esriUrl);
                })
                .then(cred => {
                    const { userId, token } = cred;
                    resolve({ userId, token });
                })
                .catch(() => {
                    resolve({ userId: null, token: null });
                });
        });
    }

    signin() {
        this.initializeIfNeeded();
        esriId.getCredential(this.esriUrl);
    }

    signout() {
        esriId.destroyCredentials();
    }
}

export const esriService = new EsriService();
