import { lazy } from "react";
import { ROUTES } from "@common/constants/routes.constants";

const DashboardScreenshots = lazy(
    () => import("@app/bigBang/dashboard/screenshots/dashboardScreenshots.page"),
);

export const routes = [
    {
        path: `${ROUTES.DASHBOARD}/screenshots/:dashboardStateId`,
        component: DashboardScreenshots,
        id: "dashboard.Screenshots",
    },
];
