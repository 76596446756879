import { ReactNode, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { StlButton } from "@common/components/button/button";
import { StlPopover } from "@common/components/popover/popover";
import type { PopoverOrigin } from "@material-ui/core/Popover/Popover";
import type { StlPopoverHandlers } from "@common/components/popover/popover";
import "./confirmationPopover.less";

type TProps = {
    title?: string;
    onCancel?: () => void;
    onConfirm: () => void;
    buttonText: ReactNode;
    confirmationText: ReactNode;
    buttonVariant: "primary" | "secondary" | "link" | "naked";
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
};

export const StlConfirmationPopover = ({
    onConfirm = () => {},
    onCancel = () => {},
    ...props
}: TProps) => {
    const popover = useRef<StlPopoverHandlers>(null);

    const handleConfirm = () => {
        popover.current?.close();
        onConfirm();
    };

    const handleCancel = () => {
        popover.current?.close();
        onCancel();
    };

    return (
        <StlPopover
            id="confirmation-popover"
            className="stl-confirmation-popover"
            ref={popover}
            sourceControl={<StlButton variant={props.buttonVariant}>{props.buttonText}</StlButton>}
            contentClickable
            {...props}
        >
            <h4>{props.confirmationText}</h4>
            <StlButton variant="link" onClick={handleConfirm}>
                <FormattedMessage id="app.yes" defaultMessage="Yes" />
            </StlButton>
            <StlButton variant="link" onClick={handleCancel}>
                <FormattedMessage id="app.no" defaultMessage="No" />
            </StlButton>
        </StlPopover>
    );
};
