import moment from "moment";
import { NONE_FILTER_OPTION } from "@common/constants/filters.constants";
import {
    EDITABLE_OSM_ZONE_KIND_IDS,
    RAIL_ZONE_KINDS,
    ZONE_KINDS,
    TZoneKind,
} from "@common/constants/zoneLibrary.constants";
import { DIRECTION_OPTIONS } from "@common/features/zonesManager/state/zonesManager.constants";
import { arrayIncludes } from "@common/utils/arrayIncludes";
import {
    getBusVintageDate,
    getOSMVintageDate,
    getRailsVintageDate,
    getStandardAreaVintageDate,
} from "@common/features/zonesManager/components/libraryZonesPicker/libraryZonesPicker.helpers";
import {
    IZoneVintageDates,
    TCustomZone,
    TZonesManagerZone,
} from "../zonesManager/zonesManager.types";

export interface IZonesByZoneKind {
    zone_ids: Array<string>;
    zone_kind_id: TZonesManagerZone["zone_kind_id"];
}

type IZonesData = {
    [key in TZonesManagerZone["zone_kind_id"]]: IZonesByZoneKind;
};

export const getZonesByZoneKind = <T extends Pick<TZonesManagerZone, "zone_id" | "zone_kind_id">>(
    zones: Array<T>,
) => {
    if (!zones?.length) return { zones: [] };

    const zonesData = zones.reduce((result, zone) => {
        const zoneIds = result[zone.zone_kind_id]?.zone_ids || [];

        result[zone.zone_kind_id] = {
            zone_kind_id: zone.zone_kind_id,
            zone_ids: [...zoneIds, String(zone.zone_id)],
        };

        return result;
    }, {} as IZonesData);

    return { zones: Object.values(zonesData) };
};

export const getDirectionData = (zone: Pick<TCustomZone, "is_bidi" | "direction">) => {
    const is_bidi = zone.is_bidi;
    const direction = zone.direction;
    let direction_type;

    if (is_bidi) {
        direction_type = DIRECTION_OPTIONS.BiDirectional.id;
    } else if (!direction) {
        direction_type = DIRECTION_OPTIONS.NoDirection.id;
    } else {
        direction_type = DIRECTION_OPTIONS.UniDirectional.id;
    }

    if (direction !== null && direction !== undefined) {
        const isBiDirectional = direction_type === DIRECTION_OPTIONS.BiDirectional.id;

        return isBiDirectional
            ? {
                  imageSource: "/img/bi-directional.svg",
                  directionType: DIRECTION_OPTIONS.BiDirectional,
                  direction,
              }
            : {
                  imageSource: "/img/uni-directional.svg",
                  directionType: DIRECTION_OPTIONS.UniDirectional,
                  direction,
              };
    }

    return {
        imageSource: "/img/no-direction.svg",
        directionType: DIRECTION_OPTIONS.NoDirection,
        direction: 0,
    };
};

export const getBulkActionZonesInputs = (zones: Array<TZonesManagerZone>) => {
    return zones.map(zone => ({
        zone_kind_id: zone.zone_kind_id,
        zone_id: Number(zone.zone_id),
        sa_version_id: (zone as TCustomZone).sa_version_id || null,
    }));
};

export const getZonesTableCellValue = (cell: any) =>
    cell.getValue ? cell.getValue() : cell.value;

export const getZonesTableRowData = (row: any) => row._original || row.original;

export const getZoneVintageFilterOptions = (
    zoneKindsList: TZoneKind[],
    zoneVintageDates?: IZoneVintageDates,
) =>
    zoneKindsList
        .reduce((res, option) => {
            const zoneVintage = zoneVintageDates?.[option.id[0]];
            let zoneVintageDate;
            switch (option.code) {
                // Remove GRID zone kind from available options as it is not supported
                case ZONE_KINDS.GRID.code:
                    return res;
                case ZONE_KINDS.USER.code:
                case ZONE_KINDS.INTERSECTION.code:
                    zoneVintageDate = NONE_FILTER_OPTION.value;
                    break;
                case ZONE_KINDS.OSM.code:
                    zoneVintageDate = getOSMVintageDate(zoneVintage);
                    break;
                case ZONE_KINDS.BUS.code:
                    zoneVintageDate = getBusVintageDate(zoneVintage);
                    break;
                case ZONE_KINDS.RAIL.code:
                    [RAIL_ZONE_KINDS.SEGMENTS, RAIL_ZONE_KINDS.STATIONS].forEach(railOption => {
                        zoneVintageDate = getRailsVintageDate(zoneVintageDates?.[railOption.id]);
                        if (!res.includes(zoneVintageDate)) {
                            res.push(zoneVintageDate);
                        }
                    });
                    return res;
                default:
                    zoneVintageDate = getStandardAreaVintageDate(zoneVintage, option.year);
                    break;
            }
            if (!res.includes(zoneVintageDate)) {
                res.push(zoneVintageDate);
            }

            return res;
        }, [] as Array<string>)
        .sort((a, b) => moment(a).valueOf() - moment(b).valueOf())
        .map(zoneVintageDate => ({
            label:
                zoneVintageDate === NONE_FILTER_OPTION.value
                    ? NONE_FILTER_OPTION.label
                    : zoneVintageDate,
            value: zoneVintageDate,
        }));

type TZone = {
    zone_kind_id: number;
    display_zone_kind_id?: number;
};

export const getIsOSMSegment = (zone: TZone) =>
    arrayIncludes(EDITABLE_OSM_ZONE_KIND_IDS, Number(zone.zone_kind_id)) ||
    arrayIncludes(EDITABLE_OSM_ZONE_KIND_IDS, Number(zone.display_zone_kind_id));
