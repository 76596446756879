import { routes as authRoutes } from "@app/auth/auth.routing";
import { routes as bigBangRoutes } from "@app/bigBang/bigBang.routing";
import { routes as analysisRoutes } from "@app/bigBang/analyses/analysis.routing";
import { routes as dashboardRoutes } from "@app/bigBang/dashboard/dashboard.routing";
import { routes as manageZoneSetRoutes } from "@app/bigBang/manageZoneSet/manageZoneSet.routing";
import { routes as viz3Routes } from "@app/viz3/viz3.routing";
import { routes as pirateShipRoutes } from "@root/src/app/pirateShip/pirateShip.routing";
import { routes as safetySolutionDashboardRoutes } from "@app/safetySolutionDashboard/safetySolutionDashboard.routing";
import { StlStatus404Page } from "@app/auth/statuses";

export const routes = [
    ...authRoutes,
    ...analysisRoutes,
    ...viz3Routes,
    ...dashboardRoutes,
    ...manageZoneSetRoutes,
    ...pirateShipRoutes,
    ...safetySolutionDashboardRoutes,
    ...bigBangRoutes,
    { path: "*", id: "stlStatus404", component: StlStatus404Page },
];
