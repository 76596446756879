import { ReactNode, useState } from "react";
import { PopoverOrigin } from "@material-ui/core/Popover/Popover";
import { StlPopover } from "@common/components/popover/popover";
import "./iconPopover.less";

const INITIAL_STATE = {
    isActive: false,
} as const;

type TProps = {
    className?: string;
    title?: string;
    content: ReactNode;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    allowMouseLeaveOnSourceControl?: boolean;
    contentClickable?: boolean;
};

export const StlIconPopover = ({ title, content, ...props }: TProps) => {
    const [isActive, setIsActive] = useState<boolean>(INITIAL_STATE.isActive);

    const helpIcon = (
        <span className="stl-icon-popover fa fa-info-circle">
            <span className="sr-only">More information</span>
            {/*
                Conditionally render content for screen reader.
                It shouldn't read the content until the user interacts with popover icon
            */}
            {isActive && <span className="sr-only">{content}</span>}
        </span>
    );

    return (
        <StlPopover
            trigger={["hover", "focus"]}
            sourceControl={helpIcon}
            title={title}
            setActive={setIsActive}
            {...props}
        >
            {content}
        </StlPopover>
    );
};
