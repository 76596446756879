import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useAppSelector } from "@app/store/hooks";
import { snackbarEventManager } from "@common/components/snackbar/snackbarEventManager";
import { getApplicationPreferences } from "@app/store/userPreferences/userPreferences.selector";
import type {
    ISnackbar,
    TSnackbarPosition,
    TSnackbarsByPosition,
} from "@common/components/snackbar/snackbar.types";
import { SnackbarComponent } from "../snackbarComponent/snackbarComponent";
import "./snackbarsContainer.less";

const INITIAL_STATE = {
    snackbars: [],
    snackbarsByPosition: {},
};

const DEFAULT_SNACKBAR_OPTIONS = {
    id: null,
    type: null,
    position: "top-right",
    hideCloseButton: false,
    hideTimeout: 5000,
    pauseTimeoutOnHover: true,
} as const;

const getSnackbarId = () => Math.random();

const getHideTimeout = (hideTimeout: number | undefined, isAutoDismissEnabled: boolean) => {
    if (!isAutoDismissEnabled) return 0;

    return hideTimeout === undefined ? DEFAULT_SNACKBAR_OPTIONS.hideTimeout : hideTimeout;
};

export const SnackbarsContainer = () => {
    const [snackbars, setSnackbars] = useState<Array<ISnackbar>>(INITIAL_STATE.snackbars);
    const [snackbarsByPosition, setSnackbarsByPosition] = useState<TSnackbarsByPosition>(
        INITIAL_STATE.snackbarsByPosition,
    );

    const { autoDismissMessages } = useAppSelector(getApplicationPreferences);

    const makeSnackbar = useCallback(
        (snackbar: ISnackbar) => ({
            ...snackbar,
            options: {
                ...snackbar.options,
                id: snackbar.options?.id || getSnackbarId(),
                type: snackbar.options?.type || DEFAULT_SNACKBAR_OPTIONS.type,
                position: snackbar.options?.position || DEFAULT_SNACKBAR_OPTIONS.position,
                hideCloseButton:
                    snackbar.options?.hideCloseButton === undefined
                        ? DEFAULT_SNACKBAR_OPTIONS.hideCloseButton
                        : snackbar.options.hideCloseButton && autoDismissMessages,
                hideTimeout: getHideTimeout(
                    snackbar.options?.hideTimeout as number,
                    autoDismissMessages,
                ),
                pauseTimeoutOnHover:
                    snackbar.options?.pauseTimeoutOnHover === undefined
                        ? DEFAULT_SNACKBAR_OPTIONS.pauseTimeoutOnHover
                        : snackbar.options.pauseTimeoutOnHover,
            },
        }),
        [autoDismissMessages],
    );

    const addSnackbar = useCallback(
        (snackbar: ISnackbar) => {
            const newSnackbar = makeSnackbar(snackbar) as ISnackbar;

            setSnackbars([...snackbars, newSnackbar]);
        },
        [snackbars, makeSnackbar],
    );

    const removeSnackbar = useCallback(
        (snackbarId: string) => {
            setSnackbars(snackbars.filter(_snackbar => _snackbar.options?.id !== snackbarId));
        },
        [snackbars],
    );

    useLayoutEffect(() => {
        const unsubscribeShow = snackbarEventManager.subscribe("show", addSnackbar);
        const unsubscribeHide = snackbarEventManager.subscribe("hide", removeSnackbar);

        return () => {
            unsubscribeShow();
            unsubscribeHide();
        };
    }, [addSnackbar, removeSnackbar]);

    useEffect(() => {
        const _snackbarsByPosition = {} as TSnackbarsByPosition;

        snackbars.forEach((snackbar: ISnackbar) => {
            if (!snackbar.options?.position) return;

            if (!_snackbarsByPosition[snackbar.options?.position]) {
                _snackbarsByPosition[snackbar.options.position] = [snackbar];
            } else {
                _snackbarsByPosition[snackbar.options?.position]?.push(snackbar);
            }
        });

        setSnackbarsByPosition(_snackbarsByPosition);
    }, [snackbars]);

    const renderSnackbars = () =>
        Object.keys(snackbarsByPosition).map(position => {
            if (snackbarsByPosition[position as TSnackbarPosition]?.length) {
                return (
                    <div key={position} className={`${position}-container`}>
                        {snackbarsByPosition[position as TSnackbarPosition]?.map(
                            (snackbar: ISnackbar) => (
                                <SnackbarComponent
                                    key={snackbar.options?.id}
                                    close={() =>
                                        snackbarEventManager.emit("hide", snackbar.options?.id)
                                    }
                                    {...snackbar}
                                />
                            ),
                        )}
                    </div>
                );
            }

            return null;
        });

    return <div className="stl-snackbars-container">{renderSnackbars()}</div>;
};
