import { batch } from "react-redux";
import type { TAppDispatch } from "@app/store";
import type { TGetState } from "@app/store/root.reducer";
import { actions } from "./mapEnhancedLayers.reducer";
import { getMapEnhancedLayersState } from "./mapEnhancedLayers.selectors";

export const { setHoveredLegendSwatch } = actions;

export const setHoveredEnhancedLayerZone =
    (zone: any, zoneIdProperty: string) => (dispatch: TAppDispatch, getState: TGetState) => {
        // @ts-ignore
        const { hoveredEnhancedLayerZone, selectedEnhancedLayerZone } = getMapEnhancedLayersState(
            getState(),
        );

        if (
            zone?.[zoneIdProperty] === hoveredEnhancedLayerZone?.[zoneIdProperty] ||
            selectedEnhancedLayerZone
        ) {
            return null;
        }

        return dispatch(actions.setHoveredEnhancedLayerZone(zone));
    };

export const setSelectedEnhancedLayerZone =
    (zone: any, zoneIdProperty: string) => (dispatch: TAppDispatch, getState: TGetState) => {
        // @ts-ignore
        const { selectedEnhancedLayerZone } = getMapEnhancedLayersState(getState());

        if (zone && zone[zoneIdProperty] === selectedEnhancedLayerZone?.[zoneIdProperty])
            return null;

        return batch(() => {
            dispatch(actions.setSelectedEnhancedLayerZone(zone));
            dispatch(actions.setHoveredEnhancedLayerZone(null));
        });
    };

export const setSelectedVizEnhancedLayer =
    (persistedEnhancedDataLayer: any) => (dispatch: TAppDispatch, getState: TGetState) => {
        return batch(() => {
            dispatch(actions.setSelectedVizEnhancedLayer(persistedEnhancedDataLayer));
        });
    };
