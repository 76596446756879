import {
    VIZ_ZONE_TYPES,
    VIZ_MAP_LAYERS,
    BASE_VIZ_DEFAULTS,
} from "@app/viz3/base/state/baseViz.constants";

export const ODMF_ZONE_TYPES_LIST = [
    VIZ_ZONE_TYPES.ORIGIN,
    VIZ_ZONE_TYPES.DESTINATION,
    VIZ_ZONE_TYPES.MIDDLE_FILTER,
];
export type TODMFZoneType = typeof ODMF_ZONE_TYPES_LIST[number];
export type TODMFZoneTypesList = typeof ODMF_ZONE_TYPES_LIST;

export const DEFAULT_ODMF_LAYERS = {
    ORIGIN: {
        code: "origin",
        label: "Origins",
        hasConfiguration: true,
        layerType: "map_layer_type_oz",
    },
    MIDDLE_FILTER: {
        code: "middle_filter",
        label: "Middle Filters",
        hasConfiguration: true,
        layerType: "map_layer_type_mfz",
    },
    DESTINATION: {
        code: "destination",
        label: "Destinations",
        hasConfiguration: true,
        layerType: "map_layer_type_dz",
    },
} as const;
export const DEFAULT_ODMF_LAYERS_LIST = Object.values(DEFAULT_ODMF_LAYERS);

export type TODMFDefaultLayerType = keyof typeof DEFAULT_ODMF_LAYERS;
export type TODMFDefaultLayer = typeof DEFAULT_ODMF_LAYERS[keyof typeof DEFAULT_ODMF_LAYERS];

export const ODMF_LAYERS = {
    ...DEFAULT_ODMF_LAYERS,
    ...VIZ_MAP_LAYERS,
} as const;

export type TODMFLayerType = keyof typeof ODMF_LAYERS;
export type TODMFLayer = typeof ODMF_LAYERS[keyof typeof ODMF_LAYERS];

export const TRAFFIC_BEHAVIORS = {
    ORIGIN_DISTRIBUTION: {
        label: "Origins",
        code: "origin",
        zoneType: VIZ_ZONE_TYPES.ORIGIN,
    },
    MIDDLE_FILTER_DISTRIBUTION: {
        label: "Middle Filters",
        code: "middle_filter",
        zoneType: VIZ_ZONE_TYPES.MIDDLE_FILTER,
    },
    DESTINATION_DISTRIBUTION: {
        label: "Destinations",
        code: "destination",
        zoneType: VIZ_ZONE_TYPES.DESTINATION,
    },
} as const;

export const TRAFFIC_BEHAVIORS_LIST = Object.values(TRAFFIC_BEHAVIORS);

export type TTrafficBehaviorType = keyof typeof TRAFFIC_BEHAVIORS;
export type TTrafficBehavior = typeof TRAFFIC_BEHAVIORS[keyof typeof TRAFFIC_BEHAVIORS];

export const DEFAULT_TRAFFIC_BEHAVIOR = TRAFFIC_BEHAVIORS.MIDDLE_FILTER_DISTRIBUTION;

export const DEFAULT_EXCLUDE_ORIGIN_DESTINATION_VALUE = true;

export const DEFAULT_MAP_LAYERS_VISIBILITY = {
    [ODMF_LAYERS.ORIGIN.code]: true,
    [ODMF_LAYERS.DESTINATION.code]: true,
    [ODMF_LAYERS.MIDDLE_FILTER.code]: true,
    [ODMF_LAYERS.BASE.code]: true,
    [ODMF_LAYERS.UNSELECTED.code]: false,
    [ODMF_LAYERS.LABEL.code]: true,
    [ODMF_LAYERS.LEGEND.code]: true,
};

export const OD_TRAFFIC_BEHAVIOR_CODES = [
    TRAFFIC_BEHAVIORS.ORIGIN_DISTRIBUTION.code,
    TRAFFIC_BEHAVIORS.DESTINATION_DISTRIBUTION.code,
];

export const ODMF_VIZ_DEFAULTS = {
    filters: {
        ...BASE_VIZ_DEFAULTS.filters,
    },
    visualization: {
        ...BASE_VIZ_DEFAULTS.visualization,
        trafficBehaviors: [DEFAULT_TRAFFIC_BEHAVIOR],
        excludeOriginDestination: DEFAULT_EXCLUDE_ORIGIN_DESTINATION_VALUE,
        mapLayersVisibility: DEFAULT_MAP_LAYERS_VISIBILITY,
    },
};
