import { useCallback, useEffect, useRef, useState } from "react";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import type { Map } from "mapbox-gl";
import type { FeatureCollection } from "geojson";
import { baseMapService } from "@common/components/baseMap/baseMap.service";
import { EControlPositions } from "@common/components/baseMap/baseMap.types";
import { DirectSelectForSpotCounter } from "./modes/directSelectForSpotCounter";
import { DEFAULT_DRAW_STYLES } from "./drawOptionStyles";
import { DrawTwoPointLineMode } from "./modes/drawTwoPointLineMode";
import { DirectSelectWithoutMidpointsMode } from "./modes/directSelectWithoutMidpointsMode";
import { StaticMode } from "./modes/staticMode";

export enum EDrawMode {
    static = "static",
    simple_select = "simple_select",
    direct_select = "direct_select",
    draw_two_point_line = "draw_two_point_line",
    draw_polygon = "draw_polygon",
    draw_line_string = "draw_line_string",
    draw_point = "draw_point",
    direct_select_without_midpoints = "direct_select_without_midpoints",
    direct_select_for_spot_counter = "direct_select_for_spot_counter",
}

export const MODES = {
    ...MapboxDraw.modes,
    draw_two_point_line: DrawTwoPointLineMode,
    direct_select_for_spot_counter: DirectSelectForSpotCounter,
    direct_select_without_midpoints: DirectSelectWithoutMidpointsMode,
    static: StaticMode as unknown as MapboxDraw.DrawCustomMode,
} as const;

const DRAW_OPTIONS = {
    displayControlsDefault: false,
    styles: DEFAULT_DRAW_STYLES,
    modes: MODES,
    keybindings: false, // To disable native Esc and Backspace handlers. They don't work well with our custom logic.
} as const;

export type TDrawOptions = {
    keybindings?: boolean;
    userProperties?: boolean;
    displayControlsDefault?: boolean;
    modes?: { [modeKey: string]: MapboxDraw.DrawMode | MapboxDraw.DrawCustomMode };
    [key: string]: unknown;
};

export type THandleGeojsonChange = (value: FeatureCollection) => void;

export const useGetDraw = (map: Map | null, options: TDrawOptions = DRAW_OPTIONS) => {
    const [draw, setDraw] = useState<MapboxDraw | null>(null);

    useEffect(() => {
        if (!map) return undefined;

        const _draw = baseMapService.findMapControl(map, MapboxDraw);

        if (_draw) {
            setDraw(_draw);
            return undefined;
        }

        const drawOptions = { ...DRAW_OPTIONS, ...options };
        const drawControl = new MapboxDraw(drawOptions);

        map.addControl(drawControl, EControlPositions.TOP_LEFT);

        setDraw(drawControl);

        return () => {
            map.removeControl(drawControl);
            setDraw(null);
        };
    }, [map, options]);

    return draw;
};

export const useDraw = (
    map: Map | null,
    onGeojsonChange: THandleGeojsonChange,
    options: TDrawOptions = DRAW_OPTIONS,
) => {
    const draw = useGetDraw(map, options);

    const drawRef = useRef<MapboxDraw | null>(null);
    const geojsonChangeRef = useRef<THandleGeojsonChange | null>(null);

    const updateGeometry = useCallback(() => {
        if (!drawRef.current || !geojsonChangeRef.current) return;

        geojsonChangeRef.current(drawRef.current.getAll());
    }, []);

    useEffect(() => {
        drawRef.current = draw;
        geojsonChangeRef.current = onGeojsonChange;
    }, [draw, onGeojsonChange]);

    useEffect(() => {
        if (!map) return undefined;

        map.on("draw.create", updateGeometry);
        map.on("draw.delete", updateGeometry);
        map.on("draw.update", updateGeometry);

        return () => {
            map.off("draw.create", updateGeometry);
            map.off("draw.delete", updateGeometry);
            map.off("draw.update", updateGeometry);
        };
    }, [map, options, updateGeometry]);

    return draw;
};
