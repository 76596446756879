import { HttpService } from "@common/services/server/http.service";
import {
    IGetPreferencesResponse,
    ISavePreferencesResponse,
    IUpdatePreferencesResponse,
    IUserPreferencesData,
} from "@common/services/server/userPreferencesApi.types";

export const UserPreferencesApiService = {
    getPreferences() {
        return HttpService.get<IGetPreferencesResponse>("/user/preferences");
    },
    updatePreferences(preferences: IUserPreferencesData) {
        return HttpService.put<IUpdatePreferencesResponse>("/user/preferences", preferences);
    },
    savePreferences(preferences: IUserPreferencesData) {
        return HttpService.post<ISavePreferencesResponse>("/user/preferences", preferences);
    },
};
