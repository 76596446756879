import { useState, useEffect, useCallback, useMemo } from "react";
import { Map } from "mapbox-gl";
// @ts-ignore  Could not find a declaration file for module '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw'.
import MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw";
import { LineString, Polygon } from "geojson";
import {
    getArea,
    getDistance,
} from "@common/components/baseMap/customControls/measurementTool/measurementTool.helpers";
import { useGetDraw } from "@common/components/baseMap";
import { DRAW_STYLES } from "@common/components/baseMap/hooks/useDraw/drawOptionStyles";
import {
    MEASUREMENT_MODES,
    TAreaMeasurementUnits,
    TDistanceMeasurementUnits,
    TMeasurementTypes,
} from "../constants";

const MIN_VALUE = 0.01;

const INITIAL_STATE = {
    value: 0,
    isDrawn: false,
};

const DRAW_OPTIONS = {
    displayControlsDefault: false,
    styles: DRAW_STYLES,
    userProperties: true,
};

type TProps = {
    map: Map;
    isMeasurementActive: boolean;
    measurementMetric: TAreaMeasurementUnits | TDistanceMeasurementUnits | null;
    measurementMode: TMeasurementTypes["id"];
};

export const useDrawAndTrack = ({
    map,
    isMeasurementActive,
    measurementMetric,
    measurementMode,
}: TProps) => {
    const [value, setValue] = useState<number>(INITIAL_STATE.value);
    const [isDrawn, setIsDrawn] = useState<boolean>(INITIAL_STATE.isDrawn);

    const draw = useGetDraw(map, DRAW_OPTIONS);

    const trackSize = useCallback((): void => {
        if (!draw || !draw.getAll().features[0]) return;

        let _value;

        if (measurementMode === MEASUREMENT_MODES.area.id) {
            _value = getArea((draw.getAll().features[0].geometry as Polygon)?.coordinates);
        } else {
            _value = getDistance((draw.getAll().features[0].geometry as LineString)?.coordinates);
        }

        setValue(_value);
    }, [draw, measurementMode]);

    const computedValue = useMemo(() => {
        if (!value || !measurementMetric) return "";

        const fixedValue =
            MEASUREMENT_MODES[measurementMode].options[measurementMetric.id].handler(value);

        if (fixedValue < MIN_VALUE) return `<${MIN_VALUE} ${measurementMetric.label}`;

        return `${fixedValue.toFixed(measurementMetric.decimalPrecision)} ${
            measurementMetric.label
        }`;
    }, [value, measurementMode, measurementMetric]);

    useEffect(() => {
        if (!draw || !map) return undefined;
        if (!isMeasurementActive) {
            draw.deleteAll();
            draw.changeMode("static");
            map.getCanvas().style.cursor = "grab";
            return undefined;
        }

        if (measurementMode === MEASUREMENT_MODES.area.id) {
            draw.changeMode(MEASUREMENT_MODES.area.drawMode);
        } else {
            draw.changeMode(MEASUREMENT_MODES.distance.drawMode);
        }

        map.getCanvas().style.cursor = "crosshair";
        map.on("draw.render", trackSize);

        return () => {
            map.off("draw.render", trackSize);
        };
    }, [isMeasurementActive, measurementMode, draw, map, trackSize]);

    const onDrawChange = useCallback(
        (e: MapboxDraw.DrawModeChageEvent) => {
            if (!map || !draw || !isMeasurementActive) return;

            map.getCanvas().style.cursor = "grab";
            const featureId = draw.getAll().features?.[0]?.id as string;

            if (!featureId && measurementMode === MEASUREMENT_MODES.area.id) {
                draw.changeMode(MEASUREMENT_MODES.area.drawMode);
                map.getCanvas().style.cursor = "crosshair";
            }

            if (!isDrawn && e.mode === "simple_select" && featureId) {
                draw.changeMode("direct_select", { featureId });
            }

            setIsDrawn(true);
        },
        [map, draw, isDrawn, isMeasurementActive, measurementMode],
    );

    useEffect(() => {
        if (!map) return undefined;
        map.on("draw.modechange", onDrawChange);

        return () => {
            map.off("draw.modechange", onDrawChange);
        };
    }, [map, onDrawChange]);

    const reset = () => {
        setValue(INITIAL_STATE.value);
        setIsDrawn(INITIAL_STATE.isDrawn);
    };

    return {
        draw,
        value: computedValue,
        isDrawn,
        reset,
    };
};
