import { AuthLayout } from "@app/auth/layout/authLayout";
import { StlStatus403 } from "./status403";

export const StlStatus403Page = () => {
    return (
        <AuthLayout>
            <StlStatus403 />
        </AuthLayout>
    );
};
