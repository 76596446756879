import type { CellInfo, Filter } from "react-table-v6";
import type { ITagAPI } from "@common/services/server/tagsApi.types";
import { ANY_FILTER_OPTION } from "@common/constants/filters.constants";
import type { ITagOption } from "./tags.types";
import { UNASSIGNED_ANALYSES_TAG_ID, UNASSIGNED_TAG_OPTION } from "./tags.constants";

export interface ITagsFilter {
    id: "tags";
    value: Array<ITagOption | typeof UNASSIGNED_TAG_OPTION> | typeof ANY_FILTER_OPTION.value;
}

export const tagsToOptions = (tagsList: ITagAPI[]): ITagOption[] =>
    tagsList.map(tag => ({ label: tag.tag_name, value: tag.tag_id }));

export const tagsToString = (tagsList: ITagAPI[]) => tagsList.map(tag => tag.tag_name).join(", ");

export const tagIdsToTags = (tagsList: ITagAPI[], tagIds: number[]) =>
    tagsList.filter(tag => tagIds.includes(tag.tag_id));

export const tagsFilterMethod = (filter: Filter, row: CellInfo["row"], key = "tag_id") => {
    if (filter.value === ANY_FILTER_OPTION.value) return true;

    return filter.value?.every((tag: ITagOption | typeof UNASSIGNED_TAG_OPTION) => {
        if (tag.value === UNASSIGNED_ANALYSES_TAG_ID) {
            return !row._original[key].length;
        }

        return row._original[key].includes(tag.value);
    });
};

export const tagsSortMethod = (a: string, b: string) => {
    if (!a.length || !b.length) return a.length - b.length;

    return a.localeCompare(b, "en", { sensitivity: "base" });
};

export const getAvailableAnalysisTagsOptions = (tags: ITagAPI[] = []) => [
    UNASSIGNED_TAG_OPTION,
    ...tagsToOptions(tags),
];
