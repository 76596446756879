import type { IMetricsPackage, IModuleType } from "@common/services/server/projectFolderApi.types";
import { TOrg } from "@app/bigBang/admin/orgs/orgs.types";
import { INITIAL_ORG_STATE_OBJ } from "@app/bigBang/admin/orgs/manageOrgModal/manageOrg/manageOrg.constants";

export interface IOrgsState {
    selectedOrg: TOrg;
    metricsPackages: Array<IMetricsPackage>;
    moduleTypes: Array<IModuleType>;
}

export const ORGS_INITIAL_STATE = {
    selectedOrg: INITIAL_ORG_STATE_OBJ,
    metricsPackages: [],
    moduleTypes: [],
} as IOrgsState;
