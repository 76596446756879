import { EMapStyleIds, TControlConfig } from "@common/components/baseMap/baseMap.types";
import { MutableRefObject, useEffect, useMemo, useState } from "react";
import { MapStyleControl } from "@common/components/baseMap/customControls";
import { BaseMapConfigurationModal } from "@common/components/baseMap";
import type { Map } from "mapbox-gl";

const INITIAL_STATE = {
    showBaseLayerModal: false,
};

export type TProps = {
    map: Map | null;
    enabled: boolean;
    position: TControlConfig["position"];
    style: EMapStyleIds;
    onStyleChange: (style: EMapStyleIds) => void;
    mapContainerRef: MutableRefObject<HTMLDivElement | null>;
};

export const useMapStyleControl = ({
    map,
    enabled,
    style,
    onStyleChange,
    position,
    mapContainerRef,
}: TProps) => {
    const [showBaseLayerModal, setShowBaseLayerModal] = useState<boolean>(
        INITIAL_STATE.showBaseLayerModal,
    );

    const baseLayerModalContainer = useMemo(() => {
        if (!showBaseLayerModal || !document.fullscreenElement) return undefined;

        return mapContainerRef.current;
    }, [showBaseLayerModal, mapContainerRef]);

    useEffect(() => {
        if (!map || !enabled) return;

        const mapStyleControl = new MapStyleControl({
            openMapStyleDialog: () => setShowBaseLayerModal(true),
        });
        map.addControl(mapStyleControl, position);
    }, [map, enabled, position]);

    const mountBaseMapConfigurationModal = () => {
        return (
            <BaseMapConfigurationModal
                show={showBaseLayerModal}
                onHide={() => setShowBaseLayerModal(false)}
                title="Base Map Style"
                style={style}
                container={baseLayerModalContainer}
                onStyleChange={onStyleChange}
            />
        );
    };

    return mountBaseMapConfigurationModal;
};
