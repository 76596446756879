import { BASE_VIZ_ACTIONS } from "@app/viz3/base/state/baseViz.actionTypes";
import { VIZ_CODES } from "@app/viz3/base/visualizationsConfiguration";
import { merge } from "lodash-es";

import { TMC_VIZ_ACTIONS } from "./tmcViz.actionTypes";
import { TMC_VIZ_INITIAL_STATE } from "./tmcViz.state";

export const tmcVizReducer = (state = TMC_VIZ_INITIAL_STATE, { data, type }) => {
    switch (type) {
        case BASE_VIZ_ACTIONS.RESET_VIZ_STATE: {
            return TMC_VIZ_INITIAL_STATE;
        }

        case BASE_VIZ_ACTIONS.SET_ANALYSIS_COMMON_CONFIG: {
            let newLayerConfig;
            if (data.vizCode === VIZ_CODES.TMC && data.layerConfigurations) {
                newLayerConfig = merge(
                    {},
                    { ...TMC_VIZ_INITIAL_STATE.map.layerConfigurations },
                    { ...data.layerConfigurations },
                );
            } else {
                newLayerConfig = TMC_VIZ_INITIAL_STATE.map.layerConfigurations;
            }

            return {
                ...state,
                map: {
                    ...state.map,
                    layerConfigurations: {
                        ...state.map.layerConfigurations,
                        ...newLayerConfig,
                    },
                },
            };
        }

        case TMC_VIZ_ACTIONS.SET_HOVERED_ARROW: {
            const arrow = data.arrow?.gate_role
                ? {
                      gate_role: data.arrow.gate_role,
                      road: data.arrow.road,
                      zone_id: data.arrow.arrow_id,
                      type: data.arrow.feature_type,
                  }
                : null;

            return {
                ...state,
                map: {
                    ...state.map,
                    hoveredArrow: arrow,
                },
            };
        }

        case TMC_VIZ_ACTIONS.SET_ZONE_LAYERS: {
            return {
                ...state,
                zoneLayers: data,
            };
        }

        case TMC_VIZ_ACTIONS.SET_VIZ_STATE: {
            return {
                ...state,
                ...data.state,
            };
        }

        case TMC_VIZ_ACTIONS.SET_DIAGRAM_DATA: {
            return {
                ...state,
                diagramData: {
                    ...state.diagramData,
                    ...data,
                },
            };
        }

        case TMC_VIZ_ACTIONS.SET_SELECTED_INTERSECTION: {
            return {
                ...state,
                selectedIntersectionZoneId: data,
            };
        }

        case TMC_VIZ_ACTIONS.SET_INBOUND_OUTBOUND_MODE: {
            return {
                ...state,
                inboundOutboundMode: data,
            };
        }

        default:
            return state;
    }
};
