import { Tooltip, Fade } from "@material-ui/core";
import type { TooltipProps } from "@material-ui/core/Tooltip/Tooltip";
import "./tooltip.less";

export const StlTooltip = ({
    children,
    arrow = false,
    className,
    ...restProps
}: TooltipProps): JSX.Element => {
    const _className = className ? `stl-tooltip ${className}` : "stl-tooltip";

    return (
        <Tooltip
            arrow={arrow}
            classes={{ popper: _className }}
            TransitionComponent={Fade}
            {...restProps}
        >
            {children}
        </Tooltip>
    );
};
