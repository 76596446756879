import { BASE_VIZ_DEFAULTS, VIZ_MAP_LAYERS } from "@app/viz3/base/state/baseViz.constants";
import { DEFAULT_LIGHTNING_FILTERS } from "@app/viz3/baseLightningViz/state/baseLightningViz.constants";
import { ZONE_SELECTION_TOOLS } from "@common/components/baseMap/customControls/zoneSelectionTools/zoneSelectionTools.constants";
import { IATVizState } from "@app/viz3/atVisualization/state/atViz.state";
import { TATSelectedFilters } from "@app/viz3/atVisualization/state/atViz.types";

export const DEFAULT_MAP_LAYERS_VISIBILITY = {
    [VIZ_MAP_LAYERS.LABEL.code]: true,
    [VIZ_MAP_LAYERS.LEGEND.code]: true,
};

export const METRIC_OPTIONS = {
    VOLUME: {
        code: "VOLUME",
        value: "volume",
        label: "Volume",
    },
    VOLUME_PERCENTAGE_CHANGE: {
        code: "VOLUME_PERCENTAGE_CHANGE",
        value: "percentage_change",
        label: "Volume (Percentage Change)",
    },
    VOLUME_NORMALIZED_BY_POPULATION: {
        code: "VOLUME_NORMALIZED_BY_POPULATION",
        value: "pop_normalized_volume",
        label: "Volume Per Person",
    },
    VOLUME_NORMALIZED_BY_AREA: {
        code: "VOLUME_NORMALIZED_BY_AREA",
        value: "area_normalized_volume",
        label: "Volume Per Sq. Mi",
    },
    MODE_SHARE: {
        code: "MODE_SHARE",
        value: "mode_share",
        label: "Mode Share",
    },
};

export const METRIC_OPTIONS_LIST = Object.values(METRIC_OPTIONS);

export type TMetricOptionsKeys = keyof typeof METRIC_OPTIONS;
export type TMetricOption = typeof METRIC_OPTIONS[TMetricOptionsKeys];

export const CHART_INFO_TITLE_LABELS: Record<
    TMetricOptionsKeys,
    Record<
        string,
        {
            title: string;
            description: string;
        }
    >
> = {
    VOLUME: {
        TV: {
            title: "[Mode] Activity",
            description: "Regionwide Average Annual Daily Volume",
        },
        AV: {
            title: "Average [Mode] Activity",
            description: "Average Annual Daily Volume Per [Geography]",
        },
        AT: {
            title: "[Mode] Activity Trend",
            description: "Regionwide % Volume Change ([Last Year] to [Recent Year])",
        },
    },
    VOLUME_PERCENTAGE_CHANGE: {
        TV: {
            title: "[Mode] Activity Trend",
            description: "Regionwide % Volume Change ([Last Year] to [Recent Year])",
        },
        AV: {
            title: "Average [Mode] Activity Trend",
            description: "Average % Volume Change ([Last Year] to [Recent Year]) Per [Geography]",
        },
        AT: {
            title: "[Mode] Activity Trend",
            description: "",
        },
    },
    VOLUME_NORMALIZED_BY_POPULATION: {
        TV: {
            title: "[Mode] Activity",
            description: "Regionwide Average Annual Daily Volume Per Person",
        },
        AV: {
            title: "Average [Mode] Activity",
            description: "Average Annual Daily Volume Per Person Per [Geography]",
        },
        AT: {
            title: "[Mode] Activity Trend",
            description: "Regionwide % Volume Per Person Change ([Last Year] to [Recent Year])",
        },
    },
    VOLUME_NORMALIZED_BY_AREA: {
        TV: {
            title: "[Mode] Activity",
            description: "Regionwide Average Annual Daily Volume Per Sq. Mi",
        },
        AV: {
            title: "Average [Mode] Activity",
            description: "Average Annual Daily Volume Per Sq. Mi  Per [Geography]",
        },
        AT: {
            title: "[Mode] Activity Trend",
            description: "Regionwide % Volume Per Sq. Mi Change ([Last Year] to [Recent Year])",
        },
    },
    MODE_SHARE: {
        TV: {
            title: "[Mode] Mode Share",
            description: "Regionwide Average Annual Daily Mode Share",
        },
        AV: {
            title: "Average [Mode] Mode Share",
            description: "Average Annual Daily Mode Share Per [Geography]",
        },
        AT: {
            title: "[Mode] Activity Trend",
            description: "Regionwide % Mode Share Change ([Last Year] to [Recent Year])",
        },
    },
};

export const AT_FILTER_BY_OPTIONS = {
    CENSUS_TRACT: {
        type: "NONE",
        code: "census_tract",
        display: "By Census Tract",
        isExpandable: true,
    },
    COUNTY: {
        type: "ZONE_GROUP_ID",
        code: "county",
        display: "By County",
        isExpandable: true,
    },
    STATE: {
        type: "NONE",
        code: "state",
        display: "By State",
        isExpandable: true,
    },
} as const;

export const AT_FILTER_BY_OPTIONS_LIST = Object.values(AT_FILTER_BY_OPTIONS);

export const TRAVEL_MODE_OPTIONS = {
    BIKE: {
        code: "BIKE",
        value: "Bicycle",
        label: "Bike",
    },
    PEDESTRIAN: {
        code: "PEDESTRIAN",
        value: "Pedestrian",
        label: "Pedestrian",
    },
    VEHICLE: {
        code: "VEHICLE",
        value: "All_Vehicles",
        label: "Vehicle",
    },
};
export const TRAVEL_MODE_OPTIONS_LIST = Object.values(TRAVEL_MODE_OPTIONS);

export type TTravelModeOptionsKeys = keyof typeof TRAVEL_MODE_OPTIONS;
export type TTravelModeOption = typeof TRAVEL_MODE_OPTIONS[TTravelModeOptionsKeys];

export const CENSUS_TRACTS_FILTER_OPTIONS = {
    ALL: { value: "all", label: "All Census Tracts" },
    CUSTOM_SELECTION: { value: "customSelection", label: "Custom Selection" },
};

export const CENSUS_TRACTS_FILTER_OPTIONS_LIST = Object.values(CENSUS_TRACTS_FILTER_OPTIONS);

export type TCensusTractsFilterOptionsKeys = keyof typeof TRAVEL_MODE_OPTIONS;
export type TCensusTractsFilterOption = typeof TRAVEL_MODE_OPTIONS[TTravelModeOptionsKeys];

const AT_VIZ_SPECIFIC_FILTERS = {
    selectedYears: [] as Array<number>,
    selectedMetricOptionCode: METRIC_OPTIONS.VOLUME.code as TMetricOptionsKeys,
    selectedTravelModeCode: TRAVEL_MODE_OPTIONS.PEDESTRIAN.code as TTravelModeOptionsKeys,
    selectedCensusTractsFilter: CENSUS_TRACTS_FILTER_OPTIONS.ALL
        .value as TCensusTractsFilterOption["value"],
    selectedSegmentFilters: {
        code: AT_FILTER_BY_OPTIONS.CENSUS_TRACT.code,
        values: [],
    } as TATSelectedFilters,
};

export type TATSpecificFilters = keyof typeof AT_VIZ_SPECIFIC_FILTERS;
export type TATVizSpecificFilterCodes = typeof AT_VIZ_SPECIFIC_FILTERS[TATSpecificFilters];

export const DEFAULT_CUSTOM_SELECTION_CONFIG_STATE: IATVizState["customSelectionConfigurations"] =
    {
        map: {
            mapError: null,
            hoveredZone: null,
            selectedToolId: ZONE_SELECTION_TOOLS.CURSOR.id,
        },
        selectedGeographies: [],
        isCustomSelectionMapOpened: false,
    };

export const AT_VIZ_DEFAULTS = {
    filters: {
        ...BASE_VIZ_DEFAULTS.filters,
        ...DEFAULT_LIGHTNING_FILTERS,
        ...AT_VIZ_SPECIFIC_FILTERS,
    },
    visualization: {
        ...BASE_VIZ_DEFAULTS.visualization,
        mapLayersVisibility: DEFAULT_MAP_LAYERS_VISIBILITY,
    },
};

export const AT_MIN_INTERACTIVE_ZOOM = 5;
