import { fetchCurrentUser } from "@app/store/currentUser/currentUser.actions";
import { getIsUserAuthenticated } from "@app/store/currentUser/currentUser.helpers";
import { getIsSandbox } from "@app/store/currentUser/currentUser.selector";
import { StlLoader, StlNotification } from "@common/components";
import { useDidMount } from "@common/hooks/useDidMount";
import { usePreferenceColorScheme } from "@common/hooks/usePreferenceColorScheme";
import { initializeHTTPInterceptors } from "@common/services/server/http.interceptors";
import { HttpService } from "@common/services/server/http.service";
import axios from "axios";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const DEFAULT_LOCALE = "en-us";

const loadResources = () => {
    return axios.get("/messages/locales/en-us.json").then(response => {
        // TODO - compute the lang from browser or cookie; use locale-specific resource
        return response[DEFAULT_LOCALE];
    });
};

const INITIAL_STATE = {
    isLoading: true,
};

export const AppInitializer = ({ children, onInit }) => {
    const [isLoading, setIsLoading] = useState(INITIAL_STATE.isLoading);
    const isSandbox = useSelector(getIsSandbox);

    const theme = usePreferenceColorScheme();

    const dispatch = useDispatch();

    useEffect(() => {
        const themeClassName = `app-theme-${theme}`;

        document.body.classList.add(themeClassName);

        return () => document.body.classList.remove(themeClassName);
    }, [theme]);

    useDidMount(() => {
        initializeHTTPInterceptors();

        const initFlow = [
            loadResources().catch(() =>
                StlNotification.error(
                    "An error occurred while loading translations, falling back to default language.",
                ),
            ),
        ];

        if (getIsUserAuthenticated()) {
            initFlow.push(dispatch(fetchCurrentUser()).catch(HttpService.silentError));
        }

        Promise.allSettled(initFlow).then(results => {
            const initValue = {
                locale: DEFAULT_LOCALE,
                messages: results[0].value,
            };

            // Temporary fix to fix React Intl errors for the service
            window.locale = initValue.locale;
            window.messages = initValue.messages;

            onInit(initValue);
            setIsLoading(false);
        });
    });

    return (
        <>
            <StlLoader show={isLoading} size="large" />
            {!isLoading && (
                <div className={classNames("app-root", isSandbox && "sandbox-label")}>
                    {children}
                </div>
            )}
        </>
    );
};
