import { KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

interface ITab {
    id: string;
    [key: string]: any;
}

interface IRouteTab extends ITab {
    path: string;
}

const INITIAL_STATE = {
    selectedOption: null,
};

const EMPTY_FUNC = () => {};

export const useMenuFocus = ({
    tabs,
    activeTabId = "",
    onSelect = EMPTY_FUNC,
    isRouteBased = false,
}: {
    tabs: Array<ITab | IRouteTab>;
    activeTabId?: string;
    onSelect?: (value: string) => void;
    isRouteBased?: boolean;
}) => {
    const [selectedOption, setSelectedOption] = useState<number | null>(
        INITIAL_STATE.selectedOption,
    );

    const history = useHistory();
    const location = useLocation();

    const tabRefs = useRef<Array<HTMLElement>>([]);

    const activeOptionIndex = isRouteBased
        ? (tabs as Array<IRouteTab>).findIndex(({ path }) => location.pathname.includes(path))
        : (tabs as Array<ITab>).findIndex(tab => tab.id === activeTabId);

    const setFocus = useCallback((selectedOptionIndex: number) => {
        const tabRef = tabRefs.current?.[selectedOptionIndex];

        if (tabRef) {
            tabRef.focus();
            setSelectedOption(selectedOptionIndex);
        }
    }, []);

    useEffect(() => {
        setFocus(activeOptionIndex);
    }, [activeOptionIndex, setFocus]);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (!tabRefs.current?.length) return;

        const tabsCount = tabRefs.current.length - 1;
        const prevIndex = selectedOption === null ? activeOptionIndex : selectedOption;

        if (event.key === "ArrowRight" || event.key === "ArrowDown") {
            event.preventDefault();
            const newSelectedIndex = prevIndex + 1 > tabsCount ? 0 : prevIndex + 1;
            setFocus(newSelectedIndex);
        } else if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
            event.preventDefault();
            const newSelectedIndex = prevIndex - 1 < 0 ? tabsCount : prevIndex - 1;
            setFocus(newSelectedIndex);
        } else if (event.key === " ") {
            event.preventDefault();
            setFocus(prevIndex);
            setSelectedOption(prevIndex);
            onSelect(tabs[prevIndex].id as string);

            if (isRouteBased) {
                history.push(tabs[prevIndex].path);
            }
        }
    };

    const handleClick = isRouteBased
        ? () => {
              const nextActiveOptionIndex = (tabs as Array<IRouteTab>).findIndex(({ path }) =>
                  history.location.pathname.includes(path),
              );

              setSelectedOption(nextActiveOptionIndex);
          }
        : EMPTY_FUNC;

    return { activeOptionIndex, tabRefs, handleKeyDown, handleClick };
};
