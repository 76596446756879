import { LightboxService } from "@common/components/lightbox/lightbox.service";

const createReloadModal = () => {
    let isModalShown = false;

    return {
        show() {
            if (isModalShown) return;

            isModalShown = true;

            LightboxService.openNotification({
                title: "Page Reloading Needed",
                content:
                    "The web page you are viewing is not the latest version. You must reload the page.",
                onClose() {
                    isModalShown = false;
                },
            });
        },
    };
};

export const ReloadModal = createReloadModal();
