import { TApproach, TTimePeriod } from "./createAnalysisHelp.types";

export const SEARCH_KEY = "search" as const;

export const MIN_QUERY_LENGTH = 3 as const;

// words (articles and prepositions) that should be ignored during search
export const EXCLUDED_WORDS = ["the", "out", "for"];

export const TRAVEL_MODES_OPTIONS = {
    All_Vehicles: {
        value: "All_Vehicles",
        label: "All Vehicles",
    },
    Truck: {
        value: "Truck",
        label: "Truck",
    },
    Bus: {
        value: "Bus",
        label: "Bus",
    },
    Rail: {
        value: "Rail",
        label: "Rail",
    },
    Bicycle: {
        value: "Bicycle",
        label: "Bike",
    },
    Pedestrian: {
        value: "Pedestrian",
        label: "Pedestrian",
    },
};

export const METRICS_OPTIONS = {
    HWL: {
        value: "HWL",
        label: "Home and Work Locations",
    },
    "85th percentile": {
        value: "85th percentile",
        label: "85th percentile",
    },
    "average trip speed": {
        value: "average trip speed",
        label: "Average Trip Speed",
    },
    "trip length": {
        value: "trip length",
        label: "Trip Length",
    },
    "travel time": {
        value: "travel time",
        label: "Travel Time",
    },
    "speed percentile": {
        value: "speed percentile",
        label: "Speed Percentile",
    },
};

export const APPROACHES_OPTIONS: Record<TApproach, { value: TApproach; label: string }> = {
    "Speed and Volume on Roadway": {
        value: "Speed and Volume on Roadway",
        label: "Speeds and Volumes on Roads",
    },
    "Trip Metrics": {
        value: "Trip Metrics",
        label: "Trip Metrics: Start to End",
    },
    Modeshare: {
        value: "Modeshare",
        label: "Mode Share",
    },
    "Traffic Flow": {
        value: "Traffic Flow",
        label: "Traffic Flow",
    },
    "Intersection Studies": {
        value: "Intersection Studies",
        label: "Intersection Studies",
    },
    TMC: {
        value: "TMC",
        label: "TMC",
    },
};

export const TIME_PERIODS_OPTIONS: Record<TTimePeriod, { value: TTimePeriod; label: string }> = {
    Recent: {
        value: "Recent",
        label: "Recent Data",
    },
    "All Years": {
        value: "All Years",
        label: "All Years",
    },
    YoY: {
        value: "YoY",
        label: "Can Compare Year Over Year",
    },
};
