import { useMemo } from "react";
import { components, SelectComponentsConfig } from "react-select";
import classNames from "classnames";
import { StlCheckbox } from "@common/components/checkbox/checkbox";
import { StlButton } from "@common/components/button/button";
import { OPTION_ALL } from "@common/components/select/select.constants";
import { useSelectComponents } from "@common/components/select/useSelectComponents";
import type { GroupTypeBase, OptionTypeBase } from "react-select/src/types";
import type {
    MultiValueProps,
    MultiValueRemoveProps,
} from "react-select/src/components/MultiValue";
import type { OptionProps } from "react-select/src/components/Option";
import type { MenuProps } from "react-select/src/components/Menu";
import type { ValueContainerProps } from "react-select/src/components/containers";
import type { IndicatorProps } from "react-select/src/components/indicators";

const MAX_ITEMS_SHOWN = 2 as const;

type TProps = {
    invalid?: boolean;
    required?: boolean;
    disableApply?: (value: unknown) => boolean;
    [key: string]: unknown;
};

export const useCheckboxedSelectComponents = (
    restProps: TProps,
    customComponents: SelectComponentsConfig<OptionTypeBase, boolean> | undefined,
) => {
    const selectComponents = useSelectComponents(restProps, undefined);
    const menuListId = useMemo(() => Math.random(), []);

    const disableApply = restProps?.disableApply;

    return useMemo(
        () => ({
            ...selectComponents,
            ValueContainer: <
                OptionType extends OptionTypeBase,
                IsMulti extends boolean,
                GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
            >(
                props: ValueContainerProps<OptionType, IsMulti, GroupType>,
            ) => {
                const { children, selectProps } = props;
                const currentValues = props.getValue();

                let toBeRendered = children;
                // Truncate number of displayed selected items only if 'shouldSquashSelectedItems' is provided
                if (
                    selectProps.shouldSquashSelectedItems &&
                    props.isMulti &&
                    currentValues.length
                ) {
                    // @ts-ignore
                    // Expression of type '0' can't be used to index type 'true | ReactChild | ReactFragment | ReactPortal'.
                    // Property 'slice' does not exist on type 'boolean | ReactChild | ReactFragment | ReactPortal'.
                    toBeRendered = [children[0].slice(0, MAX_ITEMS_SHOWN), children[1]];
                }

                return props.isMulti ? (
                    <components.ValueContainer {...props}>
                        {toBeRendered}
                    </components.ValueContainer>
                ) : (
                    <components.ValueContainer {...props} />
                );
            },
            Menu: <
                OptionType extends OptionTypeBase,
                IsMulti extends boolean,
                GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
            >(
                props: MenuProps<OptionType, IsMulti, GroupType>,
            ) => {
                const newProps = {
                    ...props,
                    innerProps: {
                        ...props.innerProps,
                        id: menuListId,
                        role: "listbox",
                        "data-testid": "menu",
                    },
                };

                return props.isMulti ? (
                    <components.Menu {...newProps}>
                        <>
                            {props.children}
                            <div
                                className={classNames("apply-button", props.selectProps.className)}
                            >
                                <StlButton
                                    variant="primary"
                                    onClick={() => props.selectProps.applyChanges()}
                                    disabled={disableApply?.(props?.selectProps?.value)}
                                >
                                    Apply
                                </StlButton>
                            </div>
                        </>
                    </components.Menu>
                ) : (
                    <components.Menu {...newProps} />
                );
            },
            Option: <
                OptionType extends OptionTypeBase,
                IsMulti extends boolean,
                GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
            >(
                props: OptionProps<OptionType, IsMulti, GroupType>,
            ) => {
                const newProps = {
                    ...props,
                    innerProps: {
                        ...props.innerProps,
                        role: "option",
                        "aria-selected": props.isSelected,
                        "data-testid": "option",
                    },
                };

                return props.isMulti ? (
                    <div>
                        <components.Option {...newProps}>
                            <div className="option-container">
                                <StlCheckbox
                                    checked={!!props.isSelected}
                                    onChange={() => {}}
                                    className="option-label"
                                >
                                    {props.label}
                                </StlCheckbox>
                            </div>
                        </components.Option>
                    </div>
                ) : (
                    <components.Option {...newProps} />
                );
            },
            DropdownIndicator: <
                OptionType extends OptionTypeBase,
                IsMulti extends boolean,
                GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
            >(
                props: IndicatorProps<OptionType, IsMulti, GroupType>,
            ) => {
                const innerProps = {
                    ...props.innerProps,
                    "data-testid": "dropdownIndicator",
                };

                return <components.DropdownIndicator {...props} innerProps={innerProps} />;
            },
            MultiValue: <
                OptionType extends OptionTypeBase,
                GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
            >(
                props: MultiValueProps<OptionType, GroupType>,
            ) => {
                let label = `${props.data.label}`;
                const selectedValues = props.selectProps.value;

                if (props.data.value === OPTION_ALL.value) {
                    label = "All";
                }
                // Show '+N' token only if 'shouldSquashSelectedItems' is provided
                if (
                    props.selectProps.shouldSquashSelectedItems &&
                    selectedValues &&
                    props.data.value !== (selectedValues as Array<OptionType>)[0].value &&
                    selectedValues.length > 1
                ) {
                    label = `+${selectedValues.length - 1}`;
                }

                return (
                    <components.MultiValue {...props}>
                        <span>{label}</span>
                    </components.MultiValue>
                );
            },
            MultiValueRemove: <
                OptionType extends OptionTypeBase,
                GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
            >(
                props: MultiValueRemoveProps<OptionType, GroupType>,
            ) => {
                const { shouldSquashSelectedItems, hideItemClearButton } = props.selectProps;
                if (shouldSquashSelectedItems || hideItemClearButton) return null;

                return <components.MultiValueRemove {...props} />;
            },
            ...customComponents,
        }),
        [selectComponents, customComponents, menuListId, disableApply],
    );
};
