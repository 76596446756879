import { COLORS } from "@common/constants/color.constants";

export const WINDSHAFT_LAYER_TYPES = {
    LINE: "all_geometries_line",
    POLYGON: "all_geometries_polygon",
    POINT: "all_geometries_point",
    INTERSECTION: "all_geometries_point_osm_intersection",
    GATE: "all_geometries_gate",
    GATE_POLYGON: "all_geometries_gate_polygon",
};

export const MAP_STYLES = {
    LINE_WIDTH: 6,
    GEOM_Z_INDEX: 2,
    OUTLINE_WIDTH: 5,
    POLYGON_OUTLINE_WIDTH: 3,
    OUTLINE_Z_INDEX: 1,
    SELECTED_LINE_WIDTH: 10,
    HOVER_LINE_WIDTH: 10,
    SELECTED_GEOM_Z_INDEX: 8,
    OPACITY: {
        ACTIVE: 0.8,
        INACTIVE: 0.6,
    },
    ZONE_COLORS: {
        SELECTED: COLORS.YELLOW,
        QUESTIONABLE: COLORS.RED,
        UNSELECTED_LINE: COLORS.LIGHT_GREY,
        UNSELECTED_POINT: COLORS.LIGHT_GREY,
        UNSELECTED_FILL: COLORS.TRANSPARENT,
        HOVER: COLORS.WHITE,
        OUTLINE: COLORS.WHITE,
        INVALID: COLORS.RED,
    },
    CUSTOM_GATE_COLORS: {
        HOVER: COLORS.WHITE,
        OUTLINE: COLORS.WHITE,
        UNSELECTED_FILL: COLORS.BLUE,
        UNSELECTED_LINE: COLORS.BLUE,
        INVALID: COLORS.RED,
    },
};

export const MIN_INTERACTIVE_ZOOM = {
    DEFAULT: 9,
    OSM: 13,
    RAIL: 8,
    COUNTY_US_2020: 7,
    LINE_GATES: 13,
};

export const MAP_ZOOM = {
    MIN: MIN_INTERACTIVE_ZOOM.DEFAULT,
    ZONE_SET_MIN: 1,
    MAX: 25,
};
