import type { IDistributionLayerGroupAPI } from "@common/services/server/layerGroupApi.types";
import type { ILayerConfiguration } from "@app/viz3/viz3.types";
import { MAP_STYLES } from "@app/viz3/atVisualization/components/content/atMap/mapStyles.constants";
import { DEFAULT_CUSTOM_SELECTION_CONFIG_STATE } from "@app/viz3/atVisualization/state/atViz.constants";
import {
    EMapType,
    ICustomSelectionConfigurations,
} from "@app/viz3/atVisualization/state/atViz.types";

export interface IATVizState {
    distributionLayers: IDistributionLayerGroupAPI | null;
    map: {
        type: EMapType;
        baseDotVolume: number;
        currentDotVolume: number;
        zoneIdsToZoom: Array<number | string>;
        layerConfiguration: Omit<ILayerConfiguration, "color" | "opacity">;
    };
    customSelectionConfigurations: ICustomSelectionConfigurations;
}

export const AT_VIZ_INITIAL_STATE: IATVizState = {
    distributionLayers: null,
    map: {
        zoneIdsToZoom: [],
        baseDotVolume: 10,
        currentDotVolume: 10,
        type: EMapType.dotDensity,
        layerConfiguration: {
            colors: {
                high: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HIGH,
                mid: "",
                low: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.LOW,
                hover: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HOVER,
            },
            choroplethOpacity: {
                min: MAP_STYLES.DISTRIBUTION_ZONES.CHOROPLETH_OPACITY.MIN,
                max: MAP_STYLES.DISTRIBUTION_ZONES.CHOROPLETH_OPACITY.MAX,
            },
        },
    },
    customSelectionConfigurations: DEFAULT_CUSTOM_SELECTION_CONFIG_STATE,
};
