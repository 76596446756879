import type { PointLike } from "mapbox-gl";
import { COLORS } from "@common/constants/color.constants";
import { SEGMENT_FILTERS } from "@app/viz3/baseLightningViz/state/baseLightningViz.constants";

export const CREATE_SEGMENTS_GROUP_TYPES = {
    [SEGMENT_FILTERS.CORRIDORS.code]: {
        id: SEGMENT_FILTERS.CORRIDORS.code,
        name: "Corridor Group",
        type: "Corridor",
    },
    [SEGMENT_FILTERS.AREAS.code]: {
        id: SEGMENT_FILTERS.AREAS.code,
        name: "Area of Interest",
        type: "Area",
    },
} as const;

export type TCreateSegmentsGroupType =
    typeof CREATE_SEGMENTS_GROUP_TYPES[keyof typeof CREATE_SEGMENTS_GROUP_TYPES];

export const MAP_ZOOM = {
    MAX: 25,
};

export const MAP_STYLES = {
    LINE_WIDTH: 6,
    COLORS: {
        UNSELECTED: COLORS.LIGHT_GREY,
        SELECTED: COLORS.BLUE,
        HOVER: COLORS.WHITE,
    },
};

//Need to draw region over segments
export const DEFAULT_DRAW_POLYGON_LAYER_ID = "gl-draw-polygon-fill-active.cold";

export const POPUP_OFFSET = {
    top: [0, 30],
    bottom: [0, -10],
    left: [20, 0],
    right: [-10, 0],
} as {
    [key: string]: PointLike;
};
