import { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import Select, { OptionTypeBase, Styles } from "react-select";
import { Components } from "react-select/src/components";
import { COLORS } from "@common/components/select/select";
import type { ActionMeta, MenuPlacement, MenuPosition, ValueType } from "react-select/src/types";
import { useCheckboxedSelectComponents } from "./useCheckboxedSelectComponents";
import { TSelection, updateSelection } from "./checkboxedSelect.helpers";
import { OPTION_ALL } from "../select.constants";
import "./checkboxedSelect.less";

const MAX_MENU_HEIGHT = 180 as const;

const SELECT_COLORS = {
    ...COLORS,
    YELLOW: "#F0CF7F",
    HOVER_COLOR: "#4D4D4D",
} as const;

const defaultStyles = {
    control: (styles, state) => ({
        ...styles,
        backgroundColor: state.isDisabled ? SELECT_COLORS.MID_GREY : SELECT_COLORS.BLACK92,
        border: "none",
        minHeight: "30px",
        maxHeight: "120px",
        minWidth: "140px",
        overflow: "auto",
        borderRadius: 0,
        width: "100%",
        borderBottom: `1px solid ${SELECT_COLORS.WHITE}`,
        boxShadow: "none",
        outline:
            state.isFocused || state.selectProps.invalid
                ? "2px solid rgba(255, 218, 106, 0.6)"
                : 0,
        transition: "none",
        ":hover": {
            borderColor: SELECT_COLORS.WHITE,
        },
    }),
    dropdownIndicator: styles => ({
        ...styles,
        padding: "0 8px",
        color: SELECT_COLORS.YELLOW,
        ":hover": {
            borderColor: SELECT_COLORS.YELLOW,
        },
    }),
    menuPortal: styles => ({
        ...styles,
        zIndex: 99,
    }),
    menu: styles => ({
        ...styles,
        margin: 0,
        background: SELECT_COLORS.BLACK92,
    }),
    input: styles => ({
        ...styles,
        color: SELECT_COLORS.WHITE,
    }),
    valueContainer: styles => ({
        ...styles,
        padding: "2px 6px",
        maxHeight: "30px",
    }),
    singleValue: (styles, state) => ({
        ...styles,
        color: state.isDisabled ? SELECT_COLORS.LIGHT_GREY : SELECT_COLORS.WHITE,
    }),
    multiValueLabel: styles => ({
        ...styles,
        padding: "3px 5px",
    }),
    placeholder: (styles, state) => ({
        ...styles,
        color: state.isDisabled ? SELECT_COLORS.LIGHT_GREY : SELECT_COLORS.WHITE,
    }),
    clearIndicator: styles => ({
        ...styles,
        color: SELECT_COLORS.YELLOW,
    }),
    indicatorSeparator: styles => ({
        ...styles,
        backgroundColor: SELECT_COLORS.YELLOW,
        width: "2px",
    }),
    menuList: styles => ({
        ...styles,
        background: SELECT_COLORS.BLACK92,
        fontSize: "12px",
    }),
    option: (styles, state) => {
        const selected = state.isSelected
            ? {
                  background: SELECT_COLORS.BLACK92,
                  color: SELECT_COLORS.WHITE,
              }
            : {};
        const focused = state.isFocused
            ? {
                  background: SELECT_COLORS.BLACK92,
                  color: SELECT_COLORS.WHITE,
              }
            : {};

        return {
            ...styles,
            padding: "6px",
            color: SELECT_COLORS.WHITE,
            ":hover": {
                color: SELECT_COLORS.WHITE,
                background: SELECT_COLORS.HOVER_COLOR,
            },
            ...selected,
            ...focused,
        };
    },
    multiValue: styles => ({
        ...styles,
        backgroundColor: SELECT_COLORS.YELLOW,
        color: SELECT_COLORS.BLACK100,
        margin: "2px 4px 2px 0",
        maxWidth: "80px",
        fontSize: "11px",
    }),
    multiValueRemove: styles => ({
        ...styles,
        ":hover": {
            color: SELECT_COLORS.WHITE,
        },
    }),
} as Styles<OptionTypeBase, boolean>;

type TOption = {
    [key: string]: any;
};

type TValue = TOption | Array<TOption>;

type TProps = {
    value: TValue | null;
    options: TOption[];
    specificOption?: TOption;
    onChange: (value: TValue | TSelection | null) => void;
    id?: string;
    className?: string;
    placeholder?: string;
    isClearable?: boolean;
    isSearchable?: boolean;
    styles?: object;
    menuPosition?: MenuPosition;
    menuPlacement?: MenuPlacement;
    disabled?: boolean;
    disablePortal?: boolean;
    disableApply?: (value: unknown) => boolean;
    isMulti?: boolean;
    isLoading?: boolean;
    closeMenuOnSelect?: boolean;
    shouldUseAllOption?: boolean;
    hideItemClearButton?: boolean;
    shouldSquashSelectedItems?: boolean;
    customComponents?: Record<string, Components[keyof Components]>;
    getOptionLabel?: (option: TOption) => string;
    getOptionValue?: (option: TOption) => string;
    onClear?: () => void;
    onOpen?: () => void;
    onClose?: () => void;
};

export const StlCheckboxedSelect = ({
    id,
    className,
    styles = {},
    options,
    value,
    menuPosition = "absolute",
    menuPlacement = "auto",
    disabled,
    disablePortal,
    isMulti,
    isClearable = false,
    isSearchable = false,
    closeMenuOnSelect = true,
    shouldSquashSelectedItems = true,
    shouldUseAllOption = true,
    hideItemClearButton = false,
    specificOption,
    customComponents,
    getOptionLabel = option => option.label as string,
    getOptionValue = option => option.value as string,
    onChange = () => {},
    onClear = () => {},
    onOpen = () => {},
    onClose = () => {},
    ...restProps
}: TProps) => {
    const [selection, setSelection] = useState<TSelection | TValue | null>(null);
    const _styles = useMemo(() => ({ ...defaultStyles, ...styles }), [styles]);

    const selectRef = useRef<HTMLElement>(null);
    const wereSpecificAndAllOptionsSelected = useRef({
        specificOption: !!specificOption,
        allOption: !specificOption,
    }); // Specific option is selected by default

    useEffect(() => {
        if (value) setSelection(value);
    }, [value]);

    const components = useCheckboxedSelectComponents({ ...restProps }, customComponents);

    const handleChange = (selected: ValueType<TValue, boolean>, event: ActionMeta<TValue>) => {
        if (!isMulti || !Array.isArray(selected)) {
            return onChange(selected as TOption);
        }

        if (specificOption) {
            if (selected.length === 1) {
                wereSpecificAndAllOptionsSelected.current.specificOption = false;
            }

            const { updatedSelection, prevValues } = updateSelection({
                wasSelected: wereSpecificAndAllOptionsSelected.current,
                selected,
                options,
                specificOption,
                shouldUseAllOption,
            });

            wereSpecificAndAllOptionsSelected.current = {
                ...wereSpecificAndAllOptionsSelected.current,
                ...prevValues,
            };

            return setSelection(updatedSelection);
        }

        if (shouldUseAllOption && selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === OPTION_ALL.value) {
                return setSelection([OPTION_ALL, ...options]);
            }
            let result = [] as Array<TOption>;
            if (selected.length === options.length) {
                if (selected.includes(OPTION_ALL)) {
                    result = selected.filter(option => option.value !== OPTION_ALL.value);
                } else if (event.action === "select-option") {
                    result = [OPTION_ALL, ...options];
                }
                return setSelection(result);
            }
        }

        if (event.action === "clear") {
            onClear();
        } else if (event.action === "remove-value") {
            onChange(selected);
        }

        return setSelection(selected);
    };

    const applyChanges = () => {
        onChange(selection);
        if (selectRef.current) {
            selectRef.current.blur();
            selectRef.current.focus();
        }
    };

    const _options = isMulti && shouldUseAllOption ? [OPTION_ALL, ...options] : options;

    return (
        <Select
            options={_options}
            // @ts-ignore Type 'RefObject<HTMLElement>' is not assignable to
            // type 'LegacyRef<StateManager<TOption, boolean, GroupTypeBase<OptionTypeBase>,
            // Select<TOption, boolean, GroupTypeBase<TOption>>>> | undefined'.
            ref={selectRef}
            components={components}
            className={classNames("stl-checkboxed-select", className)}
            menuPortalTarget={!disablePortal ? document.body : undefined}
            menuPosition={menuPosition}
            menuPlacement={menuPlacement}
            isDisabled={disabled}
            value={selection}
            menuShouldBlockScroll
            captureMenuScroll={false}
            maxMenuHeight={MAX_MENU_HEIGHT}
            styles={_styles}
            inputId={id}
            isMulti={isMulti}
            isClearable={isClearable}
            isSearchable={isSearchable}
            closeMenuOnSelect={closeMenuOnSelect}
            shouldSquashSelectedItems={shouldSquashSelectedItems}
            hideSelectedOptions={false}
            hideItemClearButton={hideItemClearButton}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onChange={handleChange}
            applyChanges={applyChanges}
            onMenuOpen={onOpen}
            onMenuClose={onClose}
            {...restProps}
        />
    );
};

StlCheckboxedSelect.displayName = "StlCheckboxedSelect";
