import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    PIRATE_SHIP_INITIAL_STATE,
    IUiStates,
    IConfigurations,
    IVisualization,
    IDailyTrafficWidget,
    IPirateShipState,
    IPendingExportRequests,
} from "./pirateShip.state";

export const { actions, reducer } = createSlice({
    name: "pirateShip",
    initialState: PIRATE_SHIP_INITIAL_STATE,
    reducers: {
        setUiState: (state, action: PayloadAction<Partial<IUiStates>>) => {
            state.uiStates = {
                ...state.uiStates,
                ...action.payload,
            };
        },
        setConfigurations: (state, action: PayloadAction<Partial<IConfigurations>>) => {
            state.configurations = {
                ...state.configurations,
                ...action.payload,
            };
        },
        resetPirateShipState: () => {
            return PIRATE_SHIP_INITIAL_STATE;
        },
        setVizFilters: (state, action: PayloadAction<Partial<IVisualization["filters"]>>) => {
            state.visualization.filters = {
                ...state.visualization.filters,
                ...action.payload,
            };
        },
        updateWidget: (state, action: PayloadAction<Partial<IDailyTrafficWidget>>) => {
            state.visualization.widgets.dailyTraffic = {
                ...state.visualization.widgets.dailyTraffic,
                ...action.payload,
            };
        },
        setSelectedAnalysis: (
            state,
            action: PayloadAction<IVisualization["selectedAnalysis"]>,
        ) => {
            state.visualization.selectedAnalysis = action.payload;
        },

        setZones: (state, action: PayloadAction<IVisualization["zones"]>) => {
            state.visualization.zones = action.payload;
        },
        setPirateShipState: (state, action: PayloadAction<IPirateShipState>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        setPendingExportRequests: (state, action: PayloadAction<IPendingExportRequests>) => {
            state.pendingExportRequests = action.payload;
        },
        setLowCountData: (state, action: PayloadAction<IVisualization["lowCountData"]>) => {
            state.visualization.lowCountData = action.payload;
        },
    },
});

export const pirateShipReducer = reducer;
