import { batch } from "react-redux";
import { merge } from "lodash-es";
import { setAnalysisTypeDefaults } from "@app/analysis/state/analysisConfiguration.actions";
import { setCalibrationCode } from "@app/analysis/basics/state/basics.actions";
import { validCalibrationCodeSelector } from "@app/analysis/basics/state/basics.selectors";
import { GENERAL_INITIAL_STATE } from "@app/analysis/state/general/general.state";
import { resetReducer as resetChooseZonesReducer } from "@app/analysis/zones/chooseZones/state/chooseZones.actions";
import { GENERAL_ACTIONS } from "./general.actionTypes";

export const resetGeneralReducer =
    (generalSwitchConfig) =>
    (dispatch) => {
        if (generalSwitchConfig) {
            return dispatch({
                type: GENERAL_ACTIONS.UPDATE_STATE,
                data: merge({}, GENERAL_INITIAL_STATE, generalSwitchConfig),
            });
        }
        return dispatch({
            type: GENERAL_ACTIONS.RESET_REDUCER,
        });
    };

export const setGeneralInitialData = generalData => ({
    type: GENERAL_ACTIONS.SET_INITIAL_DATA,
    data: generalData,
});

export const setAnalysisValidation = isInvalid => ({
    type: GENERAL_ACTIONS.SET_ANALYSIS_VALIDATION,
    data: { isInvalid },
});

export const setActiveTab = activeTab => ({
    type: GENERAL_ACTIONS.SET_ACTIVE_TAB,
    data: { activeTab },
});

export const setIs15MinuteBinsActive = is15MinuteBinsModeActive => ({
    type: GENERAL_ACTIONS.SET_IS_15_MINUTE_BINS_MODE_ACTIVE,
    data: { is15MinuteBinsModeActive },
});

export const setScreenMode = screenMode => ({
    type: GENERAL_ACTIONS.SET_SCREEN_MODE,
    data: { screenMode },
});

export const setGeneralUiState = data => ({
    type: GENERAL_ACTIONS.SET_GENERAL_UI_STATE,
    data,
});

export const setAnalysisTypeCode = (analysisTypeCode, config) => (dispatch, getState) => {
    dispatch({
        type: GENERAL_ACTIONS.SET_ANALYSIS_TYPE_CODE,
        data: { analysisTypeCode },
    });

    const newState = getState();
    batch(() => {
        dispatch(setCalibrationCode(validCalibrationCodeSelector(newState)));
        dispatch(setAnalysisTypeDefaults(analysisTypeCode, config));
    });
};

export const showPreviewAnalysis = isOpen => ({
    type: GENERAL_ACTIONS.SHOW_PREVIEW_ANALYSIS,
    data: { isOpen },
});

export const setIsAnalysisTypeChanged = value => ({
    type: GENERAL_ACTIONS.SET_ANALYSIS_TYPE_CHANGED,
    data: { value },
});

export const setIsShowAllAnalysesAllowed = value => ({
    type: GENERAL_ACTIONS.SET_SHOW_ALL_ANALYSES_ALLOWED,
    data: { value },
});

export const showAnalysisSizeReviewModal = sizeReviewData => ({
    type: GENERAL_ACTIONS.SHOW_ANALYSIS_SIZE_REVIEW_MODAL,
    data: { sizeReviewData },
});

export const resetAnalysisSizeReviewData = () => ({
    type: GENERAL_ACTIONS.RESET_ANALYSIS_SIZE_REVIEW_DATA,
});

export const showZoneQuotaModal = uniqueZoneData => ({
    type: GENERAL_ACTIONS.SHOW_ZONE_QUOTA_MODAL,
    data: { uniqueZoneData },
});

export const resetUniqueZoneData = () => ({
    type: GENERAL_ACTIONS.RESET_UNIQUE_ZONE_DATA,
});

export const setZonesMode = zonesMode => dispatch => {
    // INST-17373: Reset both zone modes state to prevent broken correlations between them.
    dispatch(resetChooseZonesReducer());

    return dispatch({
        type: GENERAL_ACTIONS.SET_ZONES_MODE,
        data: { zonesMode },
    });
};

export const saveAnalysisSuccess = isDraft => ({
    type: GENERAL_ACTIONS.SAVE_ANALYSIS_SUCCESS,
    data: { isDraft },
});

export const saveAnalysisReset = () => ({
    type: GENERAL_ACTIONS.SAVE_ANALYSIS_RESET,
});

export const setAnalysisActionError = error => ({
    type: GENERAL_ACTIONS.SET_ANALYSIS_ACTION_ERROR,
    data: { error },
});

export const updateAnalysisVersionId = analysisVersionId => ({
    type: GENERAL_ACTIONS.UPDATE_ANALYSIS_VERSION_ID,
    data: { analysisVersionId },
});

export const setProjectFolder = projectFolder => ({
    type: GENERAL_ACTIONS.SET_PROJECT_FOLDER,
    data: { projectFolder },
});
