import {
    VIZ_ZONE_TYPES,
    VIZ_MAP_LAYERS,
    BASE_VIZ_DEFAULTS,
} from "@app/viz3/base/state/baseViz.constants";

export const OD_ZONE_TYPES = {
    ORIGIN: VIZ_ZONE_TYPES.ORIGIN,
    DESTINATION: VIZ_ZONE_TYPES.DESTINATION,
} as const;
export const OD_ZONE_TYPES_LIST = [VIZ_ZONE_TYPES.ORIGIN, VIZ_ZONE_TYPES.DESTINATION];
export type TODZonesTypeKey = keyof typeof OD_ZONE_TYPES;
export type TODZonesType = typeof OD_ZONE_TYPES[keyof typeof OD_ZONE_TYPES];
export type TODZoneTypesList = typeof OD_ZONE_TYPES_LIST;

export const OD_LAYERS = {
    ORIGIN: {
        code: "origin",
        label: "Origins",
        hasConfiguration: true,
    },
    DESTINATION: {
        code: "destination",
        label: "Destinations",
        hasConfiguration: true,
    },
    ...VIZ_MAP_LAYERS,
} as const;
export type TODLayerType = keyof typeof OD_LAYERS;
export type TODLayer = typeof OD_LAYERS[keyof typeof OD_LAYERS];

export const TRAFFIC_BEHAVIORS = {
    ORIGIN_DISTRIBUTION: {
        label: "Origins",
        code: "origin",
        zoneType: OD_ZONE_TYPES.ORIGIN,
    },
    DESTINATION_DISTRIBUTION: {
        label: "Destinations",
        code: "destination",
        zoneType: OD_ZONE_TYPES.DESTINATION,
    },
} as const;
export const TRAFFIC_BEHAVIORS_LIST = Object.values(TRAFFIC_BEHAVIORS);
export type TTrafficBehaviorType = keyof typeof TRAFFIC_BEHAVIORS;
export type TTrafficBehavior = typeof TRAFFIC_BEHAVIORS[keyof typeof TRAFFIC_BEHAVIORS];

export const DEFAULT_TRAFFIC_BEHAVIOR = TRAFFIC_BEHAVIORS.DESTINATION_DISTRIBUTION;

export const DEFAULT_EXCLUDE_ORIGIN_DESTINATION_VALUE = true;

export const DEFAULT_MAP_LAYERS_VISIBILITY = {
    [OD_LAYERS.ORIGIN.code]: true,
    [OD_LAYERS.DESTINATION.code]: true,
    [OD_LAYERS.BASE.code]: true,
    [OD_LAYERS.UNSELECTED.code]: false,
    [OD_LAYERS.LABEL.code]: true,
    [OD_LAYERS.LEGEND.code]: true,
} as const;

export const OD_VIZ_DEFAULTS = {
    filters: {
        ...BASE_VIZ_DEFAULTS.filters,
    },
    visualization: {
        ...BASE_VIZ_DEFAULTS.visualization,
        trafficBehaviors: [DEFAULT_TRAFFIC_BEHAVIOR],
        excludeOriginDestination: DEFAULT_EXCLUDE_ORIGIN_DESTINATION_VALUE,
        mapLayersVisibility: DEFAULT_MAP_LAYERS_VISIBILITY,
    },
} as const;
