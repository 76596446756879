import React, { forwardRef, InputHTMLAttributes } from "react";
import classNames from "classnames";
import "./input.less";

type TProps = Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "onBlur"> & {
    invalid?: boolean;
    label?: string | JSX.Element;
    "data-testid"?: string | number;
    onBlur?: (value: string) => void;
    onChange?: (value: string) => void;
};

export const StlInput = forwardRef<HTMLInputElement, TProps>(
    (
        {
            label,
            id,
            disabled = false,
            invalid = false,
            type = "text",
            placeholder = "",
            value,
            onChange = () => {},
            onBlur = () => {},
            className,
            required,
            ...restProps
        },
        ref,
    ): JSX.Element => (
        <div className="form-group">
            {!!label && (
                <label className={classNames("stl-label", { required })} htmlFor={id}>
                    {label}
                    {required && <span className="required-label">(required)</span>}
                </label>
            )}
            <input
                disabled={disabled}
                className={classNames("stl-input", className, { invalid: !!invalid })}
                type={type}
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={e => onChange(e.target.value)}
                onBlur={e => onBlur(e.target.value)}
                ref={ref}
                aria-invalid={invalid}
                required={required}
                {...restProps}
            />
        </div>
    ),
);

StlInput.displayName = "StlInput";
