import hash from "object-hash";
import { HttpService } from "@common/services/server/http.service";

export const MetricsCacheService = {
    lastResponse: {},
    requestsMap: new Map(),
    dotsDensityRequestsMap: new Map(),
    /**
     * Makes a request to build a cache with metrics for given filters, which later are used for map
     * layer group and traffic distribution requests
     * @param analysisId
     * @param filters
     * @param {Object} [cacheServiceConfig] - cache request configuration
     * @param {string} cacheServiceConfig.cacheId - id, which is used to cancel request with same id if a new one was executed
     * @returns {Promise<unknown>|V}
     */

    clearRequests(cacheId) {
        this.requestsMap.forEach((request, key) => {
            if (!request.cacheId || request.cacheId === cacheId) {
                request.cancel();
                this.requestsMap.delete(key);
            }
        });
    },

    clearDotsDensityRequests(cacheId) {
        this.dotsDensityRequestsMap.forEach((request, key) => {
            if (!request.cacheId || request.cacheId === cacheId) {
                request.cancel();
                this.dotsDensityRequestsMap.delete(key);
            }
        });
    },

    cacheMetrics(analysisId, filters, cacheServiceConfig = {}) {
        const filtersHash = hash({ analysisId, filters });

        if (this.requestsMap.has(filtersHash)) {
            // Share request among chart/map requests
            return this.requestsMap.get(filtersHash);
        } else {
            // Cancel previous request (shouldn't be more than one per cacheId if specified) to avoid race conditions
            this.clearRequests(cacheServiceConfig.cacheId);

            this.lastResponse = {};

            const source = HttpService.CancelToken.source();
            const request = HttpService.post(`/viz3/cache/${analysisId}`, filters, {
                cancelToken: source.token,
            })
                .then(response => {
                    this.lastResponse = response;
                    return response;
                })
                .finally(() => {
                    this.requestsMap.delete(filtersHash);
                });

            request.cacheId = cacheServiceConfig.cacheId;
            request.cancel = source.cancel;

            this.requestsMap.set(filtersHash, request);

            return request;
        }
    },
    /**
     * Makes a request to build a cache with metrics for given filters, which later are used for map
     * dots density requests
     * @param analysisId
     * @param filters
     * @param {Object} [cacheServiceConfig] - cache request configuration
     * @param {string} cacheServiceConfig.cacheId - id, which is used to cancel request with same id if a new one was executed
     * @returns {Promise<unknown>|V}
     */
    cacheDotsDensityMetrics(analysisId, filters, cacheServiceConfig = {}) {
        const filtersHash = hash({ analysisId, filters });

        if (this.dotsDensityRequestsMap.has(filtersHash)) {
            return this.dotsDensityRequestsMap.get(filtersHash);
        } else {
            // Cancel previous request (shouldn't be more than one per cacheId if specified) to avoid race conditions
            this.clearDotsDensityRequests(cacheServiceConfig.cacheId);

            const source = HttpService.CancelToken.source();
            const request = HttpService.post(`/viz3/cache/dots/${analysisId}`, filters, {
                cancelToken: source.token,
            }).finally(() => {
                this.dotsDensityRequestsMap.delete(filtersHash);
            });

            request.cacheId = cacheServiceConfig.cacheId;
            request.cancel = source.cancel;

            this.dotsDensityRequestsMap.set(filtersHash, request);

            return request;
        }
    },
    getStatsAndScores(projectId, params = {}) {
        return HttpService.post(`/viz3/cache/${projectId}/stats_and_scores`, params);
    },
    get lastCacheResponse() {
        return this.lastResponse || {};
    },
    set lastCacheResponse(response) {
        this.lastResponse = response;
    },
};
