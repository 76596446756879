import { ANALYSIS_NAME_ERRORS } from "@app/analysis/basics/state/basics.constants";

const isEmptyString = string => !string || string.trim() === "";

export const getAnalysisNameError = (name, maxNameLength = 50) => {
    let analysisNameError = "";

    if (isEmptyString(name)) {
        analysisNameError = ANALYSIS_NAME_ERRORS.BLANK;
    } else if (name.length > maxNameLength) {
        analysisNameError = ANALYSIS_NAME_ERRORS.LENGTH(maxNameLength);
    }

    return analysisNameError;
};
