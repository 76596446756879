import { uniq } from "lodash-es";
import { ZONE_ROLES } from "@common/constants/zoneLibrary.constants";
import { TZonesManagerZone } from "@common/features/zonesManager/zonesManager.types";
import { dayPartsStateToData } from "@app/analysis/timePeriods/state/timePeriods.helpers";
import { DAY_CODES } from "@app/analysis/timePeriods/state/timePeriods.constants";
import { CHOOSE_ZONES_INITIAL_STATE } from "@app/analysis/zones/chooseZones/state/chooseZones.state";
import {
    EZoneType,
    TDayPeriod,
    TIPFValue,
    TIPFValuePayload,
    TZoneData,
    TZoneRoleIPFValues,
} from "./ipfCalibrationSection.types";

export const getDayPeriodName = (dayPeriod: TDayPeriod) =>
    dayPeriod ? `${dayPeriod.name} (${dayPeriod.start.name} - ${dayPeriod.end.name})` : "";

export const getIPFInvalidCountMessage = (maxIpfExpectedCount: number, zoneName: string) =>
    `Please enter positive numbers less than or equal to ${maxIpfExpectedCount.toLocaleString()} for ${zoneName} zone.`;

export const getIPFInvalidTimePeriodCountsMessage = (
    maxIpfTotalPctDiff: number,
    periodName: string,
) =>
    `Marginal totals of Origins and Destinations for ${periodName} period(s) must be within ${
        maxIpfTotalPctDiff * 100
    }% of each other.`;

export const getIPFValuesFromAnalysis = ({
    analysisIPFValues,
    dayTypes,
    dayParts,
}: {
    analysisIPFValues: Array<TIPFValuePayload>;
    dayTypes: Array<TDayPeriod>;
    dayParts: Array<TDayPeriod>;
}): {
    dayParts: Array<TDayPeriod>;
    dayTypes: Array<TDayPeriod>;
    ipfValues: TIPFValue;
} => {
    if (!analysisIPFValues) {
        return CHOOSE_ZONES_INITIAL_STATE.ipfCalibrationSettings;
    }

    const ipfSettings = analysisIPFValues.reduce(
        (res, ipfValue) => {
            const { day_type_name: dayTypeName, day_part_name: dayPartName } = ipfValue;
            const zoneData = {
                count: ipfValue.count,
                zoneId: ipfValue.zone_id,
                zoneKindId: ipfValue.zone_kind_id,
                zoneName: ipfValue.zone_name,
                isInvalid: false,
            };
            const dayTypeData = res.ipfValues[dayTypeName] || {};

            if (!dayTypeData[dayPartName]) {
                dayTypeData[dayPartName] = { oz: {}, dz: {} };
            }
            if (ipfValue.zone_type === EZoneType.origin) {
                dayTypeData[dayPartName].oz[zoneData.zoneId] = zoneData;
            } else {
                dayTypeData[dayPartName].dz[zoneData.zoneId] = zoneData;
            }

            res.dayTypes.add(dayTypeName);
            res.dayParts.add(dayPartName);

            return {
                ...res,
                ipfValues: {
                    ...res.ipfValues,
                    [dayTypeName]: dayTypeData,
                },
            };
        },
        { dayTypes: new Set(), dayParts: new Set(), ipfValues: {} as TIPFValue },
    );

    const _dayTypes = dayTypes.filter(dayType => ipfSettings.dayTypes.has(dayType.name));
    const _dayParts = dayParts.filter(dayPart => ipfSettings.dayParts.has(dayPart.name));

    return {
        dayTypes: _dayTypes,
        dayParts: _dayParts,
        ipfValues: ipfSettings.ipfValues,
    };
};

/*
 * Get IPF value payload object for a single zone
 */
export const getIPFValuePayloadObject = ({
    dayType,
    dayPart,
    zoneData,
    zoneType,
}: {
    dayType: TDayPeriod;
    dayPart: TDayPeriod;
    zoneData: TZoneData;
    zoneType: EZoneType;
}): TIPFValuePayload => {
    // @ts-ignore
    const serializedDayParts = dayPartsStateToData({ dayParts: [dayPart] }).split("|");

    return {
        count: zoneData.count,
        day_type_name: dayType.name,
        // @ts-ignore
        day_type: `${DAY_CODES[dayType.start.name].code}${DAY_CODES[dayType.end.name].code}`,
        day_part_name: dayPart.name,
        day_part: serializedDayParts[1],
        zone_id: zoneData.zoneId,
        zone_kind_id: zoneData.zoneKindId,
        zone_name: zoneData.zoneName,
        zone_type: zoneType,
    };
};

/*
 * Get full IPF values payload: all zones in all day parts of each day type
 */
export const getIPFCalibrationValuesPayload = (ipfCalibrationSettings: {
    dayParts: Array<TDayPeriod>;
    dayTypes: Array<TDayPeriod>;
    ipfValues: TIPFValue;
}): Array<TIPFValuePayload> => {
    const { dayParts, dayTypes, ipfValues } = ipfCalibrationSettings;

    // Get IPF payload for all zones of all day types
    return Object.keys(ipfValues).reduce((resDayType, dayTypeName) => {
        const dayTypeData = ipfValues[dayTypeName];
        const dayType = dayTypes.find((_dayType: TDayPeriod) => _dayType.name === dayTypeName);

        if (!dayType) {
            return resDayType;
        }

        // Get IPF payload for all zones of all day parts in day type
        const dayPartZones = Object.keys(dayTypeData).reduce((resDayPart, dayPartName) => {
            const dayPartData = dayTypeData[dayPartName];
            const dayPart = dayParts.find((_dayPart: TDayPeriod) => _dayPart.name === dayPartName);

            if (!dayPart) {
                return resDayPart;
            }

            // Get IPF payload for all Origin zones of the day part
            const originZones = Object.keys(dayPartData.oz).map(zoneId => {
                const zoneData = dayPartData.oz[zoneId];

                return getIPFValuePayloadObject({
                    dayType,
                    dayPart,
                    zoneData,
                    zoneType: EZoneType.origin,
                });
            });

            // Get IPF payload for all Destination zones of the day part
            const destinationZones = Object.keys(dayPartData.dz).map(zoneId => {
                const zoneData = dayPartData.dz[zoneId];

                return getIPFValuePayloadObject({
                    dayType,
                    dayPart,
                    zoneData,
                    zoneType: EZoneType.destination,
                });
            });

            return [...resDayPart, ...originZones, ...destinationZones];
        }, [] as Array<TIPFValuePayload>);

        return [...resDayType, ...dayPartZones];
    }, [] as Array<TIPFValuePayload>);
};

/*
 * Validates IPF calibration mandatory data:
 * - at least one Day Type and Day Part must be selected
 * - at least one origin and destination zone must be selected
 * - number of selected zones per role shouldn't exceed `maxIPFZonesPerRole` config param
 * - all selected zones must be pass-through
 * It must be a positive number less than maxIpfExpectedCount.
 */
export const validateIPFCalibrationMandatoryData = ({
    dayTypes,
    dayParts,
    selectedZones,
    maxIPFZonesPerRole,
}: {
    dayTypes: Array<TDayPeriod>;
    dayParts: Array<TDayPeriod>;
    selectedZones: { [key: string]: Array<TZonesManagerZone> };
    maxIPFZonesPerRole: number;
}): Array<string> => {
    const errors = [];

    if (!dayTypes.length || !dayParts.length) {
        errors.push("Please select at least one Day Type and Day Part to continue.");
    }

    [ZONE_ROLES.ORIGINS, ZONE_ROLES.DESTINATIONS].forEach(zoneRole => {
        const roleZones = selectedZones[zoneRole.accessor] || [];
        const isAllPassThroughZones = roleZones.every(zone => zone.is_pass);
        const uniqueZoneNames = uniq(roleZones.map(zone => zone.zone_name));

        if (!roleZones.length) {
            errors.push(`Please select some ${zoneRole.name}.`);
        } else if (roleZones.length > maxIPFZonesPerRole) {
            errors.push(`Please select maximum ${maxIPFZonesPerRole} ${zoneRole.name}.`);
        }
        if (uniqueZoneNames.length !== roleZones.length) {
            errors.push(`All ${zoneRole.name} should have unique names.`);
        }
        if (!isAllPassThroughZones) {
            errors.push(`All ${zoneRole.name} should be pass-through.`);
        }
    });

    return errors;
};

/*
 * Validates IPF count for a single Zone.
 * It must be a positive number less than maxIpfExpectedCount.
 */
export const validateZoneIPFCount = ({
    ipfCount,
    maxIpfExpectedCount,
    zone,
}: {
    ipfCount: string;
    maxIpfExpectedCount: number;
    zone: TZoneData;
}): { isInvalid: boolean; reasons: Array<string> } => {
    const count = parseInt(ipfCount, 10);
    const isInvalid = !/^[1-9][0-9]*$/.test(ipfCount) || count <= 0 || count > maxIpfExpectedCount;

    return {
        isInvalid,
        reasons: isInvalid ? [getIPFInvalidCountMessage(maxIpfExpectedCount, zone.zoneName)] : [],
    };
};

/*
 * Validates IPF count for all Zones.
 * IPF count must be a positive number less than maxIpfExpectedCount.
 */
export const validateIPFCounts = ({
    ipfValues,
    maxIpfExpectedCount,
}: {
    ipfValues: TIPFValue;
    maxIpfExpectedCount: number;
}): { isInvalid: boolean; reasons: Array<string> } => {
    const invalidZoneCounts = Object.keys(ipfValues).reduce((resDayType, dayTypeName: string) => {
        const dayTypeData = ipfValues[dayTypeName];

        const invalidZoneCountForDayType = Object.keys(dayTypeData).reduce(
            (resDayPart, dayPartName: string) => {
                const dayPartData = dayTypeData[dayPartName];
                const dayPartZones = [
                    ...Object.values(dayPartData.oz),
                    ...Object.values(dayPartData.dz),
                ];
                const invalidZoneCountForDayPart = dayPartZones.reduce((resCount, zone) => {
                    const { isInvalid } = validateZoneIPFCount({
                        ipfCount: zone.count,
                        maxIpfExpectedCount,
                        zone,
                    });
                    const invalidCount = isInvalid ? 1 : 0;

                    return resCount + invalidCount;
                }, 0);

                return resDayPart + invalidZoneCountForDayPart;
            },
            0,
        );

        if (invalidZoneCountForDayType) {
            resDayType.push(
                `Missing (${invalidZoneCountForDayType}) IPF calibration values for ${dayTypeName}.`,
            );
        }
        return resDayType;
    }, [] as Array<string>);

    const hasInvalidIPFValue = !!invalidZoneCounts.length;

    return {
        isInvalid: hasInvalidIPFValue,
        reasons: hasInvalidIPFValue ? invalidZoneCounts : [],
    };
};

/*
 * Validates percentage difference between the Origin and
 * Destination Zones counts for a single Day Part.
 * It must be less than maxIpfTotalPctDiff.
 */
export const validateDayPartCounts = ({
    dayPartData,
    maxIpfTotalPctDiff,
    periodName = "",
}: {
    dayPartData: {
        oz: TZoneRoleIPFValues;
        dz: TZoneRoleIPFValues;
    };
    maxIpfTotalPctDiff: number;
    periodName: string;
}): { isInvalid: boolean; reasons: Array<string> } => {
    const originCount = Object.values(dayPartData.oz as TZoneRoleIPFValues).reduce(
        (res, zoneData) => res + parseInt(zoneData.count, 10),
        0,
    );
    const destinationCount = Object.values(dayPartData.dz as TZoneRoleIPFValues).reduce(
        (res, zoneData) => res + parseInt(zoneData.count, 10),
        0,
    );
    const percentDiff =
        Math.abs(originCount - destinationCount) / ((originCount + destinationCount) / 2);
    const isInvalid = percentDiff > maxIpfTotalPctDiff;

    return {
        isInvalid,
        reasons: isInvalid
            ? [getIPFInvalidTimePeriodCountsMessage(maxIpfTotalPctDiff, periodName)]
            : [],
    };
};

/*
 * For each day type/part the percentage difference between the Origin and
 * Destination Zones counts must be less than maxIpfTotalPctDiff.
 */
export const validateTimePeriodCounts = ({
    ipfValues,
    maxIpfTotalPctDiff,
}: {
    ipfValues: TIPFValue;
    maxIpfTotalPctDiff: number;
}): { isInvalid: boolean; reasons: Array<string>; ipfValues: TIPFValue } => {
    const invalidPeriods: Array<string> = [];

    const validatedIPFValues = Object.keys(ipfValues).reduce((resDayType, dayTypeName) => {
        const dayTypeData = ipfValues[dayTypeName];

        const newDayPartData = Object.keys(dayTypeData).reduce((resDayPart, dayPartName) => {
            const dayPartData = dayTypeData[dayPartName];

            const { isInvalid: isInvalidDayPeriodsCounts } = validateDayPartCounts({
                dayPartData,
                maxIpfTotalPctDiff,
                periodName: `${dayTypeName}/${dayPartName}`,
            });

            const originZones = Object.values(dayPartData.oz).reduce(
                (res, zoneData) => ({
                    ...res,
                    [zoneData.zoneId]: {
                        ...zoneData,
                        isInvalid: isInvalidDayPeriodsCounts,
                    },
                }),
                {},
            );

            const destinationZones = Object.values(dayPartData.dz).reduce(
                (res, zoneData) => ({
                    ...res,
                    [zoneData.zoneId]: {
                        ...zoneData,
                        isInvalid: isInvalidDayPeriodsCounts,
                    },
                }),
                {},
            );

            if (isInvalidDayPeriodsCounts) {
                invalidPeriods.push(`${dayTypeName}/${dayPartName}`);
            }

            return {
                ...resDayPart,
                [dayPartName]: {
                    oz: originZones,
                    dz: destinationZones,
                },
            };
        }, {});

        return { ...resDayType, [dayTypeName]: newDayPartData };
    }, {});

    const isInvalid = !!invalidPeriods.length;

    return {
        isInvalid,
        reasons: isInvalid
            ? [getIPFInvalidTimePeriodCountsMessage(maxIpfTotalPctDiff, invalidPeriods.join(", "))]
            : [],
        ipfValues: validatedIPFValues,
    };
};

/*
 * Total validation for IPF calibration section
 * - validate mandatory data
 * - validate IPF counts for all zones
 * - validate percentage difference of origin/destination counts for each Day Type/Day Part pair
 */
export const validateIPFCalibrationValues = ({
    ipfCalibrationSettings,
    ipfThresholds,
    selectedZones,
}: {
    ipfCalibrationSettings: any;
    ipfThresholds: { [key: string]: number };
    selectedZones: { [key: string]: Array<TZonesManagerZone> };
}): { isInvalid: boolean; reasons: Array<string>; ipfValues?: TIPFValue } => {
    const { dayTypes, dayParts, ipfValues } = ipfCalibrationSettings;
    const {
        max_ipf_expected_count: maxIpfExpectedCount,
        max_ipf_total_percent_difference: maxIpfTotalPctDiff,
        max_ipf_zones_per_role: maxIPFZonesPerRole = 15,
    } = ipfThresholds;

    const ipfMandatoryValidationErrors = validateIPFCalibrationMandatoryData({
        dayTypes,
        dayParts,
        selectedZones,
        maxIPFZonesPerRole,
    });
    if (ipfMandatoryValidationErrors.length) {
        return {
            isInvalid: true,
            reasons: ipfMandatoryValidationErrors,
        };
    }

    const ipfCountsValidation = validateIPFCounts({ ipfValues, maxIpfExpectedCount });
    if (ipfCountsValidation.isInvalid) {
        return ipfCountsValidation;
    }

    return validateTimePeriodCounts({ ipfValues, maxIpfTotalPctDiff });
};

/*
 * Update IPF values data object based on selected Day Types, Day Parts and Zones
 */
export const updateIPFCalibrationValues = ({
    dayTypes,
    dayParts,
    zones,
    ipfValues,
}: {
    dayTypes: Array<TDayPeriod>;
    dayParts: Array<TDayPeriod>;
    zones: { [key: string]: Array<TZonesManagerZone> };
    ipfValues: TIPFValue;
}): TIPFValue => {
    const originZones = zones.oz || [];
    const destinationZones = zones.dz || [];

    return dayTypes.reduce((resDayType, dayType) => {
        const dayTypeData = ipfValues[dayType.name] || {};

        // Update all day parts for day type
        const newDayTypeData = dayParts.reduce((resDayPart, dayPart) => {
            const dayPartData = dayTypeData[dayPart.name] || { oz: {}, dz: {} };

            // Update zones and its count for each day part of day type
            const newDayPartData = {
                oz: originZones.reduce((resZones, zone) => {
                    resZones[zone.zone_id] = dayPartData.oz[zone.zone_id] || {
                        count: "",
                        zoneId: zone.zone_id,
                        zoneKindId: zone.zone_kind_id,
                        zoneName: zone.zone_name,
                        isInvalid: false,
                    };

                    return resZones;
                }, {} as TZoneRoleIPFValues),
                dz: destinationZones.reduce((resZones, zone) => {
                    resZones[zone.zone_id] = dayPartData.dz[zone.zone_id] || {
                        count: "",
                        zoneId: zone.zone_id,
                        zoneKindId: zone.zone_kind_id,
                        zoneName: zone.zone_name,
                        isInvalid: false,
                    };

                    return resZones;
                }, {} as TZoneRoleIPFValues),
            };

            return { ...resDayPart, [dayPart.name]: newDayPartData };
        }, {});

        return { ...resDayType, [dayType.name]: newDayTypeData };
    }, {});
};
