import { MultiPolygon, Polygon } from "geojson";
import { EMeasurementUnits } from "@common/features/orgs/orgs.types";
import { TDate } from "@common/components/dateRangeFilter/dateRangeFilter";

export type TGeometry = (MultiPolygon | Polygon) & {
    id?: string | number;
    isDrawn?: boolean;
};

export enum EStudyMode {
    CREATE = "CREATE",
    EDIT = "EDIT",
    VIEW = "VIEW",
}

export enum EStudyTypes {
    Request_for_Proposal = "Request_for_Proposal",
    Business_Development = "Business_Development",
    Marketing_Materials = "Marketing_Materials",
    Pursuit = "Pursuit",
    Active_Project = "Active_Project",
    Marketing_Conf = "Marketing_Conf",
}

export enum ESubscriptionTier {
    Lite = "Lite",
    Essentials = "Essentials",
    BikeOrPed_Essentials = "Bike/Ped Essentials",
    Advanced_Analytics = "Advanced Analytics",
    MultiMode = "Multi-Mode",
    Im_Not_Sure = "I’m Not Sure?",
}

export enum ESubscriptionZoneBand {
    "1-10" = "1-10",
    "11-50" = "11-50",
    "51-100" = "51-100",
    "101-250" = "101-250",
    "251-500" = "251-500",
    "501-750" = "501-750",
    "751-1000" = "751-1000",
    "1001-2000" = "1001-2000",
    "2001-3000" = "2001-3000",
    "3001-5000" = "3001-5000",
    "5001-7500" = "5001-7500",
    "7501-9000" = "7501-9000",
    "9001-10000" = "9001-10000",
    "10001-12000" = "10001-12000",
    Other = "Other",
}

export interface IStudy {
    studyType: EStudyTypes | "";
    studyId: number | null;
    startDate: TDate;
    endDate: TDate;
    nonEditableStartDate: TDate;
    name: string;
    endClient: string;
    bpCode?: string;
    taskNumber?: string;
    chargeCode?: string;
    rfpId: string;
    notes: string;
    buffer?: string;
    status?: string;
    geometry?: TGeometry | null;
    stagedRegionId?: string | null;
    measurementUnit?: EMeasurementUnits | "";
    subscriptionTier?: ESubscriptionTier | null;
    subscriptionZoneBand?: ESubscriptionZoneBand | null;
}

export interface IStudyData {
    bp_code?: string | null;
    charge_code?: string | null;
    org_name: string;
    org_nickname: string;
    ppu_org_id: number | null;
    study_id: number;
    study_name: string;
    study_type: string;
    task_number?: string | null;
}
