import { CURRENT_USER_INITIAL_STATE } from "./currentUser.state";
import { CURRENT_USER_ACTIONS } from "./currentUser.actionTypes";

export const currentUser = (state = CURRENT_USER_INITIAL_STATE, { data, type }) => {
    switch (type) {
        case CURRENT_USER_ACTIONS.SET_CURRENT_USER: {
            return {
                ...state,
                ...data.currentUser,
            };
        }

        case CURRENT_USER_ACTIONS.SET_ORG_BALANCE: {
            return {
                ...state,
                orgBalance: data.orgBalance,
            };
        }

        case CURRENT_USER_ACTIONS.SET_SHOW_HIGHLIGHT: {
            return {
                ...state,
                showHighlight: data.showHighlight,
            };
        }

        case CURRENT_USER_ACTIONS.SET_SSO_ENABLED: {
            return {
                ...state,
                isSSOEnabled: data.isSSOEnabled,
            };
        }

        default: {
            return state;
        }
    }
};
