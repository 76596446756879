import { lazy } from "react";
import { ROUTES } from "@common/constants/routes.constants";

const Viz3Screenshots = lazy(() => import("@app/viz3/screenshots/viz3Screenshots.page"));
const Viz3SharedLink = lazy(() => import("@app/viz3/sharedVisualization/viz3SharedLink.page"));
const Viz3Page = lazy(() => import("@app/viz3/base/viz3.page"));

export const routes = [
    {
        path: `${ROUTES.VIZ3_SHARED_LINK}/:typeCode/:linkId`,
        title: "Viz3D",
        component: Viz3SharedLink,
        id: "viz3.SharedVisualization",
    },
    {
        path: `${ROUTES.VIZ3}/screenshots/:vizStateId`,
        component: Viz3Screenshots,
        title: "Viz3D",
        id: "viz3.Screenshots",
    },
    {
        path: `${ROUTES.VIZ3}/:analysisId`,
        component: Viz3Page,
        title: "Viz3D",
        id: "viz3.Visualization",
    },
    {
        path: `${ROUTES.VIZ3}`,
        component: Viz3Page,
        title: "Viz3D",
        id: "viz3.SelectVisualization",
    },
];
