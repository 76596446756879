import type { ErrorEvent, Map } from "mapbox-gl";
import { useEffect } from "react";
import { debounce } from "lodash-es";

const DEBOUNCE_TIME = 500 as const;

export const useMapState = (
    map: Map | null,
    { setIsLoading }: { setIsLoading: (value: boolean) => void },
) => {
    useEffect(() => {
        if (!map) return undefined;

        const debouncedSetIsLoadingFactory = (leading: boolean, trailing: boolean) =>
            debounce(setIsLoading, DEBOUNCE_TIME, { leading, trailing });

        const debouncedSetIsLoadingLeadingOnly = debouncedSetIsLoadingFactory(true, false);
        const debouncedSetIsLoading = debouncedSetIsLoadingFactory(true, true);

        // To prevent busy/idle firing out of order, debounce busy events without trailing edge.
        const onDataLoading = () => debouncedSetIsLoadingLeadingOnly(true);
        const onIdle = () => debouncedSetIsLoading(false);
        const onError = (err: ErrorEvent) => {
            // @ts-ignore Property 'sourceId' does not exist on type 'ErrorEvent'.
            const { error, sourceId } = err;
            const errorMsg = error?.stack ? error.message : JSON.stringify(error);
            console.error(`Base map error: ${errorMsg}; sourceId: ${sourceId}`);
        };

        map.on("dataloading", onDataLoading);
        map.on("idle", onIdle);
        map.on("error", onError);

        return () => {
            map.off("dataloading", onDataLoading);
            map.off("idle", onIdle);
            map.off("error", onError);
        };
    }, [map, setIsLoading]);
};
