import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ACTIVE_TRANSPORTATION_INITIAL_STATE } from "@app/analysisLightning/activeTransportation/state/activeTransportation.state";
import type {
    IATAvailableCounty,
    IATAvailableState,
    TATSelectedCensusTractsMap,
} from "@app/analysisLightning/activeTransportation/state/activeTransportation.types";

export const { actions, reducer } = createSlice({
    name: "atReducer",
    initialState: ACTIVE_TRANSPORTATION_INITIAL_STATE,
    reducers: {
        setAvailableTimePeriods: (state, action: PayloadAction<Array<number>>) => {
            state.availableTimePeriods = action.payload;
        },
        setAvailableCountiesMap: (
            state,
            action: PayloadAction<{
                [key: number]: IATAvailableCounty;
            }>,
        ) => {
            state.availableCountiesMap = action.payload;
        },
        setAvailableStatesMap: (
            state,
            action: PayloadAction<{
                [key: number]: IATAvailableState;
            }>,
        ) => {
            state.availableStatesMap = action.payload;
        },
        setSelectedStateId: (
            state,
            action: PayloadAction<IATAvailableState["zone_id"] | null>,
        ) => {
            state.selectedStateId = action.payload;
        },
        setSelectedCountyId: (
            state,
            action: PayloadAction<IATAvailableCounty["zone_id"] | null>,
        ) => {
            state.selectedCountyId = action.payload;
        },
        setSelectedCensusTracts: (state, action: PayloadAction<TATSelectedCensusTractsMap>) => {
            state.selectedCensusTracts = action.payload;
        },
        resetAnalysisLightningATState: () => {
            return ACTIVE_TRANSPORTATION_INITIAL_STATE;
        },
    },
});

export const atReducer = reducer;
