import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube } from "@fortawesome/pro-solid-svg-icons";
import {
    ANALYSIS_TYPES_LIST,
    MODES_OF_TRAVEL,
    MODES_OF_TRAVEL_LIST,
} from "@common/constants/analysis.constants";
import { METRICS_PACKAGES_CATEGORIES } from "@app/bigBang/projects/state/projects.constants";
import type { IMetricsPackage } from "@common/services/server/projectFolderApi.types";
import type {
    TAnalysisTypeCategoryFeatureId,
    TModeOfTravelCategoryFeatureId,
} from "@app/bigBang/projects/state/projects.types";

type TProps = {
    metricsPackages: Array<IMetricsPackage>;
};

const PackageItem = ({ packageData }: { packageData: IMetricsPackage }) => {
    const { types, modes } = useMemo(() => {
        const { _types, _modes } = packageData.metrics_package_items.reduce(
            (result, item) => {
                if (item.name1 === METRICS_PACKAGES_CATEGORIES.ANALYSIS_TYPES.id) {
                    const analysisType =
                        METRICS_PACKAGES_CATEGORIES.ANALYSIS_TYPES.types[
                            item.feature_id as TAnalysisTypeCategoryFeatureId
                        ];
                    const typeName = ANALYSIS_TYPES_LIST.find(
                        type => type.id === analysisType?.code,
                    )?.name;
                    if (typeName) {
                        result._types.push(typeName);
                    }
                } else {
                    const travelModeCode =
                        METRICS_PACKAGES_CATEGORIES.MODE_OF_TRAVEL.types[
                            item.feature_id as TModeOfTravelCategoryFeatureId
                        ]?.code;
                    const mode = MODES_OF_TRAVEL_LIST.find(_mode => _mode.code === travelModeCode);

                    if (mode) {
                        const modeName =
                            mode.id === MODES_OF_TRAVEL.ALL_VEHICLES.id
                                ? "All Vehicles (including LBS+, CVD+, By Weight)"
                                : mode.name;

                        result._modes.push(modeName);
                    }
                }

                return result;
            },
            { _types: [] as Array<string>, _modes: [] as Array<string> },
        );
        return {
            types: _types.join(", "),
            modes: _modes.join(", "),
        };
    }, [packageData.metrics_package_items]);

    return (
        <div className="package-item" key={packageData.metrics_package_id}>
            <div className="package-name">
                <FontAwesomeIcon icon={faCube} />
                {packageData.metrics_package_name}
            </div>
            <div className="package-data">
                <span className="row-title">Included Analysis Types:</span>
                <span className="row-data">{types}</span>
                <span className="row-title">Modes:</span>
                <span className="row-data">{modes}</span>
            </div>
        </div>
    );
};

export const PackagesList = ({ metricsPackages }: TProps) => {
    if (!metricsPackages.length) return null;

    return (
        <div className="packages-list">
            <div className="title">Subscribed Packages</div>
            <div className="list">
                {metricsPackages.map(_package => (
                    <PackageItem key={_package.metrics_package_id} packageData={_package} />
                ))}
            </div>
        </div>
    );
};
