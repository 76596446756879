import { getSortedFilterOptions } from "@common/helpers/tableFilters.helpers";

export const ANALYSIS_TYPES = {
    OD: {
        id: "OD_Analysis",
        name: "Origin-Destination",
        shortName: "OD",
        hasSpeedMetric: true,
    },
    ZA: {
        id: "Zone_Activity_Analysis",
        name: "Zone Activity",
        shortName: "ZA",
    },
    ODG: {
        id: "OD_Preset_Geographies",
        name: "Trips to or from Pre-set Geography",
        shortName: "ODG",
    },
    ODMF: {
        id: "OD_MF",
        name: "Origin-Destination through Middle Filters",
        shortName: "ODMF",
        hasSpeedMetric: true,
    },
    VHWA: {
        id: "Visitor_Home_Work_Analysis",
        name: "Visitor Home and Work Analysis",
        shortName: "VHWA",
    },
    AADT2016: {
        id: "Estimated_2016_AADT",
        name: "Estimated 2016 AADT Values",
        shortName: "AADT2016",
    },
    AADT2017: {
        id: "Estimated_2017_AADT",
        name: "Estimated 2017 AADT Values",
        shortName: "AADT2017",
    },
    AADT2018: {
        id: "Estimated_2018_AADT",
        name: "Estimated 2018 AADT Values",
        shortName: "AADT2018",
    },
    AADT2019: {
        id: "Estimated_2019_AADT",
        name: "Estimated 2019 AADT Values",
        shortName: "AADT2019",
    },
    AADT2020: {
        id: "Estimated_2020_AADT",
        name: "Estimated 2020 AADT Values",
        shortName: "AADT2020",
    },
    AADT2021: {
        id: "Estimated_2021_AADT",
        name: "Estimated 2021 AADT Values",
        shortName: "AADT2021",
    },
    AADT2022: {
        id: "Estimated_2022_AADT",
        name: "Estimated 2022 AADT Values",
        shortName: "AADT2022",
    },
    AADT2023: {
        id: "Estimated_2023_AADT",
        name: "Estimated 2023 AADT Values",
        shortName: "AADT2023",
    },
    SEGMENT: {
        id: "Segment_Analysis",
        name: "Segment Analysis",
        shortName: "Segment",
        hasSpeedMetric: true,
    },
    NETWORK_PERFORMANCE_SEGMENT: {
        id: "TT_Segment_Analysis",
        name: "Network Performance",
        shortName: "NP",
        hasSpeedMetric: true,
    },
    NETWORK_PERFORMANCE_SPOT: {
        id: "Network_Performance_Spot",
        name: "Network Performance",
        shortName: "NP",
        hasSpeedMetric: true,
    },
    NETWORK_OD: {
        id: "Network_OD",
        name: "Network Origin-Destination",
        shortName: "Network OD",
    },
    TD: {
        id: "Congestion_Analysis",
        name: "Traffic Diagnostics",
        shortName: "TD",
    },
    K_FACTOR: {
        id: "K_Factor_Estimation",
        name: "K-Factor and Seasonal Factors [BETA]",
        shortName: "K-Factor",
    },
    TROD: {
        id: "Top_Routes",
        name: "Top Routes between Origins and Destinations",
        shortName: "TROD",
    },
    TRZA: {
        id: "Travel_Shed",
        name: "Top Routes for Zones",
        shortName: "TRZA",
    },
    TMC: {
        id: "TMC",
        name: "Turning Movement Counts",
        shortName: "TMC",
    },
    TT_TMC: {
        id: "TT_TMC",
        name: "Turning Movement Counts",
        shortName: "TMC",
    },
    CT: {
        id: "Congestion_Trends",
        name: "Congestion Management",
        shortName: "CT",
    },
    RV: {
        id: "Roadway_Volume",
        name: "Roadway Volume",
        shortName: "RV",
    },
    ATM: {
        id: "Active_Transportation_Monitor",
        name: "Active Transportation",
        shortName: "ATM",
    },
    CS: {
        id: "Corridor_Studies",
        name: "Corridor Study",
        shortName: "CS",
    },
};

export type TAnalysisType = typeof ANALYSIS_TYPES[keyof typeof ANALYSIS_TYPES];

export const ANALYSIS_TYPES_LIST = Object.values(ANALYSIS_TYPES);

export const MODES_OF_TRAVEL = {
    ALL_VEHICLES: {
        id: "All_Vehicles",
        code: "All_Vehicles",
        name: "All Vehicles LBS+",
    },
    ALL_VEHICLES_CVD: {
        id: "All_Vehicles_CVD",
        code: "All_Vehicles_CVD",
        name: "All Vehicles CVD+",
    },
    ALL_VEHICLES_ONLY: {
        id: "All_Vehicles_Only",
        code: "All_Vehicles_Only",
        name: "All Vehicles",
    },
    ALL_VEHICLES_TOMTOM: {
        id: "All_Vehicles_TT",
        code: "All_Vehicles_TT",
        name: "All Vehicles",
    },
    ALL_VEHICLES_TOMTOM_API: {
        id: "All_Vehicles_TT_API",
        code: "All_Vehicles_TT_API",
        name: "All Vehicles",
    },
    ALL_VEHICLES_BY_WEIGHT: {
        id: "All_Vehicles_By_Weight",
        code: "All_Vehicles_By_Weight",
        name: "All Vehicles By Weight",
    },
    TRUCK: { id: "Truck", code: "Truck", name: "Truck" },
    BICYCLE: { id: "Bicycle", code: "Bicycle", name: "Bicycle" },
    PEDESTRIAN: { id: "Pedestrian", code: "Pedestrian", name: "Pedestrian" },
    BUS: { id: "Personal_Bus", code: "Bus", name: "Bus" },
    RAIL: { id: "Personal_Rail", code: "Rail", name: "Rail" },
    ACTIVE_MODES: {
        id: "Active_Modes",
        code: "Active_Modes",
        name: "Pedestrian, Bicycle and Vehicle",
    },
};

export const MODES_OF_TRAVEL_LIST = Object.values(MODES_OF_TRAVEL);
export type TTravelMode = typeof MODES_OF_TRAVEL[keyof typeof MODES_OF_TRAVEL];

export const ANALYSIS_STATUSES = {
    AVAILABLE: {
        id: "Available",
        name: "Available",
    },
    CANCELLING: {
        id: "Cancelling",
        name: "Cancelling",
    },
    CANCELLED: {
        id: "Cancelled",
        name: "Cancelled",
    },
    DATA_AVAILABLE: {
        id: "Data_Available",
        name: "Data Available",
    },
    DELETED: {
        id: "Deleted",
        name: "Deleted",
    },
    DRAFT: {
        id: "Draft",
        name: "Draft",
    },
    ERROR: {
        id: "Error",
        name: "StreetLight Hold",
    },
    EXPORTING: {
        id: "Exporting",
        name: "Exporting",
    },
    FLAGGED_COVERAGE: {
        id: "Flagged_Coverage",
        name: "Flagged Coverage",
    },
    FLAGGED_SIZE: {
        id: "Flagged_Size",
        name: "Flagged Size",
    },
    IN_COVERAGE_REVIEW: {
        id: "In_Coverage_Review",
        name: "In Coverage Review",
    },
    IN_SIZE_REVIEW: {
        id: "In_Size_Review",
        name: "In Size Review",
    },
    NEW: {
        id: "New",
        name: "New",
    },
    QUEUED: {
        id: "Queued",
        name: "Queued",
    },
    PROCESSING: {
        id: "Processing",
        name: "Processing",
    },
    RERUNNING: {
        id: "Rerunning",
        name: "Rerunning",
    },
    RUNNING: {
        id: "Running",
        name: "Running",
    },
    SAVED: {
        id: "Saved",
        name: "Saved",
    },
    PAUSED: {
        id: "Paused",
        name: "Paused",
    },
    PAUSED_REQUEUED: {
        id: "Paused(Re-queued)",
        name: "Paused (Re-queued)",
    },
    RESUMED: {
        id: "Resumed",
        name: "Resumed",
    },
    VISUALIZATION_AVAILABLE: {
        id: "Visualization_Available",
        name: "Visualization Available",
    },
};

export const ANALYSIS_STATUSES_LIST = getSortedFilterOptions(
    Object.values(ANALYSIS_STATUSES),
    "name",
);
export type TAnalysisStatus = typeof ANALYSIS_STATUSES[keyof typeof ANALYSIS_STATUSES];

export const ANALYSIS_STATUSES_OPTIONS = ANALYSIS_STATUSES_LIST.map(({ id, name }) => ({
    value: id,
    label: name,
}));

export const OUTPUT_TYPES = {
    INDEX: {
        id: "index",
        name: "StreetLight Index",
    },
    VOLUME: {
        id: "volume",
        name: "StreetLight All Vehicles Volume",
    },
    BIKE_VOLUME: {
        id: "bike_volume",
        name: "StreetLight Bicycle Volume",
    },
    PED_VOLUME: {
        id: "ped_volume",
        name: "StreetLight Pedestrian Volume",
    },
    TRUCK_VOLUME: {
        id: "truck_volume",
        name: "StreetLight Truck Volume",
    },
    TRIP_COUNTS: {
        id: "trip_counts",
        name: "StreetLight Sample Trip Counts",
    },
    USER_COUNTS: {
        id: "user_counts",
        name: "Single Factor Calibrated Index (User Counts)",
    },
    IPF: {
        id: "ipf",
        name: "Calibrated Index with IPF (User Counts)",
    },
    AADT: {
        id: "aadt",
        name: "StreetLight AADT (Vehicle Trips)",
    },
    AADT_2023: {
        id: "aadt_2023",
        name: "Single Factor with StreetLight AADT 2023",
    },
    AADT_2022: {
        id: "aadt_2022",
        name: "Single Factor with StreetLight AADT 2022",
    },
    AADT_2021: {
        id: "aadt_2021",
        name: "Single Factor with StreetLight AADT 2021",
    },
    AADT_2020: {
        id: "aadt_2020",
        name: "Single Factor with StreetLight AADT 2020",
    },
    AADT_2019: {
        id: "aadt_2019",
        name: "Single Factor with StreetLight AADT 2019",
    },
    AADT_2018: {
        id: "aadt_2018",
        name: "Single Factor with StreetLight AADT 2018",
    },
    AADT_2017: {
        id: "aadt_2017",
        name: "Single Factor with StreetLight AADT 2017",
    },
    AADT_2016: {
        id: "aadt_2016",
        name: "Single Factor with StreetLight AADT 2016",
    },
};

export const OUTPUT_TYPES_LIST = Object.values(OUTPUT_TYPES);

export const ORG_COUNTRIES = {
    US: { id: "country_us", name: "United States", code: "US" },
    CA: { id: "country_ca", name: "Canada", code: "CA" },
};
export const ORG_COUNTRIES_LIST = Object.values(ORG_COUNTRIES);
export type TOrgCountryKey = keyof typeof ORG_COUNTRIES;
export type TOrgCountry = typeof ORG_COUNTRIES[keyof typeof ORG_COUNTRIES];

export const CREATE_SOURCE_OPTIONS = {
    INSIGHT: { value: "insight_full", label: "InSight" },
    LITE: { value: "insight_lite", label: "InSight Go" },
} as const;

export const CREATE_SOURCE_OPTIONS_LIST = Object.values(CREATE_SOURCE_OPTIONS);
export type TCreateSourceOption = typeof CREATE_SOURCE_OPTIONS[keyof typeof CREATE_SOURCE_OPTIONS];

export const CREATE_WITH_OPTIONS = {
    API: { value: "api", label: "API" },
    UI: { value: "ui", label: "UI" },
};

export const CREATE_WITH_OPTIONS_LIST = Object.values(CREATE_WITH_OPTIONS);

export const ANALYSIS_CENSUS_TYPES = {
    CA_2016: "ca_2016",
    US_2010: "us_2010",
    US_2020: "us_2020",
};
