import {
    VIZ_ZONE_TYPES,
    VIZ_MAP_LAYERS,
    BASE_VIZ_DEFAULTS,
} from "@app/viz3/base/state/baseViz.constants";

export const ZA_ZONE_TYPES = {
    ZONE: VIZ_ZONE_TYPES.ZONE,
    HOME_GRIDS: VIZ_ZONE_TYPES.HOME_GRIDS,
    HOME_ZIP: VIZ_ZONE_TYPES.HOME_ZIP,
    HOME_METRO: VIZ_ZONE_TYPES.HOME_METRO,
    HOME_STATE: VIZ_ZONE_TYPES.HOME_STATE,
    WORK_GRIDS: VIZ_ZONE_TYPES.WORK_GRIDS,
} as const;
export const ZA_ZONE_TYPES_LIST = Object.values(ZA_ZONE_TYPES);
export const ZA_ZONE_TYPE_DISTRIBUTION_ZONE_ONLY = [ZA_ZONE_TYPES.ZONE];
export type TZAZoneType = typeof ZA_ZONE_TYPES[keyof typeof ZA_ZONE_TYPES];

export const ZA_LAYERS = {
    ZONE: {
        code: "zone",
        label: "Zones",
        hasConfiguration: true,
    },
    HOME_GRIDS: {
        code: "home_grid",
        label: "Home Grids",
        hasConfiguration: true,
    },
    HOME_SEGMENT: {
        code: "home_grid",
        label: "Segments",
        hasConfiguration: true,
    },
    HOME_ZIP: {
        code: "home_zip",
        label: "Home Zip Codes",
        hasConfiguration: true,
    },
    HOME_METRO: {
        code: "home_metro",
        label: "Home Metro Areas",
        hasConfiguration: true,
    },
    HOME_STATE: {
        code: "home_state",
        label: "Home States",
        hasConfiguration: true,
    },
    WORK_SEGMENT: {
        code: "work_grid",
        label: "Segments",
        hasConfiguration: true,
    },
    WORK_GRIDS: {
        code: "work_grid",
        label: "Work Grids",
        hasConfiguration: true,
    },
    ...VIZ_MAP_LAYERS,
} as const;
export type TZALayerKey = keyof typeof ZA_LAYERS;
export type TZALayer = typeof ZA_LAYERS[keyof typeof ZA_LAYERS];

export const INTERSECTION_TYPE_FILTER = {
    filterName: "intersectionTypes",
};

export const INTERSECTION_TYPE_ALL = {
    label: "Include all trip intersections",
    code: 246,
};

export const INTERSECTION_TYPES = {
    TRIP_START: {
        label: "Trips that start in the selected zones",
        code: 243,
    },
    TRIP_END: {
        label: "Trips that end in the selected zones",
        code: 244,
    },
    TRIP_PASS_THROUGH: {
        label: "Trips that pass-through the selected zones",
        code: 245,
    },
} as const;

export const INTERSECTION_TYPES_LIST = Object.values(INTERSECTION_TYPES);

export const TRAFFIC_BEHAVIORS = {
    TRAFFIC_DISTRIBUTION: {
        code: "zone",
        label: "Zone Distribution",
        zoneType: ZA_ZONE_TYPES.ZONE,
    },
    HOME_GRIDS: {
        code: "home_grid",
        label: "Home Locations",
        zoneType: ZA_ZONE_TYPES.HOME_GRIDS,
    },
    HOME_ZIP: {
        code: "home_zip",
        label: "Home Locations",
        zoneType: ZA_ZONE_TYPES.HOME_ZIP,
    },
    HOME_METRO: {
        code: "home_metro",
        label: "Home Locations",
        zoneType: ZA_ZONE_TYPES.HOME_METRO,
    },
    HOME_STATE: {
        code: "home_state",
        label: "Home Locations",
        zoneType: ZA_ZONE_TYPES.HOME_STATE,
    },
    WORK_GRIDS: {
        code: "work_grid",
        label: "Work Locations",
        zoneType: ZA_ZONE_TYPES.WORK_GRIDS,
    },
} as const;
export const TRAFFIC_BEHAVIORS_LIST = Object.values(TRAFFIC_BEHAVIORS);
export type TTrafficBehaviorKey = keyof typeof TRAFFIC_BEHAVIORS;
export type TTrafficBehavior = typeof TRAFFIC_BEHAVIORS[keyof typeof TRAFFIC_BEHAVIORS];

export const TRAFFIC_BEHAVIORS_TO_AREA_TYPES = {
    [TRAFFIC_BEHAVIORS.TRAFFIC_DISTRIBUTION.code]: "zone",
    [TRAFFIC_BEHAVIORS.HOME_GRIDS.code]: "grid",
    [TRAFFIC_BEHAVIORS.HOME_ZIP.code]: "zip",
    [TRAFFIC_BEHAVIORS.HOME_METRO.code]: "metro",
    [TRAFFIC_BEHAVIORS.HOME_STATE.code]: "state",
    [TRAFFIC_BEHAVIORS.WORK_GRIDS.code]: "grid",
};

export const DEVICE_SEGMENTATIONS = {
    RESIDENTS: {
        code: "r",
        label: "Residents",
    },
    WORKERS: {
        code: "w",
        label: "Workers",
    },
    VISITORS: {
        code: "v",
        label: "Visitors",
    },
};
export const DEVICE_SEGMENTATIONS_LIST = Object.values(DEVICE_SEGMENTATIONS);

export const DEFAULT_MAP_LAYERS_VISIBILITY = {
    [ZA_LAYERS.ZONE.code]: true,
    [ZA_LAYERS.HOME_GRIDS.code]: true,
    [ZA_LAYERS.WORK_GRIDS.code]: true,
    [ZA_LAYERS.BASE.code]: true,
    [ZA_LAYERS.UNSELECTED.code]: false,
    [ZA_LAYERS.LABEL.code]: true,
    [ZA_LAYERS.LEGEND.code]: true,
};

export const ZA_VIZ_DEFAULTS = {
    filters: {
        ...BASE_VIZ_DEFAULTS.filters,
        [ZA_ZONE_TYPES.HOME_GRIDS.filterName]: [],
        [ZA_ZONE_TYPES.WORK_GRIDS.filterName]: [],
    },
    visualization: {
        ...BASE_VIZ_DEFAULTS.visualization,
        trafficBehaviors: [TRAFFIC_BEHAVIORS.TRAFFIC_DISTRIBUTION],
        mapLayersVisibility: DEFAULT_MAP_LAYERS_VISIBILITY,
        [INTERSECTION_TYPE_FILTER.filterName]: INTERSECTION_TYPES_LIST,
        deviceSegmentations: null,
    },
};

export const EXCLUDED_IDS_PREFIX = "_excluded";

export const STATE_METRO_MIN_INTERACTIVE_ZOOM = 3;
