import { CancelToken } from "axios";
import { HttpService, IRequestConfig } from "@common/services/server/http.service";
import { AnalysesApiService } from "@common/services/server/analysesApi.service";
import { ORG_COUNTRIES } from "@common/constants/analysis.constants";
import type {
    IAnalysesResponse,
    ISaveAnalysisData,
    ISaveAnalysisResponse,
    ISaveDraftResponse,
    TSaveLightningAnalysis,
} from "@common/services/server/analysesApi.types";
import type {
    IGetATMAvailableDataPeriodsResponse,
    IGetCensusTractsData,
    IGetCensusTractsResponse,
    IGetOsmSegmentsData,
    IGetOsmSegmentsResponse,
    IGetRoadsForRefineSelectionStepData,
    IGetRoadsForRefineSelectionStepResponse,
    IGetRouteOverallDirectionResponse,
    IGetSubscriptionRegionZoneInfoConfig,
    IGetSubscriptionRegionZoneInfoResponse,
    IGetRouteOverallDirectionData,
    IGetRouteRequestData,
    IGetRouteRequestResponse,
    IPrepareSearchRegionForRoutingRequestData,
    IPrepareStudySearchRegionForRoutingRequestData,
    IPrepareSubscriptionSearchRegionForRoutingRequestData,
    IPrepareSearchRegionForRoutingRequestResponse,
} from "./analysisLightningApi.types";

const LIGHTNING_ANALYSIS_DATA = {
    country: ORG_COUNTRIES.US.code,
    excluded_specific_dates: [],
    tag_ids: [],
    measurement_unit: "mi",
    enable_viz: true,
    ignore_privacy_check: true,
    ignore_runtime_check: false,
    enable_upsampling: false,
    is_massive_queue: false,
    premiumA: false,
    premiumB: false,
    use_locked_trip: true,
    has_min_bins: false,
    draft_id: "",
    is_draft: false,
    date_format: "date_ranges",
    is_copy_project: false,
    create_method: "A",
    create_zone_sets: false,
    enable_completion_email: true,
    calibration_type: "multi_factor_stl_vol_calibration",
    premiumVHW: false,
    enable_speed_percentile: true,
    speed_percentile_bins: "5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95",
    enable_trip_speed: true,
    trip_speed_bins:
        "0-5,5-10,10-15,15-20,20-25,25-30,30-35,35-40,40-45,45-50,50-55,55-60,60-65,65-70,70-75,75-80,80-85,85-90,90+",
} as const;

export const AnalysisLightningApiService = {
    /**
     * @param zoneKindIds - array of zoneKindIds in order of zones priority to check (e.g. [15,19,18] - Try state, else try county, else try census tract);
     */
    getSubscriptionRegionZoneInfo(
        zoneKindIds: number[],
        config?: IRequestConfig<IGetSubscriptionRegionZoneInfoConfig>,
    ) {
        return HttpService.get<IGetSubscriptionRegionZoneInfoResponse>(
            `/subscription_region_zone_info/${zoneKindIds.join("|")}`,
            config,
        ).catch(
            HttpService.showIfError(
                "Unknown error occurred while fetching subscription region info.",
            ),
        );
    },
    /**
     * @param zoneKindIds - array of zoneKindIds in order of zones priority to check (e.g. [15,19,18] - Try state, else try county, else try census tract);
     */
    getStudyRegionZoneInfo(
        zoneKindIds: number[],
        studyId: number,
        config?: IRequestConfig<IGetSubscriptionRegionZoneInfoConfig>,
    ) {
        return HttpService.get<IGetSubscriptionRegionZoneInfoResponse>(
            `/study_region_zone_info/${studyId}/${zoneKindIds.join("|")}`,
            config,
        ).catch(
            HttpService.showIfError("Unknown error occurred while fetching study region info."),
        );
    },
    getOsmSegments(roadName: string, zoneKindId: number, body: IGetOsmSegmentsData) {
        return HttpService.post<IGetOsmSegmentsResponse>(
            `/zone/osm/${zoneKindId}/${roadName}`,
            body,
        ).catch(HttpService.showIfError("Unknown error occurred while receiving osm segments."));
    },
    createAnalysis(data: Partial<TSaveLightningAnalysis>): Promise<ISaveAnalysisResponse> {
        return AnalysesApiService.saveAnalysis({
            ...LIGHTNING_ANALYSIS_DATA,
            ...data,
        } as ISaveAnalysisData);
    },
    saveDraft(data: Partial<TSaveLightningAnalysis>): Promise<ISaveDraftResponse> {
        return AnalysesApiService.saveDraft({
            ...LIGHTNING_ANALYSIS_DATA,
            ...data,
        } as ISaveAnalysisData);
    },
    updateDraft(
        analysisId: number,
        data: Partial<TSaveLightningAnalysis>,
    ): Promise<IAnalysesResponse> {
        return AnalysesApiService.updateDraft(analysisId, {
            ...LIGHTNING_ANALYSIS_DATA,
            ...data,
        } as ISaveAnalysisData);
    },
    getCensusTracts(data: IGetCensusTractsData) {
        return HttpService.post<IGetCensusTractsResponse>("/zones/contained_zones", data).catch(
            HttpService.showIfError("Unknown error occurred while fetching Census Tracts."),
        );
    },
    getATMAvailableDataPeriods() {
        return HttpService.get<IGetATMAvailableDataPeriodsResponse>(
            "/project/atm_available_data_years",
        ).catch(
            HttpService.showIfError(
                "Unknown error occurred while fetching ATM available data periods.",
            ),
        );
    },
    getRoadsWithSegmentCounts(data: IGetRoadsForRefineSelectionStepData) {
        return HttpService.post<IGetRoadsForRefineSelectionStepResponse>(
            "/zones/road_info",
            data,
        ).catch(
            HttpService.showIfError(
                "Unknown error occurred while fetching roads for refine selection step.",
            ),
        );
    },
    prepareSearchRegionForRouting(data: IPrepareSearchRegionForRoutingRequestData) {
        return HttpService.post<IPrepareSearchRegionForRoutingRequestResponse>(
            "/routing/prepare_search_region",
            data,
        ).catch(HttpService.showIfError("Unknown error occurred while caching Route."));
    },
    prepareSubscriptionSearchRegionForRouting(
        data: IPrepareSubscriptionSearchRegionForRoutingRequestData,
    ) {
        return HttpService.post<IPrepareSearchRegionForRoutingRequestResponse>(
            "/routing/prepare_subscription_search_region",
            data,
        ).catch(HttpService.showIfError("Unknown error occurred while caching Route."));
    },
    prepareStudySearchRegionForRouting(data: IPrepareStudySearchRegionForRoutingRequestData) {
        return HttpService.post<IPrepareSearchRegionForRoutingRequestResponse>(
            "/routing/prepare_study_search_region",
            data,
        ).catch(HttpService.showIfError("Unknown error occurred while caching Route."));
    },
    getRoute(
        data: IGetRouteRequestData,
        options: IRequestConfig<{ cancelToken: CancelToken }> = {},
    ) {
        return HttpService.post<IGetRouteRequestResponse>(
            "/routing/generate_route",
            data,
            options,
        ).catch(HttpService.showIfError("Unknown error occurred while generating Route."));
    },
    getRouteOverallDirection(data: IGetRouteOverallDirectionData) {
        return HttpService.post<IGetRouteOverallDirectionResponse>(
            "/routing/estimate_direction",
            data,
        ).catch(
            HttpService.showIfError("Unknown error occurred while estimating Route Direction."),
        );
    },
};
