import {
    BASE_VIZ_DEFAULTS,
    VIZ_MAP_LAYERS,
    VIZ_ZONE_TYPES,
} from "@app/viz3/base/state/baseViz.constants";
import {
    CUSTOM_GATE_TIME_DISTRIBUTION_METRIC_KEY,
    TIME_DISTRIBUTION_METRIC_TYPES,
} from "@app/viz3/npSpotVisualization/widgets/timeDistribution/npSpotTimeDistribution.constants";
import {
    CUSTOM_GATE_DISTRIBUTION_METRIC_KEY,
    METRIC_TYPES,
} from "@app/viz3/npSpotVisualization/widgets/zoneDistribution/npSpotZoneDistribution.constants";

export const NP_SPOT_ZONE_TYPE = {
    ...VIZ_ZONE_TYPES.ZONE,
    displayName: "Gates",
} as const;

export const NP_SPOT_TYPES_LIST = [NP_SPOT_ZONE_TYPE];

export type TNPSpotTypesList = typeof NP_SPOT_TYPES_LIST;

export const NP_SPOT_LAYERS = {
    CUSTOM_GATE: {
        code: "custom_gate",
        label: "Segments",
        hasConfiguration: true,
    },
    ...VIZ_MAP_LAYERS,
} as const;

export type TNPSpotLayer = typeof NP_SPOT_LAYERS[keyof typeof NP_SPOT_LAYERS];

export const NP_SPOT_VISUALIZATION_PROPERTIES = {
    TRAFFIC: {
        code: "traffic",
        label: "Volume",
        unit: "",
        [CUSTOM_GATE_DISTRIBUTION_METRIC_KEY]: METRIC_TYPES.traffic,
        [CUSTOM_GATE_TIME_DISTRIBUTION_METRIC_KEY]: {},
    },
    CONGESTION: {
        code: "congestion",
        label: "Congestion",
        unit: "Percent",
        [CUSTOM_GATE_DISTRIBUTION_METRIC_KEY]: METRIC_TYPES.congestion,
        [CUSTOM_GATE_TIME_DISTRIBUTION_METRIC_KEY]: {},
    },
    AVG_SPEED: {
        code: "avg_speed",
        label: "Average Speed",
        units: { MPH: "Mph", KPH: "Kph" },
        [CUSTOM_GATE_DISTRIBUTION_METRIC_KEY]: METRIC_TYPES.speed,
        [CUSTOM_GATE_TIME_DISTRIBUTION_METRIC_KEY]: TIME_DISTRIBUTION_METRIC_TYPES.speed,
    },
    SPEED_PERCENTILE: {
        code: "speed_percentile",
        label: "Speed Percentile",
        unit: "",
        [CUSTOM_GATE_DISTRIBUTION_METRIC_KEY]: {},
        [CUSTOM_GATE_TIME_DISTRIBUTION_METRIC_KEY]: {},
    },
};
export const NP_SPOT_VISUALIZATION_PROPERTIES_LIST = Object.values(
    NP_SPOT_VISUALIZATION_PROPERTIES,
);
export type TVisualizationProperty =
    typeof NP_SPOT_VISUALIZATION_PROPERTIES[keyof typeof NP_SPOT_VISUALIZATION_PROPERTIES];

export const DEFAULT_MAP_LAYERS_VISIBILITY = {
    [NP_SPOT_LAYERS.CUSTOM_GATE.code]: true,
    [NP_SPOT_LAYERS.BASE.code]: true,
    [NP_SPOT_LAYERS.UNSELECTED.code]: false,
    [NP_SPOT_LAYERS.LABEL.code]: true,
    [NP_SPOT_LAYERS.LEGEND.code]: true,
} as const;

export const NP_SPOT_VIZ_DEFAULTS = {
    filters: {
        ...BASE_VIZ_DEFAULTS.filters,
    },
    visualization: {
        ...BASE_VIZ_DEFAULTS.visualization,
        visualizationProperty: NP_SPOT_VISUALIZATION_PROPERTIES.TRAFFIC,
        mapLayersVisibility: DEFAULT_MAP_LAYERS_VISIBILITY,
    },
};

export const TRAFFIC_BEHAVIORS = {
    zoneType: VIZ_ZONE_TYPES.ZONE,
} as const;

export type TTrafficBehavior = typeof TRAFFIC_BEHAVIORS[keyof typeof TRAFFIC_BEHAVIORS];

export const NETWORK_PERFORMANCE_DISTRIBUTION_CHART_TYPE = "segmentDistributionMetric";
