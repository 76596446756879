import { lazy } from "react";
import { ROUTES } from "@common/constants/routes.constants";

const AnalysisCreatePage = lazy(() => import("@app/analysis/analysisCreate.page"));
const AnalysisViewPage = lazy(() => import("@app/analysis/analysisView.page"));
const AnalysisReviewPage = lazy(() => import("@app/analysis/analysisReview.page"));
const EsriExportPage = lazy(() => import("@app/bigBang/esriExport/esriExport.page"));
const AnalysisLightningPage = lazy(
    () => import("@app/analysisLightning/base/layout/lightningLayout"),
);

export const routes = [
    {
        path: "/analysis/view/:analysisId/:saVersionId/:source?",
        component: AnalysisViewPage,
        id: "AnalysisViewPage",
        title: "View Analysis",
    },
    {
        path: "/analysis/review/:analysisId/:saVersionId",
        component: AnalysisReviewPage,
        id: "AnalysisReviewPage",
        title: "Review Analysis",
    },
    {
        path: "/analysis/:analysisUrl",
        component: AnalysisCreatePage,
        id: "AnalysisCreatePage",
        title: "Create New Analysis",
    },
    {
        path: "/analyses/esriExport/:analysisId/:analysisName",
        component: EsriExportPage,
        id: "EsriExportPage",
        title: "Export To Esri",
    },
    {
        path: `${ROUTES.LIGHTNING_ANALYSIS}/:analysisUrl`,
        component: AnalysisLightningPage,
        id: "AnalysisLightningPage",
        title: "",
    },
];
