import { useMemo } from "react";
import fuse, { Expression, FuseSearchOptions, IFuseOptions } from "fuse.js";
import { MIN_QUERY_LENGTH } from "@common/components/createAnalysisHelp/createAnalysisHelp.constants";

const FUSE_OPTIONS: IFuseOptions<unknown> = {
    includeScore: true,
    ignoreLocation: true,
    includeMatches: true,
    findAllMatches: true,
    minMatchCharLength: MIN_QUERY_LENGTH,
} as const;

type TProps<T> = {
    keys: string[];
    searchExpression: string | Expression | null;
    collection: T[];
    options?: Partial<IFuseOptions<T>>;
    fuseSearchOptions?: FuseSearchOptions;
};

export const useFuzzySearch = <T>({
    keys,
    collection,
    searchExpression,
    options = {},
    fuseSearchOptions,
}: TProps<T>) => {
    const fuseInstance = useMemo(() => {
        return new fuse<T>(collection, { ...FUSE_OPTIONS, ...options, keys });
    }, [collection, options, keys]);

    return useMemo(() => {
        if (!searchExpression) return [];

        const res = fuseInstance.search(searchExpression, fuseSearchOptions);

        return res;
    }, [fuseInstance, fuseSearchOptions, searchExpression]);
};
