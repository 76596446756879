import type { Map } from "mapbox-gl";
import { addContentToButton, createButton } from "./customControls.helpers";

export class MapStyleControl {
    button: HTMLButtonElement | null = null;
    _map!: Map | undefined;
    _container!: HTMLDivElement;
    _openMapStyleDialog: (event: Event) => void;

    constructor({ openMapStyleDialog }: { openMapStyleDialog: (event: Event) => void }) {
        this._openMapStyleDialog = openMapStyleDialog;
    }

    onAdd = (map: Map) => {
        this._map = map;
        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group map-style-control";

        const button = createButton({
            className: "mapboxgl-map-style",
            tooltip: "Set Map Style",
        });
        addContentToButton({ button, tooltip: "Set Map Style" });

        button.onclick = this._openMapStyleDialog;

        this._container.appendChild(button);

        return this._container;
    };

    onRemove = () => {
        this._container.parentNode?.removeChild(this._container);
        this._map = undefined;
    };

    setSelected = (isSelected: boolean) => {
        this._container.classList.toggle("active", isSelected);
    };
}
