// https://github.com/mapbox/mapbox-gl-draw-static-mode
export const StaticMode = {
    onSetup() {
        this.setActionableState(); // default actionable state is false for all actions
        return {};
    },
    toDisplayFeatures(state, geojson, display) {
        display(geojson);
    },
};
