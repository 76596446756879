import { useMemo } from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { StlFormError, StlButton, StlDatePicker, StlInput, StlSelect } from "@common/components";
import {
    STUDY_TYPE_OPTIONS,
    STUDY_MODES,
} from "@common/features/studyModal/common/study.constants";
import * as helpIcons from "@common/features/studyModal/common/studyHelpIcons";
import { EStudyMode, IStudy } from "@common/features/studyModal/common/study.types";
import { IErrors } from "@common/components/formError/formError";
import { TDate } from "@common/components/dateRangeFilter/dateRangeFilter";
import "./studyGeneralTab.less";

type TProps = {
    study: IStudy;
    mode: EStudyMode;
    closeModal: () => void;
    showNextTab: () => void;
    showPreviousTab: () => void;
    saveStudy: () => Promise<unknown>;
    updateStudy: (value: Partial<IStudy>) => void;
    isValid: boolean;
    errors: IErrors;
};

export const StudyGeneralTab = ({
    study,
    updateStudy,
    saveStudy,
    showNextTab,
    showPreviousTab,
    closeModal,
    errors,
    isValid,
    mode,
}: TProps) => {
    const isViewMode = mode === STUDY_MODES.VIEW;

    const startDate = useMemo(() => study.startDate || moment().toDate(), [study.startDate]);
    const minEndDate = useMemo(() => moment(startDate).toDate(), [startDate]);

    const updateStudyData = (property: keyof IStudy, value: IStudy[keyof IStudy]): void =>
        updateStudy({ [property]: value });

    return (
        <div className="stl-study-general-tab">
            <div className="tab-content">
                <div>
                    <span>Required fields are indicated with *</span>
                </div>
                <div className="form">
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="studyType">
                            Study Type *{helpIcons.STUDY_TYPE_HELP_ICON}
                        </label>
                        <StlSelect
                            id="studyType"
                            placeholder="study type"
                            options={STUDY_TYPE_OPTIONS}
                            value={study.studyType}
                            onChange={(option): void =>
                                updateStudyData("studyType", option!.value)
                            }
                            disabled={mode !== STUDY_MODES.CREATE}
                            required
                        />
                        <StlFormError name="studyType" errors={errors} />
                    </div>
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="studyName">
                            Study Name *{helpIcons.STUDY_NAME_HELP_ICON}
                        </label>
                        <StlInput
                            id="studyName"
                            placeholder="study name"
                            value={study.name}
                            onChange={(value: string): void => updateStudyData("name", value)}
                            maxLength={50}
                            disabled={isViewMode}
                            required
                        />
                        <StlFormError name="name" errors={errors} />
                    </div>
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="studyStartDate">
                            Start Date
                        </label>
                        <StlDatePicker id="studyStartDate" value={startDate} disabled />
                    </div>
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="studyEndDate">
                            End Date (MM/DD/YYYY)
                        </label>
                        <StlDatePicker
                            id="studyEndDate"
                            placeholder="end date"
                            value={study.endDate}
                            onChange={(value: TDate): void => updateStudyData("endDate", value)}
                            minDate={minEndDate}
                            disabled={isViewMode}
                            required
                        />
                        <StlFormError name="endDate" errors={errors} />
                    </div>
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="studyId">
                            Study ID (optional)
                            {helpIcons.STUDY_ID_HELP_ICON}
                        </label>
                        <StlInput
                            id="studyId"
                            placeholder="study id"
                            value={study.rfpId}
                            onChange={(value: string): void => updateStudyData("rfpId", value)}
                            disabled={isViewMode}
                        />
                    </div>
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="studyEndClient">
                            End Client (optional)
                            {helpIcons.END_CLIENT_HELP_ICON}
                        </label>
                        <StlInput
                            id="studyEndClient"
                            placeholder="end client"
                            value={study.endClient}
                            onChange={(value: string): void => updateStudyData("endClient", value)}
                            disabled={isViewMode}
                        />
                    </div>
                </div>
            </div>
            <div className="tab-actions">
                <StlButton id="studyCloseBtn" variant="secondary" onClick={closeModal}>
                    {isViewMode ? (
                        <FormattedMessage id="app.close" defaultMessage="Close" />
                    ) : (
                        <FormattedMessage id="app.cancel" defaultMessage="Cancel" />
                    )}
                </StlButton>
                <StlButton
                    id="studyNextBtn"
                    variant="primary"
                    onClick={showNextTab}
                    disabled={!study.studyType || !study.name || !study.endDate}
                >
                    <FormattedMessage id="app.next" defaultMessage="Next" />
                </StlButton>
            </div>
        </div>
    );
};
