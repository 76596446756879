import { useCallback, useMemo, useState } from "react";
import type { Map } from "mapbox-gl";
import { useAppDispatch } from "@app/store/hooks";
import { patchMapLayersSettings } from "@app/store/userPreferences/userPreferences.actions";
import { defaultState } from "@app/store/userPreferences/userPreferences.reducer";
import { StlButton } from "@common/components";
import { TControlConfig } from "@common/components/baseMap/baseMap.types";
import { MapEnhancedLayersPicker } from "@common/components/baseMap/customControls/mapLayers/mapLayersPickers/enhanced/mapEnhancedLayersPicker";
import { MapOSMLayersPicker } from "@common/components/baseMap/customControls/mapLayers/mapLayersPickers/osm/mapOSMLayersPicker";
import { useOsmCheckboxValues } from "@common/components/baseMap/customControls/mapLayers/mapLayersPickers/osm/useOsmCheckboxValues";
import { MapLayers } from "@common/components/baseMap/mapLayers/mapLayers";
import {
    OSM_LAYERS,
    TMapLayersLocalState,
} from "@common/components/baseMap/mapLayers/mapLayers.constants";
import { MODES_OF_TRAVEL } from "@common/constants/analysis.constants";
import { setSelectedVizEnhancedLayer } from "@common/features/mapEnhancedLayers/state/mapEnhancedLayers.actions";
import {
    ENHANCED_LAYERS,
    FEMA_SUB_LAYERS,
} from "@common/features/mapEnhancedLayers/state/mapEnhancedLayers.constants";
import { getNestedRoadTypes } from "@common/features/zonesManager/components/spotCounter/spotCounter.helpers";
import { arrayIncludes } from "@common/utils/arrayIncludes";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./mapLayersPicker.less";

export const DEFAULT_DISPLAY_CONFIG = {
    showOsmLayers: true,
    showEnhancedLayers: true,
};

const INITIAL_STATE = {
    mapLayersState: {
        osmLayersCategories: {},
        enhancedLayersCategories: {
            enabled: false,
            category: ENHANCED_LAYERS.DAC_ZONES.code,
            nriSubLayer: FEMA_SUB_LAYERS.NATIONAL_RISK_INDEX.id,
            nhlSubLayer: FEMA_SUB_LAYERS.COASTAL_FLOODING.id,
        },
    },
};

const DEFAULT_SELECTED_OSM_IDS = {
    [OSM_LAYERS.OSM_VEHICLE.code]: getNestedRoadTypes(OSM_LAYERS.OSM_VEHICLE.code).map(
        road => road.id,
    ),
};

export const MapLayersPicker = ({
    map,
    options,
    disabledOptions,
    mapLayersConfig = DEFAULT_DISPLAY_CONFIG,
    onClose,
}: {
    map: Map | null;
    options?: string[];
    disabledOptions?: string[];
    mapLayersConfig?: TControlConfig["mapLayersConfig"];
    onClose: () => void;
}) => {
    const [mapLayersState, setMapLayersState] = useState<TMapLayersLocalState>(
        INITIAL_STATE.mapLayersState,
    );

    const dispatch = useAppDispatch();

    const isNPAnalysis = arrayIncludes(
        [MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code, MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code],
        mapLayersConfig?.travelModeCode,
    );
    const { mapLayersLocalState, defaultSelectedIds } = useMemo(() => {
        return mapLayersConfig?.shouldUseLocalState
            ? {
                  defaultSelectedIds: null,
                  mapLayersLocalState: mapLayersState,
              }
            : {
                  defaultSelectedIds: DEFAULT_SELECTED_OSM_IDS,
                  mapLayersLocalState: null,
              };
    }, [mapLayersConfig, mapLayersState]);

    const updateMapLayersState = useCallback((value: Partial<TMapLayersLocalState>) => {
        setMapLayersState(prevState => ({ ...prevState, ...value }));
    }, []);

    const {
        values,
        onChange,
        resetToDefault: resetOsmLayersPickerToDefault,
    } = useOsmCheckboxValues({
        options,
        disabledOptions,
        isNPAnalysis,
        mapLayersLocalState,
        updateMapLayersState,
        defaultSelectedIds,
    });

    const handleReset = () => {
        if (mapLayersLocalState) {
            const enhancedLayersCategories = {
                ...mapLayersLocalState.enhancedLayersCategories,
                ...defaultState.application.mapLayersSettings.enhancedLayersCategories,
            };
            updateMapLayersState({ enhancedLayersCategories });
            dispatch(setSelectedVizEnhancedLayer(enhancedLayersCategories));
        } else {
            dispatch(
                patchMapLayersSettings({
                    section: "enhancedLayersCategories",
                    ...defaultState.application.mapLayersSettings.enhancedLayersCategories,
                }),
            );
            resetOsmLayersPickerToDefault();
        }
    };

    const config = useMemo(
        () => ({ ...DEFAULT_DISPLAY_CONFIG, ...mapLayersConfig }),
        [mapLayersConfig],
    );

    if (!config.showEnhancedLayers && !config.showOsmLayers) return null;

    return (
        <div className="stl-map-layers-picker">
            <div className="picker-header">
                <label className="picker-label primary">Map Layers</label>
                <StlButton
                    variant="naked"
                    size="sm"
                    className="close-button"
                    aria-label="Close map layers picker"
                    onClick={onClose}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </StlButton>
            </div>
            <div className="picker-content">
                {config.showOsmLayers && (
                    <>
                        <label className="picker-label secondary" htmlFor="osm-layers-select">
                            OSM Layers
                        </label>
                        <MapOSMLayersPicker values={values} onChange={onChange} />
                    </>
                )}
                {config.showEnhancedLayers && (
                    <MapEnhancedLayersPicker
                        mapLayersLocalState={mapLayersLocalState}
                        updateMapLayersState={updateMapLayersState}
                    />
                )}
            </div>
            <div className="picker-footer">
                <StlButton className="reset-button" variant="link" size="sm" onClick={handleReset}>
                    Reset to Default
                </StlButton>
            </div>
            <MapLayers map={map} config={config} mapLayersLocalState={mapLayersLocalState} />
        </div>
    );
};
