import { BASE_VIZ_DEFAULTS, VIZ_MAP_LAYERS } from "@app/viz3/base/state/baseViz.constants";
import {
    DEFAULT_LIGHTNING_FILTERS,
    SEGMENT_FILTERS,
} from "@app/viz3/baseLightningViz/state/baseLightningViz.constants";
import { ROAD_TYPES } from "@common/features/zonesManager/state/zonesManager.constants";

export const RV_SEGMENT_FILTERS_LIST = [
    SEGMENT_FILTERS.ALL,
    SEGMENT_FILTERS.COUNTY,
    SEGMENT_FILTERS.ROAD_CLASSIFICATIONS,
];
export type TRVSegmentFilters = typeof RV_SEGMENT_FILTERS_LIST[number];

export const DEFAULT_MAP_LAYERS_VISIBILITY = {
    [VIZ_MAP_LAYERS.LABEL.code]: true,
    [VIZ_MAP_LAYERS.LEGEND.code]: true,
};

export const RV_VIZ_DEFAULTS = {
    filters: {
        ...BASE_VIZ_DEFAULTS.filters,
        ...DEFAULT_LIGHTNING_FILTERS,
        selectedRoad: null,
    },
    visualization: {
        ...BASE_VIZ_DEFAULTS.visualization,
        mapLayersVisibility: DEFAULT_MAP_LAYERS_VISIBILITY,
        selectedRoadwayHashIds: [],
        selectedRoadwaySegmentIds: [],
    },
};

export const SORT_DIRECTION = {
    ASC: "asc",
    DESC: "desc",
} as const;

export type TSortDirection = typeof SORT_DIRECTION[keyof typeof SORT_DIRECTION];

export const SORT_TYPE = {
    NAME: "name",
    TIME_PERIOD: "time_period",
} as const;

export type TSortType = typeof SORT_TYPE[keyof typeof SORT_TYPE];

export const ROADWAY_ID_PROPERTIES = {
    SEGMENT: "zone_id",
    ROADWAY: "roadway_hash_id",
};

export const COUNTIES_COUNT_VIEW_THRESHOLD = 5;
export const CITIES_COUNT_VIEW_THRESHOLD = 5;

export const GENERIC_ROADWAY_NAME = {
    [ROAD_TYPES.MOTORWAY.id]: "Motorways",
    [ROAD_TYPES.TRUNK.id]: "Trunks",
    [ROAD_TYPES.PRIMARY.id]: "Primaries",
    [ROAD_TYPES.SECONDARY.id]: "Secondaries",
    [ROAD_TYPES.TERTIARY.id]: "Tertiaries",
};

export const RV_MIN_INTERACTIVE_ZOOM = 5;
