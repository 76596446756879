import classnames from "classnames";
import type { Map } from "mapbox-gl";
import type { TControlConfig } from "@common/components/baseMap/baseMap.types";
import { addContentToButton, createButton } from "../customControls.helpers";

export class MeasurementToolControl {
    _map!: Map | undefined;
    _container!: HTMLDivElement;
    _showMeasurementTool: boolean = false;
    _position: TControlConfig["position"];
    _onAdd: () => void;
    _onClick: (isOpen: boolean) => void;
    _button!: HTMLButtonElement;
    _disabled: boolean = false;

    measurementToolContainer!: HTMLDivElement;

    constructor({
        onAdd,
        position,
        onClick,
    }: {
        onAdd: () => void;
        position: TControlConfig["position"];
        onClick: (isOpen: boolean) => void;
    }) {
        this._position = position;
        this._onAdd = onAdd;
        this._onClick = onClick;
    }

    handleOnClick = () => this._onClick(this._showMeasurementTool);

    onAdd = (map: Map) => {
        this._map = map;
        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group measurement-tool-control";

        this._button = createButton({
            className: "mapboxgl-measurement-tool-button",
            tooltip: "Configure measurement tool",
            onClick: this.onShowMeasurementTool.bind(this),
        });
        addContentToButton({ button: this._button, tooltip: "Configure measurement tool" });

        this.measurementToolContainer = document.createElement("div");
        this.measurementToolContainer.className = classnames(
            "measurement-tool-wrapper",
            !this._showMeasurementTool && "hidden",
        );

        this._container.appendChild(this._button);
        this._container.appendChild(this.measurementToolContainer);
        this._container.addEventListener("click", this.handleOnClick);

        this._onAdd();

        return this._container;
    };

    onRemove = () => {
        this._container.removeEventListener("click", this.handleOnClick);
        this._container.parentNode?.removeChild(this._container);
        this._map = undefined;
    };

    onShowMeasurementTool = () => {
        this._showMeasurementTool = !this._showMeasurementTool;
        this.measurementToolContainer.classList.toggle("hidden", !this._showMeasurementTool);
    };

    setDisable = (isDisabled: boolean) => {
        this._disabled = isDisabled;
        if (this._disabled) {
            this._button.setAttribute("disabled", "");
        } else {
            this._button.removeAttribute("disabled");
        }
    };
}
