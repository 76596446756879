import { Fragment, MutableRefObject, useRef, ReactNode, WheelEvent, useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons";
import { useMenuFocus } from "@common/hooks/useMenuFocus";
import "./tabs.less";

export interface ITab {
    id: string;
    name: ReactNode;
    [key: string]: unknown;
}

type TOptionProps = {
    tab: ITab;
    text: ReactNode;
    index: number;
    active: boolean;
    isDisabled: boolean;
    children: ReactNode;
    onSelect: (id: string) => void;
    tabRefs: MutableRefObject<Array<HTMLElement | null>>;
};

const NavLinkOption = ({
    children,
    tab,
    index,
    active,
    tabRefs,
    text,
    onSelect,
    isDisabled,
}: TOptionProps) => {
    return (
        <NavLink
            role="tab"
            aria-selected={active}
            to={tab.path as string}
            className="tabLink"
            activeClassName="selected-link"
            aria-label={`${active ? "Current Tab: " : ""}${text}`}
            ref={node => {
                tabRefs.current[index] = node;
            }}
        >
            {children}
        </NavLink>
    );
};

const ButtonOption = ({
    children,
    tab,
    index,
    active,
    tabRefs,
    text,
    onSelect,
    isDisabled,
}: TOptionProps) => {
    return (
        <button
            type="button"
            role="tab"
            aria-selected={active}
            className="tabLink"
            disabled={isDisabled}
            aria-label={`${active ? "Current Tab: " : ""}${text}`}
            onClick={() => onSelect(tab.id)}
            tabIndex={active ? 0 : -1}
            aria-controls={tab.id}
            id={`${tab.id}-tab`}
            ref={node => {
                tabRefs.current[index] = node;
            }}
        >
            {children}
        </button>
    );
};

interface TCommonProps {
    tabs: Array<ITab>;
    tabsDescription?: string;
}

interface IPropsForNavLinkOption extends TCommonProps {
    isRouteBased: boolean;
    activeTabId?: undefined;
    onSelect?: undefined;
    isVertical?: boolean;
    isCollapsible?: boolean;
}

interface IPropsForButtonOption extends TCommonProps {
    activeTabId: string;
    onSelect: (value: string) => void;
    isRouteBased?: undefined;
    isVertical?: boolean;
    isCollapsible?: boolean;
}

export const StlTabs = ({
    activeTabId = "",
    tabs,
    onSelect = (value: string) => {},
    isRouteBased = false,
    tabsDescription = "",
    isVertical = false,
    isCollapsible = false,
}: IPropsForNavLinkOption | IPropsForButtonOption) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const { activeOptionIndex, tabRefs, handleKeyDown, handleClick } = useMenuFocus({
        tabs,
        activeTabId,
        onSelect,
        isRouteBased,
    });

    const tabListRef = useRef<HTMLUListElement | null>(null);

    const OptionComponent = isRouteBased ? NavLinkOption : ButtonOption;

    const options = tabs
        .filter(tab => !tab.hidden)
        .map((tab, index) => {
            const disabled = tab.isDisabled as boolean;
            const active = activeOptionIndex === index;
            const text = tab.name;
            const hasErrors = tab.hasErrors;

            return (
                <Fragment key={tab.id}>
                    <li key={tab.id} className={classNames("tabItem", { active, disabled })}>
                        <OptionComponent
                            tab={tab}
                            index={index}
                            active={active}
                            tabRefs={tabRefs}
                            text={text}
                            onSelect={onSelect}
                            isDisabled={disabled}
                        >
                            {text}
                            {hasErrors && (
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    title="Please fix validation errors"
                                />
                            )}
                        </OptionComponent>
                    </li>
                    {tab.shouldAddSeparator && <div className="separator" />}
                </Fragment>
            );
        });

    const handleTabListScroll = (e: WheelEvent<HTMLUListElement>) => {
        if (!tabListRef.current) return;

        const scrollAmount = 100;

        if (e.deltaY > 0) {
            tabListRef.current?.scrollBy({ left: scrollAmount, top: 0, behavior: "smooth" });
        } else {
            tabListRef.current?.scrollBy({ left: -scrollAmount, top: 0, behavior: "smooth" });
        }
    };

    return (
        <nav
            className={classNames("stl-tabs", {
                vertical: isVertical,
                collapsed: isCollapsible && isCollapsed,
            })}
            role="presentation"
            onKeyDown={handleKeyDown}
            onClick={handleClick}
        >
            <ul
                role="tablist"
                aria-label={tabsDescription}
                className="nav navigation-tabs"
                ref={tabListRef}
                onWheel={handleTabListScroll}
            >
                {options}
            </ul>
            {isVertical && isCollapsible && (
                <div className="nav-buttons">
                    <button
                        type="button"
                        className="nav-button"
                        aria-label="Expand or Collapse Menu"
                        aria-expanded={isCollapsed}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronLeft} />
                    </button>
                </div>
            )}
        </nav>
    );
};
