import type { Map } from "mapbox-gl";
import type { TBaseMaps } from "@common/components/baseMap/baseMap.types";

export class BaseMapService {
    maps: TBaseMaps = {};
    defaultMapId = "base-map";

    setMap(map: Map, id: string = this.defaultMapId) {
        this.maps[id] = map;
    }

    getMap(id: string = this.defaultMapId) {
        return this.maps[id];
    }

    clearMap(id: string = this.defaultMapId) {
        this.maps[id] = null;
    }

    findMapControl(map: Map, ControlClass: unknown) {
        // @ts-ignore Property '_controls' does not exist on type 'Map'.
        return map?._controls.find(control => control instanceof ControlClass);
    }
}

export const baseMapService = new BaseMapService();
