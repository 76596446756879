import ReactDOM from "react-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import "@common/services/logger.service";
import { msalConfig } from "@app/sso/authConfig";
import { App } from "@app/app";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(<App msalInstance={msalInstance} />, document.getElementById("root"));
