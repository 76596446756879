import { MAP_STYLES } from "@app/viz3/odmfVisualization/map/mapStyles.constants";
import { ODMF_LAYERS } from "@app/viz3/odmfVisualization/state/odmfViz.constants";

export const ODMF_VIZ_INITIAL_STATE = {
    map: {
        clickedZone: null,
        layerConfigurations: {
            [ODMF_LAYERS.ORIGIN.code]: {
                colors: {
                    high: MAP_STYLES[ODMF_LAYERS.ORIGIN.code].COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES[ODMF_LAYERS.ORIGIN.code].COLOR.LOW,
                    hover: MAP_STYLES[ODMF_LAYERS.ORIGIN.code].COLOR.HOVER,
                },
                opacity: MAP_STYLES[ODMF_LAYERS.ORIGIN.code].OPACITY,
            },
            [ODMF_LAYERS.DESTINATION.code]: {
                colors: {
                    high: MAP_STYLES[ODMF_LAYERS.DESTINATION.code].COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES[ODMF_LAYERS.DESTINATION.code].COLOR.LOW,
                    hover: MAP_STYLES[ODMF_LAYERS.DESTINATION.code].COLOR.HOVER,
                },
                opacity: MAP_STYLES[ODMF_LAYERS.DESTINATION.code].OPACITY,
            },
            [ODMF_LAYERS.MIDDLE_FILTER.code]: {
                colors: {
                    high: MAP_STYLES[ODMF_LAYERS.MIDDLE_FILTER.code].COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES[ODMF_LAYERS.MIDDLE_FILTER.code].COLOR.LOW,
                    hover: MAP_STYLES[ODMF_LAYERS.MIDDLE_FILTER.code].COLOR.HOVER,
                },
                opacity: MAP_STYLES[ODMF_LAYERS.MIDDLE_FILTER.code].OPACITY,
            },
        },
    },
    distributionLayers: null,
    preSelectedZones: [],
};
