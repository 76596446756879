import { useMemo, useState, useEffect, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { ClickAwayListener } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { useAppSelector } from "@app/store/hooks";
import {
    getHasAccessToInSight,
    getHasAccessToInSightGo,
    getHasAccessToInSightSafety,
} from "@app/store/currentUser/currentUser.selector";
import { PLATFORM_ACCESS_TYPES } from "@common/constants/orgTypes.constants";
import { ROUTES } from "@common/constants/routes.constants";
import { StlButton } from "@common/components/button/button";
import "./platformSwitcher.less";

const FADE_OUT_CLASS = "fade-out" as const;
const OPTIONAL_PLATFORM_TYPES_ID = "optional-platform-types" as const;

const PLATFORM_TYPES = {
    INSIGHT: PLATFORM_ACCESS_TYPES.INSIGHT,
    LITE: PLATFORM_ACCESS_TYPES.LITE,
    SAFETY: {
        value: "insight_prioritize",
        label: "Prioritize",
    },
};

type TPlatformType = typeof PLATFORM_TYPES[keyof typeof PLATFORM_TYPES];

const INITIAL_STATE = {
    isOpen: false,
    fadeOut: false,
} as const;

export const PlatformSwitcher = () => {
    const [isOpen, setIsOpen] = useState<boolean>(INITIAL_STATE.isOpen);
    const [fadeOut, setFadeOut] = useState<boolean>(INITIAL_STATE.fadeOut);

    const accessToInSight = useAppSelector(getHasAccessToInSight);
    const accessToInSightGo = useAppSelector(getHasAccessToInSightGo);
    const accessToInSightSafety = useAppSelector(getHasAccessToInSightSafety);

    const { pathname } = useLocation();
    const history = useHistory();

    const isGoPlatform = pathname.startsWith(ROUTES.PIRATE_SHIP);
    const isSafetyPlatform = pathname.startsWith(ROUTES.SAFETY);

    const handleSelectAccessType = useCallback(
        (value: TPlatformType["value"]) => {
            switch (value) {
                case PLATFORM_TYPES.LITE.value:
                    history.push(ROUTES.PIRATE_SHIP);
                    break;
                case PLATFORM_TYPES.SAFETY.value:
                    history.push(ROUTES.SAFETY);
                    break;
                case PLATFORM_TYPES.INSIGHT.value:
                default:
                    history.push(ROUTES.DEFAULT);
            }
        },
        [history],
    );

    const availablePlatformTypes = useMemo(() => {
        const platformTypes = [];
        if (accessToInSight) {
            platformTypes.push(PLATFORM_TYPES.INSIGHT);
        }
        if (accessToInSightGo) {
            platformTypes.push(PLATFORM_TYPES.LITE);
        }
        if (accessToInSightSafety) {
            platformTypes.push(PLATFORM_TYPES.SAFETY);
        }
        return platformTypes;
    }, [accessToInSight, accessToInSightGo, accessToInSightSafety]);

    const { selectedType, optionalTypes, handleClick } = useMemo(() => {
        if (!availablePlatformTypes.length) {
            return {
                selectedType: null,
                optionalTypes: [],
                handleClick: () => {},
            };
        } else if (availablePlatformTypes.length === 1) {
            return {
                selectedType: availablePlatformTypes[0],
                optionalTypes: [],
                handleClick: handleSelectAccessType,
            };
        }

        const getOptionalTypes = (curType: TPlatformType) => {
            return availablePlatformTypes.filter(
                platformType => platformType.value !== curType.value,
            );
        };

        if (isGoPlatform) {
            return {
                selectedType: PLATFORM_TYPES.LITE,
                optionalTypes: getOptionalTypes(PLATFORM_TYPES.LITE),
                handleClick: handleSelectAccessType,
            };
        } else if (isSafetyPlatform) {
            return {
                selectedType: PLATFORM_TYPES.SAFETY,
                optionalTypes: getOptionalTypes(PLATFORM_TYPES.SAFETY),
                handleClick: handleSelectAccessType,
            };
        }

        return {
            selectedType: PLATFORM_TYPES.INSIGHT,
            optionalTypes: getOptionalTypes(PLATFORM_TYPES.INSIGHT),
            handleClick: handleSelectAccessType,
        };
    }, [isGoPlatform, isSafetyPlatform, handleSelectAccessType, availablePlatformTypes]);

    const handleClose = () => {
        if (isOpen) setFadeOut(true);
    };

    const handleOpening = () => {
        if (!optionalTypes.length) return;

        if (!isOpen) {
            setIsOpen(true);
            return;
        }

        handleClose();
    };

    const handleAnimationEnd = useCallback(() => {
        setIsOpen(INITIAL_STATE.isOpen);
        setFadeOut(INITIAL_STATE.fadeOut);
    }, []);

    useEffect(() => {
        if (!fadeOut || !isOpen) return undefined;

        const optionalPlatformType = document.getElementById(OPTIONAL_PLATFORM_TYPES_ID);

        if (!optionalPlatformType) return undefined;

        optionalPlatformType.classList.add(FADE_OUT_CLASS);

        optionalPlatformType.addEventListener("animationend", handleAnimationEnd);
        return () => optionalPlatformType?.removeEventListener("animationend", handleAnimationEnd);
    }, [isOpen, fadeOut, handleAnimationEnd]);

    if (!selectedType) {
        return null;
    }

    return (
        <div className="stl-platform-switcher" data-testid="platform-switcher-wrapper">
            {availablePlatformTypes.length === 1 ? (
                <img
                    data-testid="platform-logo-img"
                    className="platform-logo"
                    src={`/img/platform-logo/${selectedType.value}.svg`}
                    alt="platform-logo"
                    onClick={() => handleClick(selectedType.value)}
                />
            ) : (
                <ClickAwayListener onClickAway={handleClose}>
                    <div className="switch-wrapper" data-testid="platform-switcher">
                        <StlButton
                            className="platform-switcher-btn"
                            endIcon={
                                optionalTypes.length ? (
                                    <FontAwesomeIcon icon={faCaretDown} />
                                ) : undefined
                            }
                            onClick={handleOpening}
                            aria-label={selectedType.label}
                            data-testid="selected-platform-type-btn"
                        />
                        {isOpen && (
                            <div
                                id={OPTIONAL_PLATFORM_TYPES_ID}
                                className="platform-switcher-options"
                            >
                                {optionalTypes.map(option => (
                                    <StlButton
                                        key={option.value}
                                        className="platform-switcher-btn optional-type"
                                        onClick={() => handleClick(option.value)}
                                        aria-label={option.label}
                                        data-testid="optional-platform-type-btn"
                                    >
                                        <img
                                            className="platform-logo"
                                            src={`/img/platform-logo/${option.value}.svg`}
                                            data-testid="optional-platform-type-logo"
                                            alt="optional-logo"
                                        />
                                    </StlButton>
                                ))}
                            </div>
                        )}
                    </div>
                </ClickAwayListener>
            )}
        </div>
    );
};
