import { Map, Layer } from "mapbox-gl";
import { LineString } from "geojson";

export const isLabelLayer = (layer: Layer) =>
    layer.type === "symbol" && (layer.id.includes("label") || layer.id.includes("shield"));

export const getFirstLabelLayerId = (map: Map) => {
    const { layers = [] } = map.getStyle();
    return layers.find(isLabelLayer)?.id;
};

export const removeSource = (map: Map, sourceId: string | null) => {
    if (!map || !sourceId || !map.getSource(sourceId)) return;

    map.getStyle().layers?.forEach((layer: Layer) => {
        if (layer.source === sourceId) {
            map.removeLayer(layer.id);
        }
    });
    map.removeSource(sourceId);
};

// Match expression will fail if we pass an empty array
export const toSafeMatchArray = (items?: Array<any> | null) => {
    if (!items?.length) {
        return "";
    } else {
        return items;
    }
};

export const toMultipleFilters = (filters: Array<string> = []) => {
    if (!filters?.length) return ["all"];

    const isSingleFilter = filters[0] !== "all";

    if (isSingleFilter) {
        return ["all", filters];
    } else {
        return filters;
    }
};

export const toMatchZonesFilter = (
    shouldMatchZones: boolean,
    zoneIds: Array<number | string> | null,
    zoneType?: string,
) => {
    const _selectedZoneIds = toSafeMatchArray(zoneIds);
    const zoneIdsFilter = [
        "match",
        ["get", "zone_id"],
        _selectedZoneIds,
        shouldMatchZones,
        !shouldMatchZones,
    ];

    if (zoneType !== null && zoneType !== undefined) {
        return ["match", ["get", "zone_type"], zoneType, zoneIdsFilter, false];
    }

    return zoneIdsFilter;
};

export const getAngle = (coordinates: LineString["coordinates"]) => {
    if (!coordinates) return 0;

    const width = coordinates[1][0] - coordinates[0][0];
    const height = coordinates[1][1] - coordinates[0][1];
    const angle = Math.round(Math.abs((Math.atan(height / width) * 180) / Math.PI));

    if (width > 0 && height > 0) return 90 - angle;
    if (width > 0 && height < 0) return 90 + angle;
    if (width < 0 && height > 0) return 270 + angle;
    if (width < 0 && height < 0) return 270 - angle;

    return 0;
};
