import { createSelector } from "reselect";
import type { TRootState } from "@app/store";
import { getZonesByZoneKind } from "@common/features/zones/zones.helpers";
import { TZonesManagerZone } from "@common/features/zonesManager/zonesManager.types";

export const getZoneSetModalState = (state: TRootState) => state.zoneSetModal;

export const getZoneSetMap = (state: TRootState) => getZoneSetModalState(state).map;
export const getUiStates = (state: TRootState) => getZoneSetModalState(state).uiStates;
export const getZoneSet = (state: TRootState) => getZoneSetModalState(state).zoneSet;
export const getZoneSetName = (state: TRootState) => getZoneSetModalState(state).zoneSetName;
export const getHoveredZone = (state: TRootState) => getZoneSetMap(state).hoveredZone;
export const getSelectedZoneIds = (state: TRootState) =>
    getZoneSetModalState(state).selectedZoneIds;
export const getMapError = (state: TRootState) => getZoneSetMap(state).mapError;
export const getOtherZoneSet = (state: TRootState) => getZoneSetModalState(state).otherZoneSet;

export const getShouldPreserveHoveredZone = createSelector(getHoveredZone, hoveredZone =>
    Boolean(hoveredZone?.shouldPreserve),
);

export const getBoundingBoxZones = createSelector(
    getZoneSet,
    getOtherZoneSet,
    (zoneSet, otherZoneSet) => {
        const currentZoneSetZones = zoneSet.zones || [];
        const otherZoneSetZones = otherZoneSet?.zones || [];

        return getZonesByZoneKind([...currentZoneSetZones, ...otherZoneSetZones] as Pick<
            TZonesManagerZone,
            "zone_id" | "zone_kind_id"
        >[]);
    },
);

export const hasZoneSetPendingChanges = createSelector(
    getZoneSet,
    getZoneSetName,
    (zoneSet, zoneSetName) => {
        return zoneSetName.isEditMode && zoneSetName.value !== zoneSet.set_name;
    },
);
