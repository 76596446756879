import { merge } from "lodash-es";
import moment from "moment";
import hash from "object-hash";
import { arrayIncludes } from "@common/utils/arrayIncludes";
import {
    regionToGeometry,
    geometryToDrawnRegion,
} from "@common/components/baseMap/baseMap.helpers";
import { IStudyAPI } from "@common/services/server/studiesApi.types";
import { EStudyTypes, IStudy } from "@common/features/studyModal/common/study.types";
import { EMeasurementUnits } from "@common/features/orgs/orgs.types";
import { MEASUREMENT_UNITS } from "@common/constants/measurementUnits.constants";
import {
    MAX_REGION_BUFFER_KILOMETER_SIZE,
    MAX_REGION_BUFFER_MILE_SIZE,
} from "@common/constants/subscription.constants";
import {
    INITIAL_STUDY_STATE_OBJ,
    STUDY_MAP_DEFAULT_ZOOM,
    ENTERPRISE_STUDY_TYPES,
    ENTERPRISE_STUDY_TYPE_OPTIONS,
} from "./study.constants";

const getStudyRegion = (study: IStudy) => {
    // Region was uploaded from shapefile
    if (study.stagedRegionId) {
        return {
            staged_region_id: study.stagedRegionId,
        };
    }

    if (!study?.geometry) return null;
    // New study or user has drawn a region
    if (!study.studyId || study.geometry.isDrawn) {
        return {
            drawn_region: geometryToDrawnRegion(study.geometry),
        };
    }

    // Existing study is updated and region is not changed
    return {
        region: {
            buffer: study.buffer,
            geometry: geometryToDrawnRegion(study.geometry),
        },
    };
};

const getBuffer = (study: IStudy) => {
    if (!study?.geometry) return null;

    return {
        study_area_buffer: study.buffer,
        measurement_unit: study.measurementUnit,
    };
};

const getEnterpriseData = (study: IStudy) => {
    return study.studyType === ENTERPRISE_STUDY_TYPES.Pursuit.value
        ? {
              bp_code: study.bpCode,
              charge_code: null,
              task_number: null,
          }
        : {
              bp_code: null,
              charge_code: study.chargeCode,
              task_number: study.taskNumber,
          };
};

export const getDefaultMapCenter = (isUS: boolean, isCA: boolean) => {
    let country = "US";
    if (isUS && isCA) {
        country = "US_CA";
    } else if (isCA) {
        country = "CA";
    }
    const { center, zoom } =
        STUDY_MAP_DEFAULT_ZOOM[country as keyof typeof STUDY_MAP_DEFAULT_ZOOM];

    return { center, zoom };
};

export const studyDataToState = (study: IStudyAPI) =>
    merge(
        { ...INITIAL_STUDY_STATE_OBJ },
        {
            studyId: study.study_id,
            studyType: study.study_type,
            startDate: moment(study.start_date).valueOf(),
            endDate: moment(study.end_date).valueOf(),
            nonEditableStartDate: moment(
                study.non_editable_start_date || study.start_date,
            ).valueOf(),
            name: study.study_name,
            endClient: study.end_client,
            rfpId: study.rfp_id,
            geometry: regionToGeometry(study.region),
            buffer: study.region?.buffer,
            status: study.status,
            notes: study.notes || "",
            taskNumber: study.task_number,
            chargeCode: study.charge_code,
            bpCode: study.bp_code,
            subscriptionZoneBand: study.subscription_zone_band,
            subscriptionTier: study.subscription_tier,
            measurementUnit: study.region?.measurement_unit || study.measurement_unit,
        },
    );

export const studyStateToData = (study: IStudy, isEnterpriseStudy: boolean) => ({
    ...(study.studyId && { study_id: study.studyId }),
    ...(isEnterpriseStudy && getEnterpriseData(study)),
    ...getStudyRegion(study),
    ...getBuffer(study),
    study_type: study.studyType as EStudyTypes,
    study_name: study.name,
    update_end_date: moment(study.endDate).format("YYYY-MM-DD 00:00:00 Z"),
    update_start_date: moment(study.startDate).format("YYYY-MM-DD 00:00:00 Z"),
    end_client: study.endClient || INITIAL_STUDY_STATE_OBJ.endClient,
    rfp_id: study.rfpId || INITIAL_STUDY_STATE_OBJ.rfpId,
    notes: study.notes,
    subscription_zone_band: study.subscriptionZoneBand,
    subscription_tier: study.subscriptionTier,
});

export const getStudyHash = (study: IStudy) => {
    const _study = {
        endClient: study.endClient,
        rfpId: study.rfpId,
        notes: study.notes,
        subscriptionZoneBand: study.subscriptionZoneBand,
        subscriptionTier: study.subscriptionTier,
    };

    return hash(_study);
};

export const getIsEnterpriseStudy = (study: IStudy) =>
    ENTERPRISE_STUDY_TYPE_OPTIONS.some(studyType => {
        return studyType.value === study.studyType;
    });

export const getIsProjectNumberRequired = (study: IStudy) =>
    arrayIncludes(
        [ENTERPRISE_STUDY_TYPES.Marketing_Conf.value, ENTERPRISE_STUDY_TYPES.Active_Project.value],
        study.studyType,
    );

export const getIsBPNumberRequired = (study: IStudy) =>
    study.studyType === ENTERPRISE_STUDY_TYPES.Pursuit.value;

export const getMaxBufferSize = (measurementUnit?: EMeasurementUnits | null | "") => {
    return measurementUnit === MEASUREMENT_UNITS.KILOMETERS.id
        ? MAX_REGION_BUFFER_KILOMETER_SIZE
        : MAX_REGION_BUFFER_MILE_SIZE;
};
