import {
    TEnhancedLayer,
    TFEMASubLayer,
} from "@common/features/mapEnhancedLayers/state/mapEnhancedLayers.constants";

export const MIN_INTERACTIVE_ZOOM = {
    DEFAULT: 9,
    OSM: 13,
    RAIL: 8,
    LINE_GATES: 13,
    DAC_ZONES: 7,
    FEMA_ZONES: 7,
};

export const OSM_LAYERS = {
    OSM_VEHICLE: {
        code: "vehicle",
        layerCode: "OSM_VEHICLE",
        display: "Vehicle",
        legend: {
            style: "solid #FF00A8",
        },
        features: [
            "personal_all_vehicle_travel",
            "commercial_truck_travel",
            "personal_gps_travel",
        ],
    },
    OSM_BICYCLE: {
        code: "bicycle",
        layerCode: "OSM_BICYCLE",
        display: "Bicycle",
        legend: {
            style: "dashed #00F0FF",
        },
        features: ["personal_bike_travel"],
    },
    OSM_PEDESTRIAN: {
        code: "pedestrian",
        layerCode: "OSM_PEDESTRIAN",
        display: "Pedestrian",
        legend: {
            style: "dotted #FF7A00",
        },
        features: ["personal_ped_travel"],
    },
} as const;

export const OSM_LAYERS_LIST = Object.values(OSM_LAYERS);
export type TOSMLayer = typeof OSM_LAYERS[keyof typeof OSM_LAYERS];

export const OSM_LAYER_STYLES = {
    [OSM_LAYERS.OSM_VEHICLE.layerCode]: {
        line: {
            "line-color": "#FF00A8",
            "line-width": 2,
            "line-opacity": 1,
        },
    },
    [OSM_LAYERS.OSM_BICYCLE.layerCode]: {
        line: {
            "line-color": "#00F0FF",
            "line-width": 1.5,
            "line-opacity": 1,
            "line-dasharray": [8, 4],
            "line-offset": 4,
        },
    },
    [OSM_LAYERS.OSM_PEDESTRIAN.layerCode]: {
        line: {
            "line-color": "#FF7A00",
            "line-width": 2,
            "line-opacity": 1,
            "line-dasharray": [4, 4],
            "line-offset": -4,
        },
    },
};

export type TMapLayersLocalState = {
    osmLayersCategories: Record<string, string[]>;
    enhancedLayersCategories: {
        enabled: boolean;
        category: TEnhancedLayer["code"];
        nriSubLayer: TFEMASubLayer["id"];
        nhlSubLayer: TFEMASubLayer["id"];
    };
};
