import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { ISegmentLayers } from "@app/viz3/ctVisualization/state/ctViz.types";
import { CT_VIZ_INITIAL_STATE, ICTVizState } from "./ctViz.state";

export const { actions, reducer } = createSlice({
    name: "ctViz",
    initialState: CT_VIZ_INITIAL_STATE,
    reducers: {
        setSegmentLayers: (state, action: PayloadAction<ISegmentLayers>) => {
            state.segmentLayers = action.payload;
        },
        setCTVizState: (state, action: PayloadAction<ICTVizState>) => {
            return action.payload;
        },
        resetCTVizState: () => {
            return CT_VIZ_INITIAL_STATE;
        },
    },
});

export const ctVizReducer = reducer;
