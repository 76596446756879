import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DRAFT_INITIAL_STATE } from "@app/analysis/draftAnalysis/state/draftAnalysis.state";

export const { actions, reducer } = createSlice({
    name: "draftAnalysisReducer",
    initialState: DRAFT_INITIAL_STATE,
    reducers: {
        setDraftConfig: (state, action: PayloadAction<string | number>) => {
            return {
                isDraft: true,
                draftId: action.payload,
            };
        },
        resetDraftConfig: () => {
            return DRAFT_INITIAL_STATE;
        },
    },
});

export const draftAnalysisReducer = reducer;
