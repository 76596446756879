export const ORG_TYPES = {
    SUBSCRIPTION: { id: "subscription", name: "Subscription" },
    MODULAR: { id: "modular", name: "Modular" },
    TRIAL_WEEK: { id: "trial_week", name: "Free Trial" },
    TRIAL_MONTH: { id: "trial_month", name: "Evaluation" },
    INTERNAL: { id: "internal", name: "Internal" },
    INVESTOR: { id: "investor", name: "Investor" },
    PARTNER: { id: "partner", name: "Partner" },
    PROJECT: { id: "project", name: "Project" },
    ACADEMIC: { id: "academic", name: "Academic" },
    REGIONAL: { id: "regional", name: "Regional" },
    MARKETING_SUBSCRIPTION: { id: "consultant", name: "Marketing Subscription" },
    PAY_PER_USE: { id: "pay_per_use", name: "Pay Per Use" },
    PARENT: { id: "parent_organization", name: "Parent Organization" },
    SANDBOX: { id: "sandbox", name: "Sandbox", shouldHideInFilter: true },
};
export const ORG_TYPES_LIST = Object.values(ORG_TYPES);
export type OrgTypeName = keyof typeof ORG_TYPES;
export type OrgType = typeof ORG_TYPES[keyof typeof ORG_TYPES];

export const STREETLIGHT_ADMIN_ORG_ID = 0;

export const MODULE_TYPES = {
    SOLUTION: { id: "solution", name: "Solution FY'23" },
    SOLUTION_2024: { id: "solution_24", name: "Solution FY'24" },
    MODE: { id: "mode", name: "Mode" },
};
export const MODULE_TYPES_LIST = Object.values(MODULE_TYPES);
export type ModuleTypeName = keyof typeof MODULE_TYPES;
export type ModuleType = typeof MODULE_TYPES[keyof typeof MODULE_TYPES];

export const PLATFORM_ACCESS_TYPES = {
    INSIGHT: {
        value: "insight_full",
        label: "InSight",
    },
    LITE: {
        value: "insight_lite",
        label: "InSight Go",
    },
    INSIGHT_WITH_LITE: {
        value: "insight_full_with_lite",
        label: "InSight with Go",
    },
    LOGIN_ONLY: {
        value: "login_only",
        label: "Sign In Only",
    },
} as const;

export type TPlatformAccessTypes =
    typeof PLATFORM_ACCESS_TYPES[keyof typeof PLATFORM_ACCESS_TYPES];

export const PLATFORM_ACCESS_TYPES_LIST = Object.values(PLATFORM_ACCESS_TYPES);
