import {
    DEVICE_SEGMENTATIONS,
    TRAFFIC_BEHAVIORS,
    INTERSECTION_TYPES,
    INTERSECTION_TYPE_ALL,
} from "@app/viz3/zaVisualization/state/zaViz.constants";

export const getAvailableDeviceSegmentations = (trafficBehavior, analysis) => {
    const metricCode = trafficBehavior?.code;
    const {
        hwl_enable_visitor: enableVisitor,
        hwl_enable_resident: enableResident,
        hwl_enable_worker: enableWorker,
        premiumVHW,
    } = analysis;

    if (!premiumVHW) return [];

    const result = [];
    if (enableVisitor) {
        result.push(DEVICE_SEGMENTATIONS.VISITORS);
    }
    if (
        enableWorker &&
        [TRAFFIC_BEHAVIORS.TRAFFIC_DISTRIBUTION.code, TRAFFIC_BEHAVIORS.HOME_GRIDS.code].includes(
            metricCode,
        )
    ) {
        result.push(DEVICE_SEGMENTATIONS.WORKERS);
    }
    if (
        enableResident &&
        [TRAFFIC_BEHAVIORS.TRAFFIC_DISTRIBUTION.code, TRAFFIC_BEHAVIORS.WORK_GRIDS.code].includes(
            metricCode,
        )
    ) {
        result.push(DEVICE_SEGMENTATIONS.RESIDENTS);
    }

    return result;
};

export const zonesRequiredValidator = (errors, context) => {
    const { filters } = context;

    if (!filters.zone_ids?.length) {
        errors.push("Please select at least one Zone");
    }
    if (!filters.zone_type_ids?.length) {
        errors.push("Please select at least one Trip Intersection filter");
    }

    return errors;
};

export const getIntersectionTypes = (zones, analysis) => {
    const { premiumVHW: isHWLEnabled, zone_intersection_type: intersectionType } = analysis;
    if (isHWLEnabled && intersectionType === "A") {
        return [INTERSECTION_TYPE_ALL];
    }

    return zones.reduce((intersectionTypes, zone) => {
        if (intersectionTypes.length > 2) return intersectionTypes;

        if (zone.is_pass && intersectionTypes.length !== 1) {
            // if there are only pass-through zones, result will have only one type.
            return [...intersectionTypes, INTERSECTION_TYPES.TRIP_PASS_THROUGH];
        } else if (!zone.is_pass && intersectionTypes.length !== 2) {
            return [
                ...intersectionTypes,
                INTERSECTION_TYPES.TRIP_START,
                INTERSECTION_TYPES.TRIP_END,
            ];
        } else {
            return intersectionTypes;
        }
    }, []);
};

export const getInitialIntersectionTypes = (visualization, analysis) => {
    const analysisZones = Object.values(analysis.oz_zones).flat();
    const defaultIntersectionTypes = getIntersectionTypes(analysisZones, analysis);
    const defaultIntersectionTypesCodes = defaultIntersectionTypes.map(type => type.code);

    const configIntersectionTypes = visualization.intersectionTypes?.filter(type =>
        defaultIntersectionTypesCodes.includes(type.code),
    );

    return configIntersectionTypes || defaultIntersectionTypes;
};
