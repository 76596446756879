import { useState, useCallback } from "react";
import { get } from "lodash-es";
import { CRM_ID_REGEX } from "@app/bigBang/admin/orgs/manageOrgModal/manageOrg/manageOrg.constants";
import { getCrmIdFieldFormatWarning } from "@app/bigBang/admin/orgs/manageOrgModal/manageOrg/manageOrg.helpers";
import { IErrors } from "@common/components/formError/formError";

type TFormValue = Record<string, any>;

export type TValidatorFunction<T, K extends keyof T> = (
    value: T[K],
    form: T,
) => false | Record<string, string>;

export type TValidationSchema<T> = {
    [K in keyof Partial<T>]: TValidatorFunction<T, K> | Array<TValidatorFunction<T, K>>;
};

type TFormOptions<T> = {
    validationSchema?: TValidationSchema<T>;
};

const validateProperty = <K extends keyof T, T extends Record<string, any> = TFormValue>(
    value: T[K],
    formValue: T,
    validators: TValidatorFunction<T, keyof T> | Array<TValidatorFunction<T, keyof T>>,
) => {
    const _validators = Array.isArray(validators) ? validators : [validators];

    return _validators.reduce((errors, validatorFn) => {
        const error = validatorFn(value, formValue);

        if (!error) return errors;

        return {
            ...errors,
            ...error,
        };
    }, {});
};

const validateForm = <T extends Record<string, any> = TFormValue>(
    formValue: T,
    validationSchema?: TValidationSchema<T>,
): IErrors | never => {
    if (!validationSchema) throw new Error("Validation schema was not provided.");

    const NO_PROPERTY = "NO_PROPERTY";

    return Object.entries(validationSchema).reduce((formErrors, [path, validators]) => {
        const value = get(formValue, path, NO_PROPERTY) as T[keyof T] | "NO_PROPERTY";

        if (value === NO_PROPERTY) {
            throw new Error(
                `"${path}" doesn't exist at the form value or it's value is not defined.`,
            );
        }

        const errors = validateProperty(value, formValue, validators);

        if (!Object.keys(errors).length) return formErrors;

        return {
            ...formErrors,
            [path]: errors,
        };
    }, {});
};

export const useForm = <T extends Record<string, any> = TFormValue>(
    initialValue: T,
    options: TFormOptions<T> = {},
) => {
    const [formValue, setFormValue] = useState<T>(initialValue || {});

    const setFormField = useCallback(({ name, value }: { name: keyof T; value: T[keyof T] }) => {
        setFormValue(prevValue => ({
            ...prevValue,
            [name]: value,
        }));
    }, []);

    const patchFormValue = useCallback((partialValue: Partial<TFormValue>) => {
        setFormValue(prevValue => ({
            ...prevValue,
            ...partialValue,
        }));
    }, []);

    // TODO: Consider to use some hooks/libs with built-in validation
    const validate = useCallback(() => {
        const errors = validateForm(formValue, options.validationSchema);

        return {
            isValid: !Object.keys(errors).length,
            errors,
        } as {
            isValid: boolean;
            errors: IErrors;
        };
    }, [formValue, options.validationSchema]);

    return {
        formValue,
        setFormField,
        setFormValue,
        patchFormValue,
        validate,
    };
};

export const VALIDATORS = {
    required(message: string = "This field is required") {
        return (value: any) => (value ? false : { required: message });
    },
    invalidCrmOpportunityId(message: string = getCrmIdFieldFormatWarning("CRM Opportunity ID")) {
        return (value: any) =>
            !value || CRM_ID_REGEX.test(value) ? false : { invalidCrmOpportunityId: message };
    },
    invalidCrmStreetlightSubscriptionId(
        message: string = getCrmIdFieldFormatWarning("CRM StreetLight Subscription ID"),
    ) {
        return (value: any) =>
            !value || CRM_ID_REGEX.test(value)
                ? false
                : { invalidCrmStreetlightSubscriptionId: message };
    },
    lessThan(number: number, message: string = `This field should be less than ${number}`) {
        return (value: any) => (isNaN(value) || value > number ? { lessThan: message } : false);
    },
};
