import { useMemo, useState } from "react";
import { StlTabs } from "@common/components";
import { EStudyMode, IStudy, IStudyData } from "@common/features/studyModal/common/study.types";
import { IErrors } from "@common/components/formError/formError";
import { StudyGeneralTab, StudyRegionTab, EnterpriseStudyGeneralTab } from "./tabs";
import "./manageStudy.less";

const createTabs = (isEnterpriseStudy?: boolean, errors: IErrors = {}) => {
    const regionTabHasErrors = errors.buffer || errors.geometry;
    const generalTabHasErrors = errors.studyType || errors.endDate || errors.name;
    const enterpriseGeneralTabHasErrors =
        generalTabHasErrors || errors.endClient || errors.taskNumber || errors.chargeCode;

    return [
        {
            id: "general",
            name: "General",
            hasErrors: isEnterpriseStudy ? enterpriseGeneralTabHasErrors : generalTabHasErrors,
            Component: isEnterpriseStudy ? EnterpriseStudyGeneralTab : StudyGeneralTab,
        },
        {
            id: "region",
            name: "Region",
            hasErrors: regionTabHasErrors,
            Component: StudyRegionTab,
        },
    ];
};

const DEFAULT_TABS = createTabs();

const INITIAL_STATE = {
    activeTabId: DEFAULT_TABS[0].id,
};

type TProps = {
    study: IStudy;
    originalStudy: IStudyData | null;
    mode: EStudyMode;
    closeModal: () => void;
    saveStudy: () => Promise<unknown>;
    updateStudy: (value: Partial<IStudy>) => void;
    isEnterpriseStudy: boolean;
    isValid: boolean;
    isConvertToActiveProject: boolean;
    setIsConvertToActiveProject: (flag: boolean) => void;
    errors: IErrors;
};

export const ManageStudy = ({
    study,
    originalStudy,
    updateStudy,
    saveStudy,
    closeModal,
    errors,
    isValid,
    mode,
    isEnterpriseStudy,
    isConvertToActiveProject,
    setIsConvertToActiveProject,
}: TProps) => {
    const [activeTabId, setActiveTabId] = useState(INITIAL_STATE.activeTabId);

    const tabs = useMemo(() => createTabs(isEnterpriseStudy, errors), [errors, isEnterpriseStudy]);

    const showNextTab = () => {
        const currentTabIndex = tabs.findIndex(tab => tab.id === activeTabId);

        if (currentTabIndex < tabs.length - 1) {
            setActiveTabId(tabs[currentTabIndex + 1].id);
        } else {
            setActiveTabId(INITIAL_STATE.activeTabId);
        }
    };

    const showPreviousTab = () => {
        const currentTabIndex = tabs.findIndex(tab => tab.id === activeTabId);

        if (currentTabIndex > 0) {
            setActiveTabId(tabs[currentTabIndex - 1].id);
        }
    };

    const TabComponent = tabs.find(tab => tab.id === activeTabId)?.Component;

    return (
        <div className="stl-manage-study">
            <StlTabs tabs={tabs} activeTabId={activeTabId} onSelect={setActiveTabId} />
            {TabComponent && (
                <TabComponent
                    study={study}
                    originalStudy={originalStudy}
                    updateStudy={updateStudy}
                    saveStudy={saveStudy}
                    closeModal={closeModal}
                    showNextTab={showNextTab}
                    showPreviousTab={showPreviousTab}
                    errors={errors}
                    isValid={isValid}
                    mode={mode}
                    isConvertToActiveProject={isConvertToActiveProject}
                    setIsConvertToActiveProject={setIsConvertToActiveProject}
                />
            )}
        </div>
    );
};
