import type { TRootState } from "@app/store";

export const getMapEnhancedLayersState = (state: TRootState) => state.mapEnhancedLayers;

export const getSelectedEnhancedLayerZone = (state: TRootState) =>
    getMapEnhancedLayersState(state).selectedEnhancedLayerZone;

export const getHoveredEnhancedLayerZone = (state: TRootState) =>
    getMapEnhancedLayersState(state).hoveredEnhancedLayerZone;

export const getHoveredLegendSwatch = (state: TRootState) =>
    getMapEnhancedLayersState(state).hoveredLegendSwatch;

export const getSelectedVizEnhancedLayer = (state: TRootState) =>
    getMapEnhancedLayersState(state).selectedVizEnhancedLayer;
