import { ReactNode } from "react";
import classNames from "classnames";
import { StlButton } from "@common/components/button/button";
import "./mapErrorAlert.less";

const SERVER_PROBLEM_TEXT = "A problem occurred while requesting data" as const;
export const OUTSIDE_OF_VIEW_ERROR_TEXT = "The map is outside of your subscription region.";

export interface IMapError {
    errorTexts?: Array<string | ReactNode>;
    errorIcon?: ReactNode;
    isServerError?: boolean;
}

type TProps = {
    error?: IMapError | null;
    onHide: () => void;
};

const getErrorMessage = (errorText: string | ReactNode, errorIcon?: ReactNode) => {
    if (errorText === OUTSIDE_OF_VIEW_ERROR_TEXT) {
        return (
            <span>
                <span>{errorText}</span>
                <br />
                <span>Click the</span>
                <img
                    className="init-position-icon"
                    src="/img/init_position_white.svg"
                    alt="position"
                />
                <span>icon to reset your map.</span>
            </span>
        );
    } else if (errorIcon) {
        return (
            <div className="error-with-icon">
                {errorIcon}
                <span className="text">{errorText}</span>
            </div>
        );
    }
    return errorText;
};

export const MapErrorAlert = ({ error, onHide }: TProps) => {
    if (!error || !error.errorTexts?.length) return null;

    return (
        <div
            className={classNames(
                "stl-map-error-alert",
                error.isServerError && "allow-pointer-events",
            )}
            role="alert"
            aria-live="assertive"
        >
            {!!error.isServerError && <p>{`${SERVER_PROBLEM_TEXT}:`}</p>}
            {error.errorTexts?.map(text => (
                <div className="error-text" key={text as string}>
                    {getErrorMessage(text, error.errorIcon)}
                </div>
            ))}
            {!!error.isServerError && (
                <div className="button-wrapper">
                    <StlButton variant="primary" size="sm" onClick={onHide}>
                        OK
                    </StlButton>
                </div>
            )}
        </div>
    );
};
