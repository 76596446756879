import { merge, isEqual } from "lodash-es";
import { VIZ_CODES } from "@app/viz3/base/visualizationsConfiguration";
import { BASE_VIZ_ACTIONS } from "@app/viz3/base/state/baseViz.actionTypes";
import { createZoneSelectOptions } from "@app/viz3/base/state/baseViz.helpers";
import { ODG_VIZ_ACTIONS } from "./odgViz.actionTypes";
import { ODG_VIZ_INITIAL_STATE } from "./odgViz.state";

export const odgVizReducer = (state = ODG_VIZ_INITIAL_STATE, { data, type }) => {
    switch (type) {
        case BASE_VIZ_ACTIONS.RESET_VIZ_STATE: {
            return ODG_VIZ_INITIAL_STATE;
        }

        case BASE_VIZ_ACTIONS.SET_ANALYSIS_COMMON_CONFIG: {
            let newLayerConfig;
            if (data.vizCode === VIZ_CODES.ODG && data.layerConfigurations) {
                newLayerConfig = merge(
                    {},
                    { ...ODG_VIZ_INITIAL_STATE.map.layerConfigurations },
                    { ...data.layerConfigurations },
                );
            } else {
                newLayerConfig = ODG_VIZ_INITIAL_STATE.map.layerConfigurations;
            }

            return {
                ...state,
                map: {
                    ...state.map,
                    layerConfigurations: {
                        ...state.map.layerConfigurations,
                        ...newLayerConfig,
                    },
                },
            };
        }

        case ODG_VIZ_ACTIONS.SET_CLICKED_ZONE_ID: {
            return {
                ...state,
                map: {
                    ...state.map,
                    clickedZoneId: data.zoneId,
                },
            };
        }

        case ODG_VIZ_ACTIONS.SET_PRE_SELECTED_ZONE: {
            return {
                ...state,
                preSelectedZones: data,
            };
        }

        case ODG_VIZ_ACTIONS.SET_DISTRIBUTION_LAYERS: {
            return {
                ...state,
                distributionLayers: data,
            };
        }

        case ODG_VIZ_ACTIONS.SET_LAYER_CONFIGURATION: {
            return {
                ...state,
                map: {
                    ...state.map,
                    layerConfigurations: {
                        ...state.map.layerConfigurations,
                        [data.configurationType]: {
                            ...state.map.layerConfigurations[data.configurationType],
                            ...data.configuration,
                        },
                    },
                },
            };
        }

        case ODG_VIZ_ACTIONS.SET_VIZ_STATE: {
            return {
                ...state,
                ...data.state,
            };
        }

        case ODG_VIZ_ACTIONS.SET_CACHED_ZONES: {
            const trafficBehaviors = Object.keys(data);
            const cachedZones = Object.values(data)
                .flat()
                .reduce(
                    ({ zoneMap, selectedZones }, [zoneId, zoneName]) => {
                        zoneMap[zoneId] = {
                            zone_name: String(zoneName),
                            zone_id: String(zoneId),
                        };
                        selectedZones.push({ ...zoneMap[zoneId] });

                        return { zoneMap, selectedZones };
                    },
                    {
                        zoneMap: {},
                        selectedZones: [],
                    },
                );

            if (
                isEqual(trafficBehaviors, state.cachedZones.trafficBehaviors) &&
                isEqual(cachedZones.zoneMap, state.cachedZones.zoneMap)
            ) {
                return state;
            }

            return {
                ...state,
                cachedZones: {
                    trafficBehaviors,
                    zoneMap: cachedZones.zoneMap,
                    selectedZones: createZoneSelectOptions(cachedZones.selectedZones),
                },
            };
        }

        case ODG_VIZ_ACTIONS.SET_CACHED_ZONES_FILTER: {
            return {
                ...state,
                cachedZones: {
                    ...state.cachedZones,
                    selectedZones: data.value,
                },
            };
        }

        default:
            return state;
    }
};
