export const SUBSCRIPTION_TIER_OPTIONS = [
    { value: "Advanced Analytics", label: "Advanced Analytics" },
    { value: "Basic", label: "Basic" },
    { value: "Bronze", label: "Bronze" },
    { value: "Custom", label: "Custom" },
    { value: "Essentials - All Modes", label: "Essentials - All Modes" },
    { value: "Essentials - Bike/Ped only", label: "Essentials - Bike/Ped only" },
    { value: "Gold", label: "Gold" },
    { value: "Lite", label: "Lite" },
    { value: "Multimode", label: "Multimode" },
    { value: "Platinum", label: "Platinum" },
    { value: "Premium - Detailed Zone Analysis", label: "Premium - Detailed Zone Analysis" },
    { value: "Premium - Traveler", label: "Premium - Traveler" },
    {
        value: "Premium - Traveler Attributes and Detailed Zone Analysis",
        label: "Premium - Traveler Attributes and Detailed Zone Analysis",
    },
    {
        value: "Premium - Traveler Attributes and Quarterly Trends",
        label: "Premium - Traveler Attributes and Quarterly Trends",
    },
    { value: "Premium - Trip", label: "Premium - Trip" },
    {
        value: "Premium - Trip Attributes and Quarterly Trends",
        label: "Premium - Trip Attributes and Quarterly Trends",
    },
    {
        value: "Premium - Trip Attributes and Traveler Attributes",
        label: "Premium - Trip Attributes and Traveler Attributes",
    },
    {
        value: "Premium - Trip and Traveler Attributes and Volume",
        label: "Premium - Trip and Traveler Attributes and Volume",
    },
    {
        value: "Premium - Trip and Traveler Attributes and Detailed Zone Analysis",
        label: "Premium - Trip and Traveler Attributes and Detailed Zone Analysis",
    },
    {
        value: "Premium - Trip and Traveler Attributes and Quarterly Trends",
        label: "Premium - Trip and Traveler Attributes and Quarterly Trends",
    },
    { value: "Retail metrics", label: "Retail metrics" },
    { value: "Silver", label: "Silver" },
    { value: "Travel metrics", label: "Travel metrics" },
    { value: "VMT Monitor", label: "VMT Monitor" },
];

export const MAX_REGION_BUFFER_MILE_SIZE = 100;
export const MAX_REGION_BUFFER_KILOMETER_SIZE = 150;
