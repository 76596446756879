import { useState, useEffect } from "react";
import Moment from "@common/components/moment/moment";
import { useAppSelector } from "@app/store/hooks";
import { formatBooleanYesNo } from "@common/formatters/formatBooleanYesNo";
import { StlCard, StlToggleButton, StlToggleButtonGroup } from "@common/components";
import { useMounted } from "@common/utils/useMounted";
import { UserApiService } from "@common/services/server/userApi.service";
import { OrgApiService } from "@common/services/server/orgApi.service";
import type { IOrgBalanceAPI } from "@common/services/server/orgApi.types";
import type { IProjectActivity, IUserEmailAPI } from "@common/services/server/userApi.types";
import {
    getSubscription,
    getUserInfo,
    isShowExportUniqueZoneInfo,
} from "@app/store/currentUser/currentUser.selector";
import { getGeneral } from "@app/analysis/state/general/general.selectors";
import {
    StlAccountListItem,
    CommonInformation,
    AnalysisInformation,
    PackagesList,
    StatisticsInformation,
    UsersList,
} from "./subscriptionInformation";
import "./accountSubscriptionTab.less";

const VIEW_MODES = {
    packages: {
        id: "packages",
        name: "Packages",
        component: PackagesList,
    },
    users: {
        id: "users",
        name: "Users",
        component: UsersList,
    },
};

type TState = {
    users: IUserEmailAPI[];
    orgBalance: IOrgBalanceAPI;
    projectActivity: IProjectActivity;
    showExportUniqueZoneInfo: boolean;
    viewMode: typeof VIEW_MODES[keyof typeof VIEW_MODES]["id"];
};

const INITIAL_STATE: TState = {
    users: [],
    showExportUniqueZoneInfo: false,
    orgBalance: {} as IOrgBalanceAPI,
    viewMode: VIEW_MODES.packages.id,
    projectActivity: {} as IProjectActivity,
};

export const SubscriptionTab = () => {
    const [users, setUsers] = useState(INITIAL_STATE.users);
    const [orgBalance, setOrgBalance] = useState(INITIAL_STATE.orgBalance);
    const [projectActivity, setProjectActivity] = useState(INITIAL_STATE.projectActivity);
    const [viewModeId, setViewModeId] = useState(INITIAL_STATE.viewMode);
    const [showExportUniqueZoneInfo, setExportUniqueZoneInfo] = useState(
        INITIAL_STATE.showExportUniqueZoneInfo,
    );

    const user = useAppSelector(getUserInfo);
    const _showExportUniqueZoneInfo = useAppSelector(isShowExportUniqueZoneInfo);
    const { runAnalysis } = useAppSelector(getGeneral);
    const { metrics_packages = [] } = useAppSelector(getSubscription);
    const zoneProjectRemain = runAnalysis.zone_project_remain;

    const isMounted = useMounted();

    useEffect(() => {
        if (viewModeId === VIEW_MODES.users.id) {
            UserApiService.getUsers().then(resp => {
                if (isMounted.current) {
                    setUsers(resp.users);
                }
            });
        }
    }, [isMounted, viewModeId]);

    useEffect(() => {
        OrgApiService.getCurrentOrgBalance().then(balance => {
            if (isMounted.current) {
                setOrgBalance(balance.org_balance);
                setExportUniqueZoneInfo(
                    (!!balance.org_balance && balance.org_balance.unique_zone_quota !== null) ||
                        _showExportUniqueZoneInfo,
                );
            }

            if (balance.org_balance?.project_quota !== null) {
                UserApiService.getProjectActivity().then(activity => {
                    if (isMounted.current) {
                        setProjectActivity(activity.project_activity);
                    }
                });
            }
        });
    }, [isMounted, _showExportUniqueZoneInfo]);

    const TabComponent = VIEW_MODES[viewModeId as keyof typeof VIEW_MODES].component;

    return (
        <div className="stl-account-subscription-tab">
            <div className="account-layout">
                <div className="account-half-card">
                    <ul className="simple-list">
                        <StlAccountListItem title="Subscription Name:">
                            {user.org.org_name}
                        </StlAccountListItem>
                        <StlAccountListItem title="Created Date:">
                            <Moment format="MM/DD/YYYY">{user.org.created_date}</Moment>
                        </StlAccountListItem>
                        {user.org.org_type !== "parent_organization" && !!orgBalance && (
                            <>
                                <CommonInformation orgBalance={orgBalance} />
                                <AnalysisInformation
                                    orgBalance={orgBalance}
                                    zoneProjectRemain={zoneProjectRemain}
                                    showExportUniqueZoneInfo={showExportUniqueZoneInfo}
                                />
                                <StlAccountListItem title="Evaluation:">
                                    {formatBooleanYesNo(orgBalance.is_eval)}
                                </StlAccountListItem>
                            </>
                        )}
                    </ul>
                    {!!orgBalance && orgBalance.project_quota && (
                        <div className="usage-statistics-card-wrapper">
                            <StlCard title="Usage Statistics">
                                <StatisticsInformation projectActivity={projectActivity} />
                            </StlCard>
                        </div>
                    )}
                </div>
                <div className="account-half-card">
                    <div className="content-wrapper">
                        <StlToggleButtonGroup
                            value={viewModeId}
                            size="sm"
                            onChange={(e, modeId) => setViewModeId(modeId)}
                            aria-label="Change Analyses View Mode"
                        >
                            {Object.values(VIEW_MODES).map(mode => (
                                <StlToggleButton key={mode.id} id={mode.id} aria-label={mode.name}>
                                    {mode.name}
                                </StlToggleButton>
                            ))}
                        </StlToggleButtonGroup>
                        <TabComponent users={users} metricsPackages={metrics_packages} />
                    </div>
                </div>
            </div>
        </div>
    );
};
