import { useMemo, ReactNode } from "react";
import classNames from "classnames";
import { StlRadio } from "@common/components/radioButton/radio";
import "./radioGroup.less";

type TRegularOption = {
    label: string;
    value: string | number | null;
    [key: string]: unknown;
};

type TProps<TValue, TOption> = {
    value: TValue | null;
    options: Array<TOption>;
    legend?: string;
    disabled?: boolean;
    isInlined?: boolean;
    legendClassName?: string;
    onChange?: (value: TValue) => void;
    getOptionLabel?: (option: TOption) => ReactNode;
    getOptionValue?: (option: TOption) => number | string;
    getIsOptionDisabled?: (option: TOption) => boolean;
};

export const StlRadioGroup = <TValue extends unknown, TOption extends unknown>({
    options,
    value,
    onChange,
    disabled,
    isInlined,
    legend = "",
    legendClassName = "sr-only",
    getOptionLabel = option => (option as TRegularOption).label as ReactNode,
    getOptionValue = option => (option as TRegularOption).value as number | string,
    getIsOptionDisabled = option => !!(option as TRegularOption).disabled as boolean,
}: TProps<TValue, TOption>) => {
    const innerOptions = useMemo(
        () =>
            options.map(option => ({
                label: getOptionLabel(option),
                value: getOptionValue(option),
                disabled: getIsOptionDisabled(option),
            })),
        [options, getOptionLabel, getOptionValue, getIsOptionDisabled],
    );

    return (
        <fieldset className={classNames("stl-radio-group", isInlined && "inlined")}>
            <legend className={legendClassName}>{legend}</legend>
            {innerOptions.map(
                ({ value: optionValue, label: optionLabel, disabled: optionDisabled }) => (
                    <StlRadio
                        key={optionValue}
                        checked={value === optionValue}
                        disabled={disabled || optionDisabled}
                        onChange={() => onChange && onChange(optionValue as TValue)}
                    >
                        {optionLabel}
                    </StlRadio>
                ),
            )}
        </fieldset>
    );
};

StlRadioGroup.displayName = "StlRadioGroup";
