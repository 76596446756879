export enum EZoneType {
    origin = "Origin",
    destination = "Destination",
}

export type TDayPeriod = {
    name: string;
    isReadOnly: boolean;
    start: {
        name: string;
    };
    end: {
        name: string;
    };
};

export type TZoneData = {
    count: string;
    isInvalid: boolean;
    zoneId: string;
    zoneKindId: string;
    zoneName: string;
};

export type TZoneRoleIPFValues = {
    [key: string]: TZoneData;
};

export type TIPFValue = {
    [dayTypeName: string]: {
        [dayPartName: string]: {
            oz: TZoneRoleIPFValues;
            dz: TZoneRoleIPFValues;
        };
    };
};

export type TIPFValuePayload = {
    count: string;
    day_type_name: string;
    day_type: string;
    day_part_name: string;
    day_part: string;
    zone_id: string;
    zone_kind_id: string;
    zone_name: string;
    zone_type: EZoneType;
};
