import {
    BASE_VIZ_DEFAULTS,
    VIZ_MAP_LAYERS,
    VIZ_ZONE_TYPES,
} from "@app/viz3/base/state/baseViz.constants";

export const TMC_ZONE_TYPE = VIZ_ZONE_TYPES.INTERSECTIONS;

export const TMC_LAYERS = {
    GATE: {
        code: "gate",
        label: "Gates",
        hasConfiguration: false,
    },
    ...VIZ_MAP_LAYERS,
} as const;

export type TTMCLayerType = keyof typeof TMC_LAYERS;
export type TTMCLayer = typeof TMC_LAYERS[keyof typeof TMC_LAYERS];

export const DEFAULT_MAP_LAYERS_VISIBILITY = {
    gate: true,
    base: true,
    unselected: false,
    label: true,
    legend: true,
};

export const TRAFFIC_FLOW_OPTIONS = {
    total: { id: "total", name: "Total Traffic" },
    inbound: { id: "inbound", name: "Inbound Traffic" },
    outbound: { id: "outbound", name: "Outbound Traffic" },
} as const;
export type TTrafficFlowOptionKey = keyof typeof TRAFFIC_FLOW_OPTIONS;
export type TTrafficFlowOption = typeof TRAFFIC_FLOW_OPTIONS[keyof typeof TRAFFIC_FLOW_OPTIONS];

export const TRAFFIC_FLOW_OPTIONS_LIST = Object.values(TRAFFIC_FLOW_OPTIONS).map(option => ({
    label: option.name,
    value: option.id,
}));
export type TTrafficFlowOptionList = typeof TRAFFIC_FLOW_OPTIONS_LIST;

export const DAY_PART_TYPE_OPTIONS = {
    CUSTOM_RANGE: {
        value: "CUSTOM_RANGE",
        label: "Custom Range",
        tooltip: "",
    },
    PEAK_HOURS: {
        value: "PEAK_HOURS",
        label: "StL Peak Hours",
        tooltip:
            "StL Peak Hours are calculated from a full 24 hour period and include AM and PM ranges.",
    },
} as const;
export const DAY_PART_TYPE_OPTIONS_LIST = Object.values(DAY_PART_TYPE_OPTIONS);
export type TDayPartTypeOptionKey = keyof typeof DAY_PART_TYPE_OPTIONS;
export type TDayPartTypeOption = typeof DAY_PART_TYPE_OPTIONS[keyof typeof DAY_PART_TYPE_OPTIONS];

export const PEAK_HOUR_OPTIONS = {
    AM: {
        value: "am",
        label: "Peak AM",
    },
    PM: {
        value: "pm",
        label: "Peak PM",
    },
} as const;
export const PEAK_HOUR_OPTIONS_LIST = Object.values(PEAK_HOUR_OPTIONS);
export type TPeakHourOptionKey = keyof typeof PEAK_HOUR_OPTIONS;
export type TPeakHourOption = typeof PEAK_HOUR_OPTIONS[keyof typeof PEAK_HOUR_OPTIONS];

export const PEAK_HOUR_FILTER = {
    filterName: "peak_hours",
};

export const TRAFFIC_BEHAVIOR_OPTIONS = {
    INBOUND: {
        label: "Set Inbound Trips",
        value: "inbound",
    },
    OUTBOUND: {
        label: "Set Outbound Trips",
        value: "outbound",
    },
} as const;
export const TRAFFIC_BEHAVIOR_OPTIONS_LIST = Object.values(TRAFFIC_BEHAVIOR_OPTIONS);
export type TTrafficBehaviorOptionName = keyof typeof TRAFFIC_BEHAVIOR_OPTIONS;
export type TTrafficBehaviorOption =
    typeof TRAFFIC_BEHAVIOR_OPTIONS[keyof typeof TRAFFIC_BEHAVIOR_OPTIONS];

export const TMC_VIZ_DEFAULTS = {
    filters: {
        ...BASE_VIZ_DEFAULTS.filters,
        [PEAK_HOUR_FILTER.filterName]: [],
        trafficFlow: TRAFFIC_FLOW_OPTIONS.inbound.id,
        dayPartType: DAY_PART_TYPE_OPTIONS.CUSTOM_RANGE.value,
        trafficDistribution: {
            behavior: TRAFFIC_BEHAVIOR_OPTIONS.INBOUND.value,
            gate: {
                role: null,
                road: null,
                id: null,
            },
        },
    },
    visualization: {
        ...BASE_VIZ_DEFAULTS.visualization,
        mapLayersVisibility: DEFAULT_MAP_LAYERS_VISIBILITY,
    },
};
export type TTMCVizDefaultKey = keyof typeof TMC_VIZ_DEFAULTS;
export type TTMCVizDefault = typeof TMC_VIZ_DEFAULTS[keyof typeof TMC_VIZ_DEFAULTS];

export const TRAFFIC_FLOW_CHART_TYPE_OPTIONS = {
    TABLE: { label: "Table", value: "table" },
    DIAGRAM: { label: "Diagram", value: "diagram" },
} as const;
export const TRAFFIC_FLOW_CHART_TYPE_OPTIONS_LIST = Object.values(TRAFFIC_FLOW_CHART_TYPE_OPTIONS);
export type TTrafficFlowChartTypeOptionName = keyof typeof TRAFFIC_FLOW_CHART_TYPE_OPTIONS;
export type TTrafficFlowChartTypeOption =
    typeof TRAFFIC_FLOW_CHART_TYPE_OPTIONS[keyof typeof TRAFFIC_FLOW_CHART_TYPE_OPTIONS];

export const TRAFFIC_FLOW_API_PATHS = {
    DIAGRAM: "traffic_flow_diagram",
    TABLE: "traffic_flow_table",
    PEAK_HOUR_TABLE: "peak_hour_table",
} as const;
export const TRAFFIC_FLOW_API_PATHS_LIST = Object.values(TRAFFIC_FLOW_API_PATHS);
export type TTrafficFlowApiPathType = keyof typeof TRAFFIC_FLOW_API_PATHS;
export type TTrafficFlowApiPath =
    typeof TRAFFIC_FLOW_API_PATHS[keyof typeof TRAFFIC_FLOW_API_PATHS];

export const IMAGE_NAMES = {
    left: "left-trip-direction",
    right: "right-trip-direction",
    thru: "straight-trip-direction",
    noDirection: "",
} as const;
export type TImageNameKey = keyof typeof IMAGE_NAMES;
export type TImageName = typeof IMAGE_NAMES[keyof typeof IMAGE_NAMES];

export const ZONE_DISTRIBUTION_CHART_BAR_OPACITY = 0.8;
