import { forwardRef, TextareaHTMLAttributes } from "react";
import classnames from "classnames";
import "./textarea.less";

type TProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange" | "onBlur"> & {
    invalid?: boolean;
    label?: string | JSX.Element;
    "data-testid"?: string | number;
    onBlur?: (value: string) => void;
    onChange?: (value: string) => void;
};

export const StlTextarea = forwardRef<HTMLTextAreaElement, TProps>(
    (
        {
            label,
            id,
            disabled = false,
            invalid = false,
            placeholder = "",
            value,
            onChange = () => {},
            onBlur = () => {},
            className,
            ...restProps
        },
        ref,
    ) => (
        <div className="form-group">
            {!!label && (
                <label className="stl-label" htmlFor={id}>
                    {label}
                </label>
            )}
            <textarea
                disabled={disabled}
                className={classnames("stl-textarea", className, { invalid: !!invalid })}
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={e => onChange(e.target.value)}
                onBlur={e => onBlur(e.target.value)}
                ref={ref}
                aria-invalid={invalid}
                {...restProps}
            />
        </div>
    ),
);

StlTextarea.displayName = "StlTextarea";
