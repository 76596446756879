import { debounce } from "lodash-es";
import { useEffect, useState, useMemo } from "react";
import { useDidMount } from "@common/hooks/useDidMount";
import { useMounted } from "@common/utils/useMounted";
import { DEFAULT_ZOOM_LEVEL } from "@common/components/baseMap/baseMap.constants";
import type { Map } from "mapbox-gl";

const DEFAULT_DEBOUNCE_VALUES = {
    isDebounced: false,
    debounceTime: 300,
} as const;

export interface IZoomOption {
    isDebounced?: boolean;
    debounceTime?: number;
    roundingFunc?: (zoom: number) => number;
}

const DEFAULT_ROUND_FUNC = (zoom: number) => Math.floor(zoom);

export const useZoom = (map: Map | null, options: IZoomOption = {}) => {
    const {
        isDebounced = DEFAULT_DEBOUNCE_VALUES.isDebounced,
        debounceTime = DEFAULT_DEBOUNCE_VALUES.debounceTime,
        roundingFunc = DEFAULT_ROUND_FUNC,
    } = options;

    const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM_LEVEL);

    const isMounted = useMounted();

    const onZoom = useMemo(() => {
        if (!map) return () => {};

        if (!isDebounced) {
            return () => {
                if (isMounted.current) {
                    setZoom(map.getZoom());
                }
            };
        } else {
            return debounce(() => {
                if (isMounted.current) {
                    setZoom(map.getZoom());
                }
            }, debounceTime);
        }
    }, [map, isDebounced, debounceTime, isMounted]);

    useEffect(() => {
        if (!map) return undefined;

        map.on("zoom", onZoom);

        return () => {
            map.off("zoom", onZoom);
        };
    }, [map, onZoom]);

    useDidMount(() => {
        setZoom(map?.getZoom() || DEFAULT_ZOOM_LEVEL);
    });

    return roundingFunc(zoom);
};
