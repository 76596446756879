import {
    VIZ_ZONE_TYPES,
    VIZ_MAP_LAYERS,
    BASE_VIZ_DEFAULTS,
} from "@app/viz3/base/state/baseViz.constants";

export const AADT_ZONE_TYPE = {
    ...VIZ_ZONE_TYPES.ORIGIN,
    typeName: "zone",
};

export const AADT_LAYERS = {
    ZONE: {
        code: "zone",
        label: "Zones",
        hasConfiguration: true,
    },
    ...VIZ_MAP_LAYERS,
} as const;

export const DEFAULT_MAP_LAYERS_VISIBILITY = {
    zone: true,
    base: true,
    unselected: false,
    label: true,
    legend: true,
};

export const AADT_VIZ_DEFAULTS = {
    filters: {
        ...BASE_VIZ_DEFAULTS.filters,
    },
    visualization: {
        ...BASE_VIZ_DEFAULTS.visualization,
        mapLayersVisibility: DEFAULT_MAP_LAYERS_VISIBILITY,
    },
};
