import { StlButton } from "@common/components/button";
import "./mapStyleLoadingAlert.less";

export const MapStyleLoadingAlert = ({ onHide }: { onHide: () => void }) => (
    <div className="stl-map-style-error-alert" role="alert" aria-live="assertive">
        <div className="error-text">Selected Basemap Currently Not Available</div>
        <div className="button-wrapper">
            <StlButton variant="primary" size="md" onClick={onHide}>
                Ok
            </StlButton>
        </div>
    </div>
);
