import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { IRVAvailableCounty } from "@app/viz3/rvVisualization/state/rvViz.types";
import type { IDistributionLayerGroupAPI } from "@common/services/server/layerGroupApi.types";
import type { IRVRoadwayAPI, IRVSegmentAPI } from "@common/services/server/widgetApi.types";
import { RV_VIZ_INITIAL_STATE, IRVVizState } from "./rvViz.state";

export const { actions, reducer } = createSlice({
    name: "rvViz",
    initialState: RV_VIZ_INITIAL_STATE,
    reducers: {
        setRVVizState: (state, action: PayloadAction<IRVVizState>) => {
            return action.payload;
        },
        resetRVVizState: () => {
            return RV_VIZ_INITIAL_STATE;
        },
        setAvailableCounties: (state, action: PayloadAction<Array<IRVAvailableCounty>>) => {
            state.availableCounties = action.payload;
        },
        setDistributionLayers: (state, action: PayloadAction<IDistributionLayerGroupAPI>) => {
            state.distributionLayers = action.payload;
        },
        setRoadways: (state, action: PayloadAction<Array<IRVRoadwayAPI>>) => {
            state.map.roadways = action.payload;
        },
        setSegments: (state, action: PayloadAction<Array<IRVSegmentAPI>>) => {
            state.map.segments = action.payload;
        },
        setZoneIdsToZoom: (state, action: PayloadAction<Array<number | string>>) => {
            state.map.zoneIdsToZoom = action.payload;
        },
    },
});

export const rvVizReducer = reducer;
