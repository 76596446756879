export const ZONE_GEOM_TYPES = {
    LINE: { id: "line", label: "Line" },
    POLYGON: { id: "polygon", label: "Polygon" },
} as const;

export const ZONE_GEOM_TYPES_LIST = Object.values(ZONE_GEOM_TYPES);

export const ZONE_STATUSES = {
    AVAILABLE: { id: "Available", name: "Available" },
    DIRTY: { id: "Dirty", name: "Dirty" },
    PROCESSING: { id: "Processing", name: "Processing" },
    ERROR: { id: "Error", name: "Error" },
};

export const ZONE_STATUSES_LIST = Object.values(ZONE_STATUSES);

export const ZONE_NAME_MAX_LENGTH = 50;

export const ZONE_SET_DIRECTION_MISSING = 999;

export const ZONE_WARNINGS = {
    POLYGON_ZONE: `This zone is of questionable configuration: very small area and not marked pass-through, 
        or very large and marked pass-through, or marked pass-through but does not overlap any road. 
        If used in an Analysis, the Analysis may be flagged for review by StreetLight Data or have 
        invalid Metrics. Please check that the zone is created as intended and edit as needed.`,
    LINE_ZONE: `This line zone is of questionable configuration: it contains gates with very small area and 
        not marked pass-through, or very large and marked pass-through, or marked pass-through 
        but that do not overlap any road. If used in an Analysis, the Analysis may be flagged 
        for review by StreetLight Data or have invalid Metrics. Please check that the zone is created 
        as intended and edit as needed.`,
    ZONE_SET: `The configuration for this Zone Set may cause an Analysis to produce invalid Metrics, 
        or to be flagged for review by StreetLight Data. This could be caused by very small zones 
        that are not pass-through, very large zones that are pass-through, or zones that do not overlap any road. 
        Please check and edit the zones as needed.`,
    ZONE_AREA_QUOTA: (zoneAreaQuota: string | number) =>
        `This zone exceeds your subscription’s zone area limit of ${zoneAreaQuota} km2 and cannot be used for analysis. 
        Please edit your zone to be within the limit.`,
};
