export interface IMapEnhancedLayersState {
    hoveredEnhancedLayerZone: any;
    selectedEnhancedLayerZone: any;
    selectedVizEnhancedLayer: any;
    hoveredLegendSwatch: string;
}

export const MAP_ENHANCED_LAYERS_STATE = {
    hoveredEnhancedLayerZone: null,
    selectedEnhancedLayerZone: null,
    selectedVizEnhancedLayer: null,
    hoveredLegendSwatch: "",
} as IMapEnhancedLayersState;
