import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import "./formError.less";

interface IError {
    error: string;
}

interface IRequiredError {
    required: string;
}

interface ILessThanError {
    lessThan: string;
}

interface IInvalidCrmOpportunityError {
    invalidCrmOpportunityId: string;
}

interface IInvalidCrmStreetlightSubscriptionIdError {
    invalidCrmStreetlightSubscriptionId: string;
}

export type TError =
    | IError
    | IRequiredError
    | ILessThanError
    | IInvalidCrmOpportunityError
    | IInvalidCrmStreetlightSubscriptionIdError;

export interface IErrors {
    [key: string]: TError;
}

type TProps = {
    name: string;
    errors: IErrors;
    id?: string;
};

export const StlFormError = ({ name, errors, id }: TProps) => {
    if (!errors[name]) return null;

    const [message] = Object.values(errors[name]);

    return (
        <span className="stl-form-error" id={id}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            {message}
        </span>
    );
};
