import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { MeasurementToolControl } from "@common/components/baseMap/customControls";
import { MeasurementTool } from "@common/components/baseMap/customControls/measurementTool";
import { MAP_DRAW_EVENTS } from "@common/components/baseMap/baseMap.constants";
import type { Map } from "mapbox-gl";
import type { TControlConfig } from "@common/components/baseMap/baseMap.types";

export type TProps = {
    map: Map | null;
    position: TControlConfig["position"];
    enabled: boolean;
};

export const useMeasurementToolControl = ({ map, position, enabled }: TProps) => {
    const [isControlAdded, setIsControlAdded] = useState(false);

    const measurementToolControl = useMemo(() => {
        return enabled
            ? new MeasurementToolControl({
                  onAdd: () => setIsControlAdded(true),
                  position,
                  onClick: (isActive: boolean) =>
                      map?.fire("measurementControl.active", {
                          isActive,
                      }),
              })
            : null;
    }, [position, map, enabled]);

    const closeMeasurementControl = useCallback(
        () => measurementToolControl?.onShowMeasurementTool(),
        [measurementToolControl],
    );

    const setControlDisabled = useCallback(
        ({ isDrawing }: { isDrawing: boolean }) => measurementToolControl?.setDisable(isDrawing),
        [measurementToolControl],
    );

    useEffect(() => {
        if (!map) return undefined;
        map.on(MAP_DRAW_EVENTS.DRAW, setControlDisabled);
        return () => {
            map.off(MAP_DRAW_EVENTS.DRAW, setControlDisabled);
        };
    }, [map, setControlDisabled]);

    useEffect(() => {
        if (!map || !position || isControlAdded || !measurementToolControl) return;

        map.addControl(measurementToolControl, position);
    }, [map, measurementToolControl, position, isControlAdded]);

    return useCallback(() => {
        if (!map || !position || !isControlAdded || !measurementToolControl) return null;

        const measurementTool = <MeasurementTool map={map} onClose={closeMeasurementControl} />;

        return ReactDOM.createPortal(
            measurementTool,
            measurementToolControl.measurementToolContainer,
        );
    }, [isControlAdded, map, measurementToolControl, position, closeMeasurementControl]);
};
