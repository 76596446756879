import React, { Component } from "react";
import moment, { CalendarSpec, unitOfTime } from "moment";

export const optionList = [
    "element",
    "date",
    "parse",
    "format",
    "add",
    "subtract",
    "ago",
    "fromNow",
    "fromNowDuring",
    "from",
    "toNow",
    "to",
    "calendar",
    "unix",
    "utc",
    "local",
    "tz",
    "withTitle",
    "titleFormat",
    "locale",
    "interval",
    "diff",
    "duration",
    "durationFromNow",
    "trim",
    "unit",
    "decimal",
    "filter",
    "onChange",
    "className",
];

type TContent = string | number | moment.Duration | moment.Moment;

type TProps = {
    element?: string;
    date?: string | number | Array<any> | Record<string, any>;
    parse?: string | Array<any>;
    format: string | null;
    add?: Record<string, any>;
    subtract?: Record<string, any>;
    ago?: boolean;
    fromNow?: boolean;
    fromNowDuring?: number;
    from?: string | number | Array<any> | Record<string, any>;
    toNow?: boolean;
    to?: string | number | Array<any> | Record<string, any>;
    calendar?: boolean | Record<string, any>;
    unix?: boolean;
    utc?: boolean;
    local?: boolean | string | null;
    tz?: string | null;
    withTitle?: boolean;
    titleFormat?: string;
    locale?: string;
    interval?: number;
    diff?: string | number | Array<any> | Record<string, any>;
    duration?: string | number | Array<any> | Record<string, any>;
    durationFromNow?: boolean;
    trim?: string | boolean;
    unit?: string;
    decimal?: boolean;
    filter?: <T extends TContent>(d: T) => T;
    onChange?: <T extends TContent>(d: T) => T;
    className?: string;
    title?: string;
    children?: any;
};

type TState = {
    content: string | number | moment.Duration | moment.Moment;
};

export function objectKeyFilter(obj1: Partial<TProps>) {
    const newProps = { ...obj1 };
    Object.keys(newProps)
        .filter(key => optionList.indexOf(key) !== -1)
        .forEach(key => delete newProps[key as keyof TProps]);

    return newProps;
}

export default class Moment extends Component<TProps, TState> {
    static globalMoment: typeof Moment | typeof moment | null = null;

    static globalLocale: string | null = null;

    static globalLocal: string | boolean | null = null;

    static globalFormat: string | null = null;

    static globalParse: any | null = null;

    static globalFilter: (<T extends TContent>(d: T) => T) | null = null;

    static globalElement: string = "time";

    static globalTimezone: string | null = null;

    static pooledElements: Array<any> = [];

    static pooledTimer: NodeJS.Timeout | null = null;

    // Starts the pooled timer
    static startPooledTimer(interval = 60000) {
        Moment.clearPooledTimer();
        Moment.pooledTimer = setInterval(() => {
            Moment.pooledElements.forEach(element => {
                if (element.props.interval !== 0) {
                    element.update();
                }
            });
        }, interval);
    }

    // Stops the pooled timer
    static clearPooledTimer() {
        if (Moment.pooledTimer) {
            clearInterval(Moment.pooledTimer);
            Moment.pooledTimer = null;
            Moment.pooledElements = [];
        }
    }

    // Adds a Moment instance to the pooled elements list
    static pushPooledElement(element: any) {
        if (!(element instanceof Moment)) {
            console.error("Element not an instance of Moment.");
            return;
        }
        if (Moment.pooledElements.indexOf(element) === -1) {
            Moment.pooledElements.push(element);
        }
    }

    // Removes a Moment instance from the pooled elements list
    static removePooledElement(element: any) {
        const index = Moment.pooledElements.indexOf(element);
        if (index !== -1) {
            Moment.pooledElements.splice(index, 1);
        }
    }

    // Returns a Date based on the set props
    static getDatetime(props: TProps) {
        const { utc, unix } = props;
        let { date, locale, parse, tz, local } = props;

        date = date || props.children;
        parse = parse || Moment.globalParse;
        local = local || Moment.globalLocal;
        tz = tz || Moment.globalTimezone;
        if (Moment.globalLocale) {
            locale = Moment.globalLocale;
        } else {
            locale = locale || (Moment.globalMoment as typeof moment)?.locale();
        }

        let datetime = null;
        if (utc) {
            datetime = (Moment.globalMoment as typeof moment)?.utc(date, parse, locale);
        } else if (unix) {
            // moment#unix fails because of a deprecation,
            // but since moment#unix(s) is implemented as moment(s * 1000),
            // this works equivalently
            datetime = (Moment.globalMoment as typeof moment)(
                (date as number) * 1000,
                parse,
                locale,
            );
        } else {
            datetime = (Moment.globalMoment as typeof moment)(date, parse, locale);
        }
        if (tz) {
            datetime = datetime.tz(tz);
        } else if (local) {
            datetime = datetime.local();
        }

        return datetime;
    }

    // Returns computed content from sent props
    static getContent(props: TProps) {
        const {
            fromNow,
            fromNowDuring,
            from,
            add,
            subtract,
            toNow,
            to,
            ago,
            calendar,
            diff,
            duration,
            durationFromNow,
            unit,
            decimal,
            trim,
        } = props;

        let { format } = props;

        format = format || Moment.globalFormat;
        const datetime = Moment.getDatetime(props);
        if (add) {
            datetime.add(add);
        }
        if (subtract) {
            datetime.subtract(subtract);
        }

        const fromNowPeriod = Boolean(fromNowDuring) && -datetime.diff(moment()) < fromNowDuring!;
        let content: TContent = "";
        if (format && !fromNowPeriod && !(durationFromNow || duration)) {
            content = datetime.format(format);
        } else if (from) {
            content = datetime.from(from, ago);
        } else if (fromNow || fromNowPeriod) {
            content = datetime.fromNow(ago);
        } else if (to) {
            content = datetime.to(to, ago);
        } else if (toNow) {
            content = datetime.toNow(ago);
        } else if (calendar) {
            content = datetime.calendar(null, calendar as CalendarSpec);
        } else if (diff) {
            content = datetime.diff(diff, unit as unitOfTime.Diff, decimal);
        } else if (duration) {
            content = datetime.diff(duration);
        } else if (durationFromNow) {
            content = moment().diff(datetime);
        } else {
            content = datetime.toString();
        }

        if (duration || durationFromNow) {
            content = moment.duration(content);
            // @ts-ignore TS2339: Property 'format' does not exist on type 'Duration'.
            content = content.format(format, { trim });
        }

        const filter = Moment.globalFilter || props.filter;
        content = filter!(content);

        return content;
    }

    timer: NodeJS.Timeout | number | null;

    constructor(props: TProps) {
        super(props);

        if (!Moment.globalMoment) {
            Moment.globalMoment = moment;
        }
        this.state = {
            content: "",
        };
        this.timer = null;
    }

    // Invoked immediately after a component is mounted
    componentDidMount() {
        this.setTimer();
        if (Moment.pooledTimer) {
            Moment.pushPooledElement(this);
        }
    }

    // Invoked immediately after updating occurs
    componentDidUpdate(prevProps: TProps) {
        const { interval } = this.props;

        if (prevProps.interval !== interval) {
            this.setTimer();
        }
    }

    // Invoked immediately before a component is unmounted and destroyed
    componentWillUnmount() {
        this.clearTimer();
    }

    // Invoked as a mounted component receives new props
    // What it returns will become state.
    static getDerivedStateFromProps(nextProps: TProps) {
        const content = Moment.getContent(nextProps);
        return { content };
    }

    // Starts the interval timer
    setTimer = () => {
        const { interval } = this.props;

        this.clearTimer();
        if (!Moment.pooledTimer && interval !== 0) {
            this.timer = setInterval(() => {
                this.update(this.props);
            }, interval);
        }
    };

    // Returns the element title to use on hover
    getTitle = () => {
        const { titleFormat } = this.props;

        const datetime = Moment.getDatetime(this.props as TProps);
        const format = titleFormat || Moment.globalFormat;

        return datetime.format(format!);
    };

    // Clears the interval timer
    clearTimer = () => {
        if (!Moment.pooledTimer && this.timer) {
            clearInterval(this.timer as number);
            this.timer = null;
        }
        if (Moment.pooledTimer && !this.timer) {
            Moment.removePooledElement(this);
        }
    };

    // Updates this.state.content
    update(props: TProps) {
        const elementProps = props || this.props;
        const { onChange } = elementProps;

        const content = Moment.getContent(elementProps);
        this.setState({ content }, () => {
            onChange!(content);
        });
    }

    render() {
        const { withTitle, element, ...remaining } = this.props;
        const { content } = this.state;

        const props = objectKeyFilter(remaining);
        if (withTitle) {
            props.title = this.getTitle();
        }

        return React.createElement(
            element || Moment.globalElement,
            {
                dateTime: Moment.getDatetime(this.props as TProps),
                ...props,
            },
            content,
        );
    }
}
// @ts-ignore
Moment.defaultProps = {
    element: null,
    fromNow: false,
    toNow: false,
    calendar: false,
    ago: false,
    unix: false,
    utc: false,
    local: false,
    unit: null,
    withTitle: false,
    trim: false,
    decimal: false,
    titleFormat: "",
    interval: 60000,
    filter: (d: TContent) => d,
    onChange: () => {},
};
