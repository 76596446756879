import { useCallback, useEffect, useRef, useContext, useMemo } from "react";
import type { Map, Layer, Expression } from "mapbox-gl";
import { BASE_MAP_STYLES } from "@app/viz3/base/map/baseMapStyles.constants";
import { COLORS } from "@common/constants/color.constants";
import { removeHoverConditionFromColorExpression } from "@common/components/baseMap/baseMap.helpers";
import { MapContext } from "@common/components/baseMap";

const toLineHoverLayerId = (id: string) => `stl:line-outline-hover:${id}`;
const toCircleHoverLayerId = (id: string) => `stl:circle-hover:${id}`;

const getTypeToColor = (type: Layer["type"]) => (type === "symbol" ? "icon" : type);

const getColorExpression = (colorExpression?: Expression, color?: string) => {
    if (color) return color;

    if (colorExpression) {
        return Array.isArray(colorExpression)
            ? removeHoverConditionFromColorExpression(colorExpression)
            : colorExpression;
    }

    return COLORS.BLUE;
};

export const HOVER_SETTINGS = {
    OUTLINE_WIDTH: {
        LINE: 3,
        POLYGON: 5,
        CIRCLE: 3,
    },
    INTERVAL: 500,
    OPACITY: {
        ACTIVE: 0.8,
        INACTIVE: 0,
    },
    OUTLINE_Z_INDEX: 1,
    DEFAULT_HOVER_LINE_WIDTH: 10,
};

type TProps = {
    map: Map | null;
    hoverOutlineColor?: string;
    disableAnimatedHover: boolean;
    layers: Layer[];
    hoverLineWidth?: number;
};

const INITIAL_STATE: {
    intervalTimeoutId: NodeJS.Timeout | number | null;
    isShow: boolean;
} = {
    intervalTimeoutId: null,
    isShow: false,
};

export const useHoverLayer = ({
    map,
    hoverOutlineColor,
    disableAnimatedHover,
    layers,
    hoverLineWidth = HOVER_SETTINGS.DEFAULT_HOVER_LINE_WIDTH,
}: TProps) => {
    const { hoveredFeature } = useContext(MapContext);
    const timeoutIdRef = useRef(INITIAL_STATE.intervalTimeoutId);
    const isShowRef = useRef(INITIAL_STATE.isShow);

    const setHoverFlash = useCallback(
        (mapInstance: Map, layerId: string, interval: number) => {
            timeoutIdRef.current = setTimeout(() => {
                clearTimeout(timeoutIdRef.current as NodeJS.Timeout);
                const lineHoverLayerIdPrefix = toLineHoverLayerId("");

                const opacityProperty = layerId.startsWith(lineHoverLayerIdPrefix)
                    ? "line-opacity"
                    : "circle-stroke-opacity";
                const opacityValue = isShowRef.current
                    ? HOVER_SETTINGS.OPACITY.INACTIVE
                    : HOVER_SETTINGS.OPACITY.ACTIVE;

                if (mapInstance.getLayer(layerId)) {
                    mapInstance.setPaintProperty(layerId, opacityProperty, opacityValue);
                    isShowRef.current = !isShowRef.current;
                    setHoverFlash(mapInstance, layerId, HOVER_SETTINGS.INTERVAL);
                } else {
                    isShowRef.current = false;
                }
            }, interval);
        },
        [isShowRef],
    );

    const _hoveredFeature = useMemo(() => {
        if (!layers.length || !hoveredFeature) return null;

        return layers[0].id === hoveredFeature.layer.id ? hoveredFeature : null;
    }, [layers, hoveredFeature]);

    useEffect(() => {
        if (
            !map ||
            !_hoveredFeature ||
            !map.getLayer(_hoveredFeature.layer.id) ||
            disableAnimatedHover
        ) {
            return undefined;
        }

        const currentLayerFilter = map.getFilter(_hoveredFeature.layer.id) || true;
        const sourceLayer = _hoveredFeature.layer["source-layer"]
            ? { "source-layer": _hoveredFeature.layer["source-layer"] }
            : {};
        const { type, source, id } = _hoveredFeature.layer;
        const featureColorExpression = map.getPaintProperty(id, `${getTypeToColor(type)}-color`);
        const color = getColorExpression(featureColorExpression, hoverOutlineColor);

        const hoverLineLayerId = toLineHoverLayerId(id);
        const hoverCircleLayerId = toCircleHoverLayerId(id);

        if (type === "line" || type === "fill") {
            const isLineType = _hoveredFeature.layer.type === "line";

            const lineGapWidth = isLineType
                ? {
                      "line-gap-width": hoverLineWidth,
                  }
                : {};

            const hoverLineLayer: Layer = {
                id: hoverLineLayerId,
                type: "line",
                source,
                ...sourceLayer,
                paint: {
                    "line-width": isLineType
                        ? HOVER_SETTINGS.OUTLINE_WIDTH.LINE
                        : HOVER_SETTINGS.OUTLINE_WIDTH.POLYGON,
                    ...lineGapWidth,
                    "line-opacity": HOVER_SETTINGS.OPACITY.INACTIVE,
                    "line-opacity-transition": {
                        duration: HOVER_SETTINGS.INTERVAL,
                    },
                    "line-color": color,
                },
                filter: [
                    "all",
                    ["==", ["get", "zone_id"], _hoveredFeature.properties?.zone_id],
                    currentLayerFilter,
                ],
                metadata: {
                    zIndex: HOVER_SETTINGS.OUTLINE_Z_INDEX,
                },
            };
            map.addLayer(hoverLineLayer);

            setHoverFlash(map, hoverLineLayerId, 0);
        }

        if (type === "circle" || type === "symbol") {
            const circleRadius =
                type === "circle"
                    ? map.getPaintProperty(id, "circle-radius")
                    : BASE_MAP_STYLES.CIRCLE_RADIUS.MAX;

            const hoverCircleLayer: Layer = {
                id: hoverCircleLayerId,
                type: "circle",
                source,
                ...sourceLayer,
                paint: {
                    "circle-radius": circleRadius,
                    "circle-opacity": HOVER_SETTINGS.OPACITY.ACTIVE,
                    "circle-stroke-opacity-transition": {
                        duration: HOVER_SETTINGS.INTERVAL,
                    },
                    "circle-color": COLORS.TRANSPARENT,
                    "circle-stroke-width": HOVER_SETTINGS.OUTLINE_WIDTH.CIRCLE,
                    "circle-stroke-color": color,
                    "circle-stroke-opacity": HOVER_SETTINGS.OPACITY.INACTIVE,
                },
                filter: [
                    "all",
                    ["==", ["get", "zone_id"], _hoveredFeature.properties?.zone_id],
                    currentLayerFilter,
                ],
                metadata: {
                    zIndex: HOVER_SETTINGS.OUTLINE_Z_INDEX,
                },
            };

            map.addLayer(hoverCircleLayer);

            setHoverFlash(map, hoverCircleLayerId, 0);
        }

        return () => {
            if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current as NodeJS.Timeout);
            if (map.getLayer(hoverLineLayerId)) map.removeLayer(hoverLineLayerId);
            if (map.getLayer(hoverCircleLayerId)) map.removeLayer(hoverCircleLayerId);

            isShowRef.current = false;
        };
    }, [
        map,
        _hoveredFeature,
        setHoverFlash,
        hoverOutlineColor,
        disableAnimatedHover,
        hoverLineWidth,
    ]);
};
