export const capitalizeTheFirstLetter = (input?: string) => {
    if (!input) return "";

    return input.charAt(0).toUpperCase() + input.slice(1);
};

//Truncate string with more than 'num' characters
export const truncateString = (input: string = "", num: number) => {
    if (input.length <= num) {
        return input;
    }
    return `${input.slice(0, num)} ...`;
};

//Not all cases are covered
export const formatPluralToSingular = (word: string) => {
    if (!word) return "";

    const wordLength = word.length;

    if (/(s|x|z|ch|sh)es$/.test(word)) {
        return word.substring(0, wordLength - 2);
    } else if (/ies$/.test(word)) {
        return `${word.substring(0, wordLength - 3)}y`;
    } else if (/s$/.test(word)) {
        return word.substring(0, wordLength - 1);
    } else {
        return word;
    }
};
