import { TrackJS } from "trackjs";
import { ICurrentUser } from "@common/services/services.types";

class LoggerService {
    isLocal = window.location.hostname.includes("localhost");
    get logger() {
        return TrackJS;
    }

    constructor() {
        if (!this.isLocal) {
            this.logger.install({
                token: "b1a6c5a43c254246a414cca862e474b9", // real token
                network: {
                    error: false,
                },
            });
            // @ts-ignore Property '_stl_logger' does not exist on type 'Window & typeof globalThis'.
            window._stl_logger = this;
        }
    }

    // Record a Telemetry event into the log with default severity. The TrackJS.console.log
    // function is a private clone of the global console.log for context that is relevant
    // for debugging, but should not be displayed in the browser console.
    // params - type any
    log(event: string, params: any) {
        return this.logger.isInstalled() && this.logger.console.log(event, params);
    }

    debug(event: string, params: any) {
        return this.logger.isInstalled() && this.logger.console.debug(event, params);
    }

    info(event: string, params: any) {
        return this.logger.isInstalled() && this.logger.console.info(event, params);
    }

    warn(event: string, params: any) {
        return this.logger.isInstalled() && this.logger.console.warn(event, params);
    }

    error(event: string, params?: any) {
        return this.logger.isInstalled() && this.logger.console.error(event, params);
    }

    // Reports an error to TrackJS immediately with the provided error.
    // If the parameter is not an Error, one will be generated using the serialized
    // parameter as the message.
    // The reported error will include all Telemetry logged by the agent.
    // error - Any Error to be reported
    track(error: Error | Object | String) {
        return this.logger.isInstalled() && this.logger.track(error);
    }

    addUserProperties(currentUser: ICurrentUser) {
        if (!this.logger.isInstalled()) return;

        this.logger.addMetadata("user_id", String(currentUser.user.user_id));
        this.logger.addMetadata("org_id", String(currentUser.user.org.org_id));
        this.logger.addMetadata("org_type", currentUser.user.org.org_type);
        this.logger.addMetadata(
            "is_emulating",
            String(currentUser.user.user_id !== currentUser.realUser.user_id),
        );
    }
}

export const loggerService = new LoggerService();
