import moment from "moment";
import { batch } from "react-redux";
import { createValidateField } from "@app/analysis/validation";
import { getUiStates } from "@app/analysis/state/general/general.selectors";
import { TIME_PERIODS_ACTION_TYPES } from "@app/analysis/timePeriods/state/timePeriods.actionTypes";
import { getDayPartSettings } from "@app/analysis/timePeriods/state/timePeriods.selectors";
import {
    DAY_PARTS,
    DAY_TYPES,
    TDayPartKind,
} from "@app/analysis/timePeriods/state/timePeriods.constants";
import { setTimePeriodsValidation } from "@app/analysis/timePeriods/state/timePeriods.actions";
import type { TAppDispatch } from "@app/store";
import type { TGetState } from "@app/store/root.reducer";
import type { IDayPart } from "@app/analysis/timePeriods/timePeriods.types";
import { getIsAllDayPart } from "@app/analysis/timePeriods/state/timePeriods.helpers";

const validateDayItemName = (name: string) => !/[^a-zA-Z0-9 _-]+/g.test(name);

// Creates a shallow copy of an array of day items (day types or day parts) and trims their names.
const trimDayItemsName = (dayItems: Array<IDayPart>) =>
    dayItems.map(dayItem => ({
        ...dayItem,
        name: dayItem.name.trim(),
    }));

export const validateDayParts = (dayParts: Array<IDayPart>, is15MinuteBinsModeActive: boolean) => {
    //Ignore 'ALL_DAY' part in 15-minute bins mode
    const _dayParts = is15MinuteBinsModeActive
        ? dayParts.filter(dayPart => !getIsAllDayPart(dayPart))
        : dayParts;
    const error = !_dayParts.length
        ? "No day parts selected. Please provide at least one day part."
        : "";

    return createValidateField(error);
};
/*
 * Checks an array of day parts if they are invalid and returns array of reasons if they are.
 * If the day part is valid, the reason will be "".
 */
export const validateDayPartFields = (
    dayParts: Array<IDayPart>,
    is15MinuteBinsModeActive: boolean,
) => {
    const validation = dayParts.reduce(
        ({ names, hours, hourRanges, invalidReasons }, part) => {
            const _invalidReasons = [...invalidReasons] as Array<string>;

            if (is15MinuteBinsModeActive) {
                const start = moment(part.start.name, "hhA");
                const end = moment(part.end.name, "hhA");

                const current = moment(start);

                let firstIteration = false;

                // A user has selected 24h part. Start and end of the part will be the same.
                // Loop below won't work because of this. Set forcing flag, so the look will start.
                if (current.format("HH:mm") === end.format("HH:mm")) {
                    firstIteration = true;
                }

                while (current.format("HH:mm") !== end.format("HH:mm") || firstIteration) {
                    firstIteration = false;

                    const formattedCurrent = current.format("HH:mm");

                    if (!hours.has(formattedCurrent)) {
                        hours.add(formattedCurrent);

                        current.add(1, "hours");
                    } else {
                        _invalidReasons.push(
                            "Day part overlaps with the other. Please select a range with unique hours.",
                        );

                        return {
                            names,
                            hours,
                            hourRanges,
                            invalidReasons: _invalidReasons,
                        };
                    }
                }
            }

            if (!part.name) {
                _invalidReasons.push("Please provide a day part name");
            } else if (!validateDayItemName(part.name)) {
                _invalidReasons.push(
                    "Day part names must be alphanumeric with underscores, hyphens, and spaces allowed",
                );
            } else if (!part.start || !part.end) {
                _invalidReasons.push("Please provide a day part start and end");
            } else if (names.has(part.name)) {
                _invalidReasons.push("Please provide unique day part names");
            } else {
                names.add(part.name);

                const range = `${part.start.name}-${part.end.name}`;

                if (hourRanges.has(range)) {
                    _invalidReasons.push("Please provide unique day part start and end hours");
                } else {
                    hourRanges.add(range);
                    _invalidReasons.push("");
                }
            }
            return { names, hours, hourRanges, invalidReasons: _invalidReasons };
        },
        { names: new Set(), hours: new Set(), hourRanges: new Set(), invalidReasons: [] } as {
            names: Set<string>;
            hours: Set<string>;
            hourRanges: Set<string>;
            invalidReasons: Array<string>;
        },
    );

    return validation.invalidReasons;
};

export const addDayPart = () => ({
    type: TIME_PERIODS_ACTION_TYPES.ADD_DAY_PART,
    data: { dayPart: DAY_TYPES.NEW },
});

export const removeDayPart = (index: number) => (dispatch: TAppDispatch, getState: TGetState) => {
    const state = getState();
    const { dayParts } = getDayPartSettings(state);
    const { is15MinuteBinsModeActive } = getUiStates(state);
    const newValidation = { dayParts: validateDayParts(dayParts, is15MinuteBinsModeActive) };

    batch(() => {
        dispatch({
            type: TIME_PERIODS_ACTION_TYPES.REMOVE_DAY_PART,
            data: { index },
        });
        dispatch(setTimePeriodsValidation(newValidation));
    });
};

export const setDayParts =
    (dayParts: Array<IDayPart>, shouldSkipValidation?: boolean) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const dayPartSettings = getDayPartSettings(state);
        const { is15MinuteBinsModeActive } = getUiStates(state);

        const trimmedDayParts = trimDayItemsName(dayParts);

        const reasons = !shouldSkipValidation
            ? validateDayPartFields(trimmedDayParts, is15MinuteBinsModeActive)
            : [];

        const newDayParts = trimmedDayParts.map((dayPart, i) => {
            const reason = reasons[i];

            if (reason && dayPartSettings.dayParts[i]) {
                // There is invalid data, so use previous day part values.
                return {
                    ...dayPartSettings.dayParts[i],
                    editMode: true,
                    reason,
                    isInvalid: true,
                };
            }
            const validDayPart = { ...dayPart } as IDayPart;

            delete validDayPart.reason;
            delete validDayPart.isInvalid;

            return validDayPart;
        });

        const newValidation = {
            dayParts: validateDayParts(newDayParts, is15MinuteBinsModeActive),
        };

        batch(() => {
            dispatch({
                type: TIME_PERIODS_ACTION_TYPES.SET_DAY_PARTS,
                data: { dayParts: newDayParts },
            });
            dispatch(setTimePeriodsValidation(newValidation));
        });
    };

export const set24hrDayParts = () => (dispatch: TAppDispatch) => {
    const newDayParts = DAY_PARTS.get24Hrs();

    dispatch({
        type: TIME_PERIODS_ACTION_TYPES.SET_DAY_PARTS,
        data: { dayParts: newDayParts },
    });
};

export const setInitialDayParts = () => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_INITIAL_DAY_PARTS,
});

export const setInitial15MinuteBinsDayParts = () => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_INITIAL_15_MINUTE_BINS_DAY_PARTS,
});

export const clearDayParts = () => (dispatch: TAppDispatch, getState: TGetState) => {
    const { is15MinuteBinsModeActive } = getUiStates(getState());

    return dispatch({
        type: TIME_PERIODS_ACTION_TYPES.CLEAR_DAY_PARTS,
        data: { is15MinuteBinsModeActive },
    });
};

export const setDayPartKind = (dayPartKind: TDayPartKind["id"]) => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_DAY_PART_KIND,
    data: { dayPartKind },
});

export const setDraftCustomDayParts = (draftCustomDayParts: Array<IDayPart>) => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_DRAFT_CUSTOM_DAY_PARTS,
    data: { draftCustomDayParts },
});
