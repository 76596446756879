import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAGS_INITIAL_STATE } from "@common/features/tags/state/tags.state";
import type { ITagAPI } from "@common/services/server/tagsApi.types";

export const { actions, reducer } = createSlice({
    name: "tags",
    initialState: TAGS_INITIAL_STATE,
    reducers: {
        updateTags: (state, action: PayloadAction<Array<ITagAPI>>) => {
            return action.payload;
        },
    },
});

export const tagsReducer = reducer;
