import {
    IStudy,
    EStudyMode,
    EStudyTypes,
    ESubscriptionTier,
    ESubscriptionZoneBand,
} from "@common/features/studyModal/common/study.types";

export const INITIAL_STUDY_STATE_OBJ: IStudy = {
    studyId: null,
    studyType: "",
    name: "",
    endClient: "",
    startDate: null,
    endDate: null,
    nonEditableStartDate: null,
    taskNumber: "",
    chargeCode: "",
    bpCode: "",
    rfpId: "",
    geometry: null,
    buffer: "",
    notes: "",
    subscriptionZoneBand: null,
    subscriptionTier: null,
    measurementUnit: "",
} as const;

export const ENTERPRISE_STUDY_TYPES = {
    Pursuit: {
        value: EStudyTypes.Pursuit,
        label: "Pursuit",
    },
    Active_Project: {
        value: EStudyTypes.Active_Project,
        label: "Active Project",
    },
    Marketing_Conf: {
        value: EStudyTypes.Marketing_Conf,
        label: "Marketing, Conference, Thought Leadership",
    },
} as const;

export const ENTERPRISE_STUDY_TYPE_OPTIONS = Object.values(ENTERPRISE_STUDY_TYPES);

export const STUDY_TYPES = {
    [EStudyTypes.Request_for_Proposal]: {
        value: EStudyTypes.Request_for_Proposal,
        label: "Request for Proposal",
    },
    [EStudyTypes.Business_Development]: {
        value: EStudyTypes.Business_Development,
        label: "Business Development",
    },
    [EStudyTypes.Marketing_Materials]: {
        value: EStudyTypes.Marketing_Materials,
        label: "Marketing Materials",
    },
} as const;

export const STUDY_TYPE_OPTIONS = Object.values(STUDY_TYPES);

export const DEPRECATED_STUDY_TYPES = {
    Past_Studies: {
        value: "Past_Studies",
        label: "Past Studies",
    },
    Presales_Open_Middle: {
        value: "Presales_Open_Middle",
        label: "Pre-Sales: Opening/Middle Game",
    },
    Presales_End_Games: {
        value: "Presales_End_Games",
        label: "Pre-Sales: End Game",
    },
    Other: {
        value: "Other",
        label: "Other",
    },
};

export const DEPRECATED_STUDY_TYPE_OPTIONS = Object.values(DEPRECATED_STUDY_TYPES);

export const ALL_STUDY_TYPE_OPTIONS = [
    ...ENTERPRISE_STUDY_TYPE_OPTIONS,
    ...STUDY_TYPE_OPTIONS,
    ...DEPRECATED_STUDY_TYPE_OPTIONS,
];

export const SUBSCRIPTION_TIERS = {
    [ESubscriptionTier.Lite]: { value: ESubscriptionTier.Lite, label: "Lite" },
    [ESubscriptionTier.Essentials]: { value: ESubscriptionTier.Essentials, label: "Essentials" },
    [ESubscriptionTier.BikeOrPed_Essentials]: {
        value: ESubscriptionTier.BikeOrPed_Essentials,
        label: "Bike/Ped Essentials",
    },
    [ESubscriptionTier.Advanced_Analytics]: {
        value: ESubscriptionTier.Advanced_Analytics,
        label: "Advanced Analytics",
    },
    [ESubscriptionTier.MultiMode]: { value: ESubscriptionTier.MultiMode, label: "Multi-Mode" },
    [ESubscriptionTier.Im_Not_Sure]: {
        value: ESubscriptionTier.Im_Not_Sure,
        label: "I’m Not Sure?",
    },
} as const;

export const SUBSCRIPTION_TIER_OPTIONS = Object.values(SUBSCRIPTION_TIERS);

export const SUBSCRIPTION_ZONE_BAND_OPTIONS = Object.values(ESubscriptionZoneBand).map(
    zoneBand => ({
        value: zoneBand,
        label: zoneBand,
    }),
);

// Study statuses when Request Quote is not possible
export const READ_ONLY_STATUSES = ["Pending_Request", "Read_Only", "Quote_Requested"] as const;

export const STUDY_MODES = {
    CREATE: EStudyMode.CREATE,
    EDIT: EStudyMode.EDIT,
    VIEW: EStudyMode.VIEW,
} as const;

export const STUDY_MAP_DEFAULT_ZOOM = {
    US: {
        center: [-98, 39.5],
        zoom: 4,
    },
    CA: {
        center: [-96, 59],
        zoom: 3,
    },
    US_CA: {
        center: [-97, 48],
        zoom: 3,
    },
} as const;

export const EDIT_STUDY_DAYS_PERIOD = 60;
