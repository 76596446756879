import { merge } from "lodash-es";
import { VIZ_CODES } from "@app/viz3/base/visualizationsConfiguration";
import { BASE_VIZ_ACTIONS } from "@app/viz3/base/state/baseViz.actionTypes";
import { TROD_VIZ_ACTIONS } from "./trodViz.actionTypes";
import { TROD_VIZ_INITIAL_STATE } from "./trodViz.state";

export const trodVizReducer = (state = TROD_VIZ_INITIAL_STATE, { data, type }) => {
    switch (type) {
        case BASE_VIZ_ACTIONS.RESET_VIZ_STATE: {
            return TROD_VIZ_INITIAL_STATE;
        }

        case BASE_VIZ_ACTIONS.SET_ANALYSIS_COMMON_CONFIG: {
            let newLayerConfig;
            if (data.vizCode === VIZ_CODES.TROD && data.layerConfigurations) {
                newLayerConfig = merge(
                    {},
                    { ...TROD_VIZ_INITIAL_STATE.map.layerConfigurations },
                    { ...data.layerConfigurations },
                );
            } else {
                newLayerConfig = TROD_VIZ_INITIAL_STATE.map.layerConfigurations;
            }

            return {
                ...state,
                map: {
                    ...state.map,
                    layerConfigurations: {
                        ...state.map.layerConfigurations,
                        ...newLayerConfig,
                    },
                },
            };
        }

        case TROD_VIZ_ACTIONS.SET_CLICKED_ZONE: {
            return {
                ...state,
                map: {
                    ...state.map,
                    clickedZone: data.zone,
                },
            };
        }

        case TROD_VIZ_ACTIONS.SET_PRE_SELECTED_ZONE: {
            return {
                ...state,
                preSelectedZones: data,
            };
        }

        case TROD_VIZ_ACTIONS.SET_DISTRIBUTION_LAYERS: {
            const newLayers = data
                ? {
                      [data.layerType]: data.layers,
                  }
                : TROD_VIZ_INITIAL_STATE.distributionLayers;

            return {
                ...state,
                distributionLayers: {
                    ...state.distributionLayers,
                    ...newLayers,
                },
            };
        }

        case TROD_VIZ_ACTIONS.SET_STATIC_GEOMETRIES: {
            const newStaticGeometries = data
                ? {
                      [data.layerType]: data.staticGeometries,
                  }
                : TROD_VIZ_INITIAL_STATE.map.staticGeometries;

            return {
                ...state,
                map: {
                    ...state.map,
                    staticGeometries: {
                        ...state.map.staticGeometries,
                        ...newStaticGeometries,
                    },
                },
            };
        }

        case TROD_VIZ_ACTIONS.SET_LAYER_CONFIGURATION: {
            return {
                ...state,
                map: {
                    ...state.map,
                    layerConfigurations: {
                        ...state.map.layerConfigurations,
                        [data.configurationType]: {
                            ...state.map.layerConfigurations[data.configurationType],
                            ...data.configuration,
                        },
                    },
                },
            };
        }

        case TROD_VIZ_ACTIONS.SET_SEGMENTS_FOR_COMPARISON: {
            return {
                ...state,
                segmentsForComparison: data.segments,
            };
        }

        case TROD_VIZ_ACTIONS.SET_VIZ_STATE: {
            return {
                ...state,
                ...data.state,
            };
        }

        default:
            return state;
    }
};
