import { filter, find, uniqBy } from "lodash-es";
import type { Feature } from "@turf/helpers/dist/js/lib/geojson";
import type { Map, Layer, MapboxGeoJSONFeature, LngLatLike } from "mapbox-gl";
import { waitTillMapLoad } from "@common/components/baseMap/hooks/internal/useMapStyle";
import pointOnFeature from "@turf/point-on-feature";

export class BaseMapDebug {
    get map() {
        return window.BASE_MAP;
    }

    get layers() {
        return this.map.getStyle().layers;
    }

    get stlLayers() {
        return this.layers!.filter((layer: Layer) => layer.id.startsWith("stl:"));
    }

    get sources() {
        return this.map.getStyle().sources;
    }

    get styleName() {
        return this.map.getStyle().name;
    }

    get isLoaded() {
        return this.map.isStyleLoaded();
    }

    waitTillLoad({
        map = this.map,
        waitTimeout = 30000,
        checkTimeout = 500,
        successAttemptsTarget = 5,
    }: {
        map: Map;
        waitTimeout: number;
        checkTimeout: number;
        successAttemptsTarget: number;
    }) {
        return new Promise((resolve, reject) => {
            const onMapLoad = () => resolve(true);
            const onFail = () => reject(`Map style was not loaded after ${waitTimeout / 1000}s.`);

            waitTillMapLoad(map, onMapLoad, {
                checkTimeout,
                successAttemptsTarget,
                failTimeout: waitTimeout,
                onFail,
            });
        });
    }

    // Simplifies the usage of mapbox querySourceFeatures and allows to filter by partial feature
    // properties
    querySourceFeatures(
        layers: Array<Layer>,
        featureProperties: Partial<Feature> | null,
    ): Array<MapboxGeoJSONFeature> {
        const foundFeatures = layers
            .map(layer =>
                this.map.querySourceFeatures(layer.source as string, {
                    sourceLayer: layer["source-layer"],
                }),
            )
            .flat();

        if (!featureProperties) return foundFeatures;

        // @ts-ignore Property 'toJSON' does not exist on type
        return filter(foundFeatures, featureProperties).map(feature => feature.toJSON());
    }

    // Returns uniq by feature id visible and hidden features for the given viewport
    // Note: features outside of viewport still won't be available
    queryUniqSourceFeatures(layers: Array<Layer>, featureProperties: Partial<Feature> | null) {
        const foundFeatures = this.querySourceFeatures(layers, featureProperties);

        // @ts-ignore Property 'toJSON' does not exist on feature type MapboxGeoJSONFeature
        return uniqBy(foundFeatures, "id").map(feature => feature.toJSON());
    }

    // Simplifies the usage of mapbox queryRenderedFeatures and allows to filter by partial feature
    // properties
    queryRenderedFeaturesFromLayers(
        layers: Array<Layer>,
        featureProperties: Partial<Feature> | null = null,
    ) {
        const layerIds = layers.map(layer => layer.id);
        const foundFeatures = this.map.queryRenderedFeatures(undefined, { layers: layerIds });

        if (!featureProperties) return foundFeatures;

        // @ts-ignore Property 'toJSON' does not exist on feature type MapboxGeoJSONFeature
        return filter(foundFeatures, featureProperties).map(feature => feature.toJSON());
    }

    queryUniqRenderedFeaturesFromLayers(
        layers: Array<Layer>,
        featureProperties: Partial<Feature> | null = null,
    ) {
        const foundFeatures = this.queryRenderedFeaturesFromLayers(layers, featureProperties);

        // @ts-ignore Property 'toJSON' does not exist on feature type
        return uniqBy(foundFeatures, "id").map(feature => feature.toJSON());
    }

    // Returns first found feature based on the passed partial feature object.
    // Could be used to get state/properties of not rendered feature. In case if you need a feature
    // for hover/click events emulation - use `findRenderedFeature`
    // Note: zone could be split into multiple features, but we should be OK to get only part of it
    // to retrieve feature state/properties
    findSourceFeature(layers: Array<Layer>, featureProperties: Partial<Feature>) {
        const foundFeatures = layers
            .map(layer =>
                this.map.querySourceFeatures(layer.source as string, {
                    sourceLayer: layer["source-layer"],
                }),
            )
            .flat();

        const feature = find(foundFeatures, featureProperties);

        if (!feature) return null;

        // @ts-ignore Property 'toJSON' does not exist on feature type MapboxGeoJSONFeature
        return feature.toJSON();
    }

    // Returns found rendered feature
    findRenderedFeature(layers: Array<Layer>, featureProperties: Partial<Feature>) {
        const foundFeatures = this.queryRenderedFeaturesFromLayers(layers);

        const feature = find(foundFeatures, featureProperties);

        if (!feature) return null;

        return feature.toJSON();
    }

    clickFeature(
        feature: Feature,
        originalEvent: {
            [key: string]: unknown;
        } = {},
    ) {
        this.map.fire("click", {
            point: this.getPixelsPointOnFeature(feature),
            originalEvent,
        });
    }

    hoverFeature(
        feature: Feature,
        originalEvent: {
            [key: string]: unknown;
        } = {},
    ) {
        this.map.fire("mousemove", {
            point: this.getPixelsPointOnFeature(feature),
            originalEvent,
        });
    }

    removeHover() {
        this.map.fire("mousemove", { point: { x: 0, y: 0 }, originEvent: {} });
    }

    getPointOnFeature = pointOnFeature;

    getPixelsPointOnFeature(feature: Feature) {
        const pointFeature = pointOnFeature(feature);

        return this.map.project(pointFeature.geometry.coordinates as LngLatLike);
    }

    getBaseMapLayers() {
        return this.layers!.filter((layer: Layer) => !layer.id.startsWith("stl:"));
    }

    getLabelMapLayers() {
        const isLabelLayer = (layer: Layer) =>
            layer.type === "symbol" && (layer.id.includes("label") || layer.id.includes("shield"));

        return this.layers!.filter(isLabelLayer);
    }
}

export const baseMapDebug = new BaseMapDebug();

// @ts-ignore Property 'baseMapDebug' does not exist on type 'Window & typeof globalThis'.
window.baseMapDebug = baseMapDebug;
