import { createSelector } from "reselect";
import type { TRootState } from "@app/store";
import {
    TAnalysisTypeCategoryFeatureId,
    TAnalysisTypeCategoryType,
    TSubscriptionFeature,
    TCategoryFeatureIds,
    TUserPreferenceFilter,
    TUserPreferenceMetricsPackageValue,
} from "@app/bigBang/projects/state/projects.types";
import {
    DEFAULT_PREFERENCE_FILTER_VALUES,
    MAP_TYPE_OPTIONS,
    METRICS_PACKAGES_CATEGORIES,
} from "@app/bigBang/projects/state/projects.constants";
import { getProjectFoldersPreferences } from "@app/store/userPreferences/userPreferences.selector";
import { getIsOrgHasFeature, getSubscription } from "@app/store/currentUser/currentUser.selector";
import {
    getActualMetricsPackages,
    getMetricsPackagesMap,
    getUniqAnalysisTypes,
} from "@app/bigBang/projects/state/projects.helpers";
import { getZonesByZoneKind } from "@common/features/zones/zones.helpers";
import {
    getZoneIdsByZoneKind,
    getZonesFromAnalysis,
} from "@app/analysis/zones/chooseZones/state/chooseZones.helpers";
import type { IZone } from "@common/features/zones/zones.types";
import { IMetricPackageItem } from "@common/services/server/projectFolderApi.types";

export const getProjectsState = (state: TRootState) => state.projects;

export const getProjectFolders = createSelector(getProjectsState, state => state.projectFolders);
export const getSelectedProjectFolder = createSelector(
    getProjectsState,
    state => state.selectedProjectFolder,
);

export const getIsLoading = createSelector(getProjectsState, state => state.uiStates.isLoading);
export const getUIState = createSelector(getProjectsState, state => state.uiStates);
export const getFilters = createSelector(
    getProjectsState,
    state => state.selectedProjectFolderFilters,
);

export const getPreferenceFilters = createSelector(
    getProjectFoldersPreferences,
    ({ filtered }) => {
        if (!filtered.length) return DEFAULT_PREFERENCE_FILTER_VALUES;

        return filtered.reduce(
            (
                result: {
                    selectedMetricsPackages: TUserPreferenceMetricsPackageValue;
                    search: string;
                },
                filter: TUserPreferenceFilter,
            ) => {
                return {
                    ...result,
                    [filter.id]: filter.value,
                };
            },
            DEFAULT_PREFERENCE_FILTER_VALUES,
        );
    },
);

export const getMetricsPackagesDataMap = createSelector(getSubscription, ({ metrics_packages }) =>
    metrics_packages ? getMetricsPackagesMap(metrics_packages) : undefined,
);

export const getSubscriptionAnalysisTypesMap = createSelector(
    getSubscription,
    ({ metrics_packages, features }) => {
        if (!metrics_packages?.length) {
            return Object.values(METRICS_PACKAGES_CATEGORIES.ANALYSIS_TYPES.types).reduce(
                (
                    result: { [key: number]: TAnalysisTypeCategoryType },
                    analysisTypeData: TAnalysisTypeCategoryType,
                ) => {
                    const foundFeature = features.find(
                        (feature: TSubscriptionFeature) =>
                            feature.code_name === analysisTypeData.featureName,
                    );
                    if (!foundFeature || !foundFeature.enabled) return result;

                    return {
                        ...result,
                        [analysisTypeData.feature_id]: analysisTypeData,
                    };
                },
                {},
            );
        }

        const uniqAnalysisTypes = getUniqAnalysisTypes(metrics_packages);

        return uniqAnalysisTypes.reduce(
            (
                result: { [key: number]: IMetricPackageItem },
                analysisTypeData: IMetricPackageItem,
            ) => {
                return {
                    ...result,
                    [analysisTypeData.feature_id]: analysisTypeData,
                };
            },
            {},
        );
    },
);

export const getSelectedZones = createSelector(getFilters, ({ analysis }) => ({
    oz: analysis ? getZonesFromAnalysis(analysis.oz_zones) : [],
    dz: analysis ? getZonesFromAnalysis(analysis.dz_zones) : [],
    mfz: analysis ? getZonesFromAnalysis(analysis.mfz_zones) : [],
    tfz: analysis ? getZonesFromAnalysis(analysis.tfz_zones) : [],
    cz: analysis ? getZonesFromAnalysis(analysis.cz_zones) : [],
    az: analysis ? getZonesFromAnalysis(analysis.az_zones) : [],
}));

export const getSelectedZoneIds = createSelector(getSelectedZones, selectedZones => {
    return Object.entries(selectedZones).reduce((selectedZonesMap, [role, zones]) => {
        //@ts-ignore
        selectedZonesMap[role] = zones.map(zone => zone.zone_id);
        return selectedZonesMap;
    }, {});
});

export const getBoundingBoxZones = createSelector(
    getFilters,
    getSelectedZones,
    ({ mapType, zoneSet, analysis }, selectedZones) => {
        if (mapType === MAP_TYPE_OPTIONS.zoneSets.id) {
            return zoneSet
                ? //@ts-ignore
                  getZonesByZoneKind(zoneSet.zones)
                : {
                      zones: [],
                  };
        }

        return {
            zones: getZoneIdsByZoneKind(selectedZones),
        };
    },
);

export const getAvailableAnalysisTypes = (state: TRootState) => {
    const subscriptionAnalysisTypesMap = getSubscriptionAnalysisTypesMap(state);
    const selectedProjectFolder = getSelectedProjectFolder(state);
    const filters = getFilters(state);

    if (!filters.metricsPackages?.length && !selectedProjectFolder?.metrics_packages.length) {
        return [];
    }

    const hasFilters = !!filters.metricsPackages.length;

    const metricsPackages = getActualMetricsPackages(
        filters.metricsPackages,
        selectedProjectFolder?.metrics_packages || [],
    );

    const uniqAnalysisTypes = getUniqAnalysisTypes(metricsPackages);

    const availableMetricsPackagesForSelectedProjectFolder = getUniqAnalysisTypes(
        selectedProjectFolder?.metrics_packages || [],
    );

    const getIsMetricsPackageAvailable = (featureId: TCategoryFeatureIds) => {
        return (
            !hasFilters ||
            availableMetricsPackagesForSelectedProjectFolder.find(
                metricsPackage => metricsPackage.feature_id === featureId,
            )
        );
    };

    return uniqAnalysisTypes.filter(analysisType => {
        if (
            !subscriptionAnalysisTypesMap[analysisType.feature_id] ||
            !getIsMetricsPackageAvailable(analysisType.feature_id)
        ) {
            return false;
        }

        const _analysisType =
            METRICS_PACKAGES_CATEGORIES.ANALYSIS_TYPES.types[
                analysisType.feature_id as TAnalysisTypeCategoryFeatureId
            ];

        return _analysisType ? getIsOrgHasFeature(state, _analysisType.featureName) : false;
    });
};

export const getGroupedProjectZones = createSelector(getSelectedZones, projectZones => {
    const zonesGroupedByZoneKinds = Object.values(projectZones).reduce((map, zones: IZone[]) => {
        Object.values(zones).forEach(zone => {
            if (!map[zone.zone_kind_id]) map[zone.zone_kind_id] = [];

            map[zone.zone_kind_id].push(zone.zone_id.toString());
        });

        return map;
    }, {} as { [key: number]: Array<string> });

    return Object.entries(zonesGroupedByZoneKinds).map(([zoneKindId, zoneIds]) => ({
        zone_kind_id: zoneKindId,
        zone_ids: zoneIds,
    }));
});
