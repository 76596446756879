import DirectSelectMode from "@mapbox/mapbox-gl-draw/src/modes/direct_select";
import * as Constants from "@mapbox/mapbox-gl-draw/src/constants";
import createSupplementaryPoints from "@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points";

function calculateDirectionVector(coordinates) {
    const [x1, y1] = coordinates[0];
    const [x2, y2] = coordinates[1];
    const dx = x2 - x1;
    const dy = y2 - y1;
    const length = Math.sqrt(dx * dx + dy * dy);
    return [dx / length, dy / length];
}

export const DirectSelectForSpotCounter = {
    ...DirectSelectMode,
    dragVertex(state, e, delta) {
        const selectedCoords = state.selectedCoordPaths.map(coord_path =>
            state.feature.getCoordinate(coord_path),
        );

        const directionVector = calculateDirectionVector(state.feature.coordinates);

        const dotProduct = delta.lng * directionVector[0] + delta.lat * directionVector[1];
        const scalingFactor =
            dotProduct /
            Math.sqrt(
                directionVector[0] * directionVector[0] + directionVector[1] * directionVector[1],
            );

        for (let i = 0; i < selectedCoords.length; i++) {
            const coord = selectedCoords[i];
            const scaledDelta = {
                lng: directionVector[0] * scalingFactor,
                lat: directionVector[1] * scalingFactor,
            };
            state.feature.updateCoordinate(
                state.selectedCoordPaths[i],
                coord[0] + scaledDelta.lng,
                coord[1] + scaledDelta.lat,
            );
        }
    },
    dragFeature() {},
    toDisplayFeatures(state, geojson, push) {
        if (state.featureId === geojson.properties.id) {
            geojson.properties.active = Constants.activeStates.ACTIVE;
            push(geojson);
            createSupplementaryPoints(geojson, {
                map: this.map,
                midpoints: false,
                selectedPaths: state.selectedCoordPaths,
            }).forEach(push);
        } else {
            geojson.properties.active = Constants.activeStates.INACTIVE;
            push(geojson);
        }
        this.fireActionable(state);
    },
};
