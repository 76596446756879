import type { ILocation } from "@common/components/geocoder/geocoder";
import type { IMapError } from "@common/components/baseMap/mapErrorAlert/mapErrorAlert";
import type { TRoadType } from "@common/features/zonesManager/state/zonesManager.constants";
import type {
    IRoad,
    TGeography,
    ITimePeriod,
    ISelectedProjectFolder,
    TGeographyType,
    THoveredGeography,
    THoveredZone,
} from "@app/analysisLightning/base/state/analysisLightning.types";
import {
    TZoneSelectionSelectedToolId,
    ZONE_SELECTION_TOOLS,
} from "@common/components/baseMap/customControls/zoneSelectionTools/zoneSelectionTools.constants";
import {
    SCREEN_MODES,
    TScreenModes,
    TLightningAnalysisTypeIds,
} from "./analysisLightning.constants";

export interface IConfigurations {
    analysisName: string;
    analysisType: TLightningAnalysisTypeIds | null;
    draftId: number | "";
    geographies: Array<TGeography>;
    geographyType: TGeographyType["code"] | null;
    isCopy: boolean;
    projectFolder: ISelectedProjectFolder | null;
    roadClassifications: Array<TRoadType["id"]>;
    timePeriods: Array<ITimePeriod>;
    zoneSelectionConfig: {
        selectedTool: TZoneSelectionSelectedToolId | null;
    };
}

export interface IUiStates {
    activeStep: string | null;
    hoveredGeography: THoveredGeography | null;
    hoveredZone: THoveredZone | null;
    isLoading: boolean;
    location: ILocation | null;
    mapError: IMapError | null;
    roads: Array<IRoad>;
    screenMode: TScreenModes["id"];
    selectedRoad: IRoad | null;
}

export interface IAnalysisLightningState {
    configurations: IConfigurations;
    uiStates: IUiStates;
}

export const BASE_INITIAL_STATE = {
    configurations: {
        analysisName: "",
        analysisType: null,
        draftId: "",
        geographies: [] as Array<TGeography>,
        geographyType: null,
        isCopy: false,
        projectFolder: null,
        roadClassifications: [] as Array<TRoadType["id"]>,
        timePeriods: [] as Array<ITimePeriod>,
        zoneSelectionConfig: {
            selectedTool: ZONE_SELECTION_TOOLS.CURSOR.id,
        },
    },
    uiStates: {
        activeStep: null,
        hoveredZone: null,
        hoveredGeography: null,
        isLoading: false,
        location: null,
        mapError: null,
        roads: [] as Array<IRoad>,
        screenMode: SCREEN_MODES.DEFAULT.id,
        selectedRoad: null,
    },
} as IAnalysisLightningState;
