import {
    EMapStyleIds,
    ETransportationMode,
    EZoomBehaviors,
    TMapStyleProvider,
} from "@common/components/baseMap/baseMap.types";

export const BASE_MAP_NODE_ID = "base-map" as const;

export const BOUNDING_BOX_ZOOM_BEHAVIORS = {
    ZOOM_ON_INIT: EZoomBehaviors.ZOOM_ON_INIT,
    ZOOM_ON_CHANGE: EZoomBehaviors.ZOOM_ON_CHANGE,
    ZOOM_ON_CONTROL_ONLY: EZoomBehaviors.ZOOM_ON_CONTROL_ONLY,
} as const;

export const MAX_ZOOM_LEVEL = 24 as const;

const USA = {
    ZOOM: 4,
    CENTER: [-98, 39.5],
};

export const POLYGON_VISIBLE_PIXEL_THRESHOLD = 15 * 15;
export const LINE_POINT_ZOOM_THRESHOLD = 10;

// https://docs.mapbox.com/help/glossary/zoom-level/#zoom-levels-and-geographical-distance
// Using latitude +-40
export const SQ_METERS_PER_PIXEL_BY_ZOOM = [
    59959.436 * 59959.436,
    29979.718 * 29979.718,
    14989.859 * 14989.859,
    7494.929 * 7494.929,
    3747.465 * 3747.465,
    1873.732 * 1873.732,
    936.866 * 936.866,
    468.433 * 468.433,
    234.217 * 234.217,
    117.108 * 117.108,
    58.554 * 58.554,
    29.277 * 29.277,
    14.639 * 14.639,
    7.319 * 7.319,
    3.66 * 3.66,
    1.83 * 1.83,
    0.915 * 0.915,
    0.457 * 0.457,
    0.229 * 0.229,
    0.114 * 0.114,
    0.057 * 0.057,
    0.029 * 0.029,
    0.014 * 0.014,
] as const;

const POLYGON_FILTER_CASES = SQ_METERS_PER_PIXEL_BY_ZOOM.reduce(
    (result: Array<any>, sqMtrs: number, zoom: number) => {
        result.push(["==", ["to-number", ["zoom"]], zoom]);
        result.push([">=", ["get", "zone_area"], ["*", POLYGON_VISIBLE_PIXEL_THRESHOLD, sqMtrs]]);

        return result;
    },
    [],
);

const POLYGON_CIRCLE_FILTER_CASES = SQ_METERS_PER_PIXEL_BY_ZOOM.reduce(
    (result: Array<any>, sqMtrs: number, zoom: number) => {
        result.push(["==", ["to-number", ["zoom"]], zoom]);
        result.push(["<", ["get", "zone_area"], ["*", POLYGON_VISIBLE_PIXEL_THRESHOLD, sqMtrs]]);

        return result;
    },
    [],
);

export const MAP_MODES = {
    DEFAULT: "DEFAULT",
    SELECT_MULTIPLE: "SELECT_MULTIPLE",
    COMPARE_SEGMENTS: "COMPARE_SEGMENTS",
} as const;

export const DEFAULT_MAX_PITCH = 60 as const;
export const DEFAULT_ZOOM_LEVEL = USA.ZOOM;
export const DEFAULT_MAX_ZOOM_LEVEL = 20 as const;
export const DEFAULT_MIN_ZOOM_LEVEL = 1 as const;
export const DEFAULT_POINT_ZOOM_LEVEL = 16 as const;

export const TRANSPORTATION_MODES = [
    ETransportationMode.Pedestrian,
    ETransportationMode.Bicycle,
    ETransportationMode.Vehicle,
];

const DEFAULT_INTERSECTION_POINT_ZOOM_LEVEL = 17 as const;
const DEFAULT_INTERMEDIATE_ZOOM_LEVEL = 14 as const;

export const DEFAULT_CENTER_COORDINATES = USA.CENTER;

export const ZOOM_FILTERS_BY_TYPE = {
    POLYGON: ["case", ...POLYGON_FILTER_CASES, false],
    // Line zone points shown at fixed zoom level
    LINE: [">=", ["to-number", ["zoom"]], LINE_POINT_ZOOM_THRESHOLD],
    CIRCLE: [
        "case",
        ["==", ["get", "geom_type"], "L"],
        ["<", ["to-number", ["zoom"]], LINE_POINT_ZOOM_THRESHOLD],
        ["case", ...POLYGON_CIRCLE_FILTER_CASES, false],
    ],
    INTERSECTION: [">=", ["to-number", ["zoom"]], DEFAULT_INTERSECTION_POINT_ZOOM_LEVEL],
    INTERSECTION_CIRCLE: ["<", ["to-number", ["zoom"]], DEFAULT_INTERMEDIATE_ZOOM_LEVEL],
    INTERSECTION_INTERMEDIATE_CIRCLE: [
        "all",
        [">=", ["to-number", ["zoom"]], DEFAULT_INTERMEDIATE_ZOOM_LEVEL],
        ["<", ["to-number", ["zoom"]], DEFAULT_INTERSECTION_POINT_ZOOM_LEVEL],
    ],
    createCircleExpression(
        { isLineExpression } = { isLineExpression: ["==", ["get", "geom_type"], "L"] },
    ) {
        return [
            "case",
            isLineExpression,
            ["<", ["to-number", ["zoom"]], LINE_POINT_ZOOM_THRESHOLD],
            ["case", ...POLYGON_CIRCLE_FILTER_CASES, false],
        ];
    },
};

export const BASE_MAP_STYLES = {
    DARK: {
        code: "dark",
        styleId: EMapStyleIds.dark,
        display: "Dark",
        imagePath: "/img/map-style-dark.png",
    },
    STREET: {
        code: "streets",
        styleId: EMapStyleIds.streets,
        display: "Streets",
        imagePath: "/img/map-style-street.png",
    },
    LIGHT: {
        code: "light",
        styleId: EMapStyleIds.light,
        display: "Light",
        imagePath: "/img/map-style-light.png",
    },
    SATELLITE: {
        code: "satellite",
        styleId: EMapStyleIds.satellite,
        display: "Satellite",
        imagePath: "/img/map-style-satellite.png",
    },
    ESRI_DARK_GRAY_OSM: {
        code: "esriDarkGrayOsm",
        styleId: EMapStyleIds.esri_dark_gray_osm,
        display: "Dark Gray",
        imagePath: "/img/map-style-esri-dark-gray-osm.png",
    },
    ESRI_STREETS_OSM: {
        code: "esriStreetsOsm",
        styleId: EMapStyleIds.esri_streets_osm,
        display: "Streets",
        imagePath: "/img/map-style-esri-street-osm.png",
    },
    ESRI_LIGHT_OSM: {
        code: "esriLightGrayOsm",
        styleId: EMapStyleIds.esri_light_gray_osm,
        display: "Light Gray",
        imagePath: "/img/map-style-esri-light-gray-osm.png",
    },
    ESRI_IMAGERY_HYBRID_OSM: {
        code: "esriHybridOsm",
        styleId: EMapStyleIds.esri_imagery_hybrid_osm,
        display: "Imagery Hybrid",
        imagePath: "/img/map-style-esri-imagery-hybrid-osm.png",
    },
} as const;

export const BASE_MAP_STYLES_LIST = Object.values(BASE_MAP_STYLES);
export const DEFAULT_BASE_MAP_STYLE = BASE_MAP_STYLES.DARK;

type TMapStyleProviderStyles = {
    id: TMapStyleProvider;
    name: string;
    styles: `${EMapStyleIds}`[];
    baseUrl: string;
};

export const MAP_STYLE_PROVIDERS: Record<TMapStyleProvider, TMapStyleProviderStyles> = {
    mapbox: {
        id: "mapbox",
        name: "Default",
        styles: [
            "dark-v10",
            "streets-v11",
            "light-v10",
            "satellite-streets-v11",
            "navigation-day-v1",
            "navigation-night-v1",
        ],
        baseUrl: "mapbox://styles/mapbox/",
    },
    esri: {
        id: "esri",
        name: "Esri",
        styles: ["dark-gray", "light-gray", "streets", "hybrid"],
        baseUrl: "https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/osm/",
    },
};

export const MAP_STYLE_PROVIDERS_VALUES = Object.values(MAP_STYLE_PROVIDERS);

export const MULTIPLE_SELECTION_LAYER_ID_PREFIXES = {
    UNSELECTED: "stl:multiple-selection-unselected:",
    UNSELECTED_OUTLINE: "stl:multiple-selection-unselected-outline:",
    SELECTED: "stl:multiple-selection-selected:",
    SELECTED_OUTLINE: "stl:multiple-selection-selected-outline:",
} as const;

export const SELECTION_MODES = {
    ADD: "ADD",
    REMOVE: "REMOVE",
} as const;
export type TSelectionMode = typeof SELECTION_MODES[keyof typeof SELECTION_MODES];

export const MAP_RESIZE_ANIMATION_TIME = 300 as const;

export const MAP_EASY_TO_PITCH_ANIMATION_TIME = MAP_RESIZE_ANIMATION_TIME;

export const MAP_DRAW_EVENTS = {
    DRAW: "stl.draw",
    MEASUREMENT: "stl.measurement",
} as const;

const MAP_COMMON_FONTS = {
    regular: "Arial Unicode MS Regular",
    bold: "Arial Unicode MS Bold",
};

export const MAP_COMMON_TEXT_FIELD_FONT = [MAP_COMMON_FONTS.regular];
export const MAP_DATA_LOADING_ERROR_MESSAGE = "Failed to fetch" as const;
