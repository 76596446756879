import { useEffect, useState } from "react";
import classnames from "classnames";
import mapboxGL, { Map } from "mapbox-gl";
import MapboxGeocoder, { Result } from "@mapbox/mapbox-gl-geocoder";
import { MAPBOX_ACCESS_TOKEN } from "@common/constants/mapbox.constants";
import "./geocoder.less";

const INITIAL_STATE = {
    geocoder: null,
} as const;

const NO_RESULTS_ERROR_MESSAGE =
    `<div class='mapbox-gl-geocoder--error mapbox-gl-geocoder--no-results'>
        No locations found.
    </div>` as const;

const bboxDelta = 0.01 as const;

/*
 * Given a query in the form "lng, lat" or "lat, lng" returns the matching geographic coordinate(s)
 * as search results in carmen geojson format.
 * See https://docs.mapbox.com/mapbox-gl-js/example/mapbox-gl-geocoder-accept-coordinates/
 */
const coordinatesGeocoder = (query: string): Array<Result> => {
    // Match anything which looks like decimal degrees coordinate pair.
    const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Long: )?(-?\d+\.?\d*)[ ]*$/i);
    if (!matches) {
        return [];
    }

    function coordinateFeature(lng: number, lat: number): Result {
        return {
            id: `coordinate.${lng}-${lat}`,
            bbox: [lng - bboxDelta, lat - bboxDelta, lng + bboxDelta, lat + bboxDelta],
            center: [lng, lat],
            geometry: {
                type: "Point",
                coordinates: [lng, lat],
            },
            place_name: `Lat: ${lat} Long: ${lng}`,
            place_type: ["coordinate"],
            properties: {},
            type: "Feature",
            relevance: 0, // ??
            text: "",
            address: "",
            context: [],
        };
    }

    /*
     * Check if (val1, val2) is a valid (lat, long) or (long, lat) pair
     * Latitude range is [-90, 90], longitude - [-180, 180]
     */
    function isValidCoordinates(val1: number, val2: number) {
        const isValidLatLong = val1 >= -90 && val1 <= 90 && val2 >= -180 && val2 <= 180;
        const isValidLongLat = val1 >= -180 && val1 <= 180 && val2 >= -90 && val2 <= 90;
        return isValidLatLong || isValidLongLat;
    }

    const coord1 = Number(matches[1]);
    const coord2 = Number(matches[2]);
    const geocodes = [];

    if (!isValidCoordinates(coord1, coord2)) {
        return [];
    }
    // coord1 - longitude, coord2 - latitude
    if (coord1 < -90 || coord1 > 90) {
        geocodes.push(coordinateFeature(coord1, coord2));
    }
    // coord2 - longitude, coord1 - latitude
    if (coord2 < -90 || coord2 > 90) {
        geocodes.push(coordinateFeature(coord2, coord1));
    }
    // coord1 and coord2 either (lat, lng) or (lng, lat)
    if (geocodes.length === 0) {
        geocodes.push(coordinateFeature(coord2, coord1));
        geocodes.push(coordinateFeature(coord1, coord2));
    }

    return geocodes;
};

type TMapboxGeocoder = MapboxGeocoder & {
    _map?: Map;
    _mapboxgl?: typeof mapboxGL;
    _renderNoResults?: () => void;
    _renderMessage?: (message: string) => void;
};

export interface ILocation {
    id?: string | number;
    placeName: string;
    bbox: Array<number>;
    center: Array<number>;
}

type TProps = {
    id: string;
    map: Map | null;
    clearable?: boolean;
    placeholder: string;
    onSelect: (value: { result: Result }) => void;
    ariaLabelledBy?: string;
    selectedLocation?: Partial<ILocation> | null;
    showSearchIcon?: boolean;
    showMarker?: boolean;
};

export const StlGeocoder = ({
    id,
    map,
    selectedLocation,
    placeholder,
    onSelect,
    ariaLabelledBy,
    clearable,
    showSearchIcon = false,
    showMarker = true,
}: TProps) => {
    const [geocoder, setGeocoder] = useState<TMapboxGeocoder | null>(INITIAL_STATE.geocoder);

    const geocoderContainerId = `${id}-container`;

    // Initialize and mount.
    useEffect(() => {
        if (geocoder || !map) return;

        const _geocoder: TMapboxGeocoder = new MapboxGeocoder({
            accessToken: MAPBOX_ACCESS_TOKEN,
            localGeocoder: coordinatesGeocoder,
            countries: "us,ca",
            placeholder,
            // @ts-ignore
            mapboxgl: mapboxGL,
            marker: showMarker,
            reverseGeocode: true,
            render: item => {
                const isCoordinate = item.place_type.includes("coordinate");

                if (isCoordinate) {
                    return `<div class="mapboxgl-ctrl-geocoder--suggestion">
                                <div class="mapboxgl-ctrl-geocoder--suggestion-title">
                                    <i class="fa fa-map-marker suggestion-coordinate-icon" aria-hidden="true"></i>
                                    <span class="suggestion-coordinate-text">
                                        ${item.place_name}
                                    </span>
                                </div>
                            </div>`;
                }

                const placeName = item.place_name.split(",");
                return `<div class="mapboxgl-ctrl-geocoder--suggestion">
                            <div class="mapboxgl-ctrl-geocoder--suggestion-title">
                                ${placeName[0]}
                            </div>
                            <div class="mapboxgl-ctrl-geocoder--suggestion-address">
                                ${placeName.splice(1, placeName.length).join(",")}
                            </div>
                        </div>`;
            },
        });

        // Override MapboxGeocoder '_renderNoResults' function to provide different message
        _geocoder._renderNoResults = () => {
            if (_geocoder._renderMessage) _geocoder._renderMessage(NO_RESULTS_ERROR_MESSAGE);
        };
        _geocoder.addTo(`#${geocoderContainerId}`);
        // Set private properties to make markers work
        _geocoder._map = map;
        _geocoder._mapboxgl = mapboxGL;

        setGeocoder(_geocoder);
    }, [map, geocoder, geocoderContainerId, placeholder, showMarker]);

    // Subscribe parent's callback to location pick.
    useEffect(() => {
        if (!geocoder) return undefined;
        geocoder.on("result", onSelect);

        return () => {
            geocoder.off("result", onSelect);
        };
    }, [geocoder, onSelect]);

    // Update input value from parent.
    useEffect(() => {
        if (!geocoder) return;

        if (selectedLocation) {
            geocoder.setInput(selectedLocation.placeName || "");
        } else {
            geocoder.setInput("");
        }
    }, [selectedLocation, geocoder]);

    return (
        <div
            id={geocoderContainerId}
            className={classnames(
                "stl-geocoder",
                clearable && "clearable",
                showSearchIcon && "show-search-icon",
            )}
            aria-labelledby={ariaLabelledBy}
        />
    );
};
