import { ForwardedRef, forwardRef, ReactNode, useMemo } from "react";

type TProps = {
    children?: ReactNode;
    className?: string;
    size?: number | null;
    sizeProperty?: "height" | "width";
};

export const Pane = forwardRef(
    (
        { children, className, size, sizeProperty = "width" }: TProps,
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const styles = useMemo(() => {
            return size !== undefined && size !== null
                ? {
                      [sizeProperty]: size,
                      flex: "none",
                  }
                : {};
        }, [size, sizeProperty]);

        return (
            <div ref={ref} className={className} style={styles}>
                {children}
            </div>
        );
    },
);
