import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AnalyticsTrack } from "@common/services/analytics.service";

export const Tracker = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        AnalyticsTrack.pageTrack(location);
    }, [location]);

    return children;
};
