// top-level keys must match reducer names, https://redux.js.org/recipes/structuring-reducers/using-combinereducers
import { ANALYSES_INITIAL_STATE } from "./analyses.state";
import { ANALYSES_ACTIONS } from "./analyses.actionTypes";

const updateAnalyses = (state, action) => {
    return {
        ...state,
        analyses: action.data.analyses,
    };
};

const ACTION_HANDLERS = {
    [ANALYSES_ACTIONS.UPDATE_ANALYSES]: updateAnalyses,
};

export const analysesReducer = (state = ANALYSES_INITIAL_STATE, action) => {
    if (ACTION_HANDLERS[action.type]) {
        return ACTION_HANDLERS[action.type](state, action);
    }

    return state;
};
