import React, { memo, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { StlButton } from "@common/components/button/button";
import { getFileSizeString, getFileErrorString, IFileValidation } from "../fileUpload.helpers";
import { DEFAULT_FILE_MAX_SIZE, DEFAULT_FILE_TYPES, UPLOAD_ERRORS } from "../fileUpload.constants";
import "./filesList.less";

const FileInfo = ({
    file,
    onRemove,
    maxSize,
    allowedTypes,
    fileValidation = {},
}: {
    file: File;
    maxSize: number;
    allowedTypes: Array<string>;
    fileValidation?: IFileValidation;
    onRemove: (name: string) => void;
}) => {
    const fileError = getFileErrorString(file, maxSize, allowedTypes, fileValidation);
    const fileErrorContent = useMemo(() => {
        if (fileError === UPLOAD_ERRORS.FILE_SIZE) {
            return (
                <div>
                    {fileError}
                    <StlButton className="link" href="/zones" target="_blank" rel="noreferrer">
                        Zone Sets section of InSight <img src="/img/arrow-link.svg" alt="" />
                    </StlButton>
                </div>
            );
        } else {
            return <div>{fileError}</div>;
        }
    }, [fileError]);

    return (
        <>
            <li className="file-info-wrapper">
                <div className="file-info">
                    <span className="file-name">{file.name}</span>
                    <span className="file-size">{getFileSizeString(file.size)}</span>
                </div>
                <StlButton
                    className="close-button"
                    variant="naked"
                    size="sm"
                    ariaLabel="Remove file from upload list"
                    onClick={() => onRemove(file.name)}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </StlButton>
            </li>
            {fileError && (
                <div className="upload-error">
                    <FontAwesomeIcon icon={faExclamationTriangle} role="img" aria-label="error" />
                    {fileErrorContent}
                </div>
            )}
        </>
    );
};

type TProps = {
    maxSize: number;
    listTitle: string;
    files: Array<File>;
    allowedTypes: Array<string>;
    fileValidation?: IFileValidation;
    removeFile: (name: string) => void;
};

export const StlFilesList = memo(
    ({
        files,
        removeFile = () => {},
        listTitle,
        maxSize = DEFAULT_FILE_MAX_SIZE,
        allowedTypes = DEFAULT_FILE_TYPES,
        fileValidation,
    }: TProps) => {
        if (!files.length) return null;

        return (
            <div className="stl-files-list">
                {listTitle && <div className="files-list-title">{listTitle}</div>}
                <div className="list-header">
                    <span className="file-name">File Name</span>
                    <span className="file-size">File Size</span>
                </div>
                <ul className="files-list">
                    {files.map(file => (
                        <FileInfo
                            key={file.lastModified}
                            file={file}
                            onRemove={removeFile}
                            maxSize={maxSize}
                            allowedTypes={allowedTypes}
                            fileValidation={fileValidation}
                        />
                    ))}
                </ul>
            </div>
        );
    },
);
