import { BASICS_INITIAL_STATE } from "@app/analysis/basics/state/basics.state";
import { REVIEW_INITIAL_STATE } from "@app/analysis/review/state/review.state";
import { CHOOSE_ZONES_INITIAL_STATE } from "@app/analysis/zones/chooseZones/state/chooseZones.state";
import { TIME_PERIODS_INITIAL_STATE } from "@app/analysis/timePeriods/state/timePeriods.state";
import { TMC_CHOOSE_ZONES_INITIAL_STATE } from "@app/analysis/zones/chooseZones/configurations/tmc/state/tmcChooseZones.state";
import { DRAFT_INITIAL_STATE } from "@app/analysis/draftAnalysis/state/draftAnalysis.state";
import { ADD_ONS_INITIAL_STATE } from "@app/analysis/addOns/state/addOns.state";
import { GENERAL_INITIAL_STATE } from "@app/analysis/state/general/general.state";

export const ANALYSIS_CONFIGURATION_INITIAL_STATE = {
    general: GENERAL_INITIAL_STATE,
    basics: BASICS_INITIAL_STATE,
    addOns: ADD_ONS_INITIAL_STATE,
    timePeriods: TIME_PERIODS_INITIAL_STATE,
    review: REVIEW_INITIAL_STATE,
    chooseZones: CHOOSE_ZONES_INITIAL_STATE,
    tmcChooseZones: TMC_CHOOSE_ZONES_INITIAL_STATE,
    draft: DRAFT_INITIAL_STATE,
};
