import { COLORS } from "@common/constants/color.constants";
import { ValuesOfType } from "@common/utils/utility.types";

export const DEFAULT_WAYS_COUNT = 4;

export const DEFAULT_LAST_WAY_INDEX = DEFAULT_WAYS_COUNT - 1;

export const GATE_DIRECTION_OPTIONS = {
    WEST_EAST: [
        { value: "eastbound", label: "Eastbound" },
        { value: "westbound", label: "Westbound" },
    ],
    NORTH_SOUTH: [
        { value: "northbound", label: "Northbound" },
        { value: "southbound", label: "Southbound" },
    ],
};
export type TGateDirectionOption =
    typeof GATE_DIRECTION_OPTIONS[keyof typeof GATE_DIRECTION_OPTIONS];

export const GATE_TYPE_OPTIONS = {
    North: {
        value: "North",
        label: "North",
        direction: GATE_DIRECTION_OPTIONS.NORTH_SOUTH,
    },
    East: {
        value: "East",
        label: "East",
        direction: GATE_DIRECTION_OPTIONS.WEST_EAST,
    },
    South: {
        value: "South",
        label: "South",
        direction: GATE_DIRECTION_OPTIONS.NORTH_SOUTH,
    },
    West: {
        value: "West",
        label: "West",
        direction: GATE_DIRECTION_OPTIONS.WEST_EAST,
    },
} as const;

export const GATE_TYPE_OPTIONS_LIST = Object.values(GATE_TYPE_OPTIONS);
export type TGateType = keyof typeof GATE_TYPE_OPTIONS;

export const INTERSECTION_GATES = {
    NORTH: {
        role: "North",
        color: COLORS.RED,
    },
    EAST: {
        role: "East",
        color: COLORS.BLUE,
    },
    SOUTH: {
        role: "South",
        color: COLORS.GREEN,
    },
    WEST: {
        role: "West",
        color: COLORS.YELLOW,
    },
} as const;

export const INTERSECTION_GATES_LIST = Object.values(INTERSECTION_GATES);
export type IIntersectionGate = keyof typeof INTERSECTION_GATES;
export type IIntersectionRole = typeof INTERSECTION_GATES_LIST[number]["role"];
export type IIntersectionColor = typeof INTERSECTION_GATES_LIST[number]["color"];

export const INTERSECTION_GATE_DIRECTIONS = {
    NORTHBOUND: "Northbound",
    SOUTHBOUND: "Southbound",
    WESTBOUND: "Westbound",
    EASTBOUND: "Eastbound",
} as const;
export type TIntersectionGateDirectionOption = ValuesOfType<typeof INTERSECTION_GATE_DIRECTIONS>;

export const INBOUND_GATE_DIRECTIONS = {
    North: INTERSECTION_GATE_DIRECTIONS.SOUTHBOUND,
    South: INTERSECTION_GATE_DIRECTIONS.NORTHBOUND,
    West: INTERSECTION_GATE_DIRECTIONS.EASTBOUND,
    East: INTERSECTION_GATE_DIRECTIONS.WESTBOUND,
};

export const OUTBOUND_GATE_DIRECTIONS = {
    North: INTERSECTION_GATE_DIRECTIONS.NORTHBOUND,
    South: INTERSECTION_GATE_DIRECTIONS.SOUTHBOUND,
    West: INTERSECTION_GATE_DIRECTIONS.WESTBOUND,
    East: INTERSECTION_GATE_DIRECTIONS.EASTBOUND,
};

export const INTERSECTION_GATES_CIRCLE_RADIUS = 20;

export const OUTBOUND_TRIP_DIRECTIONS = {
    North: {
        South: "thru",
        West: "left",
        East: "right",
        North: "noDirection",
    },
    South: {
        North: "thru",
        East: "left",
        West: "right",
        South: "noDirection",
    },
    West: {
        East: "thru",
        South: "left",
        North: "right",
        West: "noDirection",
    },
    East: {
        West: "thru",
        North: "left",
        South: "right",
        East: "noDirection",
    },
} as const;

export const OUTBOUND_ICON_OFFSET = {
    North: {
        South: [0, 0],
        West: [-20, 0],
        East: [20, 0],
        North: [0, 0],
    },
    South: {
        North: [0, 0],
        East: [-20, 0],
        West: [20, 0],
        South: [0, 0],
    },
    West: {
        East: [0, 0],
        South: [-20, 0],
        North: [20, 0],
        West: [0, 0],
    },
    East: {
        West: [0, 0],
        North: [-20, 0],
        South: [20, 0],
        East: [0, 0],
    },
};

export const INBOUND_TRIP_DIRECTIONS = {
    North: {
        South: "thru",
        East: "left",
        West: "right",
    },
    South: {
        North: "thru",
        West: "left",
        East: "right",
    },
    West: {
        East: "thru",
        North: "left",
        South: "right",
    },
    East: {
        West: "thru",
        South: "left",
        North: "right",
    },
} as const;

export const GATE_DIRECTIONS = {
    LEFT: { label: "Left", id: "left" },
    THRU: { label: "Thru", id: "thru" },
    RIGHT: { label: "Right", id: "right" },
} as const;
export const GATE_DIRECTIONS_LIST = Object.values(GATE_DIRECTIONS);
export type TTripGateDirectionKey = keyof typeof GATE_DIRECTIONS;
export type TTripGateDirection = typeof GATE_DIRECTIONS[keyof typeof GATE_DIRECTIONS];
