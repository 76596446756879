import { v4 as uuidv4 } from "uuid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    ICSCorridorGroup,
    TCSGroupSelectionMode,
    TCSCorridorSubGroupType,
    ICSSegment,
    TCSSegmentId,
    ICorridorGroupCreation,
    ICorridorGroupCreationMap,
} from "@app/viz3/csVisualization/state/csViz.types";
import {
    sortByOrderIndex,
    getNextGroupIdByOrderIndex,
    getPrevGroupIdByOrderIndex,
    reevaluateAllOrderIndexes,
    removeEmptyGroupIdsFromChildIds,
} from "@app/viz3/csVisualization/state/csViz.helpers";
import { TCSStatsAndScores } from "@common/services/server/layerGroupApi.types";
import { CS_VIZ_INITIAL_STATE } from "./csViz.state";
import { ECorridorSubGroupType, ICSVizState, ISegmentLayers } from "./csViz.types";

export const { actions, reducer } = createSlice({
    name: "csViz",
    initialState: CS_VIZ_INITIAL_STATE,
    reducers: {
        setCSVizState: (state, action: PayloadAction<ICSVizState>) => {
            return action.payload;
        },
        resetCSVizState: () => {
            return CS_VIZ_INITIAL_STATE;
        },
        setSegmentLayers: (state, action: PayloadAction<ISegmentLayers>) => {
            state.segmentLayers = action.payload;
        },
        addFetchedCorridorGroups: (
            state,
            action: PayloadAction<{
                corridorGroups: ICSVizState["filterGroups"]["corridorGroups"];
                planningGroupIdsToOperationalGroupsIds: ICSVizState["filterGroups"]["planningGroupIdsToOperationalGroupsIds"];
            }>,
        ) => {
            const { corridorGroups, planningGroupIdsToOperationalGroupsIds } = action.payload;

            const ids: Array<string> = [];
            Object.values(corridorGroups).forEach(group => {
                if (group.type !== ECorridorSubGroupType.Corridor) return;

                ids.push(group.id);
            });

            state.filterGroups.corridorGroups = corridorGroups;
            state.filterGroups.corridorGroupIds = ids;
            state.filterGroups.planningGroupIdsToOperationalGroupsIds =
                planningGroupIdsToOperationalGroupsIds;
        },
        openGroupCreationModal: (state, action: PayloadAction<{ projectRouteId: string }>) => {
            state.corridorGroupCreation.isCorridorGroupCreationOpened = true;
            state.corridorGroupCreation.selectedProjectRouteGroupId =
                action.payload.projectRouteId;

            const corridorGroupId = uuidv4();

            // set initial (main) corridorGroup id
            state.corridorGroupCreation.mainCorridorGroupId = corridorGroupId;
            state.corridorGroupCreation.corridorGroups[corridorGroupId] = {
                id: corridorGroupId,
                type: ECorridorSubGroupType.Corridor,
                name: "Corridor Groups",
                parentId: null,
                childIds: [],
                orderIndex: 0,
            };
        },
        closeGroupCreationModal: state => {
            state.corridorGroupCreation.isCorridorGroupCreationOpened = false;
        },
        openUpdateCorridorGroupModal: (
            state,
            action: PayloadAction<{
                projectRouteId: string;
                corridorGroupId: string;
            }>,
        ) => {
            const { corridorGroupId, projectRouteId } = action.payload;

            state.corridorGroupCreation.corridorGroupBeingUpdatedId = corridorGroupId;
            state.corridorGroupCreation.isCorridorGroupCreationOpened = true;
            state.corridorGroupCreation.selectedProjectRouteGroupId = projectRouteId;
        },
        setCorridorGroupCreationConfig: (
            state,
            action: PayloadAction<Partial<ICorridorGroupCreation>>,
        ) => {
            state.corridorGroupCreation = {
                ...state.corridorGroupCreation,
                ...action.payload,
            };
        },
        setCorridorGroupCreationMapConfig: (
            state,
            action: PayloadAction<Partial<ICorridorGroupCreationMap>>,
        ) => {
            state.corridorGroupCreation.map = {
                ...state.corridorGroupCreation.map,
                ...action.payload,
            };
        },
        setGroupCreationMode: (
            state,
            action: PayloadAction<{
                type: TCSGroupSelectionMode;
            }>,
        ) => {
            const { type } = action.payload;

            state.corridorGroupCreation.groupSelectionMode = type;
        },
        confirmSubGroupCreation: (
            state,
            action: PayloadAction<{ segments: Record<string, ICSSegment> }>,
        ) => {
            const mainCorridorGroupId = state.corridorGroupCreation.mainCorridorGroupId;

            if (!mainCorridorGroupId) return;

            const corridorSubGroupId = uuidv4();
            const groupSelectionMode = state.corridorGroupCreation.groupSelectionMode;

            const getSegmentOrderIndexById = (id: string) =>
                action.payload.segments[id].orderIndex;
            const getGroupOrderIndexById = (id: string) =>
                state.corridorGroupCreation.corridorGroups[id].orderIndex;

            const getOrderIndexAndChildIds = () => {
                let sortedChildIds: Array<string> = [];
                let getNodeOrderIndex;
                if (state.corridorGroupCreation.selectedSegmentIds.length > 0) {
                    sortedChildIds = [...state.corridorGroupCreation.selectedSegmentIds].sort(
                        (a, b) => sortByOrderIndex(a, b, getSegmentOrderIndexById),
                    );
                    getNodeOrderIndex = getSegmentOrderIndexById;
                } else {
                    sortedChildIds = [
                        ...state.corridorGroupCreation.selectedCorridorGroupIds,
                    ].sort((a, b) => sortByOrderIndex(a, b, getGroupOrderIndexById));
                    getNodeOrderIndex = getGroupOrderIndexById;
                }

                return {
                    childIds: sortedChildIds,
                    orderIndex: getNodeOrderIndex(sortedChildIds[0]),
                };
            };

            const { childIds, orderIndex } = getOrderIndexAndChildIds();

            const corridorSubGroup: Omit<ICSCorridorGroup, "name"> = {
                id: corridorSubGroupId,
                type: groupSelectionMode,
                parentId: mainCorridorGroupId,
                childIds,
                orderIndex,
            };

            // Remove groups child groups from direct children of main group
            const newMainCorridorGroupChildIds = state.corridorGroupCreation.corridorGroups[
                mainCorridorGroupId
            ].childIds.filter(childId => !childIds.includes(childId));

            // Add new group to state
            const name = `${groupSelectionMode} Group ${state.corridorGroupCreation[
                `${groupSelectionMode.toLowerCase()}GroupsCounter` as
                    | "planningGroupsCounter"
                    | "operationalGroupsCounter"
            ]++}`;

            state.corridorGroupCreation.corridorGroups[corridorSubGroupId] = {
                ...corridorSubGroup,
                name,
            };

            state.corridorGroupCreation.corridorGroups[mainCorridorGroupId].childIds = [
                ...newMainCorridorGroupChildIds,
                corridorSubGroupId,
            ].sort((a, b) => sortByOrderIndex(a, b, getGroupOrderIndexById));

            // Change all child group parentId to id of current group.
            state.corridorGroupCreation.corridorGroups[corridorSubGroupId].childIds.forEach(
                childId => {
                    // child can be a segment. Segments don't need a parentId as it's only used to delete subGroups
                    if (!state.corridorGroupCreation.corridorGroups[childId]) return;

                    state.corridorGroupCreation.corridorGroups[childId].parentId =
                        corridorSubGroupId;
                },
            );

            // Clear selection arrays
            state.corridorGroupCreation.selectedSegmentIds = [];
            state.corridorGroupCreation.selectedCorridorGroupIds = [];
        },
        deleteSubGroup: (state, action: PayloadAction<{ subGroupId: string }>) => {
            const { subGroupId } = action.payload;

            // parent id could only be null for the very top corridorGroup
            const parentId = state.corridorGroupCreation.corridorGroups[subGroupId].parentId;

            if (!parentId) return;

            state.corridorGroupCreation.corridorGroups[parentId].childIds =
                state.corridorGroupCreation.corridorGroups[parentId].childIds.filter(
                    childId => childId !== subGroupId,
                );
            state.corridorGroupCreation.corridorGroups[subGroupId].childIds.forEach(childId => {
                // child can be a segment. Segments don't need a parentId as it's only used to delete subGroups
                if (!state.corridorGroupCreation.corridorGroups[childId]) return;

                state.corridorGroupCreation.corridorGroups[childId].parentId = parentId;

                state.corridorGroupCreation.corridorGroups[parentId].childIds.push(childId);
            });
            delete state.corridorGroupCreation.corridorGroups[subGroupId];
        },
        setSubGroupName: (state, action: PayloadAction<{ name: string; subGroupId: string }>) => {
            const { name, subGroupId } = action.payload;

            if (!state.corridorGroupCreation.corridorGroups[subGroupId]) return;

            state.corridorGroupCreation.corridorGroups[subGroupId].name = name;
        },
        resetAllSelectionToPlainSegments: state => {
            const mainCorridorGroupId = state.corridorGroupCreation.mainCorridorGroupId;

            if (!mainCorridorGroupId) return;

            // Remove all corridor subgroups and leave only main one
            state.corridorGroupCreation.corridorGroups = {
                [mainCorridorGroupId]: {
                    ...state.corridorGroupCreation.corridorGroups[mainCorridorGroupId],
                    childIds: [],
                },
            };

            // Clear corridor group selections
            state.corridorGroupCreation.selectedSegmentIds = [];
            state.corridorGroupCreation.selectedCorridorGroupIds = [];
            state.corridorGroupCreation.groupSelectionMode = ECorridorSubGroupType.None;
        },
        resetCorridorGroupCreation: state => {
            state.corridorGroupCreation = CS_VIZ_INITIAL_STATE.corridorGroupCreation;
        },
        moveSegmentUpOrDown: (
            state,
            action: PayloadAction<{
                segmentId: string;
                segmentParentId: string;
                segmentOrderIndex: number;
                requiredNodeType?: TCSCorridorSubGroupType;
                shouldMoveUpAGroup: boolean;
                segments: Record<string, ICSSegment>;
            }>,
        ) => {
            if (!state.corridorGroupCreation.mainCorridorGroupId) return;

            const {
                segmentId,
                segmentParentId,
                segmentOrderIndex,
                requiredNodeType,
                shouldMoveUpAGroup,
            } = action.payload;

            const getGroupById = (id: string) => state.corridorGroupCreation.corridorGroups[id];
            const getSegmentById = (id: string) => action.payload.segments[id];
            const getNodeById = (id: string) => getGroupById(id) ?? getSegmentById(id);

            const getNewParentGroupIdHelperFunc = shouldMoveUpAGroup
                ? getPrevGroupIdByOrderIndex
                : getNextGroupIdByOrderIndex;

            const newParentGroupId = getNewParentGroupIdHelperFunc({
                nodeId: state.corridorGroupCreation.mainCorridorGroupId!,
                orderIndex: segmentOrderIndex,
                getGroupById,
                getSegmentById,
                requiredGroupType: requiredNodeType,
            });

            // newParentGroupId is null if no appropriate position for given segment was found
            if (!newParentGroupId) return;

            const segmentParentGroup = state.corridorGroupCreation.corridorGroups[segmentParentId];
            const segmentNewParentGroup =
                state.corridorGroupCreation.corridorGroups[newParentGroupId];

            // Move segment from prev parent to new parental node
            // Remove segmentId from prev parent group child ids and add to new parent group child ids
            // Note: We can just shift-push/pop-unshift them as they are all sorted by orderIndexes.
            if (shouldMoveUpAGroup) {
                segmentParentGroup.childIds.shift();
                segmentNewParentGroup.childIds.push(segmentId);
            } else {
                segmentParentGroup.childIds.pop();
                segmentNewParentGroup.childIds.unshift(segmentId);
            }

            // delete previous parental node (subGroup) if it has no more children
            if (!segmentParentGroup.childIds.length) {
                const higherGroupId = segmentParentGroup.parentId!;
                state.corridorGroupCreation.corridorGroups[higherGroupId].childIds =
                    state.corridorGroupCreation.corridorGroups[higherGroupId].childIds.filter(
                        id => id !== segmentParentId,
                    );
                delete state.corridorGroupCreation.corridorGroups[segmentParentId];

                if (!state.corridorGroupCreation.corridorGroups[higherGroupId].childIds.length) {
                    delete state.corridorGroupCreation.corridorGroups[higherGroupId];
                }
            }

            removeEmptyGroupIdsFromChildIds({
                id: state.corridorGroupCreation.mainCorridorGroupId,
                getNodeById,
            });

            reevaluateAllOrderIndexes({
                id: state.corridorGroupCreation.mainCorridorGroupId,
                getNodeById,
            });
        },
        moveOperationalGroupUpOrDown: (
            state,
            action: PayloadAction<{
                originalOperationalGroupId: string;
                shouldMoveUpAGroup: boolean;
                segments: Record<TCSSegmentId, ICSSegment>;
            }>,
        ) => {
            if (!state.corridorGroupCreation.mainCorridorGroupId) return;

            const { originalOperationalGroupId, shouldMoveUpAGroup } = action.payload;

            const getGroupById = (id: string) => state.corridorGroupCreation.corridorGroups[id];
            const getSegmentById = (id: string) => action.payload.segments[id];
            const getNodeById = (id: string) => getGroupById(id) ?? getSegmentById(id);

            const getNewParentGroupIdHelperFunc = shouldMoveUpAGroup
                ? getPrevGroupIdByOrderIndex
                : getNextGroupIdByOrderIndex;

            const originalOperationalGroup = getGroupById(originalOperationalGroupId);
            const originalOperationalGroupParentId = originalOperationalGroup.parentId!;
            const originalOperationalGroupChildIds = originalOperationalGroup.childIds;

            const segmentIndexToPretend = shouldMoveUpAGroup
                ? 0
                : originalOperationalGroupChildIds.length - 1;

            const pretendSegmentId = originalOperationalGroupChildIds[segmentIndexToPretend];
            const childSegmentOrderIndex = getSegmentById(pretendSegmentId).orderIndex;

            // Algorithm is the same as for segment with small changes:
            // We pretend, that we want to move first (or last) child segment (with if of pretendSegmentId) from given operational group.
            // After we get operationalGroupIdForSegment (to which original child segment was meant to be transplanted)
            // we get his parent group and move our operational group to its child ids.
            const operationalGroupIdForSegment = getNewParentGroupIdHelperFunc({
                nodeId: state.corridorGroupCreation.mainCorridorGroupId!,
                orderIndex: childSegmentOrderIndex,
                getGroupById,
                getSegmentById,
            });

            // operationalGroupIdForSegment is null if no appropriate position for given node was found
            if (!operationalGroupIdForSegment) return;

            const newParentPlanningGroupId =
                state.corridorGroupCreation.corridorGroups[operationalGroupIdForSegment].parentId!;

            // Remove operationalGroupId from prev planning group child ids and add to new planning group child ids
            // Note: We can just shift-push/pop-unshift them as they are all sorted by orderIndexes.
            if (shouldMoveUpAGroup) {
                state.corridorGroupCreation.corridorGroups[
                    originalOperationalGroupParentId
                ].childIds.shift();
                state.corridorGroupCreation.corridorGroups[newParentPlanningGroupId].childIds.push(
                    originalOperationalGroupId,
                );
            } else {
                state.corridorGroupCreation.corridorGroups[
                    originalOperationalGroupParentId
                ].childIds.pop();
                state.corridorGroupCreation.corridorGroups[
                    newParentPlanningGroupId
                ].childIds.unshift(originalOperationalGroupId);
            }

            // Set new parent id for originalOperationalGroup
            originalOperationalGroup.parentId = newParentPlanningGroupId;

            // delete previous parental node (subGroup) if it has no more children
            if (
                !state.corridorGroupCreation.corridorGroups[originalOperationalGroupParentId]
                    .childIds.length
            ) {
                const higherGroupId =
                    state.corridorGroupCreation.corridorGroups[originalOperationalGroupParentId]
                        .parentId!;
                state.corridorGroupCreation.corridorGroups[higherGroupId].childIds =
                    state.corridorGroupCreation.corridorGroups[higherGroupId].childIds.filter(
                        id => id !== originalOperationalGroupParentId,
                    );
                delete state.corridorGroupCreation.corridorGroups[
                    originalOperationalGroupParentId
                ];
            }

            removeEmptyGroupIdsFromChildIds({
                id: state.corridorGroupCreation.mainCorridorGroupId,
                getNodeById,
            });

            reevaluateAllOrderIndexes({
                id: state.corridorGroupCreation.mainCorridorGroupId,
                getNodeById,
            });
        },
        editCorridorFilterGroup: (
            state,
            action: PayloadAction<{
                corridorFilterGroupId: string;
            }>,
        ) => {
            const { corridorFilterGroupId } = action.payload;

            state.corridorGroupCreation = {
                ...CS_VIZ_INITIAL_STATE.corridorGroupCreation,
                corridorGroups: state.filterGroups.corridorGroups,
                mainCorridorGroupId: corridorFilterGroupId,
                isCorridorGroupCreationOpened: true,
            };
        },
        updateStatsAndScores: (state, action: PayloadAction<TCSStatsAndScores>) => {
            state.statsAndScores = action.payload;
        },
    },
});

export const csVizReducer = reducer;
