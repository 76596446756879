import { IBin } from "@app/analysis/addOns/state/addOns.types";

export const getIncrementedRange = ({
    startPoint = 0,
    endPoint,
    increment,
    shouldAddFinalBin = false,
}: {
    startPoint?: number;
    endPoint?: number | null;
    increment?: number | null;
    shouldAddFinalBin?: boolean;
}) => {
    if (!endPoint) return [{ start: startPoint }];
    if (!increment || startPoint === endPoint) return [{ start: startPoint, end: endPoint }];

    const length = Math.trunc((endPoint - startPoint) / increment);
    const ranges = Array.from({ length }, (_, index) => ({
        start: index * increment + startPoint,
        end: (index + 1) * increment + startPoint,
    })) as Array<IBin>;
    if (shouldAddFinalBin) {
        ranges.push({ start: endPoint });
    }
    return ranges;
};

export const getOnlyStartPresetFromArray = (values: Array<number>) =>
    values.map(start => ({ start, onlyStartValue: true }));

export const getOnlyStartPresetRange = (
    startPoint: number,
    endPoint: number | undefined,
    increment: number | undefined,
) => {
    if (!endPoint || startPoint === endPoint) {
        return getOnlyStartPresetFromArray([startPoint]);
    }

    if (!increment) {
        return getOnlyStartPresetFromArray([startPoint, endPoint]);
    }

    const length = Math.trunc((endPoint - startPoint) / increment) + 1;

    return Array.from({ length }, (_, index) => ({
        start: index * increment + startPoint,
        end: "",
        onlyStartValue: true,
    }));
};

export const getPresetData = (preset: any) => (typeof preset === "function" ? preset() : preset);
