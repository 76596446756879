import { HTMLAttributes, MouseEvent, ReactNode } from "react";
import { StlButton } from "@common/components/button/button";
import "./toggleButton.less";

export type TProps = Omit<HTMLAttributes<HTMLButtonElement>, "onClick" | "onChange"> & {
    id: string;
    size?: "xs" | "sm" | "md" | "lg";
    children: ReactNode;
    isSelected?: boolean;
    isDisabled?: boolean;
    onClick?: (e: MouseEvent, id: string) => void;
    onChange?: (e: MouseEvent, id: string) => void;
};

export const StlToggleButton = ({
    id,
    size = "xs",
    children,
    isSelected,
    isDisabled,
    onClick,
    onChange,
    ...restProps
}: TProps) => {
    const handleClick = (e: MouseEvent) => {
        if (!id) return;

        if (onClick) {
            onClick(e, id);

            if (e.isDefaultPrevented()) return;
        }

        if (onChange) {
            onChange(e, id);
        }
    };

    return (
        <StlButton
            id={id}
            size={size}
            className="stl-toggle-button"
            variant={isSelected ? "primary" : "secondary"}
            disabled={isDisabled}
            onClick={handleClick}
            aria-pressed={isSelected}
            {...restProps}
        >
            {children}
        </StlButton>
    );
};

StlToggleButton.displayName = "StlToggleButton";
