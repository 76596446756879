import {
    useCallback,
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
    MouseEventHandler,
    AriaAttributes,
    ReactNode,
} from "react";
import classNames from "classnames";
import { Menu, MenuItem, MenuProps } from "@material-ui/core";
import { useMounted } from "@common/utils/useMounted";
import "./menu.less";

export interface IStlMenuHandlers {
    close: () => void;
}

export type TMenuOption = {
    id: string;
    label: ReactNode;
    disabled?: boolean;
};

export type TMenuControl = {
    id: string;
    "aria-controls": AriaAttributes["aria-controls"];
    "aria-expanded": AriaAttributes["aria-expanded"];
    "aria-haspopup": AriaAttributes["aria-haspopup"];
};

type TProps = Omit<MenuProps, "onClose" | "onSelect" | "open"> & {
    Control: (props: TMenuControl) => JSX.Element;
    options: Array<TMenuOption | null>;
    disableCloseOnSelect?: boolean;
    onClose?: () => void;
    onSelect?: (value: string) => void;
    setActiveMenu?: (value: boolean) => void;
};

const DEFAULT_ID = "stl-menu";

type TInitialState = {
    anchorEl: Element | null;
};

const INITIAL_STATE: TInitialState = {
    anchorEl: null,
};

export const StlMenu = forwardRef(
    (
        {
            id = DEFAULT_ID,
            className,
            Control,
            options = [],
            disableCloseOnSelect,
            onSelect = () => {},
            onClose = () => {},
            setActiveMenu = () => {},
            ...restProps
        }: TProps,
        ref,
    ) => {
        const [anchorEl, setAnchorEl] = useState<TInitialState["anchorEl"]>(
            INITIAL_STATE.anchorEl,
        );

        const isMounted = useMounted();

        const controlId = `${id}Control`;

        const handleControlClick = useCallback(
            e => {
                setAnchorEl(e.currentTarget);
                setActiveMenu(true);
            },
            [setActiveMenu],
        );

        const close = () => {
            if (isMounted.current) {
                setAnchorEl(INITIAL_STATE.anchorEl);
                setActiveMenu(false);
            }
            onClose();
        };

        // allows to close menu from the parent component
        useImperativeHandle(ref, () => ({
            close: () => close(),
        }));

        useEffect(() => {
            const controlEl = document.getElementById(controlId);

            if (!controlEl) return undefined;

            controlEl.addEventListener("click", handleControlClick);

            return () => {
                controlEl.removeEventListener("click", handleControlClick);
            };
        }, [controlId, handleControlClick, Control]);

        const handleOptionClick: MouseEventHandler<HTMLLIElement> = (
            e: React.MouseEvent<HTMLLIElement>,
        ) => {
            onSelect(e.currentTarget.id);

            if (!disableCloseOnSelect) {
                close();
            }
        };

        const mountMenu = () => (
            <Menu
                id={id}
                className={classNames("stl-menu", className)}
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={close}
                disableAutoFocusItem
                getContentAnchorEl={null}
                {...restProps}
            >
                {options.map(option => {
                    if (!option) return null;

                    return (
                        <MenuItem
                            key={option.id}
                            id={option.id}
                            onClick={handleOptionClick}
                            role="button"
                            disableRipple
                            disabled={option.disabled}
                        >
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Menu>
        );

        return (
            <>
                <Control
                    id={controlId}
                    aria-controls={anchorEl ? id : ""}
                    aria-haspopup="true"
                    aria-expanded={!!anchorEl}
                />
                {mountMenu()}
            </>
        );
    },
);
