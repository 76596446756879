import React, { HTMLAttributes, ReactNode } from "react";
// @ts-ignore
import { ReactTableDefaults } from "react-table";
import classnames from "classnames";

const checkIfNestedComponentsHaveListeners = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>,
) => {
    if ((e.target as HTMLElement).className.includes("checkbox")) return true;
    if ((e.target as HTMLElement).className.includes("radio")) return true;

    return false;
};

export const TrGroupComponent = ({
    children,
    className,
    ...rest
}: HTMLAttributes<HTMLDivElement>) => {
    return (
        <div className={classnames("rt-tr-group", className)} role="none" {...rest}>
            {children}
        </div>
    );
};

export const TbodyComponent = ({
    children,
    className,
    ...rest
}: HTMLAttributes<HTMLDivElement>) => {
    // First child contains rows with valid data.  If zero-length, then use role="none".
    // @ts-ignore
    const role = children && children.length >= 1 && !children[0].length ? "none" : "rowgroup";

    return (
        <div className={classnames("rt-tbody", className)} role={role} {...rest}>
            {children}
        </div>
    );
};

/*
    To implement accessible tab ordering (label -> filter -> label) we moved column labels to
    filters row.
 */
export const ThComponent = ({
    toggleSort = () => {},
    className = "",
    children,
    sortable,
    testid,
    HeaderComponent,
    headerProps,
    columnSortDirection,
    ...rest
}: HTMLAttributes<HTMLDivElement> & {
    testid?: string;
    sortable?: boolean;
    HeaderComponent: ReactNode;
    columnSortDirection?: string;
    headerProps: HTMLAttributes<HTMLDivElement>;
    toggleSort: (
        e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    ) => void;
}) => {
    const sortIconClassname = columnSortDirection
        ? columnSortDirection === "asc"
            ? "-sort-asc"
            : "-sort-desc"
        : null;

    const ariaSort = columnSortDirection
        ? columnSortDirection === "asc"
            ? "ascending"
            : "descending"
        : "none";

    /*
        HeaderComponent can be function, object or simple string.
        In case of function React will throw warning a won't render it as a component.
        We need to pass this function as a React component.
    */
    const columnLabel =
        typeof HeaderComponent === "function" ? <HeaderComponent /> : HeaderComponent;

    return (
        <div className={classnames("rt-th", className)} role="columnheader" {...rest}>
            <div
                role={sortable ? "button" : undefined}
                className={classnames("column-label", sortIconClassname)}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => toggleSort(e)}
                onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) =>
                    e.keyCode === 13 && toggleSort(e)
                }
                tabIndex={sortable ? 0 : -1}
                data-testid={testid}
                aria-sort={ariaSort}
                {...headerProps}
            >
                {columnLabel}
            </div>
            <div className="column-filter">{children}</div>
        </div>
    );
};

export const TdComponent = ({ className, children, ...rest }: HTMLAttributes<HTMLDivElement>) => {
    const role =
        // @ts-ignore
        children && children.type && children.type.name === "PadRowComponent"
            ? "none"
            : "gridcell";

    return (
        <div className={classnames("rt-td", className)} role={role} {...rest}>
            {children}
        </div>
    );
};

export const TrComponent = ({
    className,
    children,
    onClick,
    isHighlighted,
    ...rest
}: Omit<HTMLAttributes<HTMLDivElement>, "onClick"> & {
    isHighlighted?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => void;
}) => {
    const onRowClick = (
        e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    ) => {
        // We don't want to trigger row click if the user clicked nested interactive element.
        if (onClick && !checkIfNestedComponentsHaveListeners(e)) {
            onClick(e);
        }
    };
    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.code === "Enter" || e.code === "Space") {
            onRowClick(e);
        }
    };

    const _className = classnames(
        "rt-tr",
        className,
        isHighlighted && "highlighted",
        onClick ? "clickable-row" : "non-clickable-row",
    );
    const role = className?.includes("padRow") ? "none" : "row";
    // make row focusable only when it is clickable
    const tabIndex = onClick ? 0 : -1;

    return (
        <div
            className={_className}
            role={role}
            onClick={onRowClick}
            onKeyPress={handleKeyPress}
            tabIndex={tabIndex}
            {...rest}
        >
            {children}
        </div>
    );
};

export const TheadComponent = ({ className, children, style }: HTMLAttributes<HTMLDivElement>) => {
    // Do not render header row with column label
    if (className === "-header") return null;

    return (
        <div className="rt-thead" style={style}>
            {children}
        </div>
    );
};

export const LoadingComponent = ({
    className,
    loading,
    loadingText,
    ...rest
}: HTMLAttributes<HTMLDivElement> & {
    loading: boolean;
    loadingText: ReactNode;
}) => (
    <div
        className={classnames("-loading", { "-active": loading }, className)}
        data-testid="table-loading"
        {...rest}
    >
        <div className="-loading-inner">{loadingText}</div>
    </div>
);

// https://github.com/tannerlinsley/react-table/tree/v6#component-overrides
// Change the global default
Object.assign(ReactTableDefaults, {
    TheadComponent,
    TbodyComponent,
    TrGroupComponent,
    ThComponent,
    TrComponent,
    TdComponent,
    LoadingComponent,
});
