import { useState, useEffect } from "react";
import { Map } from "mapbox-gl";
import { MAP_DRAW_EVENTS } from "@common/components/baseMap/baseMap.constants";

export const useMeasurementToolActive = (map: Map | null): boolean => {
    const [isActivated, setIsActivated] = useState<boolean>(false);

    useEffect(() => {
        if (!map) return undefined;

        const toggleMeasurementToolState = ({ isActive }: { isActive: boolean }) =>
            setIsActivated(isActive);

        map.on(MAP_DRAW_EVENTS.MEASUREMENT, toggleMeasurementToolState);

        return () => {
            map.off(MAP_DRAW_EVENTS.MEASUREMENT, toggleMeasurementToolState);
        };
    }, [map]);

    return isActivated;
};
