import { MAP_STYLES } from "@app/viz3/odVisualization/map/mapStyles.constants";
import { OD_LAYERS } from "@app/viz3/odVisualization/state/odViz.constants";

export const OD_VIZ_INITIAL_STATE = {
    map: {
        clickedZone: null,
        layerConfigurations: {
            [OD_LAYERS.ORIGIN.code]: {
                colors: {
                    high: MAP_STYLES.FILTER_ZONES.COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES.FILTER_ZONES.COLOR.LOW,
                    hover: MAP_STYLES.FILTER_ZONES.COLOR.HOVER,
                },
                opacity: MAP_STYLES.FILTER_ZONES.OPACITY,
            },
            [OD_LAYERS.DESTINATION.code]: {
                colors: {
                    high: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.LOW,
                    hover: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HOVER,
                },
                opacity: MAP_STYLES.DISTRIBUTION_ZONES.OPACITY,
            },
        },
    },
    distributionLayers: null,
    preSelectedZones: [],
};
