import {
    Children,
    cloneElement,
    ReactElement,
    PropsWithChildren,
    HTMLAttributes,
    MouseEvent,
} from "react";
import classNames from "classnames";
import { TProps as StlToggleButtonProps } from "@common/components/toggleButton/toggleButton";
import "./toggleButtonGroup.less";

export const getIsButtonSelected = (buttonId: string, value: string, isMultiple: boolean) => {
    if (value === undefined || buttonId === undefined) return false;

    if (isMultiple) return value.includes(buttonId);

    return buttonId === value;
};

type TProps<T extends string> = Omit<HTMLAttributes<HTMLDivElement>, "onClick" | "onChange"> & {
    value: T;
    isMultiple?: boolean;
    isSelected?: boolean;
    size?: "xs" | "sm" | "md" | "lg";
    onChange?: (e: MouseEvent, id: T) => void;
};

export const StlToggleButtonGroup = <T extends string>({
    children,
    isMultiple = false,
    onChange,
    size = "xs",
    value,
    className,
    ...restProps
}: TProps<T>) => {
    const handleChange = (e: MouseEvent, selectedButtonId: T) => {
        if (!onChange) return;

        if (isMultiple) {
            const index = value ? value.indexOf(selectedButtonId) : 0;

            let newValue;

            if (value && index >= 0) {
                newValue = value.slice(index, 1);
            } else {
                newValue = value ? value.concat(selectedButtonId) : [selectedButtonId];
            }

            onChange(e, String(newValue) as T);
        } else {
            if (value === selectedButtonId) return;

            onChange(e, selectedButtonId);
        }
    };

    return (
        <div
            role="group"
            className={classNames("stl-toggle-button-group", className)}
            {...restProps}
        >
            {Children.map(children, child => {
                const item = child as ReactElement<PropsWithChildren<StlToggleButtonProps>>;
                const itemSize = item.props.size || size;
                const isSelected =
                    item.props.isSelected === undefined
                        ? getIsButtonSelected(item.props.id, value, isMultiple)
                        : item.props.isSelected;

                return cloneElement(item, {
                    onChange: handleChange as (e: MouseEvent, selectedButtonId: string) => void,
                    size: itemSize,
                    isSelected,
                });
            })}
        </div>
    );
};

StlToggleButtonGroup.displayName = "StlToggleButtonGroup";
