import { uniqBy, isEqual } from "lodash-es";
import {
    IMetricPackageItem,
    IMetricsPackage,
} from "@common/services/server/projectFolderApi.types";
import {
    METRICS_PACKAGES_CATEGORIES,
    ALL_METRICS_PACKAGES_TYPE,
} from "@app/bigBang/projects/state/projects.constants";
import {
    TAnalysisTypeCategoryFeatureId,
    TMetricsPackagesDataMap,
    TModeOfTravelCategoryFeatureId,
    TSubscriptionFeature,
    TUserFilterPreferencesIds,
    TUserPreferenceFilter,
} from "@app/bigBang/projects/state/projects.types";
import { ALL_VEHICLES_TRAVEL_MODES_LIST } from "@app/analysis/state/analysisConfiguration.constants";

export const getUniqAnalysisTypes = (metricsPackages: IMetricsPackage[]) => {
    const allAnalysisTypes = metricsPackages.reduce(
        (result: IMetricPackageItem[], metricsPackage: IMetricsPackage) => {
            const analysisTypes = metricsPackage.metrics_package_items.filter(
                metricsPackageItem =>
                    metricsPackageItem.name1 === METRICS_PACKAGES_CATEGORIES.ANALYSIS_TYPES.id,
            );

            return [...result, ...analysisTypes];
        },
        [],
    );

    return uniqBy(allAnalysisTypes, "feature_id");
};

// In analysis view mode metricsPackages is undefined
export const getUniqTravelModes = (metricsPackages?: IMetricsPackage[]) => {
    const allTravelModes = metricsPackages?.reduce(
        (result: IMetricPackageItem[], metricsPackage: IMetricsPackage) => {
            const travelModes = metricsPackage.metrics_package_items.filter(
                metricsPackageItem =>
                    metricsPackageItem.name1 === METRICS_PACKAGES_CATEGORIES.MODE_OF_TRAVEL.id,
            );

            return [...result, ...travelModes];
        },
        [],
    );

    return uniqBy(allTravelModes, "feature_id");
};

export const getFilteredValue = ({
    preferenceFilters,
    filterId,
    updatedValue,
}: {
    preferenceFilters: TUserPreferenceFilter[];
    filterId: TUserFilterPreferencesIds;
    updatedValue: TUserPreferenceFilter["value"];
}) => {
    const hasFilter = preferenceFilters.some(filter => filter.id === filterId);
    if (hasFilter) {
        return preferenceFilters.map(filter =>
            filter.id === filterId
                ? {
                      ...filter,
                      value: updatedValue,
                  }
                : filter,
        );
    }

    return [...preferenceFilters, { id: filterId, value: updatedValue }];
};

export const getMetricsPackagesMap = (metricsPackages: IMetricsPackage[]) =>
    metricsPackages.reduce((result: TMetricsPackagesDataMap, _package: IMetricsPackage) => {
        const availableAnalysisAndTravelModeCodes = _package.metrics_package_items.reduce(
            (_result, packageItem) => {
                // Not all package items are mode or analysis types
                if (packageItem.name1 === METRICS_PACKAGES_CATEGORIES.MODE_OF_TRAVEL.id) {
                    let travelTypeData;
                    if (
                        packageItem.name2 ===
                        METRICS_PACKAGES_CATEGORIES.MODE_OF_TRAVEL.types[2007].name
                    ) {
                        travelTypeData = ALL_VEHICLES_TRAVEL_MODES_LIST.reduce(
                            (allVehiclesTravelTypeData, travelModeData) => {
                                return {
                                    ...allVehiclesTravelTypeData,
                                    [travelModeData.code]: packageItem,
                                };
                            },
                            {},
                        );
                    } else {
                        travelTypeData = {
                            [METRICS_PACKAGES_CATEGORIES.MODE_OF_TRAVEL.types[
                                packageItem.feature_id as TModeOfTravelCategoryFeatureId
                            ].code]: packageItem,
                        };
                    }

                    return {
                        ..._result,
                        travelModes: {
                            ..._result.travelModes,
                            ...travelTypeData,
                        },
                    };
                }

                if (packageItem.name1 === METRICS_PACKAGES_CATEGORIES.ANALYSIS_TYPES.id) {
                    const analysisType =
                        METRICS_PACKAGES_CATEGORIES.ANALYSIS_TYPES.types[
                            packageItem.feature_id as TAnalysisTypeCategoryFeatureId
                        ];

                    return analysisType
                        ? {
                              ..._result,
                              analysisTypes: {
                                  ..._result.analysisTypes,
                                  [analysisType.code]: packageItem,
                              },
                          }
                        : _result;
                }

                return _result;
            },
            {
                travelModes: {},
                analysisTypes: {},
            },
        );

        return {
            ...result,
            [_package.metrics_package_id]: availableAnalysisAndTravelModeCodes,
        };
    }, {});

export const getMetricsPackageAllType = (subscriptionFeatures: Array<TSubscriptionFeature>) => {
    const allowedFeatures = [
        ...Object.values(METRICS_PACKAGES_CATEGORIES.ANALYSIS_TYPES.types),
        ...Object.values(METRICS_PACKAGES_CATEGORIES.MODE_OF_TRAVEL.types),
    ];

    const enabledFeatures = subscriptionFeatures.reduce(
        (res: Array<IMetricPackageItem>, feature: TSubscriptionFeature) => {
            if (!feature.enabled) return res;

            const foundFeature = allowedFeatures.find(
                allowedFeature => allowedFeature.featureName === feature.code_name,
            );
            if (foundFeature) {
                res.push({
                    feature_id: foundFeature.feature_id,
                    name1: feature.name1 as IMetricPackageItem["name1"],
                    name2: feature.name2 as IMetricPackageItem["name2"],
                });
            }
            return res;
        },
        [],
    );

    return {
        ...ALL_METRICS_PACKAGES_TYPE,
        metrics_package_items: enabledFeatures,
    };
};

export const getActualMetricsPackages = (
    savedMetricsPackages: IMetricsPackage[],
    currentMetricsPackages: IMetricsPackage[],
) => {
    const hasFilters = !!savedMetricsPackages.length;

    if (!hasFilters) {
        return currentMetricsPackages;
    }

    const isMetricsPackageChanged = savedMetricsPackages.some(savedMetricsPackage => {
        const currentMetricsPackage = currentMetricsPackages.find(
            _package => _package.metrics_package_id === savedMetricsPackage.metrics_package_id,
        );

        return !isEqual(
            savedMetricsPackage.metrics_package_items,
            currentMetricsPackage?.metrics_package_items,
        );
    });

    return isMetricsPackageChanged ? currentMetricsPackages : savedMetricsPackages;
};
