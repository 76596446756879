import { mergeWith, isObject } from "lodash-es";
import moment from "moment";
import { XSRFToken } from "@common/services/XSRFToken.service";
import {
    INITIAL_CURRENT_USER_STATE_OBJ,
    ROUTES_THAT_DONT_REQUIRE_AUTH,
} from "@app/store/currentUser/currentUser.constants";
import { ORG_COUNTRIES } from "@common/constants/analysis.constants";

export const getIsUserAuthenticated = () => !!XSRFToken.get();

export const currentUserDataToState = currentUser =>
    mergeWith(
        { ...INITIAL_CURRENT_USER_STATE_OBJ },
        {
            user: currentUser.user,
            realUser: currentUser.real_user,
            canAccessSandbox: currentUser.can_access_sandbox,
            configParams: currentUser.config_params,
            isSuper: currentUser.is_super,
            isUAFSigned: currentUser.is_uaf_signed,
            canChangeOrg: currentUser.can_change_org,
            canChangeStudy: currentUser.can_change_study,
        },
        (initialValue, newValue) => {
            if (isObject(initialValue) && !Object.values(initialValue).length && newValue) {
                if (Object.values(newValue)) return newValue;
            }

            return undefined;
        },
    );

export const orgBalanceDataToState = (orgBalance = {}) => {
    const daysRemainValue = Math.ceil(
        moment(orgBalance.expiration_date, "MM-DD-YYYY").diff(moment(), "days", true),
    );
    const daysRemain = daysRemainValue > 0 ? daysRemainValue : 0;

    return {
        uniqueZoneCount: orgBalance.unique_zone_count,
        uniqueZoneQuota: orgBalance.unique_zone_quota,
        uniqueZoneRemain: orgBalance.unique_zone_remain,
        studyUniqueZoneCount: orgBalance.study_unique_zone_count,
        analysesRemain: orgBalance.project_remain,
        zonesPerAnalysis: orgBalance.zones_per_project,
        daysRemain,
    };
};

export const isAuthRequired = route => {
    const _route = route.startsWith("#") ? route.slice(1) : route;

    return !ROUTES_THAT_DONT_REQUIRE_AUTH.some(noAuthRoute => {
        return _route.startsWith(noAuthRoute);
    });
};

/*  Get country for user according to the following order:
    - if passed, use 'preferredCountryCode' from user preferences
    - if org is both US&CA and 'defaultCountry' is passed, use it, otherwise - use US
    - if org supports only one country, use appropriate country
*/
export const getUserPreferredCountry = ({ org, preferredCountryCode = null, defaultCountry }) => {
    if (preferredCountryCode) {
        return ORG_COUNTRIES[preferredCountryCode];
    }
    if (org.country_us && org.country_ca) {
        return defaultCountry || ORG_COUNTRIES.US;
    }
    return org.country_us ? ORG_COUNTRIES.US : ORG_COUNTRIES.CA;
};
