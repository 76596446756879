import {
    VIZ_ZONE_TYPES,
    VIZ_MAP_LAYERS,
    BASE_VIZ_DEFAULTS,
} from "@app/viz3/base/state/baseViz.constants";

export const TRZA_ZONE_TYPES = {
    ZONE: VIZ_ZONE_TYPES.ZONE,
    ROUTE_SEGMENTS: VIZ_ZONE_TYPES.SEGMENTS,
} as const;
export const TRZA_ZONE_TYPES_LIST = Object.values(TRZA_ZONE_TYPES);
export type TTRZAZoneTypeKey = keyof typeof TRZA_ZONE_TYPES;
export type TTRZAZoneType = typeof TRZA_ZONE_TYPES[keyof typeof TRZA_ZONE_TYPES];
export type TTRZAZoneTypeList = typeof TRZA_ZONE_TYPES_LIST;

export const TRZA_LAYERS = {
    ZONE: {
        code: "zone",
        label: "Zones",
        hasConfiguration: true,
    },
    ROUTE_SEGMENTS: {
        code: "route_segment",
        label: "Route Segments",
        hasConfiguration: true,
    },
    ...VIZ_MAP_LAYERS,
} as const;
export type TTRZALayerKey = keyof typeof TRZA_LAYERS;
export type TTRZALayer = typeof TRZA_LAYERS[keyof typeof TRZA_LAYERS];

export const TRAFFIC_BEHAVIORS = {
    ORIGIN_DISTRIBUTION: {
        code: "origin",
        label: "Routes to Zones",
        zoneType: VIZ_ZONE_TYPES.SEGMENTS,
    },
    DESTINATION_DISTRIBUTION: {
        code: "destination",
        label: "Routes from Zones",
        zoneType: VIZ_ZONE_TYPES.SEGMENTS,
    },
} as const;
export const TRAFFIC_BEHAVIORS_LIST = Object.values(TRAFFIC_BEHAVIORS);
export type TTrafficBehaviorKey = keyof typeof TRAFFIC_BEHAVIORS;
export type TTrafficBehavior = typeof TRAFFIC_BEHAVIORS[keyof typeof TRAFFIC_BEHAVIORS];

export const DEFAULT_MAP_LAYERS_VISIBILITY = {
    [TRZA_LAYERS.ZONE.code]: true,
    [TRZA_LAYERS.ROUTE_SEGMENTS.code]: true,
    [TRZA_LAYERS.BASE.code]: true,
    [TRZA_LAYERS.UNSELECTED.code]: false,
    [TRZA_LAYERS.LABEL.code]: true,
    [TRZA_LAYERS.LEGEND.code]: true,
};

export const TRZA_VIZ_DEFAULTS = {
    filters: {
        ...BASE_VIZ_DEFAULTS.filters,
        [TRZA_ZONE_TYPES.ROUTE_SEGMENTS.filterName]: [],
    },
    visualization: {
        ...BASE_VIZ_DEFAULTS.visualization,
        dataTrimRange: [1, 100],
        trafficBehaviors: [TRAFFIC_BEHAVIORS.DESTINATION_DISTRIBUTION],
        mapLayersVisibility: DEFAULT_MAP_LAYERS_VISIBILITY,
    },
} as const;
export type TTRZAVizDefaultKey = keyof typeof TRZA_VIZ_DEFAULTS;
export type TTRZAVizDefault = typeof TRZA_VIZ_DEFAULTS[keyof typeof TRZA_VIZ_DEFAULTS];
