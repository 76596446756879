import { VIZ_ZONE_TYPES } from "@app/viz3/base/state/baseViz.constants";
import { ROAD_TYPES } from "@common/features/zonesManager/state/zonesManager.constants";

export const DEFAULT_LIGHTNING_ANALYSIS_NAME = "Untitled Project";

export const ROAD_TYPES_LIST = [
    ROAD_TYPES.MOTORWAY,
    ROAD_TYPES.RAMP,
    ROAD_TYPES.TRUNK,
    ROAD_TYPES.PRIMARY,
    ROAD_TYPES.SECONDARY,
    ROAD_TYPES.TERTIARY,
];

export type TRoadTypes = typeof ROAD_TYPES_LIST;

export const ROAD_TYPE_ID_LIST = [
    ...ROAD_TYPES.MOTORWAY.roadIds,
    ...ROAD_TYPES.RAMP.roadIds,
    ...ROAD_TYPES.TRUNK.roadIds,
    ...ROAD_TYPES.PRIMARY.roadIds,
    ...ROAD_TYPES.SECONDARY.roadIds,
    ...ROAD_TYPES.TERTIARY.roadIds,
];

export const SEGMENT_FILTERS = {
    ALL: {
        type: "NONE",
        code: "all",
        display: "All",
        isExpandable: false,
    },
    CORRIDOR_GROUPS: {
        type: "CORRIDOR_GROUPS",
        code: "corridorGroup",
        display: "Corridor Groups",
        isExpandable: true,
    },
    CENSUS_TRACT: {
        type: "NONE",
        code: "census_tract",
        display: "By Census Tract",
        isExpandable: true,
    },
    AREAS: {
        type: "ZONE_GROUP_ID",
        code: "areas",
        display: "Areas",
        isExpandable: true,
    },
    CORRIDORS: {
        type: "ZONE_GROUP_ID",
        code: "corridors",
        display: "Corridors",
        isExpandable: true,
    },
    COUNTY: {
        type: "ZONE_GROUP_ID",
        code: "county",
        display: "County",
        isExpandable: true,
    },
    ROAD_CLASSIFICATIONS: {
        type: "OSM_HIGHWAY_TYPE",
        code: "roadClassification",
        display: "Road Class",
        isExpandable: true,
    },
    STATE: {
        type: "ZONE_GROUP_ID",
        code: "state",
        display: "State",
        isExpandable: true,
    },
} as const;

export const SEGMENT_FILTERS_LIST = Object.values(SEGMENT_FILTERS);
export type TSegmentFilters = typeof SEGMENT_FILTERS[keyof typeof SEGMENT_FILTERS];

export const DEFAULT_LIGHTNING_FILTERS = {
    segmentGroups: [],
    selectedRoads: [],
    selectedYears: [],
    selectedSegmentFilters: { code: SEGMENT_FILTERS.ALL.code },
};

export const SEGMENT_ZONE_TYPE = {
    ...VIZ_ZONE_TYPES.ZONE,
    displayName: "Segments",
} as const;

export const MAP_SHAPE_OPTIONS = {
    horizontal: {
        id: "horizontal",
        img: {
            selected: "/img/ct-analysis-imgs/map-shape-icons/horizontal_selected.svg",
            unselected: "/img/ct-analysis-imgs/map-shape-icons/horizontal.svg",
            alt: "horizontal map",
        },
    },
    vertical: {
        id: "vertical",
        img: {
            selected: "/img/ct-analysis-imgs/map-shape-icons/vertical_selected.svg",
            unselected: "/img/ct-analysis-imgs/map-shape-icons/vertical.svg",
            alt: "vertical map",
        },
    },
    square: {
        id: "square",
        img: {
            selected: "/img/ct-analysis-imgs/map-shape-icons/square_selected.svg",
            unselected: "/img/ct-analysis-imgs/map-shape-icons/square.svg",
            alt: "square map",
        },
    },
} as const;

export const MAP_SHAPE_OPTIONS_LIST = Object.values(MAP_SHAPE_OPTIONS);
