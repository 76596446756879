import { StlButton } from "@common/components/button/button";

type TProps = {
    apply: () => void;
};

export const FilterHeader = ({ apply }: TProps) => (
    <div className="filter-button-wrapper">
        <StlButton variant="primary" onClick={apply}>
            FILTER
        </StlButton>
    </div>
);
