import { useEffect } from "react";
import { DEFAULT_POINT_ZOOM_LEVEL } from "@common/components/baseMap/baseMap.constants";
import type { Map, LngLatLike, LngLatBoundsLike } from "mapbox-gl";
import type { ILocation } from "@common/components/geocoder/geocoder";

export const useJumpToLocation = (map: Map | null, location: Partial<ILocation> | null = {}) => {
    useEffect(() => {
        if (!map || !location?.id) return;

        map.resize();

        if (!location.bbox) {
            map.flyTo({ center: location.center as LngLatLike, zoom: DEFAULT_POINT_ZOOM_LEVEL });
        } else {
            map.flyTo({ center: location.center as LngLatLike });
            map.fitBounds(location.bbox as LngLatBoundsLike);
        }
    }, [location, map]);
};
