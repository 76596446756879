import { OPTION_ALL } from "@common/components/select/select.constants";

type TOption = {
    [key: string]: unknown;
};

export type TIsSelectedOptions = {
    allOption?: boolean;
    specificOption?: boolean;
};

export type TSelection = {
    updatedSelection: Array<TOption>;
    prevValues: TIsSelectedOptions;
};

export const getSelectionWithoutDefaultAllOption = ({
    wasSelected,
    selected,
    specificOption,
}: {
    specificOption: TOption;
    selected: Array<TOption>;
    wasSelected: TIsSelectedOptions;
}): TSelection => {
    const isAllOptionSelected = selected.find(option => option.value === specificOption.value);

    if (!isAllOptionSelected) return { updatedSelection: selected, prevValues: {} };

    if (!wasSelected.specificOption) {
        return { updatedSelection: [specificOption], prevValues: { specificOption: true } };
    }

    return {
        updatedSelection: selected.filter(option => option.value !== specificOption.value),
        prevValues: { specificOption: false },
    };
};

export const getSelectionWithDefaultAllOption = ({
    wasSelected,
    selected,
    options,
    specificOption,
}: {
    specificOption: TOption;
    options: Array<TOption>;
    selected: Array<TOption>;
    wasSelected: TIsSelectedOptions;
}): TSelection => {
    const isSpecificOptionOptionSelected = selected.find(
        option => option.value === specificOption.value,
    );

    if (isSpecificOptionOptionSelected && !wasSelected.specificOption) {
        return {
            updatedSelection: [specificOption],
            prevValues: {
                specificOption: true,
                allOption: false,
            },
        };
    }

    const isAllOptionOptionAllSelected = selected.find(
        option => option.value === OPTION_ALL.value,
    );

    if (isAllOptionOptionAllSelected && !wasSelected.allOption) {
        const _option = options.filter(option => option.value !== specificOption.value);

        return {
            updatedSelection: [OPTION_ALL, ..._option],
            prevValues: {
                specificOption: false,
                allOption: true,
            },
        };
    }

    if (!isAllOptionOptionAllSelected && wasSelected.allOption) {
        return {
            updatedSelection: [],
            prevValues: {
                specificOption: false,
                allOption: false,
            },
        };
    }

    const _selected = selected.filter(
        option => option.value !== OPTION_ALL.value && option.value !== specificOption.value,
    );

    if (selected.length === options.length - 1 && !wasSelected.allOption) {
        return {
            updatedSelection: [OPTION_ALL, ..._selected],
            prevValues: {
                specificOption: false,
                allOption: true,
            },
        };
    }

    return {
        updatedSelection: _selected,
        prevValues: {
            specificOption: false,
            allOption: false,
        },
    };
};

export const updateSelection = ({
    wasSelected,
    selected,
    options,
    specificOption,
    shouldUseAllOption,
}: {
    specificOption: TOption;
    options: Array<TOption>;
    selected: Array<TOption>;
    shouldUseAllOption: boolean;
    wasSelected: TIsSelectedOptions;
}) => {
    const args = {
        wasSelected,
        selected,
        options,
        specificOption,
    };

    return shouldUseAllOption
        ? getSelectionWithDefaultAllOption({ ...args })
        : getSelectionWithoutDefaultAllOption({ ...args });
};
