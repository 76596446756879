const PICKER_SIZE = 48 as const;
const PICKER_CENTER = {
    x: PICKER_SIZE / 2,
    y: PICKER_SIZE / 2,
} as const;
const BASE_PICKER_POINT = {
    x: PICKER_CENTER.x,
    y: 0,
} as const;

const cx = BASE_PICKER_POINT.x - PICKER_CENTER.x;
const cy = BASE_PICKER_POINT.y - PICKER_CENTER.y;

const rad2deg = (rad: number): number => rad * 57.29577951308232;

// https://github.com/mui-org/material-ui-pickers/blob/fd8b8322c8561b30c3ae6eb81881519ca5a7f0fb/lib/src/_helpers/time-utils.ts
export const getAngleValue = (
    step: number,
    offsetX: number,
    offsetY: number,
): { value: number; distance: number } => {
    const x = offsetX - PICKER_CENTER.x;
    const y = offsetY - PICKER_CENTER.y;

    const atan = Math.atan2(cx, cy) - Math.atan2(x, y);

    let deg = rad2deg(atan);
    deg = Math.round(deg / step) * step;
    deg %= 360;

    const value = Math.floor(deg / step) || 0;
    const delta = x ** 2 + y ** 2;
    const distance = Math.sqrt(delta);

    return { value, distance };
};
