import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { StlButton, StlCard } from "@common/components";
import "./status.less";

const NOT_FOUND_TITLE = <FormattedMessage id="app.notFound" defaultMessage="Not Found" />;

const NOT_FOUND_DESCRIPTION = (
    <FormattedMessage
        id="app.status404.description"
        defaultMessage="Looks like we don't have such page. Here is an alternative page that you can visit."
    >
        {text => <p>{text}</p>}
    </FormattedMessage>
);

const REDIRECT_TO_LOGIN_PAGE_TEXT = (
    <FormattedMessage id="app.status.redirectToLogin" defaultMessage="Redirect to Login page" />
);

export const StlStatus404 = () => {
    const history = useHistory();

    const redirectToLogin = () => history.push("/login");

    return (
        <div className="stl-status">
            <StlCard title={NOT_FOUND_TITLE}>
                {NOT_FOUND_DESCRIPTION}
                <div className="footer">
                    <StlButton variant="primary" size="sm" onClick={redirectToLogin}>
                        {REDIRECT_TO_LOGIN_PAGE_TEXT}
                    </StlButton>
                </div>
            </StlCard>
        </div>
    );
};
