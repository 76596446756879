import {
    CREATE_ANALYSIS_TYPES,
    SCREEN_MODES,
    TABS,
    ZONES_MODES,
} from "@app/analysis/state/analysisConfiguration.constants";

export const GENERAL_INITIAL_STATE = {
    analysisId: null,
    analysisVersionId: null,
    analysisStatus: "",
    analysisTypeCode: CREATE_ANALYSIS_TYPES.OD.code,
    uiStates: {
        isLoading: false,
        screenMode: SCREEN_MODES.DEFAULT.id,
        zonesMode: ZONES_MODES.CHOOSE_ZONES,
        activeTab: TABS.BASICS.id,
        is15MinuteBinsModeActive: false,
        showPreviewAnalysis: false,
        isAnalysisTypeChanged: false,
        isShowAllAnalysesAllowed: false,
        isZonesTableCollapsed: false,
        isViewFromDashboard: false,
    },
    validation: {
        isInvalid: true,
    },
    runAnalysis: {},
    projectFolder: null,
};
