import { ValuesOfType } from "@common/utils/utility.types";
import { IGeoJsonRoute } from "@app/analysisLightning/base/services/analysisLightningApi.types";
import {
    TCSRoute,
    TBiDirectionalCorridor,
    TUniDirectionalCorridor,
    ICSSubRoute,
} from "./corridorStudies.types";
import { TCSGeographyType } from "./corridorStudies.constants";

export interface ICorridorStudiesState {
    availableGeographyTypes: Array<TCSGeographyType>;
    corridorConfiguration: {
        names: Record<string, string>;
        routes: Record<string, TCSRoute | null>;
        corridors: Record<string, TBiDirectionalCorridor | TUniDirectionalCorridor>;
        csData: Array<IGeoJsonRoute>;
        corridorIds: Array<string>;
        sessionHash: string | null;
        knownSubRoutes: Record<string, ICSSubRoute>;
        noRouteFound: Boolean;
        corridorHistoryStack: Array<
            Pick<ICorridorStudiesState["corridorConfiguration"], "corridors" | "corridorIds">
        >;
        isSelectRegionAvailable: boolean;
        activeCorridorSegmentMetadata: { corridorId: string; waypointIndex: number } | null;
    };
}

export type TCSStateCorridorConfiguration = ICorridorStudiesState["corridorConfiguration"];

export type TCSStateRoute = ValuesOfType<TCSStateCorridorConfiguration["routes"]>;
export type TCSStateCorridor = ValuesOfType<TCSStateCorridorConfiguration["corridors"]>;

export const CORRIDOR_STUDIES_INITIAL_STATE: ICorridorStudiesState = {
    availableGeographyTypes: [],
    corridorConfiguration: {
        names: {},
        routes: {},
        corridors: {},
        corridorIds: [],
        sessionHash: null,
        csData: [],
        knownSubRoutes: {},
        noRouteFound: false,
        corridorHistoryStack: [],
        isSelectRegionAvailable: true,
        activeCorridorSegmentMetadata: null,
    },
};
