export const MAP_MODES = {
    VIEW_MAP: "VIEW_MAP",
    VIEW_ZONE: "VIEW_ZONE",
    DRAW_GATE: "DRAW_GATE",
    EDIT_GATE: "EDIT_GATE",
} as const;

export type TMapModeKey = keyof typeof MAP_MODES;
export type TMapMode = typeof MAP_MODES[keyof typeof MAP_MODES];

export const MAP_MODE_TO_DRAW_MODE = {
    VIEW_MAP: "static",
    VIEW_ZONE: "static",
    DRAW_GATE: "draw_two_point_line",
} as const;

export type TMapModeToDrawModeKey = keyof typeof MAP_MODE_TO_DRAW_MODE;
export type TMapModeToDrawMode = typeof MAP_MODES[keyof typeof MAP_MODE_TO_DRAW_MODE];

export const NO_SELECTED_INTERSECTION_TEXT =
    "No intersection zone is selected. Please select one intersection zone";

export const GATE_POSITION_VALIDATION_WARNING_TEXT =
    "Check gate placement to ensure it aligns with the direction label.";
