import { INITIAL_CURRENT_USER_STATE_OBJ } from "./currentUser.constants";

export const CURRENT_USER_INITIAL_STATE = {
    ...INITIAL_CURRENT_USER_STATE_OBJ,
    orgBalance: {
        uniqueZoneRemain: null,
        uniqueZoneQuota: null,
        studyUniqueZoneCount: null,
    },
};
