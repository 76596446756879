import { Component, ReactNode } from "react";
import {
    ChunkErrorScreen,
    CommonErrorScreen,
} from "@common/components/errorBoundary/errorScreens";
import { getIsChunkError } from "./errorBoundary.helpers";

const INITIAL_STATE = {
    error: false,
    isChunkLoadError: false,
};

interface IProps {
    children: ReactNode;
}

interface IState {
    error: boolean;
    isChunkLoadError?: boolean;
}

export class ErrorBoundary extends Component<IProps, IState> {
    state = {
        error: INITIAL_STATE.error,
        isChunkLoadError: INITIAL_STATE.isChunkLoadError,
    };

    static getDerivedStateFromError(error: any) {
        return { error: true, isChunkLoadError: getIsChunkError(error) };
    }

    render() {
        if (this.state.error) {
            return this.state.isChunkLoadError ? <ChunkErrorScreen /> : <CommonErrorScreen />;
        }

        return this.props.children;
    }
}
