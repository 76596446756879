import type { Feature, LineString } from "geojson";
import type {
    IAvailableIntersection,
    IHoveredGate,
    IIntersection,
} from "@common/features/intersections/intersection.types";
import { MAP_MODES, TMapMode } from "./tmcChooseZones.constants";

export interface ITMCChooseZonesState {
    mapMode: TMapMode;
    hoveredGate: IHoveredGate | null;
    editableFeature: Feature<LineString> | null;
    availableIntersections: Array<IAvailableIntersection>;
    hoveredIntersectionZoneId: IIntersection["id"] | null;
    selectedIntersectionZoneId: IIntersection["id"] | null;
    selectedGateId: IIntersection["gates"][number]["id"] | null;
    intersectionZones: Record<IIntersection["id"], IIntersection>;
    intersectionZoneIdsList: IIntersection["id"][];
}

export const TMC_CHOOSE_ZONES_INITIAL_STATE = {
    mapMode: MAP_MODES.VIEW_MAP,
    editableFeature: null,
    availableIntersections: [],
    intersectionZones: {},
    intersectionZoneIdsList: [],
    hoveredIntersectionZoneId: null,
    selectedIntersectionZoneId: null,
    selectedGateId: null,
    hoveredGate: null,
} as ITMCChooseZonesState;
