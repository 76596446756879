import { ANALYSIS_STATUSES, ANALYSIS_TYPES } from "@common/constants/analysis.constants";
import { ROAD_TYPES } from "@common/features/zonesManager/state/zonesManager.constants";
import type { PointLike } from "mapbox-gl";

export const SCREEN_MODES = {
    DEFAULT: {
        id: "DEFAULT",
        isReadOnly: false,
    },
    COPY: {
        id: "COPY",
        isReadOnly: false,
    },
    DRAFT: {
        id: "DRAFT",
        isReadOnly: false,
    },
    VIEW: {
        id: "VIEW",
        isReadOnly: true,
    },
    REVIEW: {
        id: "REVIEW",
        isReadOnly: true,
    },
};

export const SCREEN_MODES_LIST = Object.values(SCREEN_MODES);

export type TScreenModes = typeof SCREEN_MODES[keyof typeof SCREEN_MODES];

export const ROAD_TYPES_LIST = [
    ROAD_TYPES.MOTORWAY,
    ROAD_TYPES.RAMP,
    ROAD_TYPES.TRUNK,
    ROAD_TYPES.PRIMARY,
    ROAD_TYPES.SECONDARY,
    ROAD_TYPES.TERTIARY,
];

export const DEFAULT_ROAD_CLASSIFICATIONS = ROAD_TYPES_LIST.map(roadType => roadType.id);

export const ROAD_TYPE_ID_LIST = [
    ...ROAD_TYPES.MOTORWAY.roadIds,
    ...ROAD_TYPES.RAMP.roadIds,
    ...ROAD_TYPES.TRUNK.roadIds,
    ...ROAD_TYPES.PRIMARY.roadIds,
    ...ROAD_TYPES.SECONDARY.roadIds,
    ...ROAD_TYPES.TERTIARY.roadIds,
];

export const RAMP_ROAD_NAMES = {
    motorway: "motorway_link",
    trunk: "trunk_link",
    primary: "primary_link",
    secondary: "secondary_link",
    tertiary: "tertiary_link",
} as const;

export type TRampRoadNamesKey = keyof typeof RAMP_ROAD_NAMES;
export type TRampRoadNames = typeof RAMP_ROAD_NAMES[TRampRoadNamesKey];

export const IN_PROGRESS_STATUSES = [
    ANALYSIS_STATUSES.NEW.id,
    ANALYSIS_STATUSES.PROCESSING.id,
    ANALYSIS_STATUSES.QUEUED.id,
    ANALYSIS_STATUSES.RUNNING.id,
    ANALYSIS_STATUSES.RERUNNING.id,
    ANALYSIS_STATUSES.SAVED.id,
    ANALYSIS_STATUSES.PAUSED_REQUEUED.id,
    ANALYSIS_STATUSES.RESUMED.id,
    ANALYSIS_STATUSES.EXPORTING.id,
];

export const MAP_THEMES = {
    LIGHT: "navigation-day-v1",
    DARK: "navigation-night-v1",
};
export type TMapType = keyof typeof MAP_THEMES;
export type TMapTheme = typeof MAP_THEMES[keyof typeof MAP_THEMES];

export const POPUP_OFFSET = {
    top: [0, 30],
    bottom: [0, -10],
    left: [20, 0],
    right: [-10, 0],
} as {
    [key: string]: PointLike;
};

export const METRICS = {
    "Summarized across Network": [
        "Vehicle Miles Traveled",
        "Vehicle Hours of Delay",
        "Travel Time Index",
        "Planning Time Index",
        "Reliability Factor",
    ],
    "Mapped across Network": [
        "Roadway Volume",
        "Average Speed",
        "Vehicle Hours of Delay",
        "Travel Time Index",
        "Level of Travel Time Reliability",
        "Unreliable Segments",
        "Planning Time Index",
    ],
    "Charted by Time of Day": [
        "Vehicle Miles Traveled",
        "Vehicle Hours of Delay",
        "Travel Time Index",
        "Level of Travel Time Reliability",
    ],
};
export type TMetricType = keyof typeof METRICS;

export const LIGHTNING_ANALYSIS_TYPES = {
    [ANALYSIS_TYPES.ATM.id]: { ...ANALYSIS_TYPES.ATM, path: "activeTransportation" },
    [ANALYSIS_TYPES.CT.id]: { ...ANALYSIS_TYPES.CT, path: "congestionTrends" },
    [ANALYSIS_TYPES.RV.id]: { ...ANALYSIS_TYPES.RV, path: "roadwayVolume" },
    [ANALYSIS_TYPES.CS.id]: { ...ANALYSIS_TYPES.CS, path: "corridorStudies" },
} as const;

export type TLightningAnalysisTypes =
    typeof LIGHTNING_ANALYSIS_TYPES[keyof typeof LIGHTNING_ANALYSIS_TYPES];
export type TLightningAnalysisTypeIds = TLightningAnalysisTypes["id"];
