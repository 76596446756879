import moment from "moment";
import type { ICustomLineZone } from "@common/features/zonesManager/zonesManager.types";
import type {
    IZoneVintageDate,
    IZoneVintageDates,
    TZoneLibraryZone,
} from "@common/features/zonesManager/components/libraryZonesPicker/libraryZonePicker.types";
import {
    BUS_VINTAGE,
    OSM_VINTAGE,
    RAIL_VINTAGE,
} from "@app/store/staticData/state/staticData.constants";
import { arrayIncludes } from "@common/utils/arrayIncludes";
import { ZONE_KINDS, ZONE_KINDS_LIST } from "@common/constants/zoneLibrary.constants";

export const getOSMVintageDate = (zoneVintageDate?: IZoneVintageDate) => {
    const year = zoneVintageDate?.year_num || OSM_VINTAGE.year_num;
    const month = zoneVintageDate?.month_num || OSM_VINTAGE.month_num;

    return moment([year, month - 1]).format("MMM YYYY");
};

export const getStandardAreaVintageDate = (zoneVintageDate?: IZoneVintageDate, year?: string) => {
    return String(zoneVintageDate?.year_num || year || "");
};

export const getBusVintageDate = (zoneVintageDate?: IZoneVintageDate) => {
    const year = zoneVintageDate?.year_num || BUS_VINTAGE.year_num;
    const month = zoneVintageDate?.month_num || BUS_VINTAGE.month_num;

    return moment([year, month - 1]).format("MMM YYYY");
};

export const getRailsVintageDate = (zoneVintageDate?: IZoneVintageDate) => {
    const year = zoneVintageDate?.year_num || RAIL_VINTAGE.year_num;
    const month = zoneVintageDate?.month_num || RAIL_VINTAGE.month_num;

    return moment([year, month - 1]).format("MMM YYYY");
};

export const getZoneVintageDate = ({
    zone,
    zoneVintage,
    zoneVintageByZoneKind,
}: {
    zone: TZoneLibraryZone | ICustomLineZone;
    zoneVintage?: IZoneVintageDate;
    zoneVintageByZoneKind?: IZoneVintageDates;
}) => {
    const zoneKindId =
        Number((zone as ICustomLineZone).display_zone_kind_id) || Number(zone.zone_kind_id);
    // 'zoneVintage' takes precedence over 'zoneVintageByZoneKind'
    const zoneVintageDate = zoneVintage || zoneVintageByZoneKind?.[zone.zone_kind_id];

    if (arrayIncludes(ZONE_KINDS.USER.id, zoneKindId)) {
        return "-";
    } else if (arrayIncludes(ZONE_KINDS.OSM.id, zoneKindId)) {
        return getOSMVintageDate(zoneVintageDate);
    } else if (arrayIncludes(ZONE_KINDS.BUS.id, zoneKindId)) {
        return getBusVintageDate(zoneVintageDate);
    } else if (arrayIncludes(ZONE_KINDS.RAIL.id, zoneKindId)) {
        return getRailsVintageDate(zoneVintageDate);
    } else {
        const zoneKind = ZONE_KINDS_LIST.find(({ id }) => arrayIncludes(id, zoneKindId));
        return getStandardAreaVintageDate(zoneVintageDate, zoneKind?.year);
    }
};
