import {
    ICalibrationDetails,
    TCustomZone,
    TZonesManagerZone,
} from "@common/features/zonesManager/zonesManager.types";
import { MODES_OF_TRAVEL } from "@common/constants/analysis.constants";
import { ZONE_KINDS } from "@common/constants/zoneLibrary.constants";
import { arrayIncludes } from "@common/utils/arrayIncludes";
import {
    CALIBRATIONS_BY_TRAVEL_MODE_LIST,
    INITIAL_CALIBRATION_DETAIL,
    MAX_BICYCLE_CALIBRATION_VALUE,
    MAX_PEDESTRIAN_CALIBRATION_VALUE,
    MAX_VEHICLE_CALIBRATION_VALUE,
} from "./zoneCalibration.constants";

export const getCalibrationByTravelMode = (travelModeCode: string) => {
    return CALIBRATIONS_BY_TRAVEL_MODE_LIST.find(calibration =>
        calibration.travelModes.includes(travelModeCode),
    );
};

export const isCalibrationValueDefined = (value: string | number | null | undefined) => {
    return value !== null && typeof value !== "undefined" && value !== "";
};

const parseCalibrationValue = (value: string | number | null | undefined) => {
    return isCalibrationValueDefined(value) ? parseInt(value as string, 10) : undefined;
};

export const validateIsCalibrationValueDefined = (value: string | number | null | undefined) => {
    return !isCalibrationValueDefined(value) ? ["Calibration Value is required."] : [];
};

export const validateCalibrationValue = (
    value: string | number | null | undefined,
    type: string,
    max: number,
) => {
    const errors = [];
    const parsedValue = parseCalibrationValue(value);
    const hasValue = isCalibrationValueDefined(parsedValue);

    if (hasValue) {
        if (!parsedValue && parsedValue !== 0) {
            errors.push(`${type} Calibration Value is required.`);
        }
        const min = 0;
        if (Number(parsedValue) < min || Number(parsedValue) > max) {
            errors.push(`${type} Calibration Value must be in range ${min} - ${max}.`);
        }
    }

    return errors;
};

export const validateRatioValue = (value: number, label: string) => {
    const parsedValue = parseFloat(String(value)) || 0.0;
    if (parsedValue && (parsedValue < 0.0 || parsedValue > 1.0)) {
        return [`${label} Traffic Ratio must be in range 0.000 - 1.000.`];
    }
    return [];
};

export const validateRatioTotal = (
    persRatio: number,
    commMdRatio: number,
    commHdRatio: number,
) => {
    if (!String(persRatio) || !String(commMdRatio) || !String(commHdRatio)) {
        return ["All Traffic Ratio values must be defined."];
    }
    const ratioValues = {
        pers_ratio: parseFloat(String(persRatio)),
        comm_md_ratio: parseFloat(String(commMdRatio)),
        comm_hd_ratio: parseFloat(String(commHdRatio)),
    };
    const ratioTotal =
        ratioValues.pers_ratio + ratioValues.comm_md_ratio + ratioValues.comm_hd_ratio;

    if (ratioTotal <= 0.99 || ratioTotal >= 1.001) {
        return ["Sum of all Traffic Ratio values is not equal to 1.00."];
    }
    return [];
};

export const validateExistingCalibrationValues = (calibrationDetails: ICalibrationDetails) => {
    const vehicleCalibrationValue = parseCalibrationValue(calibrationDetails.calibration_value);
    const bikeCalibrationValue = parseCalibrationValue(calibrationDetails.bike_calibration_value);
    const pedCalibrationValue = parseCalibrationValue(calibrationDetails.ped_calibration_value);

    const hasVehCalValue = isCalibrationValueDefined(vehicleCalibrationValue);
    const hasBikeCalValue = isCalibrationValueDefined(bikeCalibrationValue);
    const hasPedCalValue = isCalibrationValueDefined(pedCalibrationValue);

    const errors = [];
    if (!hasVehCalValue && !hasBikeCalValue && !hasPedCalValue) {
        errors.push("At least one Calibration Value is required.");
    }
    return errors;
};

export const validateZoneCalibrationInput = (zone: TCustomZone<boolean>) => {
    if (!zone?.with_calibration) {
        return [];
    }
    const calibrationDetails = zone?.calibration_detail || INITIAL_CALIBRATION_DETAIL;

    return [
        ...validateExistingCalibrationValues(calibrationDetails),
        ...validateCalibrationValue(
            calibrationDetails.calibration_value,
            "Vehicles",
            MAX_VEHICLE_CALIBRATION_VALUE,
        ),
        ...validateCalibrationValue(
            calibrationDetails.bike_calibration_value,
            "Bicycles",
            MAX_BICYCLE_CALIBRATION_VALUE,
        ),
        ...validateCalibrationValue(
            calibrationDetails.ped_calibration_value,
            "Pedestrians",
            MAX_PEDESTRIAN_CALIBRATION_VALUE,
        ),
        ...validateRatioTotal(
            calibrationDetails.pers_ratio,
            calibrationDetails.comm_md_ratio,
            calibrationDetails.comm_hd_ratio,
        ),
        ...validateRatioValue(calibrationDetails.pers_ratio, "Personal"),
        ...validateRatioValue(calibrationDetails.comm_md_ratio, "Commercial Medium Duty"),
        ...validateRatioValue(calibrationDetails.comm_hd_ratio, "Commercial Heavy Duty"),
    ];
};

export const validateZoneCalibrationInputByTravelMode = (
    zone: TCustomZone,
    travelModeCode: string,
) => {
    const calibrationByTravelMode = getCalibrationByTravelMode(travelModeCode);
    if (!zone?.with_calibration || !calibrationByTravelMode) {
        return [];
    }
    const calibrationDetails = zone?.calibration_detail || INITIAL_CALIBRATION_DETAIL;
    const calibrationValue =
        calibrationDetails[
            calibrationByTravelMode.valueAccessor as keyof ICalibrationDetails["calibration_value"]
        ];
    const commonErrors = [
        ...validateExistingCalibrationValues(calibrationDetails),
        ...validateIsCalibrationValueDefined(calibrationValue),
        ...validateCalibrationValue(
            calibrationValue,
            calibrationByTravelMode.typeName,
            calibrationByTravelMode.maxValue,
        ),
    ];

    if ([MODES_OF_TRAVEL.ALL_VEHICLES.code, MODES_OF_TRAVEL.TRUCK.code].includes(travelModeCode)) {
        return [
            ...commonErrors,
            ...validateRatioTotal(
                calibrationDetails.pers_ratio,
                calibrationDetails.comm_md_ratio,
                calibrationDetails.comm_hd_ratio,
            ),
            ...validateRatioValue(calibrationDetails.pers_ratio, "Personal"),
            ...validateRatioValue(calibrationDetails.comm_md_ratio, "Commercial Medium Duty"),
            ...validateRatioValue(calibrationDetails.comm_hd_ratio, "Commercial Heavy Duty"),
        ];
    }
    return commonErrors;
};

export const convertCalibrationToApiObject = (calibrationDetails: ICalibrationDetails) => {
    return {
        ...calibrationDetails,
        calibration_value: calibrationDetails?.calibration_value || null,
        bike_calibration_value: calibrationDetails?.bike_calibration_value || null,
        ped_calibration_value: calibrationDetails?.ped_calibration_value || null,
    };
};

export const isCustomZone = (zone: TZonesManagerZone<boolean>): zone is TCustomZone<boolean> => {
    return arrayIncludes(ZONE_KINDS.USER.id, Number(zone.zone_kind_id));
};

export const isWithCalibration = (zone: TCustomZone<boolean>): zone is TCustomZone<true> => {
    return zone.with_calibration;
};

// Check if zones has any type of calibration values (Vehicle/Bike/Ped)
export const getIsZonesHasCalibrationInfo = (zones: Array<TZonesManagerZone<boolean>>) => {
    return (zones || []).reduce(
        (res: { [key: string]: boolean }, zone: TZonesManagerZone<boolean>) => {
            if (!isCustomZone(zone) || !isWithCalibration(zone)) {
                return res;
            }

            const calibrationDetails = zone.calibration_detail;
            if (isCalibrationValueDefined(calibrationDetails.calibration_value)) {
                res[`hasVehicle${calibrationDetails.calibration_type}Value`] = true;
            }
            if (isCalibrationValueDefined(calibrationDetails.bike_calibration_value)) {
                res.hasBikeValue = true;
            }
            if (isCalibrationValueDefined(calibrationDetails.ped_calibration_value)) {
                res.hasPedValue = true;
            }
            return res;
        },
        {
            hasBikeValue: false,
            hasPedValue: false,
            hasVehicleADTValue: false,
            hasVehicleAADTValue: false,
            hasVehicleAWDTValue: false,
            hasVehicleAAWDTValue: false,
            hasSTLAADTValue: false,
        },
    );
};
