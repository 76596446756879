import classnames from "classnames";
import type { Map } from "mapbox-gl";
import { addContentToButton, createButton } from "./customControls.helpers";

export class LocationControl {
    locationContainer!: HTMLDivElement;
    button: HTMLButtonElement | null = null;
    img: HTMLImageElement | null = null;
    showMapLocation: boolean = false;
    _map!: Map | undefined;
    _container!: HTMLDivElement;
    _onAdd: () => void;

    constructor({ onAdd }: { onAdd: () => void }) {
        this._onAdd = onAdd;
    }

    onAdd = (map: Map) => {
        this._map = map;
        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group location-control";

        const button = createButton({
            className: "mapboxgl-location-button",
            tooltip: "Zoom To Location",
            onClick: this.onShowLocation.bind(this),
        });
        addContentToButton({ button, tooltip: "Zoom To Location" });

        this.locationContainer = document.createElement("div");
        this.locationContainer.className = classnames(
            "location-input-wrapper",
            !this.showMapLocation && "hidden",
        );

        this.button = button;
        this._container.appendChild(button);
        this._container.appendChild(this.locationContainer);

        this._onAdd();

        return this._container;
    };

    onRemove = () => {
        this._container.parentNode?.removeChild(this._container);
        this._map = undefined;
    };

    onShowLocation() {
        this.showMapLocation = !this.showMapLocation;
        this.locationContainer.classList.toggle("hidden", !this.showMapLocation);
    }

    switchOpening(show: boolean) {
        this.showMapLocation = show;
        this.locationContainer.classList.toggle("hidden", !show);
    }
}
