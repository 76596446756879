import {
    VIZ_ZONE_TYPES,
    VIZ_MAP_LAYERS,
    BASE_VIZ_DEFAULTS,
} from "@app/viz3/base/state/baseViz.constants";

export const ODG_ZONE_TYPES = {
    FILTER: VIZ_ZONE_TYPES.ZONE,
    PRESET_GEO: VIZ_ZONE_TYPES.PRESET_GEO,
};
export const ODG_ZONE_TYPES_LIST = [ODG_ZONE_TYPES.FILTER, ODG_ZONE_TYPES.PRESET_GEO];
export const ODG_ZONE_TYPE_DISTRIBUTION_ZONE_ONLY = [ODG_ZONE_TYPES.FILTER];

export type TODGZoneTypesList = typeof ODG_ZONE_TYPES_LIST;

export const ODG_LAYERS = {
    ORIGIN: {
        code: "origin",
        label: "Origins",
        hasConfiguration: true,
    },
    DESTINATION: {
        code: "destination",
        label: "Destinations",
        hasConfiguration: true,
    },
    ...VIZ_MAP_LAYERS,
} as const;

export type TODGLayersType = keyof typeof ODG_LAYERS;
export type TODGLayer = typeof ODG_LAYERS[keyof typeof ODG_LAYERS];

export const TRAFFIC_BEHAVIORS = {
    ORIGIN_DISTRIBUTION: {
        label: "Pre-Set Origins",
        code: "origin",
        zoneType: VIZ_ZONE_TYPES.PRESET_GEO,
    },
    DESTINATION_DISTRIBUTION: {
        label: "Pre-Set Destinations",
        code: "destination",
        zoneType: VIZ_ZONE_TYPES.PRESET_GEO,
    },
};
export const TRAFFIC_BEHAVIORS_LIST = Object.values(TRAFFIC_BEHAVIORS);
export type TTrafficBehaviorType = keyof typeof TRAFFIC_BEHAVIORS;
export type TTrafficBehavior = typeof TRAFFIC_BEHAVIORS[keyof typeof TRAFFIC_BEHAVIORS];

export const PRESET_GEOG_TYPES = {
    TAZ: {
        code: "taz",
        label: "Traffic Analysis Zone",
    },
    BLKGRP: {
        code: "blkgrp",
        label: "Block Group",
    },
    ZIP: {
        code: "zip",
        label: "Zip Code",
    },
    DA: {
        code: "da",
        label: "Dissemination Area",
    },
};
export const PRESET_GEOG_TYPES_LIST = Object.values(PRESET_GEOG_TYPES);

export type TPresetGeosType = keyof typeof PRESET_GEOG_TYPES;
export type TPresetGeos = typeof PRESET_GEOG_TYPES[keyof typeof PRESET_GEOG_TYPES];

export const DEFAULT_TRAFFIC_BEHAVIOR = TRAFFIC_BEHAVIORS.DESTINATION_DISTRIBUTION;

export const DEFAULT_MAP_LAYERS_VISIBILITY = {
    [ODG_LAYERS.DESTINATION.code]: true,
    [ODG_LAYERS.ORIGIN.code]: true,
    [ODG_LAYERS.BASE.code]: true,
    [ODG_LAYERS.UNSELECTED.code]: false,
    [ODG_LAYERS.LABEL.code]: true,
    [ODG_LAYERS.LEGEND.code]: true,
};

export const ODG_VIZ_DEFAULTS = {
    filters: {
        ...BASE_VIZ_DEFAULTS.filters,
        [ODG_ZONE_TYPES.PRESET_GEO.filterName]: [],
    },
    visualization: {
        ...BASE_VIZ_DEFAULTS.visualization,
        trafficBehaviors: [DEFAULT_TRAFFIC_BEHAVIOR],
        mapLayersVisibility: DEFAULT_MAP_LAYERS_VISIBILITY,
    },
};
