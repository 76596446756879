export const StlExternalLink = ({
    href,
    id = "external-link",
    children,
    className = "",
    ...props
}: JSX.IntrinsicElements["a"]) => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!href) {
            event.preventDefault();
        }
    };

    return (
        <a
            href={href}
            id={id}
            className={`stl-link ${className}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClick}
            {...props}
        >
            {children}
        </a>
    );
};
