export const YES_NO_FILTER_OPTIONS = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
] as const;

export const YES_NO_NULL_FILTER_OPTIONS = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
    { label: "Not set", value: "Not set" },
] as const;

export const ANY_FILTER_OPTION = { value: "any", label: "Any" } as const;

export const NONE_FILTER_OPTION = { value: "-", label: "None" } as const;
