let width: number;

export const getScrollBarWidth = () => {
    if (width) {
        return width;
    }

    const document = window.document;
    const body = document.body;

    const measurer = document.createElement("div");
    measurer.className = "scroll-bar-width-measure";

    body.appendChild(measurer);
    width = measurer.getBoundingClientRect().width - measurer.clientWidth;
    body.removeChild(measurer);

    return width;
};
