import { BASE_MAP_STYLES } from "@app/viz3/base/map/baseMapStyles.constants";
import { TRAVEL_MODE_OPTIONS } from "@app/viz3/atVisualization/state/atViz.constants";

export const MAP_STYLES = {
    ...BASE_MAP_STYLES,
    DISTRIBUTION_ZONES: {
        ...BASE_MAP_STYLES.DISTRIBUTION_ZONES,
        COLOR_VOLUME_PERCENTAGE_CHANGE: {
            HIGH: "#71BC98",
            MID: "#679CAE",
            LOW: "#4668BF",
            HOVER: BASE_MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HOVER,
        },
        CHOROPLETH_OPACITY: BASE_MAP_STYLES.DISTRIBUTION_ZONES.CHOROPLETH_OPACITY.AREA,
        COLOR_DOT_DENSITY: {
            [TRAVEL_MODE_OPTIONS.BIKE.code]: "rgba(136, 184, 246, 1)",
            [TRAVEL_MODE_OPTIONS.PEDESTRIAN.code]: "rgba(151, 198, 103, 1)",
            [TRAVEL_MODE_OPTIONS.VEHICLE.code]: "rgba(255, 151, 151, 1)",
        },
    },
};
