import { HttpService } from "@common/services/server/http.service";
import {
    IStudyData,
    IStudyResponse,
    IGetStudiesResponse,
    IPurchaseRequestResponse,
} from "@common/services/server/studiesApi.types";

export const StudiesApiService = {
    getStudies() {
        return HttpService.get<IGetStudiesResponse>("/studies").catch(
            HttpService.showIfError("Unknown error occurred while fetching studies."),
        );
    },
    getStudy(studyId: IStudyData["study_id"]) {
        return HttpService.get<IStudyResponse>(`/studies/${studyId}`).catch(
            HttpService.showIfError("Unknown error occurred while fetching study."),
        );
    },
    createStudy(study: IStudyData) {
        return HttpService.post<IStudyResponse>("/studies", study)
            .then(HttpService.showIfSuccess("Study has been successfully created."))
            .catch(HttpService.showIfError("Unknown error occurred while creating study."));
    },
    updateStudy(studyId: IStudyData["study_id"], data: Partial<IStudyData>) {
        return HttpService.put<IStudyResponse>(`/studies/${studyId}`, data)
            .then(HttpService.showIfSuccess("Study has been successfully updated."))
            .catch(HttpService.showIfError("Unknown error occurred while updating study."));
    },
    requestPurchase(data: Partial<IStudyData>) {
        return HttpService.post<IPurchaseRequestResponse>("/study_purchase_request", data)
            .then(HttpService.showIfSuccess("Purchase request has been successfully completed."))
            .catch(
                HttpService.showIfError(
                    "Unknown error occurred while completing purchase request.",
                ),
            );
    },
};
