const ACTION_PREFIX = "ODG_VIZ";

export const ODG_VIZ_ACTIONS = {
    SET_CLICKED_ZONE_ID: `${ACTION_PREFIX}/SET_CLICKED_ZONE_ID`,
    SET_PRE_SELECTED_ZONE: `${ACTION_PREFIX}/SET_PRE_SELECTED_ZONE`,
    SET_DISTRIBUTION_LAYERS: `${ACTION_PREFIX}/SET_DISTRIBUTION_LAYERS`,
    SET_LAYER_CONFIGURATION: `${ACTION_PREFIX}/SET_LAYER_CONFIGURATION`,
    SET_VIZ_STATE: `${ACTION_PREFIX}/SET_VIZ_STATE`,
    SET_CACHED_ZONES: `${ACTION_PREFIX}/SET_CACHED_ZONES`,
    SET_CACHED_ZONES_FILTER: `${ACTION_PREFIX}/SET_CACHED_ZONES_FILTER`,
};
