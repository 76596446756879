import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from "@app/store";
import { AdminApiService } from "@common/services/server/adminApi.service";
import { XSRFToken } from "@common/services/XSRFToken.service";
import { history } from "@common/services/history.service";
import {
    getByPassSignatureDiff,
    getIsAdmin,
    getIsEmulating,
    getIsSuperUser,
} from "@app/store/currentUser/currentUser.selector";
import { REDIRECT_URL_KEY } from "@app/store/currentUser/currentUser.constants";
import { getPathWithSearchRedirect } from "@app/auth/state/auth.helpers";
import { StlNotification } from "@common/components";
import { ReloadModal } from "@common/components/dialogs/reloadDialog";

export const isSignatureDiff = (inClient: string, inServer: string): boolean => {
    return !!inClient && !!inServer && inClient !== inServer;
};

export interface IHttpError<T = any> extends AxiosError<T> {
    isHttpError?: boolean;
    handled?: boolean;
    showIfError?: string;
}

export type TInterceptors = {
    request: number[];
    response: number[];
};

const handleSuccess = (response: AxiosResponse) => {
    const state = store.getState();
    const bypass = getByPassSignatureDiff(state);

    // @ts-ignore Property 'baseMapDebug' does not exist on type 'Window & typeof globalThis'.
    if (!bypass && isSignatureDiff(window.INSIGHT_CONFIG.signature, response.headers.signature)) {
        ReloadModal.show();
    }

    return response.data;
};

const handleError = (error: any): Promise<IHttpError> => {
    error.isHttpError = true;

    sessionStorage.setItem(REDIRECT_URL_KEY, window.location.pathname);

    if (error.response) {
        const { data, headers, status } = error.response;
        const state = store.getState();
        // Error priority: 401 > stale code > other errors from server

        // If a request raises a 401, clear XSRF-TOKEN and redirect to /login
        // unless navigating to choose Organization
        if (status === 401) {
            if (headers["select-organization"]) {
                error.handled = true;
                XSRFToken.set(data.authtoken);

                const redirectPath = getPathWithSearchRedirect(
                    "/chooseOrg",
                    window.location.search,
                );

                history.push(redirectPath);

                return Promise.reject(error);
            }

            if (getIsAdmin(state) && window.location.href.includes("/sandbox")) {
                error.handled = true;

                return Promise.reject(error);
            }

            if (getIsSuperUser(state) && getIsEmulating(state)) {
                // This only time this should happen is if an admin user
                // is emulating someone, and that user gets deactivated.
                AdminApiService.endEmulation();
            } else {
                XSRFToken.remove();

                const shouldRedirect =
                    !data?.skip_redirect && !window.location.href.includes("/login");
                if (shouldRedirect) {
                    history.push("/login");
                    window.location.reload();
                }
            }
            // error will be handled at login page
            return Promise.reject(error);
        }

        // bypass check if no configParams yet
        const bypass = getByPassSignatureDiff(state);
        // @ts-ignore Property 'baseMapDebug' does not exist on type 'Window & typeof globalThis'.
        if (
            !bypass &&
            headers &&
            isSignatureDiff(window.INSIGHT_CONFIG.signature, headers.signature)
        ) {
            error.handled = true;
            ReloadModal.show();
            return Promise.reject(error);
        }

        if (status === 403) {
            if (window.location.href.includes("/login")) {
                // error will be handled at login page
                return Promise.reject(error);
            }

            error.handled = true;
            const shouldRedirect = !data || !data.skip_redirect;

            if (shouldRedirect) {
                history.push("/status403");
            }

            return Promise.reject(error);
        }

        if (status === 404) {
            error.handled = true;
            StlNotification.error("Unable to locate the requested resource.", { hideTimeout: 0 });

            return Promise.reject(error);
        }
    }

    // Otherwise, raise the error as normal
    return Promise.reject(error);
};

export const initializeHTTPInterceptors = (): TInterceptors => {
    const interceptors: TInterceptors = {
        request: [],
        response: [],
    };

    interceptors.request.push(
        axios.interceptors.request.use((config: AxiosRequestConfig) => {
            // append signature to all GET ajax requests
            if (
                !(config.url?.endsWith(".html") || config.url?.endsWith("grid.json")) &&
                config.method === "get"
            ) {
                if (!config.params) {
                    config.params = {};
                }
                // @ts-ignore Property 'baseMapDebug' does not exist on type 'Window & typeof globalThis'.
                config.params.signature = window.INSIGHT_CONFIG.signature;
            }
            return config;
        }),
    );

    interceptors.response.push(axios.interceptors.response.use(handleSuccess, handleError));

    return interceptors;
};
