import { ILocation } from "@common/components/geocoder/geocoder";
import { IMapError } from "@common/components/baseMap/mapErrorAlert/mapErrorAlert";
import { IAnalysis } from "@common/services/server/analysesApi.types";
import {
    ILowCountData,
    IMapState,
    ISelectedZone,
    THoveredZone,
} from "@app/pirateShip/state/pirateShip.types";
import type {
    IDayPart,
    IDayType,
    IDateGroups,
} from "@app/pirateShip/pages/resultsPage/visualizationContent/visualization/visualisation.types";
import { IViewport } from "@common/services/server/screenshotsApi.types";
import {
    STEPS_LIST,
    DEFAULT_PERIOD,
    DAILY_TRAFFIC_METRIC_TYPE,
    TStep,
    TDailyTrafficMetric,
    TMetricOptions,
} from "./pirateShip.constants";

export interface IConfigurations {
    dataPeriods: number[];
    selectedZones: ISelectedZone[];
    analysisName: string;
    metrics: TMetricOptions[number]["label"] | null;
}

export interface IUiStates {
    showAnalysesHistory: boolean;
    activeStep: TStep;
    location: ILocation | null;
    hoveredZone: THoveredZone | null;
    mapError: IMapError | null;
    showMoreMetrics: boolean;
    showQuoteRequest: boolean;
    showChooseMetrics: boolean;
    showUserBadge: boolean;
}

export interface IDailyTrafficWidget {
    group_mode: TDailyTrafficMetric["id"];
    date_groups: IDateGroups | null;
    day_types: IDayType[] | null;
    day_parts: IDayPart[] | null;
}

export interface IVisualization {
    selectedAnalysis: IAnalysis | null;
    zones: ISelectedZone[];
    filters: {
        selectedZones: ISelectedZone[];
        selectedMonthIndex: number;
    };
    widgets: {
        dailyTraffic: IDailyTrafficWidget;
    };
    mapState: Partial<IMapState>;
    lowCountData: ILowCountData | null;
}

export interface IPendingExportRequests {
    arePending: boolean;
    requestCount: number;
}

export interface IPirateShipState {
    configurations: IConfigurations;
    uiStates: IUiStates;
    visualization: IVisualization;
    pendingExportRequests: IPendingExportRequests;
    viewport?: IViewport | null;
}

export const PIRATE_SHIP_INITIAL_STATE = {
    configurations: {
        dataPeriods: [DEFAULT_PERIOD],
        selectedZones: [] as ISelectedZone[],
        analysisName: "",
        metrics: null,
    },
    uiStates: {
        showAnalysesHistory: false,
        activeStep: STEPS_LIST[0],
        location: null,
        hoveredZone: null,
        mapError: null,
        showMoreMetrics: false,
        showQuoteRequest: false,
        showChooseMetrics: false,
        showUserBadge: false,
    },
    visualization: {
        selectedAnalysis: null,
        zones: [] as ISelectedZone[],
        filters: {
            selectedZones: [] as ISelectedZone[],
            selectedMonthIndex: 0,
        },
        widgets: {
            dailyTraffic: {
                group_mode: DAILY_TRAFFIC_METRIC_TYPE.day_part.id,
                date_groups: null,
                day_types: null,
                day_parts: null,
            },
        },
        mapState: {},
        lowCountData: null,
    },
    pendingExportRequests: {
        arePending: false,
        requestCount: 0,
    },
    viewport: null,
} as IPirateShipState;
