import React, { useMemo, useState } from "react";
import { useAppSelector } from "@app/store/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { StlLightbox, StlTabs } from "@common/components";
import { getIsUserHasPrivilege } from "@app/store/currentUser/currentUser.selector";
import { SubscriptionTab } from "@app/bigBang/layout/navigation/subscriptionModal/components/general/subscriptionTab";
import { SettingsTab } from "@app/bigBang/layout/navigation/subscriptionModal/components/settings/settingsTab";
import "./subscriptionModal.less";

type TGetIsHiddenProps = {
    displayGeneral: boolean;
};

const TABS = [
    {
        id: "subscriptionInfo",
        name: "Subscription Info",
        Component: SubscriptionTab,
        getIsHidden: ({ displayGeneral }: TGetIsHiddenProps) => !displayGeneral,
    },
    {
        id: "settings",
        name: "Settings",
        title: "Settings - Account",
        Component: SettingsTab,
    },
];

type TState = {
    activeTabId: typeof TABS[number]["id"];
};

type TProps = {
    isOpen: boolean;
    close: () => void;
};

const INITIAL_STATE = {
    activeTabId: TABS[0].id,
} as TState;

export const SubscriptionModal = ({ isOpen, close }: TProps) => {
    const [activeTabId, setActiveTabId] = useState(INITIAL_STATE.activeTabId);

    const displayGeneral = useAppSelector(state =>
        getIsUserHasPrivilege(state, "manage organization"),
    );

    const tabsList = useMemo(
        () => TABS.filter(tab => !tab.getIsHidden?.({ displayGeneral })),
        [displayGeneral],
    );

    const content = useMemo(() => {
        const TabComponent = TABS.find(tab => tab.id === activeTabId)!.Component;

        return (
            <div className="content-container">
                <div className="header">
                    <StlTabs tabs={tabsList} activeTabId={activeTabId} onSelect={setActiveTabId} />
                    <button
                        type="button"
                        aria-label="Close modal"
                        className="close-button"
                        onClick={close}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <div className="tab-content">
                    <TabComponent />
                </div>
            </div>
        );
    }, [activeTabId, tabsList, close]);

    return (
        <StlLightbox
            className="stl-subscription-modal"
            show={isOpen}
            body={content}
            centered
            hideHeader
            disableEscapeKeyDown
        />
    );
};
