import DirectSelectMode from "@mapbox/mapbox-gl-draw/src/modes/direct_select";
import createSupplementaryPoints from "@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points";
import * as Constants from "@mapbox/mapbox-gl-draw/src/constants";

/**
 * This is a replacement for regular DirectSelectMode, but without midpoints.
 * We need this patched mode to hide midpoints from the user during drawing gates (lines with 2 points only)
 */
export const DirectSelectWithoutMidpointsMode = {
    ...DirectSelectMode,
    toDisplayFeatures(state, geojson, push) {
        if (state.featureId === geojson.properties.id) {
            geojson.properties.active = Constants.activeStates.ACTIVE;
            push(geojson);
            createSupplementaryPoints(geojson, {
                map: this.map,
                midpoints: false,
                selectedPaths: state.selectedCoordPaths,
            }).forEach(push);
        } else {
            geojson.properties.active = Constants.activeStates.INACTIVE;
            push(geojson);
        }
        this.fireActionable(state);
    },
};
