import { MODES_OF_TRAVEL } from "@common/constants/analysis.constants";

export const ZONE_VEHICLE_CALIBRATION_TYPES = {
    ADT: {
        value: "ADT",
        label: "ADT",
    },
    AADT: {
        value: "AADT",
        label: "AADT",
    },
    AWDT: {
        value: "AWDT",
        label: "AWDT",
    },
    AAWDT: {
        value: "AAWDT",
        label: "AAWDT",
    },
};

export const ZONE_VEHICLE_CALIBRATION_TYPES_LIST = Object.values(ZONE_VEHICLE_CALIBRATION_TYPES);

export const ZONE_BIKE_CALIBRATION_TYPE = "AADBT" as const;
export const ZONE_PED_CALIBRATION_TYPE = "AADPT" as const;

export const MAX_VEHICLE_CALIBRATION_VALUE = 1000000;
export const MAX_BICYCLE_CALIBRATION_VALUE = 10000;
export const MAX_PEDESTRIAN_CALIBRATION_VALUE = 999999;

export const CALIBRATIONS_BY_TRAVEL_MODE = {
    VEHICLE: {
        id: "vehicleCalibration",
        typeName: "Vehicles",
        travelModes: [MODES_OF_TRAVEL.ALL_VEHICLES.code, MODES_OF_TRAVEL.TRUCK.code],
        maxValue: MAX_VEHICLE_CALIBRATION_VALUE,
        typeAccessor: "calibration_type",
        valueAccessor: "calibration_value",
        descriptionAccessor: "description",
    },
    BICYCLE: {
        id: "bicycleCalibration",
        typeName: "Bicycles",
        travelModes: [MODES_OF_TRAVEL.BICYCLE.code],
        maxValue: MAX_BICYCLE_CALIBRATION_VALUE,
        typeAccessor: "bike_calibration_type",
        valueAccessor: "bike_calibration_value",
        descriptionAccessor: "bike_description",
    },
    PEDESTRIAN: {
        id: "pedestrianCalibration",
        typeName: "Pedestrians",
        travelModes: [MODES_OF_TRAVEL.PEDESTRIAN.code],
        maxValue: MAX_PEDESTRIAN_CALIBRATION_VALUE,
        typeAccessor: "ped_calibration_type",
        valueAccessor: "ped_calibration_value",
        descriptionAccessor: "ped_description",
    },
};
export const CALIBRATIONS_BY_TRAVEL_MODE_LIST = Object.values(CALIBRATIONS_BY_TRAVEL_MODE);

export const INITIAL_CALIBRATION_DETAIL = {
    // Vehicle
    calibration_type: ZONE_VEHICLE_CALIBRATION_TYPES.ADT.value,
    calibration_value: null,
    pers_ratio: 0.96,
    comm_md_ratio: 0.02,
    comm_hd_ratio: 0.02,
    collection_method: "",
    description: "",
    // Bike
    bike_calibration_type: ZONE_BIKE_CALIBRATION_TYPE,
    bike_calibration_value: null,
    bike_description: "",
    // Ped
    ped_calibration_type: ZONE_PED_CALIBRATION_TYPE,
    ped_calibration_value: null,
    ped_description: "",
    sa_version_id: 0,
};

export const ZONE_CALIBRATION_COLLECT_METHODS = [
    "Temporary Loop",
    "Permanent Loop",
    "Bluetooth",
    "Manual",
    "Extrapolated",
    "Other",
];

export const ZONE_CALIBRATION_COLLECT_METHODS_OPTIONS = ZONE_CALIBRATION_COLLECT_METHODS.map(
    method => ({
        label: method,
        value: method,
    }),
);
