import { merge } from "lodash-es";
import { VIZ_CODES } from "@app/viz3/base/visualizationsConfiguration";
import { BASE_VIZ_ACTIONS } from "@app/viz3/base/state/baseViz.actionTypes";
import { ODMF_VIZ_ACTIONS } from "./odmfViz.actionTypes";
import { ODMF_VIZ_INITIAL_STATE } from "./odmfViz.state";

export const odmfVizReducer = (state = ODMF_VIZ_INITIAL_STATE, { data, type }) => {
    switch (type) {
        case BASE_VIZ_ACTIONS.RESET_VIZ_STATE: {
            return ODMF_VIZ_INITIAL_STATE;
        }

        case BASE_VIZ_ACTIONS.SET_ANALYSIS_COMMON_CONFIG: {
            let newLayerConfig;
            if (data.vizCode === VIZ_CODES.ODMF && data.layerConfigurations) {
                newLayerConfig = merge(
                    {},
                    { ...ODMF_VIZ_INITIAL_STATE.map.layerConfigurations },
                    { ...data.layerConfigurations },
                );
            } else {
                newLayerConfig = ODMF_VIZ_INITIAL_STATE.map.layerConfigurations;
            }

            return {
                ...state,
                map: {
                    ...state.map,
                    layerConfigurations: {
                        ...state.map.layerConfigurations,
                        ...newLayerConfig,
                    },
                },
            };
        }

        case ODMF_VIZ_ACTIONS.SET_CLICKED_ZONE: {
            const zone = {
                zoneId: data.zone?.zone_id,
                zoneType: data.zone?.zone_type,
            };
            return {
                ...state,
                map: {
                    ...state.map,
                    clickedZone: zone,
                },
            };
        }

        case ODMF_VIZ_ACTIONS.SET_PRE_SELECTED_ZONE: {
            return {
                ...state,
                preSelectedZones: data,
            };
        }

        case ODMF_VIZ_ACTIONS.SET_DISTRIBUTION_LAYERS: {
            return {
                ...state,
                distributionLayers: data,
            };
        }

        case ODMF_VIZ_ACTIONS.SET_LAYER_CONFIGURATION: {
            return {
                ...state,
                map: {
                    ...state.map,
                    layerConfigurations: {
                        ...state.map.layerConfigurations,
                        [data.configurationType]: {
                            ...state.map.layerConfigurations[data.configurationType],
                            ...data.configuration,
                        },
                    },
                },
            };
        }

        case ODMF_VIZ_ACTIONS.SET_VIZ_STATE: {
            return {
                ...state,
                ...data.state,
            };
        }

        default:
            return state;
    }
};
