import {
    TZoneSetModes,
    TZoneSetScreenModes,
    ZONE_SET_MODES,
    ZONE_SET_SCREEN_MODES,
} from "@app/bigBang/zoneSetModal/state/zoneSetModal.constants";
import { IMapError } from "@common/components/baseMap/mapErrorAlert/mapErrorAlert";
import { IZoneSet, THoveredZone } from "@common/features/zones/zones.types";

export interface IUiStates {
    viewMode: TZoneSetModes;
    screenMode: TZoneSetScreenModes;
    isLoading: boolean;
    isZoneSetModalOpen: boolean;
    isUploadZoneSetModalOpen: boolean;
    isLinkedZoneSetsModalOpen: boolean;
    isZonesTableCollapsed: boolean;
}

export interface IZoneSetModalMap {
    hoveredZone: null | THoveredZone;
    hoveredBusZones: null | THoveredZone[];
    mapError: null | IMapError;
}

export interface IZoneSetModalInitialState {
    uiStates: IUiStates;
    zoneSet: Partial<IZoneSet>;
    otherZoneSet: null | IZoneSet;
    zoneSetName: {
        isEditMode: boolean;
        value: null | string;
    };
    map: IZoneSetModalMap;
    selectedZoneIds: Array<number>;
    selectedZoneSetId?: number;
}

export const ZONE_SET_MODAL_INITIAL_STATE: IZoneSetModalInitialState = {
    uiStates: {
        viewMode: ZONE_SET_MODES.CREATE,
        screenMode: ZONE_SET_SCREEN_MODES.CREATE_ZONE_SET,
        isLoading: false,
        isZoneSetModalOpen: false,
        isUploadZoneSetModalOpen: false,
        isLinkedZoneSetsModalOpen: false,
        isZonesTableCollapsed: false,
    },
    zoneSet: {},
    otherZoneSet: null,
    zoneSetName: {
        isEditMode: false,
        value: null,
    },
    map: {
        hoveredZone: null,
        hoveredBusZones: [],
        mapError: null,
    },
    selectedZoneIds: [] as Array<number>,
};
