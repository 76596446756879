import { MODES_OF_TRAVEL } from "@common/constants/analysis.constants";
import { COLORS } from "@common/constants/color.constants";
import { ZONE_KINDS } from "@common/constants/zoneLibrary.constants";
import { ValuesOfType } from "@common/utils/utility.types";
import { arrayIncludes } from "@root/src/common/utils/arrayIncludes";

//TODO: TravelModeCode need for osm zones request
export const OSM_NETWORK_TYPES = {
    VEHICLE: {
        id: "vehicle",
        name: "Vehicle Network",
        travelModeCode: MODES_OF_TRAVEL.ALL_VEHICLES.code,
    },
    BICYCLE: {
        id: "bicycle",
        name: "Bike Network",
        travelModeCode: MODES_OF_TRAVEL.BICYCLE.code,
    },
    PEDESTRIAN: {
        id: "pedestrian",
        name: "Ped Network",
        travelModeCode: MODES_OF_TRAVEL.PEDESTRIAN.code,
    },
} as const;

export type TOSMNetworkType = ValuesOfType<typeof OSM_NETWORK_TYPES>;

export const OSM_NETWORK_TYPES_LIST = Object.values(OSM_NETWORK_TYPES);

export const ROAD_TYPES = {
    MOTORWAY: {
        id: "motorway",
        name: "Motorway",
        osmNetwork: [OSM_NETWORK_TYPES.VEHICLE.id],
        roadIds: ["motorway"],
    },
    RAMP: {
        id: "ramp",
        name: "On/Off Ramps",
        osmNetwork: [OSM_NETWORK_TYPES.VEHICLE.id],
        roadIds: ["motorway_link", "primary_link"],
    },
    TRUNK: {
        id: "trunk",
        name: "Trunk",
        osmNetwork: [OSM_NETWORK_TYPES.VEHICLE.id],
        roadIds: ["trunk", "trunk_link"],
    },
    PRIMARY: {
        id: "primary",
        name: "Primary",
        osmNetwork: [
            OSM_NETWORK_TYPES.VEHICLE.id,
            OSM_NETWORK_TYPES.BICYCLE.id,
            OSM_NETWORK_TYPES.PEDESTRIAN.id,
        ],
        roadIds: ["primary"],
    },
    SECONDARY: {
        id: "secondary",
        name: "Secondary",
        osmNetwork: [
            OSM_NETWORK_TYPES.VEHICLE.id,
            OSM_NETWORK_TYPES.BICYCLE.id,
            OSM_NETWORK_TYPES.PEDESTRIAN.id,
        ],
        roadIds: ["secondary", "secondary_link"],
    },
    TERTIARY: {
        id: "tertiary",
        name: "Tertiary",
        osmNetwork: [
            OSM_NETWORK_TYPES.VEHICLE.id,
            OSM_NETWORK_TYPES.BICYCLE.id,
            OSM_NETWORK_TYPES.PEDESTRIAN.id,
        ],
        roadIds: ["tertiary", "tertiary_link"],
    },
    RESIDENTIAL: {
        id: "residential",
        name: "Residential",
        osmNetwork: [
            OSM_NETWORK_TYPES.VEHICLE.id,
            OSM_NETWORK_TYPES.BICYCLE.id,
            OSM_NETWORK_TYPES.PEDESTRIAN.id,
        ],
        roadIds: ["residential"],
    },
    SERVICE: {
        id: "service",
        name: "Service",
        osmNetwork: [
            OSM_NETWORK_TYPES.VEHICLE.id,
            OSM_NETWORK_TYPES.BICYCLE.id,
            OSM_NETWORK_TYPES.PEDESTRIAN.id,
        ],
        roadIds: ["service"],
    },
    PEDESTRIAN: {
        id: "pedestrian",
        name: "Pedestrian",
        osmNetwork: [OSM_NETWORK_TYPES.BICYCLE.id, OSM_NETWORK_TYPES.PEDESTRIAN.id],
        roadIds: ["pedestrian", "footway", "sidewalk"],
    },
    CYCLEWAY: {
        id: "cycleway",
        name: "Cycleway",
        osmNetwork: [OSM_NETWORK_TYPES.BICYCLE.id],
        roadIds: ["cycleway"],
    },
    PATH: {
        id: "path",
        name: "Path",
        osmNetwork: [OSM_NETWORK_TYPES.BICYCLE.id, OSM_NETWORK_TYPES.PEDESTRIAN.id],
        roadIds: ["path", "trail"],
    },
    UNCLASSIFIED: {
        id: "unclassified",
        name: "Unclassified",
        osmNetwork: [
            OSM_NETWORK_TYPES.VEHICLE.id,
            OSM_NETWORK_TYPES.BICYCLE.id,
            OSM_NETWORK_TYPES.PEDESTRIAN.id,
        ],
        roadIds: ["unclassified"],
    },
} as const;

export const ROAD_TYPES_LIST: TRoadType[] = Object.values(ROAD_TYPES);

export type TRoadTypeKey = keyof typeof ROAD_TYPES;
export type TRoadType = typeof ROAD_TYPES[keyof typeof ROAD_TYPES];
export type TRoadId = TRoadType["roadIds"][number];

export const OSM_SEGMENT_SPLIT_TYPES = {
    TERTIARY: {
        id: "tertiary",
        name: "Tertiary Intersections",
    },
    RESIDENTIAL: {
        id: "residential",
        name: "Residential Intersections",
    },
} as const;

export const OSM_SEGMENT_SPLIT_TYPES_LIST = Object.values(OSM_SEGMENT_SPLIT_TYPES);

export type TOSMSegmentSplitType =
    typeof OSM_SEGMENT_SPLIT_TYPES[keyof typeof OSM_SEGMENT_SPLIT_TYPES];

export const ZONE_TYPES = {
    OSM_LINE_SEGMENTS: {
        id: "osmLineSegments",
        name: "OSM Line Segments",
        zoneKinds: [ZONE_KINDS.OSM],
    },
    STANDARD_AREAS: {
        id: "standardAreas",
        name: "Standard Areas",
        zoneKinds: [
            ZONE_KINDS.TAZ,
            ZONE_KINDS.ZIP_US_2020,
            ZONE_KINDS.BLOCK_GROUP_US_2020,
            ZONE_KINDS.TRACT_US_2020,
            ZONE_KINDS.CITY_US_2020,
            ZONE_KINDS.COUNTY_US_2020,
            ZONE_KINDS.TRACT_CA,
            ZONE_KINDS.DA,
            ZONE_KINDS.SUBDIVISION,
        ],
        oldZoneKinds: [ZONE_KINDS.ZIP, ZONE_KINDS.BLOCK_GROUP, ZONE_KINDS.TRACT_US],
    },
    USER: {
        id: "user",
        name: "User",
        zoneKinds: [ZONE_KINDS.USER],
    },
    TRANSIT: {
        id: "transit",
        name: "Transit",
        zoneKinds: [ZONE_KINDS.BUS, ZONE_KINDS.RAIL],
    },
} as const;

export type TZoneType = typeof ZONE_TYPES[keyof typeof ZONE_TYPES];
export type TUserZoneType = typeof ZONE_TYPES["USER"];
export type TStandardAreasType = typeof ZONE_TYPES["STANDARD_AREAS"];
export type TZoneLibraryZoneType = Exclude<TZoneType, TUserZoneType>;

// Hide User option for 8.0 release
export const ZONE_TYPES_LIST = Object.values(ZONE_TYPES).filter(
    zoneType => zoneType.id !== ZONE_TYPES.USER.id,
) as TZoneLibraryZoneType[];

export const DEFAULT_ZONE_TYPE = { id: ZONE_TYPES.OSM_LINE_SEGMENTS.id };

export const DEFAULT_STANDARD_AREA = ZONE_KINDS.BLOCK_GROUP_US_2020;

export const DEFAULT_BUS_ZONES_BUFFER_RADIUS = 200;

export const MIN_INTERACTIVE_ZOOM = {
    DEFAULT: 9,
    OSM: 13,
    RAIL: 8,
    BUS: 8,
    COUNTY_US_2020: 7,
    LINE_GATES: 13,
};

export const MAP_STYLES = {
    LINE_WIDTH: 6,
    GEOM_Z_INDEX: 2,
    OUTLINE_WIDTH: 5,
    POLYGON_OUTLINE_WIDTH: 3,
    LINE_WIDTH_HOVER: 10,
    OUTLINE_Z_INDEX: 1,
    OPACITY: {
        ACTIVE: 0.8,
        INACTIVE: 0.3,
    },
    ZONE_COLORS: {
        SELECTED: COLORS.YELLOW,
        QUESTIONABLE: COLORS.RED,
        UNSELECTED_LINE: COLORS.LIGHT_GREY,
        UNSELECTED_POINT: COLORS.LIGHT_GREY,
        UNSELECTED_FILL: COLORS.TRANSPARENT,
        HOVER: COLORS.WHITE,
    },
};

export const LAYERS_GROUP_LAYER_TYPES = {
    LINE: "all_geometries_line",
    POLYGON: "all_geometries_polygon",
    POINT: "all_geometries_point",
    GATE: "all_geometries_gate",
};

// TODO: Replace other zone roles (oz, dz, mfz)
export const ZONE_ROLES = {
    ZONE_SET: {
        id: "zoneSet",
        name: "Zone set zones",
        accessor: "zoneSet",
    },
};

export const COLOR_BY_ROLE = {
    oz: COLORS.BLUE,
    dz: COLORS.YELLOW,
    [ZONE_ROLES.ZONE_SET.accessor]: COLORS.BLUE,
};
export type TRoadOsmNetworkType = TRoadType["osmNetwork"][number];
export const ZONE_MANAGER_MODES = {
    EDIT: "EDIT",
    VIEW: "VIEW",
} as const;
export type TZoneManagerMode = typeof ZONE_MANAGER_MODES[keyof typeof ZONE_MANAGER_MODES];

export const PASS_THROUGH_OPTIONS = {
    PassThrough: {
        id: "Yes",
        name: "Pass-Through",
        icon: "/img/zone-directions/pass-through.svg",
    },
    NonPassThrough: {
        id: "No",
        name: "Non-Pass-Through",
        icon: "/img/zone-directions/non-pass-through.svg",
    },
} as const;

export const PASS_THROUGH_OPTIONS_LIST = Object.values(PASS_THROUGH_OPTIONS);

export const DIRECTION_OPTIONS = {
    UniDirectional: {
        id: "UniDirectional",
        name: "Uni-Directional",
        icon: "/img/zone-directions/uni-directional.svg",
    },
    BiDirectional: {
        id: "BiDirectional",
        name: "Bi-Directional",
        icon: "/img/zone-directions/bi-directional.svg",
    },
    NoDirection: {
        id: "NoDirection",
        name: "No Direction",
        icon: "/img/zone-directions/no-direction.svg",
    },
} as const;
export const POLYGON_DIRECTION_OPTIONS_LIST = Object.values(DIRECTION_OPTIONS);
export type TDirectionOption = typeof DIRECTION_OPTIONS[keyof typeof DIRECTION_OPTIONS];
// Line Zones always have direction
export const LINE_DIRECTION_OPTIONS_LIST = POLYGON_DIRECTION_OPTIONS_LIST.filter(
    option => option.id !== DIRECTION_OPTIONS.NoDirection.id,
);

export const ZONE_GATES_OPTIONS = {
    START_GATE: {
        id: "Start",
        name: "Start",
        color: "green",
        directionProperty: "s_geom_direction",
        geometryProperty: "start_geometry",
    },
    MIDDLE_GATE: {
        id: "Middle",
        name: "Middle",
        color: "yellow",
        directionProperty: "direction",
        geometryProperty: "geometry",
    },
    END_GATE: {
        id: "End",
        name: "End",
        color: "red",
        directionProperty: "e_geom_direction",
        geometryProperty: "end_geometry",
    },
} as const;
export const ZONE_GATES_OPTIONS_LIST = Object.values(ZONE_GATES_OPTIONS);

export type TGate = typeof ZONE_GATES_OPTIONS[keyof typeof ZONE_GATES_OPTIONS];
export type TDirectionProperty = TGate["directionProperty"];

export const ROAD_TYPE_OPTIONS = {
    BLANK: { name: "Leave as Blank", id: "B" },
    PRIMARY: { name: "Primary", id: "P" },
    SECONDARY: { name: "Secondary", id: "S" },
    LOCAL: { name: "Local", id: "L" },
    RAMP: { name: "Ramp", id: "R" },
} as const;
export const ROAD_TYPE_OPTIONS_LIST = Object.values(ROAD_TYPE_OPTIONS);
export type TRoadTypeOption = ValuesOfType<typeof ROAD_TYPE_OPTIONS>;

export const MANAGER_MODES = {
    VIEW_MAP: "VIEW_MAP",
    VIEW_ZONE: "VIEW_ZONE",
    DRAW_POLYGON: "DRAW_POLYGON",
    EDIT_POLYGON: "EDIT_POLYGON",
    DRAW_LINE: "DRAW_LINE",
    EDIT_LINE: "EDIT_LINE",
    DRAW_LINE_GATE: "DRAW_LINE_GATE",
    EDIT_LINE_GATE: "EDIT_LINE_GATE",
    EDIT_SPOT_COUNTER: "EDIT_SPOT_COUNTER",
    PLACE_SPOT_COUNTER: "PLACE_SPOT_COUNTER",
} as const;

export type TManagerMode = keyof typeof MANAGER_MODES;

export const ZONES_STYLE = {
    ACTIVE: {
        color: COLORS.BLUE,
        opacity: MAP_STYLES.OPACITY.ACTIVE,
    },
    INACTIVE: {
        color: COLORS.BLUE,
        opacity: MAP_STYLES.OPACITY.INACTIVE,
    },
};

export const ZONES_MANAGER_CONTROLS = {
    ZONE_TYPES_PICKER: "ZONE_TYPES_PICKER",
    LINE_ZONE: "LINE_ZONE",
    POLYGON_ZONE: "POLYGON_ZONE",
    SPOT_COUNTER: "SPOT_COUNTER",
} as const;

export type TZonesManagerControl = keyof typeof ZONES_MANAGER_CONTROLS;

export const AVAILABLE_OSM_NETWORK_ROAD_TYPE_IDS = [
    ROAD_TYPES.MOTORWAY.id,
    ROAD_TYPES.RAMP.id,
    ROAD_TYPES.TRUNK.id,
    ROAD_TYPES.PRIMARY.id,
    ROAD_TYPES.SECONDARY.id,
    ROAD_TYPES.TERTIARY.id,
    ROAD_TYPES.RESIDENTIAL.id,
    ROAD_TYPES.UNCLASSIFIED.id,
] as const;
export const OSM_NETWORK_ROAD_TYPES_LIST = ROAD_TYPES_LIST.filter(
    type =>
        arrayIncludes(AVAILABLE_OSM_NETWORK_ROAD_TYPE_IDS, type.id) &&
        arrayIncludes(type.osmNetwork, OSM_NETWORK_TYPES.VEHICLE.id as TRoadOsmNetworkType),
);

export const CUSTOM_ZONE_DRAW_TYPE = {
    LINE: { id: "line", label: "Line" },
    POLYGON: { id: "polygon", label: "Polygon" },
    SPOT_COUNTER: { id: "spotCounter", label: "Spot Counter" },
} as const;

export const ERROR_TYPES = {
    INVALID_GEOM: "invalid_geom",
    GEOM_TOO_LONG: "geom_too_long",
    GEOM_TOO_SHORT: "geom_too_short",
    NO_OSM_SEGMENT: "no_osm_segment",
};

export const ERROR_TYPES_MESSAGES = {
    [ERROR_TYPES.INVALID_GEOM]:
        "This Gate Zone has been previously edited and is not suitable. Please redraw.",
    [ERROR_TYPES.GEOM_TOO_LONG]:
        "This Gate Zone length exceeds the maximum length. Please redraw.",
    [ERROR_TYPES.GEOM_TOO_SHORT]:
        "This Gate Zone length doesn't exceed the minimum length. Please redraw.",
    [ERROR_TYPES.NO_OSM_SEGMENT]: "This Gate Zone does not intersect any roadways. Please redraw.",
} as const;

export const SERVICE_LAYER_ID = "vehicle_service";
