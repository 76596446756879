import { HttpService } from "@common/services/server/http.service";
import {
    IGetFileMappingsResponse,
    IPollFileRequestStatusResponse,
    IPollFileUploadStatusResponse,
    IUploadFileConfig,
    IUploadFileResponse,
    IUploadSpreadsheetFileToAnalysisConfig,
    IUploadSpreadsheetFileToAnalysisResponse,
    IUploadSpreadsheetFileToZoneSetConfig,
    IUploadSpreadsheetFileToZonesSetResponse,
    IUploadUserCSVFileConfig,
    IUploadUserCSVFileResponse,
    TPathFileMappingsData,
    TPathUploadFileData,
    TUploadFileData,
} from "@common/services/server/uploadApi.types";

export const UploadApiService = {
    uploadFile(to: TPathUploadFileData, [file]: TUploadFileData, config: IUploadFileConfig) {
        const _config = {
            headers: { "content-type": "multipart/form-data" },
        };

        const formData = new window.FormData();

        formData.append(config.fileFormDataName, file, file.name);

        if (config?.data) {
            Object.entries(config.data).forEach(([key, value]) => {
                formData.append(key, value);
            });
        }

        return HttpService.post<IUploadFileResponse>(`/upload/${to}`, formData, _config).catch(
            HttpService.showIfError("Unknown error occurred while uploading the file."),
        );
    },

    uploadSpreadsheetFileToZonesSet(
        [file]: TUploadFileData,
        config: IUploadSpreadsheetFileToZoneSetConfig,
    ) {
        const _config = {
            headers: { "content-type": "multipart/form-data" },
        };

        const formData = new window.FormData();

        formData.append(config.fileFormDataName, file, file.name);

        if (config?.data) {
            Object.entries(config.data).forEach(([key, value]) => {
                formData.append(key, value);
            });
        }

        return HttpService.post<IUploadSpreadsheetFileToZonesSetResponse>(
            "/set_from_xlsx",
            formData,
            _config,
        )
            .then(HttpService.showIfSuccess("File has been successfully uploaded."))
            .catch(HttpService.showIfError("Unknown error occurred while uploading the file."));
    },

    uploadSpreadsheetFileToAnalysis(
        [file]: TUploadFileData,
        config: IUploadSpreadsheetFileToAnalysisConfig,
    ) {
        const _config = {
            headers: { "content-type": "multipart/form-data" },
        };

        const formData = new window.FormData();

        formData.append(config.fileFormDataName, file, file.name);

        if (config?.data) {
            Object.entries(config.data).forEach(([key, value]) => {
                formData.append(key, value);
            });
        }

        return HttpService.post<IUploadSpreadsheetFileToAnalysisResponse>(
            "/zones_from_xlsx",
            formData,
            _config,
        )
            .then(HttpService.showIfSuccess("File has been successfully uploaded."))
            .catch(HttpService.showIfError("Unknown error occurred while uploading the file."));
    },

    uploadUserCSVFile([file]: TUploadFileData, orgId: number, config: IUploadUserCSVFileConfig) {
        const _config = {
            headers: { "content-type": "multipart/form-data" },
        };

        const formData = new window.FormData();

        formData.append(config.fileFormDataName, file, file.name);

        return HttpService.post<IUploadUserCSVFileResponse>(
            `/admin/organization/${orgId}/users/upload`,
            formData,
            _config,
        )
            .then(HttpService.showIfSuccess("File has been successfully uploaded."))
            .catch(HttpService.showIfError("Unknown error occurred while uploading the file."));
    },

    pollFileUploadStatus(to: TPathUploadFileData, uuid: string) {
        return HttpService.get<IPollFileUploadStatusResponse>(
            `/upload/${to}/${uuid}/status`,
        ).catch(
            HttpService.showIfError("Unknown error occurred while polling file upload status."),
        );
    },

    getFileMappings(to: TPathFileMappingsData, uuid: string) {
        return HttpService.get<IGetFileMappingsResponse>(`/upload/${to}/${uuid}/mappings`).catch(
            HttpService.showIfError("Unknown error occurred while fetching file mappings."),
        );
    },

    pollFileRequestStatus(requestId: number) {
        return HttpService.get<IPollFileRequestStatusResponse>(
            `/sets/shapefile_request_status/${requestId}`,
        ).catch(HttpService.showIfError("Unknown error occurred while polling request status."));
    },
};
