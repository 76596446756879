import { useEffect, useRef } from "react";

export const useValueRef = <T = any>(value: T) => {
    const valueRef = useRef<T | null>(null);

    useEffect(() => {
        valueRef.current = value;
    }, [value]);

    return valueRef;
};
