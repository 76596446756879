import { useEffect, useState } from "react";
import { searchFilter } from "@common/utils/searchFilter";

export const DEFAULT_ITEMS_LIMIT = 8 as const;

type TItem = {
    [key: string]: any;
};

export const sortItems = <T = TItem>({
    items,
    getItemLabel,
}: {
    items: Array<T>;
    getItemLabel: (item: T) => string;
}) =>
    [...items].sort((a, b) => {
        const _a = getItemLabel(a).toLowerCase();
        const _b = getItemLabel(b).toLowerCase();

        if (_a < _b) return -1;
        if (_a > _b) return 1;

        return 0;
    });

const findItems = <T = TItem>({
    items,
    searchToken,
    limit = DEFAULT_ITEMS_LIMIT,
    getItemLabel = (option: TItem) => option.label as string,
}: {
    limit?: number;
    searchToken: string;
    items: Array<T>;
    getItemLabel: (item: T) => string;
}) => {
    if (!searchToken) {
        return sortItems({ items: items.slice(0, limit), getItemLabel });
    }

    const foundItems = [];

    for (const item of items) {
        if (searchFilter(getItemLabel(item), searchToken)) {
            foundItems.push(item);

            if (foundItems.length >= limit) {
                break;
            }
        }
    }

    return sortItems({ items: foundItems, getItemLabel });
};

export const useTypeaheadSearch = <T = TItem>({
    items,
    searchToken,
    limit,
    getItemLabel,
    isLimited,
}: {
    limit?: number;
    isLimited: boolean;
    searchToken: string;
    items: Array<T>;
    getItemLabel: (item: T) => string;
}) => {
    const [foundItems, setFoundItems] = useState(items);

    useEffect(() => {
        if (isLimited) {
            setFoundItems(findItems({ items, searchToken, limit, getItemLabel }));
        } else {
            setFoundItems(items);
        }
    }, [items, searchToken, limit, getItemLabel, isLimited]);

    return foundItems;
};
