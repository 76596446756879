import classNames from "classnames";
import { StlButton } from "@common/components/button/button";
import "./pageSizeOptions.less";

const LABEL_TEXT = "ROWS" as const;

type TProps = {
    pageSize: number;
    rowsSelectorText: string;
    pageSizeOptions: Array<number>;
    onPageSizeChange: (size: number) => void;
};

export const StlTablePageSize = ({
    pageSize,
    pageSizeOptions,
    rowsSelectorText,
    onPageSizeChange,
}: TProps) => {
    const pageSizes = pageSizeOptions.map(page => ({
        value: page,
        label: page,
    }));

    const isSelected = (_pageSizeValue: number) => pageSize === _pageSizeValue;

    const getPageSizeItemClassNames = (_pageSizeValue: number) => ({
        "page-size-item": true,
        selected: isSelected(_pageSizeValue),
    });

    if (!pageSizeOptions.length) return null;

    return (
        <span className="stl-table-page-size">
            {LABEL_TEXT}
            {pageSizes.map(_pageSize => (
                <span
                    key={_pageSize.label}
                    className={classNames(getPageSizeItemClassNames(_pageSize.value))}
                >
                    <StlButton
                        variant="naked"
                        onClick={() => onPageSizeChange(_pageSize.value)}
                        aria-label={`${_pageSize.value} ${rowsSelectorText}`}
                    >
                        {_pageSize.label}
                    </StlButton>
                </span>
            ))}
        </span>
    );
};
