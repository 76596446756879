import { v4 as uuidv4 } from "uuid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    TCSStateRoute,
    TCSStateCorridor,
    ICorridorStudiesState,
    CORRIDOR_STUDIES_INITIAL_STATE,
} from "@app/analysisLightning/corridorStudies/state/corridorStudies.state";
import {
    TCSSegment,
    TCSDirectionOption,
    ICSSubRoute,
    IOrderedZone,
} from "@app/analysisLightning/corridorStudies/state/corridorStudies.types";
import { TCSGeographyType } from "@app/analysisLightning/corridorStudies/state/corridorStudies.constants";

const saveCurrentStateToCorridorHistory = (state: any) => {
    const { corridors, corridorIds, corridorHistoryStack } = state.corridorConfiguration;
    const historyItem = { corridors, corridorIds };

    corridorHistoryStack.push(historyItem);
};

const clearCorridorRouteInformationById = (state: any, corridorId: string) => {
    state.corridorConfiguration.corridors[corridorId].directions = [];
    delete state.corridorConfiguration.routes[corridorId];
};

export const { actions, reducer } = createSlice({
    name: "csReducer",
    initialState: CORRIDOR_STUDIES_INITIAL_STATE,
    reducers: {
        setAvailableGeographyTypes: (state, action: PayloadAction<Array<TCSGeographyType>>) => {
            return {
                ...state,
                availableGeographyTypes: action.payload,
            };
        },
        setIsSelectRegionAvailable: (state, action: PayloadAction<boolean>) => {
            state.corridorConfiguration.isSelectRegionAvailable = action.payload;
        },
        resetAnalysisLightningCSState: () => {
            return CORRIDOR_STUDIES_INITIAL_STATE;
        },
        setSessionHash: (
            state,
            action: PayloadAction<{
                sessionHash: string;
            }>,
        ) => {
            const { sessionHash } = action.payload;

            state.corridorConfiguration.sessionHash = sessionHash;
        },
        setCorridorName: (
            state,
            action: PayloadAction<{
                name: string;
                corridorId: TCSStateCorridor["id"];
            }>,
        ) => {
            const { corridorId, name } = action.payload;

            state.corridorConfiguration.names[corridorId] = name;
        },
        setCorridorNoRouteFound: (state, action: PayloadAction<Boolean>) => {
            state.corridorConfiguration.noRouteFound = action.payload;
        },
        setCorridorWaypoints: (
            state,
            action: PayloadAction<{
                waypoints: TCSStateCorridor["waypoints"];
                corridorId: TCSStateCorridor["id"];
            }>,
        ) => {
            saveCurrentStateToCorridorHistory(state);

            const { corridorId, waypoints } = action.payload;

            clearCorridorRouteInformationById(state, corridorId);

            state.corridorConfiguration.corridors[corridorId].waypoints = waypoints;
        },
        addNewWaypointToCorridor: (
            state,
            action: PayloadAction<{
                corridorId: TCSStateCorridor["id"];
            }>,
        ) => {
            const { corridorId } = action.payload;

            if (state.corridorConfiguration.corridors[corridorId].waypoints.length > 5) return;

            saveCurrentStateToCorridorHistory(state);

            const newSegments = [...state.corridorConfiguration.corridors[corridorId].waypoints];
            newSegments.splice(newSegments.length - 1, 0, null);

            state.corridorConfiguration.corridors[corridorId].waypoints = newSegments;
            delete state.corridorConfiguration.routes[corridorId];

            state.corridorConfiguration.activeCorridorSegmentMetadata = {
                corridorId,
                waypointIndex: newSegments.length - 2,
            };

            clearCorridorRouteInformationById(state, corridorId);
        },
        setIsBiDirectional: (
            state,
            action: PayloadAction<{
                corridorId: TCSStateCorridor["id"];
                isBiDirectional: boolean;
            }>,
        ) => {
            const { corridorId, isBiDirectional } = action.payload;

            state.corridorConfiguration.corridors[corridorId].isBiDirectional = isBiDirectional;
        },
        setCorridorRouteData: (
            state,
            action: PayloadAction<{
                corridorId: TCSStateCorridor["id"];
                corridorData: NonNullable<TCSStateRoute>["segments"] | null;
            }>,
        ) => {
            const { corridorId, corridorData } = action.payload;

            if (!state.corridorConfiguration.corridors[corridorId]) return;

            // Form Route object routeStateObj to then set it to state
            const routeStateObj: TCSStateRoute = corridorData?.length
                ? {
                      id: corridorId,
                      segments: corridorData,
                  }
                : null;

            if (routeStateObj) {
                state.corridorConfiguration.routes[corridorId] = routeStateObj;
            } else {
                delete state.corridorConfiguration.routes[corridorId];
            }

            state.corridorConfiguration.corridors[corridorId].directions = [];
        },
        deleteRouteById: (
            state,
            action: PayloadAction<{
                corridorId: TCSStateCorridor["id"];
            }>,
        ) => {
            const { corridorId } = action.payload;

            delete state.corridorConfiguration.routes[corridorId];
        },
        setCorridorRouteDirectionCode: (
            state,
            action: PayloadAction<{
                corridorId: TCSStateCorridor["id"];
                directions: TCSStateCorridor["directions"];
            }>,
        ) => {
            const { directions, corridorId } = action.payload;

            state.corridorConfiguration.corridors[corridorId].directions = directions;
        },
        addNewCorridor: (
            state,
            action: PayloadAction<{
                name?: string;
                isBiDirectional?: boolean;
                waypoints?: Array<TCSSegment | null>;
                directions?: Array<{ code: TCSDirectionOption["code"]; enabled: boolean }>;
            }>,
        ) => {
            if (state.corridorConfiguration.corridorHistoryStack.length > 0) {
                saveCurrentStateToCorridorHistory(state);
            }

            const { name, waypoints, directions, isBiDirectional } = action.payload;

            const uuid = uuidv4();
            const corridor: TCSStateCorridor = {
                id: uuid,
                directions: directions || [],
                waypoints: waypoints || [null, null],
                isBiDirectional: isBiDirectional || false,
            };

            state.corridorConfiguration.names[uuid] =
                name ||
                state.corridorConfiguration.names[state.corridorConfiguration.corridorIds[0]];
            state.corridorConfiguration.corridors[uuid] = corridor;
            state.corridorConfiguration.corridorIds.push(uuid);

            state.corridorConfiguration.activeCorridorSegmentMetadata = {
                corridorId: uuid,
                waypointIndex: 0,
            };
        },
        removeCorridorById: (state, action: PayloadAction<string>) => {
            saveCurrentStateToCorridorHistory(state);

            clearCorridorRouteInformationById(state, action.payload);
            delete state.corridorConfiguration.corridors[action.payload];
            state.corridorConfiguration.corridorIds =
                state.corridorConfiguration.corridorIds.filter(id => id !== action.payload);
            state.corridorConfiguration.activeCorridorSegmentMetadata = null;
        },
        moveBackwardsOnCorridorHistoryStack: state => {
            state.corridorConfiguration = {
                ...state.corridorConfiguration,
                ...state.corridorConfiguration.corridorHistoryStack[
                    state.corridorConfiguration.corridorHistoryStack.length - 2
                ],
                corridorHistoryStack: state.corridorConfiguration.corridorHistoryStack.slice(
                    0,
                    -1,
                ),
            };
            state.corridorConfiguration.activeCorridorSegmentMetadata = null;
        },
        resetCorridorConfigurationAndDeleteCorridors: state => {
            state.corridorConfiguration = {
                names: {},
                csData: [],
                routes: {},
                corridors: {},
                corridorIds: [],
                noRouteFound: false,
                sessionHash: null,
                knownSubRoutes: {},
                corridorHistoryStack: [],
                isSelectRegionAvailable: true,
                activeCorridorSegmentMetadata: null,
            };
        },
        resetCorridorConfigurationState: state => {
            const corridorId = state.corridorConfiguration.corridorIds[0];
            const {
                names,
                routes,
                corridors,
                sessionHash,
                knownSubRoutes,
                corridorHistoryStack,
                isSelectRegionAvailable,
            } = state.corridorConfiguration;

            state.corridorConfiguration = {
                names: { [corridorId]: names[corridorId] },
                routes,
                csData: [],
                corridors: {
                    [corridorId]: {
                        ...corridors[corridorId],
                        waypoints: [null, null],
                        isBiDirectional: false,
                        directions: [],
                    },
                },
                corridorIds: [corridorId],
                noRouteFound: false,
                sessionHash,
                knownSubRoutes,
                corridorHistoryStack,
                isSelectRegionAvailable,
                activeCorridorSegmentMetadata: {
                    corridorId,
                    waypointIndex: 0,
                },
            };
        },

        setActiveCorridorSegmentMetadata: (
            state,
            action: PayloadAction<
                ICorridorStudiesState["corridorConfiguration"]["activeCorridorSegmentMetadata"]
            >,
        ) => {
            state.corridorConfiguration.activeCorridorSegmentMetadata = action.payload;
        },
        inputActiveCorridorSegmentFromMap: (state, action: PayloadAction<TCSSegment>) => {
            if (!state.corridorConfiguration.activeCorridorSegmentMetadata) return;

            saveCurrentStateToCorridorHistory(state);

            const { corridorId, waypointIndex } =
                state.corridorConfiguration.activeCorridorSegmentMetadata;
            state.corridorConfiguration.corridors[corridorId].waypoints[waypointIndex] =
                action.payload;

            if (
                waypointIndex + 1 <
                    state.corridorConfiguration.corridors[corridorId].waypoints.length &&
                state.corridorConfiguration.corridors[corridorId].waypoints[waypointIndex + 1] ===
                    null
            ) {
                state.corridorConfiguration.activeCorridorSegmentMetadata = {
                    corridorId,
                    waypointIndex: waypointIndex + 1,
                };
            } else {
                state.corridorConfiguration.activeCorridorSegmentMetadata = null;
            }
        },
        deleteCorridorWaypoint: (
            state,
            action: PayloadAction<{ corridorId: TCSStateCorridor["id"]; waypointIndex: number }>,
        ) => {
            saveCurrentStateToCorridorHistory(state);

            const { corridorId, waypointIndex } = action.payload;

            if (
                0 < waypointIndex &&
                waypointIndex <
                    state.corridorConfiguration.corridors[corridorId].waypoints.length - 1
            ) {
                state.corridorConfiguration.corridors[corridorId].waypoints.splice(
                    waypointIndex,
                    1,
                );

                return;
            } else {
                state.corridorConfiguration.corridors[corridorId].waypoints[waypointIndex] = null;
                state.corridorConfiguration.activeCorridorSegmentMetadata = action.payload;
            }

            clearCorridorRouteInformationById(state, corridorId);
        },
        setKnownSubRoutes: (state, action: PayloadAction<{ subRoutes: Array<ICSSubRoute> }>) => {
            const { subRoutes } = action.payload;
            subRoutes.forEach(subRoute => {
                state.corridorConfiguration.knownSubRoutes[subRoute.id] = subRoute;
            });
        },
        setCorridorsData: (
            state,
            action: PayloadAction<{
                corridorsData: Array<{
                    id: string;
                    name: string;
                    isBiDirectional?: boolean;
                    waypoints: Array<IOrderedZone>;
                    segments: Array<IOrderedZone>;
                    directions: Array<{ code: TCSDirectionOption["code"]; enabled: boolean }>;
                }>;
            }>,
        ) => {
            const { corridorsData } = action.payload;

            corridorsData.forEach(corridorData => {
                const { name, directions, segments, waypoints, isBiDirectional } = corridorData;

                const uuid = uuidv4();
                const corridor = {
                    id: uuid,
                    directions: directions || [],
                    waypoints: waypoints || [null, null],
                    isBiDirectional: isBiDirectional || false,
                } as TCSStateCorridor;
                const corridorSegments = segments.map(segment => {
                    const { project_route_zone_geojson, ...segmentData } = segment;
                    const geometry = project_route_zone_geojson
                        ? JSON.parse(project_route_zone_geojson)
                        : {
                              coordinates: [[]],
                              type: "LineString",
                          };

                    return {
                        properties: {
                            ...segmentData,
                            zone_id: String(segmentData.zone_id),
                            point_on_surface: geometry.coordinates[0].join(","),
                        },
                        geometry,
                        type: "Feature" as const,
                    };
                });

                state.corridorConfiguration.routes[uuid] = {
                    id: uuid,
                    segments: corridorSegments,
                };
                state.corridorConfiguration.names[uuid] =
                    name ||
                    state.corridorConfiguration.names[state.corridorConfiguration.corridorIds[0]];
                state.corridorConfiguration.corridors[uuid] = corridor;
                state.corridorConfiguration.corridorIds.push(uuid);

                // state.corridorConfiguration.knownSubRoutes[subRoute.id] = subRoute;
            });

            if (state.corridorConfiguration.corridorHistoryStack.length > 0) {
                saveCurrentStateToCorridorHistory(state);
            }
        },
    },
});

export const csReducer = reducer;
