import {
    AT_FILTER_BY_OPTIONS,
    AT_VIZ_DEFAULTS,
} from "@app/viz3/atVisualization/state/atViz.constants";
import { IMapError } from "@common/components/baseMap/mapErrorAlert/mapErrorAlert";
import { THoveredZone } from "@common/components/baseMap";
import { TZoneSelectionSelectedToolId } from "@common/components/baseMap/customControls/zoneSelectionTools/zoneSelectionTools.constants";

export interface IATCensusTract {
    id: string;
    name: string;
}

export interface IATAvailableCounty {
    id: string;
    name: string;
}

export interface IATAvailableState {
    id: string;
    name: string;
}

export interface IATCustomGeography {
    id: string;
    name: string;
}

export interface IATCensusTractsFilters {
    code: typeof AT_FILTER_BY_OPTIONS.CENSUS_TRACT.code;
    values: Array<IATCensusTract>;
}

export interface IATCountyFilters {
    code: typeof AT_FILTER_BY_OPTIONS.COUNTY.code;
    values: Array<IATAvailableCounty>;
}

export interface IATStateFilters {
    code: typeof AT_FILTER_BY_OPTIONS.STATE.code;
    values: Array<IATAvailableState>;
}

export type TATSelectedFilters = IATCensusTractsFilters | IATCountyFilters | IATStateFilters;

export interface IATSummaryChartData {
    total_metric: number;
    avg_metric: number;
    time_trend: number;
}

export type TCustomSelectionGeography = { zone_id: string } & Record<string, any>;

interface ICustomSelectionMapConfigurations {
    mapError: IMapError | null;
    hoveredZone: THoveredZone | null;
    selectedToolId: TZoneSelectionSelectedToolId | null;
}

export interface ICustomSelectionConfigurations {
    map: ICustomSelectionMapConfigurations;
    selectedGeographies: Array<string>;
    isCustomSelectionMapOpened: boolean;
}

export enum EMapType {
    dotDensity = "dotDensity",
    choropleth = "choropleth",
}

export enum ECacheServiceCacheIds {
    layerGroup = "layer-group",
    dotsDensityLayerGroup = "dots-density-layer-group",
}

export type IATVizFilters = typeof AT_VIZ_DEFAULTS.filters;
