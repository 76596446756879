import { useState } from "react";
import { StlButton, StlRadio, StlRadioGroup } from "@common/components";
import { faAngleDown, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./nestedRadioGroup.less";

export const NestedRadioGroup = ({
    value,
    nestedValue,
    checked,
    expanded = false,
    disabled = false,
    label,
    onChange,
    onChangeNestedOption,
    nestedOptions,
}: {
    value: string;
    nestedValue: string;
    checked: boolean;
    expanded?: boolean;
    disabled?: boolean;
    label: JSX.Element;
    onChange: (value: string) => void;
    onChangeNestedOption: (value: string) => void;
    nestedOptions?: {
        value: string;
        label: JSX.Element;
    }[];
}) => {
    const [isExpanded, setIsExpanded] = useState(expanded);

    const onChangeParentOption = () => {
        // Expand parent option when selecting it
        if (!checked) {
            setIsExpanded(!checked);
        }
        onChange(value);
    };

    return (
        <div className="stl-nested-radio">
            <div className="radio-label">
                <StlRadio checked={checked} disabled={disabled} onChange={onChangeParentOption}>
                    {label}
                </StlRadio>
                {!!nestedOptions?.length && (
                    <StlButton
                        variant="naked"
                        onClick={() => setIsExpanded(prevValue => !prevValue)}
                        className="expand-button"
                        size="sm"
                    >
                        <FontAwesomeIcon icon={isExpanded ? faAngleDown : faAngleRight} />
                    </StlButton>
                )}
            </div>
            {isExpanded && !!nestedOptions?.length && (
                <StlRadioGroup
                    options={nestedOptions}
                    value={nestedValue}
                    disabled={disabled || !checked}
                    onChange={onChangeNestedOption}
                />
            )}
        </div>
    );
};
