import { polygon, lineString } from "@turf/helpers";
import { LineString, Polygon } from "geojson";
import area from "@turf/area";
import length from "@turf/length";

export const getArea = (coords?: Polygon["coordinates"]) => {
    if (!coords || coords[0].length < 4) return 0;

    const polygonCoords = polygon(coords);

    return area(polygonCoords);
};

export const getDistance = (coords?: LineString["coordinates"]) => {
    if (!coords || coords.length < 2) return 0;

    const lineCoords = lineString(coords);

    return length(lineCoords, { units: "meters" });
};
