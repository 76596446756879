import { createSelector } from "reselect";
import type { TRootState } from "@app/store";
import { getIsSandbox } from "@app/store/currentUser/currentUser.selector";
import { MAX_SANDBOX_ZONE_COUNT } from "@app/store/staticData/state/staticData.constants";
import { TAnalysisZonesLimits } from "@app/store/staticData/state/staticData.types";

export const getStaticData = (state: TRootState) => state.staticData;

export const getAvailableDataPeriods = (state: TRootState) =>
    getStaticData(state).availableDataPeriods;

export const getSATCAvailableDataPeriods = (state: TRootState) =>
    getStaticData(state).satcAvailableDataPeriods;

export const getZoneVintageDates = (state: TRootState) => getStaticData(state).zoneVintageDates;

export const getOSMLayersVintageDate = (state: TRootState) =>
    getStaticData(state).osmLayersVintageDate;

export const getDirectAnalysesZonesLimits = (state: TRootState) =>
    getStaticData(state).analysesZonesLimits;

export const getNarrowFiltersData = (state: TRootState) => getStaticData(state).narrowFiltersData;

export const getAnalysesZonesLimits = createSelector(
    getIsSandbox,
    getDirectAnalysesZonesLimits,
    (isSandbox, analysesZonesLimits) => {
        if (!isSandbox || !analysesZonesLimits) return analysesZonesLimits;

        const sandboxLimits = {} as TAnalysisZonesLimits;
        Object.entries(analysesZonesLimits.softLimits).forEach(([analysisName, limit]) => {
            sandboxLimits[analysisName as keyof TAnalysisZonesLimits] = Math.min(
                limit,
                MAX_SANDBOX_ZONE_COUNT,
            );
        });
        return {
            ...analysesZonesLimits,
            softLimits: sandboxLimits,
        };
    },
);
