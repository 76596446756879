import { ReactNode } from "react";
import classnames from "classnames";
import { StlButton } from "@common/components/button";
import { StlLightbox } from "@common/components/lightbox";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import "./confirmationDialogModal.less";

type TProps = Omit<DialogProps, "open"> & {
    show: boolean;
    onOK?: () => void;
    onHide: () => void;
    onCancel?: () => void;
    okText?: ReactNode;
    footer?: ReactNode;
    content?: ReactNode;
    closeText?: ReactNode;
    cancelText?: ReactNode;
    titleContent?: ReactNode;
    hideCancelButton?: boolean;
    hideCloseButton?: boolean;
};

export const ConfirmationDialog = ({
    okText = "OK",
    cancelText = "Cancel",
    onOK = () => {},
    onCancel = () => {},
    onHide,
    content,
    className = "",
    hideCancelButton = false,
    hideCloseButton = true,
    ...restProps
}: TProps) => {
    const confirm = () => {
        onOK();
        onHide();
    };

    const decline = () => {
        onCancel();
        onHide();
    };

    return (
        <StlLightbox
            className={classnames("stl-confirmation-dialog-modal", className)}
            onHide={onHide}
            onCancel={onCancel}
            hideCloseButton={hideCloseButton}
            body={content}
            centered
            footer={
                <>
                    {!hideCancelButton && (
                        <StlButton variant="secondary" size="sm" onClick={decline}>
                            {cancelText}
                        </StlButton>
                    )}
                    <StlButton variant="primary" testid="confirmBtn" size="sm" onClick={confirm}>
                        {okText}
                    </StlButton>
                </>
            }
            {...restProps}
        />
    );
};
