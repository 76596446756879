import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { IZoneSetAPI } from "@common/services/server/zonesApi.types";
import { ZONE_SETS_INITIAL_STATE } from "./zoneSets.state";

export const { actions, reducer } = createSlice({
    name: "zoneSetsReducer",
    initialState: ZONE_SETS_INITIAL_STATE,
    reducers: {
        updateZoneSets: (state, action: PayloadAction<Array<IZoneSetAPI>>) => {
            state.zoneSets = action.payload;
        },
    },
});

export const zoneSetsReducer = reducer;
