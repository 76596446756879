import { MAP_STYLES } from "@app/viz3/trzaVisualization/map/mapStyles.constants";
import { TRZA_LAYERS } from "@app/viz3/trzaVisualization/state/trzaViz.constants";

export const TRZA_VIZ_INITIAL_STATE = {
    map: {
        clickedZone: null,
        layerConfigurations: {
            [TRZA_LAYERS.ZONE.code]: {
                colors: {
                    high: MAP_STYLES.FILTER_ZONES.COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES.FILTER_ZONES.COLOR.LOW,
                    hover: MAP_STYLES.FILTER_ZONES.COLOR.HOVER,
                },
                opacity: MAP_STYLES.FILTER_ZONES.OPACITY,
            },
            [TRZA_LAYERS.ROUTE_SEGMENTS.code]: {
                colors: {
                    high: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.LOW,
                    hover: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HOVER,
                },
                opacity: MAP_STYLES.DISTRIBUTION_ZONES.OPACITY,
            },
        },
        staticGeometries: {
            line: null,
            point: null,
        },
    },
    distributionLayers: {
        line: null,
        point: null,
    },
    preSelectedZones: [],
    segmentsForComparison: [],
};
