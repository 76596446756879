import { merge } from "lodash-es";
import { VIZ_CODES } from "@app/viz3/base/visualizationsConfiguration";
import { BASE_VIZ_ACTIONS } from "@app/viz3/base/state/baseViz.actionTypes";
import { NP_SPOT_VIZ_ACTIONS } from "./npSpotViz.actionTypes";
import { NP_SPOT_VIZ_INITIAL_STATE } from "./npSpotViz.state";

export const npSpotVizReducer = (state = NP_SPOT_VIZ_INITIAL_STATE, { data, type }) => {
    switch (type) {
        case BASE_VIZ_ACTIONS.RESET_VIZ_STATE: {
            return NP_SPOT_VIZ_INITIAL_STATE;
        }

        case BASE_VIZ_ACTIONS.SET_ANALYSIS_COMMON_CONFIG: {
            let newLayerConfig;
            if (data.vizCode === VIZ_CODES.NP_SPOT && data.layerConfigurations) {
                newLayerConfig = merge(
                    {},
                    { ...NP_SPOT_VIZ_INITIAL_STATE.map.layerConfigurations },
                    { ...data.layerConfigurations },
                );
            } else {
                newLayerConfig = NP_SPOT_VIZ_INITIAL_STATE.map.layerConfigurations;
            }

            return {
                ...state,
                map: {
                    ...state.map,
                    layerConfigurations: {
                        ...state.map.layerConfigurations,
                        ...newLayerConfig,
                    },
                },
            };
        }

        case NP_SPOT_VIZ_ACTIONS.SET_CLICKED_ZONE_ID: {
            return {
                ...state,
                map: {
                    ...state.map,
                    clickedZoneId: data.zoneId,
                },
            };
        }

        case NP_SPOT_VIZ_ACTIONS.SET_PRE_SELECTED_ZONE: {
            return {
                ...state,
                preSelectedZones: data,
            };
        }

        case NP_SPOT_VIZ_ACTIONS.SET_CUSTOM_GATE_LAYERS: {
            return {
                ...state,
                customGateLayers: data,
            };
        }

        case NP_SPOT_VIZ_ACTIONS.SET_LAYER_CONFIGURATION: {
            return {
                ...state,
                map: {
                    ...state.map,
                    layerConfigurations: {
                        ...state.map.layerConfigurations,
                        ...data,
                    },
                },
            };
        }

        case NP_SPOT_VIZ_ACTIONS.SET_VIZ_STATE: {
            return {
                ...state,
                ...data.state,
            };
        }

        default:
            return state;
    }
};
