export const DIRECTION_OPTIONS = {
    NORTH: {
        id: "northbound",
        code: "NORTH",
        label: "Northbound",
    },
    EAST: {
        id: "eastbound",
        code: "EAST",
        label: "Eastbound",
    },
    SOUTH: {
        id: "southbound",
        code: "SOUTH",
        label: "Southbound",
    },
    WEST: {
        id: "westbound",
        code: "WEST",
        label: "Westbound",
    },
} as const;

export const DIRECTION_OPTIONS_LIST = Object.values(DIRECTION_OPTIONS);
export type TDirectionOption = typeof DIRECTION_OPTIONS[keyof typeof DIRECTION_OPTIONS];
