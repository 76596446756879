import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";
import { StlNotification } from "@common/components/snackbar";
import { SUPPORT_CONTACT_EMAIL } from "@common/constants/application.constants";
import { IHttpError } from "./http.interceptors";

export const UNEXPECTED_HTTP_ERROR = (
    <>
        <p>An unexpected error occurred and our engineering team has been notified.</p>
        <p>
            Please contact StreetLight Data at
            <a href={`mailto:${SUPPORT_CONTACT_EMAIL}`} className="stl-link">
                {SUPPORT_CONTACT_EMAIL}
            </a>{" "}
            if you continue to have issues or concerns.
        </p>
    </>
);

export interface IRequestConfig<TParams = Record<string, any>> extends AxiosRequestConfig {
    cached?: boolean;
    params?: TParams;
    user_project_configuration_name?: string;
}

/*
 * Custom "paramsSerializer" is used for flask views
 * https://github.com/axios/axios/issues/604#issuecomment-321460450
 **/
class _HttpService {
    BASE_URL = "/server";
    DEFAULT_ERROR_TIMEOUT = 5000;
    cachedRequests: Record<string, any> = {};
    CancelToken = axios.CancelToken;
    isCancel = axios.isCancel;

    constructor() {
        window.addEventListener("unhandledrejection", this.processUnhandledRejection.bind(this));
    }

    _makeRequestCacheKey(url: string, data: any) {
        return `${url}?=${this._paramsSerializer(data)}`;
    }

    _cacheRequest(url: string, res: any) {
        this.cachedRequests[url] = res;
    }

    _paramsSerializer(params: any) {
        return qs.stringify(params, { arrayFormat: "repeat" });
    }

    get<T = any>(url: string, config: IRequestConfig = {}): Promise<T> {
        const { cached } = config;
        if (cached) {
            const cacheKey = this._makeRequestCacheKey(url, config.params);

            if (this.cachedRequests[cacheKey]) {
                return Promise.resolve(this.cachedRequests[cacheKey]);
            }
        }

        return axios
            .get<unknown, T>(`${this.BASE_URL}${url}`, {
                paramsSerializer: this._paramsSerializer,
                ...config,
            })
            .then(res => {
                if (cached) this._cacheRequest(this._makeRequestCacheKey(url, config.params), res);

                return res;
            });
    }

    post<T>(url: string, data?: any, config: IRequestConfig = {}): Promise<T> {
        const { cached } = config;
        if (cached) {
            const cacheKey = this._makeRequestCacheKey(url, data);

            if (this.cachedRequests[cacheKey]) {
                return Promise.resolve(this.cachedRequests[cacheKey]);
            }
        }

        return axios
            .post<unknown, T>(`${this.BASE_URL}${url}`, data, {
                paramsSerializer: this._paramsSerializer,
                ...config,
            })
            .then(res => {
                if (cached) this._cacheRequest(this._makeRequestCacheKey(url, data), res);

                return res;
            });
    }

    put<T>(url: string, data?: any, config: IRequestConfig = {}): Promise<T> {
        return axios.put<unknown, T>(`${this.BASE_URL}${url}`, data, {
            paramsSerializer: this._paramsSerializer,
            ...config,
        });
    }

    delete<T>(url: string, config: IRequestConfig = {}) {
        return axios.delete<unknown, T>(`${this.BASE_URL}${url}`, {
            paramsSerializer: this._paramsSerializer,
            ...config,
        });
    }

    showIfError(defaultMessage: string) {
        return (error: IHttpError) => {
            error.showIfError = defaultMessage;

            return Promise.reject(error);
        };
    }

    silentError = (): void => {};

    showIfSuccess<T>(message: string) {
        return (response: T): T => {
            StlNotification.success(message);
            return response;
        };
    }

    processUnhandledRejection(event: PromiseRejectionEvent): void {
        const error = event.reason;

        // Error was already handled, so prevent it from throwing into console
        if (error.handled) {
            event.preventDefault();
            return;
        }

        // Don't handle since it's not http error, throw to console, so that it could be tracked
        // by TrackJS as a potential bug
        if (!error.isHttpError) return;

        this.showErrorMessage(error);

        event.preventDefault();
    }

    showErrorMessage(error: any) {
        const errorMessage = error?.response?.data?.message || error.showIfError;
        const hasErrorMessage = !!error.showIfError;
        const hideTimeout = hasErrorMessage ? this.DEFAULT_ERROR_TIMEOUT : 0;

        // No one handled http error, try to show default error message defined at API service,
        // otherwise show unexpected http error message
        StlNotification.error(errorMessage || UNEXPECTED_HTTP_ERROR, { hideTimeout });
    }
}

export const HttpService = new _HttpService();
