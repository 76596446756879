export const isSSOFlow = () => window.location.href.includes("sso_auth");

export const getSSOAuth = () =>
    window.location.search.split("&").find(queryParam => {
        const [key] = queryParam.split("=");

        return key.includes("sso_auth");
    });

const getSkilljarNextUrl = (url: string) => {
    const nextUrl = url.substring(url.indexOf("?next="));

    return nextUrl;
};

const getSalesforceRedirectUrl = (url: string) => {
    const nextUrl = url.substring(url.indexOf("?redirect_uri="));

    return nextUrl;
};

const getZendeskSSOReturnToQuery = (url: string) => {
    const pattern = /(?<=return_to=)([^&]+)/;
    const matches = url.match(pattern);

    return matches ? `?return_to=${matches[1]}` : "";
};

export const getServerRedirectUrl = (search: string) => {
    const _search = search || window.location.search || "";

    if (_search.includes("sso_auth=skilljar")) {
        return `/server/skilljar_auth${getSkilljarNextUrl(_search)}`;
    }

    if (_search.includes("sso_auth=zendesk")) {
        return `/server/zendesk-sso${getZendeskSSOReturnToQuery(_search)}`;
    }

    if (_search.includes("sso_auth=salesforce")) {
        return `/server/oauth/salesforce/authorize${getSalesforceRedirectUrl(_search)}`;
    }

    return "";
};

export const getPathWithSearchRedirect = (mainPath: string, search: string) => {
    return `${mainPath}${search ? `/${search}` : ""}`;
};
