import { useCallback, useEffect } from "react";
import type { EventData, Layer, Map } from "mapbox-gl";
import { getTopMostFeature } from "@app/viz3/base/state/baseViz.helpers";

type TListener = {
    layerId: string;
    listener: (event: EventData) => void;
};

type TOnClickHandler = (event: EventData, id: string, mapInstance: Map) => void;

export const useClick = (
    map: Map | null,
    {
        layers = [],
        onClick,
        onDoubleClick,
        disableListeners,
    }: {
        layers: Array<Layer>;
        onClick: TOnClickHandler;
        onDoubleClick?: TOnClickHandler;
        disableListeners?: boolean;
    },
) => {
    const onLayerClick = useCallback(
        (e: EventData, layer: Layer, mapInstance: Map, handler: TOnClickHandler) => {
            const foundFeature = getTopMostFeature(e, mapInstance, layers);

            if (foundFeature && foundFeature.layer.id === layer.id) {
                handler(e, layer.id, mapInstance);
            }
        },
        [layers],
    );

    useEffect(() => {
        if (!map || disableListeners) return undefined;

        const doubleClickZoomListener = () => {
            if (map.doubleClickZoom.isEnabled()) {
                map.doubleClickZoom.disable();
            }
        };

        if (onDoubleClick) {
            map.doubleClickZoom.disable();

            // This ensures doubleClickZoom won't be re-enabled after MapboxDraw is added to the map.
            map.on("idle", doubleClickZoomListener);
        }

        const listenersList = layers.reduce((listeners: Array<TListener>, layer: Layer) => {
            const clickListener = (e: EventData) => onLayerClick(e, layer, map, onClick);

            listeners.push({ layerId: layer.id, listener: clickListener });

            map.on("click", layer.id, clickListener);

            if (onDoubleClick) {
                const doubleClickListener = (e: EventData) =>
                    onLayerClick(e, layer, map, onDoubleClick);

                listeners.push({ layerId: layer.id, listener: doubleClickListener });

                map.on("dblclick", layer.id, doubleClickListener);
            }

            return listeners;
        }, []);

        return () => {
            listenersList.forEach(({ layerId, listener }) => {
                map.off("click", layerId, listener);
            });

            if (onDoubleClick) {
                map.off("idle", doubleClickZoomListener);
            }
        };
    }, [map, disableListeners, layers, onLayerClick, onDoubleClick, onClick]);
};
