import { lazy } from "react";
import { ROUTES } from "@common/constants/routes.constants";

const Layout = lazy(
    () => import("@app/safetySolutionDashboard/layout/safetySolutionDashboardLayout"),
);

export const routes = [
    {
        id: "safetySolutionDashboardLayout",
        path: `${ROUTES.SAFETY}`,
        component: Layout,
        optionText: "Safety",
        title: "Safety",
    },
];
