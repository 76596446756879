import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    IUiStates,
    IZoneSetModalInitialState,
    IZoneSetModalMap,
    ZONE_SET_MODAL_INITIAL_STATE,
} from "./zoneSetModal.state";

export const { actions, reducer } = createSlice({
    name: "zoneSetModal",
    initialState: ZONE_SET_MODAL_INITIAL_STATE,
    reducers: {
        setZoneSetModalUiState: (state, action: PayloadAction<Partial<IUiStates>>) => {
            state.uiStates = {
                ...state.uiStates,
                ...action.payload,
            };
        },
        setIsZoneSetModalOpen: (state, action: PayloadAction<IUiStates["isZoneSetModalOpen"]>) => {
            state.uiStates.isZoneSetModalOpen = action.payload;
        },
        setScreenMode: (state, action: PayloadAction<IUiStates["screenMode"]>) => {
            state.uiStates.screenMode = action.payload;
        },
        setIsUploadZoneSetModalOpen: (
            state,
            action: PayloadAction<IUiStates["isUploadZoneSetModalOpen"]>,
        ) => {
            state.uiStates.isUploadZoneSetModalOpen = action.payload;
        },
        setViewMode: (state, action: PayloadAction<IUiStates["viewMode"]>) => {
            state.uiStates.viewMode = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<IUiStates["isLoading"]>) => {
            state.uiStates.isLoading = action.payload;
        },
        setIsLinkedZoneSetsModalOpen: (
            state,
            action: PayloadAction<IUiStates["isLinkedZoneSetsModalOpen"]>,
        ) => {
            state.uiStates.isLinkedZoneSetsModalOpen = action.payload;
        },
        setSelectedZoneSetId: (state, action: PayloadAction<number>) => {
            state.selectedZoneSetId = action.payload;
        },
        setSelectedZoneIds: (state, action: PayloadAction<Array<number>>) => {
            state.selectedZoneIds = action.payload;
        },
        updateZoneSet: (state, action: PayloadAction<IZoneSetModalInitialState["zoneSet"]>) => {
            state.zoneSet = {
                ...state.zoneSet,
                ...action.payload,
            };
        },
        setZoneSetName: (
            state,
            action: PayloadAction<Partial<IZoneSetModalInitialState["zoneSetName"]>>,
        ) => {
            state.zoneSetName = {
                ...state.zoneSetName,
                ...action.payload,
            };
        },
        setMapError: (state, action: PayloadAction<IZoneSetModalMap["mapError"]>) => {
            state.map.mapError = action.payload;
        },
        setHoveredZone: (state, action: PayloadAction<IZoneSetModalMap["hoveredZone"]>) => {
            state.map.hoveredZone = action.payload;
        },
        setHoveredBusZone: (state, action: PayloadAction<IZoneSetModalMap["hoveredBusZones"]>) => {
            state.map.hoveredBusZones = action.payload;
        },
        resetZoneSetModalState: () => {
            return ZONE_SET_MODAL_INITIAL_STATE;
        },
    },
});

export const zoneSetModalReducer = reducer;
