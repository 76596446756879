import {
    IProjectFolder,
    ISelectedProjectFolder,
    IProjectFolderFilters,
} from "@common/services/server/projectFolderApi.types";
import { MAP_TYPE_OPTIONS } from "@app/bigBang/projects/state/projects.constants";
import { IMapError } from "@common/components/baseMap/mapErrorAlert/mapErrorAlert";
import { TZonesManagerZone } from "@common/features/zonesManager/zonesManager.types";

export const PROJECT_FOLDER_FILTERS_INITIAL_STATE = {
    metricsPackages: [] as IProjectFolderFilters["metricsPackages"],
    mapType: MAP_TYPE_OPTIONS.zoneSets.id as IProjectFolderFilters["mapType"],
    zoneSet: null as IProjectFolderFilters["zoneSet"],
    analysis: null as IProjectFolderFilters["analysis"],
};

export const PROJECTS_INITIAL_STATE = {
    projectFolders: null as IProjectFolder[] | null,
    selectedProjectFolder: null as ISelectedProjectFolder | null,
    selectedProjectFolderFilters: PROJECT_FOLDER_FILTERS_INITIAL_STATE,
    uiStates: {
        isLoading: false,
        mapError: null as IMapError | null,
        hoveredZone: null as TZonesManagerZone | null,
        shouldFilterByPackages: true,
    },
};
