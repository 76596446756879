import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMapEnhancedLayersState, MAP_ENHANCED_LAYERS_STATE } from "./mapEnhancedLayers.state";

export const { actions, reducer } = createSlice({
    name: "mapEnhancedLayers",
    initialState: MAP_ENHANCED_LAYERS_STATE,
    reducers: {
        setHoveredEnhancedLayerZone: (
            state,
            action: PayloadAction<IMapEnhancedLayersState["hoveredEnhancedLayerZone"]>,
        ) => {
            state.hoveredEnhancedLayerZone = action.payload;
        },
        setSelectedEnhancedLayerZone: (
            state,
            action: PayloadAction<IMapEnhancedLayersState["selectedEnhancedLayerZone"]>,
        ) => {
            state.selectedEnhancedLayerZone = action.payload;
        },
        setSelectedVizEnhancedLayer: (
            state,
            action: PayloadAction<IMapEnhancedLayersState["selectedVizEnhancedLayer"]>,
        ) => {
            state.selectedVizEnhancedLayer = action.payload;
        },
        setHoveredLegendSwatch: (
            state,
            action: PayloadAction<IMapEnhancedLayersState["hoveredLegendSwatch"]>,
        ) => {
            state.hoveredLegendSwatch = action.payload;
        },
        resetMapEnhancedLayersState: () => {
            return MAP_ENHANCED_LAYERS_STATE;
        },
    },
});

export const mapEnhancedLayersReducer = reducer;
