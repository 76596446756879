import { useState, useRef } from "react";
import { useMounted } from "@common/utils/useMounted";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { UserApiService } from "@common/services/server/userApi.service";
import {
    StlButton,
    StlInput,
    StlCheckbox,
    StlCheckboxGroup,
    StlNotification,
    StlCard,
} from "@common/components";
import { getApplicationPreferences } from "@app/store/userPreferences/userPreferences.selector";
import {
    setAutoApplyFilter,
    setAutoDismissMessages,
    setAutoRefresh,
    setVectorMapSettings,
    setEmailNotificationSettings,
} from "@app/store/userPreferences/userPreferences.actions";
import "./accountSettingsTab.less";

const EMAIL_NOTIFICATION_SETTINGS = [
    {
        label: "Notify me via email when my analysis result is ready to be viewed",
        value: "enableNotification",
    },
];

const VECTOR_MAP_SETTINGS = [
    {
        label: "Enable vector maps anti-aliasing (may cause slower performance on some hardware)",
        value: "enableAntialiasing",
    },
];

const defaultSettings = {
    old_password: "",
    password1: "",
    password2: "",
};

export const SettingsTab = () => {
    const [passwords, setPasswords] = useState(defaultSettings);

    const {
        autoRefreshEnabled,
        autoApplyFilter,
        autoDismissMessages,
        vectorMapSettings,
        emailNotificationSettings,
    } = useAppSelector(getApplicationPreferences);

    const isMounted = useMounted();

    const dispatch = useAppDispatch();

    const input = useRef<HTMLInputElement | null>(null);

    const onSubmitPassword = () => {
        if (passwords.password1 !== passwords.password2) {
            StlNotification.error("New passwords do not match.");

            return;
        }
        UserApiService.changePassword(passwords)
            .then(() => {
                StlNotification.success("Password has been successfully changed.");
            })
            .catch(error => {
                if (isMounted.current) {
                    setPasswords(defaultSettings);
                }
                input.current?.focus();

                return Promise.reject(error);
            });
    };

    const onChangePassword = (stateName: string, value: string) => {
        setPasswords(prevState => ({ ...prevState, [stateName]: value }));
    };

    return (
        <div className="stl-account-settings-tab">
            <div className="account-layout">
                <div className="account-half-card">
                    <StlCard title="Change Password">
                        <div className="text-wrapper">
                            <p>
                                Passwords must be 8 or more characters, have at least one capital
                                letter, and one number/special character.
                            </p>
                        </div>
                        <StlInput
                            label="Old Password"
                            type="password"
                            id="oldPassword"
                            placeholder="Enter your old password"
                            value={passwords.old_password}
                            onChange={value => {
                                onChangePassword("old_password", value);
                            }}
                            ref={input}
                        />
                        <StlInput
                            label="New Password"
                            type="password"
                            id="newPassword"
                            placeholder="Enter new password"
                            value={passwords.password1}
                            onChange={value => {
                                onChangePassword("password1", value);
                            }}
                        />
                        <StlInput
                            label="Confirm New Password"
                            type="password"
                            id="confirmPassword"
                            placeholder="Enter new password"
                            value={passwords.password2}
                            onChange={value => {
                                onChangePassword("password2", value);
                            }}
                        />
                        <div className="btn-wrapper">
                            <StlButton
                                variant="primary"
                                size="md"
                                onClick={() => {
                                    onSubmitPassword();
                                }}
                                disabled={
                                    !passwords.old_password ||
                                    !passwords.password1 ||
                                    !passwords.password2
                                }
                            >
                                Save
                            </StlButton>
                        </div>
                    </StlCard>
                </div>
                <div className="account-half-card">
                    <StlCard title="General Settings">
                        <StlCheckbox
                            checked={autoRefreshEnabled}
                            onChange={checked => dispatch(setAutoRefresh(checked))}
                        >
                            Enable Auto-refresh for content in the application
                        </StlCheckbox>
                        <StlCheckbox
                            className="general-settings-checkbox"
                            checked={autoApplyFilter}
                            onChange={checked => dispatch(setAutoApplyFilter(checked))}
                        >
                            Filter content automatically when filters are typed or selected
                        </StlCheckbox>
                        <StlCheckbox
                            className="general-settings-checkbox"
                            checked={autoDismissMessages}
                            onChange={checked => dispatch(setAutoDismissMessages(checked))}
                        >
                            Automatically dismiss success, warning, and error messages after a few
                            seconds
                        </StlCheckbox>
                    </StlCard>
                    <StlCard title="Email Notification Settings">
                        <StlCheckboxGroup
                            options={EMAIL_NOTIFICATION_SETTINGS}
                            value={emailNotificationSettings}
                            onChange={value => dispatch(setEmailNotificationSettings(value))}
                        />
                    </StlCard>
                    <StlCard title="Vector Map Settings">
                        <StlCheckboxGroup
                            options={VECTOR_MAP_SETTINGS}
                            value={vectorMapSettings}
                            onChange={value => dispatch(setVectorMapSettings(value))}
                        />
                    </StlCard>
                </div>
            </div>
        </div>
    );
};
