import { getAvailableOSMLayers } from "@app/store/currentUser/currentUser.selector";
import { MODES_OF_TRAVEL } from "@common/constants/analysis.constants";
import { OSM_ZONE_KINDS, ZONE_KINDS } from "@common/constants/zoneLibrary.constants";
import { isEqual } from "lodash-es";
import { createSelector } from "reselect";

import {
    MIN_INTERACTIVE_ZOOM,
    OSM_SEGMENT_SPLIT_TYPES,
    ROAD_TYPES,
    ZONE_TYPES,
} from "./zonesManager.constants";
import {
    getRoadIdsFromRoads,
    getSelectedKindsList,
    getTransitOptionIds,
} from "./zonesManager.helpers";

export const getZonesManagerState = state => state.zonesManager;

export const getZoneLibraryType = state => getZonesManagerState(state).zoneLibraryType;
export const getZoneLibraryFilters = state => getZonesManagerState(state).zoneLibraryFilters;
export const getOSMSegmentsFilters = state =>
    getZoneLibraryFilters(state)[ZONE_TYPES.OSM_LINE_SEGMENTS.id];
export const getZoneVintage = state => getZonesManagerState(state).zoneVintage;
export const getSelectedZone = state => getZonesManagerState(state).selectedZone;
export const getClickedBusZones = state => getZonesManagerState(state).clickedBusZones;
export const getZoneRole = state => getZonesManagerState(state).zoneRole;
export const getUiStates = state => getZonesManagerState(state).uiStates;
export const getReuseZonesConfig = state => getZonesManagerState(state).reuseZonesConfig;
export const getReuseZonesModalState = state => getZonesManagerState(state).reuseZonesModal;
export const getUploadZonesModalState = state => getZonesManagerState(state).uploadZonesModal;
export const getSpotCounterMapLayers = state => getZonesManagerState(state).spotCounterMapLayers;
export const getSpotCounterOsmLayersCategories = state =>
    getZonesManagerState(state).spotCounterMapLayers.osmLayersCategories;

export const getSelectedZoneKind = createSelector(getZoneLibraryType, zoneType => {
    switch (zoneType?.id) {
        case ZONE_TYPES.OSM_LINE_SEGMENTS.id: {
            const selectedZoneKind =
                zoneType.filters?.segmentSplitType === OSM_SEGMENT_SPLIT_TYPES.TERTIARY.id
                    ? OSM_ZONE_KINDS.OSM_TERTIARY
                    : OSM_ZONE_KINDS.OSM;
            return {
                id: ZONE_KINDS.OSM.id,
                filters: [],
                selectedKindsList: [selectedZoneKind.id],
            };
        }
        case ZONE_TYPES.STANDARD_AREAS.id: {
            const zoneKind = ZONE_TYPES.STANDARD_AREAS.zoneKinds.find(type =>
                isEqual(type.id, zoneType.filters.selectedStandardArea),
            );
            return {
                id: zoneKind.id,
                filters: [],
            };
        }
        case ZONE_TYPES.USER.id: {
            return {
                id: ZONE_KINDS.USER.id,
                filters: [],
            };
        }
        case ZONE_TYPES.TRANSIT.id: {
            if (zoneType.filters.selectedTransitZoneKind === ZONE_KINDS.BUS.code) {
                const busFilters = zoneType.filters[ZONE_KINDS.BUS.code];
                return {
                    id: ZONE_KINDS.BUS.id,
                    filters: {
                        bus_agencies: getTransitOptionIds(busFilters.selectedAgencies),
                        bus_lines: getTransitOptionIds(busFilters.selectedLines),
                    },
                    selectedKindsList: getSelectedKindsList(busFilters.selectedKinds),
                };
            }

            const railFilters = zoneType.filters[ZONE_KINDS.RAIL.code];
            return {
                id: ZONE_KINDS.RAIL.id,
                filters: {
                    rail_agencies: getTransitOptionIds(railFilters.selectedAgencies),
                    rail_lines: getTransitOptionIds(railFilters.selectedLines),
                },
                selectedKindsList: getSelectedKindsList(railFilters.selectedKinds),
            };
        }
        default:
            return null;
    }
});

export const getTravelModeCode = createSelector(
    getZoneLibraryType,
    getOSMSegmentsFilters,
    (zoneType, { networkType }) => {
        if (zoneType?.id === ZONE_TYPES.OSM_LINE_SEGMENTS.id) {
            return networkType.travelModeCode;
        }

        return MODES_OF_TRAVEL.ALL_VEHICLES.code;
    },
);

export const getMinInteractiveZoomForZoneKind = createSelector(getSelectedZoneKind, zoneKind => {
    if (zoneKind?.id === ZONE_KINDS.OSM.id) {
        return MIN_INTERACTIVE_ZOOM.OSM;
    }
    if (zoneKind?.id === ZONE_KINDS.RAIL.id) {
        return MIN_INTERACTIVE_ZOOM.RAIL;
    }
    if (zoneKind?.id === ZONE_KINDS.BUS.id) {
        return MIN_INTERACTIVE_ZOOM.BUS;
    }
    if (zoneKind?.id === ZONE_KINDS.COUNTY_US_2020.id) {
        return MIN_INTERACTIVE_ZOOM.COUNTY_US_2020;
    }
    return MIN_INTERACTIVE_ZOOM.DEFAULT;
});

export const getRoadIds = createSelector(getOSMSegmentsFilters, ({ roads }) =>
    getRoadIdsFromRoads(roads),
);

export const getSpotCounterSelectedOsmLayersCategories = createSelector(
    getSpotCounterOsmLayersCategories,
    getAvailableOSMLayers,
    (osmLayersCategories, availableLayers) => {
        return availableLayers.reduce((res, layer) => {
            const osmLayersCategoryChildren = osmLayersCategories[layer.code];

            if (osmLayersCategoryChildren?.length) {
                const filteredOsmLayersCategoryChildren = osmLayersCategoryChildren.filter(
                    childOsmData => {
                        return childOsmData !== ROAD_TYPES.SERVICE.id;
                    },
                );

                res.push({
                    ...layer,
                    children: filteredOsmLayersCategoryChildren,
                });
            }

            return res;
        }, []);
    },
);
