import { COLORS } from "@common/constants/color.constants";

export const ZOOM = {
    DEFAULT: 4,
    MIN_INTERACTIVE: 9,
    MIN_INTERACTIVE_COUNTY: 7,
    MIN_INTERACTIVE_SEGMENT: 10,
    MIN_INTERACTIVE_STATE: 3,
    MAX_REGION_BOUNDARY_ZOOM: 6.99,
    MIN: 0,
    MAX: 25,
};

export const MAP_STYLES = {
    LINE_WIDTH: 6,
    GEOM_Z_INDEX: 2,
    OUTLINE_WIDTH: 3,
    OUTLINE_Z_INDEX: 1,
    SELECTED_LINE_WIDTH: 10,
    HOVER_LINE_WIDTH: 10,
    SELECTED_GEOM_Z_INDEX: 8,
    OPACITY: {
        ACTIVE: 0.8,
        HOVER: 0.2,
        INACTIVE: 0,
    },
    GEOGRAPHIES: {
        COLORS: {
            SELECTED: COLORS.YELLOW,
            OUTLINE: COLORS.LIGHT_GREY,
            UNSELECTED: COLORS.TRANSPARENT,
            HOVER: COLORS.WHITE,
        },
    },
    SEGMENTS: {
        COLORS: {
            UNSELECTED: COLORS.LIGHT_GREY,
            SELECTED: COLORS.BLUE,
            HOVER: COLORS.WHITE,
        },
    },
    DARK_THEME: {
        ZONE_COLORS: {
            SELECTED: "#ECC66D",
            UNSELECTED: COLORS.LIGHT_GREY,
            OUTLINE_HOVER: COLORS.WHITE,
        },
    },
    LIGHT_THEME: {
        ZONE_COLORS: {
            SELECTED: "#2571C8",
            UNSELECTED: COLORS.LIGHT_GREY,
            OUTLINE_HOVER: "#262626",
        },
    },
};

export const WINDSHAFT_LAYER_TYPES = {
    LINE: "all_geometries_line",
    POLYGON: "all_geometries_polygon",
    POINT: "all_geometries_point",
};
