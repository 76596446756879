import { Expression } from "mapbox-gl";
import {
    ENHANCED_LAYER_MAX_OPACITY,
    ENHANCED_LAYER_MIN_OPACITY,
    ERiskLevels,
    FEMA_SUB_LAYERS,
    FEMA_SUB_LAYERS_LIST,
} from "./mapEnhancedLayers.constants";

const createNRILayerConfig = (layerCode: string) => ({
    color: "#F52441",
    filter: [
        "any",
        ["==", ["get", layerCode], ERiskLevels.VERY_HIGH],
        ["==", ["get", layerCode], ERiskLevels.RELATIVELY_HIGH],
        ["==", ["get", layerCode], ERiskLevels.RELATIVELY_MODERATE],
        ["==", ["get", layerCode], ERiskLevels.RELATIVELY_LOW],
        ["==", ["get", layerCode], ERiskLevels.VERY_LOW],
    ] as Expression,
    fillOpacity: [
        "case",
        ["==", ["get", layerCode], ERiskLevels.VERY_HIGH],
        0.9,
        ["==", ["get", layerCode], ERiskLevels.RELATIVELY_HIGH],
        0.7,
        ["==", ["get", layerCode], ERiskLevels.RELATIVELY_MODERATE],
        0.5,
        ["==", ["get", layerCode], ERiskLevels.RELATIVELY_LOW],
        0.3,
        ["==", ["get", layerCode], ERiskLevels.VERY_LOW],
        0.2,
        1,
    ] as Expression,
});

const createRangeExpression = (layerCode: string, min: number, max: number): Expression => [
    "all",
    [">=", ["get", layerCode], min],
    ["<", ["get", layerCode], max],
];

const createNHLLayerConfig = (layerCode: string, ranges: number[]) => {
    const step = (ENHANCED_LAYER_MAX_OPACITY - ENHANCED_LAYER_MIN_OPACITY) / (ranges.length + 1);

    const fillOpacity: Expression = ranges.reduce(
        (acc, range, index) => {
            const start = index === 0 ? 0 : ranges[index - 1];
            const opacity = ENHANCED_LAYER_MIN_OPACITY + step * (index + 1);
            acc.push(createRangeExpression(layerCode, start, range), opacity);
            return acc;
        },
        ["case"] as Expression,
    );

    fillOpacity.push(
        ["all", [">", ["get", layerCode], ranges[ranges.length - 1]]],
        ENHANCED_LAYER_MAX_OPACITY,
        1,
    );

    return {
        color: "#1073E9",
        filter: ["any", ["all", [">=", ["get", layerCode], 0]]] as Expression,
        fillOpacity,
    };
};

export const getFEMALayerExpression = (layerCode: string, riskLevel: string) => {
    const femaLayer = FEMA_SUB_LAYERS_LIST.find(item => item.visField === layerCode);

    if (femaLayer && femaLayer.mapLayerBins.length) {
        if (riskLevel.includes(">")) {
            return ["all", [">", ["get", layerCode], parseFloat(riskLevel.split(">")[1])]];
        } else {
            const [minLevel, maxLevel] = riskLevel.split("-").map(parseFloat);
            return createRangeExpression(layerCode, minLevel, maxLevel);
        }
    }

    return ["==", ["get", layerCode], riskLevel];
};

export const getFEMALayerStyleConfig = (layerCode: string) => {
    const femaLayer = FEMA_SUB_LAYERS_LIST.find(item => item.visField === layerCode);
    return femaLayer && femaLayer.mapLayerBins.length
        ? createNHLLayerConfig(layerCode, femaLayer.mapLayerBins)
        : createNRILayerConfig(layerCode);
};

export const getFEMASubLayerById = (id: string) => {
    return Object.values(FEMA_SUB_LAYERS).find((subLayer: { id: string }) => subLayer.id === id);
};
