import { uniqBy } from "lodash-es";
import { COLORS } from "@common/constants/color.constants";

export const DEFAULT_DRAW_STYLES = [
    {
        id: "gl-draw-polygon-fill-inactive",
        type: "fill",
        filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "Polygon"],
            ["!=", "mode", "static"],
        ],
        paint: {
            "fill-color": COLORS.BLUE,
            "fill-outline-color": COLORS.BLUE,
            "fill-opacity": 0.1,
        },
    },
    {
        id: "gl-draw-polygon-fill-active",
        type: "fill",
        filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
        paint: {
            "fill-color": COLORS.YELLOW,
            "fill-outline-color": COLORS.YELLOW,
            "fill-opacity": 0.1,
        },
    },
    {
        id: "gl-draw-polygon-midpoint",
        type: "circle",
        filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
        paint: {
            "circle-radius": 3,
            "circle-color": COLORS.YELLOW,
        },
    },
    {
        id: "gl-draw-polygon-stroke-inactive",
        type: "line",
        filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "Polygon"],
            ["!=", "mode", "static"],
        ],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": COLORS.BLUE,
            "line-width": 2,
        },
    },
    {
        id: "gl-draw-polygon-stroke-active",
        type: "line",
        filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": COLORS.YELLOW,
            "line-dasharray": [0.2, 2],
            "line-width": 2,
        },
    },
    {
        id: "gl-draw-line-inactive",
        type: "line",
        filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "LineString"],
            ["!=", "mode", "static"],
        ],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": COLORS.BLUE,
            "line-width": 2,
        },
    },
    {
        id: "gl-draw-line-active",
        type: "line",
        filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": COLORS.YELLOW,
            "line-dasharray": [0.2, 2],
            "line-width": 2,
        },
    },
    {
        id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
        type: "circle",
        filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
        ],
        paint: {
            "circle-radius": 5,
            "circle-color": "#fff",
        },
    },
    {
        id: "gl-draw-polygon-and-line-vertex-inactive",
        type: "circle",
        filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
        ],
        paint: {
            "circle-radius": 3,
            "circle-color": COLORS.YELLOW,
        },
    },
    {
        id: "gl-draw-point-point-stroke-inactive",
        type: "circle",
        filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "Point"],
            ["==", "meta", "feature"],
            ["!=", "mode", "static"],
        ],
        paint: {
            "circle-radius": 5,
            "circle-opacity": 1,
            "circle-color": "#fff",
        },
    },
    {
        id: "gl-draw-point-inactive",
        type: "circle",
        filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "Point"],
            ["==", "meta", "feature"],
            ["!=", "mode", "static"],
        ],
        paint: {
            "circle-radius": 3,
            "circle-color": COLORS.BLUE,
        },
    },
    {
        id: "gl-draw-point-stroke-active",
        type: "circle",
        filter: [
            "all",
            ["==", "$type", "Point"],
            ["==", "active", "true"],
            ["!=", "meta", "midpoint"],
        ],
        paint: {
            "circle-radius": 7,
            "circle-color": "#fff",
        },
    },
    {
        id: "gl-draw-point-active",
        type: "circle",
        filter: [
            "all",
            ["==", "$type", "Point"],
            ["!=", "meta", "midpoint"],
            ["==", "active", "true"],
        ],
        paint: {
            "circle-radius": 5,
            "circle-color": COLORS.YELLOW,
        },
    },
];

// zone draw styles
const DEFAULT_POLYGON_COLOR_EXPRESSION = [
    "case",
    ["==", ["get", "user_gateType"], "start"],
    COLORS.GREEN,
    ["==", ["get", "user_gateType"], "middle"],
    COLORS.MID_YELLOW,
    ["==", ["get", "user_gateType"], "end"],
    COLORS.RED,
];

const ACTIVE_POLYGON_COLOR_EXPRESSION = [...DEFAULT_POLYGON_COLOR_EXPRESSION, COLORS.YELLOW];
const INACTIVE_POLYGON_COLOR_EXPRESSION = [...DEFAULT_POLYGON_COLOR_EXPRESSION, COLORS.BLUE];

const ZONE_DRAW_STYLES = [
    {
        id: "gl-draw-line-inactive-spot-gate",
        type: "line",
        filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "LineString"],
            ["!=", "mode", "static"],
        ],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": COLORS.YELLOW,
            "line-width": 10,
        },
    },
    {
        id: "gl-draw-line-active-spot-gate",
        type: "line",
        filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "LineString"],
            ["!=", "mode", "static"],
        ],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": COLORS.YELLOW,
            "line-width": 10,
        },
    },
    {
        id: "gl-draw-polygon-fill-active",
        type: "fill",
        filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
        paint: {
            "fill-color": ACTIVE_POLYGON_COLOR_EXPRESSION,
            "fill-outline-color": ACTIVE_POLYGON_COLOR_EXPRESSION,
            "fill-opacity": 0.8,
        },
    },
    {
        id: "gl-draw-polygon-stroke-active",
        type: "line",
        filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": ACTIVE_POLYGON_COLOR_EXPRESSION,
            "line-dasharray": [0.4, 2],
            "line-width": 4,
        },
    },
    {
        id: "gl-draw-line-active",
        type: "line",
        filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": COLORS.YELLOW,
            "line-dasharray": [0.4, 2],
            "line-width": 4,
        },
    },
    {
        id: "gl-draw-polygon-midpoint",
        type: "circle",
        filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
        paint: {
            "circle-radius": 5,
            "circle-color": COLORS.YELLOW,
        },
    },
    {
        id: "gl-draw-polygon-fill-inactive",
        type: "fill",
        filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "Polygon"],
            ["!=", "mode", "static"],
        ],
        paint: {
            "fill-color": INACTIVE_POLYGON_COLOR_EXPRESSION,
            "fill-outline-color": INACTIVE_POLYGON_COLOR_EXPRESSION,
            "fill-opacity": 0.1,
        },
    },
    {
        id: "gl-draw-polygon-stroke-inactive",
        type: "line",
        filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "Polygon"],
            ["!=", "mode", "static"],
        ],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": INACTIVE_POLYGON_COLOR_EXPRESSION,
            "line-width": 2,
        },
    },
    {
        id: "gl-draw-line-inactive",
        type: "line",
        filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "LineString"],
            ["!=", "mode", "static"],
        ],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": COLORS.YELLOW,
            "line-width": 2,
        },
    },
    {
        id: "gl-draw-line-active-spot-gate-direction",
        type: "symbol",
        minzoom: 14,
        layout: {
            "icon-image": [
                "case",
                ["get", "user_is_bidi"],
                "bi-directional-map-with-border",
                "uni-directional-map-with-border",
            ],
            "icon-rotation-alignment": "map",
            "icon-rotate": ["+", ["get", "user_direction"], 90],
            "icon-size": ["interpolate", ["linear"], ["zoom"], 14, 0.2, 17, 0.5, 20, 1],
            "symbol-placement": "line-center",
        },
        filter: [">=", ["to-number", ["zoom"]], 17],
    },
];

export const DRAW_STYLES = uniqBy([...ZONE_DRAW_STYLES, ...DEFAULT_DRAW_STYLES], "id");
