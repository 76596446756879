import { ReactNode } from "react";
import type { IOptions } from "@common/components/snackbar/snackbar.types";
import { snackbarEventManager } from "./snackbarEventManager";

const renderSnackbar = (message: ReactNode, options: IOptions = {}) => {
    snackbarEventManager.emit("show", { message, options });
};

export const StlNotification = {
    show(message: ReactNode, options: IOptions) {
        renderSnackbar(message, options);
    },
    close(id: string) {
        snackbarEventManager.emit("hide", id);
    },
    success(message: ReactNode, options?: IOptions) {
        this.show(message, { ...options, type: "success", position: "top" });
    },
    error(message: ReactNode, options?: IOptions) {
        this.show(message, { ...options, type: "error" });
    },
    warning(message: ReactNode, options?: IOptions) {
        this.show(message, { ...options, type: "warning", position: "top" });
    },
};

export const StlMessage = {
    show(message: ReactNode, options: IOptions) {
        renderSnackbar(message, {
            position: "top",
            hideCloseButton: true,
            pauseTimeoutOnHover: false,
            ...options,
        });
    },
    close(id: string) {
        snackbarEventManager.emit("hide", id);
    },
    success(message: ReactNode, options?: IOptions) {
        this.show(message, { ...options, type: "success" });
    },
    error(message: ReactNode, options?: IOptions) {
        this.show(message, { ...options, type: "error" });
    },
};
