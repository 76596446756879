import mixpanel from "mixpanel-browser";
import { Location } from "history";
import { ICurrentUser } from "@common/services/services.types";

interface IEventTrackProperties {
    url: string;
}

class _AnalyticsTrack {
    isLocal = window.location.hostname.includes("localhost");
    isInitialized = false;
    get mixPanel() {
        return mixpanel;
    }

    constructor() {
        if (!this.isLocal) {
            this.mixPanel.init("4e31d3a30fd2d4cc096ab7f3a16c0bf6");
            this.isInitialized = true;
            // @ts-ignore Property '_stl_analytics' does not exist on type 'Window & typeof globalThis'.
            window._stl_analytics = this;
        }
    }

    eventTrack(action: string, properties: IEventTrackProperties) {
        if (this.isInitialized) {
            this.mixPanel.track(action, properties);
        }
    }

    pageTrack(path: Location) {
        if (this.isInitialized) {
            this.mixPanel.track("page-view", { path });
        }
    }

    registerSuperProp(currentUser: ICurrentUser) {
        if (this.isInitialized) {
            this.mixPanel.register({
                user_id: currentUser.user.user_id,
                org_id: currentUser.user.org.org_id,
                org_type: currentUser.user.org.org_type,
                is_emulating: currentUser.user.user_id !== currentUser.realUser.user_id,
            });
        }
    }
}

export const AnalyticsTrack = new _AnalyticsTrack();
