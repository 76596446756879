import { WINDSHAFT_TYPE_TO_DISTRIBUTION_LAYER_TYPE } from "@app/viz3/base/map/baseVizMap.constants";
import {
    BOTH_DIRECTION_OPTION,
    DAY_TYPES_OPTIONS,
    GROUP_MODE_OPTIONS,
    VISUALIZATION_PROPERTIES,
} from "@app/viz3/csVisualization/state/csViz.constants";
import type { GradientColorObject, ColorType } from "highcharts";
import type { ILayerConfiguration, IVizAnalysis } from "@app/viz3/viz3.types";
import type { ValuesOfType } from "@common/utils/utility.types";
import type { THoveredZone } from "@common/components/baseMap";
import type { IMapError } from "@common/components/baseMap/mapErrorAlert/mapErrorAlert";
import type { TDirectionOption } from "@common/features/directions/directions.constants";
import { TCSStatsAndScores } from "@common/services/server/layerGroupApi.types";
import type {
    IAllNetworkSegmentFilters,
    ISummaryStatistic,
    IZoneScores,
    IUnreliableSegmentsZoneScores,
} from "@app/viz3/baseLightningViz/state/baseLightningViz.types";
import { TZoneSelectionSelectedToolId } from "@common/components/baseMap/customControls/zoneSelectionTools/zoneSelectionTools.constants";

export type TDayTypeOption = typeof DAY_TYPES_OPTIONS[keyof typeof DAY_TYPES_OPTIONS];

export type TGroupModeOption = typeof GROUP_MODE_OPTIONS[keyof typeof GROUP_MODE_OPTIONS];

export type TGroupModeOptionId = TGroupModeOption["id"];

export interface IDayPart {
    label: string;
    value: number;
}

export interface IDateRange {
    year: string;
    label: string;
}

export interface IBinValue {
    tti: number;
    vhd: number;
    vmt: number;
    avg_speed: number;
    sample_size: "low" | "normal";
    speed_eightyfive_percentile: number;
}

export interface ICSMetrics {
    metric_tti: number;
    metric_vhd: number;
    metric_delay: number;
    metric_pct_vmt_congested: number;
    metric_travel_time: number;
    metric_vmt: number;
}

export interface ICSSampleSizes {
    sample_size_tti: "LOW" | "NORMAL";
    sample_size_vhd: "LOW" | "NORMAL";
    sample_size_delay: "LOW" | "NORMAL";
    sample_size_pct_vmt_congested: "LOW" | "NORMAL";
    sample_size_travel_time: "LOW" | "NORMAL";
    sample_size_vmt: "LOW" | "NORMAL";
}

export type TCSBinValue = ICSMetrics & ICSSampleSizes;

export interface ISeriesData {
    y: number;
    x?: number;
    id: string;
    color?: string | GradientColorObject;
}

export interface ISeries {
    type: "column";
    data: Array<ISeriesData>;
}

export type IVisualizationProperties =
    typeof VISUALIZATION_PROPERTIES[keyof typeof VISUALIZATION_PROPERTIES];

export type TVisualizationPropertiesCodes = IVisualizationProperties["code"];

export interface IHoveredBar {
    id: string;
    label: string;
    barIndex: number;
    barWidth: number;
    color?: ColorType;
    isLowSize: boolean;
    isWeekday?: boolean;
}

export type Coordinate = [number, number];

export type TBothDirection = typeof BOTH_DIRECTION_OPTION;

export type TCSDirection = TDirectionOption | TBothDirection;

export type TDirectionOptionCode = TDirectionOption["code"];

export enum EFilterOptionVariant {
    allSegments = "all",
    corridorGroup = "corridorGroup",
}

export interface ICSSegment {
    name: string;
    zone_id: string;
    orderIndex: number;
    projectRouteZoneId: string;
}

export type TCSSegmentId = ICSSegment["projectRouteZoneId"];

export type TCSZoneScores = IZoneScores | IUnreliableSegmentsZoneScores;

export interface ISegmentLayers {
    max_zoom: number;
    min_zoom: number;
    layergroupid: string;
    project_id: number;
    filter_hash: string;
    project_type_code: string;
    summary_statistics: Record<string, ISummaryStatistic>;
    metadata: {
        layers: Array<{
            id: string;
            sublayer_type: keyof typeof WINDSHAFT_TYPE_TO_DISTRIBUTION_LAYER_TYPE;
        }>;
    };
    zone_scores: IZoneScores;
}

export enum ECorridorSubGroupType {
    None = "None",
    Corridor = "Corridor",
    Planning = "Planning",
    Operational = "Operational",
}

export type TCSCorridorSubGroupType = Omit<ECorridorSubGroupType, ECorridorSubGroupType.None>;

export type TCSGroupSelectionMode = Omit<ECorridorSubGroupType, ECorridorSubGroupType.Corridor>;

interface ICSCorridorSubGroup {
    id: string;
    name: string;
    type: TCSCorridorSubGroupType;
    parentId: string | null;
    childIds: Array<string>;
    orderIndex: number;
    projectRouteId?: string;
}

export interface ICSCorridorGroup<
    TChildGroupType extends ECorridorSubGroupType = ECorridorSubGroupType,
> extends ICSCorridorSubGroup {
    childIds: TChildGroupType extends ECorridorSubGroupType.Operational
        ? Array<TCSSegmentId>
        : ICSCorridorGroup<ECorridorSubGroupType.Operational>["childIds"] | Array<TCSSegmentId>;
}

export interface ICorridorGroupCreationMap {
    mapError: IMapError | null;
    hoveredZone: THoveredZone | null;
    selectedToolId: TZoneSelectionSelectedToolId | null;
}

interface ICSLayerConfiguration extends Omit<ILayerConfiguration, "color" | "opacity"> {
    directionColors: {
        [key in TCSDirection["code"]]: string;
    };
}

interface IFilterGroups {
    corridorGroups: Record<ICSCorridorGroup["id"], ICSCorridorGroup>;
    corridorGroupIds: Array<ICSCorridorGroup["id"]>;
    planningGroupIdsToOperationalGroupsIds: Record<
        ICSCorridorGroup<ECorridorSubGroupType.Planning>["id"],
        Array<ICSCorridorGroup<ECorridorSubGroupType.Operational>["id"]>
    >;
}

export interface ICorridorGroupCreation {
    map: ICorridorGroupCreationMap;
    corridorGroups: Record<ICSCorridorGroup["id"], ICSCorridorGroup>;
    selectedSegmentIds: Array<TCSSegmentId>;
    groupSelectionMode: TCSGroupSelectionMode;
    mainCorridorGroupId: ICSCorridorGroup["id"] | null;
    planningGroupsCounter: number;
    operationalGroupsCounter: number;
    selectedCorridorGroupIds: Array<ICSCorridorGroup["id"]>;
    selectedProjectRouteGroupId: string | null;
    corridorGroupBeingUpdatedId: string | null;
    isCorridorGroupCreationOpened: boolean;
}

export type TSelectedSegmentGeography = { project_route_zone_id: number } & Record<string, any>;

export interface ICSVizState {
    segmentLayers: ISegmentLayers | null;
    statsAndScores: TCSStatsAndScores | null;
    map: {
        layerConfigurations: ICSLayerConfiguration;
    };
    filterGroups: IFilterGroups;
    corridorGroupCreation: ICorridorGroupCreation;
}

export type TCSVizStateFilterGroupTypeByName<T extends keyof ICSVizState["filterGroups"]> =
    ValuesOfType<ICSVizState["filterGroups"][T]>;

export type TCSVizStateCorridorCreationTypeByName<
    T extends keyof ICSVizState["corridorGroupCreation"],
> = ValuesOfType<ICSVizState["corridorGroupCreation"][T]>;

export type TCSVizStateFilterGroups = ICSVizState["filterGroups"];

export interface ICSSegmentFilters {
    code: TCSVizStateFilterGroupTypeByName<"corridorGroups">["id"];
    values: [];
}

export type TCSSelectedFilters = IAllNetworkSegmentFilters | ICSSegmentFilters;

export interface IOrderedZone {
    zone_id: number;
    zone_name: string;
    zone_kind_id: number;
    is_waypoint: boolean;
    reverse_direction: boolean;
    project_route_zone_id: number;
}

export interface IProjectRoute {
    name: string;
    project_route_id: number;
    project_route_geojson: string;
    zones: Array<IOrderedZone>;
    direction: TDirectionOption["code"];
}

export interface ICSVizAnalysis extends IVizAnalysis {
    project_routes: Array<IProjectRoute>;
}
