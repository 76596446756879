import type { ILayerConfiguration } from "@app/viz3/viz3.types";
import type { ISegmentLayers } from "@app/viz3/ctVisualization/state/ctViz.types";
import { MAP_STYLES } from "@app/viz3/ctVisualization/components/content/components/ctMap/mapStyles.constants";

export interface ICTVizState {
    segmentLayers: ISegmentLayers | null;
    map: {
        layerConfigurations: Omit<ILayerConfiguration, "color" | "opacity">;
    };
}

export const CT_VIZ_INITIAL_STATE = {
    segmentLayers: null,
    map: {
        layerConfigurations: {
            colors: {
                high: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HIGH,
                mid: "",
                low: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.LOW,
                hover: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HOVER,
            },
            choroplethOpacity: {
                min: MAP_STYLES.DISTRIBUTION_ZONES.CHOROPLETH_OPACITY.MIN,
                max: MAP_STYLES.DISTRIBUTION_ZONES.CHOROPLETH_OPACITY.MAX,
            },
        },
    },
} as ICTVizState;
