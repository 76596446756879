import { BASE_MAP_STYLES } from "@app/viz3/base/map/baseMapStyles.constants";
import { TROD_LAYERS } from "@app/viz3/trodVisualization/state/trodViz.constants";

export const MAP_STYLES = {
    ...BASE_MAP_STYLES,
    [TROD_LAYERS.ROUTE_SEGMENT.code]: {
        COLOR: {
            HIGH: "#97C667",
            LOW: "#666666",
            HOVER: "#FFFFFF",
        },
        OPACITY: BASE_MAP_STYLES.DISTRIBUTION_ZONES.OPACITY,
    },
};
