import {
    getAnalysisCountry,
    getCalibrationSettings,
    getIsUSTruckVolumeAnalysis,
} from "@app/analysis/basics/state/basics.selectors";
import { CALIBRATIONS, ZONES_MODES } from "@app/analysis/state/analysisConfiguration.constants";
import {
    getAnalysisTypeZonesLimit,
    getZonesLimitValidation,
} from "@app/analysis/state/analysisConfiguration.selectors";
import {
    getAnalysisTypeCode,
    getUiStates as getUiGeneralStates,
} from "@app/analysis/state/general/general.selectors.js";
import { createValidateField } from "@app/analysis/validation";
import { validateIPFCalibrationMandatoryData } from "@app/analysis/zones/chooseZones/base/sidebar/ipfCalibrationSection/ipfCalibrationSection.helpers";
import { CHOOSE_ZONES_INITIAL_STATE } from "@app/analysis/zones/chooseZones/state/chooseZones.state";
import { getConfigParams } from "@app/store/currentUser/currentUser.selector";
import { StlNotification } from "@common/components";
import { baseMapService } from "@common/components/baseMap/baseMap.service";
import { LightboxService } from "@common/components/lightbox/lightbox.service";
import {
    BUS_ZONE_KINDS,
    CALIBRATION_ZONE_ROLES,
    ZONE_KINDS,
} from "@common/constants/zoneLibrary.constants";
import { getBulkActionZonesInputs } from "@common/features/zones/zones.helpers";
import { ZONES_BULK_ACTIONS } from "@common/features/zones/zonesTable/components/zonesBulkActions/zonesBulkActions";
import { ZONE_TYPES } from "@common/features/zonesManager/state/zonesManager.constants";
import { LayerGroupApiService } from "@common/services/server/layerGroupApi.service";
import { ZonesApiService } from "@common/services/server/zonesApi.service";
import { cloneDeep, uniqBy } from "lodash-es";
import { batch } from "react-redux";

import { CHOOSE_ZONES_ACTION_TYPES } from "./chooseZones.actionTypes";
import { SWITCH_MODES, ZONE_ROLES, ZONE_ROLES_LIST } from "./chooseZones.constants";
import {
    convertZoneIdToString,
    getIsCalibrationZoneRole,
    makeZoneTypeObject,
    validateCalibrationZones,
    validateSelectedZones,
} from "./chooseZones.helpers";
import {
    getHoveredZone,
    getSelectedZoneIdsByZoneKind,
    getSelectedZones,
    getUiStates,
    getZonesValidation,
    getZoneType,
} from "./chooseZones.selectors";

export const setZoneRole = zoneRole => ({
    type: CHOOSE_ZONES_ACTION_TYPES.SET_ZONE_ROLE,
    data: { zoneRole },
});

export const updateChooseZonesUIState = data => ({
    type: CHOOSE_ZONES_ACTION_TYPES.UPDATE_UI_STATES,
    data,
});

export const updateZoneType = zoneType => (dispatch, getState) => {
    const currentZoneType = getZoneType(getState());

    const newZoneType = makeZoneTypeObject({
        ...currentZoneType,
        ...zoneType,
        id: zoneType?.id || currentZoneType?.id,
    });

    return dispatch({
        type: CHOOSE_ZONES_ACTION_TYPES.SET_ZONE_TYPE,
        data: { zoneType: newZoneType },
    });
};

export const setZoneType = zoneType => (dispatch, getState) => {
    const currentZoneType = getZoneType(getState());

    const newZoneType = makeZoneTypeObject({
        ...zoneType,
        id: zoneType?.id || currentZoneType?.id,
    });

    return dispatch({
        type: CHOOSE_ZONES_ACTION_TYPES.SET_ZONE_TYPE,
        data: { zoneType: newZoneType },
    });
};

export const setLocation = location => ({
    type: CHOOSE_ZONES_ACTION_TYPES.SET_LOCATION,
    data: { location },
});

export const setRoads = roads => ({
    type: CHOOSE_ZONES_ACTION_TYPES.SET_ROADS,
    data: { roads },
});

export const setZonesValidation = newValidation => ({
    type: CHOOSE_ZONES_ACTION_TYPES.SET_VALIDATION,
    data: { validation: newValidation },
});

export const setShowZonesByRole = zoneRole => ({
    type: CHOOSE_ZONES_ACTION_TYPES.SET_SHOW_ZONES_BY_ROLE,
    data: { zoneRole },
});

export const setIPFCalibrationSettings = ipfCalibrationSettings => ({
    type: CHOOSE_ZONES_ACTION_TYPES.SET_IPF_CALIBRATION,
    data: { ipfCalibrationSettings },
});

export const validateZones = selectedZones => (dispatch, getState) => {
    const state = getState();
    const { zonesMode } = getUiGeneralStates(state);
    const analysisCountry = getAnalysisCountry(state);
    const analysisTypeCode = getAnalysisTypeCode(state);
    const isTruckVolumeAnalysis = getIsUSTruckVolumeAnalysis(state);
    const zonesLimitValidation = getZonesLimitValidation(state);

    const validationResult = validateSelectedZones({
        selectedZones,
        analysisCountry,
        isTruckVolumeAnalysis,
        zonesLimitValidation,
        analysisTypeCode,
    });
    const field = zonesMode === ZONES_MODES.ZONE_SETS ? "zones" : "chooseZones";

    const newValidation = {
        [field]: createValidateField(validationResult),
    };

    dispatch(setZonesValidation(newValidation));
};

export const setSelectedZones = selectedZones => ({
    type: CHOOSE_ZONES_ACTION_TYPES.SET_SELECTED_ZONES,
    data: { selectedZones },
});

export const updateSelectedZones = (zoneType, selectedZones) => (dispatch, getState) => {
    const { isInvalid } = getZonesValidation(getState());

    // Clear validation errors on zones select.
    if (isInvalid && selectedZones.length) {
        dispatch(setZonesValidation(CHOOSE_ZONES_INITIAL_STATE.validation.fields));
    }

    return dispatch({
        type: CHOOSE_ZONES_ACTION_TYPES.UPDATE_SELECTED_ZONES,
        data: { zoneType, selectedZones },
    });
};

export const removeSelectedZones = (zoneType, zoneIds) => (dispatch, getState) => {
    const state = getState();
    const selectedZones = getSelectedZones(state);
    const { isInvalid } = getZonesValidation(getState());

    const newSelectedZones = selectedZones[zoneType].filter(
        zone => !zoneIds.some(zoneId => String(zoneId) === String(zone.zone_id)),
    );

    if (getIsCalibrationZoneRole(zoneType) && !newSelectedZones.length) {
        const { calibrationCode } = getCalibrationSettings(state);
        const newValidation = {
            [zoneType]: validateCalibrationZones({
                zones: newSelectedZones,
                zoneRole: zoneType,
                calibrationCode,
            }),
        };
        dispatch(setZonesValidation(newValidation));
    }

    if (isInvalid) dispatch(validateZones(newSelectedZones));

    return dispatch({
        type: CHOOSE_ZONES_ACTION_TYPES.UPDATE_SELECTED_ZONES,
        data: { zoneType, selectedZones: newSelectedZones },
    });
};

export const setBoundingBoxForImportedZones = () => (dispatch, getState) => {
    const zoneIds = getSelectedZoneIdsByZoneKind(getState());

    LayerGroupApiService.getBoundingBox({ zones: zoneIds })
        .then(({ data }) => {
            const map = baseMapService.getMap();

            map.fitBounds(
                [
                    [data.bounding_box.minlng, data.bounding_box.minlat],
                    [data.bounding_box.maxlng, data.bounding_box.maxlat],
                ],
                { padding: { top: 60, bottom: 60, left: 60, right: 60 } },
            );
        })
        .catch(console.error);
};

export const importZones = (selectedZonesByRole, zoneRoleId) => (dispatch, getState) => {
    const state = getState();

    const selectedZones = getSelectedZones(state);
    const zonesLimit = getAnalysisTypeZonesLimit(state);

    const zoneRoleAccessor = ZONE_ROLES_LIST.find(zoneRole => zoneRole.id === zoneRoleId).accessor;
    const zonesToImport = Object.values(selectedZonesByRole).flat();

    const newSelectedZones = {
        ...cloneDeep(selectedZones),
        [zoneRoleAccessor]: uniqBy(
            [...selectedZones[zoneRoleAccessor], ...zonesToImport],
            "zone_id",
        ),
    };

    // If resulted zone count exceeds the limit.
    if (Object.values(newSelectedZones).flat().length > zonesLimit) {
        // Calculate other zone count.
        const otherZoneCount = Object.entries(newSelectedZones).reduce((result, [role, zones]) => {
            if (role !== zoneRoleAccessor) {
                return result + zones.length;
            }

            return result;
        }, 0);

        const availableCount = zonesLimit - otherZoneCount;

        // Truncate zones to the limit.
        newSelectedZones[zoneRoleAccessor] = newSelectedZones[zoneRoleAccessor].slice(
            0,
            availableCount,
        );

        LightboxService.openNotification({
            title: "Zone count limit has been reached",
            content: `This analysis cannot contain more than ${zonesLimit} zones.
                We’ve selected as many zones as can fit within the zone limit.`,
        });
    }

    dispatch(updateSelectedZones(zoneRoleAccessor, newSelectedZones[zoneRoleAccessor]));
    dispatch(setBoundingBoxForImportedZones());
};

export const setLoadingZoneTable = (zoneRole, isLoading) => ({
    type: CHOOSE_ZONES_ACTION_TYPES.SET_LOADING_ZONE_TABLE,
    data: { zoneRole, isLoading },
});

export const copyZone = (zoneRole, zone, sourceZoneRole) => (dispatch, getState) => {
    const isCustomGate = zone.zone_kind_id === ZONE_KINDS.GATE.id[0];
    const isCustomZone = zone.zone_kind_id === ZONE_KINDS.USER.id[0];

    const data = isCustomZone || isCustomGate ? { sa_version_id: zone.sa_version_id } : {};

    const zoneRoleForLoading = sourceZoneRole || zoneRole;

    dispatch(setLoadingZoneTable(zoneRoleForLoading, true));

    return ZonesApiService.copyZone(zone.zone_kind_id, zone.zone_id, data)
        .then(res => {
            const selectedZones = getSelectedZones(getState());

            const newSelectedZones = selectedZones[zoneRole].reduce((result, _zone) => {
                result.push(_zone);

                // Duplicate the zone just below the selected one to copy.
                if (_zone.zone_id === zone.zone_id) result.push(res.zone);

                return result;
            }, []);

            dispatch({
                type: CHOOSE_ZONES_ACTION_TYPES.UPDATE_SELECTED_ZONES,
                data: { zoneType: zoneRole, selectedZones: newSelectedZones },
            });

            return Promise.resolve();
        })
        .finally(() => {
            dispatch(setLoadingZoneTable(zoneRoleForLoading, false));
        });
};

export const addAsCalibrationZone = (zoneId, sourceZoneRole) => (dispatch, getState) => {
    const state = getState();

    const selectedZones = getSelectedZones(state);
    const { calibrationCode } = getCalibrationSettings(state);
    const zonesLimit = getAnalysisTypeZonesLimit(state);
    const { newCalibrationZonesCount } = getUiStates(state);

    const calibrationZoneRole =
        calibrationCode === CALIBRATIONS.USER_COUNTS.code
            ? CALIBRATION_ZONE_ROLES.CALIBRATIONS
            : CALIBRATION_ZONE_ROLES.AADT_CALIBRATIONS;

    const selectedZonesCount = Object.values(selectedZones).flat().length;

    if (selectedZonesCount + 1 > zonesLimit) {
        return StlNotification.error(
            "Can't add zone, adding this zone would cause this analysis to exceed the " +
                "total number of zones allowed for this analysis type.",
        );
    }

    const selectedZoneBefore = selectedZones[calibrationZoneRole.accessor].find(
        zone => zone.zone_id === zoneId,
    );

    if (selectedZoneBefore) {
        return dispatch(
            copyZone(calibrationZoneRole.accessor, selectedZoneBefore, sourceZoneRole),
        ).then(() => {
            dispatch(
                updateChooseZonesUIState({
                    newCalibrationZonesCount: newCalibrationZonesCount + 1,
                }),
            );
        });
    }

    const newZone = selectedZones[sourceZoneRole].find(zone => zone.zone_id === zoneId);

    return batch(() => {
        dispatch({
            type: CHOOSE_ZONES_ACTION_TYPES.UPDATE_SELECTED_ZONES,
            data: {
                zoneType: calibrationZoneRole.accessor,
                selectedZones: [...selectedZones[calibrationZoneRole.accessor], newZone],
            },
        });
        dispatch(
            updateChooseZonesUIState({
                newCalibrationZonesCount: newCalibrationZonesCount + 1,
            }),
        );
    });
};

export const addZonesAsCalibration = zonesToAdd => (dispatch, getState) => {
    const state = getState();

    const selectedZones = getSelectedZones(state);
    const { calibrationCode } = getCalibrationSettings(state);
    const zonesLimit = getAnalysisTypeZonesLimit(state);
    const { newCalibrationZonesCount } = getUiStates(state);

    const calibrationZoneRole =
        calibrationCode === CALIBRATIONS.USER_COUNTS.code
            ? CALIBRATION_ZONE_ROLES.CALIBRATIONS
            : CALIBRATION_ZONE_ROLES.AADT_CALIBRATIONS;

    const selectedZonesCount = Object.values(selectedZones).flat().length;

    const _zonesToAdd = zonesToAdd.filter(zone => {
        const alreadyAddedZone = selectedZones[calibrationZoneRole.accessor].find(
            calibrationZone => Number(calibrationZone.zone_id) === Number(zone.zone_id),
        );

        return !alreadyAddedZone;
    });
    const addedZonesCount = _zonesToAdd.length;

    if (selectedZonesCount + addedZonesCount > zonesLimit) {
        return StlNotification.error(
            "Can't add all zones as it would cause this analysis to exceed the " +
                "total number of zones allowed for this analysis type.",
        );
    }

    return batch(() => {
        dispatch({
            type: CHOOSE_ZONES_ACTION_TYPES.UPDATE_SELECTED_ZONES,
            data: {
                zoneType: calibrationZoneRole.accessor,
                selectedZones: [...selectedZones[calibrationZoneRole.accessor], ..._zonesToAdd],
            },
        });
        dispatch(
            updateChooseZonesUIState({
                newCalibrationZonesCount: newCalibrationZonesCount + addedZonesCount,
            }),
        );
    });
};

export const reuseZone = (zoneId, sourceZoneRole, targetZoneRole) => (dispatch, getState) => {
    const state = getState();

    const selectedZones = getSelectedZones(state);
    const zonesLimit = getAnalysisTypeZonesLimit(state);

    const newSelectedZones = uniqBy(
        [
            ...selectedZones[targetZoneRole],
            selectedZones[sourceZoneRole].find(zone => zone.zone_id === zoneId),
        ],
        "zone_id",
    );

    const selectedZonesCount = Object.values(selectedZones).flat().length;
    const newSelectedZonesCount = Object.values(newSelectedZones).flat().length;

    const newZonesCount = selectedZonesCount + newSelectedZonesCount;

    if (newZonesCount > zonesLimit) {
        return StlNotification.error(
            "Can't reuse zone, reusing this zone would cause this analysis to exceed the " +
                "total number of zones allowed for this analysis type.",
        );
    }

    return dispatch({
        type: CHOOSE_ZONES_ACTION_TYPES.UPDATE_SELECTED_ZONES,
        data: { zoneType: targetZoneRole, selectedZones: newSelectedZones },
    });
};

export const reuseZones = (sourceZoneRole, targetZoneRole) => (dispatch, getState) => {
    const state = getState();

    const selectedZones = getSelectedZones(state);
    const zonesLimit = getAnalysisTypeZonesLimit(state);

    const normalizeZoneIds = zones =>
        zones.map(zone => ({
            ...zone,
            zone_id: Number(zone.zone_id),
        }));

    const targetZones = normalizeZoneIds(selectedZones[targetZoneRole]);
    const sourceZones = normalizeZoneIds(selectedZones[sourceZoneRole]);

    const newSelectedZones = uniqBy([...targetZones, ...sourceZones], "zone_id");

    const selectedZonesCount = Object.values(selectedZones).flat().length;
    const newSelectedZonesCount = Object.values(newSelectedZones).flat().length;

    const newZonesCount = selectedZonesCount + newSelectedZonesCount;

    if (newZonesCount > zonesLimit) {
        return StlNotification.error(
            "Can't reuse zones, reusing these zones would cause this analysis to exceed the " +
                "total number of zones allowed for this analysis type.",
        );
    }

    return dispatch({
        type: CHOOSE_ZONES_ACTION_TYPES.UPDATE_SELECTED_ZONES,
        data: { zoneType: targetZoneRole, selectedZones: newSelectedZones },
    });
};

export const moveZone =
    (zoneToMove, sourceZoneRole, targetZoneRole, insertIndex) => (dispatch, getState) => {
        // Only OSM Line Segments can be used as Middle Filter Zones
        if (
            targetZoneRole === ZONE_ROLES.MIDDLE_FILTERS.accessor &&
            !ZONE_KINDS.OSM.id.includes(Number(zoneToMove.zone_kind_id))
        ) {
            StlNotification.error(
                "Only OSM Line Segments can be moved to Middle Filters section.",
            );
            return;
        }

        const state = getState();
        const selectedZones = getSelectedZones(state);

        const zoneAlreadyAdded = selectedZones[targetZoneRole].find(
            zone => Number(zone.zone_id) === Number(zoneToMove.zone_id),
        );
        if (zoneAlreadyAdded) {
            const targetRole = [
                ZONE_ROLES.ORIGINS,
                ZONE_ROLES.DESTINATIONS,
                ZONE_ROLES.MIDDLE_FILTERS,
            ].find(zoneRole => zoneRole.accessor === targetZoneRole);

            StlNotification.error(`Zone already exists in ${targetRole.name} section`);
            return;
        }

        const newSourceZones = selectedZones[sourceZoneRole].filter(
            zone => Number(zone.zone_id) !== Number(zoneToMove.zone_id),
        );
        const newTargetZones = [...selectedZones[targetZoneRole]];
        newTargetZones.splice(insertIndex, 0, zoneToMove);

        batch(() => {
            dispatch({
                type: CHOOSE_ZONES_ACTION_TYPES.UPDATE_SELECTED_ZONES,
                data: { zoneType: sourceZoneRole, selectedZones: newSourceZones },
            });
            dispatch({
                type: CHOOSE_ZONES_ACTION_TYPES.UPDATE_SELECTED_ZONES,
                data: { zoneType: targetZoneRole, selectedZones: newTargetZones },
            });
        });
    };

export const moveZones = (sourceZoneRole, targetZoneRole) => (dispatch, getState) => {
    const state = getState();

    const selectedZones = getSelectedZones(state);

    let zonesToMove, remainingZones;
    // Only pass-through zones can be used as Middle Filter Zones
    // Also, remove all moved zones from OZ and DZ zone roles
    if (targetZoneRole === ZONE_ROLES.MIDDLE_FILTERS.accessor) {
        zonesToMove = selectedZones[sourceZoneRole].filter(zone => zone.is_pass);
        const zonesToMoveIds = zonesToMove.map(zone => zone.zone_id);

        remainingZones = ["oz", "dz"].reduce((res, zoneRole) => {
            const zonesToRemain = selectedZones[zoneRole].filter(
                zone => !zonesToMoveIds.includes(zone.zone_id),
            );

            return { ...res, [zoneRole]: zonesToRemain };
        }, {});
    } else {
        zonesToMove = selectedZones[sourceZoneRole];
        remainingZones = {
            [sourceZoneRole]: [],
        };
    }

    const newSelectedZones = uniqBy([...selectedZones[targetZoneRole], ...zonesToMove], "zone_id");

    batch(() => {
        Object.entries(remainingZones).forEach(([zoneRole, zones]) => {
            dispatch({
                type: CHOOSE_ZONES_ACTION_TYPES.UPDATE_SELECTED_ZONES,
                data: { zoneType: zoneRole, selectedZones: zones },
            });
        });
        dispatch({
            type: CHOOSE_ZONES_ACTION_TYPES.UPDATE_SELECTED_ZONES,
            data: { zoneType: targetZoneRole, selectedZones: newSelectedZones },
        });
    });
};

export const setMapError = mapError => ({
    type: CHOOSE_ZONES_ACTION_TYPES.SET_MAP_ERROR,
    data: { mapError },
});

export const setWizardModalState = isWizardModalOpen => ({
    type: CHOOSE_ZONES_ACTION_TYPES.SET_WIZARD_MODAL_STATE,
    data: { isWizardModalOpen },
});

export const setShouldCreateZoneSet = shouldCreateZoneSet => ({
    type: CHOOSE_ZONES_ACTION_TYPES.SET_SHOULD_CREATE_ZONE_SET,
    data: { shouldCreateZoneSet },
});

export const resetReducer = () => ({
    type: CHOOSE_ZONES_ACTION_TYPES.RESET_STATE,
});

export const setHoveredZone = (zone, allHoveredZones) => (dispatch, getState) => {
    const hoveredZone = getHoveredZone(getState());

    if (!zone?.forceUpdate && String(zone?.zone_id) === String(hoveredZone?.zone_id)) return null;

    return batch(() => {
        dispatch({
            type: CHOOSE_ZONES_ACTION_TYPES.SET_HOVERED_ZONE,
            data: { zone },
        });
        if (!zone || Number(zone.zone_kind_id) === BUS_ZONE_KINDS.ROUTES.id) {
            const busHoveredZones = allHoveredZones || (zone ? [zone] : []);
            dispatch({
                type: CHOOSE_ZONES_ACTION_TYPES.SET_HOVERED_BUS_ZONES,
                data: { zones: busHoveredZones },
            });
        }
    });
};

export const setZonesInitialData = zonesData => ({
    type: CHOOSE_ZONES_ACTION_TYPES.SET_INITIAL_DATA,
    data: zonesData,
});

// This request is used to retrieve information about zones for selected zone sets to display them
// at zones view
// Available zone sets don't have information about their zones, as it might cause performance
// issues on accounts with a big amount of zone sets/zones
// We also cannot use information about zones from geojson request as it requests information for
// all selected zone sets (this request retrieves information only about updated type of zone
// sets) and there is no good way to determine which zone sets were updated
// More details at INST-15341
export const addZonesFromList = (ids, type, selectedZones) => dispatch => {
    const params = {
        format: "zoneDetailOnly",
        [`${type}_ids`]: ids,
    };

    return ZonesApiService.getZoneSets({ params }).then(({ sets }) => {
        dispatch(setZoneType(ZONE_TYPES.USER)); // lineSegment or User +

        const zones = [];
        sets.forEach(zoneSet => {
            zoneSet.zones?.forEach(zone => {
                //Zone Library zone id should be string
                const convertedZone = convertZoneIdToString(zone);

                const isHasZone =
                    selectedZones[type].length &&
                    selectedZones[type].some(z => z.zone_id === convertedZone.zone_id);
                if (isHasZone) return;

                zones.push({
                    ...convertedZone,
                    touches_ca: zoneSet.touches_ca,
                    touches_us: zoneSet.touches_us,
                });
            });
        });

        dispatch(updateSelectedZones(type, [...selectedZones[type], ...zones]));
    });
};

export const fetchZoneById = zone => () => {
    return ZonesApiService.getZoneById(zone.zone_kind_id, zone.zone_id).then(res => res.data);
};

export const reverseBiDiCustomGates = zonesData => {
    const bidiZonesData = {
        ...zonesData,
        direction: "unidi",
    };
    return ZonesApiService.bulkUpdateAnalysisZonesDirection(bidiZonesData).then(res => {
        const uniZonesData = {
            zone_inputs: getBulkActionZonesInputs(res.zones),
        };

        return ZonesApiService.bulkReverseAnalysisZonesDirection(uniZonesData);
    });
};

export const bulkUpdateZonesDirection =
    (zones, zoneType, directionType, shouldReverseBiDiZone = false) =>
    (dispatch, getState) => {
        const state = getState();
        const selectedZones = getSelectedZones(state);
        const isCustomGates = zones[0].zone_kind_id === ZONE_KINDS.GATE.id[0];

        const isReverseAction = directionType === ZONES_BULK_ACTIONS.REVERSE_DIRECTION.code;

        const data = {
            zone_inputs: getBulkActionZonesInputs(zones),
        };

        if (!isReverseAction) {
            data.direction = directionType;
        }

        let action = isReverseAction
            ? ZonesApiService.bulkReverseAnalysisZonesDirection
            : ZonesApiService.bulkUpdateAnalysisZonesDirection;

        if (isCustomGates && shouldReverseBiDiZone) action = reverseBiDiCustomGates;

        return action(data).then(res => {
            const updatedZonesByZoneId = res.zones.reduce((result, updatedZone) => {
                const sameZone = selectedZones[zoneType].find(
                    zone => Number(updatedZone.zone_id) === Number(zone.zone_id),
                );

                if (sameZone) {
                    result[sameZone.zone_id] = updatedZone;
                } else {
                    const predecessorZone = selectedZones[zoneType].find(
                        zone => Number(updatedZone.predecessor_zone_id) === Number(zone.zone_id),
                    );

                    result[predecessorZone.zone_id] = updatedZone;
                }

                return result;
            }, {});

            const updatedZones = selectedZones[zoneType].reduce((result, zone) => {
                return [...result, updatedZonesByZoneId[zone.zone_id] || zone];
            }, []);

            dispatch({
                type: CHOOSE_ZONES_ACTION_TYPES.UPDATE_SELECTED_ZONES,
                data: { zoneType, selectedZones: updatedZones },
            });
        });
    };

export const setIPFCalibrationMandatoryValidation =
    ({ dayTypes, dayParts, selectedZones }) =>
    (dispatch, getState) => {
        const state = getState();
        const configParams = getConfigParams(state);
        const zonesValidation = getZonesValidation(state);

        const ipfMandatoryValidationErrors = validateIPFCalibrationMandatoryData({
            dayTypes,
            dayParts,
            selectedZones,
            maxIPFZonesPerRole: configParams.max_ipf_zones_per_role || 15,
        });
        const newZonesValidation = {
            ...zonesValidation.fields,
            ipfValues: createValidateField(ipfMandatoryValidationErrors),
        };

        dispatch({
            type: CHOOSE_ZONES_ACTION_TYPES.SET_VALIDATION,
            data: { validation: newZonesValidation },
        });
    };

export const resetCalibrationZonesData = calibrationCode => dispatch => {
    const isCalibrationOutputType = [
        CALIBRATIONS.USER_COUNTS.code,
        CALIBRATIONS.AADT.code,
        CALIBRATIONS.IPF.code,
    ].includes(calibrationCode);

    // Reset active tab for Zones and calibration zones counter when non-calibration output selected
    if (!isCalibrationOutputType) {
        dispatch(
            updateChooseZonesUIState({
                activeTabId: SWITCH_MODES.ANALYSIS_ZONES.id,
                newCalibrationZonesCount: 0,
            }),
        );
        dispatch(setZoneRole(ZONE_ROLES.ORIGINS.accessor));
    }
    // Clear zones for "cz" role if not USER_COUNTS calibration selected
    if (calibrationCode !== CALIBRATIONS.USER_COUNTS.code) {
        dispatch(updateSelectedZones(CALIBRATION_ZONE_ROLES.CALIBRATIONS.accessor, []));
    }
    // Clear zones for "az" role if not AADT calibration selected
    if (calibrationCode !== CALIBRATIONS.AADT.code) {
        dispatch(updateSelectedZones(CALIBRATION_ZONE_ROLES.AADT_CALIBRATIONS.accessor, []));
    }
};
