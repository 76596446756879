import FormatUnlimited from "@common/formatters/formatUnlimited";
import type { IOrgBalanceAPI } from "@common/services/server/orgApi.types";
import { StlAccountListItem } from "./accountListItem";

type TProps = {
    orgBalance: IOrgBalanceAPI;
};

export const CommonInformation = ({ orgBalance }: TProps) => {
    const userCount =
        orgBalance.user_count_standard +
        orgBalance.user_count_add_on +
        orgBalance.user_count_other;
    const userQuota = [
        orgBalance.user_quota_standard,
        orgBalance.user_quota_add_on,
        orgBalance.user_quota_other,
    ].reduce(
        (res, quotaValue) => {
            if (quotaValue !== null) {
                res.totalQuota += quotaValue;
                res.hasQuota = true;
            }
            return res;
        },
        { hasQuota: false, totalQuota: 0 },
    );

    return (
        <>
            <StlAccountListItem title="Start Date:">
                <div>{orgBalance.start_date}</div>
            </StlAccountListItem>
            <StlAccountListItem title="End Date:">{orgBalance.expiration_date}</StlAccountListItem>
            <StlAccountListItem title="User Limit:">
                <FormatUnlimited value={userQuota.hasQuota ? userQuota.totalQuota : null} />
            </StlAccountListItem>
            <StlAccountListItem title="User Usage:">{userCount}</StlAccountListItem>
        </>
    );
};
