import { Map } from "mapbox-gl";
import { getAngleValue } from "@common/components/baseMap/baseMap.helpers";
import { createButton, createImage, createMask } from "./customControls.helpers";

export class NorthArrowControl {
    button: HTMLButtonElement | null = null;
    img: HTMLImageElement | null = null;
    mask: HTMLDivElement | null = null;
    isDrag: boolean = false;
    startPosition: { x?: number; y?: number } = {};
    imgSrc!: string;
    _map!: Map | undefined;
    _container!: HTMLDivElement;
    handleRotateChange!: () => void;
    handleRotateAndPitchReset!: () => void;
    handleChange!: (event: MouseEvent) => void;
    handleMouseDown!: (event: MouseEvent) => void;
    handleMouseMove!: (event: MouseEvent) => void;
    handleMouseUp!: (event: MouseEvent) => void;

    constructor(imgSrc: string) {
        this.imgSrc = imgSrc;
    }

    onAdd = (map: Map) => {
        this._map = map;
        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group north-arrow-control";

        const button = createButton({
            className: "north-arrow-button",
            tooltip: "Reset bearing to north",
        });

        const img = createImage({
            src: this.imgSrc,
            alt: "arrow-direction",
        });

        const mask = createMask({
            className: "mask",
        });

        this.img = img;
        this.button = button;
        this.mask = mask;

        this._container.appendChild(button);
        this.button.appendChild(mask);
        this.button.appendChild(img);

        this.handleRotateAndPitchReset = () => this._map?.resetNorthPitch();

        this.handleChange = e => {
            const { offsetX, offsetY } = e;

            const { value } = getAngleValue(1, offsetX, offsetY, 60);

            this._map?.setBearing(value);
        };

        this.handleMouseDown = e => {
            this.isDrag = false;
            this.startPosition = { x: e.pageX, y: e.pageY };
        };

        this.handleMouseMove = e => {
            e.preventDefault();
            e.stopPropagation();

            const isButtonPressed =
                // @ts-ignore Property 'nativeEvent' does not exist on type 'MouseEvent'.
                typeof e.buttons === "undefined" ? e.nativeEvent.which === 1 : e.buttons === 1;

            if (!(e.pageX === this.startPosition.x && e.pageY === this.startPosition.y)) {
                this.isDrag = true;
            }

            if (this.isDrag && isButtonPressed) {
                this.handleChange(e);
            }
        };

        this.handleMouseUp = e => {
            if (this.isDrag) {
                this.handleChange(e);
            } else {
                this.handleRotateAndPitchReset();
            }

            this.isDrag = false;
            this.startPosition = {};
        };

        this.handleRotateChange = () => {
            const rotationValue = this._map?.getBearing();
            const pitchValue = this._map?.getPitch();

            if (this.img) {
                this.img.style.transform = `rotate(${rotationValue}deg) rotateX(${pitchValue}deg)`;
            }
        };

        this._map.on("rotate", this.handleRotateChange);
        this._map.on("pitch", this.handleRotateChange);
        this.mask.addEventListener("mousedown", this.handleMouseDown);
        this.mask.addEventListener("mousemove", this.handleMouseMove);
        this.mask.addEventListener("mouseup", this.handleMouseUp);

        return this._container;
    };

    onRemove = () => {
        this.mask?.removeEventListener("mousedown", this.handleMouseDown);
        this.mask?.removeEventListener("mousemove", this.handleMouseMove);
        this.mask?.removeEventListener("mouseup", this.handleMouseUp);
        this._map?.off("rotate", this.handleRotateChange);
        this._map?.off("pitch", this.handleRotateChange);
        this._container.parentNode?.removeChild(this._container);
        this._map = undefined;
    };

    setSelected = (isSelected: boolean) => {
        this._container.classList.toggle("active", isSelected);
    };
}
