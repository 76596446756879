const ACTION_PREFIX = "TROD_VIZ";

export const TROD_VIZ_ACTIONS = {
    SET_CLICKED_ZONE: `${ACTION_PREFIX}/SET_CLICKED_ZONE`,
    SET_PRE_SELECTED_ZONE: `${ACTION_PREFIX}/SET_PRE_SELECTED_ZONE`,
    SET_DISTRIBUTION_LAYERS: `${ACTION_PREFIX}/SET_DISTRIBUTION_LAYERS`,
    SET_STATIC_GEOMETRIES: `${ACTION_PREFIX}/SET_STATIC_GEOMETRIES`,
    SET_LAYER_CONFIGURATION: `${ACTION_PREFIX}/SET_LAYER_CONFIGURATION`,
    SET_SEGMENTS_FOR_COMPARISON: `${ACTION_PREFIX}/SET_SEGMENTS_FOR_COMPARISON`,
    SET_VIZ_STATE: `${ACTION_PREFIX}/SET_VIZ_STATE`,
};
