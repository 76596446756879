export const MEASUREMENT_UNITS = {
    MILES: { id: "mi", name: "Miles", singularName: "Mile" },
    KILOMETERS: { id: "km", name: "Kilometers", singularName: "Kilometer" },
} as const;

export type TMeasurementUnitsKey = keyof typeof MEASUREMENT_UNITS;
export type TMeasurementUnits = typeof MEASUREMENT_UNITS[TMeasurementUnitsKey];

export const MEASUREMENT_UNITS_LIST = Object.values(MEASUREMENT_UNITS);
export type TMeasurementUnit = typeof MEASUREMENT_UNITS[keyof typeof MEASUREMENT_UNITS];

export const SPEED_UNITS = {
    MPH: { id: "mph", name: "mph" },
    KPH: { id: "kph", name: "kph" },
} as const;

export const SPEED_UNITS_LIST = Object.values(SPEED_UNITS);
