import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";

export const WithTitle = ({ title, children }: { title: any; children: ReactNode }) => {
    return (
        <>
            {title && (
                <Helmet>
                    <title>{`${title} - StreetLight InSight`}</title>
                </Helmet>
            )}
            {children}
        </>
    );
};

export const titleThis = (title: string, Component: React.ElementType) => () =>
    (
        <>
            {title && (
                <Helmet>
                    <title>{`${title} - StreetLight InSight`}</title>
                </Helmet>
            )}
            <Component />
        </>
    );
