import {
    BASE_VIZ_DEFAULTS,
    TIME_FILTERS,
    VIZ_MAP_LAYERS,
} from "@app/viz3/base/state/baseViz.constants";
import {
    DEFAULT_LIGHTNING_FILTERS,
    SEGMENT_FILTERS,
} from "@app/viz3/baseLightningViz/state/baseLightningViz.constants";
import { DIRECTION_OPTIONS } from "@common/features/directions/directions.constants";
import {
    faLeftRight,
    faLongArrowAltDown,
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faLongArrowAltUp,
} from "@fortawesome/pro-solid-svg-icons";

export const CS_SEGMENT_FILTERS_LIST = [SEGMENT_FILTERS.ALL, SEGMENT_FILTERS.CORRIDORS];
export type TCSSegmentFilters = typeof CS_SEGMENT_FILTERS_LIST[number];

export const BOTH_DIRECTION_OPTION = {
    id: "both",
    code: "BOTH",
    label: "Both Directions",
} as const;
export type TBothDirectionOption = typeof BOTH_DIRECTION_OPTION;

export const CORRIDOR_STUDY_DIRECTIONS = {
    NORTH: "Northbound",
    SOUTH: "Southbound",
    WEST: "Westbound",
    EAST: "Eastbound",
    BOTH: "Both directions",
} as const;

export const GROUP_MODE_OPTIONS = {
    DAY_PARTS: {
        id: "day_parts",
        code: "DAY_PARTS",
        name: "Day Parts",
        label: "Day Parts",
        ariaLabel: "Select day parts group mode",
    },
    INDIVIDUAL_HOURS: {
        id: "individual_hours",
        code: "INDIVIDUAL_HOURS",
        name: "Hourly",
        label: "Hours",
        ariaLabel: "Select hourly group mode",
    },
} as const;

export const INDEX_RANGES_BY_GROUP_MODE = {
    [GROUP_MODE_OPTIONS.INDIVIDUAL_HOURS.id]: [
        { from: -0.5, to: 5.5 },
        { from: 5.5, to: 9.5 },
        { from: 9.5, to: 15.5 },
        { from: 15.5, to: 19.5 },
        { from: 19.5, to: 23.5 },
        { from: 23.5, to: 24.5 },
    ],
    [GROUP_MODE_OPTIONS.DAY_PARTS.id]: [
        { from: -0.5, to: 0.5 },
        { from: 0.5, to: 1.5 },
        { from: 1.5, to: 2.5 },
        { from: 2.5, to: 3.5 },
        { from: 3.5, to: 4.5 },
        { from: 4.5, to: 5.5 },
    ],
} as const;

export const MAIN_TIME_PERIODS_INDEXES = {
    [GROUP_MODE_OPTIONS.INDIVIDUAL_HOURS.id]: { from: 5, to: 20 },
    [GROUP_MODE_OPTIONS.DAY_PARTS.id]: { from: 0, to: 5 },
};

export type TIndexRanges =
    typeof INDEX_RANGES_BY_GROUP_MODE[keyof typeof INDEX_RANGES_BY_GROUP_MODE][number];

export const DAY_TYPES_OPTIONS = {
    WEEKDAY: {
        value: 15,
        label: "Weekday (M-F)",
    },
    WEEKEND: {
        value: 67,
        label: "Weekend Day (Sa-Su)",
    },
    SUM_OPTION: {
        value: "sum_option",
        label: "Weekday and Weekend",
    },
} as const;

export const DAY_TYPES_OPTIONS_LIST = Object.values(DAY_TYPES_OPTIONS);
export type TDayTypeOption = typeof DAY_TYPES_OPTIONS[keyof typeof DAY_TYPES_OPTIONS];

export const DAY_PARTS = {
    EARLY_AM: {
        label: "Early AM (12am-6am)",
        value: 800000559,
        hourValues: [800000059, 801000159, 802000259, 803000359, 804000459, 805000559],
    },
    PEAK_AM: {
        label: "Peak AM (6am-10am)",
        value: 806000959,
        hourValues: [806000659, 807000759, 808000859, 809000959],
    },
    MID_DAY: {
        label: "Mid-Day (10am-4pm)",
        value: 810001559,
        hourValues: [810001059, 811001159, 812001259, 813001359, 814001459, 815001559],
    },
    PEAK_PM: {
        label: "Peak PM (4pm-8pm)",
        value: 816001959,
        hourValues: [816001659, 817001759, 818001859, 819001959],
    },
    LATE_PM: {
        label: "Late PM (8pm-12am)",
        value: 820002359,
        hourValues: [820002059, 821002159, 822002259, 823002359],
    },
};

export const DAY_PARTS_OPTIONS = Object.values(DAY_PARTS);

export const CONGESTED_SEGMENTS_LABEL = "Congested Segments";

export const VISUALIZATION_PROPERTIES = {
    VOLUME: {
        code: "volume",
        label: "Roadway Volume",
    },
    AVG_SPEED: {
        code: "avg_speed",
        label: "Average Speed",
    },
    SEP: {
        code: "speed_percentile_85",
        label: "85th Speed percentile",
    },
    VHD: {
        code: "vhd",
        label: "Vehicle Hours of Delay (per mile)",
    },
    TTI: {
        code: "tti",
        label: "Travel Time Index",
    },
    COG: {
        code: "is_congested",
        label: "Congested Operational Groups",
    },
} as const;

export const VISUALIZATION_PROPERTIES_LIST = Object.values(VISUALIZATION_PROPERTIES);

export const PERCENTAGE_VISUALIZATION_PROPERTIES_LIST = [VISUALIZATION_PROPERTIES.TTI.code];

export const DEFAULT_MAP_LAYERS_VISIBILITY = {
    [VIZ_MAP_LAYERS.LABEL.code]: true,
    [VIZ_MAP_LAYERS.LEGEND.code]: true,
};

export const CS_VIZ_DEFAULTS = {
    filters: {
        ...BASE_VIZ_DEFAULTS.filters,
        [TIME_FILTERS.DAY_TYPES.filterName]: [DAY_TYPES_OPTIONS.WEEKDAY],
        [TIME_FILTERS.DAY_PARTS.filterName]: DAY_PARTS_OPTIONS,
        ...DEFAULT_LIGHTNING_FILTERS,
        selectedCorridorGroupIds: [],
    },
    visualization: {
        ...BASE_VIZ_DEFAULTS.visualization,
        visualizationProperty: VISUALIZATION_PROPERTIES.VOLUME,
        mapLayersVisibility: DEFAULT_MAP_LAYERS_VISIBILITY,
    },
};

export const CS_GROUPING_OPTIONS = {
    dayPart: { id: "dayPart", code: "DAY_PART", name: "Day Part" },
    operationalGroup: {
        id: "operationalGroup",
        code: "OPERATIONAL_GROUP",
        name: "Operational Group",
    },
} as const;
export type TCSGroupingOptionCode =
    typeof CS_GROUPING_OPTIONS[keyof typeof CS_GROUPING_OPTIONS]["code"];

export const UNRELIABLE_SEGMENTS_OPTIONS = {
    YES: "Yes",
    NO: "No",
} as const;

export const ARROW_ICONS_BY_DIRECTION_CODE = {
    [BOTH_DIRECTION_OPTION.code]: faLeftRight,
    [DIRECTION_OPTIONS.EAST.code]: faLongArrowAltRight,
    [DIRECTION_OPTIONS.WEST.code]: faLongArrowAltLeft,
    [DIRECTION_OPTIONS.NORTH.code]: faLongArrowAltUp,
    [DIRECTION_OPTIONS.SOUTH.code]: faLongArrowAltDown,
};
