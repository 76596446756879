import React, { forwardRef } from "react";
import classNames from "classnames";
import "./button.less";

export type TButtonSize = "xs" | "sm" | "md" | "lg";
export type TButtonVariant = "primary" | "secondary" | "link" | "naked";

// https://github.com/typescript-cheatsheets/react/issues/167 Conditional rendering with forwardRef
export interface ICommonProps {
    id?: string;
    size?: TButtonSize;
    variant?: TButtonVariant;
    disabled?: boolean;
    className?: string;
    testid?: string;
    loading?: boolean;
    title?: string;
    ariaLabel?: string;
    startIcon?: JSX.Element;
    endIcon?: JSX.Element;
}

interface IButtonProps extends Omit<JSX.IntrinsicElements["button"], "ref">, ICommonProps {
    href?: undefined;
    type?: "button" | "submit" | "reset";
}

interface IAnchorProps extends Omit<JSX.IntrinsicElements["a"], "ref">, ICommonProps {
    href: string; // this should actually be required for TS to properly discriminate the two
}

type TProps = IButtonProps | IAnchorProps;
type TButton = {
    (props: IAnchorProps): JSX.Element;
    (props: IButtonProps): JSX.Element;
    displayName: "StlButton";
};

const isAnchor = (props: TProps): props is IAnchorProps => {
    return props.href !== undefined;
};

export const StlButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, TProps>(
    (props, ref): JSX.Element => {
        const {
            children,
            variant,
            size = "md",
            title,
            ariaLabel,
            disabled,
            className,
            testid,
            loading = false,
            startIcon,
            endIcon,
            ...restProps
        } = props;
        const commonProps = {
            className: classNames(
                "stl-btn",
                variant && `stl-btn-${variant}`,
                {
                    [`stl-btn-${size}`]: !!size,
                    disabled: restProps.href && disabled,
                    loading: loading,
                },
                className,
            ),
            title: title,
            "data-testid": testid,
            "aria-label": ariaLabel,
            disabled: !!disabled && !restProps.href,
        };

        return isAnchor(props) ? (
            <a
                {...commonProps}
                href={props.href}
                ref={ref as React.ForwardedRef<HTMLAnchorElement>}
                {...(restProps as JSX.IntrinsicElements["a"])}
            >
                {startIcon && <span className="start-icon">{startIcon}</span>}
                {children}
                {endIcon && <span className="end-icon">{endIcon}</span>}
            </a>
        ) : (
            <button
                {...commonProps}
                // eslint-disable-next-line react/button-has-type
                type={props.type || "button"}
                onClick={props.onClick}
                ref={ref as React.ForwardedRef<HTMLButtonElement>}
                data-testid={testid}
                {...(restProps as JSX.IntrinsicElements["button"])}
            >
                {startIcon && <span className="start-icon">{startIcon}</span>}
                {children}
                {endIcon && <span className="end-icon">{endIcon}</span>}
            </button>
        );
    },
) as TButton;

StlButton.displayName = "StlButton";
