import { useState, useEffect } from "react";
import { faCopy, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StlButton, StlTooltip } from "@common/components";

const INITIAL_STATE = {
    isCopied: false,
    showSuccess: false,
};
export const MeasurementValue = ({ value }: { value: string }) => {
    const [isCopied, setIsCopied] = useState(INITIAL_STATE.isCopied);
    const [showSuccess, setShowSuccess] = useState(INITIAL_STATE.showSuccess);

    const outputValue = value || "-";

    const copyValue = () => {
        setIsCopied(true);
        setShowSuccess(true);
        navigator.clipboard.writeText(outputValue);
    };

    useEffect(() => {
        if (!showSuccess) return;

        setTimeout(() => {
            setShowSuccess(INITIAL_STATE.showSuccess);
        }, 3000);
    }, [showSuccess]);

    useEffect(() => {
        setShowSuccess(INITIAL_STATE.showSuccess);
        setIsCopied(INITIAL_STATE.isCopied);
    }, [value]);

    return (
        <div className="measurment-tool-output" id="measurmentToolOutput">
            {outputValue}
            {value &&
                (isCopied ? (
                    <>
                        {showSuccess && <div className="success-copy">Copied Successfully</div>}
                        <div className="copy">
                            <FontAwesomeIcon icon={faCheck} />
                        </div>
                    </>
                ) : (
                    <StlTooltip className="copy-tooltip" placement="top" title="Copy to Clipboard">
                        <StlButton className="copy" onClick={copyValue}>
                            <FontAwesomeIcon icon={faCopy} />
                        </StlButton>
                    </StlTooltip>
                ))}
        </div>
    );
};
