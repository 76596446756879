export const THEME = {
    background: "transparent",
    fontFamily: "'Roboto', sans-serif",
    fontSize: 11,
    textColor: "#202020",
    axis: {
        textColor: "#f5f5f5",
        domain: {
            line: {
                stroke: "transparent",
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                strokeWidth: 0,
            },
            text: {
                fill: "#f5f5f5",
                fontSize: 13,
            },
        },
        legend: {
            text: {
                fill: "#f5f5f5",
                fontSize: 12,
            },
        },
    },
    grid: {
        line: {
            stroke: "#f5f5f5",
            strokeWidth: 1,
            opacity: 0.7,
        },
    },
    legends: {
        text: {
            fill: "#202020",
        },
    },
    labels: {
        text: {},
    },
    markers: {
        lineColor: "#202020",
        lineStrokeWidth: 1,
        text: {},
    },
    dots: {
        text: {},
    },
    tooltip: {
        container: {
            padding: "9px 14px",
            background: "#313131",
            color: "#A2B9EE",
            fontSize: "12px",
            borderRadius: "0",
            boxShadow: "0 1px 5px 2px rgba(0, 0, 0, 0.4)",
        },
        basic: {
            whiteSpace: "pre",
            display: "flex",
            alignItems: "center",
        },
        table: {},
        tableCell: {
            padding: "3px 5px",
        },
    },
    crosshair: {
        line: {
            stroke: "#202020",
            strokeWidth: 1,
            strokeOpacity: 0.75,
            strokeDasharray: "6 6",
        },
    },
    annotations: {
        text: {
            fontSize: 13,
            outlineWidth: 2,
            outlineColor: "#ffffff",
        },
        link: {
            stroke: "#000000",
            strokeWidth: 1,
            outlineWidth: 2,
            outlineColor: "#ffffff",
        },
        outline: {
            fill: "none",
            stroke: "#202020",
            strokeWidth: 2,
            outlineWidth: 2,
            outlineColor: "#ffffff",
        },
        symbol: {
            fill: "#202020",
            outlineWidth: 2,
            outlineColor: "#ffffff",
        },
    },
};

export const PIE_CHART_THEME = {
    background: "transparent",
    fontFamily: "'Roboto', sans-serif",
    fontSize: 11,
    textColor: "#f5f5f5",
    axis: {
        domain: {
            line: {
                stroke: "#777777",
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                stroke: "#777777",
                strokeWidth: 1,
            },
        },
    },
    grid: {
        line: {
            stroke: "#dddddd",
            strokeWidth: 1,
        },
    },
};

export const CHART_CATEGORY_ACTIVE_COLORS = ["#FFFEA6", "#71BAFF"];

export const CHART_COLORS = [
    "#F6C171",
    "#66C5CC",
    "#FCB394",
    "#DCB0F2",
    "#87C55F",
    "#93B9F3",
    "#FE88B1",
    "#C5E297",
    "#89E189",
    "#B497E7",
    "#D3B484",
    "#C0C0C0",
    "#FD9C7B",
    "#4668BF",
    "#FFDD87",
    "#88CF9C",
    "#885DA4",
    "#71BC98",
    "#FA7F69",
    "#BC3C56",
    "#ADF3F3",
    "#00BFFF",
    "#8C84E2",
    "#7D88A3",
    "#DCED8C",
    "#CD6262",
    "#B0C4DE",
    "#BB49BB",
    "#DB7093",
    "#F3EBA2",
    "#6495ED",
    "#FFFAFA",
    "#DDA0DD",
    "#A3EDA3",
    "#A68B8B",
    "#6A72B2",
    "#FFDDFF",
    "#9AE2E2",
    "#996E99",
    "#FFFACD",
    "#FFC0CB",
];

export const DEFAULT_BOTTOM_AXIS_MAX_LABEL_LINE_LENGTH = 12;
export const DEFAULT_LEFT_AXIS_MAX_LABEL_LINE_LENGTH = 7;

export const DEFAULT_CENTERED_LABEL_LABEL_SPACING = 15;
export const DEFAULT_CENTERED_LABEL_LINE_LENGTH = 24;
