import { combineReducers } from "@reduxjs/toolkit";
import { baseReducer } from "@app/analysisLightning/base/state";
import { atReducer } from "@app/analysisLightning/activeTransportation/state/activeTransportation.reducer";
import { csReducer } from "@app/analysisLightning/corridorStudies/state/corridorStudies.reducer";

export const analysisLightningReducer = combineReducers({
    base: baseReducer,
    at: atReducer,
    cs: csReducer,
});
