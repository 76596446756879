import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { StlFormError, StlButton, StlInput, StlSelect } from "@common/components";
import { BaseMap, useDraw } from "@common/components/baseMap";
import { calculateBounds } from "@common/components/baseMap/baseMap.helpers";
import {
    SUBSCRIPTION_ZONE_BAND_OPTIONS,
    SUBSCRIPTION_TIER_OPTIONS,
} from "@common/features/studyModal/common/study.constants";
import * as helpIcons from "@common/features/studyModal/common/studyHelpIcons";
import { COLORS } from "@common/constants/color.constants";
import { MAP_STYLES } from "@common/features/analysisZonesMap/analysisZonesMap.constants";
import { IErrors } from "@common/components/formError/formError";
import { AnySourceData, Layer, Map } from "mapbox-gl";
import { IStudy, TGeometry } from "@common/features/studyModal/common/study.types";
import { FeatureCollection } from "geojson";
import "./requestQuote.less";

const SOURCE_ID = "stl:study_polygon";

const NOTES_PLACEHOLDER =
    "Please provide additional details about the Analysis, if any, " +
    "that will enable us to provide you with an accurate quote. For example, " +
    "provide the name of the Analyses you want to copy over to the new Pay-Per-Use (PPU) Analysis account, " +
    "additional users you want to be added to the PPU account, etc.";

const getAlphanumericValue = (value: string) => value.replace(/[^a-z0-9]/gi, "");

type TProps = {
    study: IStudy;
    updateStudy: (value: Partial<IStudy>) => void;
    requestQuote: () => void;
    isReadOnly: boolean;
    isEnterpriseStudy: boolean;
    closeModal: () => void;
};

const MAP_CONTROLS_CONFIG = {
    controls: { measurementTool: false },
};

export const RequestQuote = ({
    study,
    updateStudy,
    requestQuote,
    isReadOnly,
    isEnterpriseStudy,
    closeModal,
}: TProps) => {
    const [map, setMap] = useState<Map | null>(null);

    const updateStudyData = (property: keyof IStudy, value: IStudy[keyof IStudy]): void =>
        updateStudy({ [property]: value });

    const onRegionChange = useCallback(
        (geojson: FeatureCollection) => {
            const { geometry, id } = geojson.features[0];
            updateStudy({ geometry: { ...geometry, id } as TGeometry });
        },
        [updateStudy],
    );

    const errors = useMemo(() => {
        return !isReadOnly && study && !study.endClient
            ? {
                  studyEndClient: { message: "Please specify study end client" },
              }
            : {};
    }, [study, isReadOnly]);

    const draw = useDraw(map, onRegionChange);

    useEffect(() => {
        if (!map || !study.geometry || !draw) return;

        const { type, coordinates, id } = study.geometry;

        if (!isReadOnly) {
            if (id && draw.get(String(id))) return;

            draw.deleteAll();
            draw.add({ type, coordinates } as TGeometry);
        } else {
            map.addSource(SOURCE_ID, {
                type: "geojson",
                data: {
                    type: "Feature",
                    geometry: {
                        type,
                        coordinates,
                    },
                },
            } as AnySourceData);

            const polygonOutlineLayer = {
                id: `${SOURCE_ID}_layer-outline`,
                type: "line",
                source: SOURCE_ID,
                paint: {
                    "line-color": COLORS.BLUE,
                    "line-width": MAP_STYLES.OUTLINE_WIDTH,
                },
            } as Layer;

            const polygonFillLayer = {
                id: `${SOURCE_ID}_layer-fill`,
                type: "fill",
                source: SOURCE_ID,
                paint: {
                    "fill-color": COLORS.BLUE,
                    "fill-opacity": MAP_STYLES.OPACITY.INACTIVE,
                },
            } as Layer;

            map.addLayer(polygonOutlineLayer);
            map.addLayer(polygonFillLayer);
        }

        const bounds = calculateBounds(type, coordinates);

        map.fitBounds(bounds, { padding: 20 });
    }, [study.geometry, isReadOnly, draw, map]);

    return (
        <div className="stl-request-quote">
            <div className="request-quote-form">
                <div className="form">
                    <div className="form-control-group header">
                        Please confirm that the information below is accurate before submitting
                        your request.
                    </div>
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="studyType">
                            Study Type
                            {isEnterpriseStudy
                                ? helpIcons.ENTERPRISE_STUDY_TYPE_HELP_ICON
                                : helpIcons.STUDY_TYPE_HELP_ICON}
                        </label>
                        <StlInput
                            id="studyType"
                            placeholder="study type"
                            value={study.studyType}
                            disabled
                        />
                    </div>
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="studyName">
                            Study Name {helpIcons.STUDY_NAME_HELP_ICON}
                        </label>
                        <StlInput
                            id="studyName"
                            placeholder="study name"
                            value={study.name}
                            onChange={(value: string) => updateStudyData("name", value)}
                            disabled={isReadOnly}
                        />
                    </div>
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="studyId">
                            Study ID (optional)
                            {helpIcons.STUDY_ID_HELP_ICON}
                        </label>
                        <StlInput
                            id="studyId"
                            placeholder="study id"
                            value={study.rfpId}
                            onChange={(value: string) => updateStudyData("rfpId", value)}
                            disabled={isReadOnly}
                        />
                    </div>
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="studyEndClient">
                            End Client *{helpIcons.END_CLIENT_HELP_ICON}
                        </label>
                        <StlInput
                            id="studyEndClient"
                            placeholder="end client"
                            value={study.endClient || ""}
                            onChange={(value: string) => updateStudyData("endClient", value)}
                            disabled={isReadOnly}
                        />
                        <StlFormError name="studyEndClient" errors={errors as IErrors} />
                    </div>
                    {isEnterpriseStudy && (
                        <>
                            <div className="form-control-group">
                                <label className="stl-label" htmlFor="studyTaskNumber">
                                    Project Task Code
                                </label>
                                <StlInput
                                    id="studyTaskNumber"
                                    placeholder="project task code"
                                    value={study.taskNumber}
                                    onChange={(value: string) =>
                                        updateStudyData("taskNumber", getAlphanumericValue(value))
                                    }
                                    disabled={isReadOnly}
                                />
                            </div>
                            <div className="form-control-group">
                                <label className="stl-label" htmlFor="studyChargeCode">
                                    Project Number
                                </label>
                                <StlInput
                                    id="studyChargeCode"
                                    placeholder="project number"
                                    value={study.chargeCode}
                                    onChange={(value: string) =>
                                        updateStudyData("chargeCode", getAlphanumericValue(value))
                                    }
                                    disabled={isReadOnly}
                                />
                            </div>
                        </>
                    )}
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="subscriptionTier">
                            Subscription Tier (Optional)
                            {helpIcons.SUBSCRIPTION_TIER_HELP_ICON}
                        </label>
                        {!isReadOnly ? (
                            <StlSelect
                                id="subscriptionTier"
                                placeholder="Select Subscription Tier"
                                options={SUBSCRIPTION_TIER_OPTIONS}
                                value={study.subscriptionTier || ""}
                                onChange={option =>
                                    updateStudyData("subscriptionTier", option?.value)
                                }
                            />
                        ) : (
                            <StlInput
                                id="subscriptionTier"
                                placeholder="Select Subscription Tier"
                                value={study.subscriptionTier || ""}
                                disabled
                            />
                        )}
                    </div>
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="subscriptionZoneBand">
                            Subscription Zone Band (Optional)
                        </label>
                        {!isReadOnly ? (
                            <StlSelect
                                id="subscriptionZoneBand"
                                placeholder="Select Subscription Zone Band"
                                options={SUBSCRIPTION_ZONE_BAND_OPTIONS}
                                value={study.subscriptionZoneBand || ""}
                                onChange={option =>
                                    updateStudyData("subscriptionZoneBand", option?.value)
                                }
                            />
                        ) : (
                            <StlInput
                                id="subscriptionZoneBand"
                                placeholder="Select Subscription Zone Band"
                                value={study.subscriptionZoneBand || ""}
                                disabled
                            />
                        )}
                    </div>
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="notes">
                            Notes (optional)
                        </label>
                        <textarea
                            id="notes"
                            className="stl-textarea"
                            rows={4}
                            placeholder={NOTES_PLACEHOLDER}
                            value={study.notes}
                            onChange={e => updateStudyData("notes", e.currentTarget.value)}
                            disabled={isReadOnly}
                        />
                    </div>
                </div>
                <div className="tab-actions">
                    <StlButton
                        id="requestQuoteCancelBtn"
                        variant="secondary"
                        onClick={closeModal}
                        startIcon={<FontAwesomeIcon icon={faTimesCircle} />}
                    >
                        <FormattedMessage id="app.cancel" defaultMessage="Cancel" />
                    </StlButton>
                    {!isReadOnly && (
                        <StlButton
                            id="requestQuoteSubmitBtn"
                            variant="primary"
                            onClick={requestQuote}
                            disabled={!study.endClient}
                            endIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                        >
                            {isEnterpriseStudy ? "Convert to Paid License" : "Request Quote"}
                        </StlButton>
                    )}
                </div>
            </div>
            <div className="map-container">
                <BaseMap onLoad={setMap} controlsConfig={MAP_CONTROLS_CONFIG} />
            </div>
        </div>
    );
};
