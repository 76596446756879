import { useEffect, useState } from "react";
import classNames from "classnames";
import mapboxGL, { Map, Popup, PopupOptions } from "mapbox-gl";
import "./useShowCustomTooltip.less";

const DEFAULT_POPUP_OFFSET = {
    top: [0, 20],
    bottom: [0, -20],
    left: [20, 0],
    right: [-20, 0],
} as PopupOptions["offset"];

const initTooltip = ({ className, offset }: PopupOptions) => {
    const _className = classNames("stl-custom-mapbox-popup", className);

    return new mapboxGL.Popup({
        closeButton: false,
        closeOnClick: false,
        className: _className,
        offset,
    });
};

const INITIAL_STATE = {
    popup: null,
};

export const useShowCustomTooltip = ({
    map,
    content,
    className,
    offset = DEFAULT_POPUP_OFFSET,
    show = true,
}: PopupOptions & {
    map: Map | null;
    show: boolean;
    content: string;
}) => {
    const [popup, setPopup] = useState<Popup | null>(INITIAL_STATE.popup);

    useEffect(() => {
        if (!map || !show) {
            if (popup) {
                popup.remove();
                setPopup(null);
            }

            return;
        }

        if (!popup) {
            const popupInstance = initTooltip({ offset, className }).addTo(map).trackPointer();

            setPopup(popupInstance);
        }
    }, [map, offset, className, show, popup]);

    useEffect(() => {
        popup?.setText(content);
    }, [popup, content]);
};
