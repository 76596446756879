import { StlButton } from "@common/components/button/button";
import { StlCheckboxGroup } from "@common/components/checkboxGroup/checkboxGroup";
import type { TColumnDef, TColumnVisibility } from "@common/components/table/table.types";
import "./columnChooser.less";

type TProps = {
    onApply: () => void;
    onCancel: () => void;
    columnDefs: Array<TColumnDef>;
    mandatoryColumns?: Array<string>;
    columnVisibility: TColumnVisibility;
    setColumnVisibility: (columnVisibility: TColumnVisibility) => void;
};

export const ColumnChooser = ({
    setColumnVisibility,
    columnVisibility,
    onApply,
    onCancel,
    columnDefs,
    mandatoryColumns = [],
}: TProps) => {
    const options = columnDefs.map(column => ({
        value: column.name,
        label: column.label,
        disabled: mandatoryColumns.includes(column.name),
        shouldAddSeparator: column.shouldAddSeparator,
    }));

    const atLeastOne = Object.values(columnVisibility).reduce((result, b) => result || b, false);
    const prompt = atLeastOne ? "Select Columns" : "Please select at least one column";

    return (
        <div className="stl-column-chooser" data-testid="columnChooser">
            <h2 className="title" aria-live="assertive">
                {prompt}
            </h2>
            <StlCheckboxGroup
                options={options}
                value={columnVisibility}
                onChange={setColumnVisibility}
            />

            <div className="actions">
                <StlButton variant="secondary" className="stl-btn-popover" onClick={onCancel}>
                    Cancel
                </StlButton>
                <StlButton
                    variant="primary"
                    className="stl-btn-popover"
                    onClick={onApply}
                    disabled={!atLeastOne}
                >
                    Apply
                </StlButton>
            </div>
        </div>
    );
};
