import { BASE_MAP_STYLES } from "@app/viz3/base/map/baseMapStyles.constants";
import { ODMF_LAYERS } from "@app/viz3/odmfVisualization/state/odmfViz.constants";

const { DISTRIBUTION_ZONES, FILTER_ZONES, ...restStyles } = BASE_MAP_STYLES;

export const MAP_STYLES = {
    ...restStyles,
    [ODMF_LAYERS.ORIGIN.code]: {
        COLOR: FILTER_ZONES.COLOR,
        OPACITY: FILTER_ZONES.OPACITY,
    },
    [ODMF_LAYERS.DESTINATION.code]: {
        COLOR: DISTRIBUTION_ZONES.COLOR,
        OPACITY: FILTER_ZONES.OPACITY,
    },
    [ODMF_LAYERS.MIDDLE_FILTER.code]: {
        COLOR: {
            HIGH: "#97C667",
            LOW: "#666666",
            HOVER: "#FFFFFF",
        },
        OPACITY: DISTRIBUTION_ZONES.OPACITY,
    },
    DISTRIBUTION_ZONES: {
        Z_INDEX: DISTRIBUTION_ZONES.Z_INDEX,
        OPACITY: DISTRIBUTION_ZONES.OPACITY,
    },
    FILTER_ZONES: {
        Z_INDEX: FILTER_ZONES.Z_INDEX,
        OPACITY: FILTER_ZONES.OPACITY,
    },
};

export type TMapStylesType = keyof typeof MAP_STYLES;
export type TMapStyles = typeof MAP_STYLES[keyof typeof MAP_STYLES];
