import { APP_THEME } from "@common/constants/application.constants";
import {
    ENHANCED_LAYERS,
    FEMA_SUB_LAYERS,
} from "@common/features/mapEnhancedLayers/state/mapEnhancedLayers.constants";
import {
    OSM_NETWORK_TYPES,
    ROAD_TYPES_LIST,
} from "@common/features/zonesManager/state/zonesManager.constants";
import { arrayIncludes } from "@common/utils/arrayIncludes";
import { merge } from "lodash-es";

import { USER_PREFERENCES_ACTIONS } from "./userPreferences.actionTypes";

export const DEFAULT_PREFERENCES_STATE = {
    columnVisibility: {},
    filtered: [],
    sorting: [],
    pagination: {
        pageSize: 25,
    },
};

// top-level keys must match reducer names, https://redux.js.org/recipes/structuring-reducers/using-combinereducers
export const defaultState = {
    application: {
        theme: "",
        country: "",
        autoRefreshEnabled: true,
        autoApplyFilter: true,
        autoDismissMessages: true,
        vectorMapSettings: {
            enableAntialiasing: false,
        },
        emailNotificationSettings: {
            enableNotification: true,
        },
        mapLayersSettings: {
            osmLayersCategories: {
                [OSM_NETWORK_TYPES.VEHICLE.id]: ROAD_TYPES_LIST.reduce((res, roadType) => {
                    if (arrayIncludes(roadType.osmNetwork, OSM_NETWORK_TYPES.VEHICLE.id)) {
                        res.push(roadType.id);
                    }

                    return res;
                }, []),
            },
            enhancedLayersCategories: {
                enabled: false,
                category: ENHANCED_LAYERS.DAC_ZONES.code,
                nriSubLayer: FEMA_SUB_LAYERS.NATIONAL_RISK_INDEX.id,
                nhlSubLayer: FEMA_SUB_LAYERS.COASTAL_FLOODING.id,
            },
        },
        isNavigationMenuOpen: true,
        isCreateAnalysisHelpOpen: false,
    },
    zonesManage: {
        columnVisibility: {
            status: true,
            zoneSetName: true,
            country: true,
            zoneCount: true,
            withinRegion: true,
            withinZoneSize: true,
            createdBy: true,
            createdDate: true,
            updatedBy: false,
            updatedDate: false,
        },
        filtered: [],
        sorting: [],
        pagination: {
            pageSize: 25,
        },
        zonesViewType: "grid",
    },
    projects: {
        columnVisibility: {
            checkboxColumn: true,
            status: true,
            projectName: true,
            createdBy: true,
            projectType: true,
            modeOfTravel: true,
            zoneCount: true,
            createdDate: true,
            updatedDate: false,
            projectId: false,
            outputType: false,
            dataPeriods: true,
            tags: true,
        },
        filtered: [],
        pagination: {
            pageSize: 25,
        },
    },
    projectFolders: {
        filtered: [],
        pagination: {
            pageSize: 24,
        },
        projectFolderId: null,
    },
    adminOrgs: {
        columnVisibility: {
            organization: true,
            currentSubscription: true,
            status: true,
        },
        pagination: {
            pageSize: 25,
        },
    },
    adminUserOrgs: {
        columnVisibility: {
            accountName: true,
            accountNickname: true,
            userAccessRoles: true,
            userCategory: true,
            effectiveDate: true,
            endDate: true,
            userStatus: true,
        },
        pagination: {
            pageSize: 25,
        },
    },
    adminZones: {
        columnVisibility: {
            orgName: true,
            orgType: true,
            studyName: true,
            analysisName: true,
            analysisType: true,
            zoneCount: true,
            createdBy: true,
            createdDate: true,
        },
        pagination: {
            pageSize: 25,
        },
    },
    autoPassedZonesModalTable: DEFAULT_PREFERENCES_STATE,
    adminAnalyses: {
        columnVisibility: {
            orgName: true,
            analysisName: true,
            analysisType: true,
            studyName: true,
            webappProjectId: true,
            createdBy: true,
            webappCreatedTime: true,
            dsStatus: true,
            dsProjectId: true,
            dsQueue: true,
            estimatedTime: true,
            createdWith: true,
            createdUsing: true,
            massiveQueue: true,
            checkboxColumn: true,
        },
        pagination: {
            pageSize: 25,
        },
    },
    adminUsers: {
        columnVisibility: {
            userName: true,
            userId: true,
            user: true,
            orgNames: true,
            orgNicknames: true,
            userStatus: true,
            upn: false,
            ssoEnabled: false,
            createdDate: true,
            lastLoginDate: true,
            crmContactId: true,
        },
        pagination: {
            pageSize: 25,
        },
    },
    adminOrgUsers: {
        columnVisibility: {
            userName: true,
            user: true,
            roles: true,
            userStatus: true,
            effectiveDate: true,
            endDate: true,
            notes: true,
            ssoEnabled: true,
            upn: true,
            crmContactId: true,
            isUAFSigned: true,
        },
        pagination: {
            pageSize: 25,
        },
    },
    adminSSODomains: {
        columnVisibility: {
            domain: true,
            provider: true,
            isRequired: true,
        },
        pagination: {
            pageSize: 25,
        },
    },
    adminMAAPIRequests: {
        columnVisibility: {
            accountName: true,
            status: true,
            requestType: true,
            isBillable: true,
            createdDate: true,
            jobId: true,
            segmentsCount: true,
            fieldsCount: true,
            errorMessage: true,
        },
        pagination: {
            pageSize: 25,
        },
    },
    adminStudies: {
        columnVisibility: {
            status: true,
            studyInformation: true,
            studyType: true,
            startDate: true,
            endDate: true,
            requestedDate: true,
            ppuCreatedDate: true,
            studyPeople: true,
            bpCode: true,
            projectCount: true,
            uniqueZoneCount: true,
            isActive: true,
        },
        pagination: {
            pageSize: 25,
        },
    },
    adminFlaggedProjects: {
        columnVisibility: {
            checkboxColumn: true,
            status: true,
            createdDate: true,
            createdBy: true,
            analysisID: true,
            analysisName: true,
            organizationType: true,
            organizationName: true,
            studyName: true,
            createdUsing: true,
            createdWith: true,
            isRuntimeReview: true,
        },
        pagination: {
            pageSize: 25,
        },
    },
    studyManagement: {
        columnVisibility: {
            status: true,
            studyInformation: true,
            startDate: true,
            endDate: true,
            createdBy: true,
            projectCount: true,
            uniqueZoneCount: true,
        },
        pagination: {
            pageSize: 25,
        },
    },
    vizAnalysesTable: {
        columnVisibility: {
            radioColumn: true,
            analysisName: true,
            analysisType: true,
            analysisId: false,
            modeOfTravel: false,
            outputType: false,
            createdBy: false,
            createdDate: true,
            updatedDate: false,
            tags: true,
        },
        filtered: [],
        pagination: {
            pageSize: 25,
        },
    },
    vizZonesTable: {
        columnVisibility: {
            checkboxColumn: true,
            zoneName: true,
            zoneId: true,
            zoneType: true,
            passThrough: true,
            zoneDirection: true,
            biDirectional: true,
        },
        filtered: [],
        pagination: {
            pageSize: 25,
        },
    },
    dashboardAnalysesTable: {
        columnVisibility: {},
        filtered: [],
        sorting: [],
        pagination: {
            pageSize: 25,
        },
    },
    dashboardAnalysesTabMapAnalysesTable: {
        columnVisibility: {
            radioColumn: true,
            analysisName: true,
            analysisType: true,
            createdBy: false,
            createdDate: true,
        },
        filtered: [],
        sorting: [],
        pagination: {
            pageSize: 25,
        },
    },
    dashboardOrgsTable: {
        columnVisibility: {},
        filtered: [],
        sorting: [],
        pagination: {
            pageSize: 25,
        },
    },
    dashboardStudiesTable: {
        columnVisibility: {},
        filtered: [],
        sorting: [],
        pagination: {
            pageSize: 25,
        },
    },
    dashboardSubscriptionsTable: {
        columnVisibility: {},
        filtered: [],
        sorting: [],
        pagination: {
            pageSize: 25,
        },
    },
    dashboardUsersTable: {
        columnVisibility: {},
        filtered: [],
        sorting: [],
        pagination: {
            pageSize: 25,
        },
    },
    dashboardUserAnalysesTable: {
        columnVisibility: {},
        filtered: [],
        sorting: [],
        pagination: {
            pageSize: 25,
        },
    },
    dashboardUserOrgsTable: {
        columnVisibility: {},
        filtered: [],
        sorting: [],
        pagination: {
            pageSize: 25,
        },
    },
    zoneSetModalTable: {
        columnVisibility: {
            zoneUUID: false,
        },
        filtered: [],
        sorting: [],
        pagination: {
            pageSize: 25,
        },
    },
    importFromAnalysisTable: {
        columnVisibility: {},
        filtered: [],
        pagination: {
            pageSize: 25,
        },
    },
    reuseAnalysesTable: DEFAULT_PREFERENCES_STATE,
    reuseZoneSetsTable: DEFAULT_PREFERENCES_STATE,
    reuseZonesTable: DEFAULT_PREFERENCES_STATE,
    reuseOZZonesTable: DEFAULT_PREFERENCES_STATE,
    reuseDZZonesTable: DEFAULT_PREFERENCES_STATE,
    reuseMFZZonesTable: DEFAULT_PREFERENCES_STATE,
    reuseAZZonesTable: DEFAULT_PREFERENCES_STATE,
    reuseCZZonesTable: DEFAULT_PREFERENCES_STATE,
    reuseIntersectionZonesTable: DEFAULT_PREFERENCES_STATE,
    sessionPreferences: {
        adminOrgs: {
            filtered: [],
        },
        adminUsers: {
            filtered: [],
        },
        adminMAAPIRequests: {
            filtered: [],
        },
        adminStudies: {
            filtered: [],
        },
        adminAnalyses: {
            filtered: [],
        },
        adminFlaggedProjects: {
            filtered: [],
        },
        adminZones: {
            filtered: [],
            selectedOrg: null,
        },
    },
};

function appOsmCategoryLayers(state, { data }) {
    return {
        ...state,
        application: {
            ...state.application,
            mapLayersSettings: {
                ...state.application.mapLayersSettings,
                osmLayersCategories: data.osmLayersCategories,
            },
        },
    };
}

function setAutoRefresh(state, { data }) {
    return {
        ...state,
        application: {
            ...state.application,
            autoRefreshEnabled: data.autoRefreshEnabled,
        },
    };
}

function setAutoApplyFilter(state, { data }) {
    return {
        ...state,
        application: {
            ...state.application,
            autoApplyFilter: data.autoApplyFilter,
        },
    };
}

function setAutoDismissMessages(state, { data }) {
    return {
        ...state,
        application: {
            ...state.application,
            autoDismissMessages: data.autoDismissMessages,
        },
    };
}

function setEmailNotificationSettings(state, { data }) {
    return {
        ...state,
        application: {
            ...state.application,
            emailNotificationSettings: data.emailNotificationSettings,
        },
    };
}

function setVectorMapSettings(state, { data }) {
    return {
        ...state,
        application: {
            ...state.application,
            vectorMapSettings: data.vectorMapSettings,
        },
    };
}

function setFiltered(state, { data }) {
    const stateSection = state[data.section];
    /* Start at first page when filter is changed. */
    const paginationReset = { ...stateSection.pagination, pageIndex: 0 };

    return {
        ...state,
        [data.section]: {
            ...stateSection,
            ...paginationReset,
            filtered: data.filtered,
        },
    };
}

function setSorting(state, { data }) {
    const stateSection = state[data.section];
    return {
        ...state,
        [data.section]: {
            ...stateSection,
            sorting: data.sorting,
        },
    };
}

function setPagination(state, { data }) {
    const stateSection = state[data.section];
    return {
        ...state,
        [data.section]: {
            ...stateSection,
            pagination: data.pagination,
        },
    };
}

function setColumnVisibility(state, { data }) {
    const stateSection = state[data.section];
    return {
        ...state,
        [data.section]: {
            ...stateSection,
            columnVisibility: data.columnVisibility,
        },
    };
}

function getMergedPreferences(savedPreferences, defaultPreferences) {
    if (!savedPreferences) {
        return { ...defaultPreferences };
    }

    return {
        ...defaultPreferences,
        ...savedPreferences,
        columnVisibility: {
            ...defaultPreferences.columnVisibility,
            ...savedPreferences.columnVisibility,
        },
        pagination: {
            ...defaultPreferences.pagination,
            ...savedPreferences.pagination,
        },
    };
}

function getMergedApplicationPreferences(savedPreferences, defaultPreferences) {
    if (!savedPreferences) {
        return { ...defaultPreferences };
    }

    return merge({}, defaultPreferences, savedPreferences);
}

function initializeUserPreferences(state, { data }) {
    const result = { ...(data.userPreferences || {}) };

    result.application = getMergedApplicationPreferences(
        result.application,
        defaultState.application,
    );
    result.zonesManage = getMergedPreferences(result.zonesManage, defaultState.zonesManage);
    result.projects = getMergedPreferences(result.projects, defaultState.projects);
    result.projectFolders = getMergedPreferences(
        result.projectFolders,
        defaultState.projectFolders,
    );
    result.adminOrgs = getMergedPreferences(result.adminOrgs, defaultState.adminOrgs);
    result.adminOrgUsers = getMergedPreferences(result.adminOrgUsers, defaultState.adminOrgUsers);
    result.adminZones = getMergedPreferences(result.adminZones, defaultState.adminZones);
    result.autoPassedZonesModalTable = getMergedPreferences(
        result.autoPassedZonesModalTable,
        defaultState.autoPassedZonesModalTable,
    );
    result.adminUsers = getMergedPreferences(result.adminUsers, defaultState.adminUsers);
    result.adminUserOrgs = getMergedPreferences(result.adminUserOrgs, defaultState.adminUserOrgs);
    result.adminSSODomains = getMergedPreferences(
        result.adminSSODomains,
        defaultState.adminSSODomains,
    );
    result.adminMAAPIRequests = getMergedPreferences(
        result.adminMAAPIRequests,
        defaultState.adminMAAPIRequests,
    );
    result.adminStudies = getMergedPreferences(result.adminStudies, defaultState.adminStudies);
    result.adminAnalyses = getMergedPreferences(result.adminAnalyses, defaultState.adminAnalyses);
    result.adminFlaggedProjects = getMergedPreferences(
        result.adminFlaggedProjects,
        defaultState.adminFlaggedProjects,
    );
    result.studyManagement = getMergedPreferences(
        result.studyManagement,
        defaultState.studyManagement,
    );
    result.vizAnalysesTable = getMergedPreferences(
        result.vizAnalysesTable,
        defaultState.vizAnalysesTable,
    );
    result.vizZonesTable = getMergedPreferences(result.vizZonesTable, defaultState.vizZonesTable);
    result.dashboardAnalysesTable = getMergedPreferences(
        result.dashboardAnalysesTable,
        defaultState.dashboardAnalysesTable,
    );
    result.dashboardOrgsTable = getMergedPreferences(
        result.dashboardOrgsTable,
        defaultState.dashboardOrgsTable,
    );
    result.dashboardStudiesTable = getMergedPreferences(
        result.dashboardStudiesTable,
        defaultState.dashboardStudiesTable,
    );
    result.dashboardSubscriptionsTable = getMergedPreferences(
        result.dashboardSubscriptionsTable,
        defaultState.dashboardSubscriptionsTable,
    );
    result.dashboardUsersTable = getMergedPreferences(
        result.dashboardUsersTable,
        defaultState.dashboardUsersTable,
    );
    result.dashboardUserAnalysesTable = getMergedPreferences(
        result.dashboardUserAnalysesTable,
        defaultState.dashboardUserAnalysesTable,
    );
    result.dashboardAnalysesTabMapAnalysesTable = getMergedPreferences(
        result.dashboardAnalysesTabMapAnalysesTable,
        defaultState.dashboardAnalysesTabMapAnalysesTable,
    );
    result.dashboardUserOrgsTable = getMergedPreferences(
        result.dashboardUserOrgsTable,
        defaultState.dashboardUserOrgsTable,
    );
    result.zoneSetModalTable = getMergedPreferences(
        result.zoneSetModalTable,
        defaultState.zoneSetModalTable,
    );
    result.importFromAnalysisTable = getMergedPreferences(
        result.importFromAnalysisTable,
        defaultState.importFromAnalysisTable,
    );
    result.reuseZoneSetsTable = getMergedPreferences(
        result.reuseZoneSetsTable,
        defaultState.reuseZoneSetsTable,
    );
    result.reuseZonesTable = getMergedPreferences(
        result.reuseZonesTable,
        defaultState.reuseZonesTable,
    );
    result.reuseAnalysesTable = getMergedPreferences(
        result.reuseAnalysesTable,
        defaultState.reuseAnalysesTable,
    );
    result.reuseOZZonesTable = getMergedPreferences(
        result.reuseOZZonesTable,
        defaultState.reuseOZZonesTable,
    );
    result.reuseDZZonesTable = getMergedPreferences(
        result.reuseDZZonesTable,
        defaultState.reuseDZZonesTable,
    );
    result.reuseMFZZonesTable = getMergedPreferences(
        result.reuseMFZZonesTable,
        defaultState.reuseMFZZonesTable,
    );
    result.reuseAZZonesTable = getMergedPreferences(
        result.reuseAZZonesTable,
        defaultState.reuseAZZonesTable,
    );
    result.reuseCZZonesTable = getMergedPreferences(
        result.reuseCZZonesTable,
        defaultState.reuseCZZonesTable,
    );
    result.reuseIntersectionZonesTable = getMergedPreferences(
        result.reuseIntersectionZonesTable,
        defaultState.reuseIntersectionZonesTable,
    );
    result.sessionPreferences = defaultState.sessionPreferences;

    return result;
}

function setTheme(state, { data }) {
    return {
        ...state,
        application: {
            ...state.application,
            theme: data.theme,
        },
    };
}

function changeTheme(state) {
    const theme = state.application.theme === APP_THEME.DARK ? APP_THEME.LIGHT : APP_THEME.DARK;

    return {
        ...state,
        application: {
            ...state.application,
            theme,
        },
    };
}

function setMapStyle(state, { data }) {
    return {
        ...state,
        application: {
            ...state.application,
            mapStyle: data.mapStyle,
        },
    };
}

function setCountry(state, { data }) {
    return {
        ...state,
        application: {
            ...state.application,
            country: data.country,
        },
    };
}

function setWeekdayType(state, { data }) {
    return {
        ...state,
        application: {
            ...state.application,
            weekdayType: data.weekdayType,
        },
    };
}

function setCreateAnalysisHelpOpen(state, { data }) {
    return {
        ...state,
        application: {
            ...state.application,
            isCreateAnalysisHelpOpen: data.isOpen,
        },
    };
}

function setNavOpen(state, { data }) {
    return {
        ...state,
        application: {
            ...state.application,
            isNavigationMenuOpen: data.isOpen,
        },
    };
}

function patchUserPreferences(state, { data }) {
    const { update, section } = data;
    const stateSection = state[section];

    return {
        ...state,
        [section]: {
            ...stateSection,
            ...update,
        },
    };
}

function patchSessionUserPreferences(state, { data }) {
    const { update, section } = data;
    const stateSection = state.sessionPreferences[section];

    return {
        ...state,
        sessionPreferences: {
            ...state.sessionPreferences,
            [section]: {
                ...stateSection,
                ...update,
            },
        },
    };
}

function patchMapLayersSettings(state, { data }) {
    const { update, section } = data;
    const stateSection = state.application.mapLayersSettings[section];

    return {
        ...state,
        application: {
            ...state.application,
            mapLayersSettings: {
                ...state.application.mapLayersSettings,
                [section]: {
                    ...stateSection,
                    ...update,
                },
            },
        },
    };
}

const ACTION_HANDLERS = {
    [USER_PREFERENCES_ACTIONS.INITIALIZE_USER_PREFERENCES]: initializeUserPreferences,
    [USER_PREFERENCES_ACTIONS.APP_OSM_LAYERS_CATEGORIES]: appOsmCategoryLayers,
    [USER_PREFERENCES_ACTIONS.SET_AUTO_REFRESH]: setAutoRefresh,
    [USER_PREFERENCES_ACTIONS.SET_AUTO_APPLY_FILTER]: setAutoApplyFilter,
    [USER_PREFERENCES_ACTIONS.SET_AUTO_DISMISS_MESSAGES]: setAutoDismissMessages,
    [USER_PREFERENCES_ACTIONS.SET_EMAIL_NOTIFICATION_SETTINGS]: setEmailNotificationSettings,
    [USER_PREFERENCES_ACTIONS.SET_VECTOR_MAP_SETTINGS]: setVectorMapSettings,
    [USER_PREFERENCES_ACTIONS.SET_PAGINATION]: setPagination,
    [USER_PREFERENCES_ACTIONS.SET_FILTERED]: setFiltered,
    [USER_PREFERENCES_ACTIONS.SET_SORTING]: setSorting,
    [USER_PREFERENCES_ACTIONS.SET_COLUMN_VISIBILITY]: setColumnVisibility,
    [USER_PREFERENCES_ACTIONS.SET_THEME]: setTheme,
    [USER_PREFERENCES_ACTIONS.CHANGE_THEME]: changeTheme,
    [USER_PREFERENCES_ACTIONS.SET_MAP_STYLE]: setMapStyle,
    [USER_PREFERENCES_ACTIONS.SET_COUNTRY]: setCountry,
    [USER_PREFERENCES_ACTIONS.SET_WEEKDAY_TYPE]: setWeekdayType,
    [USER_PREFERENCES_ACTIONS.SET_CREATE_ANALYSIS_HELP_OPEN]: setCreateAnalysisHelpOpen,
    [USER_PREFERENCES_ACTIONS.SET_NAV_OPEN]: setNavOpen,
    [USER_PREFERENCES_ACTIONS.PATCH_USER_PREFERENCES]: patchUserPreferences,
    [USER_PREFERENCES_ACTIONS.PATCH_SESSION_USER_PREFERENCES]: patchSessionUserPreferences,
    [USER_PREFERENCES_ACTIONS.PATCH_MAP_LAYERS_SETTINGS]: patchMapLayersSettings,
};

const userPreferences = (state = defaultState, action) => {
    if (ACTION_HANDLERS[action.type]) {
        return ACTION_HANDLERS[action.type](state, action);
    }

    return state;
};

export default userPreferences;
