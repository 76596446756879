import { COMMERCIAL_TYPES } from "@app/analysis/state/analysisConfiguration.constants";
import { CHART_COLORS } from "@common/components/charts/charts.constants";

export const VIZ_FILTERS_SECTION_NODE_ID = "stl-viz-filters-section";
export const VIZ_WIDGETS_TOOLBAR_NODE_ID = "stl-viz-widgets-toolbar";

/*
    `zonesAccessor` is used to find zones by its set type. For example, if we want to collect
    all origin zones for visualization, we need to get origin zone sets first using `oz_sets`
    property of selected analysis.
*/
export const VIZ_ZONE_TYPES = {
    ORIGIN: {
        typeName: "origin",
        filterName: "originZones",
        displayName: "Origin",
        zonesAccessor: "oz_zones",
        zoneIdAttribute: "zone_id",
    },
    ZONE: {
        typeName: "zone",
        filterName: "zones",
        displayName: "Zone",
        zonesAccessor: "oz_zones",
        zoneIdAttribute: "zone_id",
    },
    DESTINATION: {
        typeName: "destination",
        filterName: "destinationZones",
        displayName: "Destination",
        zonesAccessor: "dz_zones",
        zoneIdAttribute: "zone_id",
    },
    MIDDLE_FILTER: {
        typeName: "middle_filter",
        filterName: "middleFilterZones",
        displayName: "Middle Filter",
        zonesAccessor: "mfz_zones",
        zoneIdAttribute: "zone_id",
    },
    PRESET_GEO: {
        typeName: "preset_geo",
        filterName: "presetGeoZones",
        displayName: "Preset Geo",
        geomType: "P", // Polygon
        zonesAccessor: "preset_geos",
        zoneIdAttribute: "preset_geo_id",
        zoneNameAttribute: "preset_geo_name",
    },
    HOME_GRIDS: {
        typeName: "home_grid",
        filterName: "homeGridZones",
        displayName: "Home Grids",
        geomType: "P", // Polygon
        zonesAccessor: "home_grid_ids",
        zoneIdAttribute: "grid_id",
    },
    HOME_ZIP: {
        typeName: "home_zip",
        filterName: "homeZipZones",
        displayName: "Home Zip Codes",
        geomType: "P", // Polygon
        zonesAccessor: "home_zip_ids",
        zoneIdAttribute: "zip_id",
    },
    HOME_METRO: {
        typeName: "home_metro",
        filterName: "homeMetroZones",
        displayName: "Home Metro Areas",
        geomType: "P", // Polygon
        zonesAccessor: "home_metro_ids",
        zoneIdAttribute: "metro_id",
    },
    HOME_STATE: {
        typeName: "home_state",
        filterName: "homeStateZones",
        displayName: "Home States",
        geomType: "P", // Polygon
        zonesAccessor: "home_state_ids",
        zoneIdAttribute: "state_id",
    },
    WORK_GRIDS: {
        typeName: "work_grid",
        filterName: "workGridZones",
        displayName: "Work Grids",
        geomType: "P", // Polygon
        zonesAccessor: "work_grid_ids",
        zoneIdAttribute: "grid_id",
    },
    SEGMENTS: {
        typeName: "route_segment",
        filterName: "routeSegmentZones",
        displayName: "Route Segment",
        geomType: "P", // Polygon
        zonesAccessor: "osm_segments",
        zoneIdAttribute: "route_id",
        zoneNameAttribute: "route_name",
    },
    INTERSECTIONS: {
        typeName: "intersection_zones",
        filterName: "intersectionZones",
        displayName: "Intersection",
        zonesAccessor: "intersection_zones",
        zoneNameAttribute: "name",
        zoneIdAttribute: "zone_id",
    },
};

export const VIZ_ZONE_TYPES_LIST = Object.values(VIZ_ZONE_TYPES);

export type IVizZoneTypeName = keyof typeof VIZ_ZONE_TYPES;
export type IVizZoneType = typeof VIZ_ZONE_TYPES[keyof typeof VIZ_ZONE_TYPES];

export const DAY_PARTS = {
    ALL_DAY: {
        label: "All Day (12am-12am)",
        value: 800002359,
    },
};

export const DAY_TYPES = {
    ALL_DAYS: {
        label: "All Days (M-Su)",
        value: 17,
    },
};

export const TIME_FILTERS = {
    DAY_PARTS: {
        filterName: "day_parts",
        defaultOption: DAY_PARTS.ALL_DAY,
    },
    DAY_TYPES: {
        filterName: "day_types",
        defaultOption: DAY_TYPES.ALL_DAYS,
    },
} as const;

export type TTimeFilter = typeof TIME_FILTERS[keyof typeof TIME_FILTERS];

export const GROUP_MODE_FILTER = {
    filterName: "group_mode",
    defaultValue: "day_parts",
    allowedValues: ["day_parts", "individual_hours"],
};

export const MEASUREMENT_TYPES = {
    PERCENT: {
        code: "percentage",
        label: "Percentage",
        measurementLabel: "Share",
    },
    VOLUME: {
        code: "volume",
        label: "Volume",
        measurementLabel: "Volume",
    },
    WEIGHT_CLASS_PERCENT: {
        code: "weight_class_percentage",
        label: "Weight Class Percentage",
        measurementLabel: "Share",
    },
    TRIP_PROPORTION: {
        code: "trip_proportion",
        label: "Trip Proportion",
        measurementLabel: "Share",
    },
};

export const MEASUREMENT_TYPES_LIST = Object.values(MEASUREMENT_TYPES);
export type TMeasurementTypeName = keyof typeof MEASUREMENT_TYPES;
export type TMeasurementType = typeof MEASUREMENT_TYPES[keyof typeof MEASUREMENT_TYPES];

export const TRUCK_INDEX_OUTPUT_TYPE_NAME = "StL Truck Index";
export const TRUCK_VOLUME_OUTPUT_TYPE_NAME = "StL Truck Volume";
export const TRUCK_CALIBRATED_INDEX_OUTPUT_TYPE_NAME = "StL Calibrated Truck Index";

export const DEFAULT_TRIM_RANGE = [0, 100];
export const DEFAULT_HEIGHT_SCALE = 1;
export const DEFAULT_MAX_HEIGHT_SCALE = 5;

export const VIZ_COLORS = {
    CHART_SELECTED_ZONES_COLOR: "#ffda6a",
    CHART_UNSELECTED_ZONES_COLOR: "#707070",
    CHART_HOVERED_ZONE_COLOR: "#ffffff",
};

export const VIZ_MAP_LAYERS = {
    BASE: {
        code: "base",
        label: "Base",
        hasConfiguration: true,
        isCondensedDisplay: false,
    },
    UNSELECTED: {
        code: "unselected",
        label: "Unselected Zones",
        hasConfiguration: false,
        isCondensedDisplay: false,
    },
    LABEL: {
        code: "label",
        label: "Labels",
        hasConfiguration: false,
        isCondensedDisplay: true,
    },
    LEGEND: {
        code: "legend",
        label: "Legend",
        hasConfiguration: false,
        isCondensedDisplay: true,
    },
} as const;

export const VIZ_ZONES_DISPLAY_MODES = {
    AUTO: { id: "auto", name: "Auto" },
    POINTS: { id: "points", name: "Points" },
    GEOMETRIES: { id: "geometries", name: "Geometries" },
};
export const VIZ_ZONES_DISPLAY_MODES_LIST = Object.values(VIZ_ZONES_DISPLAY_MODES);
export type TVizZonesDisplayModeKey = keyof typeof VIZ_ZONES_DISPLAY_MODES;
export type TVizZonesDisplayMode =
    typeof VIZ_ZONES_DISPLAY_MODES[keyof typeof VIZ_ZONES_DISPLAY_MODES];

export const VIZ_ZA_HWL_DISPLAY_MODES = {
    GRIDS: { id: "grids", name: "Grids" },
    STANDARD_GEOGRAPHIES: { id: "standard_geographies", name: "Standard Geographies" },
};
export const VIZ_ZA_HWL_DISPLAY_MODES_LIST = Object.values(VIZ_ZA_HWL_DISPLAY_MODES);

export const VIZ_ZA_HWL_STANDARD_GEOMETRIES = {
    ZIP_CODES: { label: "Zip Codes", value: "home_zip" },
    MSA: { label: "Metro Areas", value: "home_metro" },
    STATE: { label: "States", value: "home_state" },
} as const;

export const VIZ_ZA_HWL_STANDARD_GEOMETRIES_LIST = Object.values(VIZ_ZA_HWL_STANDARD_GEOMETRIES);

export const MAX_ZONES_FOR_SELECT = 10;
export const MAX_AVAILABLE_ZONES_FOR_SELECT = 100;
export const MAX_SELECTED_ZONE_COUNT = 200;

export const ANALYSIS_CONFIG_TYPES = {
    MAP_STYLES: "viz3_map_styles",
};

export const BASE_VIZ_DEFAULTS = {
    filters: {
        [VIZ_ZONE_TYPES.ORIGIN.filterName]: [],
        [VIZ_ZONE_TYPES.DESTINATION.filterName]: [],
        [VIZ_ZONE_TYPES.MIDDLE_FILTER.filterName]: [],
        [TIME_FILTERS.DAY_TYPES.filterName]: [TIME_FILTERS.DAY_TYPES.defaultOption],
        [TIME_FILTERS.DAY_PARTS.filterName]: [TIME_FILTERS.DAY_PARTS.defaultOption],
    },
    visualization: {
        dataTrimRange: DEFAULT_TRIM_RANGE,
        mapRotation: 0,
        mapPitch: 0,
        heightScale: DEFAULT_HEIGHT_SCALE,
        isAutoHeightScale: true,
        zonesDisplayMode: VIZ_ZONES_DISPLAY_MODES.AUTO.id,
        geometryType: VIZ_ZA_HWL_DISPLAY_MODES.GRIDS.id,
        standardGeometryType: VIZ_ZA_HWL_STANDARD_GEOMETRIES.ZIP_CODES,
    },
};

export const PROMOTE_ID_PROPERTY_NAME = "zone_id_str";

export const ZONE_ID_PREFIX = "zone_id_";

export const VIEW_STATES = {
    INITIAL: "INITIAL",
    SELECT_ANALYSIS: "SELECT_ANALYSIS",
    CHECK_SIZE_CONSTRAINT: "CHECK_SIZE_CONSTRAINT",
    CHECK_IF_DATA_READY: "CHECK_IF_DATA_READY",
    VISUALIZE: "VISUALIZE",
} as const;

export type TViewStates = typeof VIEW_STATES[keyof typeof VIEW_STATES];

export const VIZ_DATA_STATUS_CODES = {
    LOADING: "loading",
    START: "start",
    DOWNLOAD_DATA: "download_data",
    LONG_DATA_LOADING: "long_data_loading",
    IMPORT_DATA: "import_data",
    WAITING_FOR_HOST: "waiting_for_host",
    READY: "ready",
    ERROR: "error",
    BUSY: "busy",
    RETRY: "retry", // could be set only on FE, when visualization was not ready in 2 minutes
} as const;

export type TVizDataStatusCode = typeof VIZ_DATA_STATUS_CODES[keyof typeof VIZ_DATA_STATUS_CODES];

export type TVizDataStatus = {
    statusCode: TVizDataStatusCode;
    description?: string;
    estimatedLoadTime?: number;
};

export const LARGE_ANALYSES_MIN_INTERACTIVE_ZOOM = 7;

export const READ_ONLY_ZONE_TYPES = [
    "home_grid",
    "home_zip",
    "home_metro",
    "home_state",
    "work_grid",
];

export const ATTRIBUTE_TYPES = {
    TRIP_ATTRIBUTES: "trip_attributes",
    TRAVELER_ATTRIBUTES: "traveler_attributes",
} as const;
export type TAttributeType = typeof ATTRIBUTE_TYPES[keyof typeof ATTRIBUTE_TYPES];

export const WEIGHT_CLASS_TYPES = {
    LIGHT_TRUCKS: {
        ...COMMERCIAL_TYPES.LIGHT_TRUCKS,
        metricAccessor: "ld_metric",
        metricDistributionAttribute: "light", // metric attribute name in Zone Distribution chart response
        color: CHART_COLORS[2],
    },
    MEDIUM_TRUCKS: {
        ...COMMERCIAL_TYPES.MEDIUM_TRUCKS,
        metricAccessor: "md_metric",
        metricDistributionAttribute: "medium",
        color: CHART_COLORS[1],
    },
    HEAVY_TRUCKS: {
        ...COMMERCIAL_TYPES.HEAVY_TRUCKS,
        metricAccessor: "hd_metric",
        metricDistributionAttribute: "heavy",
        color: CHART_COLORS[0],
    },
} as const;

export const WEIGHT_CLASS_TYPES_LIST = Object.values(WEIGHT_CLASS_TYPES);

export type TWeightClassTypesName = keyof typeof WEIGHT_CLASS_TYPES;
export type TWeightClassType = typeof WEIGHT_CLASS_TYPES[keyof typeof WEIGHT_CLASS_TYPES];
