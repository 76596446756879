import { MAP_STYLES } from "@app/viz3/trodVisualization/map/mapStyles.constants";
import { TROD_LAYERS } from "@app/viz3/trodVisualization/state/trodViz.constants";

export const TROD_VIZ_INITIAL_STATE = {
    map: {
        clickedZoneId: null,
        layerConfigurations: {
            [TROD_LAYERS.ORIGIN.code]: {
                colors: {
                    high: MAP_STYLES.FILTER_ZONES.COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES.FILTER_ZONES.COLOR.LOW,
                    hover: MAP_STYLES.FILTER_ZONES.COLOR.HOVER,
                },
                opacity: MAP_STYLES.FILTER_ZONES.OPACITY,
            },
            [TROD_LAYERS.DESTINATION.code]: {
                colors: {
                    high: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.LOW,
                    hover: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HOVER,
                },
                opacity: MAP_STYLES.DISTRIBUTION_ZONES.OPACITY,
            },
            [TROD_LAYERS.ROUTE_SEGMENT.code]: {
                colors: {
                    high: MAP_STYLES[TROD_LAYERS.ROUTE_SEGMENT.code].COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES[TROD_LAYERS.ROUTE_SEGMENT.code].COLOR.LOW,
                    hover: MAP_STYLES[TROD_LAYERS.ROUTE_SEGMENT.code].COLOR.HOVER,
                },
                opacity: MAP_STYLES[TROD_LAYERS.ROUTE_SEGMENT.code].OPACITY,
            },
        },
        staticGeometries: {
            line: null,
            point: null,
        },
    },
    distributionLayers: {
        line: null,
        point: null,
    },
    preSelectedZones: [],
    segmentsForComparison: [],
};
