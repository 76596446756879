import { StlButton, StlSelect } from "@common/components";
import { useDidMount } from "@common/hooks/useDidMount";
import {
    AREA_MEASUREMENT_OPTIONS,
    AREA_MEASUREMENT_OPTIONS_LIST,
    TAreaMeasurementUnits,
} from "../constants";
import { MeasurementValue } from "./measurementValue";

export const AreaMeasurementTool = ({
    outputValue,
    measurementMetric,
    setMeasurementMetric,
    resetMeasuring,
    cancelMeasuring,
}: {
    outputValue: string;
    measurementMetric: TAreaMeasurementUnits | null;
    setMeasurementMetric: (value: TAreaMeasurementUnits | null) => void;
    resetMeasuring: () => void;
    cancelMeasuring: () => void;
}) => {
    useDidMount(() => setMeasurementMetric(AREA_MEASUREMENT_OPTIONS.feet));

    return (
        <>
            <label className="measurment-tool-label" htmlFor="measurmentToolMetric">
                Metric
            </label>
            <StlSelect
                id="measurmentToolMetric"
                height={30}
                options={AREA_MEASUREMENT_OPTIONS_LIST}
                value={measurementMetric}
                onChange={value => setMeasurementMetric(value)}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
            />
            <label className="measurment-tool-label" htmlFor="measurmentToolOutput">
                Output
            </label>
            <MeasurementValue value={outputValue} />
            <div className="measurment-tool-actions">
                <StlButton onClick={resetMeasuring} variant="secondary">
                    Clear
                </StlButton>
                <StlButton onClick={cancelMeasuring} variant="primary">
                    Finish
                </StlButton>
            </div>
        </>
    );
};
