import { useCallback, useMemo, useRef, useState } from "react";
import { cloneDeep, isEqual } from "lodash-es";
import { getAvailableOSMLayers } from "@app/store/currentUser/currentUser.selector";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { appSetOSMLayersWithCategories } from "@app/store/userPreferences/userPreferences.actions";
import { getOsmLayersCategories } from "@app/store/userPreferences/userPreferences.selector";
import {
    OSM_LAYERS,
    TMapLayersLocalState,
    TOSMLayer,
} from "@common/components/baseMap/mapLayers/mapLayers.constants";
import { getNestedOsmCheckboxesData } from "@common/features/zonesManager/components/spotCounter/spotCounter.helpers";
import { SERVICE_LAYER_ID } from "@common/features/zonesManager/state/zonesManager.constants";
import { useDidMount } from "@common/hooks/useDidMount";

export interface IChildCheckboxData {
    label: string;
    value: string;
    infoIconContent: string | null;
}

export interface IOsmCategoryValuesAndData {
    value: string;
    checked: boolean;
    expanded: boolean;
    labelData: Pick<TOSMLayer, "display" | "legend">;
    nestedCheckboxesData: (IChildCheckboxData & { checked: boolean; disabled: boolean })[];
}

const OSM_PARENT_TYPE_PREFIXES_MAP = Object.fromEntries(
    Object.entries(OSM_LAYERS).map(([key, value]) => [key, value.code]),
);

const getOsmLayersWithCategories = (data: IOsmCategoryValuesAndData[]) => {
    return data.reduce((res: Record<string, string[]>, parentOsm) => {
        if (!parentOsm.checked) {
            return res;
        }

        const osmParentTypePrefix = OSM_PARENT_TYPE_PREFIXES_MAP[parentOsm.value];

        res[osmParentTypePrefix] = parentOsm.nestedCheckboxesData.reduce(
            (subRes: string[], childOsm) => {
                if (childOsm.checked && !childOsm.disabled) {
                    const childOsmTypeRemovedPrefix = childOsm.value.replace(
                        `${osmParentTypePrefix}_`,
                        "",
                    );

                    subRes.push(childOsmTypeRemovedPrefix);
                }

                return subRes;
            },
            [],
        );

        return res;
    }, {});
};

interface IAvailableOsmLayersData {
    value: string;
    labelData: Pick<TOSMLayer, "display" | "legend">;
    nestedCheckboxesData: IChildCheckboxData[];
}

export const useOsmCheckboxValues = ({
    options,
    isNPAnalysis = false,
    disabledOptions,
    mapLayersLocalState,
    updateMapLayersState,
    defaultSelectedIds,
}: {
    options?: string[];
    isNPAnalysis?: boolean;
    disabledOptions?: string[];
    mapLayersLocalState?: TMapLayersLocalState | null;
    updateMapLayersState?: (value: Partial<TMapLayersLocalState>) => void;
    defaultSelectedIds?: Record<string, string[]> | null;
}) => {
    const [values, setValues] = useState<IOsmCategoryValuesAndData[]>([]);

    const defaultValues = useRef<IOsmCategoryValuesAndData[]>([]);

    const availableOSMLayers = useAppSelector(getAvailableOSMLayers);
    const persistedOsmLayersWithCategories = useAppSelector(getOsmLayersCategories);

    const dispatch = useAppDispatch();

    const computeOsmLayersSelection = useCallback(
        (
            availableOsmLayersData: IAvailableOsmLayersData[],
            selectedIdsSet: Set<string>,
            selectedOsmLayerIdsFromMapLayersLocalStateSet?: Set<string>,
        ) => {
            return availableOsmLayersData.map(category => {
                const parentCategoryKey = OSM_PARENT_TYPE_PREFIXES_MAP[category.value];

                // Determine checked state based on presence of mapLayersLocalState prop:
                // if we have it -> check availableOsmLayersData.length for cases like np_spot, where ui has only one allowed osm layer
                // else -> check selectedIdsSet.
                const isParentChecked = mapLayersLocalState
                    ? availableOsmLayersData.length === 1
                    : selectedIdsSet.has(parentCategoryKey);

                const enrichedNestedCheckboxesValues = category.nestedCheckboxesData.map(
                    childOsmData => {
                        const isServiceOsmCheckbox = childOsmData.value === SERVICE_LAYER_ID;
                        const isDisabled = !!disabledOptions?.includes(childOsmData.value);

                        // Determine checked state based on presence of mapLayersLocalState prop
                        const checked = mapLayersLocalState
                            ? !!selectedOsmLayerIdsFromMapLayersLocalStateSet?.has(
                                  childOsmData.value,
                              ) && !isServiceOsmCheckbox
                            : selectedIdsSet.has(childOsmData.value);

                        return {
                            ...childOsmData,
                            checked: !isDisabled && checked,
                            disabled: !isParentChecked || isDisabled,
                        };
                    },
                );

                return {
                    ...category,
                    checked: isParentChecked,
                    expanded: isParentChecked,
                    nestedCheckboxesData: enrichedNestedCheckboxesValues,
                };
            });
        },
        [disabledOptions, mapLayersLocalState],
    );

    useDidMount(() => {
        const availableOsmLayersData = availableOSMLayers.reduce<IAvailableOsmLayersData[]>(
            (res, option) => {
                if (options?.length && !options.includes(option.code)) {
                    return res;
                }

                res.push({
                    value: option.layerCode,
                    labelData: {
                        legend: option.legend,
                        display: option.display,
                    },
                    nestedCheckboxesData: getNestedOsmCheckboxesData(option.code, isNPAnalysis),
                });

                return res;
            },
            [],
        );

        const persistentlySelectedIdsSet = new Set<string>(
            Object.entries(
                (persistedOsmLayersWithCategories ?? {}) as Record<string, string[]>,
            ).flatMap(([parentId, childIds]) => [
                parentId,
                ...childIds.map(id => `${parentId}_${id}`),
            ]),
        );

        const selectedOsmLayerIdsFromMapLayersLocalStateSet = new Set<string>(
            Object.entries(
                (mapLayersLocalState?.osmLayersCategories ??
                    {}) as TMapLayersLocalState["osmLayersCategories"],
            ).flatMap(([parentId, childIds]) => [
                parentId,
                ...childIds.map(id => `${parentId}_${id}`),
            ]),
        );

        const startValue = computeOsmLayersSelection(
            availableOsmLayersData,
            persistentlySelectedIdsSet,
            selectedOsmLayerIdsFromMapLayersLocalStateSet,
        );
        setValues(startValue);

        if (!defaultSelectedIds) {
            defaultValues.current = startValue;
            return;
        }

        const defaultSelectedIdsSet = new Set<string>(
            Object.entries((defaultSelectedIds ?? {}) as Record<string, string[]>).flatMap(
                ([parentId, childIds]) => [parentId, ...childIds.map(id => `${parentId}_${id}`)],
            ),
        );

        defaultValues.current = computeOsmLayersSelection(
            availableOsmLayersData,
            defaultSelectedIdsSet,
        );
    });

    const osmLayersWithCategories = useMemo(() => {
        return mapLayersLocalState
            ? mapLayersLocalState.osmLayersCategories
            : persistedOsmLayersWithCategories;
    }, [mapLayersLocalState, persistedOsmLayersWithCategories]);

    const resetToDefault = useCallback(() => {
        setValues(defaultValues.current);
    }, []);

    const onUpdateLayersSelection = useCallback(
        updatedValues => {
            if (!updatedValues.length) return;

            const newOsmLayersWithCategories = getOsmLayersWithCategories(updatedValues);

            if (isEqual(osmLayersWithCategories, newOsmLayersWithCategories)) return;

            if (mapLayersLocalState && updateMapLayersState) {
                updateMapLayersState({ osmLayersCategories: newOsmLayersWithCategories });
            } else {
                dispatch(appSetOSMLayersWithCategories(newOsmLayersWithCategories));
            }
        },
        [dispatch, updateMapLayersState, mapLayersLocalState, osmLayersWithCategories],
    );

    const onChange = useCallback(
        ({ code, isChecked }: { code: string; isChecked: boolean | undefined }) => {
            const updatedValues = cloneDeep(values);
            const foundParentOsm = updatedValues.find(parentOsm => parentOsm.value === code);

            if (foundParentOsm) {
                foundParentOsm.checked = !!isChecked;

                foundParentOsm.nestedCheckboxesData.forEach(childOsm => {
                    childOsm.disabled = !isChecked;
                    childOsm.checked = !!isChecked;
                });
            } else {
                const foundChildOsm = updatedValues
                    .flatMap(parentOsm => parentOsm.nestedCheckboxesData)
                    .find(childOsm => childOsm.value === code);

                if (foundChildOsm && !foundChildOsm.disabled) {
                    foundChildOsm.checked = !!isChecked;
                }
            }
            setValues(updatedValues);
            onUpdateLayersSelection(updatedValues);
        },
        [values, onUpdateLayersSelection],
    );

    return { values, onChange, resetToDefault };
};
