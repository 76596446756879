import { analysisConfiguration } from "@app/analysis/state/analysisConfiguration.reducer";
import { ANALYSIS_CONFIGURATION_INITIAL_STATE } from "@app/analysis/state/analysisConfiguration.state";
import {
    ANALYSIS_LIGHTNING_INITIAL_STATE,
    analysisLightningReducer,
} from "@app/analysisLightning";
import { ORGS_INITIAL_STATE, orgsReducer } from "@app/bigBang/admin/orgs/state";
import { USERS_INITIAL_STATE, usersReducer } from "@app/bigBang/admin/users/state";
import { ANALYSES_INITIAL_STATE, analysesReducer } from "@app/bigBang/analyses/state";
import { DASHBOARD_INITIAL_STATE, dashboardReducer } from "@app/bigBang/dashboard/state";
import { projectsReducer } from "@app/bigBang/projects/state/projects.reducer";
import { PROJECTS_INITIAL_STATE } from "@app/bigBang/projects/state/projects.state";
import {
    ZONE_SET_MODAL_INITIAL_STATE,
    zoneSetModalReducer,
} from "@app/bigBang/zoneSetModal/state";
import { PIRATE_SHIP_INITIAL_STATE, pirateShipReducer } from "@app/pirateShip/state";
import { VIZ3_INITIAL_STATE, viz3Reducer } from "@app/viz3/state";
import { mapEnhancedLayersReducer } from "@common/features/mapEnhancedLayers/state/mapEnhancedLayers.reducer";
import { MAP_ENHANCED_LAYERS_STATE } from "@common/features/mapEnhancedLayers/state/mapEnhancedLayers.state";
import { TAGS_INITIAL_STATE, tagsReducer } from "@common/features/tags/state";
import { ZONE_SETS_INITIAL_STATE, zoneSetsReducer } from "@common/features/zoneSets/state";
import {
    ZONES_MANAGER_INITIAL_STATE,
    zonesManagerReducer,
} from "@common/features/zonesManager/state";
import { combineReducers } from "@reduxjs/toolkit";
import { currentUser } from "./currentUser/currentUser.reducer";
import { CURRENT_USER_INITIAL_STATE } from "./currentUser/currentUser.state";
import { staticDataReducer } from "./staticData/state/staticData.reducer";
import { STATIC_DATA_STATE } from "./staticData/state/staticData.state";
import userPreferences, {
    defaultState as defaultUserPreferences,
} from "./userPreferences/userPreferences.reducer";

export const defaultState = {
    staticData: STATIC_DATA_STATE,
    currentUser: CURRENT_USER_INITIAL_STATE,
    userPreferences: { ...defaultUserPreferences },
    analysisConfiguration: ANALYSIS_CONFIGURATION_INITIAL_STATE,
    analyses: ANALYSES_INITIAL_STATE,
    projects: PROJECTS_INITIAL_STATE,
    zoneSets: ZONE_SETS_INITIAL_STATE,
    orgs: ORGS_INITIAL_STATE,
    users: USERS_INITIAL_STATE,
    viz3: VIZ3_INITIAL_STATE,
    tags: TAGS_INITIAL_STATE,
    dashboard: DASHBOARD_INITIAL_STATE,
    zoneSetModal: ZONE_SET_MODAL_INITIAL_STATE,
    pirateShip: PIRATE_SHIP_INITIAL_STATE,
    zonesManager: ZONES_MANAGER_INITIAL_STATE,
    analysisLightning: ANALYSIS_LIGHTNING_INITIAL_STATE,
    mapEnhancedLayers: MAP_ENHANCED_LAYERS_STATE,
};

/*
 * Names of reducers should match top-level state key names.
 */
export const rootReducer = combineReducers({
    staticData: staticDataReducer,
    currentUser,
    userPreferences,
    analysisConfiguration,
    analyses: analysesReducer,
    projects: projectsReducer,
    zoneSets: zoneSetsReducer,
    orgs: orgsReducer,
    users: usersReducer,
    viz3: viz3Reducer,
    tags: tagsReducer,
    dashboard: dashboardReducer,
    zoneSetModal: zoneSetModalReducer,
    pirateShip: pirateShipReducer,
    zonesManager: zonesManagerReducer,
    analysisLightning: analysisLightningReducer,
    mapEnhancedLayers: mapEnhancedLayersReducer,
});

export type TRootState = ReturnType<typeof rootReducer>;
export type TGetState = () => TRootState;
