import { MAP_STYLES } from "@app/viz3/zaVisualization/map/mapStyles.constants";
import { ZA_LAYERS } from "@app/viz3/zaVisualization/state/zaViz.constants";

export const ZA_VIZ_INITIAL_STATE = {
    map: {
        clickedZoneId: null,
        layerConfigurations: {
            [ZA_LAYERS.ZONE.code]: {
                colors: {
                    high: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.LOW,
                    hover: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HOVER,
                },
                opacity: MAP_STYLES.DISTRIBUTION_ZONES.OPACITY,
            },
            [ZA_LAYERS.HOME_GRIDS.code]: {
                colors: {
                    high: MAP_STYLES[ZA_LAYERS.HOME_GRIDS.code].COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES[ZA_LAYERS.HOME_GRIDS.code].COLOR.LOW,
                    hover: MAP_STYLES[ZA_LAYERS.HOME_GRIDS.code].COLOR.HOVER,
                },
                opacity: MAP_STYLES[ZA_LAYERS.HOME_GRIDS.code].OPACITY,
            },
            [ZA_LAYERS.WORK_GRIDS.code]: {
                colors: {
                    high: MAP_STYLES[ZA_LAYERS.WORK_GRIDS.code].COLOR.HIGH,
                    mid: "",
                    low: MAP_STYLES[ZA_LAYERS.WORK_GRIDS.code].COLOR.LOW,
                    hover: MAP_STYLES[ZA_LAYERS.WORK_GRIDS.code].COLOR.HOVER,
                },
                opacity: MAP_STYLES[ZA_LAYERS.WORK_GRIDS.code].OPACITY,
            },
        },
    },
    distributionLayers: null,
    preSelectedZones: [],
    cachedZones: {
        trafficBehaviors: [],
        zoneMap: {},
        selectedZones: [],
    },
};
