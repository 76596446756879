import { IDateRange } from "@common/components/dateRangeFilter/dateRangeFilter";

interface IMultiFilterValue {
    label: string;
    value: string;
}

interface IRangeFilterValue {
    max: number;
    min: number;
}

interface IFilter {
    id: string;
    value:
        | string
        | number
        | boolean
        | IDateRange
        | IRangeFilterValue
        | IMultiFilterValue
        | Array<IMultiFilterValue>;
}

export interface IApiFilters {
    excluded_stl_user: boolean;
    [key: string]:
        | string
        | number
        | boolean
        | IDateRange
        | IRangeFilterValue
        | IMultiFilterValue
        | Array<string>
        | Array<IMultiFilterValue>;
}

interface IObjectFilters {
    [key: string]:
        | string
        | number
        | boolean
        | IDateRange
        | IRangeFilterValue
        | IMultiFilterValue
        | Array<IMultiFilterValue>;
}

interface IColumnMap {
    [key: string]: string;
}

export const arrayFiltersToObject = (filtered: Array<IFilter>) => {
    return filtered.reduce(
        (res, filter) => ({
            ...res,
            [filter.id]: filter.value,
        }),
        {} as IObjectFilters,
    );
};

export const objectFiltersToArray = (filtered: IObjectFilters) => {
    return Object.entries(filtered)
        .filter(([, value]) => !!value)
        .map(([id, value]) => ({ id, value }));
};

export const tableFiltersStateToData = (
    filtered: Array<IFilter>,
    colIdToDbId: IColumnMap,
    shouldExcludeStlUsers: boolean,
) => {
    const _apiFilters = filtered.reduce((apiFilters, filter) => {
        let filterValue;

        // filter.value could be any of Array|Object|String
        if (Array.isArray(filter.value)) {
            filterValue = filter.value.map(item => String(item.value));
        } else {
            // @ts-ignore
            filterValue = filter.value?.value || filter.value;
        }

        if (!filterValue) {
            return apiFilters;
        }

        return { ...apiFilters, [colIdToDbId[filter.id]]: filterValue };
    }, {}) as IApiFilters;

    if (shouldExcludeStlUsers) {
        _apiFilters.excluded_stl_user = true;
    }
    return _apiFilters;
};

export const getSortedFilterOptions = <
    T extends Record<string, any>,
    TSortProperty extends keyof T,
>(
    items: Array<T> = [],
    sortProperty: TSortProperty & (T[TSortProperty] extends string ? TSortProperty : never),
) => {
    return [...items].sort((a, b) => a[sortProperty].localeCompare(b[sortProperty]));
};
