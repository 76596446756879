import { useCallback, useContext, useEffect, useMemo } from "react";
import type { EventData, Layer, Map, MapboxGeoJSONFeature } from "mapbox-gl";
import { getTopMostFeature } from "@app/viz3/base/state/baseViz.helpers";
import { MapContext } from "@common/components/baseMap";
import { highlightZone } from "@common/components/baseMap/baseMap.helpers";
import { useHoverLayer } from "@common/components/baseMap/hooks/useHoverLayer";

type TListener = {
    layerId: string;
    listener: (event: EventData) => void;
};

export type THighlightZoneProps = {
    feature: MapboxGeoJSONFeature | null | undefined;
    mapInstance: Map;
    featureIdProperty: string | number;
    setHoveredFeature?: (feature: MapboxGeoJSONFeature | null) => void;
    layer?: Layer;
};

export type THighlightZone = ({
    feature,
    mapInstance,
    featureIdProperty,
    setHoveredFeature,
    layer,
}: THighlightZoneProps) => void;

const setCursor = (e: EventData, map: Map) => {
    if (e.type === "mousemove") {
        map.getCanvas().style.cursor = "pointer";
    } else if (e.type === "mouseleave") {
        map.getCanvas().style.cursor = "";
    }
};

export const useHover = (
    map: Map | null,
    {
        layers = [],
        onHover = () => {},
        disableListeners = false,
        featureIdProperty = "zone_id",
        createHighlightZone,
        hoverOutlineColor,
        disableAnimatedHover = false,
        disableCursorChanging = false,
        hoverLineWidth,
    }: {
        layers: Array<Layer>;
        disableListeners?: boolean;
        featureIdProperty?: string | number;
        onHover?: (event: EventData, layer: Layer, mapInstance: Map) => void;
        createHighlightZone?: () => THighlightZone;
        hoverOutlineColor?: string;
        disableAnimatedHover?: boolean;
        disableCursorChanging?: boolean;
        hoverLineWidth?: number;
    },
) => {
    const { setHoveredFeature } = useContext(MapContext);

    const _highlightZone = useMemo(
        () => (createHighlightZone ? createHighlightZone() : highlightZone),
        [createHighlightZone],
    );

    const onLayerHover = useCallback(
        (e: EventData, layer: Layer, mapInstance: Map) => {
            // Whether we are selecting zone using bounding box.
            const isShiftKeyPressed = e.originalEvent.shiftKey && e.originalEvent.button === 0;
            let feature = null;
            if (e.features?.length) {
                const foundFeature = getTopMostFeature(e, mapInstance, layers);
                if (foundFeature && foundFeature.layer.id === layer.id && !isShiftKeyPressed) {
                    feature = foundFeature;
                }
            }

            if (!disableCursorChanging) {
                setCursor(e, mapInstance);
            }

            onHover(e, layer, mapInstance);
            _highlightZone({
                feature,
                mapInstance,
                featureIdProperty,
                setHoveredFeature,
                layer,
            });
        },
        [
            onHover,
            layers,
            _highlightZone,
            featureIdProperty,
            setHoveredFeature,
            disableCursorChanging,
        ],
    );

    useHoverLayer({ map, hoverOutlineColor, disableAnimatedHover, layers, hoverLineWidth });

    useEffect(() => {
        if (!map || disableListeners) return undefined;

        const listenersList = layers.reduce((listeners: Array<TListener>, layer: Layer) => {
            const listener = (e: EventData) => onLayerHover(e, layer, map);
            map.on("mousemove", layer.id, listener);
            map.on("mouseleave", layer.id, listener);

            return [...listeners, { layerId: layer.id, listener }];
        }, []);

        return () => {
            listenersList.forEach(({ layerId, listener }) => {
                map.off("mousemove", layerId, listener);
                map.off("mouseleave", layerId, listener);
            });
        };
    }, [map, disableListeners, layers, onLayerHover]);

    // Need to reset hover after click on hovered zone
    useEffect(() => {
        if (!map || !layers.length || disableAnimatedHover) return undefined;

        // We need setTimeout to make sure that handler from useClick hook is executed before
        // we reset hover state, otherwise it may lead to the click outside of zone once hover
        // styles will be removed (hovered zone has bigger width than regular).
        const handleClickOnMap = (e: EventData) => {
            setTimeout(() => {
                onHover(e, layers[0], map);
                if (!disableCursorChanging) {
                    map.getCanvas().style.cursor = "";
                }
                _highlightZone({
                    feature: null,
                    mapInstance: map,
                    featureIdProperty,
                    setHoveredFeature,
                });
            }, 0);
        };

        map.on("click", handleClickOnMap);

        const handleMouseMoveOnMap = (e: EventData) => {
            const isShiftKeyPressed = e.originalEvent.shiftKey && e.originalEvent.button === 0;

            if (!isShiftKeyPressed) return;

            setTimeout(() => {
                onHover({} as EventData, {} as Layer, {} as Map);
                if (!disableCursorChanging) {
                    map.getCanvas().style.cursor = "";
                }
                _highlightZone({
                    feature: null,
                    mapInstance: map,
                    featureIdProperty,
                    setHoveredFeature,
                });
            }, 0);
        };

        map.on("mousemove", handleMouseMoveOnMap);

        return () => {
            map.off("click", handleClickOnMap);
            map.off("mousemove", handleMouseMoveOnMap);
        };
    }, [
        map,
        layers,
        onHover,
        _highlightZone,
        featureIdProperty,
        disableAnimatedHover,
        disableCursorChanging,
        setHoveredFeature,
    ]);
};
