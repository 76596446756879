import { ReactNode } from "react";
import "./card.less";

type TProps = {
    title: ReactNode;
    children: ReactNode;
};

export const StlCard = ({ title, children }: TProps): JSX.Element => {
    return (
        <article className="stl-card">
            <h2 className="stl-card-header">{title}</h2>
            <div className="stl-card-body">{children}</div>
        </article>
    );
};
