import { useEffect, useMemo } from "react";
import type { Map } from "mapbox-gl";
import type { TControlConfig } from "@common/components/baseMap/baseMap.types";
import { MapLoader } from "@common/components/baseMap/customControls";

export type TProps = {
    map: Map | null;
    enabled: boolean;
    position: TControlConfig["position"];
    isLoading: boolean;
};

export const useMapLoaderControl = ({ map, enabled, position, isLoading }: TProps) => {
    const mapLoader = useMemo(() => new MapLoader(), []);

    useEffect(() => {
        if (!map || !enabled) return;

        map.addControl(mapLoader, position);
    }, [map, mapLoader, enabled, position]);

    useEffect(() => {
        mapLoader.handleShowLoader(isLoading);
    }, [mapLoader, isLoading]);
};
