export const ANALYSES_INITIAL_STATE = {
    analyses: [],
};

export const OSM_TYPES = [
    {
        displayName: "Vehicle",
        legend: {
            type: "line",
            style: "solid #FF9797",
        },
        osmType: "vehicle",
    },
    {
        displayName: "Bicycle",
        legend: {
            type: "line",
            style: "dashed #7AB0F5",
        },
        osmType: "bicycle",
    },
    {
        displayName: "Pedestrian",
        legend: {
            type: "line",
            style: "dotted #8CBF56",
        },
        osmType: "pedestrian",
    },
];
