const createLoader = () => {
    const xmlns = "http://www.w3.org/2000/svg";
    const coords =
        "M20.164 10.3726C20.5213 10.3726 20.8514 10.5632 21.0301 10.8726L24.248 16.4462C24.4266 16.7556 24.4266 17.1368 24.248 17.4462L21.0301 23.0198C20.8514 23.3292 20.5213 23.5198 20.164 23.5198L13.7282 23.5198C13.3709 23.5198 13.0408 23.3292 12.8622 23.0198L9.64423 17.4462C9.46559 17.1368 9.46559 16.7556 9.64422 16.4462L12.8622 10.8726C13.0408 10.5632 13.3709 10.3726 13.7282 10.3726L20.164 10.3726Z";

    const svgElem = document.createElementNS(xmlns, "svg");
    svgElem.setAttributeNS(null, "viewBox", "0 0 34 34");
    svgElem.setAttributeNS(null, "class", "loader");

    const path = document.createElementNS(xmlns, "path");
    path.setAttributeNS(null, "class", "cls-1 gear1");
    path.setAttributeNS(null, "d", coords);

    svgElem.appendChild(path);

    return svgElem;
};

export class MapLoader {
    loaderWrapper: HTMLDivElement | null = null;
    _container!: HTMLDivElement;

    onAdd = () => {
        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group map-loader";

        const loader = createLoader();

        const loaderWrapper = window.document.createElement("div");
        loaderWrapper.className = "loader-wrapper hide";
        loaderWrapper.appendChild(loader);

        this.loaderWrapper = loaderWrapper;

        this._container.appendChild(loaderWrapper);

        return this._container;
    };

    onRemove = () => {
        this._container.parentNode?.removeChild(this._container);
    };

    handleShowLoader = (show: boolean) => {
        if (!this.loaderWrapper) return;

        if (show) {
            this.loaderWrapper.setAttributeNS(null, "class", "loader-wrapper");
        } else {
            this.loaderWrapper.setAttributeNS(null, "class", "loader-wrapper hide");
        }
    };
}
