import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { StlButton, StlDatePicker, StlFormError, StlInput, StlSelect } from "@common/components";
import { TDate } from "@common/components/dateRangeFilter/dateRangeFilter";
import { IErrors } from "@common/components/formError/formError";
import {
    EDIT_STUDY_DAYS_PERIOD,
    ENTERPRISE_STUDY_TYPE_OPTIONS,
    ENTERPRISE_STUDY_TYPES,
    STUDY_MODES,
    STUDY_TYPE_OPTIONS,
} from "@common/features/studyModal/common/study.constants";
import {
    getIsBPNumberRequired,
    getIsEnterpriseStudy,
    getIsProjectNumberRequired,
} from "@common/features/studyModal/common/study.helpers";
import { EStudyMode, IStudy, IStudyData } from "@common/features/studyModal/common/study.types";
import * as helpIcons from "@common/features/studyModal/common/studyHelpIcons";
import { useDidMount } from "@common/hooks/useDidMount";
import "./studyGeneralTab.less";

const getAlphanumericValue = (value: string): string => value.replace(/[^a-z0-9]/gi, "");

type TProps = {
    study: IStudy;
    originalStudy: IStudyData | null;
    mode: EStudyMode;
    closeModal: () => void;
    showNextTab: () => void;
    updateStudy: (value: Partial<IStudy>) => void;
    isConvertToActiveProject: boolean;
    setIsConvertToActiveProject: (flag: boolean) => void;
    errors: IErrors;
};

export const EnterpriseStudyGeneralTab = ({
    study,
    originalStudy,
    updateStudy,
    showNextTab,
    closeModal,
    errors,
    mode,
    isConvertToActiveProject,
    setIsConvertToActiveProject,
}: TProps): JSX.Element => {
    const isViewMode = mode === STUDY_MODES.VIEW;

    const studyTypeOptions = useMemo(() => {
        if (!originalStudy?.study_type) return ENTERPRISE_STUDY_TYPE_OPTIONS;

        const nonEnterpriseStudy = STUDY_TYPE_OPTIONS.find(
            studyType => studyType.value === originalStudy.study_type,
        );
        return nonEnterpriseStudy ? STUDY_TYPE_OPTIONS : ENTERPRISE_STUDY_TYPE_OPTIONS;
    }, [originalStudy]);

    const isNextDisabled = useMemo(() => {
        if (isViewMode) return false;

        const areCommonFieldsMissing = !study.studyType || !study.name || !study.endClient;
        if (isConvertToActiveProject) {
            return areCommonFieldsMissing || !study.bpCode || !study.chargeCode;
        }
        return (
            areCommonFieldsMissing ||
            (getIsBPNumberRequired(study) && !study.bpCode) ||
            (getIsProjectNumberRequired(study) && !study.chargeCode)
        );
    }, [study, isViewMode, isConvertToActiveProject]);

    const editStudyPeriod = useMemo(() => {
        const diff = moment().diff(moment(study.nonEditableStartDate), "days");
        return {
            elapsed: diff > EDIT_STUDY_DAYS_PERIOD,
            daysRemain: EDIT_STUDY_DAYS_PERIOD - diff,
        };
    }, [study]);

    const fieldsReadOnlyState = useMemo(() => {
        const isEnterpriseStudy = getIsEnterpriseStudy(study);
        if (mode === STUDY_MODES.VIEW) {
            return {
                common: true,
                studyType: true,
                bpCode: true,
                chargeCode: true,
                taskNumber: true,
            };
        } else if (
            mode === STUDY_MODES.CREATE ||
            !study.nonEditableStartDate ||
            !isEnterpriseStudy
        ) {
            return {
                common: false,
                studyType: false,
                bpCode: false,
                chargeCode: false,
                taskNumber: false,
            };
        }

        const isConvertedToActiveProjectStudy =
            originalStudy &&
            originalStudy.study_type === ENTERPRISE_STUDY_TYPES.Active_Project.value &&
            !!originalStudy.bp_code &&
            !!originalStudy.charge_code;

        return {
            common: isConvertedToActiveProjectStudy || editStudyPeriod.elapsed,
            studyType:
                isConvertedToActiveProjectStudy ||
                editStudyPeriod.elapsed ||
                isConvertToActiveProject,
            bpCode: editStudyPeriod.elapsed || isConvertToActiveProject,
            chargeCode: editStudyPeriod.elapsed && !isConvertToActiveProject,
            taskNumber: editStudyPeriod.elapsed && !isConvertToActiveProject,
        };
    }, [originalStudy, study, mode, isConvertToActiveProject, editStudyPeriod]);

    const startDate = useMemo(() => study.startDate || moment().toDate(), [study.startDate]);
    const minEndDate = useMemo(() => moment(startDate).toDate(), [startDate]);

    const canConvertToActiveProject = useMemo(() => {
        return (
            mode === STUDY_MODES.EDIT &&
            originalStudy?.study_type === ENTERPRISE_STUDY_TYPES.Pursuit.value &&
            !isConvertToActiveProject
        );
    }, [mode, originalStudy, isConvertToActiveProject]);

    const convertToActiveProjectCounterText = useMemo(() => {
        if (
            mode === STUDY_MODES.CREATE ||
            !study.nonEditableStartDate ||
            editStudyPeriod.elapsed ||
            isConvertToActiveProject
        ) {
            return "";
        }
        return `${editStudyPeriod.daysRemain} days remaining to edit charge codes`;
    }, [study, mode, editStudyPeriod, isConvertToActiveProject]);

    useDidMount(() => {
        if (!study.studyType) {
            updateStudy({ studyType: ENTERPRISE_STUDY_TYPES.Pursuit.value });
        }
    });

    const updateStudyData = (property: keyof IStudy, value: IStudy[keyof IStudy]): void => {
        updateStudy({ [property]: value });
    };

    const convertToActiveProject = () => {
        updateStudyData("studyType", ENTERPRISE_STUDY_TYPES.Active_Project.value);
        setIsConvertToActiveProject(true);
    };

    return (
        <div className="stl-study-general-tab">
            <div className="tab-content">
                <div>
                    <span>Required fields are indicated with *</span>
                </div>
                <div className="form enterprise-form">
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="studyName">
                            Study Name *
                        </label>
                        <StlInput
                            id="studyName"
                            placeholder="study name"
                            value={study.name}
                            onChange={(value: string): void => updateStudyData("name", value)}
                            maxLength={50}
                            disabled={fieldsReadOnlyState.common}
                            required
                        />
                        <StlFormError name="name" errors={errors} id="studyName" />
                    </div>
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="studyStartDate">
                            Start Date
                        </label>
                        <StlDatePicker id="studyStartDate" value={startDate} disabled />
                    </div>
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="studyEndClient">
                            End Client *
                        </label>
                        <StlInput
                            id="studyEndClient"
                            placeholder="end client"
                            value={study.endClient}
                            onChange={(value: string): void => updateStudyData("endClient", value)}
                            disabled={fieldsReadOnlyState.common}
                            required
                        />
                        <StlFormError name="endClient" errors={errors} id="studyEndClient" />
                    </div>
                    <div className="form-control-group">
                        <label className="stl-label" htmlFor="studyEndDate">
                            End Date (MM/DD/YYYY){helpIcons.STUDY_END_DATE_HELP_ICON}
                        </label>
                        <StlDatePicker
                            id="studyEndDate"
                            placeholder="end date"
                            value={study.endDate}
                            onChange={(value: TDate): void => updateStudyData("endDate", value)}
                            minDate={minEndDate}
                            disabled={isViewMode}
                            required
                        />
                        <StlFormError name="endDate" errors={errors} id="studyEndDate" />
                    </div>
                </div>
                {isConvertToActiveProject && (
                    <div className="form enterprise-form">
                        <div className="form-control-group study-type">
                            <label className="stl-label" htmlFor="activeProjectStudyType">
                                Study Type
                            </label>
                            <StlInput
                                id="activeProjectStudyType"
                                value={ENTERPRISE_STUDY_TYPES.Pursuit.label}
                                disabled
                            />
                        </div>
                        <div className="form-control-group">
                            <label className="stl-label" htmlFor="studyBPNumber">
                                B&P Number
                            </label>
                            <StlInput
                                id="studyBPNumber"
                                placeholder="B&P number"
                                value={study.bpCode || ""}
                                disabled
                            />
                        </div>
                    </div>
                )}
                <div className="form enterprise-form">
                    <div className="form-control-group study-type">
                        <label className="stl-label" htmlFor="studyType">
                            Study Type *{" "}
                            <span className="counter-text">
                                {convertToActiveProjectCounterText}
                            </span>
                        </label>
                        <StlSelect
                            id="studyType"
                            placeholder="study type"
                            //@ts-ignore
                            options={studyTypeOptions}
                            value={study.studyType}
                            onChange={(option): void =>
                                updateStudyData("studyType", option!.value)
                            }
                            disabled={fieldsReadOnlyState.studyType}
                            required
                        />
                        <StlFormError name="studyType" errors={errors} id="studyType" />
                    </div>
                    {getIsBPNumberRequired(study) && (
                        <div className="form-control-group">
                            <label className="stl-label" htmlFor="studyBPNumber">
                                B&P Number *
                            </label>
                            <StlInput
                                id="studyBPNumber"
                                placeholder="B&P number"
                                value={study.bpCode || ""}
                                onChange={(value: string): void =>
                                    updateStudyData("bpCode", getAlphanumericValue(value))
                                }
                                disabled={fieldsReadOnlyState.bpCode}
                                required={study.studyType === ENTERPRISE_STUDY_TYPES.Pursuit.value}
                            />
                            <StlFormError name="bpCode" errors={errors} id="studyBPNumber" />
                        </div>
                    )}
                    {getIsProjectNumberRequired(study) && (
                        <>
                            <div className="form-control-group">
                                <label className="stl-label" htmlFor="studyChargeCode">
                                    Project Number *
                                    {study.studyType ===
                                        ENTERPRISE_STUDY_TYPES.Marketing_Conf.value &&
                                        helpIcons.CHARGE_CODE_HELP_ICON}
                                </label>
                                <StlInput
                                    id="studyChargeCode"
                                    placeholder="project number"
                                    value={study.chargeCode || ""}
                                    onChange={(value: string): void =>
                                        updateStudyData("chargeCode", getAlphanumericValue(value))
                                    }
                                    disabled={fieldsReadOnlyState.chargeCode}
                                    required
                                />
                                <StlFormError
                                    name="chargeCode"
                                    errors={errors}
                                    id="studyChargeCode"
                                />
                            </div>
                            <div className="form-control-group">
                                <label className="stl-label" htmlFor="studyTaskNumber">
                                    Project Task Code
                                </label>
                                <StlInput
                                    id="studyTaskNumber"
                                    placeholder="project task code"
                                    value={study.taskNumber || ""}
                                    onChange={(value: string): void =>
                                        updateStudyData("taskNumber", getAlphanumericValue(value))
                                    }
                                    disabled={fieldsReadOnlyState.taskNumber}
                                />
                                <StlFormError
                                    name="taskNumber"
                                    errors={errors}
                                    id="studyTaskNumber"
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="tab-actions">
                <StlButton id="studyCloseBtn" variant="secondary" onClick={closeModal}>
                    {isViewMode ? (
                        <FormattedMessage id="app.close" defaultMessage="Close" />
                    ) : (
                        <FormattedMessage id="app.cancel" defaultMessage="Cancel" />
                    )}
                </StlButton>
                {isConvertToActiveProject && (
                    <StlButton
                        id="studyBackBtn"
                        variant="secondary"
                        onClick={() => setIsConvertToActiveProject(false)}
                    >
                        <FormattedMessage id="app.back" defaultMessage="Back" />
                    </StlButton>
                )}
                <StlButton
                    id="studyNextBtn"
                    variant="primary"
                    onClick={showNextTab}
                    disabled={isNextDisabled}
                >
                    <FormattedMessage id="app.next" defaultMessage="Next" />
                </StlButton>
                {canConvertToActiveProject && (
                    <StlButton
                        id="studyConvertToActiveProjectBtn"
                        variant="primary"
                        onClick={convertToActiveProject}
                    >
                        Convert to Active Project
                    </StlButton>
                )}
            </div>
        </div>
    );
};
