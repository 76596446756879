const ACTION_PREFIX = "TMC_VIZ";

export const TMC_VIZ_ACTIONS = {
    SET_PRE_SELECTED_ZONE: `${ACTION_PREFIX}/SET_PRE_SELECTED_ZONE`,
    SET_ZONE_LAYERS: `${ACTION_PREFIX}/SET_ZONE_LAYERS`,
    SET_VIZ_STATE: `${ACTION_PREFIX}/SET_VIZ_STATE`,
    SET_HOVERED_ARROW: `${ACTION_PREFIX}/SET_HOVERED_ARROW`,
    SET_DIAGRAM_DATA: `${ACTION_PREFIX}/SET_DIAGRAM_DATA`,
    SET_SELECTED_INTERSECTION: `${ACTION_PREFIX}/SET_SELECTED_INTERSECTION`,
    SET_INBOUND_OUTBOUND_MODE: `${ACTION_PREFIX}/SET_INBOUND_OUTBOUND_MODE`,
};
