import type { IProjectActivity } from "@common/services/server/userApi.types";
import { StlAccountListItem } from "./accountListItem";

type TProps = {
    projectActivity: IProjectActivity;
};

export const StatisticsInformation = ({ projectActivity }: TProps) => (
    <>
        <p className="against-quota">Analyses that count against quota</p>
        <ul className="simple-list">
            <StlAccountListItem title="Metrics Available:">
                <div>{projectActivity.available || 0}</div>
            </StlAccountListItem>
            <StlAccountListItem title="Deleted:">
                <div>{projectActivity.deleted || 0}</div>
            </StlAccountListItem>
            <StlAccountListItem title="Processing:">
                <div>{projectActivity.processing || 0}</div>
            </StlAccountListItem>
            <StlAccountListItem title="In Review:">
                <div>
                    {(projectActivity.in_coverage_review || 0) +
                        (projectActivity.in_size_review || 0)}
                </div>
            </StlAccountListItem>
        </ul>
    </>
);
