import { BASE_MAP_STYLES } from "@app/viz3/base/map/baseMapStyles.constants";
import { ZA_LAYERS } from "@app/viz3/zaVisualization/state/zaViz.constants";

export const MAP_STYLES = {
    ...BASE_MAP_STYLES,
    [ZA_LAYERS.HOME_GRIDS.code]: {
        COLOR: {
            HIGH: "#97C667",
            LOW: "#666666",
            HOVER: "#FFFFFF",
        },
        OPACITY: 0.7,
        Z_INDEX: 9,
    },
    [ZA_LAYERS.WORK_GRIDS.code]: {
        COLOR: {
            HIGH: "#FF9797",
            LOW: "#666666",
            HOVER: "#FFFFFF",
        },
        OPACITY: 0.7,
        Z_INDEX: 9,
    },
};
