import React, {
    forwardRef,
    ReactNode,
    useCallback,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import classNames from "classnames";
import { useMounted } from "@common/utils/useMounted";
import { Popover } from "@material-ui/core";
import type { PopoverProps } from "@material-ui/core/Popover/Popover";
import "./popover.less";

const DEFAULT_ID = "stl-popover" as const;

const CLASSES = { root: "stl-popover-root", paper: "stl-popover-paper" } as const;

const INITIAL_STATE = {
    anchorEl: null,
} as const;

const DEFAULT_ANCHOR_ORIGIN = {
    vertical: "bottom",
    horizontal: "center",
} as const;
const DEFAULT_TRANSFORM_ORIGIN = {
    vertical: "top",
    horizontal: "center",
} as const;

export type StlPopoverHandlers = {
    open: () => void;
    close: () => void;
};

type TProps = Omit<PopoverProps, "open" | "onClose"> & {
    title?: string;
    className?: string;
    onClose?: () => void;
    trigger?: Array<string>;
    sourceControl?: ReactNode;
    contentClickable?: boolean;
    allowWrapperSelection?: boolean;
    allowMouseLeaveOnSourceControl?: boolean;
    setActive?: (value: boolean) => void;
    hoverDelay?: number;
};

export const StlPopover = forwardRef<HTMLDivElement | StlPopoverHandlers, TProps>(
    (
        {
            id = DEFAULT_ID,
            className,
            sourceControl,
            title,
            children,
            trigger = ["click"],
            onClose = () => {},
            setActive = () => {},
            contentClickable = false,
            disableAutoFocus = true,
            disableEnforceFocus = true,
            disableRestoreFocus = true,
            allowWrapperSelection = true,
            allowMouseLeaveOnSourceControl = true,
            // https://material-ui.com/components/popover/#anchor-playground to check position options
            anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
            transformOrigin = DEFAULT_TRANSFORM_ORIGIN,
            hoverDelay = 0,
        },
        ref,
    ) => {
        const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(INITIAL_STATE.anchorEl);

        const btnRef = useRef<HTMLDivElement>(null);
        const popoverTimeoutId = useRef<NodeJS.Timeout | null>(null);

        const isMounted = useMounted();

        const controlId = `${id}-ControlWrapper`;

        const handlePopoverOpen = useCallback(
            ({ currentTarget: target }: { currentTarget: HTMLDivElement | null }) => {
                if (hoverDelay) {
                    popoverTimeoutId.current = setTimeout(() => {
                        setAnchorEl(target);
                        setActive(true);
                    }, hoverDelay);
                } else {
                    setAnchorEl(target);
                    setActive(true);
                }
            },
            [setActive, hoverDelay],
        );

        const handlePopoverClose = useCallback(() => {
            setAnchorEl(INITIAL_STATE.anchorEl);
            setActive(false);
            if (popoverTimeoutId.current) {
                clearTimeout(popoverTimeoutId.current);
            }
        }, [setActive]);

        const close = () => {
            if (isMounted.current) {
                setAnchorEl(INITIAL_STATE.anchorEl);
                setActive(false);
            }
            onClose();
        };

        const open = () => {
            if (isMounted.current) handlePopoverOpen({ currentTarget: btnRef.current });
        };

        const onMouseLeave = trigger.includes("hover") ? handlePopoverClose : undefined;

        // allows to close and open popover from the parent component
        useImperativeHandle(ref, () => ({
            close: () => close(),
            open: () => open(),
        }));

        return (
            <>
                {trigger.includes("click") ? (
                    <div
                        id={controlId}
                        className="stl-popover-control-wrapper"
                        onClick={handlePopoverOpen}
                        role="presentation"
                        ref={btnRef}
                    >
                        {sourceControl}
                    </div>
                ) : (
                    <div
                        id={controlId}
                        className="stl-popover-control-wrapper"
                        aria-owns={anchorEl ? id : ""}
                        aria-haspopup="true"
                        tabIndex={allowWrapperSelection ? 0 : -1}
                        onMouseEnter={trigger.includes("hover") ? handlePopoverOpen : undefined}
                        onMouseLeave={allowMouseLeaveOnSourceControl ? onMouseLeave : undefined}
                        onFocus={trigger.includes("focus") ? handlePopoverOpen : undefined}
                        onBlur={trigger.includes("focus") ? handlePopoverClose : undefined}
                    >
                        {sourceControl}
                    </div>
                )}
                <Popover
                    id={id}
                    className={classNames(
                        "stl-popover",
                        className,
                        contentClickable && "clickable",
                    )}
                    classes={CLASSES}
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={close}
                    disableAutoFocus={disableAutoFocus}
                    disableEnforceFocus={disableEnforceFocus}
                    disableRestoreFocus={disableRestoreFocus}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                >
                    {title && <div className="title-wrapper">{title}</div>}
                    <div
                        className="content-wrapper"
                        onMouseLeave={!allowMouseLeaveOnSourceControl ? onMouseLeave : undefined}
                    >
                        {children}
                    </div>
                </Popover>
            </>
        );
    },
);
