import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { IColors, IChoroplethOpacity } from "@app/viz3/viz3.types";
import type { IDistributionLayerGroupAPI } from "@common/services/server/layerGroupApi.types";
import {
    ICustomSelectionConfigurations,
    EMapType,
} from "@app/viz3/atVisualization/state/atViz.types";
import { DEFAULT_CUSTOM_SELECTION_CONFIG_STATE } from "@app/viz3/atVisualization/state/atViz.constants";
import { AT_VIZ_INITIAL_STATE, IATVizState } from "./atViz.state";

export const { actions, reducer } = createSlice({
    name: "atViz",
    initialState: AT_VIZ_INITIAL_STATE,
    reducers: {
        setATVizState: (state, action: PayloadAction<IATVizState>) => {
            return action.payload;
        },
        resetATVizState: state => {
            return {
                ...AT_VIZ_INITIAL_STATE,
                customSelectionConfigurations: state.customSelectionConfigurations,
            };
        },
        setDistributionLayers: (state, action: PayloadAction<IDistributionLayerGroupAPI>) => {
            state.distributionLayers = action.payload;
        },
        setZoneIdsToZoom: (state, action: PayloadAction<Array<number | string>>) => {
            state.map.zoneIdsToZoom = action.payload;
        },
        setCustomSelectionConfig: (
            state,
            action: PayloadAction<Partial<ICustomSelectionConfigurations>>,
        ) => {
            state.customSelectionConfigurations = {
                ...state.customSelectionConfigurations,
                ...action.payload,
            };
        },
        setCustomSelectionMapConfig: (
            state,
            action: PayloadAction<Partial<ICustomSelectionConfigurations["map"]>>,
        ) => {
            state.customSelectionConfigurations.map = {
                ...state.customSelectionConfigurations.map,
                ...action.payload,
            };
        },
        setCustomSelectionHoveredZone: (
            state,
            action: PayloadAction<ICustomSelectionConfigurations["map"]["hoveredZone"]>,
        ) => {
            state.customSelectionConfigurations.map.hoveredZone = action.payload;
        },
        closeCustomSelection: state => {
            state.customSelectionConfigurations.isCustomSelectionMapOpened = false;
        },
        resetCustomSelectionMapConfig: state => {
            state.customSelectionConfigurations.map = DEFAULT_CUSTOM_SELECTION_CONFIG_STATE.map;
        },
        resetCustomSelectionConfig: state => {
            state.customSelectionConfigurations = DEFAULT_CUSTOM_SELECTION_CONFIG_STATE;
        },
        setLayerColorConfiguration: (state, action: PayloadAction<IColors>) => {
            state.map.layerConfiguration.colors = action.payload;
        },
        setMapType: (state, action: PayloadAction<EMapType>) => {
            state.map.type = action.payload;
        },
        setBaseDotVolume: (state, action: PayloadAction<IATVizState["map"]["baseDotVolume"]>) => {
            state.map.baseDotVolume = action.payload;
        },
        setCurrentDotVolume: (
            state,
            action: PayloadAction<IATVizState["map"]["currentDotVolume"]>,
        ) => {
            state.map.currentDotVolume = action.payload;
        },
        setMapChoroplethOpacity: (state, action: PayloadAction<IChoroplethOpacity>) => {
            state.map.layerConfiguration.choroplethOpacity = action.payload;
        },
    },
});

export const atVizReducer = reducer;
