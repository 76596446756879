import React from "react";
import UniDirectionalSvg from "@root/src/assets/img/uni-directional.svg";
import BiDirectionalSvg from "@root/src/assets/img/bi-directional.svg";
import { getAngleValue } from "./radialAnglePicker.helpers";
import "./radialAnglePicker.less";

type TProps = {
    value: number | null | undefined;
    onChange?: (angle: number) => void;
    isBiDirectional: boolean;
};

export const RadialAnglePicker = ({ value = 0, onChange, isBiDirectional }: TProps) => {
    const ArrowSvg = isBiDirectional ? BiDirectionalSvg : UniDirectionalSvg;
    const arrowStyle = {
        transform: `rotate(${value}deg)`,
    };

    const handleChange = (e: MouseEvent) => {
        const { offsetX, offsetY } = e;

        const { value: angle } = getAngleValue(1, offsetX, offsetY);

        if (onChange) onChange(angle);
    };

    const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = e => {
        e.preventDefault();
        e.stopPropagation();
        // MouseEvent.which is deprecated, but MouseEvent.buttons is not supported in Safari
        const isButtonPressed =
            typeof e.buttons === "undefined" ? e.nativeEvent.which === 1 : e.buttons === 1;

        if (isButtonPressed) {
            handleChange(e.nativeEvent);
        }
    };

    const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = e => {
        handleChange(e.nativeEvent);
    };

    return (
        <div className="stl-radial-angle-picker">
            <div
                className="mask"
                aria-hidden="true"
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
            />

            {/* @ts-ignore */}
            <ArrowSvg className="direction" style={arrowStyle} />
        </div>
    );
};

RadialAnglePicker.displayName = "RadialAnglePicker";
