import moment from "moment";
import type { IDateRange, ISortedDateRange } from "@app/analysis/timePeriods/timePeriods.types";

export const getDataPeriodYearDefaults = (year: number) => {
    // Months start from 0: 0 = January, 11 = December
    const startDate = moment([year, 0, 1]).valueOf();
    const endDate = moment([year, 11, 31]).valueOf();

    return [{ startDate, endDate }];
};

export const getAvailableDatesText = (whiteListDateRanges: Array<IDateRange>) => {
    if (!whiteListDateRanges.length) {
        return "";
    }

    const dateRanges = whiteListDateRanges
        .map(
            ({ startDate, endDate }) =>
                `${moment(startDate).format("MM/DD/YYYY")}
                    -
                    ${moment(endDate).format("MM/DD/YYYY")}`,
        )
        .join(", ");

    return `Available dates: ${dateRanges}.`;
};

export const checkAreDatesWithinLimits = (
    whiteListDateRanges: Array<IDateRange>,
    dates: ISortedDateRange,
) => {
    if (!whiteListDateRanges) return true;

    return whiteListDateRanges.reduce((result, dateRange) => {
        if (dates!.startDate < dateRange.startDate || dates!.startDate > dateRange.endDate) {
            return false;
        }

        if (dates!.endDate > dateRange.endDate || dates!.endDate < dateRange.startDate) {
            return false;
        }

        return result;
    }, true);
};
