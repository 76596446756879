import { IFilterOptions, TSummaryTabFilters } from "@app/bigBang/dashboard/state/dashboard.types";
import { TQuery } from "@app/bigBang/dashboard/tabs/analysesTab/views/map/map/analysesMap";
import { IAnalysisFilter } from "@app/bigBang/dashboard/tabs/analysesTab/analysesTab.types";
import { IGetAnalysesMapData } from "@common/services/server/dashboardApi.types";

export interface IAnalysisTab {
    activeView: string | null;
    shouldExcludeStlUsers: boolean;
    mapState: {
        query: TQuery | null;
        zoom: null | number;
        center: null | { lng: number; lat: number };
        bearing: null | number;
        pitch: null | number;
        loaded: boolean;
        dataLoaded: boolean;
        map_viewport?: {
            height: number;
            width: number;
        };
    };
}

export interface ISummaryTab {
    shouldExcludeStlUsers: boolean;
    filters: TSummaryTabFilters;
}

export interface IUsersTab {
    shouldExcludeStlUsers: boolean;
}

export interface IPendingExportRequests {
    arePending: boolean;
    requestCount: number;
}

export interface IDashboardState {
    filtersOptions: IFilterOptions;
    summaryTab: ISummaryTab;
    analysisTab: IAnalysisTab;
    usersTab: IUsersTab;
    orgDetails: {
        analysisTab: {
            shouldExcludeStlUsers: boolean;
        };
        usersTab: {
            shouldExcludeStlUsers: boolean;
        };
    };
    pendingExportRequests: IPendingExportRequests;
    chartParams?: {
        filtered: Array<IAnalysisFilter>;
        filter_options: IGetAnalysesMapData["filter_options"];
        detail_id?: number;
    };
}

export const DASHBOARD_INITIAL_STATE = {
    filtersOptions: {} as IFilterOptions,
    summaryTab: {
        shouldExcludeStlUsers: false,
        filters: {} as TSummaryTabFilters,
    },
    analysisTab: {
        activeView: null,
        shouldExcludeStlUsers: false,
        mapState: {
            query: null,
            zoom: null,
            center: null,
            bearing: null,
            pitch: null,
            loaded: false,
            dataLoaded: false,
        },
    },
    usersTab: {
        shouldExcludeStlUsers: false,
    },
    orgDetails: {
        analysisTab: {
            shouldExcludeStlUsers: false,
        },
        usersTab: {
            shouldExcludeStlUsers: false,
        },
    },
    pendingExportRequests: {
        arePending: false,
        requestCount: 0,
    },
} as IDashboardState;
