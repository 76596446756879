import { CSSProperties, ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import { faGripLines, faGripLinesVertical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./divider.less";

const NoOp = () => {};

type TProps = {
    style?: CSSProperties;
    paneType?: "horizontal" | "vertical";
    onMouseDown?: (event: any) => void;
};

export const Divider = forwardRef(
    (
        { onMouseDown, style, paneType = "horizontal" }: TProps = {
            onMouseDown: NoOp,
            style: {},
        },
        ref: ForwardedRef<HTMLDivElement>,
    ) => (
        <div className={classNames("stl-divider", paneType)} style={style} ref={ref}>
            <div className="divider-handle" role="presentation" onMouseDown={onMouseDown}>
                <FontAwesomeIcon
                    icon={paneType === "horizontal" ? faGripLinesVertical : faGripLines}
                />
            </div>
        </div>
    ),
);
