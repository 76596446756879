import Cookies from "js-cookie";

const XSRF_TOKEN_KEY = "XSRF-TOKEN";

export const XSRFToken = {
    key: XSRF_TOKEN_KEY,

    get() {
        return Cookies.get(this.key);
    },
    set(token: string) {
        return Cookies.set(this.key, token);
    },
    remove() {
        return Cookies.remove(this.key);
    },
};
