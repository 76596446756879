import { ElementType, lazy } from "react";
import {
    faBook,
    faChartMixed,
    faImage,
    faDrawPolygon,
    faFolder,
    faObjectsColumn,
    faUserTie,
} from "@fortawesome/pro-solid-svg-icons";
import { ROUTES } from "@common/constants/routes.constants";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface IRoutePermissions {
    [key: string]: boolean;
}

export interface IPage {
    path: string;
    label: string;
    ariaLabel: string;
    isAvailable: boolean;
}

export interface IBigBangRoute {
    id: string;
    path: string;
    component?: ElementType;
    icon?: IconProp;
    optionText: string;
    title: string;
}

const AdminPage = lazy(() => import("@app/bigBang/admin/admin.page"));
const ZonesPage = lazy(() => import("@app/bigBang/zones/zones.page"));
const AnalysesPage = lazy(() => import("@app/bigBang/analyses/analyses.page"));
const StudiesPage = lazy(() => import("@app/bigBang/studies/studies.page"));
const DashboardPage = lazy(() => import("@app/bigBang/dashboard/dashboard.page"));
const ProjectsPage = lazy(() => import("@app/bigBang/projects/pages/projectsPage/projects.page"));
const ProjectFolderPage = lazy(
    () => import("@app/bigBang/projects/pages/projectFolderPage/projectFolder.page"),
);

// Preserve order for Navigation panel
const BIG_BANG_ROUTES = [
    {
        id: "bigBangLayout.projectFolder",
        path: ROUTES.PROJECT_FOLDER,
        component: ProjectFolderPage,
        optionText: "Project",
        title: "Project",
    },
    {
        id: "bigBangLayout.projects",
        path: ROUTES.PROJECTS,
        component: ProjectsPage,
        icon: faFolder,
        optionText: "Projects",
        title: "Projects",
    },
    {
        id: "bigBangLayout.zones",
        path: ROUTES.ZONES,
        component: ZonesPage,
        icon: faDrawPolygon,
        optionText: "Zones",
        title: "Zones",
    },
    {
        id: "bigBangLayout.analyses",
        path: ROUTES.ANALYSES,
        component: AnalysesPage,
        icon: faChartMixed,
        optionText: "Analyses",
        title: "Analyses",
    },
    {
        id: "bigBangLayout.viz3",
        path: ROUTES.VIZ3,
        icon: faImage,
        optionText: "Visualizations",
        title: "Visualization",
    },
    {
        id: "bigBangLayout.dashboard",
        path: ROUTES.DASHBOARD,
        component: DashboardPage,
        icon: faObjectsColumn,
        optionText: "Dashboards",
        title: "Dashboards",
    },
    {
        id: "bigBangLayout.studies",
        path: ROUTES.STUDIES,
        component: StudiesPage,
        icon: faBook,
        optionText: "Studies",
        title: "Studies",
    },
    {
        id: "bigBangLayout.admin",
        path: ROUTES.ADMIN,
        component: AdminPage,
        icon: faUserTie,
        optionText: "Admin",
        title: "Admin",
    },
];

export const getBigBangRoutes = (routePermissions: IRoutePermissions): Array<IBigBangRoute> =>
    BIG_BANG_ROUTES.filter(({ path }) => routePermissions[path]);

const JUMPER_PAGES = [
    {
        label: "Projects",
        path: ROUTES.PROJECTS,
    },
    {
        label: "Zones",
        path: ROUTES.ZONES,
    },
    {
        label: "Analyses",
        path: ROUTES.ANALYSES,
    },
    {
        label: "Viz3D",
        path: ROUTES.VIZ3,
    },
    {
        label: "Studies",
        path: ROUTES.STUDIES,
    },
    {
        label: "Dashboard",
        path: ROUTES.DASHBOARD,
    },
    {
        label: "Admin -> Users",
        path: ROUTES.ADMIN_USERS,
    },
    {
        label: "Admin -> Studies",
        path: ROUTES.ADMIN_STUDIES,
    },
    {
        label: "Admin -> Orgs",
        path: ROUTES.ADMIN_ORGS,
    },
    {
        label: "Admin -> Pending Analyses",
        path: ROUTES.ADMIN_PENDING_ANALYSES,
    },
    {
        label: "Admin -> Review",
        path: ROUTES.ADMIN_REVIEW_ANALYSES,
    },
    {
        label: "Admin -> Auto-Passed Zones",
        path: ROUTES.ADMIN_AUTO_PASSED_ZONES,
    },
    {
        label: "Admin -> About",
        path: ROUTES.ADMIN_ABOUT,
    },
    {
        label: "InSight Go",
        path: ROUTES.PIRATE_SHIP,
    },
];

export const getJumperPages = (routePermissions: IRoutePermissions): Array<IPage> =>
    JUMPER_PAGES.filter(({ path }) => routePermissions[path]).map(({ label, path }) => ({
        label,
        ariaLabel: label,
        path,
        isAvailable: true,
    }));
