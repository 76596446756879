interface IFormatConfig {
    totalValue?: number;
    shouldShowAsFractional?: boolean;
    shouldShowAsPercentage?: boolean;
    shouldCalculatePercentage?: boolean;
    fractionDigitsCount?: number;
}

export const formatUsingLocale = (value: number, config: IFormatConfig = {}) => {
    const { shouldShowAsFractional, shouldShowAsPercentage, fractionDigitsCount } = config;

    let _fractionDigitsCount;

    if (Number.isInteger(fractionDigitsCount)) {
        _fractionDigitsCount = fractionDigitsCount;
    } else {
        _fractionDigitsCount = shouldShowAsFractional || shouldShowAsPercentage ? 2 : 0;
    }

    const options = {
        minimumFractionDigits: _fractionDigitsCount,
        maximumFractionDigits: _fractionDigitsCount,
    };

    return value.toLocaleString(undefined, options);
};

export const numberToPercentage = (number: number, totalValue?: number | null) =>
    totalValue
        ? `${formatUsingLocale((number / totalValue) * 100, { shouldShowAsPercentage: true })}%`
        : "";

export const formatValue = (value: any, config: IFormatConfig = {}) => {
    if (typeof value !== "number") return "";

    if (config.shouldShowAsFractional) return formatUsingLocale(value, config);
    if (config.shouldCalculatePercentage) return numberToPercentage(value, config.totalValue);
    if (config.shouldShowAsPercentage) return `${formatUsingLocale(value * 100, config)}%`;

    return formatUsingLocale(value);
};
