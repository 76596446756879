import { lazy } from "react";
import { ROUTES } from "@common/constants/routes.constants";

const ManageZoneSetPage = lazy(() => import("@app/bigBang/manageZoneSet/manageZoneSet.page"));

export const routes = [
    {
        path: `${ROUTES.ZONES}/:zoneSetId/:mode`,
        component: ManageZoneSetPage,
        id: "ManageZoneSetPage",
        title: "Manage Zone Set",
    },
];
