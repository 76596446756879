import { useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { StlButton } from "@common/components/button/button";
import { ANY_FILTER_OPTION } from "@common/constants/filters.constants";
import type { DerivedDataObject, Filter } from "react-table-v6";
import "./filterInfoBanner.less";

type TProps = {
    totalCount: number;
    data: Array<unknown>;
    filtered: Array<Filter>;
    clearAllFilters: () => void;
    sortedData: Array<DerivedDataObject>;
    getSortedData?: (sortedData: Array<DerivedDataObject>) => void;
};

export const FilterInfoBanner = ({
    data,
    filtered,
    sortedData,
    getSortedData,
    totalCount,
    clearAllFilters,
}: TProps) => {
    const totalItemsCount = totalCount || data.length;
    const filteredCount = sortedData?.length || 0;

    const areFiltersApplied = useMemo(() => {
        const appliedFilters = filtered.filter(
            filter => filter.value !== null && filter.value !== ANY_FILTER_OPTION.value,
        );

        return appliedFilters.length > 0;
    }, [filtered]);

    const filtersMessage = `${
        !areFiltersApplied ? "No " : ""
    }Filters Applied - showing ${filteredCount} of ${totalItemsCount} items`;

    useEffect(() => {
        if (getSortedData && sortedData.length) getSortedData(sortedData);
    }, [getSortedData, sortedData]);

    return (
        <div
            className="stl-filter-info-banner"
            aria-live="polite"
            aria-atomic="true"
            data-testid="filterCount"
        >
            <FontAwesomeIcon className="filter-icon" icon={faFilter} />
            <div className="filters-message">{filtersMessage}</div>
            {areFiltersApplied && (
                <StlButton
                    variant="link"
                    className="clear-filters"
                    testid="clearFiltersBtn"
                    onClick={clearAllFilters}
                >
                    clear filters
                </StlButton>
            )}
        </div>
    );
};
